import { OfflineAction } from '@redux-offline/redux-offline/lib/types'
import { Failure, ActionCreator, AsyncActionCreators, Meta, Action, isType } from 'typescript-fsa'
import * as orderActions from '../order/actions'
import { refreshTokenSafely } from './index'
import { CREATE_ORDER_401, ERROR_ORDER_NOT_FOUND_IN_DATABASE } from '../order/functions'
import { getPlatformSupportImplementation } from '../platform'

type GenericActionCreatorFunction = ((result: {}) => ({}))

type ApiActionHandler<P> = (payload: P, options: RequestInit) => Promise<object | undefined>

/** Wrap promise results into the result format expected by typescript-fsa async actions so
 * the payload on the done and failed actions matches the type signatures provided by
 * typescript-fsa.
 */
function apiPromise(action: OfflineAction, retry: boolean = true): Promise<object | undefined> {
	const handler = handlerForAction(action)
	if (!handler) {
		return Promise.reject({ params: action.payload, error: new Error('No offline API handler found for action: ' + action.type) })
	}

	let promise = handler(action.payload!, {})
	console.log('apiPromise', promise)
	return promise.then(result => {
		console.log('apiPromise result', result)
		return Promise.resolve({ params: action.payload, result })
	}).catch(error => {
		console.log('apiPromise error', error)
		// we want to know about any errors when processing the order
		if (action.type === orderActions.submitOrder.started.type) {
			getPlatformSupportImplementation().logBreadCrumb('action', 'failed to process order: ' + action.payload)
		}
		if ((error instanceof Response && error.status === 401)
			|| (error instanceof Error && error.message === CREATE_ORDER_401)) {
			if (retry) {
				console.log('Retrying auth after offline API call failed', error)
				return refreshTokenSafely().then(() => {
					return apiPromise(action, false)
				}).catch(() => {
					/* Must fail with the original error so that handleDiscard can handle this correctly. */
					return Promise.reject({ params: action.payload, error })
				})
			} else {
				/* Fall through to Promise.reject below */
			}
		}
		return Promise.reject({ params: action.payload, error })
	})
}

export function handleDiscard(error: Failure<{}, Response | Error>, action: OfflineAction, retries: number = 0) {
	/* The Swagger Codegen API throws the response in the event of an error, so we use the
	status code from the response to determine whether to discard. And we use wrapPromise to wrap the results
	of the API into the Success or Failure containers that typescript-fsa uses, so we deconstruct those here.
	*/
	if (retries === 3) {
		// the order should already be set to ERROR state after the failed server response so we can simply remove the action from queue
		// log breadcrumb for discarded order action
		if (action.type === orderActions.submitOrder.started.type) {
			getPlatformSupportImplementation().logBreadCrumb('action', 'discarding submitOrderAction for: ' + action.payload)
			// we need to send an error so we can see the breadcrumbs for this failed order
			getPlatformSupportImplementation().reportError('Failed to submit order', action.payload)
		}
		return true
	}
	if (error.error instanceof Response) {
		if (error.error.status === 401) {
			/* Don't discard in the face of auth errors, we will try again once we're authed. */
			return false
		}

		const shouldDiscard: boolean = error.error.status >= 400 && error.error.status < 500
		if (shouldDiscard) {
			getPlatformSupportImplementation().reportError('Discarding offline action', action.payload)
		}
		return shouldDiscard
	} else if (action.type === orderActions.submitOrder.started.type && error.error instanceof Error && (error.error.message === CREATE_ORDER_401 || error.error.message === ERROR_ORDER_NOT_FOUND_IN_DATABASE)) {
		/**
		 * We discard submitOrder-related actions in the following cases:
		 * 
		 * - CREATE_ORDER_401 message has been thrown
		 * In the case of the submitOrder action we catch the error response and re-throw an Error with the CREATE_ORDER_401 message.
		 * If there was a 401 that must mean the refesh token has expired, we want to discard this so we can show the login screen instead.
		 * 
		 * - Order not found in database
		 * This is in cases where the order id can't be retrieved from realm.
		 * We discard further retries since the order may have already been uploaded to the server given it's not in the database anymore.
		 */
		return true
	} else {
		// We don't want to discard anything that is not an instance of Response, because the request didn't make it to the server.
		return false
	}
}

export function handleEffect(effect: {}, action: OfflineAction): Promise<object | undefined> {
	return apiPromise(action)
}

interface AsyncActionCreatorsWithHandler<P> {
	handler: ApiActionHandler<P>
}

/** Wrap an async action creator so that it creates actions with the metadata for redux-offline. So
 * when you create and dispatch the started action from the resulting action creator, it will be picked
 * up and handled by redux-offline.
 */
export function wrapOfflineAction<P, B, C>(action: AsyncActionCreators<P, B, C>, handler: ApiActionHandler<P>): AsyncActionCreators<P, B, C> {
	let newActionStartedCreator = function (payload: P, meta?: Meta): Action<P> {
		let result = action.started(payload, meta)
		result.meta = {
			...result.meta,
			offline: {
				// @ts-ignore
				commit: (action.done as GenericActionCreatorFunction)({ params: payload, result: undefined }),
				// @ts-ignore
				rollback: (action.failed as GenericActionCreatorFunction)({ params: payload, result: {} }),
			},
		}
		return result
	}

	let newAction = newActionStartedCreator as ActionCreator<P>
	newAction.type = action.started.type
	newAction.match = action.started.match

	let actionCreator: AsyncActionCreators<P, B, C> = {
		type: action.type,
		started: newAction,
		done: action.done,
		failed: action.failed,
	};

	((actionCreator as {}) as AsyncActionCreatorsWithHandler<P>).handler = handler
	return actionCreator
}

/** Find the handler function for the given action. */
function handlerForAction(action: OfflineAction): ApiActionHandler<{}> | undefined {
	/* Find the handler function by iterating through all the exported actions, looking for the one with the right type.
	   The handler function is inserted into the action creator by wrapOfflineAction.
	*/
	for (let o in orderActions) {
		if (isType(action, orderActions[o].started)) {
			return orderActions[o].handler
		}
	}
	return undefined
}
