import * as React from 'react'
import { Event, EventType, EventAttributeType, EventAttribute } from 'typescript-fetch-api'
import { IconButton, InputAdornment, TextField } from '@mui/material'

import * as Container from '../../../common/search/containers/SearchToolbar'
import SearchSuggestions from '../containers/SearchSuggestions'

import '../styles/SearchToolbar.scss'

interface OwnProps {
	shouldHideSearchSuggestions?: boolean
	onSearchInputFocusChange: (focused: boolean) => void
}

interface State {
	query?: string
	open: boolean
	focused: boolean
	hovered: boolean
}

const INITIAL_STATE = {
	open: false,
	focused: false,
	hovered: false,
}

export default class SearchToolbar extends React.PureComponent<OwnProps & Container.Props & Container.Actions, State> {

	state = {
		...INITIAL_STATE,
		query: this.props.searchText || '',
	}
	searchTimeout?: NodeJS.Timer
	anchorElement: HTMLElement | null = null

	componentDidUpdate(prevProps: OwnProps & Container.Props & Container.Actions, prevState: State) {
		// we only update the display state of the search suggestions if it has been previously opened
		if (prevProps.shouldHideSearchSuggestions !== this.props.shouldHideSearchSuggestions && this.props.shouldHideSearchSuggestions === true && this.state.open) {
			this.setState({ open: false })
		}
		// if focused state changed, notify parent
		if (prevState.focused !== this.state.focused || prevState.hovered !== this.state.hovered) {
			const isFocused = this.state.focused || this.state.hovered
			this.props.onSearchInputFocusChange(isFocused)
		}
		// if the user is viewing the search result screen, close the search suggestions
		if (prevProps.searchText !== this.props.searchText && this.props.searchText === this.state.query) {
			this.setState({ open: false })
		}
	}

	_handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		this.setState({ query: value }, () => {
			if (value.trim().length > 1) {
				this.props.fetchSearchSuggestions(value)
			}
		})
	}

	_handleClear = () => {
		this.setState({ query: '', focused: false })
	}

	_handleOnToggle = (value: boolean) => {
		this.setState({ open: value })
	}

	_handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault()
			if (this.state.query.trim().length > 1) {
				this.setState({ open: false })
				this._handleViewProductList()
			}
		}
	}

	_handleViewProductList = () => {
		const { query } = this.state
		if (query.trim().length > 1) {
			this.setState({ open: false })
			this.props.navigateToSearchResults(query)
			this._handleLogSearchEvent()
		}
	}

	_handleLogSearchEvent = (sku?: string) => {
		const eventAttributes: EventAttribute[] = [
			{ eventAttributeType: EventAttributeType.SEARCH_TERM, message: this.state.query },
			{ eventAttributeType: EventAttributeType.CLIENT_ID, value: this.props.customerId },
		]
		// add the product attribute
		if (sku) {
			eventAttributes.push({ eventAttributeType: EventAttributeType.PRODUCT_ID, message: sku })
		}
		// log search event
		const event: Event = {
			eventType: EventType.PRODUCT_SEARCHED,
			eventAttributes,
		}
		this.props.createEvent(event)
	}

	_onFocus = () => {
		this.setState({ focused: true })
	}

	_onBlur = () => {
		// only collapse text field if it is empty
		if (!this.state.query) {
			this.setState({ focused: false })
		}
	}

	_handleOnMouseOver = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		this.setState({ hovered: true })
	}

	_handleOnMouseLeave = () => {
		this.setState({ hovered: false })
	}

	render() {
		const { query, open, focused, hovered } = this.state

		return (
			<div
				onMouseOver={this._handleOnMouseOver}
				onMouseLeave={this._handleOnMouseLeave}
				className={`search-toolbar ${hovered || focused ? 'search-toolbar--focused' : ''}`}
			>
				<form className="search__container" ref={ref => this.anchorElement = ref}>
					<TextField
						value={query}
						placeholder={'PRODUCT SEARCH'}
						margin="dense"
						className="search__input"
						variant='standard'
						onChange={this._handleOnChange}
						onKeyPress={this._handleOnKeyPress}
						onFocus={this._onFocus}
						onBlur={this._onBlur}
						InputProps={{
							endAdornment: query && (
								<InputAdornment position="end">
									<IconButton
										onClick={this._handleClear}
										disableRipple={false}
									>
										<span className="icon-clear search__icon--clear" />
									</IconButton>
								</InputAdornment>
							),
							disableUnderline: true,
						}}
					/>
					<span className="icon-mui_search header-search-image" onClick={this._handleViewProductList} />
				</form>

				<div className="header-button">
					<span className="icon-mui_search header-button--icon" />
					<span className="header-button--text">Search</span>
				</div>

				<SearchSuggestions
					searchText={query}
					open={open}
					anchorElement={this.anchorElement}
					onToggle={this._handleOnToggle}
					onViewMore={this._handleViewProductList}
					logSearchEvent={this._handleLogSearchEvent}
				/>
			</div>
		)
	}
}