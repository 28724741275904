import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import * as accountsActions from '../accounts/actions'
import { readyAction } from '../root/actions'
import { AuthToken } from './types'

export interface StoreState {
	readonly userId?: number
	readonly firstName?: string
	readonly lastName?: string
	readonly mobileNumber?: string
	readonly email?: string
	readonly username?: string
	readonly goCheckoutAfter?: boolean
	readonly error?: Error
	readonly authToken?: AuthToken
	readonly loadingMessage?: string
	readonly pin?: string
	readonly branchFilterPreferenceEnabled: boolean
	readonly deletingSelf: boolean
	readonly deletingSelfMessage?: string
	readonly aroFloHookUrl?: string
}

const INITIAL_STATE: StoreState = {
	mobileNumber: undefined,
	goCheckoutAfter: undefined,
	error: undefined,
	authToken: undefined,
	loadingMessage: undefined,
	pin: undefined,
	branchFilterPreferenceEnabled: false,
	deletingSelf: false,
	deletingSelfMessage: undefined,
	aroFloHookUrl: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.refreshedAuthToken, (state, authToken): StoreState => {
		return {
			...state,
			authToken,
		}
	})
	.case(actions.refreshAuthTokenFailed, (state): StoreState => {
		/* When the refresh token fails we blank the accessToken, so the app knows we need to re-auth, but we do not
		   do the loggedOut action, so we retain our username property, so we know we need to re-auth as that user
		   in order to preserve our offline queue.
		 */
		return {
			...state,
			authToken: undefined,
		}
	})
	.case(actions.login.done, (state, { result: authToken }): StoreState => {
		return {
			...state,
			authToken,
			loadingMessage: state.loadingMessage, /* we keep the loading message because we want to wait for accounts */
		}
	})
	.case(actions.login.failed, (state, { error }): StoreState => {
		return {
			...state,
			error: error,
			loadingMessage: undefined,
		}
	})
	.case(actions.login.started, (state, params): StoreState => {
		return {
			...state,
			mobileNumber: params.username,
			goCheckoutAfter: params.goCheckoutAfter,
			error: undefined,
			loadingMessage: 'Signing in…',
		}
	})
	.case(actions.deleteSelf.done, (state): StoreState => {
		return {
			...state,
			deletingSelf: false,
			deletingSelfMessage: undefined,
		}
	})
	.case(actions.deleteSelf.failed, (state, { error }): StoreState => {
		return {
			...state,
			error: error,
			deletingSelf: false,
			deletingSelfMessage: undefined,
		}
	})
	.case(actions.deleteSelf.started, (state): StoreState => {
		return {
			...state,
			deletingSelf: true,
			deletingSelfMessage: 'Deleting Profile…',
		}
	})
	.case(accountsActions.getAccounts.started, (state): StoreState => {
		return {
			...state,
			error: undefined,
			loadingMessage: 'Loading accounts…',
		}
	})
	.case(accountsActions.getAccounts.failed, (state): StoreState => {
		return {
			...state,
			// the error is stored on the accounts reducer, this is just to keep the loading flag in one place for login
			loadingMessage: undefined,
		}
	})
	.case(accountsActions.getAccounts.done, (state, { result }): StoreState => {
		return {
			...state,
			userId: result.userId,
			firstName: result.firstname,
			lastName: result.lastname,
			mobileNumber: result.mobile,
			email: result.email,
			username: result.username,
			error: undefined,
			loadingMessage: undefined,
			branchFilterPreferenceEnabled: result.branchFilterPreferenceEnabled || false,
		}
	})
	.case(actions.clearAuthError, (state): StoreState => {
		return {
			...state,
			error: undefined,
		}
	})
	.case(readyAction, (state): StoreState => {
		return {
			...state,
			error: undefined,
			loadingMessage: undefined,
		}
	})
	.case(actions.backSignInStack, (state): StoreState => {
		return {
			...state,
			// clear the loading and errors when navigating back
			loadingMessage: undefined,
			error: undefined,
		}
	})
	// aroflo hook url
	.case(actions.storeAroFloHookUrl, (state, payload): StoreState => {
		return {
			...state,
			aroFloHookUrl: payload,
		}
	})
	/* The user has been logged out remove our stored access token from the state. */
	.cases([actions.loggedOut, actions.clearAuthToken], (): StoreState => {
		return INITIAL_STATE
	})

reducer.case(actions.setPinComplete, (state, payload): StoreState => ({
	...state, pin: payload.pin
}))

reducer.case(actions.setBranchFilterPreference.done, (state, { params }): StoreState => ({
	...state, branchFilterPreferenceEnabled: params,
}))
