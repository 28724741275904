import { BranchProductDetail } from 'typescript-fetch-api'

import { RootStoreState } from '../root'
import { PlatformProduct } from '../../modules/platform'
import { LoadProductsCountPayload, ProductFilter } from './actions'

export const productCategorySelector = (state: RootStoreState) => state.products.productCategory

export const productsSelector = (state: RootStoreState): ReadonlyArray<PlatformProduct> => state.products.products

export const allProductsParamsSelector = (state: RootStoreState): LoadProductsCountPayload | undefined => state.products.productsCount.allProductsParams

export const branchProductsParamsSelector = (state: RootStoreState): LoadProductsCountPayload | undefined => state.products.productsCount.branchProductsParams

export const productStockCountsSelector = (state: RootStoreState): BranchProductDetail[] => state.products.productStockCounts

export const savedFilterSelector = (state: RootStoreState): ProductFilter | undefined => state.products.savedFilter