import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { PasswordUpdate } from 'typescript-fetch-api'

import * as actions from './actions'
import * as accountActions from '../accounts/actions'
import { ApiErrorMessage, getUserApi } from '../api'
import { userIdSelector } from '../auth/selectors'
import { callApi } from '../api/functions'

export function* handleUpdateUserPassword(action: actions.UpdateUserPasswordAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.updateUserPassword,
		(payload: PasswordUpdate, options: RequestInit) => {
			return getUserApi().updateUserPassword({ passwordUpdate: payload }, options)
				.catch(error => {
					let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
					if (error instanceof Response) {
						switch (error.status) {
							case 409:
								errorText = 'Old password does not match'
								break
							case 406:
								errorText = 'New password is invalid'
								break
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
		})
}

function* handleUpdateUserProfile(action: actions.EditProfileAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.editProfile,
		(payload: actions.EditProfilePayload, options: RequestInit) => {
			return getUserApi().updateUserProfile({ id: payload.id, profileUpdate: payload.profile }, options)
				.catch(error => {
					let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
					if (error instanceof Response) {
						switch (error.status) {
							case 409:
								errorText = 'User with the same credentials already exists'
								break
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
		})
}

/**
 * Gets fired whenever an update user profile action is done.
 * Reloading of accounts should only happen when the edited profile matches the current user's logged in ID.
 * @param action The action containing the updater user profile success payload
 */
function* handleReloadUserAccountsOnProfileEdit(action: actions.EditProfileSuccessAction): SagaIterator {
	const userId = yield select(userIdSelector)
	if (userId) {
		const hasEditedOwnAccount: boolean = userId === action.payload.params.id
		if (hasEditedOwnAccount) {
			yield put(accountActions.getAccounts.started({}))
		}
	}
}

export default function* (): SagaIterator {
	yield takeEvery(actions.updateUserPassword.started, handleUpdateUserPassword)
	yield takeEvery(actions.editProfile.started, handleUpdateUserProfile)
	yield takeEvery(actions.editProfile.done, handleReloadUserAccountsOnProfileEdit)
}