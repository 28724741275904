import { BranchProductsResponse, ProductPricesResponse, ProductSku, ProductsResponse, ProductBranchesResponse, SearchFilter, SearchRequest } from 'typescript-fetch-api'
import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { PlatformProduct, ProductInfo } from '../../modules/platform'
import { UpdatingProductsState, ProductsViewState } from '../config/types'
import { TotalProductsCount, TotalProductsCountType } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Products')

export interface LoadProductsActionPayload {
	categoryId: string
	page?: number
	pageSize?: number
	searchRequest?: SearchRequest // used on web to set stockedInBranch filter for initial products load
}
export interface LoadProductsResult {
	products: ReadonlyArray<PlatformProduct>
	totalProductPagesCount?: number
	totalProductsCount?: number
	productsCount?: TotalProductsCount
}
export type LoadSubcategoriesAction = Action<LoadProductsActionPayload>
export type LoadProductsSuccessAction = Action<Success<LoadProductsActionPayload, LoadProductsResult>>
export const loadProducts = actionCreator.async<LoadProductsActionPayload, LoadProductsResult, Error>('LOAD_PRODUCTS')
export const setLoadingProductsState = actionCreator('SET_LOADING_PRODUCTS_STATE')
export const fetchProducts = actionCreator.async<void, ProductsResponse | undefined, Error>('FETCH_PRODUCTS')
export const fetchProductBranches = actionCreator.async<void, ProductBranchesResponse | undefined, Error>('FETCH_PRODUCT_BRANCHES')
export const setUpdatingProductsState = actionCreator<UpdatingProductsState>('SET_UPDATING_PRODUCTS_STATE')
// needed a way to allower user entry to the app and process any product updates in the background because was taking too long (except on first install)
export const setUpdatingProductsInBackground = actionCreator('SET_UPDATING_PRODUCTS_IN_BACKGROUND')
export const clearUpdatingProductsState = actionCreator('CLEAR_UPDATING_PRODUCTS_STATE')
export const navigateToProductsTab = actionCreator('NAVIGATE_TO_PRODUCTS_TAB')

// When the ProductsList screen gets unmounted we want to clear the selected category. This is because when the user taps the Home tab we want to clear any selected supplier filters,
// which also needs to trigger a new products load (with no filters) BUT only if the ProductsList screen is in the Products stack.
// (eg: we don't want to trigger a products load if the user is just viewing the CategoriesList in the Products tab)
export const clearSelectedCategory = actionCreator('CLEAR_SELECTED_CATEGORY')

export const updateSelectedCategory = actionCreator<string>('UPDATE_SELECTED_CATEGORY')

export const toggleProductsViewState = actionCreator<ProductsViewState>('TOGGLE_PRODUCTS_VIEW_STATE')

interface ResetProductsTabPayload {
	navigateToProducts?: boolean
}
export type ResetProductsTabAction = Action<ResetProductsTabPayload>
// dispatched after the products where updated in the database, we need to refresh the Products tab so its using new realm objects (categories and products)
export const resetProductsTab = actionCreator<ResetProductsTabPayload>('RESET_PRODUCTS_TAB')

export interface LoadFiltersForProductsPayload {
	categoryId: string
}
export const loadFiltersForProducts = actionCreator.async<LoadFiltersForProductsPayload, SearchFilter[], Error>('LOAD_FILTERS_FOR_PRODUCTS')

export interface ProductFilter {
	categoryId: string
	filters?: SearchFilter[]
	page?: number
	pageSize?: number
}
export type SaveProductsFilterAction = Action<ProductFilter>
export const saveProductsFilter = actionCreator<ProductFilter>('SAVE_PRODUCTS_FILTER')

export const setLoadingFiltersState = actionCreator('SET_LOADING_FILTERS_STATE')

export interface LoadProductPricesPayload {
	skus: ProductSku[]
	customerId?: number
	appendToList?: boolean
}
export type LoadProductPricesAction = Action<LoadProductPricesPayload>
export const loadProductPrices = actionCreator.async<LoadProductPricesPayload, ProductPricesResponse, Error>('LOAD_PRODUCT_PRICES')

export const cancelLoadProductPrices = actionCreator('CANCEL_LOAD_PRODUCT_PRICES')

export interface GetProductsAvailabilityPayload {
	skus: ProductSku[]
	branchId: number
	// serves as reference when loading multiple batches of product stock counts.
	// if populated, we append the batch results to the current stock count list in the store (previously appendToList).
	batchCount?: number
	// flag to know whether to clear old stock counts in the store. by default, the results get automatically appended to the list. 
	clearOldStockCounts?: boolean
}
export type GetProductsAvailabilityAction = Action<GetProductsAvailabilityPayload>
export type GetProductsAvailabilitySuccessAction = Action<Success<GetProductsAvailabilityPayload, BranchProductsResponse>>
export const getProductsAvailability = actionCreator.async<GetProductsAvailabilityPayload, BranchProductsResponse, Error>('GET_PRODUCTS_AVAILABILITY')

export const cancelLoadProductsAvailability = actionCreator('CANCEL_LOAD_PRODUCTS_AVAILABILITY')

export interface LoadProductsCountPayload {
	type: TotalProductsCountType
	categoryId?: string
	queryText?: string
	isProductFinder?: boolean
	customerId?: number
	searchRequest?: SearchRequest
}
export type loadProductsCountAction = Action<LoadProductsCountPayload>
export const loadProductsCount = actionCreator.async<LoadProductsCountPayload, number>('LOAD_PRODUCTS_COUNT')

export interface RequestImagesPayload {
	skus: ProductSku[]
	customerId: number
}
export type RequestImagesAction = Action<RequestImagesPayload>
export const requestImages = actionCreator.async<RequestImagesPayload, ProductPricesResponse, Error>('REQUEST_IMAGES')

export const addImageRequest = actionCreator<ProductInfo>('ADD_IMAGE_REQUEST')
export const removeImageRequest = actionCreator<ProductInfo>('REMOVE_IMAGE_REQUEST')
export const removeAllImageRequests = actionCreator('REMOVE_ALL_IMAGE_REQUESTS')