import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

// import Component from '../components/FinancialsMenu'
import Component from '../components/FinancialsMenuPopper'
import { navigateToFinancialsTab } from '../../../common/financials/actions'
import { SubheaderMenuProps } from '../../platform/types'
import { FinancialsTabEnum } from '../../../common/financials/types'

export type OwnProps = SubheaderMenuProps

export interface Actions {
	navigateToInvoices: () => void
	navigateToAccountBalance: () => void
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	navigateToInvoices: () => {
		dispatch(navigateToFinancialsTab({ tab: FinancialsTabEnum.INVOICES }))
	},
	navigateToAccountBalance: () => {
		dispatch(navigateToFinancialsTab({ tab: FinancialsTabEnum.ACCOUNT_BALANCE }))
	},
})

export default connect(undefined, mapDispatchToProps)(Component)
