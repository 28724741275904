import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { PriceBookExampleFileFormat, PriceBookSubscriptionResponse } from 'typescript-fetch-api'

import { SubscriptionSuccessEnum } from './types'

import * as actions from './actions'
import * as authActions from '../auth/actions'

export interface StoreState {
	readonly subscription: PriceBookSubscriptionResponse | undefined
	// Price Book Subscription
	readonly subscriptionLoading: boolean | undefined
	readonly subscriptionSuccessType: SubscriptionSuccessEnum | undefined
	readonly subscriptionError: Error | undefined
	// Price Book Request
	readonly requestLoading: boolean | undefined
	readonly requestSuccess: boolean | undefined
	readonly requestError: Error | undefined
	// Price Book Example File Download
	downloadingExamplePriceBookFiles: PriceBookExampleFileFormat[]
}

const INITIAL_STATE: StoreState = {
	subscription: undefined,
	subscriptionLoading: undefined,
	subscriptionSuccessType: undefined,
	subscriptionError: undefined,
	requestLoading: undefined,
	requestSuccess: undefined,
	requestError: undefined,
	downloadingExamplePriceBookFiles: [],
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.fetchPriceBookSubscription.started, (): StoreState => {
		return INITIAL_STATE
	})
	.case(actions.fetchPriceBookSubscription.done, (state, payload): StoreState => {
		const subscription = payload.result
		// if the `isSubscribed` flag is null, we reset the subscription since we know the user hasn't set any subscriptions yet.
		// we perform this extra check since the server returns a subscription object regardless if the user has previously set a subscription or not.
		if (subscription && subscription.isSubscribed === null) {
			return {
				...state,
				subscription: INITIAL_STATE.subscription,
			}
		}
		return {
			...state,
			subscription,
		}
	})
	.case(actions.updatePriceBookSubscription.started, (state): StoreState => {
		return {
			...state,
			subscriptionLoading: true,
			subscriptionSuccessType: undefined,
			subscriptionError: undefined,
		}
	})
	.case(actions.updatePriceBookSubscription.done, (state, payload): StoreState => {
		return {
			...state,
			subscription: payload.result,
			subscriptionLoading: undefined,
			// check if the user had a previous subscription setup
			subscriptionSuccessType: !state.subscription || !state.subscription.dateLastChanged
				? SubscriptionSuccessEnum.CREATE
				: SubscriptionSuccessEnum.UPDATE
		}
	})
	.case(actions.updatePriceBookSubscription.failed, (state, payload): StoreState => {
		return {
			...state,
			subscriptionError: payload.error,
			subscriptionLoading: undefined,
		}
	})
	.case(actions.createPriceBookRequest.started, (state): StoreState => {
		return {
			...state,
			requestLoading: true,
			requestSuccess: undefined,
			requestError: undefined,
		}
	})
	.case(actions.createPriceBookRequest.done, (state): StoreState => {
		return {
			...state,
			requestSuccess: true,
			requestLoading: undefined,
		}
	})
	.case(actions.createPriceBookRequest.failed, (state, payload): StoreState => {
		return {
			...state,
			requestError: payload.error,
			requestLoading: undefined,
		}
	})
	.case(actions.downloadExamplePriceBookFile.started, (state, payload): StoreState => {
		if (state.downloadingExamplePriceBookFiles.some(item => item === payload)) return state
		return {
			...state,
			downloadingExamplePriceBookFiles: [...state.downloadingExamplePriceBookFiles, payload],
		}
	})
	.cases([actions.downloadExamplePriceBookFile.done, actions.downloadExamplePriceBookFile.failed], (state, { params }): StoreState => {
		return {
			...state,
			downloadingExamplePriceBookFiles: state.downloadingExamplePriceBookFiles.filter(item => item !== params),
		}
	})
	/* The user has been logged out remove accounts from the state. */
	.cases([authActions.loggedOut, authActions.clearAuthToken], (): StoreState => {
		return INITIAL_STATE
	})