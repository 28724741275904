import { BackflowProductBrand, BackflowProductType } from 'typescript-fetch-api'

export const getBackflowBrandTitle = (brand: BackflowProductBrand | undefined): string => {
	switch (brand) {
		case BackflowProductBrand.WATTS:
			return 'Watts by Hydroflow'
		case BackflowProductBrand.WILKINS:
			return 'Wilkins'
		default:
			return ''
	}
}

export const getBackflowTypeTitle = (brand: BackflowProductType | undefined): string => {
	switch (brand) {
		case BackflowProductType.LOW_MEDIUM_HAZARD:
			return 'Low-Medium Hazard'
		case BackflowProductType.HIGH_HAZARD:
			return 'High Hazard'
		case BackflowProductType.SPARE_PART:
			return 'Spare Parts'
		default:
			return ''
	}
}

/**
 * Grabs the backflow product brand or type and returns the method to be used in analytics
 * @param backflowProduct the backflow product brand or type
 * @returns the method to be used in analytics
 */
export const getBackflowAsAnalyticsMethod = (backflowProduct: BackflowProductBrand | BackflowProductType): string => {
	switch (backflowProduct) {
		case BackflowProductBrand.WATTS:
			return 'watts'
		case BackflowProductBrand.WILKINS:
			return 'wilkins'
		case BackflowProductType.LOW_MEDIUM_HAZARD:
			return 'low hazard'
		case BackflowProductType.HIGH_HAZARD:
			return 'high hazard'
		case BackflowProductType.SPARE_PART:
			return 'spare part'
	}
}