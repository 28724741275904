import { useEffect, useRef } from 'react'

/**
 * A hook that gets the previous value of either props or state.
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @see https://usehooks.com/usePrevious/
 * 
 * @param value the value to look out for
 */
const usePrevious = <T>(value: T): T | undefined => {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef<T>()

	// Store current value in ref
	useEffect(() => {
		ref.current = value
	}, [value]) // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current
}

export default usePrevious
