import * as React from 'react'
import { Button, Divider } from '@mui/material'

import { KaingaOraLogoWithText, ToggleHnzMode, HnzBackground } from '../../../utils/imagesProvider'

import '../../platform/styles/NewFeaturePage.scss'

interface Props {
	onNext: () => void
}

export default class HnzNewFeaturePageFour extends React.PureComponent<Props> {

	render() {
		return (
			<div className="page">
				<div className="page__heading">
					<img src={KaingaOraLogoWithText} alt="Kāinga Ora Logo" className="page__logo--heading" />
				</div>

				<div className="page__content">
					<h1 className="page__text--heading">STEP 2</h1>

					<h2 className="page__text--subheading page__text--subheading-emphasized">
						SELECT & SHOP ONLY<br />
						KĀINGA ORA APPROVED PRODUCTS
					</h2>

					<img src={ToggleHnzMode} alt="Toggle Kāinga Ora" className="page__image--toggle-mode" />

					<Divider className="page__divider" />

					<p className="page__text">
						Located above filters on each product category page<br />
						& Products Menu drop down
					</p>
				</div>

				<div className="page__footer">
					<Button
						variant="outlined"
						className="button--fixed-width"
						onClick={this.props.onNext}
					>
						NEXT
					</Button>
				</div>

				<img src={HnzBackground} alt="Kāinga Ora" className="page__background page__background--hnz" />
			</div>
		)
	}
}