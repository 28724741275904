import '../styles/SubheaderGeneral.scss'

import * as React from 'react'
import { Divider } from '@mui/material'
import { useLocation } from 'react-router-dom'

import SubheaderTab from '../containers/SubheaderTab'
import { SUBHEADER_ITEMS_GENERAL, SUBHEADER_ITEMS_PARTNERS } from '../content'
import { Partners } from '../../integrations/types'
import { Paths } from '../../navigation/types'

const SubheaderGeneral: React.FunctionComponent = () => {

	const { pathname } = useLocation()
	// remove leading '/' from path
	const pathnameStripped = pathname.startsWith('/') ? pathname.substring(1) : pathname

	const _renderAuthSubheader = () => (
		<div className="subheader-general__auth subheader-general__group">
			<SubheaderTab
				key={SUBHEADER_ITEMS_GENERAL.signIn.title}
				tab={SUBHEADER_ITEMS_GENERAL.signIn}
				pathname={pathnameStripped}
			/>

			<Divider orientation="vertical" className="subheader-general__divider subheader-general__divider--main" />

			<SubheaderTab
				key={SUBHEADER_ITEMS_GENERAL.register.title}
				tab={SUBHEADER_ITEMS_GENERAL.register}
				pathname={pathnameStripped}
			/>
		</div>
	)

	const isPartnerPage = pathname.endsWith(Partners.XERO) || pathname.endsWith(Partners.SIMPRO)

	const isArofloSignin = pathname.includes(Paths.AROFLO_PUNCHOUT)

	// do not show sign in/register buttons on aroflo punchout page
	if (isArofloSignin) {
		return null
	}

	return (
		<div className="subheader-general">
			<div className="subheader-general__content">
				<div className={`subheader-general__content ${isPartnerPage ? 'subheader-general__content--partners' : ''}`}>
					{isPartnerPage && (
						<div className="subheader-general__partners">
							<div className="subheader-general__group">
								<SubheaderTab
									key={SUBHEADER_ITEMS_PARTNERS.xero.title}
									tab={SUBHEADER_ITEMS_PARTNERS.xero}
									pathname={pathnameStripped}
								/>

								<Divider orientation="vertical" className="subheader-general__divider subheader-general__divider--main" />

								<SubheaderTab
									key={SUBHEADER_ITEMS_PARTNERS.simpro.title}
									tab={SUBHEADER_ITEMS_PARTNERS.simpro}
									pathname={pathnameStripped}
								/>
							</div>

							<Divider orientation="vertical" className="subheader-general__divider subheader-general__divider--partners" />
						</div>
					)}

					{_renderAuthSubheader()}
				</div>
			</div>
		</div>
	)
}

export default SubheaderGeneral