import '../styles/LabelsSmallInfoDialog.scss'

import React from 'react'
import { Dialog } from '@mui/material'

import Button from '../../platform/components/Button'

interface Props {
	onClose: () => void
}

const LabelsSmallInfoDialog = ({ onClose }: Props): JSX.Element => {

	return (
		<Dialog
			open={true}
			onClose={onClose}
			maxWidth="xs"
			className="labels-small-info-dialog"
			fullWidth={true}
		>
			<div className="labels-small-info-dialog__container">
				<div className="labels-small-info-dialog__title">
					<span className="icon-label labels-small-info-dialog__icon-label" />
					<span className="labels-small-info-dialog__text--title">Labels</span>
				</div>

				<div className="labels-small-info-dialog__separator" />

				<div className="labels-small-info-dialog__content">
					<span className="labels-small-info-dialog__text">Create new label groups, add products and send to print</span>
					<span className="icon-arrow_drop_down labels-small-info-dialog__icon-arrow" />
					<span className="labels-small-info-dialog__text">Collect Labels from your local branch</span>
					<span className="icon-arrow_drop_down labels-small-info-dialog__icon-arrow" />
					<span className="labels-small-info-dialog__text">Use the pwGO app to scan the labels & order products on-the-go</span>

					<Button
						kind="outlined"
						fullWidth={true}
						onClick={onClose}
						className="labels-small-info-dialog__button"
					>
						Close
					</Button>
				</div>
			</div>
		</Dialog>
	)
}

export default LabelsSmallInfoDialog