import '../styles/ProductImage.scss'

import React, { useState } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

import { getImageLargeServerUrl, getImageLargeServerUrlFallback, IMAGE_ID_PLACEHOLDER } from '../../../common/api'
import { IconNoImage } from '../../../utils/imagesProvider'

interface Props extends React.HTMLAttributes<HTMLImageElement> {
	alt: string
	image?: string
	cartCount?: number
	requestImage?: boolean
	onRequestImage?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ProductImage = ({ alt, image, cartCount, requestImage, className, children, onRequestImage, onClick, ...rest }: Props) => {

	const imageSources = image ? [
		`${getImageLargeServerUrl().replace(IMAGE_ID_PLACEHOLDER, image)}`,
		`${getImageLargeServerUrlFallback().replace(IMAGE_ID_PLACEHOLDER, image)}`,
		IconNoImage,
	] : [IconNoImage]

	/**
	 * Local State
	 */

	const [imageIndex, setImageIndex] = useState(0)

	const canRequestImage = !!onRequestImage

	return (
		<React.Fragment>
			{(!image || imageIndex > 1) && canRequestImage && (
				<FormControlLabel
					control={
						<Checkbox
							color="secondary"
							checked={requestImage}
							onChange={onRequestImage!}
							classes={{
								colorSecondary: 'product-image__checkbox',
							}}
						/>
					}
					label="Request Image"
					classes={{
						root: 'product-image__form',
						label: 'product-image__text--form-label',
					}}
				/>
			)}

			<div className="product-image" onClick={onClick}>
				<img
					src={imageSources[imageIndex]}
					onError={() => setImageIndex(imageIndex + 1)}
					alt={alt}
					className={`product-image__image ${className || ''} ${onClick ? ' product-image__content--clickable' : ''}`}
					{...rest}
				/>

				<div className="product-image__icons">
					{children}
					{cartCount !== undefined && cartCount > 0 && (
						<div className="product-image__cart-count">
							<span className="product-image__text--cart-count">{cartCount}</span>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	)
}

export default ProductImage
