import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'

import * as actions from '../../common/backflow/actions'
import * as NavigationManager from '../navigation/NavigationManager'

function handleNavigateToBackflow() {
	NavigationManager.navigateToBackflowLanding()
}

function handleNavigateToBackflowBrand(action: actions.NavigateToBackflowBrandAction) {
	NavigationManager.navigateToBackflowBrand(action.payload)
}

function handleNavigateToBackflowType(action: actions.NavigateToBackflowTypeAction) {
	NavigationManager.navigateToBackflowType(action.payload)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.navigateToBackflow, handleNavigateToBackflow)
	yield takeEvery(actions.navigateToBackflowBrand, handleNavigateToBackflowBrand)
	yield takeEvery(actions.navigateToBackflowType, handleNavigateToBackflowType)
}