import '../styles/BranchOpeningHours.scss'

import moment from 'moment'
import React from 'react'
import { StandardHoursDay } from 'typescript-fetch-api'

import { API_DATE_TIME_FORMAT } from '../../../common/util/functions'

interface Props {
	currentDay: StandardHoursDay | undefined
}

const BranchOpeningHours = ({ currentDay }: Props) => {
	if (!currentDay) {
		return (
			<p className="branch-opening-hours__text branch-opening-hours__text--closed">Closed Today</p>
		)
	}
	const start = moment(currentDay.startTimestamp, API_DATE_TIME_FORMAT).format('h:mma')
	const end = moment(currentDay.endTimestamp, API_DATE_TIME_FORMAT).format('h:mma')

	return (
		<React.Fragment>
			<p className="branch-opening-hours__text branch-opening-hours__text--open">Open Today</p>
			{currentDay && <p className="branch-opening-hours__text branch-opening-hours__text--open">{start} - {end}</p>}
		</React.Fragment>
	)
}

export default BranchOpeningHours