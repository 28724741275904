import '../styles/HazardRating.scss'

import { Paper, Tooltip } from '@mui/material'

import { HighHazardIcon, LowMediumHazardIcon } from '../../../utils/imagesProvider'

type Props = {
	rating: string
	elevation?: number
	className?: string
}

const HazardRating = ({ rating, elevation, className }: Props): JSX.Element | null => {
	return (
		<Paper
			className={`hazard-rating ${elevation && 'hazard-rating__paper'} ${className}'}}`}
			elevation={elevation || 0}
		>
			<Tooltip title={`Hazard Rating: ${rating}`}>
				<img src={rating === 'High' ? HighHazardIcon : LowMediumHazardIcon} alt="Hazard Level" className="hazard-rating__icon" />
			</Tooltip>
		</Paper>
	)
}

export default HazardRating
