import { BranchProductDetail, ProductWelsAttribute } from 'typescript-fetch-api'

import { BranchesAvailability } from './types'

export const updateBranchesAvailability = (regionId: string, products: BranchProductDetail[] | undefined, savedAvailability: BranchesAvailability): BranchesAvailability | undefined => {
	switch (regionId) {
		case '2':
		case 'NTHN':
			return { ...savedAvailability, northern: products || [] }
		case '3':
		case 'CENT':
			return { ...savedAvailability, central: products || [] }
		case '4':
		case 'LCNT':
			return { ...savedAvailability, lowerCentral: products || [] }
		case '5':
		case 'STHN':
			return { ...savedAvailability, southern: products || [] }
		default:
			return undefined
	}
}

/**
 * Handles grouping wels attributes based on the wels group name, ie Water Consumption, WELS Water Efficiency
 * @param attributes the attributes to group
 * @returns the grouped attributes
 */
export const getGroupedWelsAttributes = (attributes: ProductWelsAttribute[]): Record<string, ProductWelsAttribute>[] => {
	return attributes.reduce((data, attribute) => {
		const welsGroup = attribute.name!
		if (!data[welsGroup]) {
			data[welsGroup] = []
		}
		data[welsGroup].push(attribute)
		return data
	}, [])
}