import { ClosureType, CreateOrderDespatchMethodEnum, CreateOrderCheckoutMethodEnum } from 'typescript-fetch-api'

import { getDespatchMethodLabel } from '../../common/cart/functions'
import { CheckoutPath, CheckoutStep } from './types'
import { CustomOption } from '../platform/types'

export const CHECKOUT_LAST_STEP = 3
export const CHECKOUT_STEPPER: CheckoutStep[] = [
	{ id: 0, title: 'Review Cart', path: undefined },
	{ id: 1, title: 'Order Details', path: CheckoutPath.OrderDetails },
	{ id: 2, title: 'Confirm', path: CheckoutPath.Confirm },
	{ id: CHECKOUT_LAST_STEP, title: 'Order Complete', path: CheckoutPath.Complete },
]

export const QUANTITY_MIN_VALUE = '1'
// list of uoms that shouldn't handle decimals
export const QUANTITY_DECIMALS_NOT_ALLOWED = ['ea', 'gm', 'hd']
export const PICKER_DATE_FORMAT = 'DD/MM/YYYY'
export const DEFAULT_BRANCH_ID = -1
export const NOT_INSTOCK_DESCRIPTION = 'Available to Order. Branch to confirm ETA.'

export const OrderDespatchMethodOptions: CustomOption<CreateOrderDespatchMethodEnum>[] = [
	{ label: getDespatchMethodLabel(CreateOrderDespatchMethodEnum.CPU), value: CreateOrderDespatchMethodEnum.CPU },
	{ label: getDespatchMethodLabel(CreateOrderDespatchMethodEnum.DIR_D), value: CreateOrderDespatchMethodEnum.DIR_D },
]

export const OrderCheckoutMethodOptions: CustomOption<CreateOrderCheckoutMethodEnum>[] = [
	{ label: 'Job', value: CreateOrderCheckoutMethodEnum.JOB_SIMPRO },
	{ label: 'Stock', value: CreateOrderCheckoutMethodEnum.STOCK_SIMPRO },
]

export const ClosureOptions: CustomOption<ClosureType>[] = [
	{ value: ClosureType.AM, label: ClosureType.AM },
	{ value: ClosureType.PM, label: ClosureType.PM },
]