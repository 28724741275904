import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { ChangePasswordRequest } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('ForgotPassword')

// payload determines whether to take the user to checkout screen afterwards
export interface ForgotPasswordPayload {
	user: string
	goCheckoutAfter: boolean
	fromProfile?: boolean
}
export type ForgotPasswordAction = Action<ForgotPasswordPayload>
export type ForgotPasswordSuccessAction = Action<Success<ForgotPasswordPayload, Response>>
export const forgotPassword = actionCreator.async<ForgotPasswordPayload, Response, Error>('FORGOT_PASSWORD')

// payload determines whether to take the user to checkout screen afterwards
export interface ChangePasswordRequestPayload {
	changePasswordRequest: ChangePasswordRequest
	goCheckoutAfter: boolean
}
export type ChangePasswordRequestAction = Action<ChangePasswordRequestPayload>
export const changePassword = actionCreator.async<ChangePasswordRequestPayload, Response, Error>('CHANGE_PASSWORD')

export const clearChangePasswordSuccess = actionCreator('CLEAR_CHANGE_PASSWORD_SUCCESS')