import { Button, Snackbar, SnackbarCloseReason } from '@mui/material'
import React, { useEffect, useState } from 'react'

const UPDATE_INTERVAL_TIME = 1800000 // 30 minutes

// DELETEME: I am not used in the Express Application (Need to double check on other projects)
const UpdateSnackbarNotification = (): JSX.Element => {

	/**
	 * Local State
	 */

	const [isOpenSnackbar, setIsOpenSnackbar] = useState(false)

	/**
	 * Effects/Subscriptions
	 */

	useEffect(() => {
		const interval = setInterval(() => {
			// fetch latest js file
			// NB using random query string to prevent hitting cached version
			const timestamp = Date.now()
			fetch('/asset-manifest.json?' + timestamp)
				.then(response => response.json())
				.then(manifest => {
					const latest = manifest.files['main.js']

					// check current main.js file from cached index.html
					const scripts = document.getElementsByTagName('script')
					for (let i = 0; i < scripts.length; i++) {
						// find main.js script
						const src = scripts[i].getAttribute('src')
						if (src !== null && src.startsWith('/static/js/main.')) {
							// check if different
							if (latest !== src) {
								setIsOpenSnackbar(true)
							}
							break
						}
					}
				})
				.catch(error => {
					console.error('error loading asset-manifest', error)
				})
		}, UPDATE_INTERVAL_TIME)

		return () => {
			clearInterval(interval)
		}
	}, [])

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={isOpenSnackbar}
			autoHideDuration={1800000}
			onClose={(_: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
				if (reason === 'clickaway') return
				setIsOpenSnackbar(false)
			}}
			ContentProps={{
				'aria-describedby': 'message-id',
			}}
			message={<span id='message-id' className='snackbar'>Update available</span>}
			action={
				<Button
					variant='contained'
					color='primary'
					onClick={() => {
						window.location.reload()
					}}
				>
					RELOAD
				</Button>
			}
		/>
	)
}

export default UpdateSnackbarNotification