import { SagaIterator } from 'redux-saga'
import { call, takeEvery } from 'redux-saga/effects'

import * as actions from './actions'
import { getUserApi } from '../api'
import { callApi } from '../api/functions'

function* handleSendContactUs(action: actions.SendContactUsAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.sendContactUs,
		(payload: actions.SendContactUsPayload) => {
			const { reCaptchaToken, contactUsRequest } = payload
			return getUserApi().contact({ pwRec: reCaptchaToken, contactUsRequest })
		})
}

export default function* (): SagaIterator {
	yield takeEvery(actions.sendContactUs.started, handleSendContactUs)
}