import { RootStoreState } from '../root'
import { Branch } from '../showrooms/types'
import { AuthToken } from './types'

/**
 * 
 * @param state Select the current auth access token from the root store state.
 */
export const authTokenSelector = (state: RootStoreState): AuthToken | undefined => state.auth.authToken

/**
 * 
 * @param state Select the current pin from the root store state.
 */
export const pinSelector = (state: RootStoreState): string | undefined => state.auth.pin

/**
 * 
 * @param state Select the username from the root store state.
 */
export const usernameSelector = (state: RootStoreState): string | undefined => state.auth.username || state.auth.email || state.auth.mobileNumber

/**
 * 
 * @param state Select the user ID from the root store state.
 */
export const userIdSelector = (state: RootStoreState): number | undefined => state.auth.userId

/**
 * 
 * @param state Select the branch filter preference from the root store state.
 */
export const branchFilterPreferenceSelector = (state: RootStoreState): boolean => state.auth.branchFilterPreferenceEnabled

/**
 * 
 * @param state Select the current selected branch from the root store state.
 */
export const branchSelector = (state: RootStoreState): Branch | undefined => {
    const branches = state.showrooms.regions.reduce<Branch[]>((data, region) => data.concat(region.branches), [])
    const selectedBranchId = state.order.branchId
    const selectedAccount = state.order.selectedAccount
    const defaultBranchId = selectedBranchId || selectedAccount?.defaultBranchId || branches[0]?.id
    const selectedBranch = branches.find(branch => branch.id === defaultBranchId)
    return selectedBranch
}