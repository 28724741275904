import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { ProductPricesResponse, ProductSku, SearchFilter, SearchRequest, ProductListType, BranchProductsResponse } from 'typescript-fetch-api'

import { PlatformProduct, ProductSearchResult } from '../../modules/platform'
import { LoadProductsResult } from '../products/actions'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Search')

/* An example action with a primitive payload. */
export interface SearchQuery {
	queryText: string
	appendToList?: boolean
}
export type SearchQueryAction = Action<SearchQuery>
export const textChangeAction = actionCreator<SearchQuery>('QUERY_TEXT_CHANGED')

export const searchCancelledAction = actionCreator('SEARCH_CANCELLED')
export const searchInProgressAction = actionCreator('SEARCH_IN_PROGRESS_ACTION')

export interface QuickSearchResponse {
	products: Array<ProductSearchResult>
	totalPagesCount?: number
	count?: number
	searchExcludesHnzProducts?: boolean
}
export type PerformQuickSearchSuccess = Action<Success<SearchQuery, QuickSearchResponse>>
export type PerformQuickSearchAction = Action<SearchQuery>
export const performQuickSearch = actionCreator.async<SearchQuery, QuickSearchResponse, Error>('PERFORM_QUICK_SEARCH')

export type FetchSearchSuggestionsAction = Action<string>
export interface FetchSearchSuggestionsResponse {
	products: ReadonlyArray<PlatformProduct>
	searchExcludesHnzProducts?: boolean
}
export const fetchSearchSuggestions = actionCreator.async<string, FetchSearchSuggestionsResponse, Error>('FETCH_SEARCH_SUGGESTIONS')

// optional param to pass through the query text
type NavigateToSearchResultsPayload = string | undefined
export type NavigateToSearchResultsAction = Action<NavigateToSearchResultsPayload>
export const navigateToSearchResults = actionCreator<NavigateToSearchResultsPayload>('NAVIGATE_TO_SEARCH_RESULTS')

/**
 * Filter actions
 */

export interface LoadSearchFiltersPayload extends SearchQuery {
	hnzProductsOnly?: boolean
	clearCategoryFilters?: boolean
}
export type LoadSearchFiltersAction = Action<LoadSearchFiltersPayload>
export const loadSearchFiltersAction = actionCreator.async<LoadSearchFiltersPayload, SearchFilter[], Error>('LOAD_SEARCH_FILTERS')

// used on mobile to apply search filters (not based on search results like on web)
export const saveSearchFilters = actionCreator<SearchFilter[]>('SAVE_SEARCH_FILTERS')

export interface FilterQueryPayload {
	queryText?: string
	categoryId?: string
	searchRequest?: SearchRequest
	page?: number
	pageSize?: number
	hnzProductsOnly?: boolean
	clearCategoryFilters?: boolean
}
export type FilterQueryAction = Action<FilterQueryPayload>
export const filterChangeAction = actionCreator<FilterQueryPayload>('FILTER_CHANGED')

export const setLoadingSearchFiltersState = actionCreator('SET_LOADING_SEARCH_FILTERS_STATE')

export type ProductsLoadedActionSuccess = Action<Success<FilterQueryPayload, LoadProductsResult>>

// have to store pagination values on redux to show the 'View More' button
export interface SearchProductsForListResponse {
	products: ReadonlyArray<PlatformProduct>
	page?: number
	totalPages?: number
}
export interface SearchProductsForListPayload {
	query: string
	page?: number
	productListType?: ProductListType
}
export type SearchProductsForListAction = Action<SearchProductsForListPayload>
export const searchProductsForList = actionCreator.async<SearchProductsForListPayload, SearchProductsForListResponse, Error>('SEARCH_PRODUCTS_FOR_LIST')

export const clearSearchedProductsForList = actionCreator<ProductListType | undefined>('CLEAR_SEARCHED_PRODUCTS_FOR_LIST')

export interface LoadSearchPricesPayload {
	skus: ProductSku[]
	customerId?: number
}
export type LoadSearchPricesAction = Action<LoadSearchPricesPayload>
export const loadSearchPrices = actionCreator.async<LoadSearchPricesPayload, ProductPricesResponse, Error>('LOAD_SEARCH_PRICES')

export const cancelSearchPrices = actionCreator('CANCEL_SEARCH_PRICES')

export interface GetSearchProductsAvailabilityPayload {
	skus: ProductSku[]
	branchId: number
}
export type GetSearchProductsAvailabilityAction = Action<GetSearchProductsAvailabilityPayload>
export const getSearchProductsAvailability = actionCreator.async<GetSearchProductsAvailabilityPayload, BranchProductsResponse, Error>('GET_SEARCH_PRODUCTS_AVAILABILITY')

export const cancelGetSearchProductsAvailability = actionCreator('CANCEL_GET_SEARCH_PRODUCTS_AVAILABILITY')