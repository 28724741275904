import { Environment } from '../../common/api/types'

export const FirebaseConfig = {
	[Environment.LOCAL]: {
		apiKey: 'AIzaSyDmyp6_hqkozGKWeIuWpH-ORIe9kPr3jKM',
		authDomain: 'plumbing-world.firebaseapp.com',
		databaseURL: 'https://plumbing-world.firebaseio.com',
		projectId: 'plumbing-world',
		storageBucket: 'plumbing-world.appspot.com',
		messagingSenderId: '598089183755',
		appId: '1:598089183755:web:dcbd04ee7c8187035b4d0e',
		measurementId: 'G-1WZS3T4WRH'
	},
	[Environment.DEV]: {
		apiKey: 'AIzaSyDmyp6_hqkozGKWeIuWpH-ORIe9kPr3jKM',
		authDomain: 'plumbing-world.firebaseapp.com',
		databaseURL: 'https://plumbing-world.firebaseio.com',
		projectId: 'plumbing-world',
		storageBucket: 'plumbing-world.appspot.com',
		messagingSenderId: '598089183755',
		appId: '1:598089183755:web:9951cae965589c7c5b4d0e',
		measurementId: 'G-T6DTT0JVSR'
	},
	[Environment.TEST]: {
		apiKey: 'AIzaSyDmyp6_hqkozGKWeIuWpH-ORIe9kPr3jKM',
		authDomain: 'plumbing-world.firebaseapp.com',
		databaseURL: 'https://plumbing-world.firebaseio.com',
		projectId: 'plumbing-world',
		storageBucket: 'plumbing-world.appspot.com',
		messagingSenderId: '598089183755',
		appId: '1:598089183755:web:826b5a38b4c60d545b4d0e',
		measurementId: 'G-SN2VSRQGBN'
	},
	[Environment.DEMO]: {
		apiKey: 'AIzaSyDmyp6_hqkozGKWeIuWpH-ORIe9kPr3jKM',
		authDomain: 'plumbing-world.firebaseapp.com',
		databaseURL: 'https://plumbing-world.firebaseio.com',
		projectId: 'plumbing-world',
		storageBucket: 'plumbing-world.appspot.com',
		messagingSenderId: '598089183755',
		appId: '1:598089183755:web:944402d5aba5c8475b4d0e',
		measurementId: 'G-2LH3XLJ3C4'
	},
	[Environment.PRODUCTION]: {
		apiKey: 'AIzaSyDmyp6_hqkozGKWeIuWpH-ORIe9kPr3jKM',
		authDomain: 'plumbing-world.firebaseapp.com',
		databaseURL: 'https://plumbing-world.firebaseio.com',
		projectId: 'plumbing-world',
		storageBucket: 'plumbing-world.appspot.com',
		messagingSenderId: '598089183755',
		appId: '1:598089183755:web:4ee069b557a36fc45b4d0e',
		measurementId: 'G-MZRQDLBK80'
	},
}
