import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { AccountPermission } from 'typescript-fetch-api'

import Component from '../components/UserProfile'
import { WebStoreState } from '../../platform/types'
import { UserAccount, getRoleForRoleId, Role } from '../../../common/accounts/types'
import { Branch } from '../../../common/showrooms/types'
import { clearEditAccountState, EditAccountRequestPayload, CreateAccountRequestPayload, editAccount, createAccount, deleteAccount, EditLoginIdPayload, editLoginId } from '../../../common/accounts/actions'
import { updateUserPassword } from '../../../common/profile/actions'
import { getSortedData } from '../../../common/util/functions'
import { setBranchFilterPreference } from '../../../common/auth/actions'
import { requestShareholdingAccess, RequestShareholdingAccessPayload } from '../../platform/actions'

export interface OwnProps {
	account?: UserAccount
	showAccountsSection: boolean
	onCancel: () => void
	onShowConfirmRemoval: (show: boolean) => void // changes the drawer title when in confirm mode
}

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	branches: Branch[]
	customerId: number
	role?: Role // the role logged in user has for currently selected business (determines which roles they can assign to the selected account)
	defaultOrderAccountBranchId: string | undefined
	permissions: AccountPermission[] // the permissions logged in user has for currently selected business
	loggedInUserMobileNumber?: string
	loggedInUserEmail?: string
	updatingProfile: boolean
	updateProfileError?: Error
	updateProfileSuccess: boolean
	updatingPassword: boolean
	updatePasswordError?: Error
	updatePasswordSuccess: boolean
	accounts?: Array<UserAccount>
	branchFilterPreferenceEnabled: boolean
	requestingShareholdingAccess: boolean
	successRequestingShareholdingAccess: boolean
	errorRequestingShareholdingAccess?: Error
}

/**
 * Interface for action callbacks that the container exposes to the component.
 */
export interface Actions {
	editAccount: (accountDetails: EditAccountRequestPayload) => void
	createAccount: (accountDetails: CreateAccountRequestPayload) => void
	deleteAccount: (accountDetails: UserAccount) => void
	clearEditAccountFlags: () => void
	updateUserPassword: (oldPassword: string, newPassword: string) => void
	editLoginId: (payload: EditLoginIdPayload) => void
	setBranchFilterPreference: (enabled: boolean) => void
	requestShareholdingAccess: (payload: RequestShareholdingAccessPayload) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: WebStoreState, ownProps: OwnProps): Props & OwnProps => {
	const userAccount = state.order.selectedAccount ? state.order.selectedAccount : undefined

	// find the role logged in user has for selected business
	const role = userAccount && userAccount.accountRoleName ? getRoleForRoleId(userAccount.accountRoleName) : undefined

	// combines all the branches in each region to a single array
	const combinedBranches: Branch[] = state.showrooms.regions.reduce<Branch[]>((data, region) => data.concat(region.branches), [])
	// sorts the branches alphabetically according to name
	const branches: Branch[] = getSortedData(combinedBranches, 'name')

	// find the default branch id for the selected order account so we can set it as default when they create a new account
	const defaultOrderAccountBranchId = userAccount && userAccount.defaultBranchId ? `${userAccount.defaultBranchId}` : undefined

	// get customer id (in case user creating a new account so cannot extract from selectedAccount)
	const customerId = userAccount ? userAccount.customerId : undefined
	// MUST have account selected on redux in order to render this screen (handled by PrivateRouteAdmin)
	if (!customerId) {
		throw Error('No user account selected')
	}

	return ({
		...ownProps,
		branches,
		customerId,
		role,
		defaultOrderAccountBranchId,
		permissions: userAccount && userAccount.permissions ? userAccount.permissions : [],
		loggedInUserMobileNumber: state.auth.mobileNumber,
		loggedInUserEmail: state.auth.email,
		updatingProfile: !!state.accounts.loadingMessageAddOrEditAdminAccount,
		updateProfileError: state.accounts.errorAddOrEditAdminAccount,
		updateProfileSuccess: state.accounts.successAddOrEditAdminAccount,
		updatingPassword: state.profile.updatingPassword,
		updatePasswordError: state.profile.updatePasswordError,
		updatePasswordSuccess: state.profile.updatePasswordSuccess,
		accounts: state.accounts.accounts,
		branchFilterPreferenceEnabled: state.auth.branchFilterPreferenceEnabled,
		requestingShareholdingAccess: state.platform.requestingShareholdingAccess,
		successRequestingShareholdingAccess: state.platform.successRequestingShareholdingAccess,
		errorRequestingShareholdingAccess: state.platform.errorRequestingShareholdingAccess,
	})
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	editAccount: (accountDetails: EditAccountRequestPayload) => {
		dispatch(editAccount.started(accountDetails))
	},
	createAccount: (accountDetails: CreateAccountRequestPayload) => {
		dispatch(createAccount.started(accountDetails))
	},
	deleteAccount: (accountDetails: UserAccount) => {
		dispatch(deleteAccount.started(accountDetails))
	},
	clearEditAccountFlags: () => {
		dispatch(clearEditAccountState())
	},
	updateUserPassword: (oldPassword: string, newPassword: string) => {
		dispatch(updateUserPassword.started({ oldPassword, newPassword }))
	},
	editLoginId: (payload: EditLoginIdPayload) => {
		dispatch(editLoginId(payload))
	},
	setBranchFilterPreference: (enabled: boolean) => {
		dispatch(setBranchFilterPreference.started(enabled))
	},
	requestShareholdingAccess: (payload: RequestShareholdingAccessPayload) => {
		dispatch(requestShareholdingAccess.started(payload))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
