import '../styles/SearchSuggestions.scss'

import * as React from 'react'
import { Popper, Fade, Paper, List, ListItem, Divider, ClickAwayListener } from '@mui/material'

import * as Container from '../containers/SearchSuggestions'
import SearchSuggestionItem from './SearchSuggestionItem'
import { PlatformProduct } from '../../platform'
import LoaderThreeDots from '../../navigation/components/LoaderThreeDots'

interface State {
	noResults: boolean
}

const INITIAL_STATE = {
	noResults: false
}

export default class SearchSuggestions extends React.PureComponent<Container.Props & Container.OwnProps & Container.Actions, State> {

	state = INITIAL_STATE

	componentDidUpdate(prevProps: Container.Props & Container.OwnProps & Container.Actions) {
		if (prevProps.loading !== this.props.loading) {
			if (this.props.loading) {
				this.props.onToggle(true) // open search suggestions to show loading state
				this.setState({ noResults: false }) // hide no results content
			} else {
				this.setState({ noResults: this.props.products.length === 0 })
			}
		}
	}

	onCloseSuggestions = () => {
		this.props.onToggle(false)
	}

	handleOnClick = (item: PlatformProduct) => {
		this.onCloseSuggestions()
		this.props.onSelectProduct(item)
	}

	handleNavigateToSearchResults = () => {
		this.onCloseSuggestions()
		this.props.onViewMore()
	}

	render() {
		const { open, searchText, products, loading, anchorElement, searchExcludesHnzProducts } = this.props

		return (
			<Popper
				open={open && !!searchText}
				anchorEl={anchorElement}
				transition={true}
				placement="bottom"
				modifiers={[{
					name: 'preventOverflow',
					enabled: true,
					options: {
						rootBoundary: 'scrollParent',
					}
				}]}
				className="search-suggestions__container"
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={this.onCloseSuggestions}>
						<Fade {...TransitionProps}>
							<Paper className="search-suggestions search-suggestions__quick-search">
								<List>
									{loading && (
										<ListItem className="search-suggestions__loading">
											<LoaderThreeDots />
										</ListItem>
									)}

									{products.map((item, index) => index < 5 && (
										<React.Fragment key={item.sku}>
											<SearchSuggestionItem
												item={item}
												onSearchSuggestionItemClick={this.handleOnClick}
											/>
											{index < products.length - 1 && <Divider />}
										</React.Fragment>
									))}

									{this.state.noResults && (
										<ListItem className="search-suggestions__empty">
											<span className="search-suggestions__text--empty-title">
												No search results found
											</span>
											<span className="search-suggestions__text--empty-body">
												Try a more generic term or a product code
											</span>
										</ListItem>
									)}

									{searchExcludesHnzProducts && (
										<ListItem>
											<span className="search-suggestions__text--message">
												The search includes products only available to Kāinga Ora accounts in Kāinga Ora mode.
											</span>
										</ListItem>
									)}

									{products.length > 5 && (
										<React.Fragment>
											<div className="search-suggestions__divider" />
											<ListItem
												className="search-suggestions__text--view-all"
												onClick={this.handleNavigateToSearchResults}
											>
												VIEW ALL PRODUCTS
											</ListItem>
										</React.Fragment>
									)}
								</List>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		)
	}
}