import { useSelector } from 'react-redux'

import { RootStoreState } from '../../root'
import { Branch, Region } from '../types'

/**
 * A hook that retrieves the branch details given a branch ID.
 *
 * @returns the branch if match is found; undefined if not
 */
const useGetBranchForId = (branchId: number): Branch | undefined => {

	// grab the regions from the store
	const regions = useSelector<RootStoreState, Region[]>(state => state.showrooms.regions)

	for (let i = 0; i < regions.length; i++) {
		// check if a branch matches the id passed
		const branch = regions[i].branches.find(item => item.id === branchId.toString())
		if (branch) {
			return branch
		}
	}

	// no matching branch found
	return undefined
}

export default useGetBranchForId
