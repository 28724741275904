import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { BackflowProductBrand, BackflowProductType, BackflowProductsResponse, BranchProductsResponse, ContactUsBackflowRequest, ProductPricesResponse, ProductSku } from 'typescript-fetch-api'

const actionCreator = actionCreatorFactory('Backflow')

/**
 * navigation
 */

export const navigateToBackflow = actionCreator('NAVIGATE_TO_BACKFLOW')

export type NavigateToBackflowBrandAction = Action<BackflowProductBrand>
export const navigateToBackflowBrand = actionCreator<BackflowProductBrand>('NAVIGATE_TO_BACKFLOW_BRAND')

export type NavigateToBackflowTypeAction = Action<BackflowProductType>
export const navigateToBackflowType = actionCreator<BackflowProductType>('NAVIGATE_TO_BACKFLOW_TYPE')

export type BackflowProductsLoadedActionSuccess = Action<Success<BackflowProductBrand | BackflowProductType, BackflowProductsResponse>>

export type FetchBackflowProductsByBrandAction = Action<BackflowProductBrand>
export const fetchBackflowProductsByBrand = actionCreator.async<BackflowProductBrand, BackflowProductsResponse, Error>('FETCH_BACKFLOW_PRODUCTS_BY_BRAND')

export type FetchBackflowProductsByTypeAction = Action<BackflowProductType>
export const fetchBackflowProductsByType = actionCreator.async<BackflowProductType, BackflowProductsResponse, Error>('FETCH_BACKFLOW_PRODUCTS_BY_TYPE')

export interface LoadBackflowProductPricesPayload {
	skus: ProductSku[]
	customerId?: number
}
export type LoadBackflowProductPricesAction = Action<LoadBackflowProductPricesPayload>
export const loadBackflowProductPrices = actionCreator.async<LoadBackflowProductPricesPayload, ProductPricesResponse, Error>('LOAD_BACKFLOW_PRODUCT_PRICES')

export const cancelLoadBackflowProductPrices = actionCreator('CANCEL_LOAD_BACKFLOW_PRODUCT_PRICES')

export interface GetBackflowProductsAvailabilityPayload {
	skus: ProductSku[]
	branchId: number
}
export type GetBackflowProductsAvailabilityAction = Action<GetBackflowProductsAvailabilityPayload>
export type GetBackflowProductsAvailabilitySuccessAction = Action<Success<GetBackflowProductsAvailabilityPayload, BranchProductsResponse>>
export const getBackflowProductsAvailability = actionCreator.async<GetBackflowProductsAvailabilityPayload, BranchProductsResponse, Error>('GET_BACKFLOW_PRODUCTS_AVAILABILITY')

export const cancelLoadBackflowProductsAvailability = actionCreator('CANCEL_LOAD_BACKFLOW_PRODUCTS_AVAILABILITY')

export const updateCartWithBackflowProduct = actionCreator('UPDATE_CART_WITH_BACKFLOW_PRODUCT')

export interface SendContactUsBackflowPayload {
	reCaptchaToken: string
	contactUsBackflowRequest: ContactUsBackflowRequest
}
export type SendContactUsBackflowAction = Action<SendContactUsBackflowPayload>
export const sendContactUsBackflow = actionCreator.async<SendContactUsBackflowPayload, Response, Error>('SEND_CONTACT_US_BACKFLOW')
