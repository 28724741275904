import '../styles/Header.scss'

import * as React from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import * as Container from '../containers/Header'
import SubheaderGeneral from './SubheaderGeneral'
import SearchToolbar from '../../../common/search/containers/SearchToolbar'
import { PriceType } from '../../../common/accounts/types'
import { getPriceType, getOrderPriceSummary } from '../../cart/functions'
import { OrderPriceSummary } from '../../cart/types'
import { Partners } from '../../integrations/types'
import ClearCartDialog from '../../cart/components/ClearCartDialog'
import { getLogo } from '../../../utils/functions'
import { AroFloArrows, KaingaOraLogo, AroFloHeader } from '../../../utils/imagesProvider'
import { DrawerType } from '../types'
import HeaderButtonPopper from './HeaderButtonPopper'

interface OwnProps {
	isAroFloSignInPage: boolean
}

interface State {
	shouldShowClearCartDialog: boolean
	shouldHideSearchSuggestions?: boolean
	hoveredItem?: string // show popper underneath hovered action button
	searchInputFocused: boolean // to hide logo when search input focused on smaller screens
}

const INITIAL_STATE: State = {
	shouldShowClearCartDialog: false,
	shouldHideSearchSuggestions: undefined,
	hoveredItem: undefined,
	searchInputFocused: false,
}

export default class Header extends React.PureComponent<Container.Props & Container.Actions & OwnProps, State> {

	state = INITIAL_STATE

	_anchorElementAccount = React.createRef<HTMLDivElement>()
	_anchorElementBranch = React.createRef<HTMLDivElement>()
	_anchorElementProfile = React.createRef<HTMLDivElement>()
	_closeTimeout?: NodeJS.Timer

	_onGoSignout = () => {
		this.setState(INITIAL_STATE) // reset all the values before signing out
		this.props.goSignout()
	}

	_onGoPartners = () => {
		this.props.goPartners(Partners.XERO)
	}

	_handleSearchSuggestionsDisplay = (isMenuOpen: boolean) => {
		// if a subheader menu is open from any of the tabs, then hide the search suggestions
		this.setState({ shouldHideSearchSuggestions: isMenuOpen ? true : undefined })
	}

	_handleSignOut = () => {
		if (this.props.cartQuantity > 0) {
			// we still have items in the cart, show dialog prompt
			this.setState({ shouldShowClearCartDialog: true })
		} else {
			this._onGoSignout()
		}
	}

	_onHideClearCartDialog = () => {
		this.setState({ shouldShowClearCartDialog: false })
	}

	_onShowBranchDrawer = () => {
		this.props.onToggleDrawer(DrawerType.BRANCH)
	}

	_onShowAccountDrawer = () => {
		this.props.onToggleDrawer(DrawerType.ACCOUNT)
	}

	_onShowProfileDrawer = () => {
		this.props.onToggleDrawer(DrawerType.PROFILE)
	}

	_onShowMenuDrawer = () => {
		this.props.onToggleDrawer(DrawerType.MENU)
	}

	_onShowCartDrawer = () => {
		this.props.onToggleDrawer(DrawerType.CART)
	}

	_handleOnMouseOver = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const { hoveredItem } = this.state
		const { id } = e.currentTarget
		// cancel any existing close timer
		if (this._closeTimeout) clearTimeout(this._closeTimeout)
		// if hovered over a different item, update state
		if (hoveredItem !== id) {
			this.setState({ hoveredItem: id })
		}
	}

	_handleOnMouseLeave = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// if no hoveredItem, do nothing
		if (!this.state.hoveredItem) return
		// start a timer to hide the popper after 3 seconds
		this._closeTimeout = setTimeout(this._handleClosePopper, 3000)
	}

	_handleClosePopper = () => {
		const { hoveredItem } = this.state
		if (hoveredItem !== undefined) {
			this.setState({ hoveredItem: undefined })
		}
	}

	_onSearchInputFocusChange = (focused: boolean) => {
		this.setState({ searchInputFocused: focused })
	}

	render() {
		const { loggedIn, cartQuantity, currentUsername, selectedAccount, orderItems, impersonatorUsername, selectedBranch, hasShareholdingAccess, isAroFloProcurementUser, isAroFloSignInPage, goContactUs, goHome, goRegions, onGoToShareholdingSite } = this.props
		const { shouldHideSearchSuggestions, shouldShowClearCartDialog, hoveredItem, searchInputFocused } = this.state
		const hasHnzAccountSelected: boolean = !!selectedAccount && !!selectedAccount.hnz
		const impersonatedUser: string | undefined = loggedIn && impersonatorUsername ? currentUsername : undefined

		// calculating price-related values
		const priceType: PriceType = getPriceType(selectedAccount)
		const { total }: OrderPriceSummary = getOrderPriceSummary(orderItems, priceType, hasHnzAccountSelected)

		return (
			<React.Fragment>
				{/* Header */}
				<div className="header__container">
					<SwitchTransition mode="out-in">
						{loggedIn && !isAroFloSignInPage ?
							<CSSTransition key="logged_in" classNames="header__transition" timeout={300}>
								<div className="header__content">
									{/* top row */}
									<div className="header__content--row">
										{/* left */}
										<ul className="header-nav-top header-nav-top--bold">
											{impersonatedUser && <li className="header-nav-top--highlight">IMPERSONATING<span className="header-nav-top--regular header-nav-top--bold">: {impersonatedUser}</span></li>}
											{selectedAccount && <li onClick={!isAroFloProcurementUser ? this._onShowAccountDrawer : undefined}><span className="header-nav-top--regular">Your Account is </span>{selectedAccount.name} {!isAroFloProcurementUser && <span className="header-nav-top--arrow">▶</span>}</li>}
											{selectedBranch && <li onClick={this._onShowBranchDrawer}><span className="header-nav-top--regular">Your Branch is </span>{selectedBranch.name} <span className="header-nav-top--arrow">▶</span></li>}
											{hasShareholdingAccess && <li onClick={onGoToShareholdingSite}><span className="header-nav-top--nzpm">my<span className="header-nav-top--em">NZPM</span> </span><span className="header-nav-top--arrow-nzpm">▶</span></li>}
										</ul>

										{/* right */}
										<ul className="header-nav-top">
											<li onClick={goRegions} className="header-nav-item-hidden">Find a branch</li>
											<li onClick={goContactUs} className="header-nav-item-hidden">Contact us</li>
											<li onClick={this._handleSignOut}>Sign out</li>
										</ul>
									</div>

									{/* mid row - icons only on small screen */}
									<div className="logo-container logo-container--middle">
										<img
											src={getLogo()}
											className="logo"
											alt="Plumbing World"
											onClick={goHome}
										/>
										{isAroFloProcurementUser && (
											<>
												<img
													src={AroFloArrows}
													className="logo logo--aroflo"
													alt=">"
												/>
												<img
													src={AroFloHeader}
													className="logo logo--aroflo"
													alt="AroFlo"
												/>
											</>
										)}
									</div>

									{/* bottom row */}
									<div className="header__content--row">
										{/* left */}
										<div className="logo-container">
											<div className="header-button header-button--menu" onClick={this._onShowMenuDrawer}>
												<div className="header-button--menu-icon">
													<div className="header-button--strip" />
													<div className="header-button--strip" />
													<div className="header-button--strip" />
												</div>
												<span className="header-button--text">Menu</span>
											</div>
											<div className="logo-container logo-container--bottom">
												<img
													src={getLogo()}
													className={`logo ${searchInputFocused && 'logo--hidden'}`}
													alt="Plumbing World"
													onClick={goHome}
												/>
												{isAroFloProcurementUser && (
													<>
														<img
															src={AroFloArrows}
															className={`logo logo--aroflo ${searchInputFocused && 'logo--hidden'}`}
															alt=">"
														/>
														<img
															src={AroFloHeader}
															className={`logo logo--aroflo ${searchInputFocused && 'logo--hidden'}`}
															alt="AroFlo"
														/>
													</>
												)}
											</div>
										</div>

										{/* right */}
										<div className="header-nav-bottom">
											<SearchToolbar shouldHideSearchSuggestions={shouldHideSearchSuggestions} onSearchInputFocusChange={this._onSearchInputFocusChange} />

											{!isAroFloProcurementUser &&
												<div id="ACCOUNT" ref={this._anchorElementAccount} className="header-button header-button--hidden" onMouseEnter={this._handleOnMouseOver} onMouseLeave={this._handleOnMouseLeave} onClick={this._onShowAccountDrawer}>
													{hasHnzAccountSelected ?
														<img src={KaingaOraLogo} alt="Kainga Ora" className="header-button--hnz" /> :
														<span className="icon-user-circle-outline header-button--icon" />}
													<span className="header-button--text">Account</span>
												</div>
											}
											<div id="BRANCH" ref={this._anchorElementBranch} className="header-button header-button--hidden" onMouseEnter={this._handleOnMouseOver} onMouseLeave={this._handleOnMouseLeave} onClick={this._onShowBranchDrawer}>
												<span className="icon-shop header-button--icon" />
												<span className="header-button--text">Branch</span>
											</div>
											{!isAroFloProcurementUser &&
												<div id="PROFILE" ref={this._anchorElementProfile} className="header-button header-button--hidden" onMouseEnter={this._handleOnMouseOver} onMouseLeave={this._handleOnMouseLeave} onClick={this._onShowProfileDrawer}>
													<span className="icon-user header-button--icon" />
													<span className="header-button--text">Profile</span>
												</div>
											}

											<div className="header-cart-items-container" onClick={this._onShowCartDrawer}>
												<span className="icon-shopping-cart header-cart-image" />
												<div className="header-cart-items-container-right">
													<span className="nav__text">{cartQuantity} ITEMS</span>
													<span className="nav__text nav__text--hightlight">${total ? total.toFixed(2) : 0.00}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</CSSTransition>
							:
							// logged out
							<CSSTransition key="logged_out" classNames="header__transition" timeout={300}>
								<div className="header__content">
									{/* top row */}
									<div className="header__content--row header__content--row__right-align">
										{/* right */}
										<ul className="header-nav-top">
											<li onClick={goRegions}>Find a branch</li>
											<li onClick={this._onGoPartners}>Partners</li>
											<li onClick={goContactUs}>Contact us</li>
										</ul>
									</div>

									{/* bottom row */}
									<div className="header__content--row">
										{/* left */}
										<img
											src={getLogo()}
											className="logo"
											alt="Plumbing World"
											onClick={goHome}
										/>
									</div>
								</div>
							</CSSTransition>
						}
					</SwitchTransition>
				</div>

				{/* Subheader */}
				{!loggedIn ? <SubheaderGeneral /> : hasHnzAccountSelected ? <div className="header__indicator--hnz" /> : undefined}

				{/* Clear Cart Dialog */}
				{shouldShowClearCartDialog && (
					<ClearCartDialog
						origin="signout"
						itemCount={orderItems.length}
						onClose={this._onHideClearCartDialog}
						onClearAll={this._onGoSignout}
					/>
				)}

				{/* Action button poppers */}
				{hoveredItem === 'ACCOUNT' &&
					<HeaderButtonPopper
						anchorElement={this._anchorElementAccount.current}
						titleText={selectedAccount ? `${selectedAccount.name}\n${selectedAccount.customerId}\n${selectedAccount.accountRoleName}` : 'No Account selected'}
						actionText={'Change your Account'}
						onActionClick={this._onShowAccountDrawer}
						onClickAway={this._handleClosePopper}
					/>
				}
				{hoveredItem === 'BRANCH' &&
					<HeaderButtonPopper
						anchorElement={this._anchorElementBranch.current}
						titleText={selectedBranch ? selectedBranch.name : 'No Branch selected'}
						actionText={'Change your Branch'}
						onActionClick={this._onShowBranchDrawer}
						onClickAway={this._handleClosePopper}
					/>
				}
				{hoveredItem === 'PROFILE' &&
					<HeaderButtonPopper
						anchorElement={this._anchorElementProfile.current}
						titleText={`Hello ${currentUsername}`}
						actionText={'Edit your Profile & Account'}
						onActionClick={this._onShowProfileDrawer}
						onClickAway={this._handleClosePopper}
					/>
				}
			</React.Fragment>
		)
	}
}