/**
 * The root saga file that defines the root saga.
 */
import { all } from 'redux-saga/effects'

/* Import module sagas */
import authSaga from '../auth/sagas'
import priceBookSaga from '../priceBook/sagas'
import productSaga from '../product/sagas'
import productsSaga from '../products/sagas'
import showroomsSaga from '../showrooms/sagas'
import labelsSaga from '../labels/sagas'
import listsSaga from '../mylists/sagas'
import searchSaga from '../search/sagas'
import orderSaga from '../order/sagas'
import remoteConfigSaga from '../remoteConfig/sagas'
import accountsSaga from '../accounts/sagas'
import profileSaga from '../profile/sagas'
import forgotPasswordSaga from '../forgotpassword/sagas'
import mobileVerificationSaga from '../mobileVerification/sagas'
import myOrdersSaga from '../myorders/sagas'
import contactUsSaga from '../contactus/sagas'
import customerOrdersSaga from '../customerorders/sagas'
import registerSaga from '../register/sagas'
import eventsSaga from '../events/sagas'
import financialsSaga from '../financials/sagas'
import platformSaga from '../../modules/platform/sagas'
import homeSaga from '../home/sagas'
import versionUpdatesSaga from '../versionUpdates/sagas'
import productFinderSaga from '../productfinder/sagas'
import backflowSaga from '../backflow/sagas'

/** The root saga that starts all of the other sagas. */
export default function* rootSaga() {
	yield all([
		authSaga(),
		priceBookSaga(),
		productSaga(),
		productsSaga(),
		showroomsSaga(),
		labelsSaga(),
		listsSaga(),
		searchSaga(),
		orderSaga(),
		remoteConfigSaga(),
		accountsSaga(),
		profileSaga(),
		forgotPasswordSaga(),
		mobileVerificationSaga(),
		myOrdersSaga(),
		contactUsSaga(),
		customerOrdersSaga(),
		registerSaga(),
		eventsSaga(),
		financialsSaga(),
		platformSaga(),
		homeSaga(),
		versionUpdatesSaga(),
		productFinderSaga(),
		backflowSaga(),
	])
}
