
/**
 * Defines the paths for all screens.
 */
export enum Paths {
	HOME = '/',
	SIGN_IN = '/signin',

	SEARCH = '/search',
	PRODUCTS_BASE = '/products',
	PRODUCTS = '/products/:id/:name',
	PRODUCTS_SUB = '/products/:id/:name/:sid/:sname',
	PRODUCTS_SUB_SUB = '/products/:id/:name/:sid/:sname/:ssid/:ssname',
	PRODUCT = '/product',
	PRODUCT_DETAILS = '/product/:id',
	PRODUCTS_HNZ = '/products/kāinga-ora',
	PRODUCTS_HNZ_SUB = '/products/kāinga-ora/:id/:name',

	HWC_PRODUCT_FINDER = '/product-finder/hwc',
	PRODUCT_FINDER_RESULTS = '/product-finder/results',

	BACKFLOW_LANDING = '/backflow-water-protection',
	BACKFLOW_BRAND = '/backflow-water-protection/brand/:id',
	BACKFLOW_PRODUCT_TYPE = '/backflow-water-protection/type/:id',

	ORDERS = '/orders/*',
	CUSTOMER_QUOTES = '/orders/quotes',
	CUSTOMER_QUOTE_DETAILS = '/orders/quotes/:id/:suffix',
	CUSTOMER_ORDERS = '/orders/current-orders',
	CUSTOMER_ORDERS_RELATIVE = 'current-orders',
	CUSTOMER_ORDER_DETAILS = '/orders/current-orders/:id/:suffix',
	INTERIM_ORDERS = '/orders/interim-orders',
	INTERIM_ORDERS_RELATIVE = 'interim-orders',
	INTERIM_ORDER_DETAILS = '/orders/interim-orders/:id/:suffix',
	LABEL_ORDERS = '/orders/labels',
	LABEL_ORDERS_RELATIVE = 'labels',
	LABEL_ORDER_DETAILS = '/orders/labels/:id',
	CHECKOUT = '/checkout',
	LABELS_CHECKOUT = '/labels/checkout',

	LISTS = '/lists',
	LIST_GROUP = '/lists/:id',
	LIST_DETAILS = '/lists/:id/:listId',
	LABELS = '/labels',
	LABEL_DETAILS = '/labels/:id',

	REGISTER = '/register',
	REGISTER_CONFIRM_EMAIL = '/register/confirmEmail',
	REGISTER_CONFIRM_PHONE = '/register/confirmPhone',
	CONFIRM_RESET_PASSWORD = '/register/confirmResetPassword',
	RESET_PASSWORD = '/resetPassword',
	VERIFY_ACCOUNT = '/verifyAccount',
	PROFILE = '/profile',
	ADD_ACCOUNT = '/accounts/add',

	ADMIN = '/admin/*',
	ADMIN_INTEGRATIONS = '/admin/integrations',
	ADMIN_INTEGRATIONS_SUBSCREEN_RELATIVE = 'integrations/*',
	ADMIN_INTEGRATIONS_XERO = '/admin/integrations/xero',
	ADMIN_INTEGRATIONS_XERO_RELATIVE = 'xero',
	ADMIN_INTEGRATIONS_FERGUS = '/admin/integrations/fergus',
	ADMIN_INTEGRATIONS_FERGUS_RELATIVE = 'fergus',
	ADMIN_INTEGRATIONS_SIMPRO = '/admin/integrations/simpro',
	ADMIN_INTEGRATIONS_SIMPRO_RELATIVE = 'simpro',
	ADMIN_INTEGRATIONS_AROFLO = '/admin/integrations/aroflo',
	ADMIN_INTEGRATIONS_AROFLO_RELATIVE = 'aroflo',
	ADMIN_INTEGRATIONS_ITRADE = '/admin/integrations/itrade',
	ADMIN_INTEGRATIONS_ITRADE_RELATIVE = 'itrade',
	ADMIN_PRICE_BOOK = '/admin/price-book',
	ADMIN_PRICE_BOOK_RELATIVE = 'price-book',
	IMPERSONATE = '/impersonate',

	FINANCIALS = '/financials/:screen/*',
	FINANCIALS_RELATIVE = 'financials',
	FINANCIALS_INVOICES = '/financials/invoices',
	FINANCIALS_STATEMENTS = '/financials/statements',
	FINANCIALS_ACCOUNT_BALANCE = '/financials/account-balance',

	VERSION_UPDATES = '/version-updates',
	TERMS_AND_CONDITIONS = '/terms-and-conditions',
	TERMS_OF_TRADE = '/terms-of-trade',
	PRIVACY_POLICY = '/privacy-policy',
	REGIONS = '/regions',
	CONTACT = '/contact',
	XERO = '/xero',
	SIMPRO = '/simpro',
	AROFLO_PUNCHOUT = '/punchout/oci/aroflo',
}
