import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

import Component from '../components/BasicUserProfile'
import { WebStoreState } from '../../platform/types'
import { updateUserPassword } from '../../../common/profile/actions'
import { BasicUserProfile } from '../types'
import { requestShareholdingAccess, RequestShareholdingAccessPayload } from '../../platform/actions'

export interface OwnProps {
	account: BasicUserProfile
	onCancel: () => void
}

export interface Props {
	updatingPassword: boolean
	updatePasswordError?: Error
	updatePasswordSuccess: boolean
	requestingShareholdingAccess: boolean
	successRequestingShareholdingAccess: boolean
	errorRequestingShareholdingAccess?: Error
}

/**
 * Interface for action callbacks that the container exposes to the component.
 */
export interface Actions {
	updateUserPassword: (oldPassword: string, newPassword: string) => void
	requestShareholdingAccess: (payload: RequestShareholdingAccessPayload) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: WebStoreState, ownProps: OwnProps): Props => {
	return ({
		updatingPassword: state.profile.updatingPassword,
		updatePasswordError: state.profile.updatePasswordError,
		updatePasswordSuccess: state.profile.updatePasswordSuccess,
		requestingShareholdingAccess: state.platform.requestingShareholdingAccess,
		successRequestingShareholdingAccess: state.platform.successRequestingShareholdingAccess,
		errorRequestingShareholdingAccess: state.platform.errorRequestingShareholdingAccess,
	})
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	updateUserPassword: (oldPassword: string, newPassword: string) => {
		dispatch(updateUserPassword.started({ oldPassword, newPassword }))
	},
	requestShareholdingAccess: (payload: RequestShareholdingAccessPayload) => {
		dispatch(requestShareholdingAccess.started(payload))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
