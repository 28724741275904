import { PaymentMethodContent, PaymentResultEnum } from './types'

export const PAYMENT_METHODS: Record<'store' | 'online' | 'recurring', PaymentMethodContent> = {
	'store': { title: 'In Store', subtitle: 'Eftpos | Credit', icon: 'icon-storefront' },
	'online': { title: 'Online', subtitle: 'Bank Deposits ', icon: 'icon-devices' },
	'recurring': { title: 'Recurring Payments', subtitle: 'Credit', icon: 'icon-recurring-payment' },
}

export const ADTEAM_EMAIL = 'adteam@plumbingworld.co.nz'

export const RECURRING_PAYMENTS_URL = 'http://images2.plumbingworld.co.nz/Forms/Recurring%20Payments.pdf'

export const PAYMENT_CALLBACK_BASE_PATH = 'financials/account-balance/payment/'
export const PAYMENT_CALLBACK_URL_SUCCESS = PAYMENT_CALLBACK_BASE_PATH + PaymentResultEnum.SUCCESS
export const PAYMENT_CALLBACK_URL_FAIL = PAYMENT_CALLBACK_BASE_PATH + PaymentResultEnum.FAIL
export const PAYMENT_CALLBACK_URL_CANCEL = PAYMENT_CALLBACK_BASE_PATH + PaymentResultEnum.CANCEL
