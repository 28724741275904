import { SagaIterator } from 'redux-saga'
import { takeEvery, select, call, put } from 'redux-saga/effects'
import { GetSearchFiltersWithFiltersAppliedRequest, SearchFilterFilterTypeEnum, SearchProductsRequest, SearchRequest } from 'typescript-fetch-api'

import * as actions from '../../common/productfinder/actions'
import { savedProductFinderFiltersSelector } from '../../common/productfinder/selectors'
import { UserAccount } from '../../common/accounts/types'
import { selectedAccountSelector } from '../../common/order/selectors'
import { getPriceParams } from '../../common/auth/functions'
import { getContentApi } from '../../common/api'
import { callApi } from '../../common/api/functions'
import { paginationSizeSelector } from '../platform/selectors'
import { INITIAL_PAGE } from '../platform/content'
import * as NavigationManager from '../navigation/NavigationManager'
import { FilterQueryAction, filterChangeAction } from '../../common/search/actions'
import { Paths } from '../navigation/types'
import { HEIGHT_ATTRIBUTE_NAME, LITRES_ATTRIBUTE_NAME, ProductFinderType, WIDTH_ATTRIBUTE_NAME } from '../../common/productfinder/types'

function* loadProducts(action: actions.LoadSubcategoriesAction): SagaIterator {
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId } = getPriceParams(selectedAccount)
	const pageSize: number = yield select(paginationSizeSelector)

	const location = NavigationManager.getLocation()
	const pathname = location?.pathname || ''
	const search = location?.search || ''
	const isHWCFinder = pathname.includes(Paths.PRODUCT_FINDER_RESULTS) && search.includes(ProductFinderType.HWC.toString())

	const updatedSearchRequest: SearchRequest | undefined = action.payload.searchRequest?.filters ? { ...action.payload.searchRequest, filters: [...action.payload.searchRequest.filters] } : undefined
	if (isHWCFinder && updatedSearchRequest?.filters) {
		// to ensure we only get back HWC products we need to add dimension filters, even if the user has not saved any
		let minWidth, maxWidth, minHeight, maxHeight, minLitres, maxLitres
		updatedSearchRequest.filters.forEach(filter => {
			if (filter.filterType === SearchFilterFilterTypeEnum.ATTRIBUTE) {
				if (filter.name === LITRES_ATTRIBUTE_NAME && filter.id === 'MIN') minLitres = true
				else if (filter.name === LITRES_ATTRIBUTE_NAME && filter.id === 'MAX') maxLitres = true
				else if (filter.name === WIDTH_ATTRIBUTE_NAME && filter.id === 'MIN') minWidth = true
				else if (filter.name === WIDTH_ATTRIBUTE_NAME && filter.id === 'MAX') maxWidth = true
				else if (filter.name === HEIGHT_ATTRIBUTE_NAME && filter.id === 'MIN') minHeight = true
				else if (filter.name === HEIGHT_ATTRIBUTE_NAME && filter.id === 'MAX') maxHeight = true
			}
		})
		if (minWidth !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: WIDTH_ATTRIBUTE_NAME, id: 'MIN', value: '0', })
		if (maxWidth !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: WIDTH_ATTRIBUTE_NAME, id: 'MAX', value: '10000', })
		if (minHeight !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: HEIGHT_ATTRIBUTE_NAME, id: 'MIN', value: '0', })
		if (maxHeight !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: HEIGHT_ATTRIBUTE_NAME, id: 'MAX', value: '10000', })
		if (minLitres !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: LITRES_ATTRIBUTE_NAME, id: 'MIN', value: '0', })
		if (maxLitres !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: LITRES_ATTRIBUTE_NAME, id: 'MAX', value: '10000', })
	}

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadProductFinderProducts,
		(payload: actions.LoadProductFinderProductsPayload) => {
			const requestParameters: SearchProductsRequest = {
				categoryId: payload.categoryId,
				page: payload.page || INITIAL_PAGE,
				pageSize,
				customerId,
				// don't include pricing or stock count to improve loading times (we load them asynchronously after the page of results gets back)
				// includePrices,
				// branchId: selectedBranchId,
				searchRequest: updatedSearchRequest,
			}
			return getContentApi().searchProducts(requestParameters)
				.then(response => ({ products: response.products || [], totalProductPagesCount: response.totalPagesCount, totalProductsCount: response.count }))
		})
}

function* loadFiltersForProducts(action: actions.LoadSubcategoriesAction): SagaIterator {
	// update store flag to know when fetching product filters
	yield put(actions.setLoadingProductFinderFiltersState())

	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId } = getPriceParams(selectedAccount)

	let branchId: number | undefined
	const branchFilter = action.payload.searchRequest?.filters?.find(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH)
	if (branchFilter && branchFilter.id) {
		branchId = parseInt(branchFilter.id, 10)
	}
	const goldProductsOnly: boolean | undefined = action.payload.searchRequest?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.GOLD)


	const location = NavigationManager.getLocation()
	const pathname = location?.pathname || ''
	const search = location?.search || ''
	const isHWCFinder = pathname.includes(Paths.PRODUCT_FINDER_RESULTS) && search.includes(ProductFinderType.HWC.toString())

	// remove attribute filters because we don't want to limit filters in the response (eg if Mains Pressure is initially applied, only Mains Pressure products are returned)
	let searchRequest: SearchRequest | undefined = action.payload.searchRequest
	if (isHWCFinder && searchRequest?.filters) {
		searchRequest = {
			filters: searchRequest.filters.filter(item =>
				item.filterType !== SearchFilterFilterTypeEnum.ATTRIBUTE
			)
		}
		// we want to have at least one dimension attribute filter applied to ensure only HWC products are returned
		searchRequest.filters?.push({
			filterType: SearchFilterFilterTypeEnum.ATTRIBUTE,
			name: LITRES_ATTRIBUTE_NAME,
			id: 'MIN',
			value: '0',
		})
		searchRequest.filters?.push({
			filterType: SearchFilterFilterTypeEnum.ATTRIBUTE,
			name: LITRES_ATTRIBUTE_NAME,
			id: 'MAX',
			value: '1000',
		})
	}

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadProductFinderFilters,
		(payload: actions.LoadProductFinderProductsPayload) => {
			const requestParameters: GetSearchFiltersWithFiltersAppliedRequest = {
				categoryId: payload.categoryId,
				customerId,
				branchId,
				goldProductsOnly,
				includeAttributes: payload.includeAttributes,
				searchRequest,
			}
			return getContentApi().getSearchFiltersWithFiltersApplied(requestParameters)
				.then(response => response.filters || [])
		})
}

function* reloadProductFiltersOnBranchUpdate(action: FilterQueryAction): SagaIterator {
	const location = NavigationManager.getLocation()
	const pathname = location?.pathname || ''
	const search = location?.search || ''
	const isProductFinderPath = pathname.includes(Paths.PRODUCT_FINDER_RESULTS)
	if (!isProductFinderPath) return
	const includeAttributes = search.includes(ProductFinderType.HWC.toString()) // include attributes for HWC only

	const savedFilters: actions.ProductFilter | undefined = yield select(savedProductFinderFiltersSelector)
	const searchFilters = action.payload.searchRequest?.filters

	const categoryId = action.payload.categoryId
	if (categoryId) {
		// check if the user enabled/disabled the branch or gold products filter
		const newBranchFilter = searchFilters?.find(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH)
		const hasEnabledBranchFilter = !savedFilters?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH) && !!newBranchFilter
		const hasDisabledBranchFilter = !!savedFilters?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH) && !newBranchFilter
		const newGoldProductsFilter = searchFilters?.find(item => item.filterType === SearchFilterFilterTypeEnum.GOLD)
		const hasEnabledGoldProductsFilter = !savedFilters?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.GOLD) && !!newGoldProductsFilter
		const hasDisabledGoldProductsFilter = !!savedFilters?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.GOLD) && !newGoldProductsFilter
		if (hasEnabledBranchFilter || hasDisabledBranchFilter || hasEnabledGoldProductsFilter || hasDisabledGoldProductsFilter) {
			yield call(loadFiltersForProducts, {
				type: actions.loadProductFinderProducts.started.type,
				payload: {
					categoryId,
					searchRequest: action.payload.searchRequest,
					includeAttributes,
				},
			})
		}
	}
}

function handleNavigateToProductFinder(action: actions.NavigateToProductFinderAction) {
	// gold products go straight to finder results
	if (action.payload === ProductFinderType.GOLD) {
		NavigationManager.navigateToProductFinderResults(action.payload)
	} else {
		NavigationManager.navigateToProductFinder(action.payload)
	}
}

function handleNavigateToProductFinderResults(action: actions.NavigateToProductFinderResultsAction) {
	NavigationManager.navigateToProductFinderResults(action.payload)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.loadProductFinderProducts.started, loadProducts)
	yield takeEvery(actions.loadProductFinderProducts.started, loadFiltersForProducts)
	yield takeEvery(actions.loadProductFinderFilters.started, loadFiltersForProducts)

	yield takeEvery(actions.navigateToProductFinder, handleNavigateToProductFinder)
	yield takeEvery(actions.navigateToProductFinderResults, handleNavigateToProductFinderResults)

	yield takeEvery(filterChangeAction, reloadProductFiltersOnBranchUpdate)
}