import { Category as CategoryApi } from 'typescript-fetch-api'

import { Category } from '../../common/categories/types'

export const formatCategoryResponse = (categories: Array<CategoryApi>): Category[] => {
	return categories
		.map(category => ({
			id: category.categoryId,
			name: category.categoryName,
			parentCategory: undefined,
			productCount: category.productCount || 0,
		}))
		.sort((a, b) => a.name && b.name ? (a.name > b.name ? 1 : b.name > a.name ? -1 : 0) : 0)
}