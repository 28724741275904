import '../styles/ListCategoryInput.scss'

import React, { useEffect, useState } from 'react'
import { TextField, Autocomplete, AutocompleteValue, createFilterOptions, AutocompleteChangeReason } from '@mui/material'

import { UserAccount } from '../../../common/accounts/types'
import { ListCategory } from '../../../common/mylists/types'
import * as listFunctions from '../../../common/mylists/functions'
import useGetListCategories from '../../../common/mylists/hooks/useGetListCategories'

interface Props {
	listCategory?: ListCategory
	selectedAccount?: UserAccount
	showExistingLists?: boolean // flag to show list categories that have lists (for adding to an existing list)
	hnzOnly?: boolean
	error?: boolean
	onOptionSelected: (selectedListCategory?: ListCategory) => void
}

/**
 * Gets the label to be displayed on the input given the existing fields on a list category.
 * @param listCategory the list category
 * @returns the label
 */
const getInputLabel = (listCategory: ListCategory | undefined): string => {
	if (listCategory) {
		if (listCategory.name && listCategory.customerId) return `${listCategory.name} - ${listCategory.customerId}`
		else if (listCategory.name) return listCategory.name
	}
	return ''
}

const ListCategoryInput = (props: Props): JSX.Element => {

	/**
	 * Store State
	 */

	const isAdminOrPrincipal = listFunctions.isAdminOrPrincipal(props.selectedAccount)
	const listCategories = useGetListCategories({
		hnzMode: props.selectedAccount && props.selectedAccount.hnz,
		hnzOnly: props.hnzOnly,
		showExistingLists: props.showExistingLists,
	})

	/**
	 * Local State
	 */

	const [inputValue, setInputValue] = useState<string>(getInputLabel(props.listCategory))

	/**
	 * Effects / Subscriptions
	 */

	/**
	 * Effect that sets the list category by default to the USERS_LISTS if the account selected only has a Trade role.
	 */
	useEffect(() => {
		if (!isAdminOrPrincipal) {
			const userList = listCategories.find(item => !item.customerId)
			if (userList) {
				props.onOptionSelected(userList)
				if (!inputValue) {
					// also update the content shown on the input so it matches the selected option
					// NOTE: this is needed since we control updates for the input value 
					setInputValue(getInputLabel(userList))
				}
			}
		}
		// missing dependency: 'props'
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAdminOrPrincipal])

	/**
	 * Local Functions
	 */

	const _filterOptions = createFilterOptions<ListCategory | null>({
		stringify: option => {
			if (option) {
				if (option.customerId && option.name) return option.customerId + option.name
				else if (option.customerId) return `${option.customerId}`
				else if (option.name) return option.name
			}
			return ''
		},
	})

	return (
		<Autocomplete<ListCategory | null, false, false, false>
			key={inputValue}
			inputValue={inputValue}
			value={props.listCategory}
			options={listCategories}
			getOptionLabel={option => getInputLabel(option || undefined)}
			autoComplete={true}
			filterOptions={_filterOptions}
			renderInput={(params) => (
				<TextField
					{...params}
					label="List Category"
					variant="outlined"
					inputProps={{
						...params.inputProps,
						autoComplete: 'off',
					}}
					error={props.error}
				/>
			)}
			onChange={(_: React.ChangeEvent<{}>, value: AutocompleteValue<ListCategory | null, false, false, true>, reason: AutocompleteChangeReason) => {
				if (reason === 'selectOption') {
					props.onOptionSelected(value as ListCategory)
				} else if (reason === 'clear') {
					props.onOptionSelected(undefined)
				}
			}}
			onInputChange={(_: React.ChangeEvent<{}>, value: string) => {
				setInputValue(value)
			}}
			isOptionEqualToValue={(option: ListCategory | null, value: ListCategory | null) => option === value}
			renderOption={(optionProps: React.HTMLAttributes<HTMLLIElement>, option: ListCategory | null) => {
				if (!option) return undefined
				const { className, ...rest } = optionProps
				return (
					<li className={`${className} list-category-input__option`} {...rest}>
						<p className="list-category-input__text--title">{option.name}</p>
						<p className="list-category-input__text">{option.customerId}</p>
					</li>
				)
			}}
			disabled={listCategories.length === 0}
			popupIcon={<span className="icon-keyboard_arrow_down list-category-input__icon" />}
			className="list-category-input"
		/>
	)
}

export default ListCategoryInput