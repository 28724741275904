import { Location } from 'react-router-dom'

import * as OrderComplete from '../../common/cart/containers/OrderComplete'
import * as Confirm from '../../common/cart/containers/Confirm'
import * as Pin from '../../common/cart/containers/Pin'
import { Order, OrderItem } from '../../common/order/types'
import { PIN_TYPE } from '../../common/cart/types'
import { UserAccount } from '../../common/accounts/types'

/**
 * ORDER COMPLETE
 */

export interface OrderCompleteNavigationState {
	order: Order
	customerId?: number
}

export interface OrderCompleteNavigationProps extends OrderCompleteNavigationState { }

export function extractOrderCompleteNavProps(props: OrderCompleteNavigationProps): OrderComplete.OwnPropInfo {
	return props
}

export function extractOrderCompleteNavigationState(location: Location): OrderComplete.OwnPropInfo {
	const state = location.state as OrderCompleteNavigationState
	if (state) {
		return {
			order: state.order,
			customerId: state.customerId,
		}
	}
	return {
		order: { orderItems: [] } as unknown as Order, // OrderComplete.tsx requires the Order to have orderItems array (even if its empty),
		customerId: undefined,
	}
}

/**
 * PIN
 */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PinNavigationProps { }

export function extractPinNavProps(props: PinNavigationProps): Pin.OwnPropInfo {
	return {
		type: PIN_TYPE.SET, // temporarily setting as default `type` value
		goCheckoutAfter: false
	}
}

/**
 * CONFIRM
 */

export interface ConfirmNavigationState {
	orderItems?: OrderItem[]
	selectedAccount?: UserAccount
}

export interface ConfirmNavigationProps extends ConfirmNavigationState { }

export function extractConfirmNavProps(props: ConfirmNavigationProps): Confirm.OwnPropInfo {
	return {
		items: props.orderItems,
		selectedAccount: props.selectedAccount,
	}
}

/**
 * We pass through the order items and selected account via the router so we can preserve the values even when a user decides to change the selected account from the subheader.
 * This is a web-only case since users can only change their account from the profile or the checkout form screen.
 */
export function extractConfirmNavigationState(location: Location): Confirm.OwnPropInfo {
	const state = location.state as ConfirmNavigationState
	if (state) {
		return {
			items: state.orderItems,
			selectedAccount: state.selectedAccount,
		}
	}
	return {}
}

/**
 * Checkout
 */

export interface CheckoutNavigationState {
	checkoutMethod?: 'simpro',
}

export interface CheckoutNavigationProps {
	location: Location
}

export function extractCheckoutNavProps(props: CheckoutNavigationProps): CheckoutNavigationState {
	return props.location.state || {}
}

/**
 * OTHER TYPES
 */

// defines the actions in the checkout stepper
export enum CheckoutPath {
	OrderDetails = 'details',
	Confirm = 'confirm',
	Complete = 'complete',
}

export interface OrderPriceSummary {
	subtotal: number
	gst: number
	total: number
}

export interface CheckoutStep {
	id: number
	title: string
	path: CheckoutPath | undefined
}

export interface PlaceType {
	description: string
	structured_formatting: {
		main_text: string
		secondary_text: string
	}
}

export enum OrderFormErrors {
	REQUIRED_ORDER_NUMBER = 'An order number is required',
	REQUIRED_DESPATCH_DATE = 'A date is required',
	INVALID_DATE = 'Invalid date',
	REQUIRED_RECIPIENT = 'A recipient name is required',
	REQUIRED_CONTACT_NUMBER = 'A contact number is required',
	REQUIRED_ADDRESS = 'A street address is required',
	REQUIRED_CITY = 'A city is required',
	INVALID_ACCOUNT_PERMISSION = 'Invalid account, no permissions to create order',
	INVALID_ORDER_NUMBER = 'Invalid order number',
	INVALID_NOTES = 'Invalid notes',
}
