import { ProductList, ProductListGroup } from 'typescript-fetch-api'

import { RootStoreState } from '../root'

/**
 * Retrieves the account lists from the store.
 * @param state the store state
 */
export const accountListsSelector = (state: RootStoreState): ProductListGroup[] => state.lists.lists

/**
 * Retrieves a list from the store given an ID
 * @param listId the list ID
 * @returns the list, if found
 */
export const listSelector = (listId: string) => (state: RootStoreState): ProductList | undefined => {
    for (const group of state.lists.lists) {
        const matchingList = group.lists?.find(list => list.id === listId)
        if (matchingList) return matchingList
    }
    return undefined
}