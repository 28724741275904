import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

import Component from '../components/HnzCartDialog'
import { removeProductFromCart } from '../../../common/cart/actions'
import { OrderItem } from '../../../common/order/types'

export interface OwnProps {
	hnzOnlyOrderItems: OrderItem[]
	onConfirm: () => void
	onCancel: () => void
}

/**
 * Interface for action callbacks that the container exposes to the component.
 */
export interface Actions {
	onRemoveProductFromCart: (item: OrderItem) => void
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onRemoveProductFromCart: (item: OrderItem) => {
		dispatch(removeProductFromCart(item))
	},
})

export default connect(undefined, mapDispatchToProps)(Component)
