import { SagaIterator } from 'redux-saga'
import { takeEvery, call } from 'redux-saga/effects'
import { CarouselPlatform, GetMobileAppVersionsRequest, GetWebAppVersionsRequest } from 'typescript-fetch-api'

import { getMetadataApi } from '../../common/api'
import { callApi } from '../../common/api/functions'
import * as actions from './actions'

function* handleGetVersionUpdates(action: actions.FetchVersionUpdatesAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchingVersionUpdates,
		(payload: actions.FetchVersionUpdatesPayload) => {
			const requestParameters: GetMobileAppVersionsRequest | GetWebAppVersionsRequest = {
				page: payload.page,
				pageSize: payload.pageSize,
			}
			if (payload.platform === CarouselPlatform.WEB) return getMetadataApi().getWebAppVersions(requestParameters)
			return getMetadataApi().getMobileAppVersions(requestParameters)
		}
	)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.fetchingVersionUpdates.started, handleGetVersionUpdates)
}
