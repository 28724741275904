import { SagaIterator } from 'redux-saga'
import { takeEvery, call } from 'redux-saga/effects'
import { CreateAroFloProcurementUserRequest, DeleteAroFloPunchoutUserRequest, GetAroFloPunchoutUserRequest, GetAroFloSubscriptionRequest, GetFergusSubscriptionRequest, GetScheduledSubscriptionRequest, GetSimproTokenGrantTypeEnum, ResetAroFloPunchoutUserRequest, UpdateAroFloSubscriptionOperationRequest, UpdateScheduledSubscriptionOperationRequest, UpdateXeroSubscriptionRequest } from 'typescript-fetch-api'

import * as actions from './actions'
import { getUserApi } from '../../common/api'
import { callApi } from '../../common/api/functions'
import * as NavigationManager from '../navigation/NavigationManager'

function* connectIntegration(action: actions.IntegrationAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.connectIntegration, () => {
		const { customerId, integrationCompany, integrationAction, companyUrl } = action.payload
		return getUserApi().getIntegrationLink({
			customerId, integrationCompany, action: integrationAction, companyUrl
		})
	})
}

function openIntegrationLink(action: actions.ConnectIntegrationSuccessAction) {
	const { link } = action.payload.result
	if (link) {
		// open the link in current tab
		const linkToOpen = window.open(link, '_self')
		if (linkToOpen) {
			linkToOpen.focus()
		}
	}
}

function* updateIntegrationStatus(action: actions.IntegrationAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.updateIntegrationStatus, () => {
		const { customerId, integrationCompany, integrationAction } = action.payload
		return getUserApi().updateIntegrationStatus({
			customerId, integrationCompany, action: integrationAction
		})
	})
}

function* getXeroSubscription(action: actions.GetXeroSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getXeroSubscription, (payload: actions.GetXeroSubscriptionPayload) => {
		return getUserApi().getXeroSubscription({ customerId: payload.customerId })
	})
}

function* updateXeroSubscription(action: actions.UpdateXeroSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.updateXeroSubscription, (payload: UpdateXeroSubscriptionRequest) => {
		return getUserApi().updateXeroSubscription({
			customerId: payload.customerId, updateXeroSubscriptionRequest: payload
		})
	})
}

function* getXeroConnections(action: actions.GetXeroConnectionsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getXeroConnections, (payload: actions.GetXeroConnectionsPayload) => {
		const { customerId, grantType, value } = payload
		return getUserApi().getXeroConnections({ customerId, grantType, value })
	})
}

function* getXeroContacts(action: actions.GetXeroContactsOrAccountsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getXeroContacts, (payload: actions.GetXeroContactsOrAccountsPayload) => {
		const { accessToken, tenantId, customerId } = payload
		return getUserApi().getXeroContacts({ customerId, accessToken, tenantId })
	})
}

function* getXeroAccounts(action: actions.GetXeroContactsOrAccountsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getXeroAccounts, (payload: actions.GetXeroContactsOrAccountsPayload) => {
		const { accessToken, tenantId, customerId } = payload
		return getUserApi().getXeroAccounts({ customerId, accessToken, tenantId })
	})
}

function* getSimproSubscription(action: actions.GetSimproSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getSimproSubscription, ({ customerId, type }: actions.GetSimproSubscriptionPayload) => {
		return getUserApi().getSimproSubscription({ customerId, type })
	})
}

function* updateSimproSubscription(action: actions.UpdateSimproSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.updateSimproSubscription, ({ customerId, accessToken, refreshToken, companyId, companyUrl, orderNumberFormat, vendorId, isSubscribed, jobStages, catalogueId, itemise }: actions.UpdateSimproSubscriptionPayload) => {
		const vendorIdNumber = Number(vendorId)
		return getUserApi().updateSimproSubscription({ customerId, updateSimproSubscriptionRequest: { customerId, accessToken, refreshToken, companyId, companyUrl, orderNumberFormat, vendorId: vendorIdNumber, isSubscribed, jobStages, catalogueId, itemise } })
	})
}

function* getSimproAccessToken(action: actions.GetSimproAccessTokenAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getSimproAccessToken, (payload: actions.GetSimproAccessTokenPayload) => {
		const { customerId, code, companyUrl } = payload
		return getUserApi().getSimproToken({ customerId, grantType: GetSimproTokenGrantTypeEnum.AUTHORIZATION_CODE, value: code, companyUrl })
	})
}

function* getSimproCompanies(action: actions.GetSimproCompaniesAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getSimproCompanies, (payload: actions.GetSimproCompaniesPayload) => {
		const { customerId, accessToken, companyUrl } = payload
		return getUserApi().getSimproCompanies({ customerId, accessToken, companyUrl })
	})
}

function* getSimproVendors(action: actions.GetSimproVendorsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getSimproVendors, (payload: actions.GetSimproVendorsPayload) => {
		const { customerId, accessToken, companyUrl, companyId, searchTerm } = payload
		return getUserApi().getSimproVendors({ customerId, accessToken, companyUrl, companyId, searchTerm })
	})
}

function* getSimproOrderNumberFormats(action: actions.GetSimproOrderNumberFormatsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getSimproOrderNumberFormats, (payload: actions.GetSimproOrderNumberFormatsPayload) => {
		const { customerId } = payload
		return getUserApi().getSimproOrderNumberFormats({ customerId })
	})
}

function* getSimproCatalogues(action: actions.GetSimproCataloguesAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getSimproCatalogues, (payload: actions.GetSimproCataloguesPayload) => {
		return getUserApi().getSimproCatalogues({ ...payload })
	})
}

function* getAroFloSubscription(action: actions.GetAroFloSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getAroFloSubscription, (payload: GetAroFloSubscriptionRequest) => {
		return getUserApi().getAroFloSubscription(payload)
	})
}

function* updateAroFloSubscription(action: actions.UpdateAroFloSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.updateAroFloSubscription, (payload: UpdateAroFloSubscriptionOperationRequest) => {
		return getUserApi().updateAroFloSubscription(payload)
	})
}

function* getAroFloCatalogueUser(action: actions.GetAroFloCatalogueUserAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getAroFloCatalogueUser, (payload: GetAroFloPunchoutUserRequest) => {
		return getUserApi().getAroFloPunchoutUser(payload)
			.catch((error: Response) => {
				// if user does not exist, return undefined instead of error
				if (error.status === 404) {
					return undefined
				}
				throw error
			})
	})
}

function* createAroFloCatalogueUser(action: actions.CreateAroFloCatalogueUserAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.createAroFloCatalogueUser, (payload: CreateAroFloProcurementUserRequest) => {
		return getUserApi().createAroFloProcurementUser(payload)
	})
}

function* resetAroFloCatalogueUser(action: actions.ResetAroFloCatalogueUserAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.resetAroFloCatalogueUser, (payload: ResetAroFloPunchoutUserRequest) => {
		return getUserApi().resetAroFloPunchoutUser(payload)
	})
}

function* deleteAroFloCatalogueUser(action: actions.DeleteAroFloCatalogueUserAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.deleteAroFloCatalogueUser, (payload: DeleteAroFloPunchoutUserRequest) => {
		return getUserApi().deleteAroFloPunchoutUser(payload)
	})
}

function* getITradeSubscription(action: actions.GetITradeSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getITradeSubscription, (payload: GetITradeSubscriptionRequest) => {
		return getUserApi().getITradeSubscription(payload)
	})
}

function* updateITradeSubscription(action: actions.UpdateITradeSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.updateITradeSubscription, (payload: UpdateITradeSubscriptionOperationRequest) => {
		return getUserApi().updateITradeSubscription(payload)
	})
}

function* getScheduledInvoiceSubscription(action: actions.GetScheduledInvoiceSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getScheduledInvoiceSubscription, (payload: GetScheduledSubscriptionRequest) => {
		return getUserApi().getScheduledSubscription(payload)
	})
}

function* updateScheduledInvoiceSubscription(action: actions.UpdateScheduledInvoiceSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.updateScheduledInvoiceSubscription, (payload: UpdateScheduledSubscriptionOperationRequest) => {
		return getUserApi().updateScheduledSubscription(payload)
	})
}

function* getFergusSubscription(action: actions.GetFergusSubscriptionAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.getFergusSubscription, (payload: GetFergusSubscriptionRequest) => {
		return getUserApi().getFergusSubscription(payload)
	})
}

function handleNavigateToIntegration(action: actions.NavigateToIntegrationAction) {
	NavigationManager.navigateToIntegration(action.payload || undefined)
}

function handleNavigateToPartners(action: actions.NavigateToPartnersAction) {
	NavigationManager.navigateToPartner(action.payload)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.connectIntegration.started, connectIntegration)
	yield takeEvery(actions.connectIntegration.done, openIntegrationLink)
	yield takeEvery(actions.updateIntegrationStatus.started, updateIntegrationStatus)
	yield takeEvery(actions.getXeroSubscription.started, getXeroSubscription)
	yield takeEvery(actions.updateXeroSubscription.started, updateXeroSubscription)
	yield takeEvery(actions.getXeroConnections.started, getXeroConnections)
	yield takeEvery(actions.getXeroContacts.started, getXeroContacts)
	yield takeEvery(actions.getXeroAccounts.started, getXeroAccounts)
	yield takeEvery(actions.getSimproSubscription.started, getSimproSubscription)
	yield takeEvery(actions.updateSimproSubscription.started, updateSimproSubscription)
	yield takeEvery(actions.getSimproAccessToken.started, getSimproAccessToken)
	yield takeEvery(actions.getSimproCompanies.started, getSimproCompanies)
	yield takeEvery(actions.getSimproVendors.started, getSimproVendors)
	yield takeEvery(actions.getSimproOrderNumberFormats.started, getSimproOrderNumberFormats)
	yield takeEvery(actions.getSimproCatalogues.started, getSimproCatalogues)
	yield takeEvery(actions.getAroFloSubscription.started, getAroFloSubscription)
	yield takeEvery(actions.updateAroFloSubscription.started, updateAroFloSubscription)
	yield takeEvery(actions.getAroFloCatalogueUser.started, getAroFloCatalogueUser)
	yield takeEvery(actions.createAroFloCatalogueUser.started, createAroFloCatalogueUser)
	yield takeEvery(actions.resetAroFloCatalogueUser.started, resetAroFloCatalogueUser)
	yield takeEvery(actions.deleteAroFloCatalogueUser.started, deleteAroFloCatalogueUser)
	yield takeEvery(actions.getITradeSubscription.started, getITradeSubscription)
	yield takeEvery(actions.updateITradeSubscription.started, updateITradeSubscription)
	yield takeEvery(actions.getScheduledInvoiceSubscription.started, getScheduledInvoiceSubscription)
	yield takeEvery(actions.updateScheduledInvoiceSubscription.started, updateScheduledInvoiceSubscription)
	yield takeEvery(actions.getFergusSubscription.started, getFergusSubscription)
	yield takeEvery(actions.navigateToIntegration, handleNavigateToIntegration)
	yield takeEvery(actions.navigateToPartners, handleNavigateToPartners)
}