import { CreateLabelOrder, OrderStatus } from 'typescript-fetch-api'
import { Location } from 'react-router-dom'

import * as LabelGroup from '../../common/labels/containers/LabelGroup'
import * as CreateLabels from '../../common/labels/containers/CreateLabels'
import * as LabelOrderComplete from '../../common/labels/containers/LabelOrderComplete'

export type LabelGroupMatch = { id: string }

export interface LabelGroupNavigationProps {
	id: string
}

export function extractLabelGroupNavProps(props: LabelGroupNavigationProps): LabelGroup.OwnProps {
	return {
		labelGroupId: props.id,
	}
}

export interface NewLabelGroupNavigationProps { }

export function extractNewLabelGroupNavProps(props: NewLabelGroupNavigationProps): CreateLabels.OwnPropInfo {
	return {}
}

export interface LabelOrderCompleteNavigationProps {
	labelOrder: CreateLabelOrder
	orderStatus: OrderStatus
	dateCreated?: string
}

export function extractLabelOrderCompleteNavProps(props: LabelOrderCompleteNavigationProps): LabelOrderComplete.OwnProps {
	return {
		labelOrder: props.labelOrder,
	}
}

// needed this method for web as label order details are stored on the navigation state from the saga, then passed through to OrderComplete container
export function extractLabelOrderCompleteNavigationState(location: Location): LabelOrderCompleteNavigationProps {
	const state = location.state as LabelOrderCompleteNavigationProps
	return {
		labelOrder: state?.labelOrder,
		orderStatus: state?.orderStatus,
		dateCreated: state?.dateCreated,
	}
}

export interface InfoItem {
	id: number
	element?: JSX.Element | null
	title: string
	description: string
	boldDescription?: string | null
}

export interface NavigateToCreateLabelsState {
	dialogType?: LabelsDialogType
}

export type LabelOrderMatch = { id?: string }

export enum LabelsDialogType {
	CREATE, DELETE, INFO, UPLOAD
}

export const LABEL_NAME_MAX_LENGTH = 60