import { SagaIterator } from 'redux-saga'
import { call, debounce, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { GetSearchFiltersRequest, SearchFilter, SearchFilterFilterTypeEnum, SearchProductsRequest, SearchRequest } from 'typescript-fetch-api'

import * as actions from '../../common/search/actions'
import * as hnzActions from '../hnz/actions'
import * as productsActions from '../../common/products/actions'
import * as productFinderActions from '../../common/productfinder/actions'
import { UserAccount } from '../../common/accounts/types'
import { orderBranchSelector, selectedAccountSelector } from '../../common/order/selectors'
import { getPriceParams } from '../../common/auth/functions'
import { hnzModeSelector, paginationSizeSelector } from '../platform/selectors'
import { callApi } from '../../common/api/functions'
import { getContentApi } from '../../common/api'
import { INITIAL_PAGE } from '../platform/content'
import { HnzSearchFilter } from '../hnz/types'
import { branchFilterPreferenceSelector } from '../../common/auth/selectors'
import * as NavigationManager from '../navigation/NavigationManager'
import { Paths } from '../navigation/types'
import { HEIGHT_ATTRIBUTE_NAME, LITRES_ATTRIBUTE_NAME, ProductFinderType, WIDTH_ATTRIBUTE_NAME } from '../../common/productfinder/types'

// 6 as the page size is to limit the search items to be displayed in the search suggestions dropdown
export const SEARCH_SUGGESTIONS_PAGE_SIZE = 6

function handleNavigateToSearchResults(action: actions.NavigateToSearchResultsAction) {
	const searchText: string | undefined = action.payload
	NavigationManager.navigateToProductSearch(searchText)
}

function* handleSearchProducts(action: actions.SearchQueryAction): SagaIterator {
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId } = getPriceParams(selectedAccount)
	const pageSize: number = yield select(paginationSizeSelector)
	const hnzMode: boolean = yield select(hnzModeSelector)
	const selectedBranchId = yield select(orderBranchSelector)
	const useBranchFilter: boolean = yield select(branchFilterPreferenceSelector)

	// search filters
	const filters: SearchFilter[] = []
	// we setup the search request depending if the selected account is an hnz one
	if (hnzMode) filters.push(HnzSearchFilter)
	if (useBranchFilter) filters.push({ id: selectedBranchId, filterType: SearchFilterFilterTypeEnum.BRANCH })

	const searchRequest: SearchRequest | undefined = filters.length > 0 ? { filters } : undefined

	// @ts-ignore - add request params to original action so that branch product counts gets correct counts for applied search filters (hnz mode)
	action.payload.searchRequest = searchRequest

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.performQuickSearch,
		(payload: actions.SearchQuery) => {
			const requestParameters: SearchProductsRequest = {
				query: payload.queryText,
				page: INITIAL_PAGE,
				pageSize,
				customerId,
				searchRequest,
			}
			return getContentApi().searchProducts(requestParameters).then(response => ({
				products: response.products || [],
				totalPagesCount: response.totalPagesCount,
				count: response.count,
				searchExcludesHnzProducts: response.searchExcludesHnzProducts,
			}))
		})
}

function* loadSearchFilters(action: actions.SearchQueryAction | actions.LoadSearchFiltersAction): SagaIterator {
	const { queryText } = action.payload
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)

	const loadSearchFiltersActionPayload = action.payload as actions.LoadSearchFiltersPayload
	// we update the `isHnz` flag depending on the payload value if it exists and on the selected order account if it doesn't
	const isHnz: boolean = loadSearchFiltersActionPayload.hnzProductsOnly !== undefined
		? loadSearchFiltersActionPayload.hnzProductsOnly
		: !!selectedAccount && !!selectedAccount.hnz

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadSearchFiltersAction,
		() => {
			const requestParameters: GetSearchFiltersRequest = {
				query: queryText,
				customerId,
				includePrices,
				hnzProductsOnly: isHnz,
			}
			return getContentApi().getSearchFilters(requestParameters)
				.then(response => response.filters || [])
		})
}

/**
 * Loads the search suggestions for the search dropdown in the header.
 * @param action contains the search query
 */
function* fetchSearchSuggestions(action: actions.FetchSearchSuggestionsAction): SagaIterator {
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)
	const isHnz: boolean = yield select(hnzModeSelector)
	// we setup the search request depending if the selected account is an hnz one
	// Note: do not apply branch filter to search suggestions, as they may be performing search from home screen where branch filter not visible
	const searchRequest: SearchRequest | undefined = isHnz ? { filters: [HnzSearchFilter] } : undefined

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchSearchSuggestions,
		(payload: string) => {
			const requestParameters: SearchProductsRequest = {
				query: payload,
				page: INITIAL_PAGE,
				pageSize: SEARCH_SUGGESTIONS_PAGE_SIZE,
				customerId,
				includePrices,
				branchId: undefined, // don't include stock counts in the quick search results
				searchRequest,
			}
			return getContentApi().searchProducts(requestParameters).then(response => ({ products: response.products || [], searchExcludesHnzProducts: response.searchExcludesHnzProducts }))
		})
}

/**
 * Loads the search suggestions for the search dropdown in the list or label group details page
 * Note: a PW list is either a PRODUCT list or LABEL list
 * @param action contains the search query
 */
function* fetchSearchSuggestionsForList(action: actions.SearchProductsForListAction): SagaIterator {
	// NB: don't need prices as we don't display price in the search results
	if (!action.payload.query) {
		const searchProductsForListResponse: actions.SearchProductsForListResponse = {
			products: [],
			page: undefined,
			totalPages: undefined,
		}
		yield put(actions.searchProductsForList.done({ params: action.payload, result: searchProductsForListResponse }))
	} else {
		// Include the customer ID to enable searching for HNZ products when the selected account is HNZ
		const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
		const { customerId } = getPriceParams(selectedAccount)
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.searchProductsForList,
			(payload: actions.SearchProductsForListPayload) => {
				const requestParameters: SearchProductsRequest = {
					query: payload.query,
					page: payload.page ? payload.page : INITIAL_PAGE,
					pageSize: SEARCH_SUGGESTIONS_PAGE_SIZE,
					includePrices: false,
					customerId,
				}
				return getContentApi().searchProducts(requestParameters).then(response => {
					const searchProductsForListResponse: actions.SearchProductsForListResponse = {
						products: response.products || [],
						page: response.page,
						totalPages: response.totalPagesCount,
					}
					return searchProductsForListResponse
				})
			})
	}
}

/**
 * Loads the products based on changes in the filter.
 * 
 * NOTE: we trigger 3 different actions which handle saving the products in the right reducer:
 * 1 - search: `performQuickSearch`
 * 2 - hnz products: `loadHnzProducts`
 * 3 - usual products: `loadProducts`
 * 
 * @param action the action containing the filter/s
 */
function* handleFilterChanged(action: actions.FilterQueryAction): SagaIterator {
	const { queryText, categoryId, page: payloadPage, searchRequest } = action.payload
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId } = getPriceParams(selectedAccount)
	const page: number = payloadPage || INITIAL_PAGE
	const pageSize: number = yield select(paginationSizeSelector)
	const isHnzAccount: boolean = !!selectedAccount && !!selectedAccount.hnz
	const selectedBranchId: string | undefined = yield select(orderBranchSelector)

	// we check for the specific products page being loaded based on the current location
	const pathname = NavigationManager.getLocation()?.pathname || ''
	const isHnzPath = pathname.includes('kāinga-ora') || pathname.includes('k%C4%81inga-ora')
	const isProductFinderPath = pathname.includes(Paths.PRODUCT_FINDER_RESULTS)
	const isHWCFinder = pathname.includes(Paths.PRODUCT_FINDER_RESULTS) && NavigationManager.getLocation()?.search?.includes(ProductFinderType.HWC.toString())

	if (queryText) {
		// user filtered by product range/branch stock, we dispatch a separate action to show a loading state on the search screen
		if (selectedBranchId !== undefined) {
			yield put(actions.searchInProgressAction())
		}

		const requestParameters: SearchProductsRequest = {
			query: queryText,
			page,
			pageSize,
			customerId,
			searchRequest,
		}
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.performQuickSearch, () => (
				getContentApi().searchProducts(requestParameters).then(response => ({
					products: response.products || [],
					totalPagesCount: response.totalPagesCount,
					count: response.count,
					searchExcludesHnzProducts: response.searchExcludesHnzProducts,
				}))
			))
	} else if (categoryId || isHnzAccount || isProductFinderPath) {
		const savedCategoryId: string = categoryId || '' // we default to an empty string for Kainga Ora/HNZ mode
		const saveFilterPayload = {
			categoryId: savedCategoryId,
			page,
			pageSize,
			filters: (searchRequest && searchRequest.filters) || undefined
		}
		let searchAction
		const updatedSearchRequest: SearchRequest | undefined = searchRequest?.filters ? { ...searchRequest, filters: [...searchRequest.filters] } : undefined

		// we save the product filter and setup the search action depending on the current path
		if (isHnzPath) {
			searchAction = hnzActions.loadHnzProducts
			yield put(hnzActions.saveHnzProductsFilter(saveFilterPayload))
		} else if (isProductFinderPath) {
			searchAction = productFinderActions.loadProductFinderProducts
			yield put(productFinderActions.saveProductFinderFilter(saveFilterPayload))

			if (isHWCFinder && updatedSearchRequest?.filters) {
				// to ensure we only get back HWC products we need to add dimension filters, even if the user has not saved any
				let minWidth, maxWidth, minHeight, maxHeight, minLitres, maxLitres
				updatedSearchRequest.filters.forEach(filter => {
					if (filter.filterType === SearchFilterFilterTypeEnum.ATTRIBUTE) {
						if (filter.name === LITRES_ATTRIBUTE_NAME && filter.id === 'MIN') minLitres = true
						else if (filter.name === LITRES_ATTRIBUTE_NAME && filter.id === 'MAX') maxLitres = true
						else if (filter.name === WIDTH_ATTRIBUTE_NAME && filter.id === 'MIN') minWidth = true
						else if (filter.name === WIDTH_ATTRIBUTE_NAME && filter.id === 'MAX') maxWidth = true
						else if (filter.name === HEIGHT_ATTRIBUTE_NAME && filter.id === 'MIN') minHeight = true
						else if (filter.name === HEIGHT_ATTRIBUTE_NAME && filter.id === 'MAX') maxHeight = true
					}
				})
				if (minWidth !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: WIDTH_ATTRIBUTE_NAME, id: 'MIN', value: '0', })
				if (maxWidth !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: WIDTH_ATTRIBUTE_NAME, id: 'MAX', value: '10000', })
				if (minHeight !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: HEIGHT_ATTRIBUTE_NAME, id: 'MIN', value: '0', })
				if (maxHeight !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: HEIGHT_ATTRIBUTE_NAME, id: 'MAX', value: '10000', })
				if (minLitres !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: LITRES_ATTRIBUTE_NAME, id: 'MIN', value: '0', })
				if (maxLitres !== true) updatedSearchRequest.filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: LITRES_ATTRIBUTE_NAME, id: 'MAX', value: '10000', })
			}
		} else {
			searchAction = productsActions.loadProducts
			yield put(productsActions.saveProductsFilter(saveFilterPayload))
		}

		// user filtered by product range/branch stock, we dispatch a separate action to show a loading state on the products screen
		if (selectedBranchId !== undefined) {
			yield put(isHnzPath ? hnzActions.setLoadingHnzProductsState() : isProductFinderPath ? productFinderActions.setLoadingProductFinderProductsState() : productsActions.setLoadingProductsState())
		}

		const requestParameters: SearchProductsRequest = {
			query: queryText,
			categoryId,
			page,
			pageSize,
			customerId,
			searchRequest: updatedSearchRequest,
		}
		yield call(
			// @ts-ignore API
			callApi,
			action,
			searchAction, () => (
				getContentApi().searchProducts(requestParameters).then(response => ({ products: response.products || [], totalProductPagesCount: response.totalPagesCount, totalProductsCount: response.count }))
			))
	}
}

/**
 * Handles loading the filters (categories and suppliers) on successful search
 * @param action the action containing the search details
 */
function* loadSearchFiltersOnSearchSuccess(action: actions.PerformQuickSearchSuccess): SagaIterator {
	// update store flag to know when fetching search filters
	yield put(actions.setLoadingSearchFiltersState())

	const payload = action.payload.params as actions.FilterQueryPayload
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)

	const branchFilter = payload.searchRequest && payload.searchRequest.filters && payload.searchRequest.filters.find(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH)
	const branchId = branchFilter && branchFilter.id ? parseInt(branchFilter.id, 10) : undefined

	// pass comma-separated values of the category ids included in the search
	let categoryIds = ''
	if (payload.searchRequest && payload.searchRequest.filters) {
		const categoryFilters = payload.searchRequest.filters.filter(item => item.filterType === SearchFilterFilterTypeEnum.CATEGORY)
		if (categoryFilters.length > 0) {
			categoryFilters.forEach(item => {
				categoryIds += `${item.id},`
			})
		}
	}

	const loadSearchFiltersAction: actions.LoadSearchFiltersAction = {
		type: actions.loadSearchFiltersAction.type,
		payload: {
			queryText: payload.queryText!,
			clearCategoryFilters: payload.clearCategoryFilters,
			hnzProductsOnly: payload.hnzProductsOnly,
		},
	}
	yield call(
		// @ts-ignore API
		callApi,
		loadSearchFiltersAction,
		actions.loadSearchFiltersAction,
		() => {
			const requestParameters: GetSearchFiltersRequest = {
				query: payload.queryText,
				categoryId: categoryIds || undefined,
				customerId,
				includePrices,
				branchId,
				hnzProductsOnly: payload.hnzProductsOnly,
			}
			return getContentApi().getSearchFilters(requestParameters)
				.then(response => response.filters || [])
		}
	)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.navigateToSearchResults, handleNavigateToSearchResults)
	yield takeLatest(actions.textChangeAction, handleSearchProducts)
	yield takeLatest(actions.loadSearchFiltersAction.started, loadSearchFilters)
	yield takeLatest(actions.performQuickSearch.done, loadSearchFiltersOnSearchSuccess)
	yield debounce(1000, actions.fetchSearchSuggestions.started, fetchSearchSuggestions)
	yield debounce(1000, actions.searchProductsForList.started, fetchSearchSuggestionsForList)
	yield takeLatest(actions.filterChangeAction, handleFilterChanged)
}