import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { createOrEditList, fetchLists, deleteList, clearErrorDeletingList, clearErrorEditingList, navigateToNewList, fetchList, removeProductFromList, addProductToList, updateProductsInList, updateListTitle, clearErrorUpdatingListTitle, ListsScreen, pinList, unpinList, updateListTitleAndProducts, getListProductsAvailability, getListProductsPrices, uploadList, uploadNewList, clearUploadListResult, downloadList, updateListItemComment, updateSelectedListId } from './actions'
import { BranchProductDetail, ProductList, ProductListGroup } from 'typescript-fetch-api'
import { clearAuthToken, loggedOut, login } from '../auth/actions'
import { readyAction } from '../root/actions'
import { mapListsToUpdateListTitle, updateList, mapListsToUpdatePin, updateListWithPricing, mapPagedProductListToProductList, updateListWithAvailability, mapListsToUpdateItemComment, updateLists } from './functions'

export interface StoreState {
	readonly lists: ProductListGroup[]
	readonly fetchingLists: boolean
	readonly errorFetchingLists?: Error

	// list ids currently being fetched
	readonly isFetchingListIds: string[]
	readonly isFetchingPricingListIds: string[]

	readonly selectedListId?: string

	readonly editingList: boolean
	readonly successEditingList?: boolean
	readonly errorEditingList?: Error
	// needed to check what screen dispatched the action so we only show 1 modal for loading/error states (this could probably use navigation current screen/scene instead)
	readonly editingFromScreen?: ListsScreen
	readonly deletingList: boolean
	readonly errorDeletingList?: Error
	readonly updatingListTitle: boolean
	readonly errorUpdatingListTitle?: Error

	readonly listProductStockCounts: BranchProductDetail[]

	readonly uploadingList: boolean
	readonly successUploadingList: boolean
	readonly errorUploadingList?: Error
	readonly skippedProductsFromUpload?: string[]

	readonly downloadingList: boolean
	readonly errorDownloadingList?: Error
}

const INITIAL_STATE: StoreState = {
	lists: [],
	isFetchingListIds: [],
	isFetchingPricingListIds: [],
	fetchingLists: false,
	editingList: false,
	deletingList: false,
	updatingListTitle: false,
	listProductStockCounts: [],
	uploadingList: false,
	successUploadingList: false,
	errorUploadingList: undefined,
	skippedProductsFromUpload: undefined,
	downloadingList: false,
	errorDownloadingList: undefined,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

// FETCHING
reducer.case(fetchLists.started, (state): StoreState => {
	return {
		...state, fetchingLists: true, errorFetchingLists: undefined,
	}
})
reducer.case(fetchLists.done, (state, payload): StoreState => {
	const updatedLists = payload.result.lists || []
	return {
		...state,
		lists: updateLists(state.lists, updatedLists, state.selectedListId),
		fetchingLists: false,
	}
})
reducer.case(fetchLists.failed, (state, payload): StoreState => {
	return {
		...state, fetchingLists: false, errorFetchingLists: payload.error,
	}
})

// FETCHING A LIST
reducer.case(fetchList.started, (state, { id }): StoreState => {
	return {
		...state,
		// add the id to the list of ids being fetched
		isFetchingListIds: [...state.isFetchingListIds, id],
	}
})
reducer.case(fetchList.done, (state, { params, result }): StoreState => {
	return {
		...state,
		lists: updateList(state.lists, result, params.appendToList, params.includePrices),
		// remove from the list of ids that are being fetched
		isFetchingListIds: state.isFetchingListIds.filter(listId => listId !== params.id),
	}
})
reducer.case(fetchList.failed, (state, { params }): StoreState => {
	return {
		...state,
		// remove from the list of ids that are being fetched
		isFetchingListIds: state.isFetchingListIds.filter(listId => listId !== params.id),
	}
})

// UPDATE SELECTED LIST ID
reducer.case(updateSelectedListId, (state, listId): StoreState => {
	return {
		...state,
		selectedListId: listId,
	}
})

// FETCHING STOCK COUNTS
reducer.case(getListProductsAvailability.done, (state, { params, result }): StoreState => {
	const listProductStockCounts = result.products || []
	return {
		...state,
		// append availability to products in the list
		lists: updateListWithAvailability(state.lists, params.listId, listProductStockCounts),
		listProductStockCounts: params.appendToList ? [...state.listProductStockCounts, ...listProductStockCounts] : listProductStockCounts,
	}
})

// FETCHING LIST PRICING
reducer.case(getListProductsPrices.started, (state, { listId }): StoreState => {
	return {
		...state,
		// add the id to the list of ids being fetched
		isFetchingPricingListIds: [...state.isFetchingPricingListIds, listId],
	}
})
reducer.case(getListProductsPrices.done, (state, { params, result }): StoreState => {
	const removeLoadingFlag: boolean = !params.appendToList || (!!params.appendToList && !!params.isLastBatch)
	return {
		...state,
		// append pricing to products in the list
		lists: updateListWithPricing(state.lists, params.listId, result.prices || []),
		// remove from the list of ids that are being fetched (if its the last batch)
		isFetchingPricingListIds: removeLoadingFlag ? state.isFetchingPricingListIds.filter(listId => listId !== params.listId) : state.isFetchingPricingListIds,
	}
})
reducer.case(getListProductsPrices.failed, (state, { params }): StoreState => {
	// only remove loading flag if there is only 1 batch, or its the last batch request
	const removeLoadingFlag: boolean = !params.appendToList || (!!params.appendToList && !!params.isLastBatch)
	if (removeLoadingFlag)
		return {
			...state,
			// remove from the list of ids that are being fetched (if its the last batch)
			isFetchingPricingListIds: state.isFetchingPricingListIds.filter(listId => listId !== params.listId),
		}
	return state
})

// EDITING A LIST (ADDING/REMOVING PRODUCT)
reducer.cases([addProductToList.started, updateProductsInList.started, removeProductFromList.started, updateListTitleAndProducts.started], (state, payload): StoreState => {
	return {
		...state,
		editingList: true,
		errorEditingList: undefined,
		editingFromScreen: payload.screen,
		successEditingList: undefined,
	}
})
reducer.cases([addProductToList.done, updateProductsInList.done, removeProductFromList.done, updateListTitleAndProducts.done], (state, { params, result }): StoreState => {
	return {
		...state,
		editingList: false,
		successEditingList: true,
		lists: updateList(state.lists, result, params.appendToList, params.includePrices),
	}
})
reducer.cases([addProductToList.failed, updateProductsInList.failed, removeProductFromList.failed, updateListTitleAndProducts.failed], (state, { error }): StoreState => {
	return {
		...state,
		editingList: false,
		errorEditingList: error,
	}
})

// UPDATING A LIST TITLE
reducer.case(updateListTitle.started, (state): StoreState => {
	return {
		...state,
		updatingListTitle: true,
		errorUpdatingListTitle: undefined,
	}
})
reducer.case(updateListTitle.done, (state, { params }): StoreState => {
	return {
		...state,
		updatingListTitle: false,
		lists: mapListsToUpdateListTitle(state.lists, params.id, params.title),
	}
})
reducer.case(updateListTitle.failed, (state, { error }): StoreState => {
	return {
		...state,
		updatingListTitle: false,
		errorUpdatingListTitle: error,
	}
})
reducer.case(clearErrorUpdatingListTitle, (state): StoreState => {
	return {
		...state, errorUpdatingListTitle: undefined,
	}
})

// CREATING/EDITING
reducer.case(createOrEditList.started, (state, payload): StoreState => {
	return {
		...state,
		editingList: true,
		errorEditingList: undefined,
		editingFromScreen: payload.screen,
		successEditingList: undefined,
	}
})
reducer.case(createOrEditList.done, (state, { result }): StoreState => {
	return {
		...state,
		lists: result.lists || [],
		editingList: false,
		successEditingList: true,
	}
})
reducer.case(createOrEditList.failed, (state, payload): StoreState => {
	return {
		...state, editingList: false, errorEditingList: payload.error,
	}
})
reducer.case(clearErrorEditingList, (state): StoreState => {
	return {
		...state, errorEditingList: undefined, editingFromScreen: undefined,
	}
})

// DELETING
reducer.case(deleteList.started, (state): StoreState => {
	return {
		...state, deletingList: true, errorDeletingList: undefined,
	}
})
reducer.case(deleteList.done, (state, { result }): StoreState => {
	return {
		...state, lists: result.lists || [], deletingList: false,
	}
})
reducer.case(deleteList.failed, (state, payload): StoreState => {
	return {
		...state, deletingList: false, errorDeletingList: payload.error,
	}
})
reducer.case(clearErrorDeletingList, (state): StoreState => {
	return {
		...state, errorDeletingList: undefined,
	}
})

// upload list
reducer.cases([uploadList.started, uploadNewList.started], (state): StoreState => ({
	...state,
	uploadingList: true,
	successUploadingList: false,
	errorUploadingList: undefined,
	skippedProductsFromUpload: undefined,
}))
reducer.case(uploadList.done, (state, payload): StoreState => {
	let lists: ProductListGroup[]
	if (payload.result.list) {
		// map to product list type
		const updatedList: ProductList = mapPagedProductListToProductList(payload.result.list)
		// place updated list in array of lists
		lists = updateList(state.lists, updatedList, false, false)
	} else {
		lists = state.lists
	}
	return {
		...state,
		lists,
		uploadingList: false,
		successUploadingList: true,
		skippedProductsFromUpload: payload.result.skippedProducts,
	}
})
reducer.case(uploadNewList.done, (state, { result }): StoreState => {
	return {
		...state,
		// store new list in array, but don't change any loading state yet as we immediately perform the uploadList action 
		lists: result.lists || [],
	}
})
reducer.cases([uploadList.failed, uploadNewList.failed], (state, payload): StoreState => ({
	...state,
	uploadingList: false,
	errorUploadingList: payload.error,
}))
reducer.case(clearUploadListResult, (state): StoreState => ({
	...state,
	uploadingList: false,
	successUploadingList: false,
	errorUploadingList: undefined,
	skippedProductsFromUpload: undefined,
}))

// download list
reducer
	.case(downloadList.started, (state): StoreState => ({
		...state, downloadingList: true, errorDownloadingList: undefined,
	}))
	.case(downloadList.done, (state): StoreState => ({
		...state, downloadingList: false,
	}))
	.case(downloadList.failed, (state, payload): StoreState => ({
		...state, downloadingList: false, errorDownloadingList: payload.error,
	}))

// UN/PINNING
reducer.cases([pinList.started, unpinList.started], (state): StoreState => {
	return {
		...state, editingList: true, errorEditingList: undefined, successEditingList: undefined,
	}
})
reducer.case(pinList.done, (state, { params: listId }): StoreState => {
	return {
		...state,
		editingList: false,
		successEditingList: true,
		lists: mapListsToUpdatePin(state.lists, listId, true),
	}
})
reducer.case(unpinList.done, (state, { params: listId }): StoreState => {
	return {
		...state,
		editingList: false,
		successEditingList: true,
		lists: mapListsToUpdatePin(state.lists, listId, false),
	}
})
reducer.cases([pinList.failed, unpinList.failed], (state, payload): StoreState => {
	return {
		...state, editingList: false, errorEditingList: payload.error,
	}
})

// Reset the create/edit status flags when user navigates to New List screen
reducer.case(navigateToNewList, (state): StoreState => {
	return {
		...state,
		editingList: false,
		successEditingList: false,
		errorEditingList: undefined,
	}
})

// UPDATING A LIST ITEM COMMENT
reducer.case(updateListItemComment.done, (state, { params }): StoreState => {
	return {
		...state,
		lists: mapListsToUpdateItemComment(state.lists, params.id, params.sku, params.comment),
	}
})

// Clear the state flags
reducer.case(readyAction, (state): StoreState => {
	return {
		...state,
		fetchingLists: false,
		errorFetchingLists: undefined,

		isFetchingListIds: [],
		selectedListId: undefined,

		editingList: false,
		successEditingList: false,
		errorEditingList: undefined,
		editingFromScreen: undefined,

		deletingList: false,
		errorDeletingList: undefined,

		updatingListTitle: false,
		errorUpdatingListTitle: undefined,
	}
})

reducer.cases([loggedOut, login.done, clearAuthToken], (): StoreState => {
	return INITIAL_STATE
})