import Lottie from 'react-lottie'

import animationData from '../../../assets/anims/three_dots_loading.json'

const LoaderThreeDots = (): JSX.Element => (
	<Lottie
		options={{ autoplay: true, loop: true, animationData }}
		height={40}
		width={100}
	/>
)

export default LoaderThreeDots