import { SagaIterator } from 'redux-saga'
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects'
import { UserPreference } from 'typescript-fetch-api'

import { offlineOutboxQueueLength } from '../api/selectors'
import { readyAction } from '../root/actions'
import * as actions from './actions'
import { authenticate } from './functions'
import { authTokenSelector, pinSelector } from './selectors'
import { AuthToken, LoginRequest } from './types'
import * as accountsActions from '../accounts/actions'
import { getPlatformSupportImplementation } from '../platform'
import { ApiErrorMessage, getUserApi } from '../api'
import { callApi } from '../api/functions'

/** Saga handling the state of being logged out. */
function* loggedOutSaga(): SagaIterator {
	/* Wait for a login request, but we also look for a logout request */
	const loginRaceResult = yield race({
		loginRequest: take(actions.login.started),
		logout: take(actions.logoutRequest),
	})

	if (loginRaceResult.logout) {
		yield call(handleLogoutRequest, loginRaceResult.logout)
		return
	}

	let loginRequest = loginRaceResult.loginRequest.payload as LoginRequest

	try {
		/* Attempt to login, but also let a logout request interrupt our login request */
		const loggingInRaceResult = yield race({
			loginResult: call(authenticate, loginRequest),
			logout: take(actions.logoutRequest),
		})

		if (loggingInRaceResult.loginResult) {
			yield put(actions.login.done({ params: loginRequest, result: loggingInRaceResult.loginResult }))
		} else if (loggingInRaceResult.logout) {
			yield call(handleLogoutRequest, loggingInRaceResult.logout)
		}
	} catch (error) {
		getPlatformSupportImplementation().reportError('loggedOutSaga error: ', error)
		yield put(actions.login.failed({ params: loginRequest, error: error as Error }))
	}
}

/** Saga handling the state of being logged in. */
function* loggedInSaga(): SagaIterator {
	try {
		let raceResult = yield race({
			loggedOut: take(actions.loggedOut), // we want to wait for a loggedOut action because this is when the authToken is cleared
			logoutRequest: take(actions.logoutRequest), // if we get a logout request we want to call through to the function that handles that
			loggedInError: take(actions.loggedInError),
			refreshTokenFailed: take(actions.refreshAuthTokenFailed),
		})

		if (raceResult.loggedOut) {
			// do nothing, we are already logged out
		} else if (raceResult.logoutRequest) {
			yield call(handleLogoutRequest, raceResult.logoutRequest)
		} else if (raceResult.loggedInError) {
			yield put(actions.preloggedOut())
		} else if (raceResult.refreshTokenFailed) {
			/* There's nothing for us to do here, as the routing saga handles this to take us to the login form to reauth. */
		}
	} catch (error) {
		getPlatformSupportImplementation().reportError('loggedInSaga error: ', error)
		yield put(actions.loggedInError(error as Error))
		yield put(actions.preloggedOut())
	}
}

/** When we request to logout we must check if we have an offline queue that will be lost if
 *  we actually logout. So we do a confirm step first whenever there is a logout request and our
 *  offline queue is not empty.
 */
function* handleLogoutRequest(action: actions.LogoutRequestAction | actions.DeleteSelfRequestSuccessAction): SagaIterator {

	const queueLength = yield select(offlineOutboxQueueLength)
	if (queueLength > 0) {
		const confirmResult = (yield call(
			getPlatformSupportImplementation().signOutConfirmation,
			'Warning!',
			'Are you sure you want to sign out? You have pending orders that will be lost.',
			'Sign out')) as boolean
		if (confirmResult) {
			yield put(actions.preloggedOut())
		}
	} else {
		yield put(actions.preloggedOut())
	}
}

/** Yields a boolean result, whether there is a user logged in or not. */
export function* loggedIn(): SagaIterator {
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	const isLoggedIn: boolean = getPlatformSupportImplementation().hasPinFeature()
		? hasAuthToken && (yield select(pinSelector)) !== undefined
		: hasAuthToken
	return isLoggedIn
}

var refreshingToken = false

/**
 * refreshes the auth token by calling authenticate
 */
export function* refreshTokenNow(): SagaIterator {

	let authToken: AuthToken

	try {
		authToken = yield select(authTokenSelector)
	} catch (error) {
		getPlatformSupportImplementation().reportError('refreshTokenNow authTokenSelector error: ', error)
		throw error
	}

	if (!authToken) {
		throw new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN)
	}

	// if there is no refresh token, the user is impersonating someone, so just log them out and kick to sign in screen
	if (!authToken.refresh_token) {
		yield put(actions.preloggedOut())
		return false
	}

	if (!refreshingToken) {
		refreshingToken = true
		try {
			let refreshedAuthToken = (yield call(authenticate, { refreshToken: authToken.refresh_token })) as AuthToken
			console.log('refreshedAuthToken', refreshedAuthToken)
			refreshingToken = false
			yield put(actions.refreshedAuthToken(refreshedAuthToken))
			return true
		} catch (error) {
			getPlatformSupportImplementation().reportError('refreshTokenNow authenticate error: ', error)
			// TODO should maybe at least call this to match how refreshTokenAndApply behaves?
			// yield put(actions.refreshAuthTokenFailed())
			refreshingToken = false
			return false
		}
	} else {
		/* The token is already being refreshed, so wait for the result of that operation, so we don't
		   double-up our refresh requests.
		 */
		const raceResult = yield race({
			refreshedToken: take(actions.refreshedAuthToken),
			loggedInError: take(actions.loggedInError),
			refreshTokenFailed: take(actions.refreshAuthTokenFailed),
		})

		if (raceResult.refreshedToken) {
			return true
		} else {
			return false
		}
	}
}

/**
 * This might be platform specific. The `fromLogin` flag is used to navigate the user to set their PIN after successfully getting their accounts.
 */
function* handleLoginDone(action: actions.LoginRequestSuccessAction): SagaIterator {
	yield put(accountsActions.getAccounts.started({
		goCheckoutAfter: action.payload.params.goCheckoutAfter,
		fromLogin: true,
		failedOrderId: action.payload.params.failedOrderId,
	}))
}

/**
 * Sets user branch filter preference
 */
function* handleSetBranchFilterPreference(action: actions.SetBranchFilterPreferenceAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.setBranchFilterPreference,
		(payload: boolean) => {
			return getUserApi()
				.updateUserPreference({
					updateUserPreferenceRequest: {
						id: UserPreference.BRANCH_FILTER,
						value: `${payload}`
					}
				})
		})
}

/**
 * Gets fired whenever an user trys to delete their account.
 */
function* handleDeleteSelf(action: void): SagaIterator {
	const queueLength = yield select(offlineOutboxQueueLength)
	if (queueLength > 0) {
		const confirmResult = (yield call(
			getPlatformSupportImplementation().signOutConfirmation,
			'Warning!',
			'Are you sure you want to delete your account and sign out? You have pending orders that will be lost.',
			'Delete Account')) as boolean
		if (confirmResult) {
			// @ts-ignore API
			yield call(callApi, {}, actions.deleteSelf, () => {
				return getUserApi().deleteSelf()
			})
		}
	} else {
		// @ts-ignore API
		yield call(callApi, {}, actions.deleteSelf, () => {
			return getUserApi().deleteSelf()
		})
	}
}

export default function* saga(): SagaIterator {
	/* Wait for the state to be ready, as we read the state in the loggedIn function.
	The state isn't immediately ready, as we use react-persist to load persisted state,
	which happens asynchronously.
	*/
	yield take(readyAction)

	yield takeEvery(actions.login.done, handleLoginDone)
	yield takeEvery(actions.setBranchFilterPreference.started, handleSetBranchFilterPreference)

	yield takeEvery(actions.deleteSelf.started, handleDeleteSelf)
	yield takeEvery(actions.deleteSelf.done, handleLogoutRequest)

	while (true) {
		const isLoggedIn: boolean = yield call(loggedIn)
		if (isLoggedIn) {
			yield call(loggedInSaga)
		} else {
			yield call(loggedOutSaga)
		}
	}
}
