import { Branch, InvoiceType } from "typescript-fetch-api"

export enum InvoiceFilterType {
    DATE, INVOICE_NO, ORDER_NO, TYPE, BRANCH, DISPLAY_$0_INVOICES
}

export interface DateRange {
    fromDate: string
    toDate: string
}

export interface InvoiceFilter {
    type: InvoiceFilterType
    value: string | DateRange | InvoiceType | Branch
}

export const INVOICE_FILTERS: InvoiceFilter[] = [
    {
        type: InvoiceFilterType.DATE,
        value: ''
    },
    {
        type: InvoiceFilterType.INVOICE_NO,
        value: ''
    },
    {
        type: InvoiceFilterType.ORDER_NO,
        value: ''
    },
    {
        type: InvoiceFilterType.TYPE,
        value: ''
    },
    {
        type: InvoiceFilterType.BRANCH,
        value: ''
    },
    {
        type: InvoiceFilterType.DISPLAY_$0_INVOICES,
        value: ''
    },
]

export interface TypeFilter {
    type: InvoiceType,
    name: string,
}

export const INVOICE_TYPES: TypeFilter[] = [
    {
        type: InvoiceType.INVOICE,
        name: 'Invoice',
    },
    {
        type: InvoiceType.CREDIT_NOTE,
        name: 'Credit Note',
    },
    {
        type: InvoiceType.CASH_SALE,
        name: 'Cash Sale',
    },
]

export type PaymentMethodContent = {
	title: string
	subtitle: string
	icon: string
}

export enum PaymentResultEnum {
	SUCCESS = 'success',
	FAIL = 'fail',
	CANCEL = 'cancel',
}

export enum FinancialsTabEnum {
	INVOICES = 'invoices',
	STATEMENTS = 'statements',
	ACCOUNT_BALANCE = 'account-balance',
}

export interface PaymentDetails {
	customerId: number
	amount: number
	paymentResult: PaymentResultEnum
}

export interface PaymentSession {
	sessionId: string
	customerId: number
	amount: number
}