import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import * as mobileVerification from '../mobileVerification/actions'

export interface StoreState {
	readonly mobileNumber?: string
	readonly registerSuccess?: boolean
	readonly error?: Error
	readonly loading: boolean
}

const INITIAL_STATE: StoreState = {
	loading: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.register.started, (state, { registerRequest }): StoreState => {
		return {
			...state,
			error: undefined,
			loading: true,
			registerSuccess: undefined,
			mobileNumber: registerRequest.mobileNumber,
		}
	})
	.case(actions.register.done, (state): StoreState => {
		return { ...state, registerSuccess: true, loading: false }
	})
	.case(actions.register.failed, (state, { error }): StoreState => {
		return { ...state, registerSuccess: undefined, error: error, loading: false }
	})
	.case(mobileVerification.verifyMobile.done, (state): StoreState => {
		return { ...state, mobileNumber: undefined }
	})