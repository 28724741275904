import * as Api from 'typescript-fetch-api'
import { store } from '../root'
import { fetchTimeout } from './timeout'
import { getPlatformSupportImplementation } from '../platform'
import { refreshTokenAndApply } from '../auth/functions'
import { AuthToken } from '../auth/types'
import { Environment, EnvironmentUrls } from './types'

export type ApiErrorTransformer = (response: Response) => Error
let errorTransformer: ApiErrorTransformer | undefined

export const IMAGE_ID_PLACEHOLDER = '{id}'
export enum ApiErrorMessage {
	GENERIC_ERROR_MESSAGE = 'An unknown error has occurred',
	QUOTE_ERROR_MESSAGE = 'This transaction cannot be updated, it is a Voided Quote',
	ORDER_ERROR_MESSAGE = 'This transaction cannot be updated, it is a Voided Sales Order',
	INVOICE_ERROR_MESSAGE = 'This transaction cannot be updated, it is a invoice',
	STATEMENT_ERROR_MESSAGE = 'Customer has no open transactions',
	STATEMENT_HAS_NO_CONTENT = 'Statement has no content',
	CODE_SEND_FAILURE = 'Code send failure',
	ERROR_NOT_LOGGED_IN = 'Not logged in',
	ERROR_MSG_ALREADY_VERIFIED = 'User already verified',
	USER_IS_DISABLED_MESSAGE = 'User is disabled',
	REQUEST_MAX_ERROR_MESSAGE = 'Too Many Requests',
}

/** Our fetch API wrapper to provide additional functionality */
export const apiTimeout: Api.FetchAPI = (url: string, init?: {}): Promise<Response> => {
	/* Apply a timeout to our requests. Note that the timeout doesn't cancel the request, it merely
	   throws an error so we are not left hanging.
	 */
	// bigger timeout for the ff:
	// - loading products as can take a while to process the request on first install
	// - sending big orders
	// - loading huge amounts of quotes without server-side filtering
	const timeoutMillis: number = url.endsWith('/products') || url.endsWith('/order') || url.endsWith('/orders?type=QUOTE') ? 120000 : 60000
	return fetchTimeout(url, init, timeoutMillis)
}

const EnvConfig: Record<Environment, EnvironmentUrls> = {
	[Environment.LOCAL]: {
		'appServerRootURL': 'http://localhost:8080',
		'imageThumbServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageThumb.webp',
		'imageLargeServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageLarge.webp',
		'imageLargeServerUrlFallback': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageLarge.png',
		'specSheetsServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/specs/',
		'brochuresServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/brochures/',
		'msdsServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/msds/',
		'warrantyServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/warranty/',
		'sdocServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/SDoC/',
		'installServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/install/',
		'metaDataUrl': 'https://images2.plumbingworld.co.nz/Dev/Data/metadata.json',
	},
	[Environment.DEV]: {
		'appServerRootURL': 'http://api-dev.pwgo.co.nz',
		'imageThumbServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageThumb.webp',
		'imageLargeServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageLarge.webp',
		'imageLargeServerUrlFallback': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageLarge.png',
		'specSheetsServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/specs/',
		'brochuresServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/brochures/',
		'msdsServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/msds/',
		'warrantyServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/warranty/',
		'sdocServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/SDoC/',
		'installServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/install/',
		'metaDataUrl': 'https://images2.plumbingworld.co.nz/Dev/Data/metadata.json',
	},
	[Environment.TEST]: {
		'appServerRootURL': 'https://api-test.pwgo.co.nz',
		'imageThumbServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageThumb.webp',
		'imageLargeServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageLarge.webp',
		'imageLargeServerUrlFallback': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageLarge.png',
		'specSheetsServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/specs/',
		'brochuresServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/brochures/',
		'msdsServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/msds/',
		'warrantyServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/warranty/',
		'sdocServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/SDoC/',
		'installServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/install/',
		'metaDataUrl': 'https://images2.plumbingworld.co.nz/Test/Data/metadata.json',
	},
	[Environment.DEMO]: {
		'appServerRootURL': 'https://api-demo.pwgo.co.nz',
		'imageThumbServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageThumb.webp',
		'imageLargeServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageLarge.webp',
		'imageLargeServerUrlFallback': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageLarge.png',
		'specSheetsServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/specs/',
		'brochuresServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/brochures/',
		'msdsServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/msds/',
		'warrantyServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/warranty/',
		'sdocServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/SDoC/',
		'installServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/install/',
		'metaDataUrl': 'https://images2.plumbingworld.co.nz/Test/Data/metadata.json',
	},
	[Environment.PRODUCTION]: {
		'appServerRootURL': 'https://api.pwgo.co.nz',
		'imageThumbServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageThumb.webp',
		'imageLargeServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageLarge.webp',
		'imageLargeServerUrlFallback': 'https://images2.plumbingworld.co.nz/Products/{id}/ImageLarge.png',
		'specSheetsServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/specs/',
		'brochuresServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/brochures/',
		'msdsServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/msds/',
		'warrantyServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/warranty/',
		'sdocServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/SDoC/',
		'installServerUrl': 'https://images2.plumbingworld.co.nz/Products/{id}/install/',
		'metaDataUrl': 'https://images2.plumbingworld.co.nz/Data/metadata.json',
	}
}

export function isDevelopment(): boolean {
	return getPlatformSupportImplementation().getEnvironment().toUpperCase() === Environment.LOCAL
}

export function getAppServerRootURL() {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].appServerRootURL
}

export function getDataServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].metaDataUrl
}

export function getImageLargeServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].imageLargeServerUrl
}

export function getImageLargeServerUrlFallback(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].imageLargeServerUrlFallback
}

export function getSpecSheetsServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].specSheetsServerUrl
}

export function getBrochureServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].brochuresServerUrl
}

export function getMSDSServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].msdsServerUrl
}

export function getWarrantyServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].warrantyServerUrl
}

export function getSdocServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].sdocServerUrl
}

export function getInstallServerUrl(): string {
	return EnvConfig[getPlatformSupportImplementation().getEnvironment()].installServerUrl
}

export function getErrorTransformer(): ApiErrorTransformer | undefined {
	return errorTransformer
}

/** Set a function to be used to transform failed Response objects into Errors. */
export function setErrorTransformer(e?: ApiErrorTransformer) {
	errorTransformer = e
}

/** The API configuration. */
const getOAuthConfig = (headers?: Api.HTTPHeaders) => {
	return new Api.Configuration({
		basePath: getAppServerRootURL(),
		accessToken: (name?: string, scopes?: string[]) => {
			let authToken = store.getState().auth.authToken
			const accessToken = authToken !== undefined ? authToken.access_token : undefined
			if (accessToken) {
				return 'Bearer ' + accessToken
			} else {
				// TODO the generated API doesn't support not returning a valid access token
				// We send a string, rather than nothing, so the server responds with a 401 rather
				// than a 403. A 401 signals that we need to authenticate, so the rest of our code
				// handles the failure appropriately. See handleDiscard.
				return 'INVALID'
			}
		},
		fetchApi: (input: RequestInfo, init?: RequestInit | undefined): Promise<Response> => {
			const url: string = (input as Request).url || (input as string)
			return apiTimeout(url, init)
		},
		headers,
	})
}
export const oAuthConfig = getOAuthConfig()

/**
 * 
 */
export function getOauthApi(headers?: Api.HTTPHeaders): Api.OauthApi {
	console.log('Path: ', getAppServerRootURL())
	const configuration = new Api.Configuration({
		basePath: getAppServerRootURL(),
		// TODO: store these deets properly
		apiKey: 'x38V93VvtMtik8DXLYX3lhWmSbAOjKEv',
		username: getPlatformSupportImplementation().getPlatformClientId(), // client id
		password: getPlatformSupportImplementation().getPlatformClientSecret(), // client secret
		headers,
	})
	return new Api.OauthApi(configuration)
}

/**
 * 
 */
export function getRegisterApi(): Api.RegisterApi {
	console.log('Path: ', getAppServerRootURL())
	const configuration = new Api.Configuration({
		basePath: getAppServerRootURL(),
		apiKey: 'x38V93VvtMtik8DXLYX3lhWmSbAOjKEv'
	})
	return new Api.RegisterApi(configuration)
}

/**
 * 
 */
export function getOrderApi(): Api.OrderApi {
	return new Api.OrderApi(oAuthConfig)
}

/**
 * 
 */
export function getUserApi(): Api.UserApi {
	return new Api.UserApi(oAuthConfig)
}

export function getInvoiceApi(): Api.InvoiceApi {
	return new Api.InvoiceApi(oAuthConfig)
}

export function getPriceBookApi(): Api.PricebookApi {
	return new Api.PricebookApi(oAuthConfig)
}

export function getListApi(): Api.ProductListsApi {
	return new Api.ProductListsApi(oAuthConfig)
}

export function getLabelOrderApi(): Api.LabelOrderApi {
	return new Api.LabelOrderApi(oAuthConfig)
}

export function getBranchApi(): Api.BranchApi {
	return new Api.BranchApi(oAuthConfig)
}

export function getContentApi(): Api.ContentApi {
	console.log('Path: ', getAppServerRootURL())
	const configuration = new Api.Configuration({
		basePath: getAppServerRootURL(),
		apiKey: 'x38V93VvtMtik8DXLYX3lhWmSbAOjKEv',
		accessToken: oAuthConfig.accessToken,
	})
	return new Api.ContentApi(configuration)
}

export function getMetadataApi(): Api.MetadataApi {
	const configuration = new Api.Configuration({
		basePath: getAppServerRootURL(),
		apiKey: 'x38V93VvtMtik8DXLYX3lhWmSbAOjKEv',
	})
	return new Api.MetadataApi(configuration)
}

export function getAuthMetadataApi(): Api.MetadataApi {
	return new Api.MetadataApi(oAuthConfig)
}

export function getEventsApi(): Api.EventsApi {
	return new Api.EventsApi(oAuthConfig)
}

export function getShareholdingApi(): Api.ShareholdingApi {
	return new Api.ShareholdingApi(oAuthConfig)
}

var refreshingToken: Promise<AuthToken> | undefined
/**
 * This function was created to fix the issue where both offlineApi and callApi 401 errors were fetching a new access token at same time.
 * This meant that after the second one came back, the first one would already be invalid, and so the next request that tried to use it would fail.
 * This function fixes this by keeping a reference to inflight refreshToken requests and returning the existing request if one is available.
 */
export function refreshTokenSafely(): Promise<AuthToken> {
	// if already refreshing token, return the same promise
	if (refreshingToken) {
		return refreshingToken
	}
	refreshingToken = refreshTokenAndApply()
	// clear promise reference after it completes
	refreshingToken
		.then(() => {
			refreshingToken = undefined
		})
		.catch(() => {
			refreshingToken = undefined
		})
	return refreshingToken
}