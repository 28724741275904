import actionCreatorFactory, { Action, Failure, Success } from 'typescript-fsa'
import { Product as ApiProduct, ProductList, ProductPrices, BranchProductDetail, BranchProductsResponse } from 'typescript-fetch-api'

import { PlatformProduct } from '../../modules/platform'
import { SpecSheet } from './types'
import { DownloadInvoicePayload } from '../financials/actions'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Product')

export interface ViewProductPayload {
	productSku: string
	productDescription?: string
	productDetails?: PlatformProduct
	hasExtraParams?: boolean
}
export type ProductSelectedAction = Action<ViewProductPayload>
export const navigateToProduct = actionCreator<ViewProductPayload>('NAVIGATE_TO_PRODUCT')

export interface ViewPDFPayload {
	productId: string,
	productTitle?: string,
	imageId: string, // used in pdf file name
	pdfId: number, // used in pdf file name
	type: SpecSheet | undefined
}
export type ViewPdfAction = Action<ViewPDFPayload | DownloadInvoicePayload>
export const navigateToPDFViewer = actionCreator<ViewPDFPayload | DownloadInvoicePayload>('NAVIGATE_TO_PDF_VIEWER')

export interface LoadProductDetailsPayload {
	productSku: string
}
export type LoadProductDetailsAction = Action<LoadProductDetailsPayload>
export const loadProductDetails = actionCreator.async<LoadProductDetailsPayload, PlatformProduct, Error>('LOAD_PRODUCT_DETAILS')

export interface LoadProductListsPayload {
	productSku: string
	customerId?: number
}
export type LoadProductListsAction = Action<LoadProductListsPayload>
export const loadProductLists = actionCreator.async<LoadProductListsPayload, ProductList[], Error>('LOAD_PRODUCT_LISTS')

export interface CheckProductInPriceBookPayload {
	sku: string
	customerId: number
}
export type CheckProductInPriceBookAction = Action<CheckProductInPriceBookPayload>
export const checkProductInPriceBook = actionCreator.async<CheckProductInPriceBookPayload, boolean, Error>('CHECK_PRODUCT_IN_PRICE_BOOK')

export interface IncludeProductInPriceBookPayload {
	sku: string
	isIncluded: boolean
}
export type IncludeProductInPriceBookAction = Action<IncludeProductInPriceBookPayload>
export const includeProductInPriceBook = actionCreator.async<IncludeProductInPriceBookPayload, Response, Error>('INCLUDE_PRODUCT_IN_PRICE_BOOK')

export const navigateToBarcodeScanner = actionCreator('NAVIGATE_TO_BARCODE_SCANNER')

export const navigateToProductSearch = actionCreator('NAVIGATE_TO_PRODUCT_SEARCH')

// first parameter is the barcode to search by
export type LoadProductForBarcodeSuccessAction = Action<Success<string, PlatformProduct>>
export const loadProductForBarcode = actionCreator.async<string, PlatformProduct, Error>('LOAD_PRODUCT_FOR_BARCODE')

export const clearBarcodeDetails = actionCreator('CLEAR_BARCODE_DETAILS')

export type SearchProductForBarcodeApiSuccessAction = Action<Success<string, ApiProduct | undefined>>
export type SearchProductForBarcodeApiFailureAction = Action<Failure<string, Error>>
export const searchProductForBarcodeAPI = actionCreator.async<string, ApiProduct | undefined, Error>('SEARCH_PRODUCT_FOR_BARCODE_API')

export const loadProductPrices = actionCreator.async<string, ProductPrices | undefined, Error>('LOAD_PRODUCT_PRICES')
export const loadProductPricesForBarcode = actionCreator.async<string, ProductPrices | undefined, Error>('LOAD_PRODUCT_PRICES_FOR_BARCODE')

export interface GetProductAvailabilityPayload {
	productSku: string
	branchId: number
}
export type GetProductAvailabilityAction = Action<GetProductAvailabilityPayload>
export const getProductAvailability = actionCreator.async<GetProductAvailabilityPayload, BranchProductDetail, Error>('GET_PRODUCT_AVAILABILITY')
export const getProductAvailabilityForBarcode = actionCreator.async<GetProductAvailabilityPayload, BranchProductDetail, Error>('GET_PRODUCT_AVAILABILITY_FOR_BARCODE')

export interface GetBranchesAvailabilityPayload {
	productSku: string
	regionId: string
	branchIds: string[]
}
export type GetBranchesAvailabilityAction = Action<GetBranchesAvailabilityPayload>
export const getBranchesAvailability = actionCreator.async<GetBranchesAvailabilityPayload, BranchProductsResponse, Error>('GET_BRANCHES_AVAILABILITY')
