import { WebStoreState } from './types'

/**
 * 
 * @param state Select the current pagination size from the root store state.
 */
export const paginationSizeSelector = (state: WebStoreState): number => state.platform.paginationSize

/**
 * 
 * @param state Select the current hnzMode from the root store state.
 */
export const hnzModeSelector = (state: WebStoreState): boolean => state.hnz.hnzMode
