import '../styles/AccountsTable.scss'

import React, { useState, useEffect } from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip, TableContainer } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AccountEmailOptIn } from 'typescript-fetch-api'

import { UserAccount, AccountStatus } from '../../../common/accounts/types'
import AccountRow from './AccountRow'
import EditLoginId from './EditLoginId'
import { updateAccountsEmailOptIn, clearAccountsEmailOptInState } from '../../../common/accounts/actions'
import { WebStoreState } from '../../platform/types'
import { ApiErrorMessage } from '../../../common/api'
import { colors } from '../../platform/muiTheme'
import { isAdminOrPrincipal } from '../../../common/mylists/functions'

enum MessageType {
	SUCCESS, ERROR
}

interface Props {
	accounts: UserAccount[]
	onAddAccount: () => void
}

const AccountsTable = ({ accounts }: Props): JSX.Element => {

	/**
	 * DEPENDENCY HOOKS
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */

	const isLoading = useSelector<WebStoreState, boolean>(state => state.accounts.updatingAccountsEmailOptIn)
	const success = useSelector<WebStoreState, boolean>(state => state.accounts.updateAccountsEmailOptInSuccess)
	const error = useSelector<WebStoreState, Error | undefined>(state => state.accounts.updateAccountsEmailOptInError)

	/**
	 * Local State
	 */

	const initialEmailOptInAccounts: AccountEmailOptIn[] = accounts
		.filter(account => {
			// filter approved and admin or principal accounts
			return account.registrationState === AccountStatus.Approved && isAdminOrPrincipal(account)
		})
		.map(account => ({
			accountId: account.id,
			optedIn: account.orderEmailOptIn,
		}))

	const [emailOptInAccounts, setEmailOptInAccounts] = useState<AccountEmailOptIn[]>(initialEmailOptInAccounts)
	const [showSuccessMessage, setShowSuccessMessage] = useState(success)
	const [showErrorMessage, setShowErrorMessage] = useState(!!error)

	/**
	 * Effects / Subscriptions
	 */

	useEffect(() => {
		dispatch(clearAccountsEmailOptInState())
	}, [dispatch])

	useEffect(() => {
		setShowSuccessMessage(success)
	}, [success])

	useEffect(() => {
		setShowErrorMessage(!!error)
	}, [error])

	/**
	 * Local Functions
	 */

	const _onAccountEmailOptIn = (accountId: number, optIn: boolean): void => {
		// if loading previous request, wait
		if (isLoading) return

		// update email opt in value
		const updatedAccounts = [
			...emailOptInAccounts.filter(account => account.accountId !== accountId),
			{ accountId, optedIn: optIn },
		]
		setEmailOptInAccounts(updatedAccounts)

		// hide all the messages
		setShowSuccessMessage(false)
		setShowErrorMessage(false)

		// if no email, we will render an input for user to update their email too
		const hasNoEmail = !accounts[0].email
		if (hasNoEmail) return

		// perform the update now
		dispatch(updateAccountsEmailOptIn.started({ accounts: updatedAccounts }))
	}

	const _renderActions = (): JSX.Element | null => {
		// check if the user updated any of the opt-in states
		const hasUpdatedOptInState = initialEmailOptInAccounts.some(account => (
			emailOptInAccounts.find(emailOptInAccount => (
				emailOptInAccount.accountId === account.accountId && emailOptInAccount.optedIn !== account.optedIn
			))
		))

		let ActionComponent
		const hasNoEmail = !accounts[0].email
		if (hasUpdatedOptInState && hasNoEmail) {
			ActionComponent = (
				<EditLoginId
					isLoading={isLoading}
					success={success}
					error={!!error}
					onUpdateLoginId={(email: string) => {
						dispatch(updateAccountsEmailOptIn.started({ accounts: emailOptInAccounts, email }))
					}}
				/>
			)
		}

		const _renderMessage = (title: string, description: string, type: MessageType): JSX.Element => {
			const style = type === MessageType.SUCCESS ? 'accounts-table__text--success' : 'accounts-table__text--error'
			return (
				<div className="accounts-table__message">
					<p className={`accounts-table__text ${style} accounts-table__text--em`}>
						{title}
					</p>
					<span className={`accounts-table__text ${style}`}>
						{description}
					</span>
				</div>
			)
		}

		return (
			<React.Fragment>
				{ActionComponent}

				{showSuccessMessage && _renderMessage(
					'Notification Set!',
					'All order confirmation emails will be\nsent to your Login ID email.',
					MessageType.SUCCESS
				)}

				{showErrorMessage && error && _renderMessage(
					'Failed to Set Notification',
					error.message || ApiErrorMessage.GENERIC_ERROR_MESSAGE,
					MessageType.ERROR
				)}
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<div className="accounts-table">
				<TableContainer className="accounts-table__table">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align="center" style={{ width: '5%' }}>Status</TableCell>
								<TableCell style={{ width: '55%' }}>Account</TableCell>
								<TableCell align="center" style={{ width: '10%' }}>Role</TableCell>
								<TableCell align="center" style={{ width: '30%' }}>
									<Tooltip
										title="Track placed orders within an account by receiving copies of all order confirmation sent to users. Notification will be sent to your Login ID email. For Principal and Admin Roles only."
										placement="bottom-end"
									>
										<div className="accounts-table__notify">
											<span>Notify Me</span>
											<span className="icon-info" />
										</div>
									</Tooltip>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{accounts.map(account => {
								const emailOptInAccount = emailOptInAccounts.find(item => item.accountId === account.id)
								return (
									<AccountRow
										key={account.id}
										account={account}
										optedIn={!!emailOptInAccount && !!emailOptInAccount.optedIn}
										// based on the `initialEmailOptInAccounts`, only Admin/Principal AND Approved accounts should be selectable
										isSelectable={!!emailOptInAccount}
										onAccountEmailOptIn={_onAccountEmailOptIn}
									/>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>

			{_renderActions()}

			<div className='accounts-table__footer'>
				<span className="accounts-table__footer--text">Status</span>
				<div className="accounts-table__footer--status-container"><span className="accounts-table__footer--status-icon" style={{ background: colors.tealMain }} /><span className="accounts-table__footer--text">Approved</span></div>
				<div className="accounts-table__footer--status-container"><span className="accounts-table__footer--status-icon" style={{ background: colors.orange }} /><span className="accounts-table__footer--text">Pending</span></div>
				<div className="accounts-table__footer--status-container"><span className="accounts-table__footer--status-icon" style={{ background: colors.redMain }} /><span className="accounts-table__footer--text">Disabled</span></div>
			</div>

		</React.Fragment>
	)
}

export default AccountsTable
