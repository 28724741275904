import '../styles/CartItemDetailed.scss'

import * as React from 'react'
import { IconButton, TextField, Paper } from '@mui/material'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import { ProductInfo } from '../../platform'
import { getPriceTypeDisplayNameShort, PriceType } from '../../../common/accounts/types'
import ProductImage from '../../products/components/ProductImage'
import { OrderItem } from '../../../common/order/types'
import { getOrderPriceSummary } from '../functions'
import GoldProductLogo from '../../platform/components/GoldProductLogo'
import StockAvailabilityLogo from '../../products/components/StockAvailablityLogo'
import CommentIcon from '../../platform/components/CommentIcon'
import HnzLogo from '../../hnz/components/HnzLogo'
import ProductUom from '../../products/components/ProductUom'
import CartActions from './CartActions'
import Button from '../../../common-ui/components/Button'
import HazardRating from '../../platform/components/HazardRating'

const minValue = 1

interface Props {
	product: ProductInfo
	quantity: number
	comment?: string
	priceType: PriceType
	hasHnzAccountSelected?: boolean
	stockCount?: number
	isProductCommentsEnabled: boolean
	hideUom?: boolean
	onRemoveProductFromCart?: (product: OrderItem) => void
	onProductSelected?: (product: ProductInfo) => void
	onUpdateProductQuantityInCart?: (product: ProductInfo, quantity: number) => void
	onUpdateProductCommentInCart?: (product: ProductInfo, quantity: number, comment: string) => void
}

interface State {
	quantity: number
	showComment: boolean
	comment: string
	showRemoveProductPrompt: boolean
}

const INITIAL_STATE: State = {
	quantity: minValue,
	showComment: false,
	comment: '',
	showRemoveProductPrompt: false,
}

export default class CartItemDetailed extends React.PureComponent<Props, State> {

	state = INITIAL_STATE
	timeout: NodeJS.Timeout | undefined

	componentDidMount() {
		if (this.props.quantity) {
			this.setState({ quantity: this.props.quantity })
		}

		if (this.props.comment) {
			this.setState({ comment: this.props.comment })
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		// update the state quantity when the user adds new items from the product dialog
		if (prevProps.quantity !== this.props.quantity && this.props.quantity !== this.state.quantity) {
			this.setState({ quantity: this.props.quantity })
		}
	}

	componentWillUnmount() {
		if (this.timeout) clearTimeout(this.timeout) // Clear any existing timeout
	}

	/*
	 * Prop functions
	 */

	_handleProductSelected = () => {
		const { product, onProductSelected } = this.props
		onProductSelected!(product)
	}

	_handleRemoveProductFromCart = (e: React.MouseEvent) => {
		e.stopPropagation() // stop event propogation to parent
		const { product, quantity, onRemoveProductFromCart } = this.props
		const productAsOrderItem: OrderItem = { quantity, product }
		onRemoveProductFromCart!(productAsOrderItem)
	}

	_handleOnUpdateQuantity = (newQuantity: number) => {
		this.setState({ quantity: newQuantity }, () => {
			this.props.onUpdateProductQuantityInCart!(this.props.product, newQuantity)
		})
	}

	_handleToggleCommentVisible = () => {
		this.setState({ showComment: !this.state.showComment, showRemoveProductPrompt: false })
	}

	_handleCommentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (this.timeout) clearTimeout(this.timeout) // Clear any existing timeout

		const comment = e.target.value
		this.setState({ comment })

		// set a new timeout after 500ms (half a second)
		this.timeout = setTimeout(() => {
			this.props.onUpdateProductCommentInCart!(this.props.product, this.props.quantity, comment)
		}, 500)
	}

	_handleShowRemoveProductPrompt = () => {
		this.setState({ showRemoveProductPrompt: true, showComment: false })
	}

	_handleHideRemoveProductPrompt = () => {
		this.setState({ showRemoveProductPrompt: false })
	}

	_handleAddToCart = () => {
		console.log('Add to cart')
	}

	render() {
		const { quantity, showComment, comment, showRemoveProductPrompt } = this.state
		const { product, hasHnzAccountSelected, priceType, stockCount, isProductCommentsEnabled, hideUom } = this.props
		const isHnzProduct = hasHnzAccountSelected && product.hnz

		const productAsOrderItem: OrderItem = { quantity, product }
		const { subtotal } = getOrderPriceSummary([productAsOrderItem], priceType, !!hasHnzAccountSelected)

		const canSelectProduct = !!this.props.onProductSelected
		const canRemoveProduct = !!this.props.onRemoveProductFromCart
		const canUpdateProduct = !!this.props.onUpdateProductQuantityInCart
		const canUpdateComment = !!this.props.onUpdateProductCommentInCart

		return (
			<div
				key={product.sku}
				className="cart-item"
			>
				<div className="cart-item__details cart-item__content">
					<div
						className={`cart-item__details--main ${canSelectProduct ? 'cart-item__details--clickable' : ''}`}
						onClick={canSelectProduct ? this._handleProductSelected : undefined}
					>
						<span className="cart-item__text--heading">
							{product.longDescription}
						</span>

						<div className="cart-item__details--content">
							<ProductImage
								image={product.primaryImageTitle}
								alt={product.sku}
								className="cart-item__image"
								cartCount={quantity}
							/>

							<div className="cart-item__details--subcontent">
								<span className="cart-item__text">{product.sku}</span>

								<div className="cart-item__info-icons">
									{stockCount !== undefined && (
										<StockAvailabilityLogo
											stockCount={stockCount}
											quantity={quantity}
											sku={product.sku}
										/>
									)}
									{isHnzProduct && (
										<Paper
											className="cart-item__info-container"
											elevation={2}
										>
											<HnzLogo />
										</Paper>
									)}
									{product.isGoldProduct && (
										<GoldProductLogo
											className="cart-item__info-container"
											elevation={2}
										/>
									)}
									{product.hazardRating && (
										<HazardRating
											rating={product.hazardRating}
											elevation={2}
											className="cart-item__info-container"
										/>
									)}
								</div>
							</div>
						</div>
					</div>

					{isProductCommentsEnabled && (showComment ?
						<Paper elevation={2} className="cart-item__comment cart-item__comment--visible">
							<IconButton
								onClick={this._handleToggleCommentVisible}
								disableRipple={true}
							>
								<ExpandLessRoundedIcon fontSize="large" />
							</IconButton>
						</Paper>
						:
						<Paper elevation={2} className="cart-item__comment">
							<CommentIcon
								hasComment={!!comment}
								onClick={this._handleToggleCommentVisible}
							/>
						</Paper>
					)}
				</div>

				<div className="cart-item__actions cart-item__content">
					<div className="cart-item__actions--top">
						<div className="cart-item__pricing">
							<span className="cart-item__text--label">{getPriceTypeDisplayNameShort(priceType)}</span>
							<span className="cart-item__text--price">${isNaN(subtotal) ? '0.00' : (subtotal / quantity).toFixed(2)}</span>
							<span className="cart-item__text--label">Subtotal</span>
							<span className="cart-item__text--price">${isNaN(subtotal) ? '0.00' : subtotal.toFixed(2)}</span>
						</div>

						{canRemoveProduct && (
							<IconButton
								onClick={this._handleShowRemoveProductPrompt}
								className="cart-item__button--remove"
							>
								<CloseRoundedIcon fontSize="small" />
							</IconButton>
						)}
					</div>

					<div className="cart-item__actions--bottom">
						{product.uom && !hideUom && <ProductUom uoms={[product.uom]} selectedUom={product.uom} />}

						{!!canUpdateProduct && (
							<CartActions
								parentQuantity={quantity}
								isHnz={isHnzProduct}
								uom={product.uom}
								hideCartButton={true}
								onAddToCart={this._handleAddToCart}
								onUpdateQuantity={this._handleOnUpdateQuantity}
							/>
						)}
					</div>
				</div>

				{showComment && canUpdateComment && (
					<TextField
						variant="standard"
						InputProps={{
							disableUnderline: true,
							className: 'cart-item__input--root',
						}}
						inputProps={{
							className: 'cart-item__input--multiline',
						}}
						placeholder="Add your comment here"
						multiline={true}
						minRows={3}
						maxRows={3}
						value={comment}
						className="cart-item__footer"
						onChange={this._handleCommentChanged}
					/>
				)}

				{showRemoveProductPrompt && (
					<div className="cart-item__footer cart-item__footer--remove">
						<div className="cart-item__remove--content">
							<span className="cart-item__text--price">Remove Product?</span>
							<span className="cart-item__text">Are you sure you want to remove this product from the cart?</span>
						</div>

						<div className="cart-item__remove--actions">
							<Button
								kind="white"
								onClick={this._handleHideRemoveProductPrompt}
								className="cart-item__button--cancel"
							>
								Cancel
							</Button>

							<Button
								kind="keppel"
								onClick={this._handleRemoveProductFromCart}
							>
								Yes
							</Button>
						</div>
					</div>
				)}
			</div>
		)
	}
}