import { useSelector } from 'react-redux'

import { RootStoreState } from '../../root'
import { RemoteConfig } from '../types'
import { isEnabledRemoteConfig } from '../functions'

/**
 * A hook that checks if a specific remote config is enabled.
 * This hook serves as a mid-layer so we can gain reference of the redux store via `useSelector`.
 * 
 * @param remoteConfig the remote config key
 */
const useEnabledRemoteConfig = (remoteConfig: RemoteConfig): boolean => {
	// check if the config is enabled
	const isEnabled = useSelector<RootStoreState, boolean>(state => isEnabledRemoteConfig(remoteConfig, state))
	return isEnabled
}

export default useEnabledRemoteConfig
