import { BranchProductDetail, ProductPrices } from 'typescript-fetch-api'

import { OrderItem } from '../order/types'
import { LoadProductsCountPayload } from './actions'
import { TotalProductsCount } from './types'

export function getCartCount(productSku: string, orderItems: OrderItem[]): number {
	const orderItem = orderItems.find((element) => {
		return productSku === element.product.sku
	})
	return orderItem ? orderItem.quantity : 0
}

export const getProductPrices = (productSku: string, productPrices: ProductPrices[]): ProductPrices | undefined => {
	return productPrices.find(item => item.sku === productSku)
}

export const getProductStockCount = (productSku: string, productStockCounts: BranchProductDetail[]): number | undefined => {
	return productStockCounts.find(item => item.productId === productSku)?.stockAvailable
}

export const updateTotalProductsCount = (params: LoadProductsCountPayload, count: number, totalProductsCount: TotalProductsCount): TotalProductsCount | undefined => {
	switch (params.type) {
		case 'VIEW_ALL':
			return { ...totalProductsCount, allProductsCount: count || 0, allProductsParams: params, loadingAllProductsCount: false };
		case 'BRANCH':
			return { ...totalProductsCount, branchProductsCount: count || 0, branchProductsParams: params, loadingBranchProductsCount: false };
		default:
			return undefined
	}
}

export const updateTotalProductsCountLoadingState = (params: LoadProductsCountPayload, loading: boolean, totalProductsCount: TotalProductsCount): TotalProductsCount | undefined => {
	switch (params.type) {
		case 'VIEW_ALL':
			return { ...totalProductsCount, loadingAllProductsCount: loading };
		case 'BRANCH':
			return { ...totalProductsCount, loadingBranchProductsCount: loading };
		default:
			return undefined
	}
}