import { Channel, SagaIterator } from 'redux-saga'
import { actionChannel, call, flush, fork, race, take } from 'redux-saga/effects'
import { BranchProductsRequest, ProductPriceRequestList } from 'typescript-fetch-api'

import { callApi } from '../api/functions'
import * as actions from './actions'
import { getBranchApi, getContentApi } from '../api'

function* handleLoadPrices(action: actions.LoadProductFinderPricesAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.loadProductFinderPrices, ({ skus, customerId }: actions.LoadProductFinderPricesPayload) => {
		const products: ProductPriceRequestList = { products: skus }
		return getContentApi().getProductPrices({ productPriceRequestList: products, customerId })
	})
}

/**
 * This channel allows the queueing of requests for loading product prices.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 * NOTE: This channel also handles clearing the channel queue whenever a cancel action is dispatched.
 */
function* loadPricesChannel() {
	// 1. Create a channel to watch for `loadProductFinderPrices` actions and queue them
	const channel: Channel<actions.LoadProductFinderPricesAction> = yield actionChannel(actions.loadProductFinderPrices.started)

	while (true) {
		// 2. Pop request from the channel
		const action: actions.LoadProductFinderPricesAction = yield take(channel)

		const { cancel } = yield race({
			// use a blocking call and perform the requests sequentially
			task: call(handleLoadPrices, action),
			cancel: take(actions.cancelLoadProductFinderPrices)
		})

		if (cancel) {
			// clear the channel queue
			yield flush(channel)
		}
	}
}

function* handleLoadAvailability(action: actions.LoadProductFinderAvailabilityAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadProductFinderAvailability,
		({ skus, branchId }: actions.LoadProductFinderAvailabilityPayload) => {
			const products: BranchProductsRequest = { products: skus }
			return getBranchApi().getBranchProducts({ branchId, branchProductsRequest: products })
		}
	)
}

function* getAvailabilityChannel() {
	// 1. Create a channel to watch specific actions and queue them
	const channel: Channel<actions.LoadProductFinderAvailabilityAction> = yield actionChannel(actions.loadProductFinderAvailability.started)

	while (true) {
		// 2. Pop request from the channel
		const action: actions.LoadProductFinderAvailabilityAction = yield take(channel)

		const { cancel } = yield race({
			// use a blocking call and perform the requests sequentially
			task: call(handleLoadAvailability, action),
			cancel: take(actions.cancelLoadProductFinderAvailability)
		})

		if (cancel) {
			// clear the channel queue
			yield flush(channel)
		}
	}
}

export default function* (): SagaIterator {
	yield fork(loadPricesChannel)
	yield fork(getAvailabilityChannel)
}
