import 'react-app-polyfill/ie11'
import 'core-js/features/string'
import 'core-js/features/array'
import 'url-search-params-polyfill'
import 'es7-object-polyfill'
import smoothscroll from 'smoothscroll-polyfill'

import { createRoot } from 'react-dom/client'
import App from './App'
import './styles/index.scss'

// kick-off polyfill to allow `scroll-behavior`
smoothscroll.polyfill()

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(<App />)
