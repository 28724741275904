import '../../platform/styles/Menu.css'

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AccountPermission } from 'typescript-fetch-api'

import Menu from '../../platform/components/Menu'
import { WebStoreState, SubheaderMenuProps } from '../../platform/types'
import { navigateToTab } from '../../platform/actions'
import { navigateToPriceBook } from '../../../common/priceBook/actions'
import { navigateToIntegration } from '../../integrations/actions'
import useEnabledRemoteConfig from '../../../common/remoteConfig/hooks/useEnabledRemoteConfig'
import { RemoteConfig } from '../../../common/remoteConfig/types'

const AdminMenu = ({ anchorElement, isHnzAccount, onClickAway }: SubheaderMenuProps): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */

	// checks if the current selected account has appropriate integration permissions
	const hasIntegrationPermission = useSelector<WebStoreState, boolean>(({ order: { selectedAccount } }) => (
		!!selectedAccount
		&& !!selectedAccount.permissions
		&& selectedAccount.permissions.some(item => item === AccountPermission.EDIT_INTEGRATIONS)
	))
	const isPricebookFeatureEnabled = useEnabledRemoteConfig(RemoteConfig.PRICEBOOKS)

	return (
		<Menu anchorElement={anchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount}>
			<div className="menu__content-container">
				<h2 className="menu__text--heading">ADMIN</h2>

				<hr className="menu__separator" />

				<div className="menu__items menu__items--vertical">
					<h4
						className="menu__text"
						onClick={() => {
							dispatch(navigateToTab({ tab: 'admin' }))
							onClickAway()
						}}
					>
						Users
					</h4>
					{hasIntegrationPermission && (
						<h4
							className="menu__text"
							onClick={() => {
								dispatch(navigateToIntegration())
								onClickAway()
							}}
						>
							Integrations
						</h4>
					)}
					{isPricebookFeatureEnabled && (
						<h4
							className="menu__text"
							onClick={() => {
								dispatch(navigateToPriceBook())
								onClickAway()
							}}
						>
							Price Book
						</h4>
					)}
				</div>
			</div>
		</Menu>
	)
}

export default AdminMenu
