import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { SearchFilter, ProductListType, SearchFilterFilterTypeEnum } from 'typescript-fetch-api'

import * as actions from './actions'
import { loadProductsCount, resetProductsTab } from '../products/actions'
import { ProductSearchResult, PlatformProduct } from '../../modules/platform'
import { clearAuthToken, loggedOut } from '../auth/actions'
import { getPlatformSupportImplementation } from '../platform'
import { TotalProductsCount } from '../products/types'
import { updateTotalProductsCount, updateTotalProductsCountLoadingState } from '../products/functions'
import { addProductToList, removeProductFromList } from '../mylists/actions'
import { addProductToLabelGroup, removeProductFromLabelGroup } from '../labels/actions'

export interface StoreState {
	readonly searchText?: string
	readonly searchResults?: ProductSearchResult[]
	readonly totalPagesCount?: number
	readonly searchResultsCount?: number
	readonly fetchingSearchResults: boolean
	readonly errorFetchingSearchResults?: Error

	readonly quickSearchResults?: ReadonlyArray<PlatformProduct>
	readonly fetchingQuickSearchResults: boolean
	readonly errorFetchingQuickSearchResults?: Error

	readonly searchResultFilters?: SearchFilter[]
	readonly fetchingSearchResultFilters: boolean
	readonly appliedSearchResultFilters?: SearchFilter[]

	readonly searchResultCategoryFilters: SearchFilter[]

	readonly fetchingSearchResultStockCount: boolean

	readonly fetchingSearchResultPrices: boolean

	// flags to help identify if we have hnz only products in the search
	readonly searchExcludesHnzProducts?: boolean
	readonly quickSearchExcludesHnzProducts?: boolean

	readonly searchedProductsForLabelGroup: ReadonlyArray<PlatformProduct>
	readonly searchedProductsPage?: number
	readonly searchedProductsPageCount?: number
	readonly fetchingProductsForLabelGroup: boolean
	readonly errorFetchingProductsForLabelGroup?: Error

	readonly searchedProductsForList: ReadonlyArray<PlatformProduct>
	readonly searchedProductsPageForList?: number
	readonly searchedProductsPageCountForList?: number
	readonly fetchingProductsForList: boolean
	readonly errorFetchingProductsForList?: Error

	// array of product skus being added to list by quick search so we can display a loading spinner for each product
	readonly productsBeingAddedToList: string[]

	readonly productsCount: TotalProductsCount
}

const INITIAL_STATE: StoreState = {
	searchText: undefined,
	searchResults: undefined,
	totalPagesCount: undefined,
	searchResultsCount: undefined,
	fetchingSearchResults: false,
	errorFetchingSearchResults: undefined,

	quickSearchResults: undefined,
	fetchingQuickSearchResults: false,
	errorFetchingQuickSearchResults: undefined,

	searchResultFilters: undefined,
	fetchingSearchResultFilters: false,
	appliedSearchResultFilters: [],

	searchResultCategoryFilters: [],

	fetchingSearchResultStockCount: false,

	fetchingSearchResultPrices: false,

	searchExcludesHnzProducts: undefined,
	quickSearchExcludesHnzProducts: undefined,

	searchedProductsForLabelGroup: [],
	fetchingProductsForLabelGroup: false,
	errorFetchingProductsForLabelGroup: undefined,
	searchedProductsPage: undefined,
	searchedProductsPageCount: undefined,

	searchedProductsForList: [],
	searchedProductsPageForList: undefined,
	searchedProductsPageCountForList: undefined,
	fetchingProductsForList: false,
	errorFetchingProductsForList: undefined,

	productsBeingAddedToList: [],

	productsCount: {
		allProductsCount: undefined,
		branchProductsCount: undefined,
		allProductsParams: undefined,
		branchProductsParams: undefined,
		loadingAllProductsCount: false,
		loadingBranchProductsCount: false,
	},
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

/** Reducer function for the exampleAction that returns a new state using an implicit return. */
reducer.case(actions.textChangeAction, (state, payload): StoreState => {
	const hasUpdatedQuery: boolean = state.searchText !== payload.queryText
	if (hasUpdatedQuery) {
		// using pin feature to determine mobile
		const isMobile = getPlatformSupportImplementation().hasPinFeature()
		let appliedSearchResultFilters = undefined
		if (isMobile && state.appliedSearchResultFilters) {
			// clear brand and supplier filters when query text changes (we want to keep branch/gold filter applied). only used on mobile
			appliedSearchResultFilters = state.appliedSearchResultFilters.filter(item => item.filterType !== SearchFilterFilterTypeEnum.PRODUCT_RANGE && item.filterType !== SearchFilterFilterTypeEnum.SUPPLIER)
		}
		return {
			...state,
			searchText: payload.queryText,
			searchResults: undefined,
			searchResultFilters: undefined,
			appliedSearchResultFilters,
			totalPagesCount: undefined,
			searchResultsCount: undefined,
			fetchingSearchResults: true,
			errorFetchingSearchResults: undefined,
			searchExcludesHnzProducts: undefined,
		}
	}
	return state
})

reducer.case(actions.performQuickSearch.done, (state, payload): StoreState => {
	let searchResults: ProductSearchResult[] | undefined
	if (state.searchResults && payload.params.appendToList) {
		searchResults = [...state.searchResults, ...payload.result.products]
	} else {
		searchResults = payload.result.products
	}
	return {
		...state,
		searchResults,
		totalPagesCount: payload.result.totalPagesCount,
		searchResultsCount: payload.result.count,
		fetchingSearchResults: false,
		searchExcludesHnzProducts: payload.result.searchExcludesHnzProducts,
	}
})

reducer.case(actions.performQuickSearch.failed, (state, payload): StoreState => ({
	...state, totalPagesCount: undefined, searchResultsCount: undefined, fetchingSearchResults: false, errorFetchingSearchResults: payload.error
}))

reducer.case(actions.fetchSearchSuggestions.started, (state): StoreState => ({
	...state, fetchingQuickSearchResults: true, quickSearchResults: undefined, quickSearchExcludesHnzProducts: undefined, errorFetchingQuickSearchResults: undefined,
}))
reducer.case(actions.fetchSearchSuggestions.done, (state, { result }): StoreState => {
	return {
		...state, fetchingQuickSearchResults: false, quickSearchResults: result.products, quickSearchExcludesHnzProducts: result.searchExcludesHnzProducts,
	}
})
reducer.case(actions.fetchSearchSuggestions.failed, (state, { error }): StoreState => ({
	...state, fetchingQuickSearchResults: false, errorFetchingQuickSearchResults: error,
}))

reducer.case(actions.loadSearchFiltersAction.started, (state, payload): StoreState => ({
	...state, searchText: payload.queryText, searchResultFilters: undefined, fetchingSearchResultFilters: true,
}))

reducer.case(actions.loadSearchFiltersAction.done, (state, payload): StoreState => ({
	...state,
	searchResultFilters: payload.result,
	searchResultCategoryFilters: payload.params.clearCategoryFilters === false
		? state.searchResultCategoryFilters
		: payload.result.filter(item => item.filterType === SearchFilterFilterTypeEnum.CATEGORY),
	fetchingSearchResultFilters: false,
}))

reducer.case(actions.loadSearchFiltersAction.failed, (state): StoreState => ({
	...state, fetchingSearchResultFilters: false,
}))

reducer.case(actions.setLoadingSearchFiltersState, (state): StoreState => ({
	...state, fetchingSearchResultFilters: true,
}))

reducer.case(actions.saveSearchFilters, (state, payload): StoreState => ({
	...state, appliedSearchResultFilters: payload,
}))

reducer.case(actions.loadSearchPrices.started, (state): StoreState => ({
	...state, fetchingSearchResultPrices: true,
}))
reducer.case(actions.loadSearchPrices.done, (state, { result }): StoreState => {
	// loop through products and append pricing
	let searchResults: ProductSearchResult[] = []
	if (state.searchResults) {
		if (result.prices) {
			searchResults = getPlatformSupportImplementation().appendPricingToProducts(state.searchResults, result.prices)
		} else {
			searchResults = state.searchResults
		}
	}
	return {
		...state,
		fetchingSearchResultPrices: false,
		searchResults,
	}
})
reducer.case(actions.loadSearchPrices.failed, (state): StoreState => ({
	...state, fetchingSearchResultPrices: false,
}))

reducer.case(actions.searchCancelledAction, (): StoreState => ({
	...INITIAL_STATE
}))

/**
 * LIST/LABEL SEARCH
 */

reducer.case(actions.searchProductsForList.started, (state, payload): StoreState => {
	if (payload.productListType === ProductListType.LABEL) {
		return {
			...state,
			fetchingProductsForLabelGroup: true,
			// if loading next page, keep previous results (append to the array)
			searchedProductsForLabelGroup: !payload.page ? [] : state.searchedProductsForLabelGroup,
			searchedProductsPage: undefined,
			searchedProductsPageCount: undefined,
			errorFetchingProductsForLabelGroup: undefined,
		}
	}
	return {
		...state,
		fetchingProductsForList: true,
		// if loading next page, keep previous results (append to the array)
		searchedProductsForList: !payload.page ? [] : state.searchedProductsForList,
		searchedProductsPageForList: undefined,
		searchedProductsPageCountForList: undefined,
		errorFetchingProductsForList: undefined,
	}
})

reducer.case(actions.searchProductsForList.done, (state, { params, result }): StoreState => {
	if (params.productListType === ProductListType.LABEL) {
		return {
			...state,
			fetchingProductsForLabelGroup: false,
			// if loading next page, append current results to the array
			searchedProductsForLabelGroup: !params.page
				? result.products
				: state.searchedProductsForLabelGroup.concat(result.products),
			searchedProductsPage: result.page,
			searchedProductsPageCount: result.totalPages,
		}
	}
	return {
		...state,
		fetchingProductsForList: false,
		// if loading next page, append current results to the array
		searchedProductsForList: !params.page
			? result.products
			: state.searchedProductsForList.concat(result.products),
		searchedProductsPageForList: result.page,
		searchedProductsPageCountForList: result.totalPages,
	}
})

reducer.case(actions.searchProductsForList.failed, (state, { params, error }): StoreState => {
	if (params.productListType === ProductListType.LABEL) {
		return {
			...state,
			fetchingProductsForLabelGroup: false,
			errorFetchingProductsForLabelGroup: error,
		}
	}
	return {
		...state,
		fetchingProductsForList: false,
		errorFetchingProductsForList: error,
	}
})

reducer.case(actions.clearSearchedProductsForList, (state, productListType): StoreState => {
	if (productListType === ProductListType.LABEL) {
		return {
			...state,
			searchedProductsForLabelGroup: [],
			searchedProductsPage: undefined,
			searchedProductsPageCount: undefined,
			fetchingProductsForLabelGroup: false,
			errorFetchingProductsForLabelGroup: undefined,
		}
	}
	return {
		...state,
		searchedProductsForList: [],
		searchedProductsPageForList: undefined,
		searchedProductsPageCountForList: undefined,
		fetchingProductsForList: false,
		errorFetchingProductsForList: undefined,
	}
})

reducer.case(actions.searchInProgressAction, (state): StoreState => ({
	...state,
	searchResults: INITIAL_STATE.searchResults,
	totalPagesCount: INITIAL_STATE.totalPagesCount,
	searchResultsCount: INITIAL_STATE.searchResultsCount,
	fetchingSearchResults: true,
	errorFetchingSearchResults: INITIAL_STATE.errorFetchingSearchResults,
}))

// FETCHING STOCK COUNTS
reducer.case(actions.getSearchProductsAvailability.started, (state): StoreState => ({
	...state,
	fetchingSearchResultStockCount: true,
}))
reducer.case(actions.getSearchProductsAvailability.done, (state, { params, result }): StoreState => {
	// loop through products and append availability
	let searchResults: ProductSearchResult[] = []
	if (state.searchResults) {
		if (result.products) {
			searchResults = getPlatformSupportImplementation().appendAvailabilityToProducts(state.searchResults, result.products)
		} else {
			searchResults = state.searchResults
		}
	}
	return {
		...state,
		fetchingSearchResultStockCount: false,
		searchResults,
	}
})
reducer.case(actions.getSearchProductsAvailability.failed, (state): StoreState => ({
	...state,
	fetchingSearchResultStockCount: false,
}))

reducer.cases([resetProductsTab, loggedOut, clearAuthToken], (): StoreState => {
	/**
	 * we clear the search results in the following cases:
	 * - when the user updates products (clear out any old Realm objects)
	 * - when the user logs out
	 */
	return INITIAL_STATE
})

// FETCHING PRODUCT COUNTS
reducer.case(loadProductsCount.started, (state, params): StoreState => {
	if (params.queryText) {
		const updatedResult = updateTotalProductsCountLoadingState(params, true, state.productsCount)
		if (updatedResult) {
			return ({
				...state, productsCount: updatedResult,
			})
		}
	}
	return state
})
reducer.case(loadProductsCount.done, (state, { params, result }): StoreState => {
	if (params.queryText) {
		const updatedResult = updateTotalProductsCount(params, result, state.productsCount)
		if (updatedResult) {
			return ({
				...state, productsCount: updatedResult,
			})
		}
	}
	return state
})
reducer.case(loadProductsCount.failed, (state, { params }): StoreState => {
	if (params.queryText) {
		const updatedResult = updateTotalProductsCountLoadingState(params, false, state.productsCount)
		if (updatedResult) {
			return ({
				...state, productsCount: updatedResult,
			})
		}
	}
	return state
})

// add/remove product to list
reducer.cases([addProductToList.started, removeProductFromList.started], (state, payload): StoreState => {
	if (payload.screen === 'List Details') {
		const productsBeingAddedToList = [...state.productsBeingAddedToList]
		productsBeingAddedToList.push(payload.sku)
		return {
			...state,
			productsBeingAddedToList,
		}
	}
	return state
})
reducer.cases([addProductToList.done, removeProductFromList.done, addProductToList.failed, removeProductFromList.failed], (state, { params }): StoreState => {
	if (params.screen === 'List Details') {
		const productsBeingAddedToList = state.productsBeingAddedToList.filter(sku => sku !== params.sku)
		return {
			...state,
			productsBeingAddedToList,
		}
	}
	return state
})
reducer.cases([addProductToLabelGroup.started, removeProductFromLabelGroup.started], (state, payload): StoreState => {
	const productsBeingAddedToList = [...state.productsBeingAddedToList]
	productsBeingAddedToList.push(payload.sku)
	return {
		...state,
		productsBeingAddedToList,
	}
})
reducer.cases([addProductToLabelGroup.done, removeProductFromLabelGroup.done, addProductToLabelGroup.failed, removeProductFromLabelGroup.failed], (state, { params }): StoreState => {
	const productsBeingAddedToList = state.productsBeingAddedToList.filter(sku => sku !== params.sku)
	return {
		...state,
		productsBeingAddedToList,
	}
})