import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { Product } from 'typescript-fetch-api'

import * as actions from './actions'
import { appendAvailabilityToApiProducts, appendPricingToApiProducts } from '../util/functions'

export interface StoreState {
	readonly watts: Product[]
	readonly wilkins: Product[]
	readonly lowMediumHazard: Product[]
	readonly highHazard: Product[]
	readonly spareParts: Product[]

	readonly fetchingBackflowProductsByBrand: boolean
	readonly fetchingBackflowProductsByType: boolean
	readonly fetchingPrices: boolean
	readonly fetchingStockCounts: boolean

	readonly errorFetchingBackflowProductsByBrand?: Error
	readonly errorFetchingBackflowProductsByType?: Error

	readonly sendingContactUs: boolean
	readonly sendingContactUsError?: Error
}

const INITIAL_STATE: StoreState = {
	watts: [],
	wilkins: [],
	lowMediumHazard: [],
	highHazard: [],
	spareParts: [],
	fetchingBackflowProductsByBrand: false,
	fetchingBackflowProductsByType: false,
	fetchingPrices: false,
	fetchingStockCounts: false,
	errorFetchingBackflowProductsByBrand: undefined,
	errorFetchingBackflowProductsByType: undefined,
	sendingContactUs: false,
	sendingContactUsError: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer
	// by brand
	.case(actions.fetchBackflowProductsByBrand.started, (state): StoreState => ({
		...state,
		fetchingBackflowProductsByBrand: true,
		errorFetchingBackflowProductsByBrand: undefined,
		lowMediumHazard: [],
		highHazard: [],
		spareParts: [],
	}))
	.case(actions.fetchBackflowProductsByBrand.done, (state, { result }): StoreState => ({
		...state,
		fetchingBackflowProductsByBrand: false,
		lowMediumHazard: result.lowMediumHazard || [],
		highHazard: result.highHazard || [],
		spareParts: result.spareParts || [],
	}))
	.case(actions.fetchBackflowProductsByBrand.failed, (state, payload): StoreState => ({
		...state,
		fetchingBackflowProductsByBrand: false,
		errorFetchingBackflowProductsByBrand: payload.error,
	}))

	// by type
	.case(actions.fetchBackflowProductsByType.started, (state): StoreState => ({
		...state,
		fetchingBackflowProductsByType: true,
		errorFetchingBackflowProductsByType: undefined,
		watts: [],
		wilkins: [],
	}))
	.case(actions.fetchBackflowProductsByType.done, (state, { result }): StoreState => ({
		...state,
		fetchingBackflowProductsByType: false,
		watts: result.watts || [],
		wilkins: result.wilkins || [],
	}))
	.case(actions.fetchBackflowProductsByType.failed, (state, payload): StoreState => ({
		...state,
		fetchingBackflowProductsByType: false,
		errorFetchingBackflowProductsByType: payload.error,
	}))

	// prices
	.case(actions.loadBackflowProductPrices.started, (state): StoreState => ({
		...state, fetchingPrices: true,
	}))
	.case(actions.loadBackflowProductPrices.done, (state, { result }): StoreState => {
		const prices = result.prices || []
		return {
			...state,
			fetchingPrices: false,
			watts: appendPricingToApiProducts(state.watts, prices),
			wilkins: appendPricingToApiProducts(state.wilkins, prices),
			lowMediumHazard: appendPricingToApiProducts(state.lowMediumHazard, prices),
			highHazard: appendPricingToApiProducts(state.highHazard, prices),
			spareParts: appendPricingToApiProducts(state.spareParts, prices),
		}
	})
	.case(actions.loadBackflowProductPrices.failed, (state): StoreState => ({
		...state, fetchingPrices: false,
	}))

	// availability
	.case(actions.getBackflowProductsAvailability.started, (state): StoreState => ({
		...state, fetchingStockCounts: true,
	}))
	.case(actions.getBackflowProductsAvailability.done, (state, { result }): StoreState => {
		const stockCounts = result.products || []
		return {
			...state,
			fetchingStockCounts: false,
			watts: appendAvailabilityToApiProducts(state.watts, stockCounts),
			wilkins: appendAvailabilityToApiProducts(state.wilkins, stockCounts),
			lowMediumHazard: appendAvailabilityToApiProducts(state.lowMediumHazard, stockCounts),
			highHazard: appendAvailabilityToApiProducts(state.highHazard, stockCounts),
			spareParts: appendAvailabilityToApiProducts(state.spareParts, stockCounts),
		}
	})
	.case(actions.getBackflowProductsAvailability.failed, (state): StoreState => ({
		...state, fetchingStockCounts: false,
	}))

	// contact us
	.case(actions.sendContactUsBackflow.started, (state): StoreState => ({
		...state, sendingContactUs: true, sendingContactUsError: undefined,
	}))
	.case(actions.sendContactUsBackflow.done, (state): StoreState => ({
		...state, sendingContactUs: false, sendingContactUsError: undefined,
	}))
	.case(actions.sendContactUsBackflow.failed, (state, { error }): StoreState => ({
		...state, sendingContactUs: false, sendingContactUsError: error,
	}))

