import { BranchProductDetail, PagedProductListResponse, ProductList, ProductListDomain, ProductListGroup, ProductPrices } from 'typescript-fetch-api'

import { API_PWGO_LISTS_TITLE, API_USER_LISTS_TITLE, MY_LISTS_ID, MY_LISTS_TITLE, PWGO_LISTS_ID, PWGO_LISTS_TITLE, UNICODE_LONG_DASH } from './content'
import { Role, UserAccount } from '../accounts/types'
import { getSortedLabelGroups } from '../labels/functions'
import { AccountList } from './types'

export const DEFAULT_LIST_PAGE_SIZE = 24

/**
 * Handles updating the product lists in the store.
 * NOTE: we make an extra check if a list is selected, to prevent clearing the content from the selected list
 * 
 * @param lists the product lists saved in the store that we want to update
 * @param updatedLists the updated lists 
 * @param selectedListId the ID of the selected list, if any
 */
export const updateLists = (lists: ProductListGroup[], updatedLists: ProductListGroup[], selectedListId?: string) => {
	if (selectedListId) {
		// find the list group that contains the selected list
		const selectedListGroup = lists.find(list => list.lists?.some(list => list.id === selectedListId))
		if (selectedListGroup) {
			// find the selected list
			const selectedList = selectedListGroup.lists?.find(list => list.id === selectedListId)
			if (selectedList) {
				// updates all the lists except the selected list, to prevent clearing the content
				return updateList(updatedLists, selectedList, false, false)
			}
		}
	}
	// updates all the lists
	return updatedLists
}

/**
 * Handles adding/updating a product list in the current product list groups
 * @param lists the product lists
 * @param updatedList the new list to add
 * @param appendToList flag to append the items to the list or not
 * @param updatedListIncludePrices if the updated list contains pricing - if not we merge with existing pricing info
 * @returns the updated product lists
 */
export const updateList = (lists: ProductListGroup[], updatedList: ProductList, appendToList?: boolean, updatedListIncludePrices?: boolean): ProductListGroup[] => (
	lists.map(parentList => {
		// check which parent list the fetched list belongs to (ie user list or customer list)
		if (parentList.lists && parentList.lists.some(list => list.id === updatedList.id)) {
			return ({
				...parentList,
				// update the details of the matching list 
				lists: parentList.lists.map(list => {
					if (list.id === updatedList.id) {
						// add results to current list 
						if (appendToList) {
							const currentProductListItems = list.productListItems || []
							const newProductListItems = updatedList.productListItems || []
							return ({
								...updatedList,
								productListItems: [...currentProductListItems, ...newProductListItems],
							})
						}
						// if updated list does not contain pricing check if existing list has any pricing details, copy it across to the new list
						if (!updatedListIncludePrices) {
							return {
								...updatedList,
								productListItems: updatedList.productListItems?.map(product => {
									// find this item in existing list
									const existing = list.productListItems?.find(existingProduct => existingProduct.sku === product.sku)
									if (existing) {
										// if update item has pricing, use that, else fall back to existing pricing info
										return {
											...product,
											recommendedRetailPrice: product.recommendedRetailPrice || existing.recommendedRetailPrice,
											recommendedRetailPriceGst: product.recommendedRetailPriceGst || existing.recommendedRetailPriceGst,
											accountPrice: product.accountPrice || existing.accountPrice,
											accountPriceGst: product.accountPriceGst || existing.accountPriceGst,
											tradePrice: product.tradePrice || existing.tradePrice,
											tradePriceGst: product.tradePriceGst || existing.tradePriceGst,
											hnzPrice: product.hnzPrice || existing.hnzPrice,
											hnzPriceGst: product.hnzPriceGst || existing.hnzPriceGst,
										}
									}
									// else return new item untouched
									return product
								}),
							}
						}
						// TODO consider copying across quantityAvailable when merging item details
						return updatedList
					}
					return list
				}),
			})
		}
		return parentList
	})
)

/**
 * Handles adding/updating a product list in the current product list groups with product pricing
 * @param lists the product lists
 * @param listId id of list prices were fetched for
 * @param pricing the product pricing for the list
 * @returns the updated product lists
 */
export const updateListWithPricing = (lists: ProductListGroup[], listId: string, prices: Array<ProductPrices>): ProductListGroup[] => (
	lists.map(parentList => {
		// check which parent list the fetched list belongs to (ie user list or customer list)
		if (parentList.lists && parentList.lists.some(list => list.id === listId)) {
			return ({
				...parentList,
				// update the details of the matching list 
				lists: parentList.lists.map(list => {
					if (list.id === listId) {
						// update products with pricing
						const productListItems = list.productListItems?.map(item => {
							const pricing = prices.find(price => price.sku === item.sku)
							if (pricing) {
								// item found, append pricing info to the product details
								return {
									...item,
									...pricing,
								}
							}
							return item
						})
						return ({
							...list,
							productListItems,
						})
					}
					return list
				}),
			})
		}
		return parentList
	})
)

/**
 * Handles adding/updating a product list in the current product list groups with stock availbility
 * @param lists the product lists
 * @param listId id of list prices were fetched for
 * @param availabilities the availabilities for the list
 * @returns the updated product lists
 */
export const updateListWithAvailability = (lists: ProductListGroup[], listId: string, availabilities: Array<BranchProductDetail>): ProductListGroup[] => (
	lists.map(parentList => {
		// check which parent list the fetched list belongs to (ie user list or customer list)
		if (parentList.lists && parentList.lists.some(list => list.id === listId)) {
			return ({
				...parentList,
				// update the details of the matching list 
				lists: parentList.lists.map(list => {
					if (list.id === listId) {
						// update products with stock count
						const productListItems = list.productListItems?.map(item => {
							const availability = availabilities.find(availability => availability.productId === item.sku)
							if (availability) {
								// item found, append availability to the product details
								return {
									...item,
									quantityAvailable: availability.stockAvailable,
								}
							}
							return item
						})
						return ({
							...list,
							productListItems,
						})
					}
					return list
				}),
			})
		}
		return parentList
	})
)

/**
 * Maps a PagedProductListResponse to a ProductList - removes page info
 * @param pagedProductList the paged response
 * @returns a ProductList
 */
export const mapPagedProductListToProductList = (pagedProductList: PagedProductListResponse): ProductList => ({
	id: pagedProductList.id!,
	title: pagedProductList.title!,
	createdBy: pagedProductList.createdBy,
	branchId: pagedProductList.branchId,
	customerId: pagedProductList.customerId,
	productListItems: pagedProductList.productListItems,
	productListType: pagedProductList.productListType,
	favourited: pagedProductList.favourited,
	productCount: pagedProductList.count,
	productListDomain: pagedProductList.productListDomain,
	productListSchedule: pagedProductList.productListSchedule,
	ordinal: pagedProductList.ordinal,
})

/**
 * Loops through the product lists and updates the title of the matching list.
 * @param lists the product lists
 * @param listId the ID of the list to update
 * @param listTitle the updated title
 * @returns the updated product lists
 */
export const mapListsToUpdateListTitle = (lists: ProductListGroup[], listId: string, listTitle: string): ProductListGroup[] => {
	return lists.map(parentList => {
		if (!parentList.lists) return parentList

		// check if the list to update exists in the current parent list
		const listMatch = parentList.lists.some(list => list.id === listId)
		if (!listMatch) return parentList

		return {
			...parentList,
			lists: parentList.lists.map(list => {
				if (list.id === listId) {
					return {
						...list,
						title: listTitle,
					}
				}
				return list
			})
		}
	})
}

/**
 * Loops through the product lists and updates the pin/favourited value of the matching list.
 * @param lists the product lists
 * @param listId the ID of the list to update
 * @param pin the pin/favourited value
 * @returns the updated product lists
 */
export const mapListsToUpdatePin = (lists: ProductListGroup[], listId: string, pin: boolean) => {
	return lists.map(parentList => {
		if (!parentList.lists) return parentList

		// check if the list to update exists in the current parent list
		const listMatch = parentList.lists.some(list => list.id === listId)
		if (!listMatch) return parentList

		const updatedLists: ProductList[] = parentList.lists.map(list => {
			if (list.id === listId) {
				return {
					...list,
					favourited: pin,
				}
			}
			return list
		})

		return {
			...parentList,
			// sort the lists manually since we directly update the favourited flag from the list
			// NOTE: we're reusing the method for label groups since we apply the same logic
			lists: getSortedLabelGroups(updatedLists)
		}
	})
}

/**
 * Loops through the product lists and updates the comment of the matching product.
 * @param lists the product lists
 * @param listId the ID of the list to update
 * @param sku the sku of the product to update
 * @param comment the product comment
 * @returns the updated product lists
 */
export const mapListsToUpdateItemComment = (lists: ProductListGroup[], listId: string, sku: string, comment: string): ProductListGroup[] => {
	return lists.map(parentList => {
		if (!parentList.lists) return parentList

		// check if the list to update exists in the current parent list
		const listMatch = parentList.lists.some(list => list.id === listId)
		if (!listMatch) return parentList

		return {
			...parentList,
			lists: parentList.lists.map(list => {
				if (list.id === listId) {
					return {
						...list,
						productListItems: list.productListItems?.map(item => {
							if (item.sku === sku) {
								return {
									...item,
									comment,
								}
							}
							return item
						}),
					}
				}
				return list
			})
		}
	})
}

/**
 * Grabs the information for a product list group via the title.
 * NOTE: list group titles for accounts are setup as `SEMTEX LTD — 151206`
 * @param title the product list group title
 * @returns the details containing the name and customer ID
 */
export const getProductListGroupDetails = (title: string): { name: string, customerId?: string } => {
	const titles = title.split(UNICODE_LONG_DASH)
	return {
		name: titles[0].trim(),
		customerId: titles.length > 1 ? titles[1].trim() : undefined,
	}
}

/**
 * Checks if an account has has either an Admin or Principal role.
 * @param selectedAccount the account to check the role for
 * @returns true if the account has either an Admin or Principal role
 */
export const isAdminOrPrincipal = (selectedAccount: UserAccount | undefined): boolean => (
	!!selectedAccount && !!selectedAccount.accountRoleName && (selectedAccount.accountRoleName === Role.Admin || selectedAccount.accountRoleName === Role.Principal)
)

/**
 * Handles shaping the api list groups into list sections.
 * @param lists the list groups
 * @param hnzMode flag to know if in hnz mode
 * @param selectedAccount the selected account for the order
 * @returns the list sections
 */
export const getListSections = (lists: ProductListGroup[], hnzMode: boolean, selectedAccount?: UserAccount): AccountList[] => (
	lists
		.filter(group => {
			// always display users lists group
			if (group.title === API_USER_LISTS_TITLE) return true
			// only display groups with lists
			const hasLists = group.lists && group.lists.length > 0
			if (hasLists) {
				// in hnz mode, only display: user lists, pwGO lists and the current account's lists
				if (hnzMode) {
					if (!group.prostix_account_id && (group.title === API_USER_LISTS_TITLE || group.title === API_PWGO_LISTS_TITLE)) {
						return true
					} else if (selectedAccount && group.prostix_account_id === selectedAccount.customerId) {
						return true
					}
					return false
				}
				return true
			}
			return false
		})
		.map<AccountList>(group => {
			if (group.prostix_account_id) {
				return {
					id: group.prostix_account_id,
					title: group.title!,
					data: group.lists || [],
				}
			} else if (group.title === API_PWGO_LISTS_TITLE) {
				return {
					id: PWGO_LISTS_ID,
					title: PWGO_LISTS_TITLE,
					// only display pwGO lists with products
					data: group.lists ? group.lists.filter(list => list.productCount && list.productCount > 0) : [],
				}
			}
			return {
				id: MY_LISTS_ID,
				title: MY_LISTS_TITLE,
				data: group.lists || [],
			}
		})
		.sort((groupA: AccountList, groupB: AccountList) => {
			// perform sort order where:
			// 1 - user lists
			// 2 - alphabetical account lists
			// 3 - pwGO lists
			if (groupA.id === MY_LISTS_ID || groupB.id === PWGO_LISTS_ID) return -1
			if (groupB.id === MY_LISTS_ID || groupA.id === PWGO_LISTS_ID) return 1
			if (!groupA.title || !groupB.title) return 0
			return groupA.title < groupB.title ? -1 : 1
		})
)

/**
 * Handles checking for the product list group domain based on certain criteria
 * @param group the product list group
 * @returns the product list group domain
 */
export const getProductListGroupDomain = (group: ProductListGroup): ProductListDomain => {
	// checks if a list is an account list based on the ID
	// NOTE: We check for anything greater than MY_LISTS_ID (0), since PWGO_LISTS_ID is set as -1
	const isAccountListGroup = group.prostix_account_id !== undefined && group.prostix_account_id > MY_LISTS_ID
	if (isAccountListGroup) return ProductListDomain.ACCOUNT

	// checks if a list is a pwGO based on the following:
	// - API LIST TITLE: if data from the store
	// - LOCAL LIST ID: if data from the nav
	const isPwgoListGroup = group.title === API_PWGO_LISTS_TITLE || group.prostix_account_id === PWGO_LISTS_ID
	if (isPwgoListGroup) return ProductListDomain.PWGO

	// by default must be a user list
	return ProductListDomain.USER
}

/**
 * Checks if the user can delete a list or multiple lists from the group (account list or personal lists).
 * - Personal lists: can be deleted by all roles
 * - Account lists: can only be delete by Admin or Principal roles
 * - pwGO lists: can't be deleted on the client (only on the admin site)
 * @returns true if the user can delete a list
 */
export const canDeleteProductListGroup = (group: ProductListGroup, selectedAccount?: UserAccount): boolean => {
	if (!group.lists || group.lists.length === 0) return false
	const productListGroupDomain = getProductListGroupDomain(group)
	switch (productListGroupDomain) {
		case ProductListDomain.ACCOUNT:
			return isAdminOrPrincipal(selectedAccount)
		case ProductListDomain.PWGO:
			return false
		default:
			return true
	}
}