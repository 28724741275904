import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { locationChange } from '../../platform/actions'

import { setLocation, setNavigateFunction } from '../NavigationManager'

/**
 * Needed a way to get access to navigate hook to use from redux-sagas, without using history router.
 * https://stackoverflow.com/questions/68399876/how-to-navigate-outside-of-react-component-using-react-router-6/70002872#70002872
 */
const NavigateSetter = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()

	setNavigateFunction(navigate)
	setLocation(location)

	useEffect(() => {
		dispatch(locationChange({ pathname: location.pathname, search: location.search }))
	}, [location])

	return null
}

export default NavigateSetter