import { Branch, ClickAndCollectTimeslot, ClosureType, CreateLabelOrderDespatchMethodEnum, LabelOrder, LabelOrderItem, ProductList } from 'typescript-fetch-api'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import * as actions from '../labels/actions'
import * as authActions from '../auth/actions'
import * as rootActions from '../root/actions'
import * as cartActions from '../cart/actions'
import { LabelOrderInputs } from './types'
import { getSortedLabelGroups, LABEL_CART_LIMIT, updateLabelGroups } from './functions'
import { Filter } from '../util/types'
import { mapPagedProductListToProductList } from '../mylists/functions'

const MAX_LABEL_ORDER_ITEM_QUANTITY = 5
const PW_TEST_BRANCH_ID = 0

export interface StoreState {
	readonly labelGroups: ProductList[]

	readonly fetchingLabelGroups: boolean
	readonly errorFetchingLabelGroups?: Error

	// id of the current label group being fetched - we only fetch one label group at a time
	readonly isFetchingLabelGroupId?: string
	readonly selectedLabelGroupId?: string

	readonly editingLabelGroups: boolean
	readonly errorEditingLabelGroups?: Error
	readonly successEditingLabelGroups: boolean

	readonly deletingLabelGroup: boolean
	readonly errorDeletingLabelGroup?: Error

	// label cart
	readonly labelOrderItems: LabelOrderItem[]
	readonly [LabelOrderInputs.BRANCH_ID]?: number
	readonly [LabelOrderInputs.SHIPPING_ADDRESS]?: string
	readonly [LabelOrderInputs.SHIPPING_CITY]?: string
	readonly [LabelOrderInputs.SHIPPING_SUBURB]?: string
	readonly [LabelOrderInputs.DESPATCH_METHOD]: CreateLabelOrderDespatchMethodEnum
	readonly [LabelOrderInputs.DESPATCH_SLOT]?: ClosureType.AM | ClosureType.PM
	readonly [LabelOrderInputs.DESPATCH_CLICK_AND_COLLECT_TIMESLOT]?: ClickAndCollectTimeslot
	readonly [LabelOrderInputs.INSTRUCTIONS]?: string
	readonly [LabelOrderInputs.DATE_REQUIRED]: string
	readonly [LabelOrderInputs.RECIPIENT_NAME]?: string
	readonly [LabelOrderInputs.ON_SITE_CONTACT_NUMBER]?: string

	// label order
	readonly creatingLabelOrder?: string
	readonly errorCreatingLabelOrder?: Error

	// label orders
	readonly labelOrders: LabelOrder[]
	readonly totalLabelOrderCount?: number
	readonly fetchingLabelOrders: boolean
	readonly errorFetchingLabelOrders?: Error
	readonly filters: Filter[]
	readonly branches: Branch[]

	// download labels
	readonly downloadingLabels: boolean

	// label group upload
	readonly uploadingLabelGroup: boolean
	readonly successUploadingLabelGroup: boolean
	readonly errorUploadingLabelGroup?: Error
	readonly skippedProductsFromUpload?: string[]
}

const INITIAL_STATE: StoreState = {
	labelGroups: [],

	fetchingLabelGroups: false,
	errorFetchingLabelGroups: undefined,

	isFetchingLabelGroupId: undefined,

	editingLabelGroups: false,
	errorEditingLabelGroups: undefined,
	successEditingLabelGroups: false,

	deletingLabelGroup: false,
	errorDeletingLabelGroup: undefined,

	// label cart
	labelOrderItems: [],
	[LabelOrderInputs.BRANCH_ID]: undefined,
	[LabelOrderInputs.SHIPPING_ADDRESS]: undefined,
	[LabelOrderInputs.SHIPPING_CITY]: undefined,
	[LabelOrderInputs.SHIPPING_SUBURB]: undefined,
	[LabelOrderInputs.DESPATCH_METHOD]: CreateLabelOrderDespatchMethodEnum.CPU,
	[LabelOrderInputs.DESPATCH_SLOT]: undefined,
	[LabelOrderInputs.DESPATCH_CLICK_AND_COLLECT_TIMESLOT]: undefined,
	[LabelOrderInputs.INSTRUCTIONS]: undefined,
	[LabelOrderInputs.DATE_REQUIRED]: '',
	[LabelOrderInputs.RECIPIENT_NAME]: undefined,
	[LabelOrderInputs.ON_SITE_CONTACT_NUMBER]: undefined,

	// label order
	creatingLabelOrder: undefined,
	errorCreatingLabelOrder: undefined,

	// label orders
	labelOrders: [],
	totalLabelOrderCount: undefined,
	fetchingLabelOrders: false,
	errorFetchingLabelOrders: undefined,
	filters: [],
	branches: [],

	// download labels
	downloadingLabels: false,

	// label group upload
	uploadingLabelGroup: false,
	successUploadingLabelGroup: false,
	errorUploadingLabelGroup: undefined,
	skippedProductsFromUpload: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

// CLEARING STATE
reducer.case(rootActions.readyAction, (state): StoreState => {
	return {
		...state,
		fetchingLabelGroups: false,
		errorFetchingLabelGroups: undefined,

		isFetchingLabelGroupId: undefined,
		selectedLabelGroupId: undefined,

		editingLabelGroups: false,
		errorEditingLabelGroups: undefined,
		successEditingLabelGroups: false,

		deletingLabelGroup: false,
		errorDeletingLabelGroup: undefined,

		creatingLabelOrder: undefined,
		errorCreatingLabelOrder: undefined,

		fetchingLabelOrders: false,
		errorFetchingLabelOrders: undefined,
	}
})

// FETCHING
reducer.case(actions.fetchLabelGroups.started, (state): StoreState => {
	return {
		...state, fetchingLabelGroups: true, errorFetchingLabelGroups: undefined,
	}
})
reducer.case(actions.fetchLabelGroups.done, (state, payload): StoreState => {
	const updatedLabelGroups = payload.result || []
	return {
		...state,
		labelGroups: updateLabelGroups(state.labelGroups, updatedLabelGroups, state.selectedLabelGroupId),
		fetchingLabelGroups: false,
	}
})
reducer.case(actions.fetchLabelGroups.failed, (state, payload): StoreState => {
	return {
		...state, fetchingLabelGroups: false, errorFetchingLabelGroups: payload.error,
	}
})

// FETCH LABEL GROUP
reducer.case(actions.fetchLabelGroup.started, (state, params): StoreState => {
	return {
		...state,
		isFetchingLabelGroupId: params.id,
	}
})
reducer.case(actions.fetchLabelGroup.done, (state, { params, result }): StoreState => {
	return {
		...state,
		labelGroups: state.labelGroups.map(labelGroup => {
			if (labelGroup.id === result.id) {
				// add results to current list 
				if (params.appendToList) {
					const currentProductListItems = labelGroup.productListItems || []
					const newProductListItems = result.productListItems || []
					return ({
						...result,
						productListItems: [...currentProductListItems, ...newProductListItems],
					})
				}
				return result
			}
			return labelGroup
		}),
		isFetchingLabelGroupId: undefined,
	}
})
reducer.case(actions.fetchLabelGroup.failed, (state): StoreState => {
	return {
		...state,
		isFetchingLabelGroupId: undefined,
	}
})

// UPDATE SELECTED LABEL GROUP ID
reducer.case(actions.updateSelectedLabelGroupId, (state, labelGroupId): StoreState => {
	return {
		...state,
		selectedLabelGroupId: labelGroupId,
	}
})

// CREATING/EDITING LABELS GROUP
reducer.case(actions.createOrEditLabelsGroup.started, (state): StoreState => {
	return {
		...state,
		editingLabelGroups: true,
		errorEditingLabelGroups: undefined,
		successEditingLabelGroups: false,
	}
})
reducer.case(actions.createOrEditLabelsGroup.done, (state, { result }): StoreState => {
	return ({
		...state,
		labelGroups: result,
		editingLabelGroups: false,
		successEditingLabelGroups: true,
	})
})
reducer.case(actions.createOrEditLabelsGroup.failed, (state, payload): StoreState => {
	return {
		...state, editingLabelGroups: false, errorEditingLabelGroups: payload.error,
	}
})

// DELETING
reducer.case(actions.deleteLabelGroup.started, (state): StoreState => {
	return {
		...state, deletingLabelGroup: true, errorDeletingLabelGroup: undefined,
	}
})
reducer.case(actions.deleteLabelGroup.done, (state, { result }): StoreState => {
	return {
		...state,
		deletingLabelGroup: false,
		labelGroups: result,
	}
})
reducer.case(actions.deleteLabelGroup.failed, (state, { error }): StoreState => {
	return {
		...state, deletingLabelGroup: false, errorDeletingLabelGroup: error,
	}
})

// UPDATING ITEMS IN A LABEL GROUP
reducer.cases([actions.addProductToLabelGroup.started, actions.removeProductFromLabelGroup.started, actions.updateProductsInLabelGroup.started, actions.updateLabelGroupTitleAndProducts.started], (state): StoreState => {
	return {
		...state,
		editingLabelGroups: true,
		errorEditingLabelGroups: undefined,
		successEditingLabelGroups: false,
	}
})
reducer.cases([actions.addProductToLabelGroup.done, actions.removeProductFromLabelGroup.done, actions.updateProductsInLabelGroup.done, actions.updateLabelGroupTitleAndProducts.done], (state, { result }): StoreState => {
	return {
		...state,
		editingLabelGroups: false,
		successEditingLabelGroups: true,
		labelGroups: state.labelGroups.map(labelGroup => {
			if (labelGroup.id === result.id) {
				return result
			}
			return labelGroup
		}),
	}
})
reducer.cases([actions.addProductToLabelGroup.failed, actions.removeProductFromLabelGroup.failed, actions.updateProductsInLabelGroup.failed, actions.updateLabelGroupTitleAndProducts.failed], (state, { error }): StoreState => {
	return {
		...state,
		editingLabelGroups: false,
		errorEditingLabelGroups: error,
	}
})

// CLEARING LABEL GROUP
reducer.case(actions.clearLabelGroup.started, (state): StoreState => {
	return {
		...state,
		editingLabelGroups: true,
		errorEditingLabelGroups: undefined,
		successEditingLabelGroups: false,
	}
})
reducer.case(actions.clearLabelGroup.done, (state, { result }): StoreState => {
	return {
		...state,
		editingLabelGroups: false,
		successEditingLabelGroups: true,
		labelGroups: state.labelGroups.map(labelGroup => {
			if (labelGroup.id === result.id) {
				return result
			}
			return labelGroup
		}),
	}
})
reducer.case(actions.clearLabelGroup.failed, (state, { error }): StoreState => {
	return {
		...state,
		editingLabelGroups: false,
		errorEditingLabelGroups: error,
	}
})

// PIN/UNPIN LABEL GROUP & UPDATE LABEL GROUP TITLE
reducer.cases([actions.pinLabelGroup.started, actions.unpinLabelGroup.started, actions.updateLabelGroupTitle.started], (state): StoreState => {
	return {
		...state,
		editingLabelGroups: true,
		errorEditingLabelGroups: undefined,
		successEditingLabelGroups: false,
	}
})
reducer.case(actions.pinLabelGroup.done, (state, { params: id }): StoreState => {
	const updatedLabelGroups = state.labelGroups.map(labelGroup => {
		if (labelGroup.id === id) {
			return {
				...labelGroup,
				favourited: true,
			}
		}
		return labelGroup
	})
	return {
		...state,
		editingLabelGroups: false,
		successEditingLabelGroups: true,
		// sort the label groups manually since we directly update the favourited flag from the list
		labelGroups: getSortedLabelGroups(updatedLabelGroups),
	}
})
reducer.case(actions.unpinLabelGroup.done, (state, { params: id }): StoreState => {
	const updatedLabelGroups = state.labelGroups.map(labelGroup => {
		if (labelGroup.id === id) {
			return {
				...labelGroup,
				favourited: false,
			}
		}
		return labelGroup
	})
	return {
		...state,
		editingLabelGroups: false,
		successEditingLabelGroups: true,
		// sort the label groups manually since we directly update the favourited flag from the list
		labelGroups: getSortedLabelGroups(updatedLabelGroups),
	}
})
reducer.case(actions.updateLabelGroupTitle.done, (state, { params }): StoreState => {
	return {
		...state,
		editingLabelGroups: false,
		successEditingLabelGroups: true,
		labelGroups: state.labelGroups.map(labelGroup => {
			if (labelGroup.id === params.id) {
				return {
					...labelGroup,
					title: params.title,
				}
			}
			return labelGroup
		}),
	}
})
reducer.cases([actions.pinLabelGroup.failed, actions.unpinLabelGroup.failed, actions.updateLabelGroupTitle.failed], (state, { error }): StoreState => {
	return {
		...state,
		editingLabelGroups: false,
		errorEditingLabelGroups: error,
	}
})

// UPDATING ITEMS IN LABEL CART
reducer.case(actions.addItemQuantityToLabelCart, (state, { item }): StoreState => {
	const currentItem = state.labelOrderItems.find(labelOrderItem => labelOrderItem.productSku === item.productSku)
	if (currentItem) {
		const updatedQuantity = currentItem.quantity + item.quantity
		return {
			...state,
			labelOrderItems: state.labelOrderItems.map(labelOrderItem => {
				if (labelOrderItem.productSku === item.productSku) {
					return ({
						...item,
						quantity: updatedQuantity >= MAX_LABEL_ORDER_ITEM_QUANTITY ? MAX_LABEL_ORDER_ITEM_QUANTITY : updatedQuantity,
					})
				}
				return labelOrderItem
			})
		}
	}
	// restricts the number of items being added to the cart; we perform the check here since initially, the items are not loaded altogether
	if (state.labelOrderItems.length < LABEL_CART_LIMIT) {
		return {
			...state,
			labelOrderItems: [...state.labelOrderItems, item],
		}
	}
	return state
})
reducer.case(actions.subtractItemQuantityFromLabelCart, (state, { item }): StoreState => {
	const currentItem = state.labelOrderItems.find(labelOrderItem => labelOrderItem.productSku === item.productSku)
	if (currentItem) {
		const updatedQuantity = currentItem.quantity - item.quantity
		if (updatedQuantity <= 0) {
			return {
				...state,
				labelOrderItems: state.labelOrderItems.filter(labelOrderItem => labelOrderItem.productSku !== item.productSku),
			}
		}
		return {
			...state,
			labelOrderItems: state.labelOrderItems.map(labelOrderItem => {
				if (labelOrderItem.productSku === item.productSku) {
					return ({
						...item,
						quantity: updatedQuantity,
					})
				}
				return labelOrderItem
			})
		}
	}
	return {
		...state,
		labelOrderItems: [...state.labelOrderItems, item],
	}
})
reducer.case(actions.removeItemFromLabelCart, (state, { productSku }): StoreState => {
	return {
		...state,
		labelOrderItems: state.labelOrderItems.filter(item => item.productSku !== productSku),
	}
})

reducer.case(actions.updateItemQuantityInLabelCart, (state, payload): StoreState => {
	let items: LabelOrderItem[] = []
	// find the OrderItem by the product sku
	const currentOrderItem: LabelOrderItem | undefined = state.labelOrderItems.find(item => item.productSku === payload.item.productSku)

	if (currentOrderItem) {
		items = payload.quantity > 0
			// update the order item quantity
			? state.labelOrderItems.map(item => item.productSku !== payload.item.productSku ? item : { ...item, quantity: payload.quantity >= MAX_LABEL_ORDER_ITEM_QUANTITY ? MAX_LABEL_ORDER_ITEM_QUANTITY : payload.quantity })
			// if the quantity is zero or below we filter out the OrderItem from the current order items
			: state.labelOrderItems.filter(item => item.productSku !== payload.item.productSku)
	} else {
		if (payload.quantity > 0) {
			// add the payload OrderItem to the current list of order items
			items = [...state.labelOrderItems, payload.item]
		} else {
			items = state.labelOrderItems
		}
	}

	return { ...state, labelOrderItems: items }
})

reducer.case(actions.clearLabelCart, (state): StoreState => {
	return {
		...state, labelOrderItems: [],
	}
})

reducer.case(cartActions.branchSelected, (state, payload): StoreState => {
	return {
		...state,
		[LabelOrderInputs.BRANCH_ID]: payload ? parseInt(payload, 10) : undefined,
	}
})

// LABEL CHECKOUT 
reducer.case(actions.updateLabelOrder, (state, { id, value }): StoreState => {
	return {
		...state,
		[id]: value,
	}
})

reducer.case(actions.reOrderLabels, (state, payload): StoreState => {
	let labelOrderItems: LabelOrderItem[] = payload.orderItems.map(orderItem => {
		return {
			productSku: orderItem.productId,
			productImage: orderItem.productImage,
			productDescription: orderItem.productDescription,
			quantity: orderItem.quantity
		}
	})
	return {
		...state,
		labelOrderItems,
		[LabelOrderInputs.BRANCH_ID]: payload.branchId !== PW_TEST_BRANCH_ID ? payload.branchId : state[LabelOrderInputs.BRANCH_ID],
		[LabelOrderInputs.SHIPPING_ADDRESS]: payload.clientAddress,
		[LabelOrderInputs.SHIPPING_CITY]: payload.clientCity,
		[LabelOrderInputs.SHIPPING_SUBURB]: payload.clientSuburb,
		[LabelOrderInputs.DESPATCH_METHOD]: payload.despatchMethod as unknown as CreateLabelOrderDespatchMethodEnum,
		[LabelOrderInputs.INSTRUCTIONS]: payload.notes,
		[LabelOrderInputs.RECIPIENT_NAME]: payload.clientName,
		[LabelOrderInputs.ON_SITE_CONTACT_NUMBER]: payload.builderContact,
	}
})

// LABEL ORDER
reducer.case(actions.createLabelOrder.started, (state): StoreState => {
	return {
		...state,
		creatingLabelOrder: 'Creating Label Order…',
		errorCreatingLabelOrder: undefined,
	}
})
reducer.case(actions.createLabelOrder.done, (state): StoreState => {
	return {
		...state,
		creatingLabelOrder: undefined,
		// clear the label cart
		labelOrderItems: [],
		[LabelOrderInputs.SHIPPING_ADDRESS]: undefined,
		[LabelOrderInputs.SHIPPING_CITY]: undefined,
		[LabelOrderInputs.SHIPPING_SUBURB]: undefined,
		[LabelOrderInputs.DESPATCH_METHOD]: CreateLabelOrderDespatchMethodEnum.CPU,
		[LabelOrderInputs.DESPATCH_SLOT]: undefined,
		[LabelOrderInputs.DESPATCH_CLICK_AND_COLLECT_TIMESLOT]: undefined,
		[LabelOrderInputs.INSTRUCTIONS]: undefined,
		[LabelOrderInputs.DATE_REQUIRED]: '',
		[LabelOrderInputs.RECIPIENT_NAME]: undefined,
		[LabelOrderInputs.ON_SITE_CONTACT_NUMBER]: undefined,
	}
})
reducer.case(actions.createLabelOrder.failed, (state, { error }): StoreState => {
	return {
		...state,
		creatingLabelOrder: undefined,
		errorCreatingLabelOrder: error,
	}
})

// LABEL ORDERS
reducer.case(actions.fetchLabelOrders.started, (state, params): StoreState => {
	if (params.appendToList) {
		return {
			...state,
			fetchingLabelOrders: true,
			errorCreatingLabelOrder: undefined,
		}
	}
	return {
		...state,
		fetchingLabelOrders: true,
		labelOrders: [],
		totalLabelOrderCount: undefined,
		errorCreatingLabelOrder: undefined,
		branches: [],
	}
})
reducer.case(actions.fetchLabelOrders.done, (state, { params, result }): StoreState => {
	const labelOrders = result.orders || []
	return {
		...state,
		fetchingLabelOrders: false,
		labelOrders: params.appendToList
			? [...state.labelOrders, ...labelOrders]
			: labelOrders,
		totalLabelOrderCount: result.count,
		branches: result.branches || []
	}
})
reducer.case(actions.fetchLabelOrders.failed, (state, { error }): StoreState => {
	return {
		...state,
		fetchingLabelOrders: false,
		errorCreatingLabelOrder: error,
	}
})
reducer.case(actions.setLabelOrdersFiltersAction, (state, payload): StoreState => {
	return {
		...state, filters: payload.filters
	}
})

// FETCH LABEL ORDER
reducer.case(actions.fetchLabelOrder.done, (state, { result }): StoreState => {
	return {
		...state,
		labelOrders: state.labelOrders.map(labelOrder => labelOrder.id === result.id ? result : labelOrder),
	}
})

// LOGOUT - remove labels from the state
reducer.case(authActions.loggedOut, (state): StoreState => {
	return {
		...INITIAL_STATE,
		branchId: state.branchId,
	}
})
reducer.case(authActions.clearAuthToken, (): StoreState => {
	return INITIAL_STATE
})

// DOWNLOAD LABELS
reducer.case(actions.downloadLabels.started, (state): StoreState => {
	return {
		...state,
		downloadingLabels: true,
	}
})
reducer.cases([actions.downloadLabels.done, actions.downloadLabels.failed], (state): StoreState => {
	return {
		...state,
		downloadingLabels: false,
	}
})

// LABEL GROUP UPLOAD
reducer.case(actions.uploadLabelGroup.started, (state): StoreState => ({
	...state,
	uploadingLabelGroup: true,
	successUploadingLabelGroup: false,
	errorUploadingLabelGroup: undefined,
	skippedProductsFromUpload: undefined,
}))
reducer.case(actions.uploadLabelGroup.done, (state, payload): StoreState => {
	if (payload.result.list) {
		const updatedLabelGroup = mapPagedProductListToProductList(payload.result.list)
		let updatedLabelGroups

		const isExistingLabelGroup = state.labelGroups.some(item => item.id === updatedLabelGroup.id)
		if (isExistingLabelGroup) {
			// override existing label group details
			updatedLabelGroups = state.labelGroups.map(item => item.id === updatedLabelGroup.id ? updatedLabelGroup : item)
		} else {
			// append newly created label group
			updatedLabelGroups = [...state.labelGroups, updatedLabelGroup]
		}

		return {
			...state,
			labelGroups: getSortedLabelGroups(updatedLabelGroups),
			uploadingLabelGroup: false,
			successUploadingLabelGroup: true,
			skippedProductsFromUpload: payload.result.skippedProducts,
		}
	}
	return {
		...state,
		uploadingLabelGroup: false,
		successUploadingLabelGroup: true,
		skippedProductsFromUpload: payload.result.skippedProducts,
	}
})
reducer.case(actions.uploadLabelGroup.failed, (state, payload): StoreState => ({
	...state,
	uploadingLabelGroup: false,
	errorUploadingLabelGroup: payload.error,
}))
reducer.case(actions.clearUploadLabelGroupResult, (state): StoreState => ({
	...state,
	uploadingLabelGroup: false,
	successUploadingLabelGroup: false,
	errorUploadingLabelGroup: undefined,
	skippedProductsFromUpload: undefined,
}))