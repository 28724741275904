import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'

import { navigateToContactUs } from '../../common/contactus/actions'
import * as NavigationManager from '../navigation/NavigationManager'

function handleNavigateToContactUs() {
	NavigationManager.navigateToContact()
}

export default function* (): SagaIterator {
	yield takeEvery(navigateToContactUs, handleNavigateToContactUs)
}