import '../../../common-ui/components/LoaderErrorBoundary/styles.scss'

import React from 'react'
import { CircularProgress } from '@mui/material'

const Loader = (): JSX.Element => (
	<div className="loader">
		<CircularProgress color="primary" size={50} />
	</div>
)

export default Loader