import '../styles/UserProfile.scss'

import * as React from 'react'
import { FormControl, FormHelperText, TextField, Button, CircularProgress, IconButton, InputAdornment, Snackbar } from '@mui/material'

import * as Container from '../containers/BasicUserProfile'
import { validatePassword } from '../../../common/auth/functions'
import DrawerAccordion from '../../platform/components/DrawerAccordion'
import ShareholdingAccessDrawerAccordion from './ShareholdingAccessDrawerAccordion'

interface State {
	password: string
	confirmPassword: string
	oldPassword: string
	passwordShown: boolean
	confirmPasswordShown: boolean
	oldPasswordShown: boolean

	passwordError?: string
	confirmPasswordError?: string
	oldPasswordError?: string

	shareholderNo: string

	showSnackbar: boolean
	hasMadeChanges: boolean
}

const INITIAL_STATE: State = {
	password: '',
	confirmPassword: '',
	oldPassword: '',
	passwordShown: false,
	confirmPasswordShown: false,
	oldPasswordShown: false,
	shareholderNo: '',
	showSnackbar: false,
	hasMadeChanges: false,
}

/**
 * The BasicUserProfile component renders the basic profile details of a user.
 * 
 * This is being used in the following cases:
 * - the user doesn't have any accounts registered
 * - the user has no approved accounts
 */
export default class BasicUserProfile extends React.PureComponent<Container.OwnProps & Container.Props & Container.Actions> {

	state = INITIAL_STATE

	componentDidUpdate(prevProps: Container.Props & Container.Actions) {
		if (prevProps.updatePasswordSuccess !== this.props.updatePasswordSuccess && this.props.updatePasswordSuccess) {
			// user has successfully updated their password, clear the fields
			this.setState(INITIAL_STATE)
			return
		}

		const successRequestingShareholdingAccess = prevProps.successRequestingShareholdingAccess !== this.props.successRequestingShareholdingAccess && this.props.successRequestingShareholdingAccess
		const errorRequestingShareholdingAccess = prevProps.errorRequestingShareholdingAccess !== this.props.errorRequestingShareholdingAccess && this.props.errorRequestingShareholdingAccess
		if ((successRequestingShareholdingAccess || errorRequestingShareholdingAccess) && !this.state.showSnackbar) {
			this.setState({ showSnackbar: true, shareholderNo: '' })
			return
		}
	}

	_onUpdatePassword = () => {
		const { password, confirmPassword, oldPassword } = this.state
		if (password) {
			if (!oldPassword) {
				this.setState({ oldPasswordError: 'Please enter your old password' })
				return
			}

			const passwordError = validatePassword(password)
			if (passwordError) {
				this.setState({ passwordError: 'Password must be at least 8 characters long and contain a capital and numeric value' })
				return
			}
			if (!confirmPassword) {
				this.setState({ confirmPasswordError: 'Confirm password is required' })
				return
			} else if (password !== confirmPassword) {
				this.setState({ confirmPasswordError: 'Passwords do not match' })
				return
			}
		}

		// the user is attempting to update their password since they have the password fields filled
		if (oldPassword && password) {
			this.props.updateUserPassword(oldPassword, password)
		}
	}

	_handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, id } = e.target
		this.setState({ [id]: value, [`${id}Error`]: undefined, hasMadeChanges: true })
	}

	_handleToggleOldPasswordVisible = () => {
		this.setState({ oldPasswordShown: !this.state.oldPasswordShown })
	}

	_handleTogglePasswordVisible = () => {
		this.setState({ passwordShown: !this.state.passwordShown })
	}

	_handleToggleConfirmPasswordVisible = () => {
		this.setState({ confirmPasswordShown: !this.state.confirmPasswordShown })
	}

	_handleSnackbarClose = () => {
		this.setState({ showSnackbar: false })
	}

	_getSnackbarMessage = (): string | undefined => {
		const { account, updatePasswordSuccess, updatePasswordError, successRequestingShareholdingAccess, errorRequestingShareholdingAccess } = this.props
		if (updatePasswordError) {
			const action: string = account ? 'updating' : 'adding'
			return updatePasswordError!.message || `Error ${action} password`
		} else if (updatePasswordSuccess) {
			return `Successfully ${account ? 'updated' : 'added'} user`
		} else if (successRequestingShareholdingAccess) {
			return 'Successfully requested shareholder access'
		} else if (errorRequestingShareholdingAccess) {
			return errorRequestingShareholdingAccess.message || 'Error requesting shareholder access'
		}
		return undefined
	}

	_onUpdateShareholderNo = (shareholderNo: string) => {
		this.setState({ shareholderNo })
	}

	_onClearShareholderNo = () => {
		this.setState({ shareholderNo: '' })
	}

	_onRequestShareholdingAccess = () => {
		if (!this.state.shareholderNo) return

		// highly unlikely scenario since the user would've logged in either with their mobile/email
		const loginId = this.props.account.mobileNumber || this.props.account.email
		if (!loginId) return

		const shareholderNumber = parseInt(this.state.shareholderNo, 10)
		this.props.requestShareholdingAccess({ shareholderNumber, loginId })
	}

	_renderFooter = () => {
		if (this.state.shareholderNo) {
			return (
				<div className="user-profile__update-footer">
					<Button variant="contained" color="primary" className="user-profile__update-footer--button" onClick={this._onRequestShareholdingAccess} disabled={this.props.requestingShareholdingAccess}>
						{(this.props.requestingShareholdingAccess) ? <CircularProgress color="secondary" size="small" /> : 'SEND REQUEST'}
					</Button>
					<Button variant="outlined" className="user-profile__update-footer--button" onClick={this._onClearShareholderNo}>
						CANCEL
					</Button>
				</div>
			)
		}

		if (this.state.hasMadeChanges) {
			return (
				<div className="user-profile__update-footer">
					<Button variant="contained" color="primary" className="user-profile__update-footer--button" onClick={this._onUpdatePassword} disabled={this.props.updatingPassword}>
						{(this.props.updatingPassword) ? <CircularProgress color="secondary" size="small" /> : 'UPDATE'}
					</Button>
					<Button variant="outlined" className="user-profile__update-footer--button" onClick={this.props.onCancel}>
						CANCEL
					</Button>
				</div>
			)
		}

		return undefined
	}

	render() {
		const { password, confirmPassword, oldPassword, passwordError, confirmPasswordError, oldPasswordError, oldPasswordShown, passwordShown, confirmPasswordShown, shareholderNo, showSnackbar } = this.state
		const { firstName, lastName, email, mobileNumber } = this.props.account

		return (
			<React.Fragment>
				<div className="profile-drawer__container">
					<div className="profile-drawer__accounts">
						<DrawerAccordion title="Profile" iconName="icon-user" divider={true}>
							<div className="user-profile__row">
								<FormControl className="user-profile__form">
									<TextField
										type="text"
										id="firstName"
										label="First Name *"
										value={firstName || ''}
										disabled={true}
										onChange={this._handleInputChange}
										variant="outlined"
										autoComplete="off"
									/>
									<FormHelperText />
								</FormControl>

								<FormControl className="user-profile__form">
									<TextField
										type="text"
										id="lastName"
										label="Last Name *"
										value={lastName || ''}
										disabled={true}
										onChange={this._handleInputChange}
										variant="outlined"
										autoComplete="off"
									/>
									<FormHelperText />
								</FormControl>
							</div>

							<p className="user-profile__text user-profile__text--section">Login ID - Email and/or Mobile</p>

							<div className="user-profile__row">
								<FormControl className="user-profile__form">
									<TextField
										type="email"
										id="email"
										label="Email"
										value={email || ''}
										disabled={true}
										onChange={this._handleInputChange}
										variant="outlined"
										autoComplete="off"
										autoCapitalize="none"
									/>
									<FormHelperText />
								</FormControl>

								<FormControl className="user-profile__form">
									<TextField
										type="number"
										id="mobileNumber"
										label="Mobile"
										value={mobileNumber || ''}
										disabled={true}
										onChange={this._handleInputChange}
										variant="outlined"
										autoComplete="off"
										autoCapitalize="none"
									/>
									<FormHelperText />
								</FormControl>
							</div>

							<p className="user-profile__text user-profile__text--section">Change Password</p>

							<div className="user-profile__row">
								<FormControl className="user-profile__form">
									<TextField
										type={oldPasswordShown ? 'text' : 'password'}
										id="oldPassword"
										label="Old Password"
										onChange={this._handleInputChange}
										variant="outlined"
										value={oldPassword}
										autoComplete="off"
										autoCapitalize="none"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={this._handleToggleOldPasswordVisible}
														disableRipple={true}
														edge="end"
													>
														<span className={`icon-${oldPasswordShown ? 'visibility_off' : 'visibility'} user-profile__icon-visibility`} />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<FormHelperText error={!!oldPasswordError}>{oldPasswordError}</FormHelperText>
								</FormControl>
							</div>

							<div className="user-profile__row">
								<FormControl className="user-profile__form">
									<TextField
										type={passwordShown ? 'text' : 'password'}
										id="password"
										label="New Password"
										onChange={this._handleInputChange}
										variant="outlined"
										value={password}
										autoComplete="off"
										autoCapitalize="none"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={this._handleTogglePasswordVisible}
														disableRipple={true}
														edge="end"
													>
														<span className={`icon-${passwordShown ? 'visibility_off' : 'visibility'} user-profile__icon-visibility`} />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<FormHelperText error={!!passwordError}>{passwordError}</FormHelperText>
								</FormControl>

								<FormControl className="user-profile__form">
									<TextField
										type={confirmPasswordShown ? 'text' : 'password'}
										id="confirmPassword"
										label="Confirm New Password"
										onChange={this._handleInputChange}
										variant="outlined"
										value={confirmPassword}
										autoComplete="off"
										autoCapitalize="none"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={this._handleToggleConfirmPasswordVisible}
														disableRipple={true}
														edge="end"
													>
														<span className={`icon-${confirmPasswordShown ? 'visibility_off' : 'visibility'} user-profile__icon-visibility`} />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<FormHelperText error={!!confirmPasswordError}>{confirmPasswordError}</FormHelperText>
								</FormControl>
							</div>
						</DrawerAccordion>

						<ShareholdingAccessDrawerAccordion
							shareholderNo={shareholderNo}
							onUpdateShareholderNo={this._onUpdateShareholderNo}
						/>
					</div>

					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						open={showSnackbar}
						autoHideDuration={2000}
						onClose={this._handleSnackbarClose}
						message={this._getSnackbarMessage()}
					/>
				</div>

				{this._renderFooter()}
			</React.Fragment >
		)
	}
}