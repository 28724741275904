import React, { useState } from 'react'
import { TabPanel } from '@mui/lab'
import { Button, Box, FormControl, FormHelperText, TextField, CircularProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { ListDialogTabPanelEnum, ListDialogTabPanelProps } from '../types'
import { ListCategory } from '../../../common/mylists/types'
import ListCategoryInput from '../../mylists/components/ListCategoryInput'
import { createOrEditList } from '../../../common/mylists/actions'
import { ProductList, ProductListItem } from 'typescript-fetch-api'
import { LIST_NAME_MAX_LENGTH } from '../../mylists/types'

const CreateNewListTabPanel = (props: ListDialogTabPanelProps): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Local State
	 */

	const [quantityError, setQuantityError] = useState<string | undefined>(undefined)

	const [selectedListCategory, setSelectedListCategory] = useState<ListCategory | undefined>(undefined)
	const [selectedListCategoryError, setSelectedListCategoryError] = useState<string | undefined>(undefined)

	const [listName, setListName] = useState<string>('')
	const [listNameError, setListNameError] = useState<string | undefined>(undefined)

	const hnzOnly = props.product ? props.product.hnzOnly : props.items!.some(item => item.product.hnzOnly)

	return (
		<TabPanel value={ListDialogTabPanelEnum.CREATE_NEW_LIST}>
			<form className="list-dialog__form">
				{/* only show quantity if adding single product */}
				{props.product && (
					<React.Fragment>
						<FormControl fullWidth={true}>
							<TextField
								type="number"
								label="Quantity"
								value={props.quantity}
								error={!!quantityError}
								variant="outlined"
								autoComplete="off"
								autoFocus={true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const parsedQuantity = parseInt(e.target.value, 10)
									props.setQuantity(parsedQuantity)

									if (!isNaN(parsedQuantity) && parsedQuantity > 0) {
										setQuantityError(undefined)
									} else {
										setQuantityError(!parsedQuantity ? 'A quantity is required' : 'Invalid quantity')
									}
								}}
								inputProps={{
									min: 1,
								}}
							/>
							{quantityError && <FormHelperText error={true}>{quantityError}</FormHelperText>}
						</FormControl>

						<Box m={1} />
					</React.Fragment>
				)}

				<FormControl fullWidth={true}>
					<ListCategoryInput
						selectedAccount={props.selectedAccount}
						listCategory={selectedListCategory}
						hnzOnly={hnzOnly}
						error={!!selectedListCategoryError}
						onOptionSelected={(listCategory?: ListCategory) => {
							setSelectedListCategory(listCategory)
							setSelectedListCategoryError(!listCategory ? 'A list category is required' : undefined)
						}}
					/>
					{selectedListCategoryError && <FormHelperText error={true}>{selectedListCategoryError}</FormHelperText>}
				</FormControl>

				<Box m={1} />

				<FormControl fullWidth={true}>
					<TextField
						type="text"
						label={`List Name*${listName ? ` ${listName.length}/${LIST_NAME_MAX_LENGTH}` : ''}`}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const updatedListName = e.target.value
							setListName(updatedListName)

							if (updatedListName) {
								setListNameError(undefined)
							} else {
								setListNameError(!updatedListName.trim() ? 'Invalid list name' : 'A list name is required')
							}
						}}
						value={listName}
						error={!!listNameError}
						variant="outlined"
						inputProps={{ maxLength: LIST_NAME_MAX_LENGTH }}
					/>
					{listNameError && <FormHelperText error={true}>{listNameError}</FormHelperText>}
				</FormControl>

				<Box m={1.5} />

				{props.isLoading && <CircularProgress color="primary" />}

				{!props.isLoading && (
					<React.Fragment>
						<Button
							variant="contained"
							color="primary"
							fullWidth={true}
							onClick={() => {
								if (!props.quantity) {
									setQuantityError('A quantity is required')
								} else if (!selectedListCategory) {
									setSelectedListCategoryError('A list category is required')
								} else if (!listName) {
									setListNameError('A list name is required')
								} else if (!listName.trim()) {
									setListNameError('Invalid list name')
								} else {
									const productListItems: ProductListItem[] = props.product ?
										[{ sku: props.product.sku, quantity: props.quantity }] :
										props.items!.map(item => ({ sku: item.product.sku, quantity: item.quantity }))
									const list: ProductList = {
										id: uuidv4(),
										title: listName,
										productListItems,
										customerId: selectedListCategory.customerId,
									}
									dispatch(createOrEditList.started({
										list,
										customerId: props.selectedAccount && props.selectedAccount.customerId,
										screen: 'Product',
										goViewListsAfter: false,
									}))
								}
							}}
						>
							Add to New List
						</Button>

						<Box m={1} />

						<Button
							variant="outlined"
							fullWidth={true}
							onClick={props.onClose}
						>
							Cancel
						</Button>

						{props.error && (
							<React.Fragment>
								<Box m={1} />
								<p className="message error">{props.error.message || 'There was an error creating the list'}</p>
							</React.Fragment>
						)}
					</React.Fragment>
				)}
			</form>
		</TabPanel>
	)
}

export default CreateNewListTabPanel
