import * as React from 'react'
import { Divider } from '@mui/material'

import { OwnProps, Props, Actions } from '../containers/CategoryMenu'
import { Category } from '../../../common/categories/types'
import MenuPopper from '../../platform/components/MenuPopper'
import { ProductFinderType } from '../../../common/productfinder/types'
import { HWCProductFinder, GoldProductFinder, BackflowWaterProtectionLogo } from '../../../utils/imagesProvider'
import GoldStarsAnim from '../../search/components/GoldStarsAnim'

interface State {
	subAnchorElement: HTMLElement | null
	subSubAnchorElement: HTMLElement | null
	selectedCategory?: Category
	selectedSubCategory?: Category
}

const INITIAL_STATE: State = {
	subAnchorElement: null,
	subSubAnchorElement: null,
	selectedCategory: undefined,
	selectedSubCategory: undefined,
}

export default class CategoryMenuPopper extends React.PureComponent<OwnProps & Props & Actions, State> {

	state = INITIAL_STATE

	componentDidMount() {
		this.loadTopLevelCategories()
	}

	componentDidUpdate(prevProps: OwnProps & Props & Actions) {
		const { isHnzAccount } = this.props

		// the user just changed to a non-hnz account
		if (prevProps.isHnzAccount !== isHnzAccount) {
			// the user just changed to account with different hnz status, reload the top level categories
			this.loadTopLevelCategories()
		}
	}

	loadTopLevelCategories = () => {
		const { isHnzAccount, categories, hnzCategories, loadTopLevelCategories } = this.props
		if (isHnzAccount) {
			if (hnzCategories.length === 0) {
				loadTopLevelCategories(true)
			}
		} else if (categories.length === 0) {
			loadTopLevelCategories(false)
		}
	}

	/**
	 * Navigation
	 */
	navigateToCategory = (item: Category) => {
		this.props.navigateToCategory(item.id, item.name, this.props.hnzMode)
		this.props.onClickAway()
	}

	navigateToSubcategory = (item: Category) => {
		this.props.navigateToCategory(item.id, item.name, this.props.hnzMode)
		this.props.onClickAway()
	}

	navigateToSubSubcategory = (item: Category) => {
		this.props.navigateToCategory(item.id, item.name, this.props.hnzMode)
		this.props.onClickAway()
	}

	navigateToProductFinder = (mode: ProductFinderType) => {
		this.props.navigateToProductFinder(mode)
		this.props.onClickAway()
	}

	navigateToBackflowProducts = () => {
		this.props.navigateToBackflowProducts()
		this.props.onClickAway()
	}

	/**
	 * Navigates the user to a view all HNZ/Kainga Ora products
	 */
	_navigateToHnzProducts = () => {
		const { navigateToHnzProducts, onClickAway } = this.props
		if (navigateToHnzProducts) {
			navigateToHnzProducts()
		}
		onClickAway()
	}

	_onMouseOverCategory = (item: Category) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation()
		const { selectedCategory } = this.state
		// if user mouses over a different category
		if (selectedCategory?.id !== item.id) {
			// clear subcategory too so subsubcategory popper disapears
			this.setState({ selectedCategory: item, selectedSubCategory: undefined })
			// load subcategories
			this.handleLoadSubCategories(item.id)
		}
	}

	handleLoadSubCategories = (selectedCategoryId: string) => {
		const { categoryId } = this.props
		// check if we already have subcategories loaded for this category
		if (selectedCategoryId !== categoryId) {
			this.props.loadSubcategories(selectedCategoryId, 'subcategory')
		}
	}

	_onMouseOverSubCategory = (item: Category) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation()
		const { selectedSubCategory } = this.state
		// if user mouses over a different category
		if (selectedSubCategory?.id !== item.id) {
			this.setState({ selectedSubCategory: item })
			// load subsubcategories
			this.handleLoadSubSubCategories(item.id)
		}
	}

	handleLoadSubSubCategories = (selectedSubcategoryId: string) => {
		const { subcategoryId } = this.props
		// check if we already have subsubcategories loaded for this subcategory
		if (selectedSubcategoryId !== subcategoryId) {
			this.props.loadSubcategories(selectedSubcategoryId, 'subsubcategory')
		}
	}

	closeSubcategoriesPopper = () => {
		this.setState({ selectedCategory: undefined })
	}

	closeSubSubcategoriesPopper = () => {
		this.setState({ selectedSubCategory: undefined })
	}

	/**
	 * render
	 */

	render() {
		const { subAnchorElement, selectedCategory, subSubAnchorElement, selectedSubCategory } = this.state
		const { anchorElement, onClickAway, categories, hnzCategories, isHnzAccount, isLoadingCategories, isLoadingHnzCategories, subcategories, isLoadingSubcategories, subSubcategories, isLoadingSubSubcategories, isSmallScreen, onCloseSubmenu } = this.props

		// choose category list based on the local state flag
		const categoryList = isHnzAccount ? hnzCategories : categories
		const isLoading = isHnzAccount ? isLoadingHnzCategories : isLoadingCategories

		return (
			<React.Fragment>
				{(!isSmallScreen || !selectedCategory) &&
					<MenuPopper anchorElement={anchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount} titleText="Product Categories" onCloseSubmenu={onCloseSubmenu}>
						{/* empty div for nested popper anchor - absolute position as popper content is scrollable and we dont' want nested popper to move up off screen */}
						<div ref={ref => { if (ref != null) this.setState({ subAnchorElement: ref }) }} style={{ position: 'absolute', width: '100%' }} />
						{/* loading spinner while loading categories */}
						{isLoading ?
							<div className="menu-drawer__item">
								<span />
								{/* they don't want to show loading message as the request happens very quick and this adds jank to the screen */}
								{/* <span className="menu-drawer__item--title">Loading…</span> */}
							</div>
							:
							<React.Fragment>
								<div className="menu-drawer__product-finders">
									<div className="menu-drawer__product-finder-item menu-drawer__product-finder-item--gold" onClick={() => this.navigateToProductFinder(ProductFinderType.GOLD)}>
										<div className="menu-drawer__product-finder-item--logo-container">
											<img src={GoldProductFinder} alt="GOLD" className="menu-drawer__product-finder-item--logo" />
											<GoldStarsAnim className="menu-drawer__product-finder-item--sparkle" size={70} />
										</div>
										<span className="menu-drawer__product-finder-item--title">GOLD PRODUCTS</span>
									</div>
									<div className="menu-drawer__product-finder-item menu-drawer__product-finder-item--hwc" onClick={() => this.navigateToProductFinder(ProductFinderType.HWC)}>
										<img src={HWCProductFinder} alt="HWC" className="menu-drawer__product-finder-item--logo" />
										<span className="menu-drawer__product-finder-item--title">HWC FINDER</span>
									</div>

									<div className="menu-drawer__product-finder-item menu-drawer__product-finder-item--backflow" onClick={this.navigateToBackflowProducts}>
										<img src={BackflowWaterProtectionLogo} alt="BACKFLOW" className="menu-drawer__product-finder-item--logo" />
										<span className="menu-drawer__product-finder-item--title">BACKFLOW</span>
									</div>
								</div>
								{categoryList.map(category => (
									<React.Fragment key={category.id}>
										<div
											className={`menu-drawer__item ${selectedCategory?.id === category.id && 'menu-drawer__item--checked'}`}
											onMouseOver={!isHnzAccount && !isSmallScreen ? this._onMouseOverCategory(category) : undefined}
											onClick={() => this.navigateToCategory(category)}
										>
											<span className={`menu-drawer__item--category-label ${selectedCategory?.id === category.id && 'menu-drawer__item--category-label__checked'}`}>{category.id}</span>
											<span className={`menu-drawer__item--title ${selectedCategory?.id === category.id && 'menu-drawer__item--title__checked'}`}>{category.name}</span>
											{!isHnzAccount && <span className={`menu-drawer__item--arrow icon-keyboard_arrow_right ${selectedCategory?.id === category.id && 'menu-drawer__item--arrow__checked'}`} onClick={this._onMouseOverCategory(category)} />}
										</div>
										<Divider className="menu-drawer__footer--divider" />
									</React.Fragment>
								))}
							</React.Fragment>
						}

						{/* view all hnz products */}
						{isHnzAccount && !isLoading && (
							<React.Fragment>
								<div className="menu-drawer__item" onClick={this._navigateToHnzProducts}>
									{/* TODO HNZ logo */}
									<span className="icon-kainga-ora-simple menu-drawer__item--icon menu-drawer__item--icon__checked" />
									<span className="menu-drawer__item--title">View All Products</span>
								</div>
								<Divider className="menu-drawer__footer--divider" />
							</React.Fragment>
						)}
					</MenuPopper>
				}

				{/* sub menu */}
				{(!isSmallScreen || !selectedSubCategory) && selectedCategory &&
					<MenuPopper anchorElement={subAnchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount} titleText={selectedCategory.name} onCloseSubmenu={this.closeSubcategoriesPopper} anchorOffset={0}>
						<div ref={ref => { if (ref != null) this.setState({ subSubAnchorElement: ref }) }} style={{ position: 'absolute', width: '100%' }} />
						{/* loading spinner while loading subcategories */}
						{isLoadingSubcategories ?
							<div className="menu-drawer__item">
								<span />
								{/* <span className="menu-drawer__item--title">Loading…</span> */}
							</div>
							:
							subcategories.map(subcategory => (
								<React.Fragment key={subcategory.id}>
									<div
										className={`menu-drawer__item ${selectedSubCategory?.id === subcategory.id && 'menu-drawer__item--checked'}`}
										onMouseOver={!isSmallScreen ? this._onMouseOverSubCategory(subcategory) : undefined}
										onClick={() => this.navigateToSubcategory(subcategory)}
									>
										<span className={`menu-drawer__item--category-label ${selectedSubCategory?.id === subcategory.id && 'menu-drawer__item--category-label__checked'}`}>{subcategory.id.substring(4)}</span>
										<span className={`menu-drawer__item--title ${selectedSubCategory?.id === subcategory.id && 'menu-drawer__item--title__checked'}`}>{subcategory.name}</span>
										<span className={`menu-drawer__item--arrow icon-keyboard_arrow_right ${selectedSubCategory?.id === subcategory.id && 'menu-drawer__item--arrow__checked'}`} onClick={this._onMouseOverSubCategory(subcategory)} />
									</div>
									<Divider className="menu-drawer__footer--divider" />
								</React.Fragment>
							))
						}
					</MenuPopper>
				}

				{/* sub sub menu */}
				{selectedSubCategory &&
					<MenuPopper anchorElement={subSubAnchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount} titleText={selectedSubCategory.name} onCloseSubmenu={this.closeSubSubcategoriesPopper} anchorOffset={0}>
						{/* loading spinner while loading subsubcategories */}
						{isLoadingSubSubcategories ?
							<div className="menu-drawer__item">
								<span />
								{/* <span className="menu-drawer__item--title">Loading…</span> */}
							</div>
							:
							subSubcategories.map(subsubcategory => (
								<React.Fragment key={subsubcategory.id}>
									<div className="menu-drawer__item" onClick={() => this.navigateToSubSubcategory(subsubcategory)}>
										<span className="menu-drawer__item--category-label">{subsubcategory.id.substring(8)}</span>
										<span className="menu-drawer__item--title">{subsubcategory.name}</span>
									</div>
									<Divider className="menu-drawer__footer--divider" />
								</React.Fragment>
							))
						}
					</MenuPopper>
				}
			</React.Fragment>
		)
	}
}