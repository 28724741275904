export enum FilterType {
	PRODUCT, INVOICE_NUMBER, TRANSACTION_NUMBER, INVOICE_TYPE, AMOUNT, BRANCH, REFERENCE, ORDERED_BY, ORDER_STATUS, QUOTE_NUMBER, ADDRESS, PWGO, ORDER_NUMBER, COMMENT, DATE, DISPLAY_$0_RESULTS
}

export interface Filter {
	type: FilterType
	name: string
	tooltip?: string
}

export enum SortDirection {
	ASCENDING = 'asc',
	DESCENDING = 'desc',
}