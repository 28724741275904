import * as React from 'react'
import { Tooltip as MaterialTooltip, TooltipProps } from '@mui/material'

interface Props extends TooltipProps {
	title: string
}

/* Note: For some reason, the tooltips for textfields are only displayed on hover after already being clicked on once. */
const Tooltip = (props: Props) => {
	const { title, children, ...rest } = props

	return (
		<MaterialTooltip title={title} arrow={true} {...rest}>
			{children}
		</MaterialTooltip>
	)
}

export default Tooltip