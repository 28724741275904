import React from 'react'
import Dialog from '@mui/material/Dialog'
import SwipeableViews from 'react-swipeable-views'
import { useDispatch, useSelector } from 'react-redux'

import { NewFeatureDialogProps, WebStoreState } from '../../platform/types'
import { showHnzNewFeatureDialog } from '../actions'
import HnzNewFeaturePageOne from './HnzNewFeaturePageOne'
import HnzNewFeaturePageTwo from './HnzNewFeaturePageTwo'
import HnzNewFeaturePageThree from './HnzNewFeaturePageThree'
import HnzNewFeaturePageFour from './HnzNewFeaturePageFour'
import HnzNewFeaturePageFive from './HnzNewFeaturePageFive'
import HnzNewFeaturePageSix from './HnzNewFeaturePageSix'
import NewFeatureLastPage from '../../platform/components/NewFeatureLastPage'
import CarouselPagination from '../../home/components/CarouselPagination'
import useNewFeatureDialog from '../../../utils/hooks/useNewFeatureDialog'

import '../styles/HnzNewFeatureDialog.scss'

const TOTAL_INDEX = 6

const HnzNewFeatureDialog: React.FunctionComponent<NewFeatureDialogProps> = ({ isUnseen, onRemoveFromUnseenList }: NewFeatureDialogProps) => {
	const dispatch = useDispatch()

	const showDialog = useSelector<WebStoreState, boolean>(state => state.hnz.showHnzNewFeatureDialog)

	const { isOpen, activeIndex, onNext, onChangeIndex } = useNewFeatureDialog({
		showDialog,
		forceShowDialog: isUnseen,
	})

	const _onClose = () => {
		if (isUnseen) {
			// user has seen the dialog at least one time, update parent component to remove from unseen list
			onRemoveFromUnseenList()
		}
		// close the dialog as per usual
		dispatch(showHnzNewFeatureDialog(false))
	}

	// we subtract 1 since we only start showing the pagination on the 2nd page
	const paginationIndex: number = activeIndex - 1

	return (
		<Dialog
			open={isOpen}
			onBackdropClick={_onClose}
			maxWidth="sm"
			fullWidth={true}
			className="hnz-new-feature"
		>
			<SwipeableViews
				enableMouseEvents={true}
				index={activeIndex}
				onChangeIndex={onChangeIndex}
			>
				<HnzNewFeaturePageOne onNext={onNext} onClose={_onClose} />
				<HnzNewFeaturePageTwo onNext={onNext} />
				<HnzNewFeaturePageThree onNext={onNext} />
				<HnzNewFeaturePageFour onNext={onNext} />
				<HnzNewFeaturePageFive onNext={onNext} />
				<HnzNewFeaturePageSix onNext={onNext} onClose={_onClose} />
				<NewFeatureLastPage onClose={_onClose} />
			</SwipeableViews>

			{paginationIndex >= 0 && (
				<CarouselPagination
					dots={TOTAL_INDEX}
					index={paginationIndex}
				/>
			)}
		</Dialog>
	)
}

export default HnzNewFeatureDialog