import { put, call } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { Action, AsyncActionCreators } from 'typescript-fsa'
import { getErrorTransformer, getSpecSheetsServerUrl, IMAGE_ID_PLACEHOLDER, getBrochureServerUrl, getMSDSServerUrl, getWarrantyServerUrl, getSdocServerUrl, getInstallServerUrl } from '.'
import { ApiErrorMessage } from '../auth/types'
import { getPlatformSupportImplementation } from '../platform'
import { refreshTokenSafely } from './index'
import { SpecSheet } from '../product/types'

/** 
 * A generic function for calling API while calling typescript-fsa async action's done and failed
 * actions.
 */
export function* callApi<A, B>(
	action: Action<A | undefined>,
	async: AsyncActionCreators<unknown, unknown, Error>,
	func: (payload: unknown, options: RequestInit) => Promise<B | undefined>,
	retry?: boolean
): SagaIterator {
	// set default retry to true - prevent setting defaults on async functions to bypass babel error
	retry = retry !== undefined ? retry : true

	let result: B | undefined
	try {
		result = yield call(func, action.payload, {})

		// attempt to extract any errors in the response
		const KEY_ERROR_TABLE = 'errorTable'
		const errorTable: ApiErrorMessage[] | undefined = result !== undefined ? result[KEY_ERROR_TABLE] : undefined

		if (errorTable) {
			console.log('callApi: ', errorTable)
			if (isAuthorizationError(errorTable)) {
				if (retry) {
					try {
						const refreshResult = yield call(refreshTokenSafely)
						if (refreshResult) {
							yield call(callApi, action, async, func, false)
							return
						}
					} catch (e) {
						// catching error thrown by refreshTokenSafely if get another 401 due to invalid refresh token value
					}
					const error = new Error('Unable to refresh auth token')
					yield put(async.failed({ params: action.payload, error }))
					return
				}
			} else {
				const error = new Error((errorTable as ApiErrorMessage[])[0].msg)
				yield put(async.failed({ params: action.payload, error }))
				return
			}
		}
	} catch (response) {
		if (response instanceof Response) {
			if (response.status === 401) {
				if (retry) {
					try {
						const refreshResult = yield call(refreshTokenSafely)
						if (refreshResult) {
							yield call(callApi, action, async, func, false)
							return
						}
					} catch (e) {
						// catching error thrown by refreshTokenSafely if get another 401 due to invalid refresh token value
					}
				}
			} else if (response.status === 304) {
				// don't do anything ?
			} else if (response.url.endsWith('/forgotPassword') && response.status === 404) {
				// special case if 404 on /forgotPassword endpoint we don't want to get Sentry report about it (it means the mobile number does not exist)
			} else {
				// we don't want to report 401 or 304 errors
				getPlatformSupportImplementation().reportError('callApi Response: ', response)
			}
			const errorTransformer = getErrorTransformer()
			if (errorTransformer) {
				const error = errorTransformer(response)
				yield put(async.failed({ params: action.payload, error }))
			} else {
				const error = new Error(response.statusText)
				error.name = 'APIError'
				// special error message for this scenario
				if (response.url.endsWith('/forgotPassword') && response.status === 404) {
					error.message = 'Mobile number does not exist'
				}
				yield put(async.failed({ params: action.payload, error }))
			}
		} else if (response instanceof Error) {
			getPlatformSupportImplementation().reportError('callApi Error: ', response)
			yield put(async.failed({ params: action.payload, error: response }))
		} else {
			getPlatformSupportImplementation().reportError('callApi Unknown: ', response)
			yield put(async.failed({ params: action.payload, error: new Error('Unknown API response') }))
		}
		return
	}

	yield put(async.done({ params: action.payload, result }))
}

// TODO: relying on the contents of the error message is bad. we're also assuming
// the error message will exist and will be the first in the list of errors
export function isAuthorizationError(errorTable: ApiErrorMessage[]) {
	return errorTable[0].msg === 'This session has expired.  Please login again.'
}

export function timestamp() {
	return new Date().toISOString()
}

/**
 * Calcualates the exact url to download a pdf document for a product
 * 
 * @param imageId used in server url path and file name
 * @param pdfId used in pdf file name
 * @param type determines which url directory to download pdf from
 */
export function createPDFDocumentUrl(imageId: string, pdfId: string, type: SpecSheet): string | undefined {
	let folder: string = ''
	if (type === SpecSheet.SPECS) {
		folder = getSpecSheetsServerUrl().replace(IMAGE_ID_PLACEHOLDER, imageId)
	} else if (type === SpecSheet.BROCHURES) {
		folder = getBrochureServerUrl().replace(IMAGE_ID_PLACEHOLDER, imageId)
	} else if (type === SpecSheet.MSDS) {
		folder = getMSDSServerUrl().replace(IMAGE_ID_PLACEHOLDER, imageId)
	} else if (type === SpecSheet.WARRANTY) {
		folder = getWarrantyServerUrl().replace(IMAGE_ID_PLACEHOLDER, imageId)
	} else if (type === SpecSheet.SDOC) {
		folder = getSdocServerUrl().replace(IMAGE_ID_PLACEHOLDER, imageId)
	} else if (type === SpecSheet.INSTALL) {
		folder = getInstallServerUrl().replace(IMAGE_ID_PLACEHOLDER, imageId)
	} else {
		// unsupported spec sheet type
		return undefined
	}
	// not yet sure if file name is the product sku or imageId
	const fileName: string = imageId + '.' + pdfId + '.pdf'
	return folder + fileName
}