import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { ProductPrices, SearchFilter, BranchProductDetail, SearchFilterFilterTypeEnum } from 'typescript-fetch-api'

import * as actions from './actions'
import { loadProductsCount, resetProductsTab } from '../products/actions'
import { PlatformProduct, ProductSearchResult } from '../../modules/platform'
import { clearAuthToken, loggedOut } from '../auth/actions'
import { updateTotalProductsCount, updateTotalProductsCountLoadingState } from '../products/functions'
import { TotalProductsCount } from '../products/types'
import { getPlatformSupportImplementation } from '../platform'

export interface StoreState {
	readonly products: ReadonlyArray<PlatformProduct>
	readonly fetchingProducts: boolean
	readonly errorFetchingProducts?: Error
	readonly totalPagesCount?: number
	readonly totalProductsCount?: number
	readonly page?: number

	readonly fetchingFilters: boolean
	readonly filters: SearchFilter[]
	readonly categoryFilters: SearchFilter[] // used to display category filters in the UI
	readonly savedFilter: actions.ProductFilter | undefined

	readonly prices: ProductPrices[] // NB: might not be needed if MOBILE also combines prices and stock counts onto products property as requests are online only (not using)
	readonly fetchingPrices: boolean

	readonly stockCounts: BranchProductDetail[]
	readonly fetchingStockCounts: boolean

	readonly productsCount: TotalProductsCount
}

const INITIAL_STATE: StoreState = {
	products: [],
	fetchingProducts: false,
	errorFetchingProducts: undefined,
	totalPagesCount: undefined,
	totalProductsCount: undefined,
	page: undefined,
	fetchingFilters: false,
	filters: [],
	categoryFilters: [],
	savedFilter: undefined,
	prices: [],
	stockCounts: [],
	fetchingStockCounts: false,
	fetchingPrices: false,
	productsCount: {
		allProductsCount: undefined,
		branchProductsCount: undefined,
		allProductsParams: undefined,
		branchProductsParams: undefined,
		loadingAllProductsCount: false,
		loadingBranchProductsCount: false,
	},
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

// PRODUCTS
reducer.case(actions.loadProductFinderProducts.started, (state, payload): StoreState => {
	const isWeb = !!getPlatformSupportImplementation().appendPricingToProducts
	return {
		...state,
		fetchingProducts: true,
		products: isWeb || payload.page === 0 ? INITIAL_STATE.products : state.products, // don't clear subsequent page loads on mobile (combine results)
		totalPagesCount: payload.page === 0 ? INITIAL_STATE.totalPagesCount : state.totalPagesCount,
		totalProductsCount: payload.page === 0 ? INITIAL_STATE.totalProductsCount : state.totalProductsCount,
		errorFetchingProducts: INITIAL_STATE.errorFetchingProducts, // always clear the error message
	}
})
reducer.case(actions.loadProductFinderProducts.done, (state, payload): StoreState => {
	return {
		...state,
		fetchingProducts: false,
		products: payload.params.page === 0 ? payload.result.products : [...state.products, ...payload.result.products],
		totalPagesCount: payload.result.totalProductPagesCount,
		totalProductsCount: payload.result.totalProductsCount,
		page: payload.params.page,
	}
})
reducer.case(actions.loadProductFinderProducts.failed, (state, payload): StoreState => {
	return {
		...state,
		fetchingProducts: false,
		errorFetchingProducts: payload.error,
	}
})
reducer.case(actions.setLoadingProductFinderProductsState, (state): StoreState => ({
	...state,
	fetchingProducts: true,
	products: INITIAL_STATE.products,
	totalPagesCount: INITIAL_STATE.totalPagesCount,
	totalProductsCount: INITIAL_STATE.totalProductsCount,
	errorFetchingProducts: INITIAL_STATE.errorFetchingProducts,
}))

// FILTERS
reducer.case(actions.loadProductFinderFilters.started, (state, payload): StoreState => {
	return {
		...state,
		filters: [],
		fetchingFilters: true,
	}
})
reducer.case(actions.loadProductFinderFilters.done, (state, payload): StoreState => {
	return {
		...state,
		fetchingFilters: false,
		filters: payload.result,
		categoryFilters: payload.result.filter(item => item.filterType === SearchFilterFilterTypeEnum.CATEGORY),
	}
})
reducer.case(actions.loadProductFinderFilters.failed, (state, payload): StoreState => {
	return {
		...state,
		fetchingFilters: false,
	}
})
reducer.case(actions.setLoadingProductFinderFiltersState, (state): StoreState => ({
	...state,
	fetchingFilters: true,
}))

// SAVE FILTERS
reducer.case(actions.saveProductFinderFilter, (state, payload): StoreState => ({
	...state, savedFilter: payload,
}))

// PRODUCT COUNTS
reducer.case(loadProductsCount.done, (state, { params, result }): StoreState => {
	if (params.isProductFinder) {
		const updatedResult = updateTotalProductsCount(params, result, state.productsCount)
		if (updatedResult) {
			return ({
				...state, productsCount: updatedResult,
			})
		}
	}
	return state
})
reducer.case(loadProductsCount.started, (state, params): StoreState => {
	if (params.isProductFinder) {
		const updatedResult = updateTotalProductsCountLoadingState(params, true, state.productsCount)
		if (updatedResult) {
			return ({
				...state, productsCount: updatedResult,
			})
		}
	}
	return state
})
reducer.case(loadProductsCount.failed, (state, { params }): StoreState => {
	if (params.isProductFinder) {
		const updatedResult = updateTotalProductsCountLoadingState(params, false, state.productsCount)
		if (updatedResult) {
			return ({
				...state, productsCount: updatedResult,
			})
		}
	}
	return state
})

// PRICES
reducer.case(actions.loadProductFinderPrices.started, (state): StoreState => ({
	...state, fetchingPrices: true,
}))
reducer.case(actions.loadProductFinderPrices.done, (state, { params, result }): StoreState => {
	const prices = result.prices || []

	// loop through products and append pricing (on web only)
	let products: ReadonlyArray<PlatformProduct>
	const isWeb = !getPlatformSupportImplementation().hasPinFeature()
	if (isWeb) {
		// TODO: remove temporary force cast for web products - a ProductSearchResult is a PlatformProduct for web
		const productsWithPricing = getPlatformSupportImplementation().appendPricingToProducts(state.products as unknown as ProductSearchResult[], prices)
		products = productsWithPricing as unknown as ReadonlyArray<PlatformProduct>
	} else {
		products = state.products
	}

	if (params.appendToList) {
		return {
			...state,
			prices: [...state.prices, ...prices],
			fetchingPrices: false,
			products,
		}
	}
	return {
		...state,
		prices,
		fetchingPrices: false,
		products,
	}
})
reducer.case(actions.loadProductFinderPrices.failed, (state): StoreState => ({
	...state, fetchingPrices: false,
}))

// AVAILABILITY
reducer.case(actions.loadProductFinderAvailability.started, (state): StoreState => ({
	...state, fetchingStockCounts: true,
}))
reducer.case(actions.loadProductFinderAvailability.done, (state, { params, result }): StoreState => {
	const stockCounts = result.products || []

	// loop through products and append availability (on web only)
	let products: ReadonlyArray<PlatformProduct>
	const isWeb = !getPlatformSupportImplementation().hasPinFeature()
	if (isWeb) {
		// TODO: remove temporary force cast for web products - a ProductSearchResult is a PlatformProduct for web
		const productsWithPricing = getPlatformSupportImplementation().appendAvailabilityToProducts(state.products as unknown as ProductSearchResult[], stockCounts)
		products = productsWithPricing as unknown as ReadonlyArray<PlatformProduct>
	} else {
		products = state.products
	}

	if (params.appendToList) {
		return {
			...state,
			stockCounts: [...state.stockCounts, ...stockCounts],
			fetchingStockCounts: false,
			products,
		}
	}
	return {
		...state,
		stockCounts,
		fetchingStockCounts: false,
		products,
	}
})
reducer.case(actions.loadProductFinderAvailability.failed, (state): StoreState => ({
	...state, fetchingStockCounts: false,
}))


// LOGOUT
reducer.cases([resetProductsTab, loggedOut, clearAuthToken], (): StoreState => {
	/**
	 * we clear the search results in the following cases:
	 * - when the user updates products (clear out any old Realm objects)
	 * - when the user logs out
	 */
	return INITIAL_STATE
})
