import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Event } from 'typescript-fetch-api'

import Component from '../../../modules/search/components/SearchToolbar'
import { RootStoreState } from '../../root'
import * as actions from '../actions'
import * as actionsConfig from '../../products/actions'
import * as eventsActions from '../../events/actions'
import { ProductsViewState } from '../../config/types'
import { navigateToBarcodeScanner } from '../../product/actions'
import { getPlatformSupportImplementation } from '../../platform'

export interface Props {
	loggedIn: boolean
	customerId?: number
	searchText?: string
	productsViewState: ProductsViewState
	categoryId?: string
}

export interface OwnProps {
	categoryId?: string | undefined
}

export interface Actions {
	onTextChange: (queryText: string) => void
	fetchSearchSuggestions: (queryText: string) => void
	onClearSearch: () => void
	onToggleProductsViewState: (newState: ProductsViewState) => void
	onBarcodeClick: () => void
	navigateToSearchResults: (queryText?: string) => void
	createEvent: (event: Event) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		loggedIn: getPlatformSupportImplementation().hasPinFeature()
			? state.auth.authToken !== undefined && state.auth.pin !== undefined
			: state.auth.authToken !== undefined,
		customerId: state.order.selectedAccount && state.order.selectedAccount.customerId,
		searchText: state.search.searchText,
		productsViewState: state.config.productsViewState,
		categoryId: ownProps.categoryId,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onTextChange: (queryText: string) => {
		dispatch(actions.textChangeAction({ queryText }))
	},
	fetchSearchSuggestions: (queryText: string) => {
		dispatch(actions.fetchSearchSuggestions.started(queryText))
	},
	onClearSearch: () => {
		dispatch(actions.searchCancelledAction())
	},
	onToggleProductsViewState: (newState) => {
		dispatch(actionsConfig.toggleProductsViewState(newState))
	},
	onBarcodeClick: () => {
		dispatch(navigateToBarcodeScanner())
	},
	navigateToSearchResults: (queryText?: string) => {
		dispatch(actions.navigateToSearchResults(queryText))
	},
	createEvent: (event: Event) => {
		dispatch(eventsActions.createEvent.started(event))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
