import { PriceBookType, PriceBookFileFormat, PriceBookFrequency, PriceBookProductRange, PriceBookDeliveryMethod, PriceBookExampleFileFormat } from 'typescript-fetch-api'
import { PriceBookFileFormatLabel, PriceBookFrequencyOption } from './types'

export const PriceBookSampleFiles: Array<{ title: string, format: PriceBookExampleFileFormat }> = [
	{ title: PriceBookFileFormatLabel.TABDELIMITED, format: PriceBookExampleFileFormat.TAB_DELIMITED },
	{ title: PriceBookFileFormatLabel.COMMADELIMITED, format: PriceBookExampleFileFormat.CSV },
	{ title: PriceBookFileFormatLabel.COMMADELIMITED2, format: PriceBookExampleFileFormat.CSV_2 },
	{ title: PriceBookFileFormatLabel.DEFAULTFORMAT1, format: PriceBookExampleFileFormat.DEFAULT_FORMAT_1 },
	{ title: PriceBookFileFormatLabel.DEFAULTFORMAT2, format: PriceBookExampleFileFormat.DEFAULT_FORMAT_2 },
	{ title: PriceBookFileFormatLabel.WORKFLOWMAX, format: PriceBookExampleFileFormat.WORKFLOWMAX },
	{ title: PriceBookFileFormatLabel.SIMPRO, format: PriceBookExampleFileFormat.SIMPRO },
]

export const PriceBookSampleGuideFiles: Array<{ title: string, format: PriceBookExampleFileFormat }> = [
	{ title: PriceBookFileFormatLabel.PRICEBOOK, format: PriceBookExampleFileFormat.PRICE_BOOK_GUIDE },
	{ title: PriceBookFileFormatLabel.FERGUS, format: PriceBookExampleFileFormat.FERGUS },
]

export const PriceBookTypeOptions: Array<{ value: PriceBookType, label: string }> = [
	{ value: PriceBookType.INCREMENTAL, label: 'Changes Only' },
	{ value: PriceBookType.FULL_PRICE_BOOK, label: 'Full Price Book' }
]

export const PriceBookFileFormatOptions: Array<{ value: PriceBookFileFormat, label: PriceBookFileFormatLabel }> = [
	{ value: PriceBookFileFormat.TAB_DELIMITED, label: PriceBookFileFormatLabel.TABDELIMITED },
	{ value: PriceBookFileFormat.CSV, label: PriceBookFileFormatLabel.COMMADELIMITED },
	{ value: PriceBookFileFormat.CSV_2, label: PriceBookFileFormatLabel.COMMADELIMITED2 },
	{ value: PriceBookFileFormat.DEFAULT_FORMAT_1, label: PriceBookFileFormatLabel.DEFAULTFORMAT1 },
	{ value: PriceBookFileFormat.DEFAULT_FORMAT_2, label: PriceBookFileFormatLabel.DEFAULTFORMAT2 },
	{ value: PriceBookFileFormat.WORKFLOWMAX, label: PriceBookFileFormatLabel.WORKFLOWMAX },
	{ value: PriceBookFileFormat.SIMPRO, label: PriceBookFileFormatLabel.SIMPRO },
	{ value: PriceBookFileFormat.FERGUS, label: PriceBookFileFormatLabel.FERGUSCSV },
	{ value: PriceBookFileFormat.TRADIFY, label: PriceBookFileFormatLabel.TRADIFY },
	{ value: PriceBookFileFormat.I_TRADE, label: PriceBookFileFormatLabel.I_TRADE },
]

// added separate definitions since users can:
// - have all options shown, through impersonation
// - have MONTHLY only
// - have MONTHLY and either DAILY or WEEKLY or FORTNIGHTLY, if they had a previous subscription
export const PriceBookFrequencyDaily: PriceBookFrequencyOption = { value: PriceBookFrequency.DAILY, label: 'Daily' }
export const PriceBookFrequencyWeekly: PriceBookFrequencyOption = { value: PriceBookFrequency.WEEKLY, label: 'Weekly' }
export const PriceBookFrequencyFortnightly: PriceBookFrequencyOption = { value: PriceBookFrequency.FORTNIGHTLY, label: 'Fortnightly' }
export const PriceBookFrequencyMonthly: PriceBookFrequencyOption = { value: PriceBookFrequency.MONTHLY, label: 'Monthly' }
export const PriceBookFrequencyOptions = [PriceBookFrequencyDaily, PriceBookFrequencyWeekly, PriceBookFrequencyFortnightly, PriceBookFrequencyMonthly]

export const PriceBookFileFormatFileName: Record<PriceBookExampleFileFormat, string> = {
	TAB_DELIMITED: 'Tab Delimited.txt',
	CSV: 'Comma delimited.txt',
	CSV_2: 'Comma delimited.csv',
	DEFAULT_FORMAT_1: 'Default Format 1.dat',
	DEFAULT_FORMAT_2: 'Default Format 2.dat',
	WORKFLOWMAX: 'Work Flow Max.csv',
	SIMPRO: 'simPRO Format.txt',
	FERGUS: 'Fergus Import Guide.pdf',
	PRICE_BOOK_GUIDE: 'pwGO Price Books User Guide.pdf',
}

export const EMAIL_MAX_LENGTH = 60
export const FILE_NAME_MAX_LENGTH = 40

export const PriceBookProductRangeOptions = [
	{ value: PriceBookProductRange.DEFAULT, label: 'Sales & Core Products' },
	{ value: PriceBookProductRange.ALL, label: 'All Products' }
]

export const PriceBookDeliveryMethodOptions = [
	{ value: PriceBookDeliveryMethod.EMAIL, label: 'Email' },
	{ value: PriceBookDeliveryMethod.SIMPRO, label: 'simPRO' }
]