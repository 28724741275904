import { useState, useEffect } from 'react'

interface Props {
	showDialog: boolean
	forceShowDialog?: boolean
}

interface ReturnProps {
	isOpen: boolean
	activeIndex: number
	onNext: () => void
	onChangeIndex: (index: number) => void
}

/**
 * A hook that handles local state and reusable actions related to handling new feature dialogs.
 * Specifically it helps in:
 * - showing/hiding a dialog
 * - documenting the index that's active on the dialog
 * and returns a list of reusable actions to update the index.
 * 
 * @param props
 */
const useNewFeatureDialog = ({ showDialog, forceShowDialog }: Props): ReturnProps => {
	const [isOpen, setIsOpen] = useState(showDialog)
	const [activeIndex, setActiveIndex] = useState(0)

	/**
	 * Effect that listens to the `showDialog` flag and resets the
	 * index if `showDialog` is set to true, which means user just (re)opened dialog.
	 */
	useEffect(() => {
		setIsOpen(showDialog)
		if (showDialog) {
			setActiveIndex(0)
		}
	}, [showDialog])

	/**
	 * Effect that forces the dialog to open based on the `forceShowDialog` flag.
	 * This is in cases where we want to show the dialog based on extra checks,
	 * i.e. if unseen feature on initial user session
	 */
	useEffect(() => {
		setIsOpen(!!forceShowDialog)
	}, [forceShowDialog])

	const onNext = () => {
		setActiveIndex(activeIndex + 1)
	}

	const onChangeIndex = (index: number) => {
		setActiveIndex(index)
	}

	return { isOpen, activeIndex, onNext, onChangeIndex }
}

export default useNewFeatureDialog
