import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Divider, Switch } from '@mui/material'

import MenuPopper from '../../platform/components/MenuPopper'
import { SubheaderMenuProps, WebStoreState } from '../../platform/types'
import { RootStoreState } from '../../../common/root/index'
import { navigateToVersionUpdates } from '../../../common/versionUpdates/actions'
import { navigateToPrivacyPolicy, navigateToTermsAndConditions } from '../../../common/profile/actions'
import { UserAccount } from '../../../common/accounts/types'
import { toggleHnzMode } from '../../hnz/actions'

const SettingsMenuPopper = ({ anchorElement, isHnzAccount, onClickAway, onCloseSubmenu }: SubheaderMenuProps): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */

	const hnzMode = useSelector<WebStoreState, boolean>(state => state.hnz.hnzMode)
	const selectedAccount = useSelector<RootStoreState, UserAccount | undefined>(state => state.order.selectedAccount)
	const isHnzAccountSelected = !!selectedAccount && !!selectedAccount.hnz

	/**
	 * Local Functions
	 */

	const _toggleHnzMode = () => {
		dispatch(toggleHnzMode(!hnzMode))
	}

	const _navigateToVersions = () => {
		dispatch(navigateToVersionUpdates())
		onClickAway()
	}

	const _navigateToTerms = () => {
		dispatch(navigateToTermsAndConditions())
		onClickAway()
	}

	const _navigateToPrivacyPolicy = () => {
		dispatch(navigateToPrivacyPolicy())
		onClickAway()
	}

	return (
		<MenuPopper anchorElement={anchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount} titleText={isHnzAccountSelected ? 'Mode' : 'More Info'} onCloseSubmenu={onCloseSubmenu} anchorBottom={true}>
			{isHnzAccountSelected && (
				<React.Fragment>
					<div className="menu-drawer__item" onClick={_toggleHnzMode}>
						<span />
						<div className="menu-drawer__item--row">
							<span className="menu-drawer__item--title">Kainga Ora Mode</span>
							<Switch
								color="primary"
								checked={hnzMode}
								onChange={_toggleHnzMode}
							/>
						</div>
						<span />
					</div>
					<Divider className="menu-drawer__footer--divider" />

					<div className="menu-drawer__popper--title-container">
						<span className="menu-drawer__popper--title">More Info</span>
					</div>
				</React.Fragment>
			)}

			<div className="menu-drawer__item" onClick={_navigateToVersions}>
				<span />
				<span className="menu-drawer__item--title">Version Releases &amp; Updates</span>
				<span />
			</div>
			<Divider className="menu-drawer__footer--divider" />
			<div className="menu-drawer__item" onClick={_navigateToTerms}>
				<span />
				<span className="menu-drawer__item--title">Terms and Conditions</span>
				<span />
			</div>
			<Divider className="menu-drawer__footer--divider" />
			<div className="menu-drawer__item" onClick={_navigateToPrivacyPolicy}>
				<span />
				<span className="menu-drawer__item--title">Privacy Policy</span>
				<span />
			</div>
			{/* <Divider className="menu-drawer__footer--divider" /> */}
		</MenuPopper>
	)
}

export default SettingsMenuPopper
