import * as React from 'react'
import { Tooltip } from '@mui/material'

import { KaingaOraLogo } from '../../../utils/imagesProvider'

import '../styles/HnzLogo.css'

export default class HnzLogo extends React.PureComponent {

	render() {
		return (
			<Tooltip title="Kāinga Ora Approved Product" className="hnz-logo-container">
				<img src={KaingaOraLogo} alt="Kainga Ora" className="hnz-logo" />
			</Tooltip>
		)
	}
}