import { OAuthResponse } from 'typescript-fetch-api'
import actionCreatorFactory, { Action, Success } from 'typescript-fsa'

const actionCreator = actionCreatorFactory('Auth')

interface CheckImpersonatorTokenRequestParams {
	token: string
	username: string
}
export type CheckImpersonatorTokenAction = Action<CheckImpersonatorTokenRequestParams>
export const checkImpersonatorToken = actionCreator<CheckImpersonatorTokenRequestParams>('CHECK_IMPERSONATOR_TOKEN')

export type GetShareholdingTokenSuccessAction = Action<Success<void, OAuthResponse>>
export const getShareholdingToken = actionCreator.async<void, OAuthResponse, Error>('GET_SHAREHOLDING_TOKEN')