import '../styles/DrawerAccordion.scss'

import * as React from 'react'
import { Collapse } from '@mui/material'

interface Props {
	title: string
	icon?: React.ReactNode
	iconName?: string
	alwaysOpen?: boolean // for reusing accordian header style without the collapsing feature
	headerClassName?: string // used to style the header background
	divider?: boolean // show divider or not
	subtitle?: string
	children: React.ReactNode
	onStateChange?: () => void // callback when collapsed state changes incase parent wants to know about it (eg for rerendering the summary popper view)
	description?: string //optional description for the accordian header
}

interface State {
	isOpen: boolean
}

const INITIAL_STATE: State = {
	isOpen: true,
}

export default class DrawerAccordion extends React.PureComponent<Props, State> {

	state = INITIAL_STATE

	_handleToggle = () => {
		this.setState((prevState: State) => ({ isOpen: !prevState.isOpen }))
	}

	render() {
		const { isOpen } = this.state
		const { title, children, icon, iconName, alwaysOpen, headerClassName, divider, subtitle, onStateChange, description } = this.props

		return (
			<div className="drawer-accordion">
				<div className={`drawer-accordion__header ${headerClassName}`}>
					<div className="drawer-accordion__horizontal">
						{icon}
						{iconName && <span className={`drawer-accordion__icon ${iconName}`} />}
						<div className="drawer-accordion__text--heading">
							<span className="drawer-accordion__text--heading">{title}</span>
							{description && <p className="drawer-accordion__text--description">{description}</p>}
						</div>
					</div>

					<div className="drawer-accordion__horizontal">
						{subtitle && <span className="drawer-accordion__text--subtitle">{subtitle}</span>}
						{!alwaysOpen && (
							<span
								className={`${isOpen ? 'icon-keyboard_arrow_up' : 'icon-keyboard_arrow_down'} drawer-accordion__text--action`}
								onClick={this._handleToggle}
							/>
						)}
					</div>
				</div>

				{divider && <div className="drawer-accordion__separator" />}

				<Collapse in={isOpen} unmountOnExit={true} onExited={onStateChange} onEntered={onStateChange}>
					{children}
				</Collapse>
			</div>
		)
	}
}