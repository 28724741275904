import moment from 'moment'
import { DayOfWeek, StandardHoursDay } from 'typescript-fetch-api'

import { DaysOfWeekEnum } from './types'

/**
 * Handles sorting the standard hours for a branch based on the day of the week with MON as the start.
 * @param standardHours the hours to sort
 * @returns the sorted hours
 */
export const getSortedStandardHours = (standardHours: StandardHoursDay[]): StandardHoursDay[] => (
	standardHours.sort((a: StandardHoursDay, b: StandardHoursDay) => (
		DaysOfWeekEnum[a.dayOfWeek] < DaysOfWeekEnum[b.dayOfWeek] ? -1 : 1
	))
)

/**
 * Gets the corresponding label for a given DayOfWeek
 * @param dayOfWeek the DayOfWeek
 * @returns the label
 */
export const getDayOfWeekLabel = (dayOfWeek: DayOfWeek): string => {
	switch (dayOfWeek) {
		case DayOfWeek.MON:
			return 'Monday'
		case DayOfWeek.TUE:
			return 'Tuesday'
		case DayOfWeek.WED:
			return 'Wednesday'
		case DayOfWeek.THU:
			return 'Thursday'
		case DayOfWeek.FRI:
			return 'Friday'
		case DayOfWeek.SAT:
			return 'Saturday'
		case DayOfWeek.SUN:
			return 'Sunday'
		default:
			return ''
	}
}

/**
 * Checks if the standard hour matches the current day of the week
 * @param day the StandardHoursDay
 * @returns true if the standard hour matches the current day of the week, false if otherwise
 */
export const isCurrentDay = (day: StandardHoursDay): boolean => {
	const dayId = DaysOfWeekEnum[day.dayOfWeek]
	// DaysOfWeekEnum is setup with MON as the start, so we add 1 to match with moment
	const dayIdAsMomentDayId = dayId + 1
	// moment is setup with SUN as the start
	const finalDayId = dayIdAsMomentDayId === 7 ? 0 : dayIdAsMomentDayId
	return finalDayId === moment().day()
}
