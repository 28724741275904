import '../styles/ChangeAccountDrawer.scss'

import React from 'react'
import { useSelector } from 'react-redux'
import { Divider, Drawer } from '@mui/material'

import { RootStoreState } from '../../../common/root'
import { UserAccount } from '../../../common/accounts/types'
import { KaingaOraLogo } from '../../../utils/imagesProvider'

interface Props {
	isDrawerVisible: boolean
	onHideDrawer: () => void
	onChangeAccount: (selectedAccount: UserAccount) => void
}

const ChangeAccountDrawer = ({ isDrawerVisible, onHideDrawer, onChangeAccount }: Props): JSX.Element => {

	/**
	* Store State
	*/

	const selectedAccount = useSelector<RootStoreState, UserAccount | undefined>(state => state.order.selectedAccount)
	const accounts = useSelector<RootStoreState, UserAccount[] | undefined>(state => state.accounts.accounts ? state.accounts.accounts.filter(account => account.registrationState === 'approved') : [])


	/**
	 * Render
	 */

	const renderAccountItem = (account: UserAccount) => {
		const isSelectedAccount: boolean = selectedAccount ? account.id === selectedAccount.id : false

		return (
			<div
				key={account.id}
				className="change-account-drawer__account-container"
				onClick={() => onChangeAccount(account)}
			>
				{account.hnz
					? <img src={KaingaOraLogo} alt="Kainga Ora" className="change-account-drawer__account-icon" />
					: <span className={`icon-user-circle-outline change-account-drawer__${isSelectedAccount ? 'selected-account' : 'account-icon'}`} />
				}
				<div className="change-account-drawer__branch-content">
					<span className="change-account-drawer__branch-content--title-availability change-account-drawer__branch-content--title">
						{account.name}
					</span>
					<span className="change-account-drawer__branch-content--text">{account.customerId}</span>
					<span className="change-account-drawer__branch-content--text">{account.accountRoleName}</span>
					<div className="change-account-drawer__branch-content--selected">
						{isSelectedAccount &&
							<span className="change-account-drawer__branch-content--footer-text">Selected</span>
						}
					</div>
					<Divider className="change-account-drawer__divider" />
				</div>
			</div>
		)
	}

	return (
		<Drawer
			open={isDrawerVisible}
			onClose={onHideDrawer}
			anchor="right"
			className="change-account-drawer"
		>
			<div className="change-account-drawer__close--container">
				<span className="icon-close-round change-account-drawer__close--icon" onClick={onHideDrawer} />
			</div>

			<div className="change-account-drawer__content">
				<div className="change-account-drawer__header">
					<span className="change-account-drawer__header--text">Change Account</span>
				</div>

				<div className="change-account-drawer__container">
					<div className="change-account-drawer__accounts">
						{accounts?.map(account => (
							renderAccountItem(account)
						))}
					</div>
				</div>
			</div>
		</Drawer>

	)
}

export default ChangeAccountDrawer