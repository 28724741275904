import React, { useEffect } from 'react'
import { FormControl, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Shareholder } from 'typescript-fetch-api'

import DrawerAccordion from '../../platform/components/DrawerAccordion'
import { SHARES_EMAIL } from '../content'
import { WebStoreState } from '../../platform/types'
import { fetchShareholdingAccounts } from '../../platform/actions'

type Props = {
	shareholderNo?: string
	onUpdateShareholderNo: (value: string) => void
}

const ShareholdingAccessDrawerAccordion = (props: Props): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */

	const accounts = useSelector<WebStoreState, Shareholder[]>(state => state.platform.accounts)

	/**
	 * Effects/Subscriptions
	 */

	useEffect(() => {
		dispatch(fetchShareholdingAccounts.started())
	}, [])

	return (
		<DrawerAccordion title="myNZPM" divider={true}>
			{accounts.length > 0 && (
				<React.Fragment>
					<p className="user-profile__text user-profile__text--section user-profile__text--padding">myNZPM Access</p>

					{accounts.map(account => (
						<div className="user-profile__row" key={account.id || account.allotmentNumber}>
							<FormControl className="user-profile__form">
								<TextField
									label="Shareholder No."
									value={account.allotmentNumber}
									variant="outlined"
									disabled={true}
								/>
							</FormControl>

							<FormControl className="user-profile__form">
								<TextField
									label="Shareholder Name"
									value={account.name}
									variant="outlined"
									disabled={true}
								/>
							</FormControl>
						</div>
					))}
				</React.Fragment>
			)}

			<p className="user-profile__text user-profile__text--section user-profile__text--padding">Request Access to myNZPM</p>

			<p className="user-profile__text user-profile__text--section user-profile__text--padding">
				Access is only granted to known NZPM Shareholders.<br />
				For more information please contact NZPM at<br />
				<a href={`mailto:${SHARES_EMAIL}`} className="user-profile__text user-profile__text--section user-profile__text--padding">
					{SHARES_EMAIL}
				</a>
			</p>

			<div className="user-profile__row--centred">
				<FormControl className="user-profile__form">
					<TextField
						type="number"
						label="Shareholder No."
						value={props.shareholderNo}
						variant="outlined"
						autoComplete="off"
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							props.onUpdateShareholderNo(e.target.value)
						}}
					/>
				</FormControl>
			</div>
		</DrawerAccordion>
	)
}

export default ShareholdingAccessDrawerAccordion