import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { ProductList, ListsResponse, ProductListItem, UpdateListProductsActionType, ProductSku, BranchProductsResponse, ProductPricesResponse, UploadListResponse, DownloadListFileType } from 'typescript-fetch-api'
import { AccountList } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('MyLists')

// passing through entire List for now as we use both ID (to find list on redux in container) and TITLE to display in header
export type NavigateToListDetailsActionPayload = Action<ProductList>
export const navigateToListDetails = actionCreator<ProductList>('NAVIGATE_TO_LIST_DETAILS')

export interface NewListPayload {
	items: ProductListItem[]
	customerId?: number
}
export type NewListAction = Action<NewListPayload | undefined>
export const navigateToNewList = actionCreator<NewListPayload | undefined>('NAVIGATE_TO_NEW_LIST')
export const navigateToImportList = actionCreator('NAVIGATE_TO_IMPORT_LIST')

export const navigateToListsTab = actionCreator('NAVIGATE_TO_LISTS')

export type NavigateToAccountListAction = Action<AccountList>
export const navigateToAccountList = actionCreator<AccountList>('NAVIGATE_TO_ACCOUNT_LIST')

export interface FetchListsPayload {
	includePrices?: boolean
	customerId?: number
	includeItems?: boolean
}
export type FetchListsAction = Action<FetchListsPayload | undefined>
export const fetchLists = actionCreator.async<FetchListsPayload | undefined, ListsResponse, Error>('FETCH_LISTS')

export interface FetchListPayload {
	id: string
	includePrices?: boolean
	customerId?: number
	page?: number
	pageSize?: number
	appendToList?: boolean
}
export type FetchListAction = Action<FetchListPayload>
export type FetchListSuccessAction = Action<Success<FetchListPayload, ProductList>>
export const fetchList = actionCreator.async<FetchListPayload, ProductList, Error>('FETCH_LIST')

// needed to check what screen dispatched the action so we only show 1 modal for loading and error states
// this should probably be refactored to use Navigation Current Scene as the issues is related to that, but did it this way to save time (ask sam)
export type ListsScreen = 'New List' | 'List Details' | 'Product' | 'Cart' | 'Barcode'

export interface CreateOrEditListPayload {
	list: ProductList
	// flag which determines whether to take user to AllLists screen after successfully creating list
	goViewListsAfter: boolean
	screen: ListsScreen
	includePrices?: boolean
	customerId?: number
	includeItems?: boolean
}
export type CreateOrEditListAction = Action<CreateOrEditListPayload>
export type CreateOrEditListActionSuccess = Action<Success<CreateOrEditListPayload, ListsResponse>>
export const createOrEditList = actionCreator.async<CreateOrEditListPayload, ListsResponse, Error>('CREATE_OR_EDIT_LIST')
export const clearErrorEditingList = actionCreator('CLEAR_ERROR_EDITING_LIST')

export interface DeleteListPayload {
	list: ProductList
	includePrices?: boolean
	customerId?: number
	includeItems?: boolean
}
export type DeleteListAction = Action<DeleteListPayload>
export const deleteList = actionCreator.async<DeleteListPayload, ListsResponse, Error>('DELETE_LIST')
export const clearErrorDeletingList = actionCreator('CLEAR_ERROR_DELETING_LIST')

export interface AddProductToListPayload extends FetchListPayload {
	sku: string
	quantity: number
	screen: ListsScreen
}
export type AddProductToListAction = Action<AddProductToListPayload>
export type AddProductToListSuccessAction = Action<Success<AddProductToListPayload, ProductList>>
export const addProductToList = actionCreator.async<AddProductToListPayload, ProductList, Error>('ADD_PRODUCT_TO_LIST')

export interface RemoveProductFromListPayload extends FetchListPayload {
	sku: string
	screen: ListsScreen
}
export type RemoveProductFromListAction = Action<RemoveProductFromListPayload>
export type RemoveProductFromListSuccessAction = Action<Success<RemoveProductFromListPayload, ProductList>>
export const removeProductFromList = actionCreator.async<RemoveProductFromListPayload, ProductList, Error>('REMOVE_PRODUCT_FROM_LIST')

export interface UpdateProductsInListPayload extends FetchListPayload {
	products: ProductListItem[]
	updateActionType?: UpdateListProductsActionType
	screen: ListsScreen
}
export type UpdateProductsInListAction = Action<UpdateProductsInListPayload>
export const updateProductsInList = actionCreator.async<UpdateProductsInListPayload, ProductList, Error>('UPDATE_PRODUCTS_IN_LIST')

export interface UpdateListTitlePayload {
	id: string
	title: string
}
export type UpdateListTitleAction = Action<UpdateListTitlePayload>
export const updateListTitle = actionCreator.async<UpdateListTitlePayload, Response, Error>('UPDATE_LIST_TITLE')
export const clearErrorUpdatingListTitle = actionCreator('CLEAR_ERROR_UPDATING_LIST_TITLE')

export interface UpdateListTitleAndProductsPayload extends UpdateProductsInListPayload {
	title: string
}
export type UpdateListTitleAndProductsAction = Action<UpdateListTitleAndProductsPayload>
export const updateListTitleAndProducts = actionCreator.async<UpdateListTitleAndProductsPayload, ProductList, Error>('UPDATE_LIST_TITLE_AND_PRODUCTS')

export interface UploadListParams {
	listId: string
	file: File
	fetchPricingAfter?: boolean // a way to only fetch price/stock if user is viewing the list details screen (user can upload from Lists screen where pricing not required)
}
export type UploadListRequestAction = Action<UploadListParams>
export type UploadListSuccessAction = Action<Success<UploadListParams, UploadListResponse>>
export const uploadList = actionCreator.async<UploadListParams, UploadListResponse, Error>('UPLOAD_LIST')
export const clearUploadListResult = actionCreator('CLEAR_UPLOADING_LIST_RESULT')

export interface UploadNewListParams {
	listId: string // locally generated uuid
	title: string
	customerId?: number // if creating account list
	file: File // products to upload to new list after creation
}
export type UploadNewListRequestAction = Action<UploadNewListParams>
export type UploadNewListSuccessAction = Action<Success<UploadNewListParams, ListsResponse>>
export type UploadNewListSuccessPayload = Success<UploadNewListParams, ListsResponse>
export const uploadNewList = actionCreator.async<UploadNewListParams, ListsResponse, Error>('UPLOAD_NEW_LIST')

export type PinListAction = Action<string>
export const pinList = actionCreator.async<string, Response, Error>('PIN_LIST')
export const unpinList = actionCreator.async<string, Response, Error>('UNPIN_LIST')

export interface GetListProductsAvailabilityPayload {
	listId: string
	skus: ProductSku[]
	branchId: number
	appendToList?: boolean
}
export type GetListProductsAvailabilityAction = Action<GetListProductsAvailabilityPayload>
export const getListProductsAvailability = actionCreator.async<GetListProductsAvailabilityPayload, BranchProductsResponse, Error>('GET_LIST_PRODUCTS_AVAILABILITY')

export const cancelGetListProductsAvailability = actionCreator('CANCEL_GET_LIST_PRODUCTS_AVAILABILITY')

export interface GetListProductsPricesPayload {
	listId: string
	skus: ProductSku[]
	customerId?: number
	appendToList?: boolean
	isLastBatch?: boolean
}
export type GetListProductsPricesAction = Action<GetListProductsPricesPayload>
export const getListProductsPrices = actionCreator.async<GetListProductsPricesPayload, ProductPricesResponse, Error>('GET_LIST_PRODUCTS_PRICES')

export const cancelGetListProductPrices = actionCreator('CANCEL_GET_LIST_PRODUCTS_PRICES')

export interface DownloadListPayload {
	listId: string
	listTitle: string // used in file name
	customerId?: number
	type?: DownloadListFileType
}
export type DownloadListAction = Action<DownloadListPayload>
export const downloadList = actionCreator.async<DownloadListPayload, Blob, Error>('DOWNLOAD_LIST')

export interface UpdateListItemCommentPayload {
	id: string
	sku: string
	comment: string
}
export type UpdateListItemCommentAction = Action<UpdateListItemCommentPayload>
export const updateListItemComment = actionCreator.async<UpdateListItemCommentPayload, Response, Error>('UPDATE_LIST_ITEM_COMMENT')

export const updateSelectedListId = actionCreator<string>('UPDATE_SELECTED_LIST_ID')