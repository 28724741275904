export const PW_DETAILS = {
	contact: '0800 800 686',
	url: 'tel:0800800686',
}

export const PW_OFFICES: { name: string, contact: string, url: string, address: string }[] = [
	{
		name: 'Palmerston North Support Office',
		contact: '06 353 0283',
		url: 'tel:063530283',
		address: '599 Main Street, Palmerston North, 4410',
	},
]

export const PW_WEBSITES: { name: string, description: string, url: string }[] = [
	{ name: 'Retail Website', description: 'www.plumbingworld.co.nz', url: 'https://www.plumbingworld.co.nz' },
	{ name: 'Young Plumbers Club', description: 'www.youngplumbersclub.co.nz', url: 'https://www.youngplumbersclub.co.nz' },
]

export const PW_SOCIALS: { icon: string, url: string }[] = [
	{ icon: 'icon-facebook', url: 'https://www.facebook.com/PlumbingWorldNZ/' },
	{ icon: 'icon-pinterest', url: 'https://www.pinterest.nz/plumbingworldnz/' },
	{ icon: 'icon-youtube', url: 'https://www.youtube.com/channel/UCt1frbAgvcK2-dlJCGbvvPA' },
	{ icon: 'icon-instagram', url: 'https://www.instagram.com/plumbingworld_nz/' },
]

export const NZPM_DETAILS = {
	name: 'NZPM',
	contact: '09 379 7436',
	url: 'tel:093797436',
	address: '65 Parnell Rise, Parnell, Auckland, 1052, NZ',
	postalAddress: 'PO Box 137 151, Parnell, Auckland, 1151',
	web: 'www.nzpm.co.nz',
}