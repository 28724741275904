import React from 'react'
import Dialog from '@mui/material/Dialog'
import SwipeableViews from 'react-swipeable-views'
import { useSelector, useDispatch } from 'react-redux'

import IntegrationNewFeaturePage from '../components/IntegrationNewFeaturePage'
import NewFeatureLastPage from '../../platform/components/NewFeatureLastPage'
import CarouselPagination from '../../home/components/CarouselPagination'
import * as images from '../../../utils/imagesProvider'
import { WebStoreState, NewFeatureDialogProps } from '../../platform/types'
import { showFergusNewFeatureDialog } from '../actions'
import useNewFeatureDialog from '../../../utils/hooks/useNewFeatureDialog'

import '../styles/IntegrationNewFeatureDialog.scss'

const TOTAL_INDEX = 6

const FergusNewFeatureDialog: React.FunctionComponent<NewFeatureDialogProps> = ({ isUnseen, onRemoveFromUnseenList }: NewFeatureDialogProps) => {
	const dispatch = useDispatch()

	const showDialog = useSelector<WebStoreState, boolean>(state => state.integrations.showFergusNewFeatureDialog)

	const { isOpen, activeIndex, onNext, onChangeIndex } = useNewFeatureDialog({
		showDialog,
		forceShowDialog: isUnseen,
	})

	const _onClose = () => {
		if (isUnseen) {
			// user has seen the dialog at least one time, update parent component to remove from unseen list
			onRemoveFromUnseenList()
		}
		// close the dialog as per usual
		dispatch(showFergusNewFeatureDialog(false))
	}

	return (
		<Dialog
			open={isOpen}
			onBackdropClick={_onClose}
			maxWidth="sm"
			fullWidth={true}
			className="integration-new-feature"
		>
			<SwipeableViews
				enableMouseEvents={true}
				index={activeIndex}
				onChangeIndex={onChangeIndex}
			>
				<IntegrationNewFeaturePage
					background={images.FergusPageOne}
					buttonText="LEARN"
					headingImage="pwgo"
					onNext={onNext}
					onClose={_onClose}
				/>

				<IntegrationNewFeaturePage
					background={images.FergusPageTwo}
					headingImage="fergus"
					backgroundClass="page__indented"
					onNext={onNext}
				/>

				<IntegrationNewFeaturePage
					background={images.FergusPageThree}
					headingImage="fergus-dark"
					onNext={onNext}
				/>

				<IntegrationNewFeaturePage
					background={images.FergusPageFour}
					headingImage="fergus"
					backgroundClass="page__indented"
					onNext={onNext}
				/>

				<IntegrationNewFeaturePage
					background={images.FergusPageFive}
					headingImage="fergus-dark"
					onNext={onNext}
				/>

				<NewFeatureLastPage onClose={_onClose} />
			</SwipeableViews>

			<div className={`integration-new-feature__pagination ${activeIndex === TOTAL_INDEX - 1 ? 'integration-new-feature__pagination--last-index' : ''}`}>
				<CarouselPagination
					dots={TOTAL_INDEX}
					index={activeIndex}
				/>
			</div>
		</Dialog>
	)
}

export default FergusNewFeatureDialog
