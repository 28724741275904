import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { CarouselItem, HomePageItem } from 'typescript-fetch-api'

import * as actions from './actions'

export interface StoreState {
	readonly carouselItems: CarouselItem[]
	readonly loadingCarousel: boolean

	readonly loadingHomePageItems: boolean
	readonly homePageItems: HomePageItem[]
}

const INITIAL_STATE: StoreState = {
	carouselItems: [],
	loadingCarousel: false,
	homePageItems: [],
	loadingHomePageItems: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer
	.case(actions.fetchCarousel.started, (state): StoreState => ({
		...state, loadingCarousel: true,
	}))
	.case(actions.fetchCarousel.done, (state, { result }): StoreState => ({
		...state, loadingCarousel: false, carouselItems: result.items || [],
	}))
	.case(actions.fetchCarousel.failed, (state): StoreState => ({
		...state, loadingCarousel: false,
	}))

reducer
	.case(actions.fetchHomePageItems.started, (state): StoreState => ({
		...state, loadingHomePageItems: true,
	}))
	.case(actions.fetchHomePageItems.done, (state, { result }): StoreState => ({
		...state, loadingHomePageItems: false, homePageItems: result.items || [],
	}))
	.case(actions.fetchHomePageItems.failed, (state): StoreState => ({
		...state, loadingHomePageItems: false,
	}))
