import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

import Component from '../components/SubheaderTab'
import { navigateToTab, TabOptions } from '../actions'
import { UserAccount } from '../../../common/accounts/types'
import { RootStoreState } from '../../../common/root'
import { isOwnAccount } from '../../../common/util/functions'
import { SubheaderItem } from '../content'

export interface OwnProps {
	tab: SubheaderItem
	pathname?: string
	isMenuDisplayed?: (value: boolean) => void
}

export interface Props {
	userId?: number
	isHnzAccount: boolean
}

export interface Actions {
	navigateToTab: (tab: TabOptions, userId?: number) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	const { accounts: { adminAccounts }, auth: { mobileNumber, email }, order: { selectedAccount } } = state
	// finds the complete user account details of the logged in user to be used when navigating to a profile
	const userAccount: UserAccount | undefined = adminAccounts ? adminAccounts.find(account => isOwnAccount(account, mobileNumber, email)) : undefined
	return {
		userId: userAccount ? userAccount.id : undefined,
		isHnzAccount: !!selectedAccount && !!selectedAccount.hnz,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	navigateToTab: (tab: TabOptions, userId?: number) => {
		dispatch(navigateToTab({ tab, userId }))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
