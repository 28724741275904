import Lottie from 'react-lottie'

import animationData from '../../../assets/anims/gold_stars.json'

interface Props {
	className: string
	size: number
}

const GoldStarsAnim = ({ className, size }: Props): JSX.Element => (
	<div className={className}>
		<Lottie
			options={{ autoplay: true, loop: true, animationData }}
			height={size}
			width={size}
		/>
	</div>
)

export default GoldStarsAnim