import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { SearchFilter } from 'typescript-fetch-api'

import * as actions from './actions'
import * as products from '../../common/products/actions'
import { Category } from '../../common/categories/types'
import { clearAuthToken, loggedOut, refreshAuthTokenFailed } from '../../common/auth/actions'
import { PlatformProduct } from '../platform'
import { accountSelected } from '../../common/cart/actions'

export interface StoreState {
	readonly hnzCategories: ReadonlyArray<Category>
	readonly isLoadingHnzCategories: boolean
	readonly errorLoadingHnzCategories: Error | undefined

	readonly hnzProducts: ReadonlyArray<PlatformProduct>
	readonly isLoadingHnzProducts: boolean
	readonly errorLoadingHnzProducts: Error | undefined
	readonly totalProductPagesCount: number | undefined

	readonly hnzFilters: SearchFilter[]
	readonly savedHnzFilter: products.ProductFilter | undefined

	readonly showHnzNewFeatureDialog: boolean

	readonly hnzMode: boolean
}

const INITIAL_STATE: StoreState = {
	hnzCategories: [],
	isLoadingHnzCategories: false,
	errorLoadingHnzCategories: undefined,

	hnzProducts: [],
	isLoadingHnzProducts: false,
	errorLoadingHnzProducts: undefined,
	totalProductPagesCount: undefined,

	hnzFilters: [],
	savedHnzFilter: undefined,

	showHnzNewFeatureDialog: false,

	hnzMode: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.loadHnzCategories.started, (state): StoreState => {
		return {
			...state,
			isLoadingHnzCategories: true,
			errorLoadingHnzCategories: undefined,
		}
	})
	.case(actions.loadHnzCategories.done, (state, { result }): StoreState => {
		return {
			...state,
			isLoadingHnzCategories: false,
			hnzCategories: result,
		}
	})
	.case(actions.loadHnzCategories.failed, (state, { error }): StoreState => {
		return {
			...state,
			isLoadingHnzCategories: false,
			errorLoadingHnzCategories: error,
		}
	})
	.case(actions.loadHnzProducts.started, (state): StoreState => {
		return {
			...state,
			isLoadingHnzProducts: true,
			errorLoadingHnzProducts: undefined,
		}
	})
	.case(actions.loadHnzProducts.done, (state, { result }): StoreState => {
		return {
			...state,
			isLoadingHnzProducts: false,
			hnzProducts: result.products || [],
			totalProductPagesCount: result.totalProductPagesCount,
		}
	})
	.case(actions.loadHnzProducts.failed, (state, { error }): StoreState => {
		return {
			...state,
			isLoadingHnzProducts: false,
			errorLoadingHnzProducts: error,
		}
	})
	.case(actions.setLoadingHnzProductsState, (state): StoreState => {
		return {
			...state,
			isLoadingHnzProducts: true,
			hnzProducts: INITIAL_STATE.hnzProducts,
			totalProductPagesCount: INITIAL_STATE.totalProductPagesCount,
			errorLoadingHnzProducts: INITIAL_STATE.errorLoadingHnzProducts,
		}
	})
	.case(actions.loadFiltersForHnzProducts.started, (state): StoreState => {
		return {
			...state,
			hnzFilters: [],
		}
	})
	.case(actions.loadFiltersForHnzProducts.done, (state, { result }): StoreState => {
		return {
			...state,
			hnzFilters: result,
		}
	})
	.case(actions.saveHnzProductsFilter, (state, payload): StoreState => ({
		...state, savedHnzFilter: payload,
	}))
	.case(actions.showHnzNewFeatureDialog, (state, payload): StoreState => ({
		...state,
		showHnzNewFeatureDialog: payload,
	}))

	.case(actions.toggleHnzMode, (state, payload): StoreState => ({
		...state,
		hnzMode: payload,
	}))
	.case(accountSelected, (state, payload): StoreState => ({
		...state,
		hnzMode: !!payload.hnz, // when changing account update hnzMode default
	}))

	.case(refreshAuthTokenFailed, (state): StoreState => {
		return {
			...INITIAL_STATE,
			showHnzNewFeatureDialog: state.showHnzNewFeatureDialog,
		}
	})
	.cases([loggedOut, clearAuthToken], (): StoreState => {
		return INITIAL_STATE
	})