import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AccountPermission } from 'typescript-fetch-api'
import { Divider } from '@mui/material'

import MenuPopper from '../../platform/components/MenuPopper'
import { WebStoreState, SubheaderMenuProps } from '../../platform/types'
import { navigateToTab } from '../../platform/actions'
import { navigateToPriceBook } from '../../../common/priceBook/actions'
import { navigateToIntegration } from '../../integrations/actions'
import useEnabledRemoteConfig from '../../../common/remoteConfig/hooks/useEnabledRemoteConfig'
import { RemoteConfig } from '../../../common/remoteConfig/types'

const AdminMenuPopper = ({ anchorElement, isHnzAccount, onClickAway, onCloseSubmenu }: SubheaderMenuProps): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */

	// checks if the current selected account has appropriate integration permissions
	const hasIntegrationPermission = useSelector<WebStoreState, boolean>(({ order: { selectedAccount } }) => (
		!!selectedAccount
		&& !!selectedAccount.permissions
		&& selectedAccount.permissions.some(item => item === AccountPermission.EDIT_INTEGRATIONS)
	))
	const isPricebookFeatureEnabled = useEnabledRemoteConfig(RemoteConfig.PRICEBOOKS)

	/**
	 * Local Functions
	 */

	const _navigateToUsers = () => {
		dispatch(navigateToTab({ tab: 'admin' }))
		onClickAway()
	}

	const _navigateToIntegrations = () => {
		dispatch(navigateToIntegration())
		onClickAway()
	}

	const _navigateToPriceBook = () => {
		dispatch(navigateToPriceBook())
		onClickAway()
	}

	return (
		<MenuPopper anchorElement={anchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount} titleText="Admin" onCloseSubmenu={onCloseSubmenu} anchorBottom={true}>
			<div className="menu-drawer__item" onClick={_navigateToUsers}>
				<span />
				<span className="menu-drawer__item--title">Users</span>
				<span />
			</div>
			<Divider className="menu-drawer__footer--divider" />
			{hasIntegrationPermission && (
				<React.Fragment>
					<div className="menu-drawer__item" onClick={_navigateToIntegrations}>
						<span />
						<span className="menu-drawer__item--title">Integrations</span>
						<span />
					</div>
					<Divider className="menu-drawer__footer--divider" />
				</React.Fragment>
			)}
			{isPricebookFeatureEnabled && (
				<React.Fragment>
					<div className="menu-drawer__item" onClick={_navigateToPriceBook}>
						<span />
						<span className="menu-drawer__item--title">Price Book</span>
						<span />
					</div>
					{/* <Divider className="menu-drawer__footer--divider" /> */}
				</React.Fragment>
			)}
		</MenuPopper>
	)
}

export default AdminMenuPopper
