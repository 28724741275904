import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { ClosureType, ProductPrices, CreateOrderDespatchMethodEnum, OrderDespatchMethodEnum, ClickAndCollectTimeslot, SimproJob, SimproJobSection, SimproJobCostCenter, CreateOrderCheckoutMethodEnum } from 'typescript-fetch-api'

import * as actions from './actions'
import * as cartActions from '../cart/actions'
import * as authActions from '../auth/actions'
import { OrderItem } from './types'
import { AccountStatus, UserAccount } from '../accounts/types'
import { readyAction } from '../root/actions'
import { getPlatformSupportImplementation } from '../platform'
import { formatQuantityWithDecimalCheck } from '../cart/functions'

export interface StoreState {
	readonly orderItems: OrderItem[]
	readonly orderNumber: string | undefined,
	readonly externalReference?: string
	readonly name: string | undefined,
	readonly suburb: string | undefined,
	readonly address: string | undefined,
	readonly city: string | undefined,
	readonly branchName: string | undefined,
	readonly branchId: string | undefined,
	readonly branchContactNumber: string | undefined,
	readonly despatchMethod: CreateOrderDespatchMethodEnum,
	readonly despatchDate: string
	readonly despatchSlot?: ClosureType // mainly used for delivery and in-store orders
	readonly despatchClickAndCollectTimeslot?: ClickAndCollectTimeslot
	readonly recpientName?: string | undefined
	readonly notes: string | undefined,
	readonly onSiteContactNumber?: string | undefined
	readonly selectedAccount?: UserAccount | undefined
	readonly checkoutMethod?: CreateOrderCheckoutMethodEnum
	readonly simproJob?: SimproJob // we store job and section too to display on the Confirm Order screen (even though we only need cost center for actual request)
	readonly simproJobSection?: SimproJobSection
	readonly simproJobCostCenter?: SimproJobCostCenter
	readonly successSendOrder: boolean
	readonly loadingMessageSendOrder?: string

	readonly simproJobs: SimproJob[]
	readonly fetchingSimproJobs: boolean
	readonly simproJobsError: Error | undefined
	readonly simproJobSections: SimproJobSection[]
	readonly fetchingSimproJobSections: boolean
	readonly simproJobSectionsError: Error | undefined
	readonly simproJobCostCenters: SimproJobCostCenter[]
	readonly fetchingSimproJobCostCenters: boolean
	readonly simproJobCostCentersError: Error | undefined
}

const INITIAL_STATE: StoreState = {
	orderItems: [],
	orderNumber: '',
	externalReference: undefined,
	name: undefined,
	suburb: undefined,
	address: undefined,
	city: undefined,
	branchName: undefined,
	branchId: undefined,
	branchContactNumber: undefined,
	despatchMethod: CreateOrderDespatchMethodEnum.CPU,
	despatchDate: '',
	despatchSlot: undefined,
	despatchClickAndCollectTimeslot: undefined,
	notes: undefined,
	successSendOrder: true,
	loadingMessageSendOrder: undefined,

	simproJobs: [],
	fetchingSimproJobs: false,
	simproJobsError: undefined,
	simproJobSections: [],
	fetchingSimproJobSections: false,
	simproJobSectionsError: undefined,
	simproJobCostCenters: [],
	fetchingSimproJobCostCenters: false,
	simproJobCostCentersError: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(cartActions.addProductToCart, (state, payload): StoreState => {
	let itemExists: boolean = false
	// create full clone of existing items in cart
	const updatedOrders = state.orderItems.map((item) => {
		if (item.product.sku !== payload.product.sku) {
			// This isn't the item we care about - keep it as-is
			return item
		} else {
			// Otherwise, this is the one we want - return an updated value
			itemExists = true
			const updatedQuantity = item.quantity + payload.count
			if (payload.product.uom) {
				// handle extra validation for special uoms
				const formattedQuantity = formatQuantityWithDecimalCheck(updatedQuantity.toString(), payload.product.uom)
				return {
					...item,
					quantity: formattedQuantity <= 0 ? 0 : formattedQuantity,
				}
			}
			return {
				...item,
				quantity: updatedQuantity <= 0 ? 0 : updatedQuantity,
			}
		}
	})
	// if item was not found, create and add to end of list
	if (!itemExists) {
		const newOrderItem: OrderItem = {
			product: payload.product,
			quantity: payload.count,
			comment: payload.comment,
		}
		updatedOrders.push(newOrderItem)
	}
	return {
		...state,
		// only return items with valid quantities
		orderItems: updatedOrders.filter(item => item.quantity > 0),
	}
})

reducer.case(cartActions.removeProductFromCart, (state, payload): StoreState => {
	// create a new array of the current order items
	const currentOrderItems = Array.from(state.orderItems)
	// find the OrderItem by the product sku
	let currentOrderItem = currentOrderItems.find((element) => {
		return element.product.sku === payload.product.sku
	})
	// remove the order item from the order items if found
	if (currentOrderItem) {
		currentOrderItems.splice(currentOrderItems.indexOf(currentOrderItem), 1)
	}
	// checks if there are no order items left in the cart and clears the order details previously inputted
	const orderDetails = currentOrderItems.length === 0
		? {
			orderNumber: '',
			suburb: undefined,
			address: undefined,
			city: undefined,
			branchContactNumber: undefined,
			despatchMethod: CreateOrderDespatchMethodEnum.CPU,
			despatchDate: '',
			despatchSlot: undefined,
			notes: undefined,
		}
		: {}

	return {
		...state, orderItems: currentOrderItems, ...orderDetails
	}
})

reducer.case(cartActions.updateProductQuantityInCart, (state, payload): StoreState => {
	const updatedQuantity = parseFloat(payload.quantity.toFixed(2))
	let orderItems: OrderItem[] = []
	// find the OrderItem by the product sku
	const currentOrderItem: OrderItem | undefined = state.orderItems.find(item => item.product.sku === payload.product.sku)

	if (currentOrderItem) {
		orderItems = payload.quantity > 0
			// update the order item quantity
			? state.orderItems.map(item => item.product.sku !== payload.product.sku ? item : { ...item, quantity: updatedQuantity })
			// if the quantity is zero or below we filter out the OrderItem from the current order items
			: state.orderItems.filter(item => item.product.sku !== payload.product.sku)
	} else {
		if (payload.quantity > 0) {
			// add the payload OrderItem to the current list of order items
			orderItems = [...state.orderItems, { ...payload, quantity: updatedQuantity }]
		} else {
			orderItems = state.orderItems
		}
	}

	return { ...state, orderItems }
})

reducer.case(cartActions.updateProductQuantitiesInCart, (state, payload): StoreState => {
	return { ...state, orderItems: payload }
})


reducer.case(cartActions.updateProductCommentInCart, (state, payload): StoreState => {
	// find the OrderItem by the product sku
	const currentOrderItem: OrderItem | undefined = state.orderItems.find(item => item.product.sku === payload.product.sku)
	if (currentOrderItem) {
		const orderItems: OrderItem[] = state.orderItems.map(item => item.product.sku !== payload.product.sku ? item : { ...item, comment: payload.comment })
		return { ...state, orderItems }
	}
	return state
})

reducer.case(cartActions.updateProductPricesInCart.done, (state, payload): StoreState => {
	const { prices } = payload.result
	if (prices && prices.length > 0) {
		const updatedOrderItems: OrderItem[] = state.orderItems.map(order => {
			const productPrices: ProductPrices | undefined = prices.find(item => item.sku === order.product.sku)
			// append the updated prices if the product was found, if not then return as is
			if (productPrices) {
				const updatedProduct = getPlatformSupportImplementation().mapProductPricesToProductInfo(order.product, productPrices) // TODO consider removing this mapper (only copy account pricing for mobile client)
				return {
					...order,
					product: updatedProduct,
				}
			}
			return order

		})
		return { ...state, orderItems: updatedOrderItems }
	}
	return { ...state }
})

reducer.case(cartActions.updateProductAvailabilityInCart.done, (state, payload): StoreState => {
	const { products } = payload.result
	if (products && products.length > 0) {
		const updatedOrderItems: OrderItem[] = state.orderItems.map(order => {
			const productDetails = products.find(item => item.productId === order.product.sku)
			// append the updated availability if the product was found, if not then return as is
			if (productDetails) {
				return {
					...order,
					product: { ...order.product, quantityAvailable: productDetails.stockAvailable },
				}
			}
			return order

		})
		return { ...state, orderItems: updatedOrderItems }
	}
	return state
})

reducer.case(cartActions.navigateToConfirmOrder, (state, payload): StoreState => {
	if (payload.despatchDetails.despatchMethod === CreateOrderDespatchMethodEnum.DIR_D && payload.deliveryDetails) {
		return {
			...state,
			orderNumber: payload.orderNumber,
			externalReference: payload.externalReference,
			branchName: payload.branchName,
			branchId: payload.branchId,
			branchContactNumber: payload.branchContactNumber,
			name: payload.deliveryDetails.recipientName,
			suburb: payload.deliveryDetails.suburb,
			address: payload.deliveryDetails.streetAddress,
			city: payload.deliveryDetails.city,
			recpientName: payload.deliveryDetails.recipientName,
			notes: payload.notes,
			despatchMethod: payload.despatchDetails.despatchMethod,
			despatchDate: payload.despatchDetails.despatchDate,
			despatchSlot: payload.despatchDetails.despatchSlot,
			despatchClickAndCollectTimeslot: undefined,
			onSiteContactNumber: payload.deliveryDetails.onSiteContactNumber,
			checkoutMethod: payload.checkoutMethodDetails?.checkoutMethod,
			simproJob: payload.checkoutMethodDetails?.simproJob,
			simproJobSection: payload.checkoutMethodDetails?.simproJobSection,
			simproJobCostCenter: payload.checkoutMethodDetails?.simproJobCostCenter,
			successSendOrder: false,
		}
	} else {
		return {
			...state,
			orderNumber: payload.orderNumber,
			externalReference: payload.externalReference,
			branchName: payload.branchName,
			branchId: payload.branchId,
			branchContactNumber: payload.branchContactNumber,
			despatchMethod: payload.despatchDetails.despatchMethod,
			despatchDate: payload.despatchDetails.despatchDate,
			despatchSlot: payload.despatchDetails.despatchSlot,
			despatchClickAndCollectTimeslot: payload.despatchDetails.despatchClickAndCollectTimeslot,
			checkoutMethod: payload.checkoutMethodDetails?.checkoutMethod,
			simproJob: payload.checkoutMethodDetails?.simproJob,
			simproJobSection: payload.checkoutMethodDetails?.simproJobSection,
			simproJobCostCenter: payload.checkoutMethodDetails?.simproJobCostCenter,
			// clear out any delivery details
			name: undefined,
			suburb: undefined,
			address: undefined,
			city: undefined,
			recpientName: undefined,
			notes: payload.notes,
			onSiteContactNumber: undefined,
			successSendOrder: false,
		}
	}
})

reducer.case(cartActions.reOrder, (state, payload): StoreState => {

	// process order items
	let updatedOrders: OrderItem[] = payload.orderItems.map(orderItem => {
		return {
			product: {
				sku: orderItem.productId,
				primaryImageTitle: orderItem.productImage || '',
				longDescription: orderItem.productDescription,
				price: orderItem.price ? orderItem.price.toString() : undefined,
			},
			quantity: orderItem.quantity,
			comment: orderItem.comment,
		}
	})

	// set remaining order details
	// Note: we do not copy across the date/time as the order is probably in the past, so would be invalid
	// Note2: account ID not set as we need the full account object not just ID
	if (payload.despatchMethod === OrderDespatchMethodEnum.DIR_D) {
		return {
			...state,
			orderItems: updatedOrders,
			orderNumber: payload.orderReference,
			externalReference: payload.externalReference,
			branchName: payload.branchName,
			branchId: payload.branchId.toString(),
			branchContactNumber: payload.branchContactNumber,
			despatchMethod: payload.despatchMethod as unknown as CreateOrderDespatchMethodEnum,
			name: payload.clientName,
			suburb: payload.clientSuburb,
			address: payload.clientAddress,
			city: payload.clientCity,
			recpientName: payload.clientName,
			notes: payload.notes,
			onSiteContactNumber: payload.builderContact,
			successSendOrder: false,
		}
	} else {
		return {
			...state,
			orderItems: updatedOrders,
			orderNumber: payload.orderReference,
			externalReference: payload.externalReference,
			branchName: payload.branchName,
			branchId: payload.branchId.toString(),
			branchContactNumber: payload.branchContactNumber,
			despatchMethod: payload.despatchMethod as unknown as CreateOrderDespatchMethodEnum,
			// clear out any delivery details
			name: undefined,
			suburb: undefined,
			address: undefined,
			city: undefined,
			recpientName: undefined,
			notes: payload.notes,
			onSiteContactNumber: undefined,
			successSendOrder: false,
		}
	}
})

// simpro jobs
reducer
	.case(cartActions.getSimproJobs.started, (state): StoreState => {
		return {
			...state,
			simproJobs: [],
			fetchingSimproJobs: true,
			simproJobsError: undefined,
		}
	})
	.case(cartActions.getSimproJobs.done, (state, { result }): StoreState => {
		return {
			...state,
			simproJobs: result,
			fetchingSimproJobs: false,
		}
	})
	.case(cartActions.getSimproJobs.failed, (state, { error }): StoreState => {
		return {
			...state,
			simproJobsError: error,
			fetchingSimproJobs: false,
		}
	})
// simpro job sections
reducer
	.case(cartActions.getSimproJobSections.started, (state): StoreState => {
		return {
			...state,
			simproJobSections: [],
			fetchingSimproJobSections: true,
			simproJobSectionsError: undefined,
		}
	})
	.case(cartActions.getSimproJobSections.done, (state, { result }): StoreState => {
		return {
			...state,
			simproJobSections: result,
			fetchingSimproJobSections: false,
		}
	})
	.case(cartActions.getSimproJobSections.failed, (state, { error }): StoreState => {
		return {
			...state,
			simproJobSectionsError: error,
			fetchingSimproJobSections: false,
		}
	})
// simpro job cost center
reducer
	.case(cartActions.getSimproJobCostCenters.started, (state): StoreState => {
		return {
			...state,
			simproJobCostCenters: [],
			fetchingSimproJobCostCenters: true,
			simproJobCostCentersError: undefined,
		}
	})
	.case(cartActions.getSimproJobCostCenters.done, (state, { result }): StoreState => {
		return {
			...state,
			simproJobCostCenters: result,
			fetchingSimproJobCostCenters: false,
		}
	})
	.case(cartActions.getSimproJobCostCenters.failed, (state, { error }): StoreState => {
		return {
			...state,
			simproJobCostCentersError: error,
			fetchingSimproJobCostCenters: false,
		}
	})

reducer.case(cartActions.accountSelected, (state, payload): StoreState => {
	return {
		...state,
		// we will only allow approved accounts to be selected
		selectedAccount: payload.registrationState === AccountStatus.Approved ? payload : undefined
	}
})

reducer.case(cartActions.clearAccountSelected, (state): StoreState => {
	return {
		...state,
		selectedAccount: undefined,
	}
})

reducer.case(cartActions.branchSelected, (state, payload): StoreState => {
	return {
		...state,
		branchId: payload
	}
})

reducer.case(cartActions.sendOrderFailed, (state): StoreState => {
	return {
		...state,
		successSendOrder: true,
	}
})

reducer.cases([actions.saveOrderToDatabase.started, actions.submitOrderOnline.started], (state): StoreState => {
	return {
		...state,
		loadingMessageSendOrder: 'Sending order…',
	}
})

reducer.cases([actions.saveOrderToDatabase.failed, actions.submitOrderOnline.failed], (state): StoreState => {
	return {
		...state,
		successSendOrder: false,
		loadingMessageSendOrder: undefined,
	}
})

// Mobile uses saveOrderToDatabase to clear redux, while Web uses submitOrderOnline
reducer.cases([actions.saveOrderToDatabase.done, actions.submitOrderOnline.done], (state): StoreState => {
	return {
		...INITIAL_STATE,
		successSendOrder: true,
		loadingMessageSendOrder: undefined,
		selectedAccount: state.selectedAccount,
		branchId: state.branchId,
		despatchMethod: state.despatchMethod,
	}
})

reducer.case(cartActions.clearCart, (state): StoreState => {
	return {
		...INITIAL_STATE,
		selectedAccount: state.selectedAccount,
		branchId: state.branchId,
	}
})

reducer.case(authActions.loggedOut, (state): StoreState => {
	return {
		...INITIAL_STATE,
		branchId: state.branchId,
	}
})

reducer.cases([authActions.clearAuthToken, authActions.refreshAuthTokenFailed], (): StoreState => {
	return INITIAL_STATE
})

reducer.case(readyAction, (state): StoreState => {
	return {
		...state,
		successSendOrder: true,
		loadingMessageSendOrder: undefined,
	}
})

reducer.case(cartActions.updateDespatchMethodInCart, (state, payload): StoreState => {
	return {
		...state,
		despatchMethod: payload,
	}
})
