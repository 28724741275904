import '../styles/EditLoginId.scss'

import React, { useState, useEffect } from 'react'
import { Button, FormControl, TextField, FormHelperText } from '@mui/material'

import { isValidEmail } from '../../../common/auth/functions'
import EditLoginIdDialog from '../../admin/components/EditLoginIdDialog'
import { EditLoginIdDialogType } from '../../admin/types'

interface Props {
	isLoading: boolean
	success?: boolean
	error?: boolean
	onUpdateLoginId: (email: string) => void
}

const EditLoginId = ({ isLoading, success, error, onUpdateLoginId }: Props): JSX.Element => {

	/**
	 * Local State
	 */

	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState<string | undefined>(undefined)
	const [showDialog, setShowDialog] = useState(false)

	/**
	 * Effects/Subscriptions
	 */

	useEffect(() => {
		if (success || error) {
			// close the edit login ID dialog on success/error 
			setShowDialog(false)
		}
	}, [success, error])

	/**
	 * Local Functions
	 */

	const _onValidate = () => {
		if (!email) {
			setEmailError('An email is required')
			return
		} else if (email && !isValidEmail(email)) {
			setEmailError('Please enter a valid email')
			return
		}

		setShowDialog(true)
	}

	const _handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value)
		setEmailError(undefined)
	}

	const _handleOnKeyDown = (event: React.KeyboardEvent) => {
		if (event.keyCode === 13) {
			_onValidate()
		}
	}

	return (
		<div className="edit-login-id">
			<p className="edit-login-id__text edit-login-id__text--error">
				Your <span className="edit-login-id__text--em">Login ID</span> requires an email.
			</p>
			<p className="edit-login-id__text edit-login-id__text--error">Input an email and click Update to complete.</p>

			<FormControl className="edit-login-id__form">
				<TextField
					type="email"
					id="email"
					label="Email"
					value={email}
					onChange={_handleInputChange}
					onKeyDown={_handleOnKeyDown}
					variant="outlined"
					autoFocus={true}
					autoComplete="off"
					autoCapitalize="none"
				/>
				<FormHelperText error={!!emailError}>{emailError}</FormHelperText>
			</FormControl>

			<Button
				variant="contained"
				color="primary"
				className="accounts-table__button--update button--fixed-width"
				onClick={_onValidate}
				disabled={isLoading}
			>
				UPDATE
			</Button>

			{showDialog && (
				<EditLoginIdDialog
					type={EditLoginIdDialogType.NOTIFY_ME}
					isLoading={isLoading}
					onEdit={() => onUpdateLoginId(email)}
					onClose={() => setShowDialog(false)}
				/>
			)}
		</div>
	)
}

export default EditLoginId
