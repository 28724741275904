import React, { useEffect, useState } from 'react'
import { Divider } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { LabelOrderItem, ProductList } from 'typescript-fetch-api'

import { RootStoreState } from '../../../common/root'
import { SubheaderMenuProps } from '../../platform/types'
import { navigateToCreateLabels, navigateToUploadLabels, navigateToLabelCart, navigateToLabelGroup, fetchLabelGroups } from '../../../common/labels/actions'
import { navigateToOrdersTab, navigateToTab } from '../../platform/actions'
import MenuPopper from '../../platform/components/MenuPopper'

const LabelsMenuPopper = (props: SubheaderMenuProps): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */

	const labelGroups = useSelector<RootStoreState, ProductList[]>(state => state.labels.labelGroups)
	const orderItems = useSelector<RootStoreState, LabelOrderItem[]>(state => state.labels.labelOrderItems)
	const itemCount = orderItems.reduce<number>((data, item) => data + item.quantity, 0)

	/**
	 * Local State
	 */

	const [showSubMenu, setShowSubMenu] = useState<boolean>(false)
	const [subAnchorElement, setSubAnchorElement] = useState<HTMLDivElement | null>(null)

	/**
	 * Effects/Subscriptions
	 */

	useEffect(() => {
		dispatch(fetchLabelGroups.started({ includeItems: false }))
	}, [])

	/**
	 * Local Functions
	 */

	const _navigateToLabels = () => {
		dispatch(navigateToTab({ tab: 'labels' }))
		props.onClickAway()
	}

	const _onCreateNewLabelGroup = () => {
		dispatch(navigateToCreateLabels())
		props.onClickAway()
	}

	const _onImportLabelGroup = () => {
		dispatch(navigateToUploadLabels())
		props.onClickAway()
	}

	const _navigateToLabelOrders = () => {
		dispatch(navigateToOrdersTab({ tab: 'labels' }))
		props.onClickAway()
	}

	const _onLabelCartClick = () => {
		dispatch(navigateToLabelCart())
		props.onClickAway()
	}

	const _onLabelGroupClicked = (labelGroup: ProductList) => {
		dispatch(navigateToLabelGroup(labelGroup))
		props.onClickAway()
	}

	const _onMouseOver = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (!showSubMenu) {
			setShowSubMenu(true)
			setSubAnchorElement(e.currentTarget)
		}
	}

	const _onCloseSubmenu = () => {
		setShowSubMenu(false)
	}

	return (
		<React.Fragment>
			{/* on small screens the sub menu replaces top menu */}
			{(!props.isSmallScreen || !showSubMenu) &&
				<MenuPopper anchorElement={props.anchorElement} onClickAway={props.onClickAway} isHnzAccount={props.isHnzAccount} titleText="Labels" onCloseSubmenu={props.onCloseSubmenu}>
					<div className="menu-drawer__item" onMouseOver={!props.isSmallScreen ? _onMouseOver : undefined}>
						<span />
						<span className="menu-drawer__item--title" onClick={_navigateToLabels}>View Label Groups</span>
						<span className="menu-drawer__item--arrow icon-keyboard_arrow_right" onClick={_onMouseOver} />
					</div>
					<Divider className="menu-drawer__footer--divider" />
					<div className="menu-drawer__item" onClick={_onCreateNewLabelGroup}>
						<span />
						<span className="menu-drawer__item--title">Create Label Group</span>
						<span />
					</div>
					<Divider className="menu-drawer__footer--divider" />
					<div className="menu-drawer__item" onClick={_onImportLabelGroup}>
						<span />
						<span className="menu-drawer__item--title">Import Label Group</span>
						<span />
					</div>
					<Divider className="menu-drawer__footer--divider" />
					<div className="menu-drawer__item" onClick={_navigateToLabelOrders}>
						<span />
						<div className="menu-drawer__item--column">
							<span className="menu-drawer__item--title">Label Orders</span>
							<span className="menu-drawer__item--subtitle">View/Re-order Submitted Orders</span>
						</div>
						<span />
					</div>
					<Divider className="menu-drawer__footer--divider" />
					<div className="menu-drawer__item" onClick={_onLabelCartClick}>
						<span />
						<div className="menu-drawer__item--column">
							<span className="menu-drawer__item--title">Label Cart</span>
							<span className="menu-drawer__item--subtitle">{orderItems.length} Product{orderItems.length === 1 ? '' : 's'} | {itemCount} Label{itemCount === 1 ? '' : 's'}</span>
						</div>
						<span />
					</div>
					{/* <Divider className="menu-drawer__footer--divider" /> */}
				</MenuPopper>
			}

			{/* sub menu */}
			{showSubMenu && subAnchorElement && labelGroups.length > 0 &&
				<MenuPopper anchorElement={subAnchorElement} onClickAway={props.onClickAway} isHnzAccount={props.isHnzAccount} titleText="Label Groups" onCloseSubmenu={_onCloseSubmenu} anchorOffset={170}>
					{labelGroups.map(label => (
						<React.Fragment key={label.id}>
							<div className="menu-drawer__item" onClick={() => _onLabelGroupClicked(label)}>
								<span className={label.favourited ? 'menu-drawer__item--pin icon-push_pin' : undefined} />
								<span className="menu-drawer__item--title">{label.title}</span>
								<span />
							</div>
							<Divider className="menu-drawer__footer--divider" />
						</React.Fragment>
					))}
				</MenuPopper>
			}
		</React.Fragment>
	)
}

export default LabelsMenuPopper
