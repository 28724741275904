import * as React from 'react'
import { Popper, Fade, Paper, ClickAwayListener } from '@mui/material'

import { SubheaderMenuProps } from '../types'

import '../styles/Menu.css'

interface Props extends SubheaderMenuProps {
	children: React.ReactElement
}

export default class Menu extends React.PureComponent<Props> {

	render() {
		const { anchorElement, onClickAway, isHnzAccount, children } = this.props

		return (
			<Popper
				open={true}
				anchorEl={anchorElement}
				transition={true}
				modifiers={[{
					name: 'preventOverflow',
					enabled: true,
					options: {
						rootBoundary: 'scrollParent',
					}
				}]}
				className="menu"
			>
				{({ TransitionProps }) => (
					<ClickAwayListener
						onClickAway={onClickAway}
						// disable the listeners since we are listening to events via the anchor element
						mouseEvent={false}
						touchEvent={false}
					>
						<Fade {...TransitionProps}>
							<Paper
								className="menu__content"
								square={true}
							>
								{children}
								{isHnzAccount && <div className="menu__indicator--hnz" />}
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		)
	}
}