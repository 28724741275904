import '../styles/BranchDetails.scss'

import { Collapse } from '@mui/material'
import { useState } from 'react'
import { OpeningHoursType, StandardHoursDay } from 'typescript-fetch-api'
import moment from 'moment'

import { getDayOfWeekLabel } from '../../../common/showrooms/functions'
import { API_DATE_TIME_FORMAT } from '../../../common/util/functions'

interface Props {
	standardHours: StandardHoursDay[]
	latitude: number
	longitude: number
}

const BranchDetails = ({ standardHours, latitude, longitude }: Props) => {

	/**
	 * Local State
	 */

	const [open, setOpen] = useState<boolean>(false)

	/**
	 * Local Functions
	 */

	const _toggleCollapse = () => {
		setOpen(!open)
	}

	const _renderDay = (day: StandardHoursDay): JSX.Element => {
		const isClosed = day.openingHoursType === OpeningHoursType.CLOSED
		const start = moment(day.startTimestamp, API_DATE_TIME_FORMAT).format('h:mm a')
		const end = moment(day.endTimestamp, API_DATE_TIME_FORMAT).format('h:mm a')

		return (
			<div key={day.id} className="branch-details__day">
				<p className="branch-details__text">{getDayOfWeekLabel(day.dayOfWeek)}</p>
				{isClosed
					? <p className="branch-details__text branch-details__text--hours">Closed</p>
					: <p className="branch-details__text branch-details__text--hours">{start} - {end}</p>
				}
			</div>
		)
	}

	return (
		<div className="branch-details">
			<div className="branch-details__title" onClick={_toggleCollapse}>
				<p className={`branch-details__text branch-details__text--title ${open ? 'branch-details__text--open' : ''}`}>Branch Details</p>
				<span className={`${open ? 'icon-arrow_drop_up branch-details__icon--open' : 'icon-arrow_drop_down'} branch-details__icon`} />
			</div>
			<Collapse in={open} unmountOnExit={true}>
				{standardHours.map(_renderDay)}

				<a
					href={`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`}
					target="_blank"
					rel="noreferrer"
					className="branch-details__text branch-details__text--title branch-details__directions"
				>
					<p className="branch-details__text">Get Directions</p>
					<span className="icon-arrow_drop_up branch-details__icon  branch-details__icon--rotate" />
				</a>
			</Collapse>
		</div>
	)
}

export default BranchDetails