import { SagaIterator } from 'redux-saga'
import { takeEvery, call } from 'redux-saga/effects'

import * as actions from './actions'
import { ApiErrorMessage, getPriceBookApi } from '../api'
import { callApi } from '../api/functions'

function* fetchPriceBookSubscription(action: actions.FetchPriceBookSubscriptionAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchPriceBookSubscription,
		() => getPriceBookApi().getPriceBookSubscription({ customerId: action.payload })
	)
}

function* updatePriceBookSubscription(action: actions.UpdatePriceBookSubscriptionAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.updatePriceBookSubscription,
		() => {
			const { customerId, subscription: priceBookSubscription } = action.payload
			return getPriceBookApi().updatePriceBookSubscription({ customerId, priceBookSubscription })
		}
	)
}

function* createPriceBookRequest(action: actions.CreatePriceBookRequestAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.createPriceBookRequest,
		() => {
			const { customerId, request: priceBookRequest } = action.payload
			return getPriceBookApi().createPriceBookRequest({ customerId, priceBookRequest })
				.catch(error => {
					let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
					if (error instanceof Response) {
						switch (error.status) {
							case 429:
								errorText = ApiErrorMessage.REQUEST_MAX_ERROR_MESSAGE
								break
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
		}
	)
}

function* reloadSubscription(action: actions.CreatePriceBookRequestSuccessAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchPriceBookSubscription,
		() => getPriceBookApi().getPriceBookSubscription({ customerId: action.payload.params.customerId })
	)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.fetchPriceBookSubscription.started, fetchPriceBookSubscription)
	yield takeEvery(actions.updatePriceBookSubscription.started, updatePriceBookSubscription)
	yield takeEvery(actions.createPriceBookRequest.started, createPriceBookRequest)
	yield takeEvery(actions.createPriceBookRequest.done, reloadSubscription)
}