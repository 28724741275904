import { SagaIterator } from 'redux-saga'
import { call, select, takeEvery } from 'redux-saga/effects'

import { navigateToArofloCheckout, navigateToCart, navigateToCheckout, navigateToCheckoutWithSimpro, navigateToConfirmOrder, navigateToOrders, navigateToReviewCart } from '../../common/cart/actions'
import { submitOrderOnline, SubmitOrderOnlineSuccessAction } from '../../common/order/actions'
import { loggedIn } from '../../common/auth/sagas'
import { CheckoutPath, OrderCompleteNavigationState, ConfirmNavigationState, CheckoutNavigationState } from '../cart/types'
import { orderItemsSelector, selectedAccountSelector } from '../../common/order/selectors'
import { UserAccount } from '../../common/accounts/types'
import * as NavigationManager from '../navigation/NavigationManager'
import { OrderItem } from '../../common/order/types'
import { Paths } from '../navigation/types'

function* handleNavigateToReviewCart(): SagaIterator {
	// if not signed in, go sign in first, then redirect to checkout
	const isLoggedIn: boolean | undefined = yield call(loggedIn)
	if (isLoggedIn) {
		NavigationManager.navigateToCheckout()
	} else {
		NavigationManager.navigateToSignIn()
	}
}

function handleNavigateToCheckout() {
	NavigationManager.navigateToCheckoutWithAction(CheckoutPath.OrderDetails)
}

function handleNavigateToCheckoutWithSimpro() {
	const state: CheckoutNavigationState = { checkoutMethod: 'simpro' }
	NavigationManager.navigateToCheckoutWithAction(CheckoutPath.OrderDetails, state)
}

function* handleNavigateToConfirmOrder(): SagaIterator {
	const orderItems: OrderItem[] = yield select(orderItemsSelector)
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)

	const state: ConfirmNavigationState = { orderItems, selectedAccount }
	NavigationManager.navigateToCheckoutWithAction(CheckoutPath.Confirm, state)
}

function handleNavigateToCompleteOrder(action: SubmitOrderOnlineSuccessAction) {
	// only navigate the user to the order complete page if they are in a checkout url
	if (NavigationManager.getLocation()?.pathname === Paths.CHECKOUT) {
		const { params, result: { customerId } } = action.payload
		const state: OrderCompleteNavigationState = { order: params, customerId }
		NavigationManager.navigateToCheckoutWithAction(CheckoutPath.Complete, state)
	}
}

function handleNavigateToOrders() {
	NavigationManager.navigateToCustomerOrders()
}

function handleNavigateToArofloCheckout() {
	NavigationManager.navigateToArofloCheckoutLoadingScreen()
}

export default function* (): SagaIterator {
	yield takeEvery([navigateToReviewCart, navigateToCart], handleNavigateToReviewCart)
	yield takeEvery(navigateToCheckout, handleNavigateToCheckout)
	yield takeEvery(navigateToCheckoutWithSimpro, handleNavigateToCheckoutWithSimpro)
	yield takeEvery(navigateToConfirmOrder, handleNavigateToConfirmOrder)
	yield takeEvery(submitOrderOnline.done, handleNavigateToCompleteOrder)
	yield takeEvery(navigateToOrders, handleNavigateToOrders)
	yield takeEvery(navigateToArofloCheckout, handleNavigateToArofloCheckout)
}