import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, all } from 'redux-saga/effects'

import * as actions from '../../common/remoteConfig/actions'
import * as authActions from '../../common/auth/actions'
import * as rootActions from '../../common/root/actions'
import { authTokenSelector } from '../../common/auth/selectors'
import { RemoteConfig } from '../../common/remoteConfig/types'

/**
 * Handles fetching all remote configs from firebase.
 * Note: We keep this platform-specific since we might not need all remote configs for web and vice versa.
 */
function* handleFetchAllRemoteConfigs(): SagaIterator {
	yield all([
		put(actions.fetchRemoteConfig(RemoteConfig.ACCOUNT_PAYMENT)),
		put(actions.fetchRemoteConfig(RemoteConfig.LABELS)),
		put(actions.fetchRemoteConfig(RemoteConfig.PRICEBOOKS)),
		put(actions.fetchRemoteConfig(RemoteConfig.CLICK_AND_COLLECT)),
		put(actions.fetchRemoteConfig(RemoteConfig.PRODUCT_COMMENTS)),
		put(actions.fetchRemoteConfig(RemoteConfig.MANAGE_SCHEDULED_INVOICES)),
		put(actions.fetchRemoteConfig(RemoteConfig.STATEMENT_MESSAGE)),
		put(actions.fetchRemoteConfig(RemoteConfig.INTERIM_ORDERS)),
	])
}

function* handleFetchRemoteConfigOnReady(): SagaIterator {
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (hasAuthToken) {
		yield call(handleFetchAllRemoteConfigs)
	}
}

export default function* (): SagaIterator {
	yield takeEvery(authActions.login.done, handleFetchAllRemoteConfigs)
	yield takeEvery(rootActions.readyAction, handleFetchRemoteConfigOnReady)
}