import { LabelOrderItem, ProductListItem, ProductList, LabelOrder, Order, ClosureType, OrderDespatchMethodEnum } from 'typescript-fetch-api'
import { ProductInfo } from '../../modules/platform'
import { createInstructionsForOrder } from '../util/functions'

export const RECIPIENT_NAME_PLACEHOLDER: string = ' Recipient Name: '
export const ON_SITE_CONTACT_NUMBER_PLACEHOLDER: string = ' On Site Contact Number: '
export const LABEL_CART_LIMIT: number = 300

export const convertProductListItemToLabelOrderItem = (productListItem: ProductListItem): LabelOrderItem => {
	// convert to label order item
	const labelOrderItem: LabelOrderItem = {
		productSku: productListItem.sku,
		productDescription: productListItem.shortdescription,
		productImage: productListItem.primaryimage,
		quantity: productListItem.quantity,
	}

	return labelOrderItem
}

/**
 * Sort the label groups by favourited, then title.
 * @param labelGroups the label groups to sort
 */
export const getSortedLabelGroups = (labelGroups: ProductList[]): ProductList[] => {
	const sorted = labelGroups.sort((a, b) => {
		if (a.favourited && b.favourited) {
			return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
		} else if (a.favourited || b.favourited) {
			return b.favourited ? 1 : -1
		}
		return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
	})
	return sorted
}

export const convertLabelOrderToApiOrder = (order: LabelOrder): Order => ({
	id: order.id,
	orderItems: order.orderItems.map(item => ({
		productId: item.productSku,
		productDescription: item.productDescription,
		productImage: item.productImage,
		quantity: item.quantity,
	})),
	branchId: order.branchId,
	branchName: order.branchName,
	branchContactNumber: order.branchContactNumber,
	clientAddress: order.shippingAddress,
	clientCity: order.shippingCity,
	clientSuburb: order.shippingSuburb,
	despatchMethod: order.despatchMethod as unknown as OrderDespatchMethodEnum,
	// we mimic how the shipping instructions are setup for a normal order
	shippingInstructions: createInstructionsForOrder(order.despatchMethod, order.despatchSlot as ClosureType),
	// we extract name and number from the notes for label orders 
	clientName: extractClientName(order.instructions),
	builderContact: extractContactNumber(order.instructions),
	notes: extractNotes(order.instructions),
	orderStatus: order.orderStatus,
	dateRequired: order.dateRequired,
	dateCreated: order.dateCreated,

	// the following fields do not apply to a label order
	orderReference: '',
	customerId: 0,
	customerName: '',
})

function extractClientName(notes?: string): string {
	if (notes && notes.includes(RECIPIENT_NAME_PLACEHOLDER) && notes.includes(ON_SITE_CONTACT_NUMBER_PLACEHOLDER)) {
		return notes.substring(notes.indexOf(RECIPIENT_NAME_PLACEHOLDER) + RECIPIENT_NAME_PLACEHOLDER.length, notes.indexOf(ON_SITE_CONTACT_NUMBER_PLACEHOLDER))
	}
	return ''
}

function extractContactNumber(notes?: string): string {
	if (notes && notes.includes(ON_SITE_CONTACT_NUMBER_PLACEHOLDER)) {
		return notes.substring(notes.indexOf(ON_SITE_CONTACT_NUMBER_PLACEHOLDER) + ON_SITE_CONTACT_NUMBER_PLACEHOLDER.length)
	}
	return ''
}

function extractNotes(notes?: string): string | undefined {
	// remove name and number from the notes
	if (notes && notes.includes(RECIPIENT_NAME_PLACEHOLDER) && notes.includes(ON_SITE_CONTACT_NUMBER_PLACEHOLDER)) {
		return notes.substring(0, notes.indexOf(RECIPIENT_NAME_PLACEHOLDER))
	}
	return notes
}

export function createDeliveryInstructionsForLabelOrder(instructions: string, recipientName?: string, onSiteContactNumber?: string): string {
	// append the recipient name and on-site contact number if in delivery mode
	let deliveryInstructions = instructions
	if (recipientName) {
		instructions += RECIPIENT_NAME_PLACEHOLDER + recipientName
	}
	if (onSiteContactNumber) {
		instructions += ON_SITE_CONTACT_NUMBER_PLACEHOLDER + onSiteContactNumber
	}
	return deliveryInstructions
}

export function isAddToCartDisabled(labelOrderItems: LabelOrderItem[], item: ProductListItem | ProductInfo): boolean {
	// check the limit in label cart (individual products, not summed quantities)
	return labelOrderItems.length >= LABEL_CART_LIMIT && !labelOrderItems.some(label => label.productSku === item.sku)
}

export function isAddAllToCartDisabled(labelOrderItems: LabelOrderItem[], items: ProductListItem[]): boolean {
	// check if at least one item in the list being added, is not already in the cart (meaning we are trying to add new line in cart)
	if (labelOrderItems.length >= LABEL_CART_LIMIT && items.some(item => !labelOrderItems.some(cartItem => cartItem.productSku === item.sku))) return true
	// check if sum of all items and item in the cart exceeds the limit
	if (labelOrderItems.length + items.length >= LABEL_CART_LIMIT) return true
	return false
}

/**
 * Handles updating the label groups in the store.
 * NOTE: we make an extra check if a label group is selected, to prevent clearing the content from the selected label group
 * 
 * @param labelGroups the label groups saved in the store that we want to update
 * @param updatedLabelGroups the updated label groups 
 * @param selectedLabelGroupId the ID of the selected label group, if any
 */
export const updateLabelGroups = (labelGroups: ProductList[], updatedLabelGroups: ProductList[], selectedLabelGroupId?: string) => {
	if (selectedLabelGroupId) {
		// find the selected label group
		const selectedLabelGroup = labelGroups.find(labelGroup => labelGroup.id === selectedLabelGroupId)
		if (selectedLabelGroup) {
			// updates all the label groups except the selected label group, to prevent clearing the content
			return updatedLabelGroups.map(updatedLabelGroup => {
				if (updatedLabelGroup.id === selectedLabelGroupId) return selectedLabelGroup
				return updatedLabelGroup
			})
		}
	}
	// updates all the label groups
	return updatedLabelGroups
}