import * as React from 'react'
import { Divider } from '@mui/material'
import { ProductList } from 'typescript-fetch-api'

import * as Container from '../containers/ListMenu'
import MenuPopper from '../../platform/components/MenuPopper'
import { getListSections } from '../../../common/mylists/functions'
import { MY_LISTS_ID, UNICODE_LONG_DASH } from '../../../common/mylists/content'
import { AccountList } from '../../../common/mylists/types'

interface State {
	showSubMenu: boolean
	subAnchorElement: HTMLElement | null
}

const INITIAL_STATE: State = {
	showSubMenu: false,
	subAnchorElement: null,
}

export default class ListPopperMenu extends React.PureComponent<Container.Props & Container.OwnProps & Container.Actions, State> {

	state = INITIAL_STATE

	componentDidMount() {
		this.props.fetchLists()
	}

	_navigateToLists = () => {
		this.props.navigateToLists()
		this.props.onClickAway()
	}

	_navigateToNewList = () => {
		this.props.navigateToNewList()
		this.props.onClickAway()
	}

	_navigateToImport = () => {
		this.props.navigateToImportList()
		this.props.onClickAway()
	}

	_navigateToListGroup = (list: AccountList) => {
		this.props.navigateToAccountList(list)
		this.props.onClickAway()
	}

	_navigateToList = (list: ProductList) => {
		this.props.navigateToProductList(list)
		this.props.onClickAway()
	}

	_onMouseOver = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (!this.state.showSubMenu) {
			this.setState({ showSubMenu: true, subAnchorElement: e.currentTarget })
		}
	}

	_onCloseSubmenu = () => {
		this.setState({ showSubMenu: false })
	}

	render() {
		const { anchorElement, onClickAway, isHnzAccount, lists, selectedAccount, isSmallScreen, onCloseSubmenu } = this.props
		const { showSubMenu, subAnchorElement } = this.state

		const sections = getListSections(lists, isHnzAccount, selectedAccount)

		return (
			<React.Fragment>
				{/* on small screens the sub menu replaces top menu */}
				{(!isSmallScreen || !showSubMenu) &&
					<MenuPopper anchorElement={anchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount} titleText="Lists" onCloseSubmenu={onCloseSubmenu}>
						<div className="menu-drawer__item" onMouseOver={!isSmallScreen ? this._onMouseOver : undefined}>
							<span />
							<span className="menu-drawer__item--title" onClick={this._navigateToLists}>View Lists</span>
							<span className="menu-drawer__item--arrow icon-keyboard_arrow_right" onClick={this._onMouseOver} />
						</div>
						<Divider className="menu-drawer__footer--divider" />
						<div className="menu-drawer__item" onClick={this._navigateToNewList}>
							<span />
							<span className="menu-drawer__item--title">Create List</span>
							<span />
						</div>
						<Divider className="menu-drawer__footer--divider" />
						<div className="menu-drawer__item menu-drawer__import-list" onClick={this._navigateToImport}>
							<span />
							<span className="menu-drawer__item--title">Import List</span>
							<span />
						</div>
					</MenuPopper>
				}

				{/* sub menu */}
				{showSubMenu && subAnchorElement && sections.length > 0 &&
					<MenuPopper anchorElement={subAnchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount} titleText="List Categories" onCloseSubmenu={this._onCloseSubmenu} anchorOffset={113}>
						{sections.map(list => (
							<React.Fragment key={list.id}>
								<div className="menu-drawer__item" onClick={() => this._navigateToListGroup(list)}>
									<span />
									<span className="menu-drawer__item--title">{list.title.replace(UNICODE_LONG_DASH, '|')}</span>
									<span />
								</div>
								<Divider className="menu-drawer__footer--divider" />
								{/* if this section is user lists - append pinned user lists */}
								{list.id === MY_LISTS_ID &&
									list.data
										.filter(userList => !!userList.favourited)
										.map(userList => (
											<React.Fragment key={userList.id}>
												<div className="menu-drawer__item" onClick={() => this._navigateToList(userList)}>
													<span className="menu-drawer__item--pin icon-push_pin" />
													<span className="menu-drawer__item--title">{userList.title}</span>
													<span />
												</div>
												<Divider className="menu-drawer__footer--divider" />
											</React.Fragment>
										))}
							</React.Fragment>
						))}
					</MenuPopper>
				}
			</React.Fragment>
		)
	}
}