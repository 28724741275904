import { SagaIterator } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'

import * as actions from '../../common/labels/actions'
import { loggedIn } from '../../common/auth/sagas'
import { LabelOrderCompleteNavigationProps, LabelsDialogType, NavigateToCreateLabelsState } from './types'
import { CheckoutPath } from '../cart/types'
import * as NavigationManager from '../navigation/NavigationManager'
import { Paths } from '../navigation/types'

function handleNavigateToLabelOrders() {
	NavigationManager.navigateToLabelOrders()
}

function* handleNavigateToReviewLabelCart(): SagaIterator {
	// if not signed in, go sign in first, then redirect to checkout
	const isLoggedIn: boolean | undefined = yield call(loggedIn)
	if (isLoggedIn) {
		NavigationManager.navigateToLabelCheckout()
	} else {
		NavigationManager.navigateToSignIn()
	}
}

function handleNavigateToLabelCheckout() {
	NavigationManager.navigateToLabelCheckoutWithAction(CheckoutPath.OrderDetails)
}

function handleNavigateToLabelConfirm() {
	NavigationManager.navigateToLabelCheckoutWithAction(CheckoutPath.Confirm)
}

function handleNavigateToLabelOrderComplete(action: actions.CreateLabelOrderActionSuccess) {
	// only navigate the user to the label order complete page if they are in a label checkout url
	if (NavigationManager.getLocation()?.pathname === Paths.LABELS_CHECKOUT) {
		const { params, result: { orderStatus, dateCreated } } = action.payload
		const state: LabelOrderCompleteNavigationProps = { labelOrder: params, orderStatus, dateCreated }
		NavigationManager.navigateToLabelCheckoutWithAction(CheckoutPath.Complete, state)
	}
}

function handleNavigateToLabels() {
	NavigationManager.navigateToLabels()
}

function handleNavigateToCreateLabels() {
	const state: NavigateToCreateLabelsState = { dialogType: LabelsDialogType.CREATE }
	NavigationManager.navigateToLabels(state)
}

function handleNavigateToUploadLabels() {
	const state: NavigateToCreateLabelsState = { dialogType: LabelsDialogType.UPLOAD }
	NavigationManager.navigateToLabels(state)
}

function handleNavigateToLabelGroup(action: actions.NavigateToLabelGroupAction) {
	const listId = action.payload.id
	NavigationManager.navigateToLabelGroup(listId)
}

function handleNavigateToLabelOrderDetails(action: actions.NavigateToLabelOrderAction) {
	const orderId = action.payload
	NavigationManager.navigateToLabelOrderDetails(orderId)
}

function* handleCreateOrEditLabelGroupDone(action: actions.CreateOrEditLabelsGroupActionSuccess) {
	if (action.payload.params.navigateToDetailsOnSuccess) {
		// navigate to the label details for new list - can use list from params as all we need is the list id
		yield put(actions.navigateToLabelGroup(action.payload.params.list))
	}
}

export default function* (): SagaIterator {
	yield takeEvery(actions.navigateToLabelOrders, handleNavigateToLabelOrders)
	yield takeEvery(actions.navigateToLabelCart, handleNavigateToReviewLabelCart)
	yield takeEvery(actions.navigateToLabelCheckout, handleNavigateToLabelCheckout)
	yield takeEvery(actions.navigateToLabelConfirm, handleNavigateToLabelConfirm)
	yield takeEvery(actions.createLabelOrder.done, handleNavigateToLabelOrderComplete)
	yield takeEvery(actions.navigateToLabels, handleNavigateToLabels)
	yield takeEvery(actions.navigateToCreateLabels, handleNavigateToCreateLabels)
	yield takeEvery(actions.navigateToUploadLabels, handleNavigateToUploadLabels)
	yield takeEvery(actions.navigateToLabelGroup, handleNavigateToLabelGroup)
	yield takeEvery(actions.navigateToLabelOrder, handleNavigateToLabelOrderDetails)
	yield takeEvery(actions.createOrEditLabelsGroup.done, handleCreateOrEditLabelGroupDone)
}