import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { ProductList, ProductListGroup } from 'typescript-fetch-api'

// import Component from '../components/ListMenu'
import Component from '../components/ListPopperMenu'
import { navigateToTab } from '../../platform/actions'
import { fetchLists, navigateToAccountList, navigateToImportList, navigateToListDetails, navigateToNewList } from '../../../common/mylists/actions'
import { SubheaderMenuProps } from '../../platform/types'
import { RootStoreState } from '../../../common/root'
import { UserAccount } from '../../../common/accounts/types'
import { AccountList } from '../../../common/mylists/types'

export type OwnProps = SubheaderMenuProps

export interface Props {
	lists: ProductListGroup[]
	selectedAccount?: UserAccount
}

export interface Actions {
	fetchLists: () => void
	navigateToLists: () => void
	navigateToNewList: () => void
	navigateToImportList: () => void
	navigateToAccountList: (list: AccountList) => void
	navigateToProductList: (list: ProductList) => void
}

const mapStateToProps = (state: RootStoreState): Props => {
	return {
		lists: state.lists.lists,
		selectedAccount: state.order.selectedAccount,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	fetchLists: () => {
		dispatch(fetchLists.started({ includeItems: false }))
	},
	navigateToLists: () => {
		dispatch(navigateToTab({ tab: 'lists' }))
	},
	navigateToNewList: () => {
		dispatch(navigateToNewList(undefined))
	},
	navigateToImportList: () => {
		dispatch(navigateToImportList())
	},
	navigateToAccountList: (list: AccountList) => {
		dispatch(navigateToAccountList(list))
	},
	navigateToProductList: (list: ProductList) => {
		dispatch(navigateToListDetails(list))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
