import React from 'react'
import { TableRow, TableCell, Checkbox } from '@mui/material'

import { UserAccount } from '../../../common/accounts/types'
import AccountStatus from './AccountStatus'

interface Props {
	account: UserAccount
	optedIn: boolean
	isSelectable: boolean
	onAccountEmailOptIn: (accountId: number, optIn: boolean) => void
}

const AccountRow = ({ account, optedIn, isSelectable, onAccountEmailOptIn }: Props): JSX.Element => {

	const _onAccountEmailOptIn = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		onAccountEmailOptIn(account.id, checked)
	}

	return (
		<TableRow key={account.id}>
			<TableCell align="center"><AccountStatus account={account} /></TableCell>
			<TableCell>{account.name || account.prostixName}<br />{account.customerId || 'Unassigned'}</TableCell>
			<TableCell align="center">{account.accountRoleName || 'Unassigned'}</TableCell>
			<TableCell align="center">
				{isSelectable && (
					<Checkbox
						checked={optedIn}
						icon={<div className="accounts-table__icon accounts-table__icon--unchecked" />}
						checkedIcon={<div className="accounts-table__icon accounts-table__icon--checked"><span className="icon-check" /></div>}
						onChange={_onAccountEmailOptIn}
						color="secondary"
					/>
				)}
			</TableCell>
		</TableRow>
	)
}

export default AccountRow
