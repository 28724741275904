import '../styles/OrderItemQuantity.scss'

import React, { useEffect, useState } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import { ERP } from 'typescript-fetch-api'

import { formatQuantityWithDecimalCheck, getUpdatedQuantityWithDecimalCheck } from '../../../common/cart/functions'
import { QUANTITY_MIN_VALUE } from '../content'
import { RootStoreState } from '../../../common/root'

interface Props {
	itemQuantity: string
	uom?: string
	hideUom?: boolean
	onUpdateQuantity: (quantity: number) => void
}

const OrderItemQuantity = ({ itemQuantity, uom, hideUom, onUpdateQuantity }: Props): JSX.Element => {

	/**
	 * Store State
	 */

	const isBranchDynamics = useSelector<RootStoreState, boolean>(state => {
		for (let i = 0; i < state.showrooms.regions.length; i++) {
			const matchingBranch = state.showrooms.regions[i].branches.find(branch => branch.id === state.order.branchId)
			if (matchingBranch) {
				return matchingBranch.erp === ERP.DYNAMICS
			}
		}
		return false
	})

	/**
	 * Local State
	 */

	const [quantity, setQuantity] = useState<string>(QUANTITY_MIN_VALUE)

	/**
	 * Effects/Subscriptions
	 */

	useEffect(() => {
		// update the local quantity to match
		if (itemQuantity && quantity !== itemQuantity) {
			setQuantity(itemQuantity)
		}
	}, [itemQuantity]) // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * Local Functions
	 */

	const _handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQuantity(getUpdatedQuantityWithDecimalCheck(e.target.value, uom || '', isBranchDynamics))
	}

	const _handleInputOnClick = (e: React.MouseEvent) => {
		e.stopPropagation() // stop event propogation to parent
	}

	const _onUpdateQuantity = () => {
		if (quantity === '' || Number(quantity) <= 0) { // set quantity in state to the original value when the input is empty
			// revert to original quantity
			setQuantity(itemQuantity)
		} else if (quantity !== itemQuantity) {
			onUpdateQuantity(formatQuantityWithDecimalCheck(quantity, uom || ''))
		}
	}

	const _handleInputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			// trigger blur to update the quantity and close the soft keyboard
			(e.target as HTMLInputElement).blur()
		}
	}

	const _handleInputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		// when the input is first focused, highlight the text so its easy to change quantity
		e.target.select()
	}

	return (
		<TextField
			id="quantity"
			type="number"
			variant="outlined"
			value={quantity}
			onChange={_handleInputOnChange}
			onClick={_handleInputOnClick}
			onBlur={_onUpdateQuantity}
			// @ts-ignore - focus type mismatch
			onFocus={_handleInputOnFocus}
			onKeyDown={_handleInputOnKeyDown}
			InputProps={{
				endAdornment: uom && !hideUom ? <InputAdornment position="end"><p className="order-item-quantity__text">{uom}</p></InputAdornment> : undefined,
				className: 'order-item-quantity',
			}}
			inputProps={{
				className: 'order-item-quantity__input',
				min: 1,
			}}
			className="order-item-quantity__root"
		/>
	)
}

export default OrderItemQuantity
