import * as React from 'react'
import { Button, Dialog as MaterialDialog, DialogTitle, DialogContent, DialogActions, DialogProps } from '@mui/material'

import '../styles/Dialog.css'

interface Props {
	isOpen: boolean
	title: string
	message?: React.ReactNode
	onClose?: () => void
	onConfirm?: () => void
	onCancel?: () => void
}

export default class Dialog extends React.PureComponent<Props & Omit<DialogProps, 'open'>> {

	render() {
		const { isOpen, title, children, onClose, onConfirm, onCancel, message, maxWidth, ...rest } = this.props

		return (
			<MaterialDialog
				open={isOpen}
				onClose={onClose}
				maxWidth={maxWidth || 'md'}
				className="dialog"
				{...rest}
			>
				<DialogTitle className="dialog__text--title">
					{title}
				</DialogTitle>

				<DialogContent className="dialog__content">
					{children}
				</DialogContent>

				<DialogActions className="dialog__actions">
					{onConfirm && (
						<Button
							variant="outlined"
							onClick={onConfirm}
						>
							Confirm
						</Button>
					)}
					{onCancel && (
						<Button
							variant="outlined"
							onClick={onCancel}
						>
							Cancel
						</Button>
					)}
				</DialogActions>

				{message && <div className="dialog__message">{message}</div>}
			</MaterialDialog>
		)
	}
}