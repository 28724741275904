import { AppState, OfflineAction } from '@redux-offline/redux-offline/lib/types'

export const CREATE_ORDER_401 = 'Create order 401'
export const ERROR_ORDER_NOT_FOUND_IN_DATABASE: string = 'Order not found in database'

/**
 * Gets the list of pending offline actions in queue
 */
export function getOfflineQueue(state: AppState): OfflineAction[] {
	return state.offline.outbox
}