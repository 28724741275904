import { CreateLabelOrder, LabelOrder, LabelOrderItem, Order, PagedUserLabelOrdersResponse, ProductList, ProductListItem, SearchLabelOrdersFilter, UpdateListProductsActionType, UploadListResponse } from 'typescript-fetch-api'
import actionCreatorFactory, { Action, Failure, Success } from 'typescript-fsa'
import { Filter } from '../util/types'
import { LabelOrderInputs } from './types'

const actionCreator = actionCreatorFactory('Labels')

export const navigateToLabels = actionCreator('GO_LABELS')

export const navigateToLabelOrders = actionCreator('GO_LABEL_ORDERS')

export type CreateNewLabelsAction = Action<ProductListItem[] | void>
export const navigateToCreateLabels = actionCreator<ProductListItem[] | void>('GO_CREATE_LABELS')

export const navigateToUploadLabels = actionCreator<ProductListItem[] | void>('GO_UPLOAD_LABELS')

export type NavigateToLabelGroupAction = Action<ProductList>
export const navigateToLabelGroup = actionCreator<ProductList>('NAVIGATE_TO_LABEL_GROUP')

export const navigateToLabelCart = actionCreator('NAVIGATE_TO_LABEL_CART')

export const navigateToLabelCheckout = actionCreator('NAVIGATE_TO_LABEL_CHECKOUT')

export const navigateToLabelConfirm = actionCreator('NAVIGATE_TO_LABEL_CONFIRM')

export const navigateToPin = actionCreator('NAVIGATE_TO_PIN')

export type NavigateToLabelOrderAction = Action<string>
export const navigateToLabelOrder = actionCreator<string>('NAVIGATE_TO_LABEL_ORDER')

export const reOrderLabels = actionCreator<Order>('RE_ORDER_LABELS')

export interface CreateOrEditLabelsGroupPayload {
	list: ProductList
	fromProduct?: boolean
	navigateToDetailsOnSuccess?: boolean
}
export type CreateOrEditLabelsGroupAction = Action<CreateOrEditLabelsGroupPayload>
export type CreateOrEditLabelsGroupActionSuccess = Action<Success<CreateOrEditLabelsGroupPayload, ProductList[]>>
export const createOrEditLabelsGroup = actionCreator.async<CreateOrEditLabelsGroupPayload, ProductList[], Error>('CREATE_OR_EDIT_LABELS_GROUP')

export interface UpdateItemInLabelCartPayload {
	item: LabelOrderItem
}
export const addItemQuantityToLabelCart = actionCreator<UpdateItemInLabelCartPayload>('ADD_ITEM_QUANTITY_TO_LABEL_CART')
export const subtractItemQuantityFromLabelCart = actionCreator<UpdateItemInLabelCartPayload>('SUBTRACT_ITEM_QUANTITY_FROM_LABEL_CART')

export interface UpdateItemQuantityInLabelCartPayload {
	item: LabelOrderItem
	quantity: number
}
export const updateItemQuantityInLabelCart = actionCreator<UpdateItemQuantityInLabelCartPayload>('UPDATE_ITEM_QUANTITY_IN_LABEL_CART')

export interface RemoveItemFromLabelCartPayload {
	productSku: string
}
export const removeItemFromLabelCart = actionCreator<RemoveItemFromLabelCartPayload>('REMOVE_ITEM_FROM_LABEL_CART')

export const clearLabelCart = actionCreator('CLEAR_LABEL_CART')

export interface FetchLabelGroupsPayload {
	includeItems?: boolean
}
export type FetchLabelGroupsAction = Action<FetchLabelGroupsPayload>
export const fetchLabelGroups = actionCreator.async<FetchLabelGroupsPayload, ProductList[], Error>('FETCH_LABEL_GROUPS')

export interface FetchLabelGroupPayload {
	id: string
	page?: number
	pageSize?: number
	appendToList?: boolean
	addToCart?: boolean
}
export type FetchLabelGroupAction = Action<FetchLabelGroupPayload>
export type FetchLabelGroupSuccessAction = Action<Success<FetchLabelGroupPayload, ProductList>>
export const fetchLabelGroup = actionCreator.async<FetchLabelGroupPayload, ProductList, Error>('FETCH_LABEL_GROUP')

export type DeleteLabelGroupAction = Action<ProductList>
export const deleteLabelGroup = actionCreator.async<ProductList, ProductList[], Error>('DELETE_LABEL_GROUP')

export interface UpdateLabelOrderPayload {
	id: LabelOrderInputs
	value: any
}
export const updateLabelOrder = actionCreator<UpdateLabelOrderPayload>('UPDATE_LABEL_ORDER')

export type CreateLabelOrderAction = Action<CreateLabelOrder>
export type CreateLabelOrderActionSuccess = Action<Success<CreateLabelOrder, LabelOrder>>
export type CreateLabelOrderActionFailure = Action<Failure<CreateLabelOrder, Error>>
export const createLabelOrder = actionCreator.async<CreateLabelOrder, LabelOrder, Error>('CREATE_LABEL_ORDER')

export interface FetchLabelOrdersPayload {
	page?: number
	pageSize?: number
	appendToList?: boolean
	filters?: SearchLabelOrdersFilter[]
}
export type FetchLabelOrdersAction = Action<FetchLabelOrdersPayload>
export const fetchLabelOrders = actionCreator.async<FetchLabelOrdersPayload, PagedUserLabelOrdersResponse, Error>('FETCH_LABEL_ORDERS')

export type FetchLabelOrderAction = Action<string>
export const fetchLabelOrder = actionCreator.async<string, LabelOrder, Error>('FETCH_LABEL_ORDER')

export interface AddProductToLabelGroupPayload extends FetchLabelGroupPayload {
	sku: string
	quantity: number
	reloadProductLabels?: boolean
}
export type AddProductToLabelGroupAction = Action<AddProductToLabelGroupPayload>
export type AddProductToLabelGroupActionSuccessAction = Action<Success<AddProductToLabelGroupPayload, ProductList>>
export const addProductToLabelGroup = actionCreator.async<AddProductToLabelGroupPayload, ProductList, Error>('ADD_PRODUCT_TO_LABEL_GROUP')

export interface RemoveProductFromLabelGroupPayload extends FetchLabelGroupPayload {
	sku: string
	reloadProductLabels?: boolean
}
export type RemoveProductFromLabelGroupAction = Action<RemoveProductFromLabelGroupPayload>
export type RemoveProductFromLabelGroupSuccessAction = Action<Success<RemoveProductFromLabelGroupPayload, ProductList>>
export const removeProductFromLabelGroup = actionCreator.async<RemoveProductFromLabelGroupPayload, ProductList, Error>('REMOVE_PRODUCT_FROM_LABEL_GROUP')

export interface ClearLabelGroupPayload {
	id: string
	products: ProductListItem[]
}
export type ClearLabelGroupAction = Action<ClearLabelGroupPayload>
export const clearLabelGroup = actionCreator.async<ClearLabelGroupPayload, ProductList, Error>('CLEAR_LABEL_GROUP')

export interface UpdateProductsInLabelGroupPayload extends FetchLabelGroupPayload {
	products: ProductListItem[]
	updateActionType?: UpdateListProductsActionType
}
export type UpdateProductsInLabelGroupAction = Action<UpdateProductsInLabelGroupPayload>
export const updateProductsInLabelGroup = actionCreator.async<UpdateProductsInLabelGroupPayload, ProductList, Error>('UPDATE_PRODUCTS_IN_LABEL_GROUP')

export interface UpdateLabelGroupTitleAndProductsPayload extends UpdateProductsInLabelGroupPayload {
	title: string
}
export type UpdateLabelGroupTitleAndProductsAction = Action<UpdateLabelGroupTitleAndProductsPayload>
export const updateLabelGroupTitleAndProducts = actionCreator.async<UpdateLabelGroupTitleAndProductsPayload, ProductList, Error>('UPDATE_TITLE_AND_PRODUCTS_IN_LABEL_GROUP')

// set filters
export interface SetLabelOrdersFilters {
	filters: Filter[]
}
export const setLabelOrdersFiltersAction = actionCreator<SetLabelOrdersFilters>('SET_LABEL_ORDERS_FILTERS')

export type PinLabelGroupAction = Action<string>
export const pinLabelGroup = actionCreator.async<string, Response, Error>('PIN_LABEL_GROUP')
export const unpinLabelGroup = actionCreator.async<string, Response, Error>('UNPIN_LABEL_GROUP')

export interface UpdateLabelGroupTitlePayload {
	id: string
	title: string
}
export type UpdateLabelGroupTitleAction = Action<UpdateLabelGroupTitlePayload>
export const updateLabelGroupTitle = actionCreator.async<UpdateLabelGroupTitlePayload, Response, Error>('UPDATE_LABEL_GROUP_TITLE')

export interface DownloadLabelsPayload {
	id: string
	view?: boolean
}
export type DownloadLabelsAction = Action<DownloadLabelsPayload>
export const downloadLabels = actionCreator.async<DownloadLabelsPayload, Blob, Error>('DOWNLOAD_LABELS')

export interface UploadLabelGroupPayload {
	id: string
	file: File
	// if populated, we are creating a new label group
	// NOTE: the ID should be a locally generated uuid
	title?: string
}
export type UploadLabelGroupAction = Action<UploadLabelGroupPayload>
export type UploadLabelGroupSuccessPayload = Success<UploadLabelGroupPayload, UploadListResponse>
export type UploadLabelGroupSuccessAction = Action<UploadLabelGroupSuccessPayload>
export const uploadLabelGroup = actionCreator.async<UploadLabelGroupPayload, UploadListResponse, Error>('UPLOAD_LABEL_GROUP')
export const clearUploadLabelGroupResult = actionCreator('CLEAR_UPLOADING_LABEL_GROUP_RESULT')

export const updateSelectedLabelGroupId = actionCreator<string>('UPDATE_SELECTED_LABEL_GROUP_ID')