import { RootStoreState } from '../root'

/**
 * Returns the selected account for the currently logged in user.
 * @param state the root store state
 */
export const selectedAccountSelector = (state: RootStoreState) => state.order.selectedAccount

/**
 * Returns the order items in the cart.
 * @param state the root store state
 */
export const orderItemsSelector = (state: RootStoreState) => state.order.orderItems

/**
 * Returns the selected branch for order.
 * @param state the root store state
 */
export const orderBranchSelector = (state: RootStoreState): string | undefined => state.order.branchId