import { Shareholder } from 'typescript-fetch-api'
import actionCreatorFactory, { Action } from 'typescript-fsa'

import { CategorySelectedActionPayload } from '../../common/categories/actions'
import { Category, CategoryType } from '../../common/categories/types'
import { Partners } from '../integrations/types'
import { DrawerType } from './types'

const actionCreator = actionCreatorFactory('Platform')

export const updatePaginationSize = actionCreator<number>('UPDATE_PAGINATION_SIZE')

export const hideBrowserBanner = actionCreator<boolean>('HIDE_BROWSER_BANNER')

export type RegisterNotificationTokenAction = Action<string>
export const registerNotificationToken = actionCreator.async<string, Response, Error>('REGISTER_NOTIFICATION_TOKEN')

export type DeregisterNotificationTokenAction = Action<string>
export const deregisterNotificationToken = actionCreator.async<string, Response, Error>('DEREGISTER_NOTIFICATION_TOKEN')

export const updateLastDisplayedFeatureDialog = actionCreator.async<void, string, Error>('UPDATE_LAST_DISPLAYED_FEATURE_DIALOG')

export type TabOptions = 'home' | 'categories' | 'admin' | 'lists' | 'signin' | 'register' | 'financials' | 'profile' | 'labels' | Partners.XERO | Partners.SIMPRO
export interface NavigateToTabActionPayload {
    tab: TabOptions
    userId?: number // used when displaying the user profile for a trade user
}
export type NavigateToTabAction = Action<NavigateToTabActionPayload>
export const navigateToTab = actionCreator<NavigateToTabActionPayload>('ON_TAB_SELECTED')

export type OrdersTabOptions = 'current-orders' | 'labels' | 'interim-orders'
export interface NavigateToOrdersTabActionPayload {
    tab?: OrdersTabOptions
}
export type NavigateToOrdersTabAction = Action<NavigateToOrdersTabActionPayload>
export const navigateToOrdersTab = actionCreator<NavigateToOrdersTabActionPayload>('NAVIGATE_TO_ORDERS_TAB')

export const navigateToBranchQuotes = actionCreator('NAVIGATE_TO_BRANCH_QUOTES')

export type NavigateToCategoryFromProductBreadcrumbsAction = Action<CategorySelectedActionPayload>
export const navigateToCategoryFromProductBreadcrumbs = actionCreator<CategorySelectedActionPayload>('NAVIGATE_TO_CATEGORY_FROM_PRODUCT_BREADCRUMBS')

export type ToggleDrawerAction = Action<DrawerType | undefined>
export const toggleDrawer = actionCreator<DrawerType | undefined>('TOGGLE_DRAWER')

export interface LoadSubcategoriesPayload {
    categoryId: string
    type: CategoryType // load subCategories or subSubCategories
}
export type LoadSubcategoriesForMenuAction = Action<LoadSubcategoriesPayload>
export const loadSubcategoriesForMenu = actionCreator.async<LoadSubcategoriesPayload, ReadonlyArray<Category>, Error>('LOAD_SUBCATEGORIES_FOR_MENU')

export type FetchShareholdingAccountsAction = Action<void>
export const fetchShareholdingAccounts = actionCreator.async<void, Shareholder[], Error>('FETCH_SHAREHOLDING_ACCOUNTS')

export interface RequestShareholdingAccessPayload {
    loginId: string
    shareholderNumber: number
}
export type RequestShareholdingAccessAction = Action<RequestShareholdingAccessPayload>
export const requestShareholdingAccess = actionCreator.async<RequestShareholdingAccessPayload, void, Error>('REQUEST_SHAREHOLDING_ACCESS')

export const setGoldProductFilterEnabled = actionCreator<boolean>('SET_GOLD_PRODUCT_FILTER_ENABLED')

// location change action is dispatched by us when the url changes, for analytics page view reporting
export interface LocationChangePayload {
    pathname: string
    search: string
}
export type LocationChangeAction = Action<LocationChangePayload>
export const locationChange = actionCreator<LocationChangePayload>('LOCATION_CHANGE')