import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'

export interface StoreState {
	readonly mobileVerificationSuccess?: boolean
	readonly error?: Error
	readonly loadingMessage?: string
	readonly resendCodeSuccess?: boolean
	readonly resendCodeError?: Error
	readonly resendCodeLoadingMessage?: string
}

const INITIAL_STATE: StoreState = {
	mobileVerificationSuccess: undefined,
	error: undefined,
	loadingMessage: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.verifyMobile.started, (state): StoreState => {
		return { ...state, error: undefined, loadingMessage: 'Verifying code…' }
	})
	.case(actions.verifyMobile.done, (state): StoreState => {
		return { ...state, mobileVerificationSuccess: true, loadingMessage: undefined }
	})
	.case(actions.verifyMobile.failed, (state, { error }): StoreState => {
		return { ...state, mobileVerificationSuccess: false, error: error, loadingMessage: undefined }
	})
	.case(actions.verifyEmail.started, (state): StoreState => {
		return { ...state, error: undefined, loadingMessage: 'Verifying email…' }
	})
	.case(actions.verifyEmail.done, (state): StoreState => {
		return { ...state, mobileVerificationSuccess: true, loadingMessage: undefined }
	})
	.case(actions.verifyEmail.failed, (state, { error }): StoreState => {
		return { ...state, mobileVerificationSuccess: false, error: error, loadingMessage: undefined }
	})
	.case(actions.resendVerificationCode.started, (state): StoreState => {
		return { ...state, resendCodeError: undefined, resendCodeLoadingMessage: 'Sending verification code…' }
	})
	.case(actions.resendVerificationCode.done, (state): StoreState => {
		return { ...state, resendCodeSuccess: true, resendCodeLoadingMessage: undefined }
	})
	.case(actions.resendVerificationCode.failed, (state, { error }): StoreState => {
		return { ...state, resendCodeSuccess: false, resendCodeError: error, resendCodeLoadingMessage: undefined }
	})