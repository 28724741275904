import { PCFNZLogo, BCNZLogo, BigBuddyLogo } from '../../utils/imagesProvider'
import { PWSpecs, PWSupport } from './types'

export const PW_SPECS_CROSS_PLATFORM: PWSpecs[] = [
	{ title: 'ORDER PRODUCTS', description: 'Order from over 20,000 products from our trusted suppliers.', icon: 'icon-shopping-cart' },
	{ title: 'SPEC & SAFETY DATA SHEETS', description: 'View and download specification and safety sheets.', icon: 'icon-file_download' },
	{ title: 'CUSTOM LISTS', description: 'Create and manage products with custom lists', icon: 'icon-list' },
]

export const PW_SPECS_WEB: PWSpecs[] = [
	{ title: 'INVOICES & STATEMENTS', description: 'Account owners can view & download account financials.', icon: 'icon-library_books' },
	{ title: 'INTEGRATIONS', description: 'Integrate software to make processes streamline.', icon: 'icon-swap_horizontal_circle' },
	{ title: 'MANAGE USERS', description: 'Account owners can manage their staff user profiles.', icon: 'icon-user-circle' },
]

export const PW_SUPPORT: PWSupport[] = [
	{ title: 'Prostate Cancer Foundation NZ', image: PCFNZLogo, url: 'https://prostate.org.nz/' },
	{ title: 'Bowel Cancer NZ', image: BCNZLogo, url: 'https://bowelcancernz.org.nz/' },
	{ title: 'Big Buddy', image: BigBuddyLogo, url: 'https://www.bigbuddy.org.nz/' },
]

export const ACCOUNT_SIGNUP_URL = 'https://my.1centre.com/websitebutton/a46a32e9-b84f-4317-99e7-0316f547a087/start'
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=nz.co.plumbingworld'
export const APP_STORE_URL = 'https://apps.apple.com/app/id1464613840'

export const RECAPTCHA_SITE_KEY = '6Lf9ydkUAAAAAOGhOziWuljfe8Qcp2u2XE--M87F'