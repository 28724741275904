import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { Invoice, Statement, Branch } from 'typescript-fetch-api'

import * as actions from './actions'
import * as authActions from '../auth/actions'
import * as rootActions from '../root/actions'
import { InvoiceFilter, PaymentSession } from './types'

export interface StoreState {
	readonly invoices?: Array<Invoice>
	readonly invoiceTotalPages?: number
	readonly invoiceBranches: Branch[]
	readonly invoiceFilters: InvoiceFilter[]
	readonly totalInvoiceCount?: number
	readonly errorGetInvoices?: Error
	readonly loadingMessageGetInvoices?: string
	readonly downloadingInvoices: Array<string>
	readonly downloadInvoiceAsZipLoading: boolean | undefined
	readonly downloadInvoiceAsZipSuccess: boolean | undefined
	readonly downloadInvoiceAsZipError: Error | undefined

	readonly emailInvoicesLoading: boolean | undefined
	readonly emailInvoicesSuccess: boolean | undefined
	readonly emailInvoicesError: Error | undefined

	readonly statements?: Array<Statement>
	readonly downloadingStatementDates: Array<string>
	readonly errorDownloadStatement?: Error
	readonly errorGetStatements?: Error
	readonly loadingMessageGetStatements?: string
	readonly downloadStatementAsZipLoading: boolean | undefined
	readonly downloadStatementAsZipSuccess: boolean | undefined
	readonly downloadStatementAsZipError: Error | undefined
	readonly creditLimit?: number | undefined
	readonly creditLimitLoading: boolean
	readonly creditLimitError: Error | undefined
	readonly accountSummary?: Statement
	readonly accountSummaryLoading: boolean
	readonly accountSummaryError?: Error

	readonly emailStatementsLoading: boolean | undefined
	readonly emailStatementsSuccess: boolean | undefined
	readonly emailStatementsError: Error | undefined

	readonly savingComment: boolean
	readonly errorEditingComment?: Error

	readonly requestingCreditLimitChange: boolean
	readonly errorRequestingCreditLimitChange?: Error

	readonly gettingPaymentLink: boolean
	readonly paymentLink?: string
	readonly paymentSession?: PaymentSession
	readonly errorGettingPaymentLink?: Error

	readonly notifyingPaymentCompletion: boolean
	readonly successfullyNotifiedPaymentCompletion: boolean
	readonly errorNotifyingPaymentCompletion?: Error
}

const INITIAL_STATE: StoreState = {
	invoices: undefined,
	invoiceTotalPages: undefined,
	invoiceBranches: [],
	invoiceFilters: [],
	totalInvoiceCount: undefined,
	errorGetInvoices: undefined,
	downloadingInvoices: [],
	downloadInvoiceAsZipLoading: undefined,
	downloadInvoiceAsZipSuccess: undefined,
	downloadInvoiceAsZipError: undefined,
	emailInvoicesLoading: undefined,
	emailInvoicesSuccess: undefined,
	emailInvoicesError: undefined,

	statements: undefined,
	errorGetStatements: undefined,
	downloadingStatementDates: [],
	errorDownloadStatement: undefined,
	downloadStatementAsZipLoading: undefined,
	downloadStatementAsZipSuccess: undefined,
	downloadStatementAsZipError: undefined,
	emailStatementsLoading: undefined,
	emailStatementsSuccess: undefined,
	emailStatementsError: undefined,
	creditLimit: undefined,
	creditLimitLoading: false,
	creditLimitError: undefined,
	accountSummary: undefined,
	accountSummaryLoading: false,
	accountSummaryError: undefined,

	savingComment: false,
	errorEditingComment: undefined,

	requestingCreditLimitChange: false,
	errorRequestingCreditLimitChange: undefined,

	gettingPaymentLink: false,
	paymentLink: undefined,
	paymentSession: undefined,
	errorGettingPaymentLink: undefined,

	notifyingPaymentCompletion: false,
	successfullyNotifiedPaymentCompletion: false,
	errorNotifyingPaymentCompletion: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.getInvoices.done, (state, { params, result }): StoreState => {
		const invoiceBranches = result.branches || []
		const invoices = params.appendToList ? [...state.invoices || [], ...result.invoices || []] : result.invoices
		return {
			...state,
			invoices,
			totalInvoiceCount: result.totalInvoiceCount,
			errorGetInvoices: undefined,
			loadingMessageGetInvoices: undefined,
			invoiceBranches,
			invoiceTotalPages: result.count,
		}
	})
	.case(actions.getInvoices.failed, (state, { error }): StoreState => {
		return {
			...state,
			totalInvoiceCount: undefined,
			errorGetInvoices: error,
			loadingMessageGetInvoices: undefined,
			invoiceTotalPages: undefined,
		}
	})
	.case(actions.getInvoices.started, (state, payload): StoreState => {
		return {
			...state,
			totalInvoiceCount: undefined,
			errorGetInvoices: undefined,
			loadingMessageGetInvoices: 'Loading invoices…',
			invoices: payload.appendToList ? state.invoices : undefined, // clear invoices so loading/error message is shown
			invoiceBranches: payload.appendToList ? state.invoiceBranches : [],
			invoiceTotalPages: payload.appendToList ? state.invoiceTotalPages : undefined,
		}
	})

	.case(actions.getStatements.done, (state, { result }): StoreState => {
		return {
			...state,
			statements: result.statements,
			errorGetStatements: undefined,
			loadingMessageGetStatements: undefined
		}
	})
	.case(actions.getStatements.failed, (state, { error }): StoreState => {
		return {
			...state,
			errorGetStatements: error,
			loadingMessageGetStatements: undefined
		}
	})
	.case(actions.getStatements.started, (state): StoreState => {
		return {
			...state,
			errorGetStatements: undefined,
			loadingMessageGetStatements: 'Loading statements…',
			statements: undefined, // clear statements so loading/error message is shown
		}
	})

	.case(actions.editInvoiceComment.done, (state, payload): StoreState => {
		if (state.invoices) {
			let { invoiceId, invoiceSuffixId, comment } = payload.params
			const invoices = state.invoices.map(invoice => {
				const isInvoiceMatch: boolean = invoice.invoiceNumber === invoiceId && invoice.invoiceSuffixNumber === invoiceSuffixId
				// we found a matching invoice, update the comment
				if (isInvoiceMatch) {
					return { ...invoice, invoiceComment: comment }
				}
				return invoice
			})

			return {
				...state,
				invoices,
				errorEditingComment: undefined,
				savingComment: false,
			}
		}

		return {
			...state,
			errorEditingComment: undefined,
			savingComment: false,
		}
	})
	.case(actions.editInvoiceComment.failed, (state, { error }): StoreState => {
		return {
			...state,
			errorEditingComment: error,
			savingComment: false,
		}
	})
	.case(actions.editInvoiceComment.started, (state): StoreState => {
		return {
			...state,
			errorEditingComment: undefined,
			savingComment: true,
		}
	})
	.case(actions.clearInvoiceCommentError, (state): StoreState => {
		return {
			...state,
			errorEditingComment: undefined,
		}
	})

	// invoice filters
	.case(actions.setInvoiceFilter, (state, payload): StoreState => {
		// remove filter for same type if already exists
		var invoiceFilters = state.invoiceFilters.filter(filter => filter.type !== payload.type)
		// add the new filter
		invoiceFilters.push(payload)
		return {
			...state,
			invoiceFilters,
		}
	})
	.case(actions.clearInvoiceFilter, (state, payload): StoreState => {
		// remove filter for same type
		var invoiceFilters = state.invoiceFilters.filter(filter => filter.type !== payload)
		return {
			...state,
			invoiceFilters,
		}
	})
	.case(actions.clearAllInvoiceFilters, (state): StoreState => {
		return {
			...state,
			invoiceFilters: [],
		}
	})

	.case(actions.downloadStatement.done, (state, payload): StoreState => {
		let downloadingStatements = state.downloadingStatementDates.filter((date) => date !== payload.params.statementDate)
		return {
			...state,
			downloadingStatementDates: downloadingStatements,
		}
	})
	.case(actions.downloadStatement.failed, (state, payload): StoreState => {
		let downloadingStatements = state.downloadingStatementDates.filter((date) => date !== payload.params.statementDate)
		return {
			...state,
			downloadingStatementDates: downloadingStatements,
			errorDownloadStatement: payload.error,
		}
	})
	.case(actions.downloadStatement.started, (state, payload): StoreState => {
		let downloadingStatements: Array<string> = []
		downloadingStatements = downloadingStatements.concat(state.downloadingStatementDates)
		downloadingStatements = downloadingStatements.concat(payload.statementDate)
		return {
			...state,
			downloadingStatementDates: downloadingStatements,
			errorDownloadStatement: undefined,
		}
	})

	.case(actions.downloadInvoice.done, (state, payload): StoreState => {
		let downloadingInvoices = state.downloadingInvoices.filter((date) => date !== (payload.params.invoiceId + '/' + payload.params.invoiceSuffixId))
		return {
			...state,
			downloadingInvoices,
		}
	})
	.case(actions.downloadInvoice.failed, (state, payload): StoreState => {
		let downloadingInvoices = state.downloadingInvoices.filter((date) => date !== (payload.params.invoiceId + '/' + payload.params.invoiceSuffixId))
		return {
			...state,
			downloadingInvoices,
		}
	})
	.case(actions.downloadInvoice.started, (state, payload): StoreState => {
		let downloadingInvoices: Array<string> = []
		downloadingInvoices = downloadingInvoices.concat(state.downloadingInvoices)
		downloadingInvoices = downloadingInvoices.concat(payload.invoiceId + '/' + payload.invoiceSuffixId)
		return {
			...state,
			downloadingInvoices,
		}
	})

	.case(actions.downloadInvoiceAsZip.started, (state): StoreState => {
		return {
			...state,
			downloadInvoiceAsZipLoading: true,
			downloadInvoiceAsZipSuccess: undefined,
			downloadInvoiceAsZipError: undefined,
		}
	})
	.case(actions.downloadInvoiceAsZip.done, (state): StoreState => {
		return {
			...state,
			downloadInvoiceAsZipLoading: undefined,
			downloadInvoiceAsZipSuccess: true,
		}
	})
	.case(actions.downloadInvoiceAsZip.failed, (state, { error }): StoreState => {
		return {
			...state,
			downloadInvoiceAsZipLoading: undefined,
			downloadInvoiceAsZipError: error,
		}
	})

	.case(actions.emailInvoices.started, (state): StoreState => {
		return {
			...state,
			emailInvoicesLoading: true,
			emailInvoicesSuccess: undefined,
			emailInvoicesError: undefined,
		}
	})
	.case(actions.emailInvoices.done, (state): StoreState => {
		return {
			...state,
			emailInvoicesLoading: undefined,
			emailInvoicesSuccess: true,
		}
	})
	.case(actions.emailInvoices.failed, (state, { error }): StoreState => {
		return {
			...state,
			emailInvoicesLoading: undefined,
			emailInvoicesError: error,
		}
	})

	.case(actions.emailStatements.started, (state): StoreState => {
		return {
			...state,
			emailStatementsLoading: true,
			emailStatementsSuccess: undefined,
			emailStatementsError: undefined,
		}
	})
	.case(actions.emailStatements.done, (state): StoreState => {
		return {
			...state,
			emailStatementsLoading: undefined,
			emailStatementsSuccess: true,
		}
	})
	.case(actions.emailStatements.failed, (state, { error }): StoreState => {
		return {
			...state,
			emailStatementsLoading: undefined,
			emailStatementsError: error,
		}
	})

	.case(actions.downloadStatementAsZip.started, (state): StoreState => {
		return {
			...state,
			downloadStatementAsZipLoading: true,
			downloadStatementAsZipSuccess: undefined,
			downloadStatementAsZipError: undefined,
		}
	})
	.case(actions.downloadStatementAsZip.done, (state): StoreState => {
		return {
			...state,
			downloadStatementAsZipLoading: undefined,
			downloadStatementAsZipSuccess: true,
		}
	})
	.case(actions.downloadStatementAsZip.failed, (state, { error }): StoreState => {
		return {
			...state,
			downloadStatementAsZipLoading: undefined,
			downloadStatementAsZipError: error,
		}
	})

	/* The user has been logged out remove invoices from the state. */
	.cases([authActions.loggedOut, authActions.clearAuthToken], (): StoreState => {
		return INITIAL_STATE
	})

	// reset flags to their initial state
	.case(rootActions.readyAction, (state): StoreState => {
		return {
			...state,
			errorGetInvoices: undefined,
			loadingMessageGetInvoices: undefined,
			downloadingInvoices: [],
			downloadInvoiceAsZipLoading: undefined,
			downloadInvoiceAsZipSuccess: undefined,
			downloadInvoiceAsZipError: undefined,

			downloadingStatementDates: [],
			errorGetStatements: undefined,
			loadingMessageGetStatements: undefined,
			downloadStatementAsZipLoading: undefined,
			downloadStatementAsZipSuccess: undefined,
			downloadStatementAsZipError: undefined,

			savingComment: false,
			errorEditingComment: undefined,
		}
	})
	// account summary
	.case(actions.getAccountSummary.started, (state): StoreState => {
		return {
			...state,
			accountSummary: undefined,
			accountSummaryLoading: true,
			accountSummaryError: undefined,
		}
	})
	.case(actions.getAccountSummary.done, (state, payload): StoreState => {
		return {
			...state,
			accountSummary: payload.result,
			accountSummaryLoading: false,
		}
	})
	.case(actions.getAccountSummary.failed, (state, { error }): StoreState => {
		return {
			...state,
			accountSummaryLoading: false,
			accountSummaryError: error,
		}
	})
	// credit limit
	.case(actions.getAccountDetails.started, (state): StoreState => {
		return {
			...state,
			creditLimit: undefined,
			creditLimitLoading: true,
			creditLimitError: undefined,
		}
	})
	.case(actions.getAccountDetails.done, (state, payload): StoreState => {
		return {
			...state,
			creditLimit: payload.result.creditLimit,
			creditLimitLoading: false,
		}
	})
	.case(actions.getAccountDetails.failed, (state, { error }): StoreState => {
		return {
			...state,
			creditLimitLoading: false,
			creditLimitError: error,
		}
	})
	// credit limit change
	.case(actions.requestCreditLimitChange.started, (state): StoreState => {
		return { ...state, requestingCreditLimitChange: true, errorRequestingCreditLimitChange: undefined }
	})
	.case(actions.requestCreditLimitChange.done, (state): StoreState => {
		return { ...state, requestingCreditLimitChange: false }
	})
	.case(actions.requestCreditLimitChange.failed, (state, payload): StoreState => {
		return { ...state, requestingCreditLimitChange: false, errorRequestingCreditLimitChange: payload.error }
	})
	// get payment link
	.case(actions.getPaymentLink.started, (state): StoreState => {
		return {
			...state,
			gettingPaymentLink: true,
			paymentSession: undefined,
			errorGettingPaymentLink: undefined,
		}
	})
	.case(actions.getPaymentLink.done, (state, { params, result }): StoreState => {
		return {
			...state,
			gettingPaymentLink: false,
			paymentLink: result.link,
			paymentSession: {
				sessionId: result.sessionId,
				customerId: params.customerId,
				amount: params.amount,
			},
		}
	})
	.case(actions.getPaymentLink.failed, (state, { error }): StoreState => {
		return {
			...state,
			gettingPaymentLink: false,
			errorGettingPaymentLink: error,
		}
	})
	// notify payment complete
	.case(actions.notifyPaymentComplete.started, (state): StoreState => {
		return {
			...state,
			notifyingPaymentCompletion: true,
			errorNotifyingPaymentCompletion: undefined,
		}
	})
	.case(actions.notifyPaymentComplete.done, (state): StoreState => {
		return {
			...state,
			notifyingPaymentCompletion: false,
		}
	})
	.case(actions.notifyPaymentComplete.failed, (state, { error }): StoreState => {
		return {
			...state,
			notifyingPaymentCompletion: false,
			errorNotifyingPaymentCompletion: error,
		}
	})
