import { SagaIterator } from 'redux-saga'
import { takeEvery, select, call, takeLatest } from 'redux-saga/effects'
import { AccountPermission, CreateOrder, IntegrationData, IntegrationDataTypeEnum, ProductSku } from 'typescript-fetch-api'
import moment from 'moment'
import { Action } from 'redux'
import { v4 as uuidv4 } from 'uuid'

import * as actions from '../../common/order/actions'
import * as cartActions from '../../common/cart/actions'
import { UserAccount } from '../../common/accounts/types'
import { orderItemsSelector, selectedAccountSelector } from '../../common/order/selectors'
import { getContentApi, getOrderApi } from '../../common/api'
import { Order, OrderItem } from '../../common/order/types'
import { callApi } from '../../common/api/functions'
import { createInstructionsForOrder } from '../../common/util/functions'

/**
 * Handles updating the product prices in the cart whenever a new account for the order is selected.
 */
function* updateProductPricesInCart(action: cartActions.AccountSelectedAction): SagaIterator {
	const orderItems: OrderItem[] = yield select(orderItemsSelector)
	if (orderItems.length > 0) {
		const products: ProductSku[] = orderItems.map(item => ({ sku: item.product.sku }))
		const account: UserAccount = action.payload
		yield call(fetchProductPricing, products, account, action)
	}
}

/**
 * Handles fetching pricing for product being added to the cart, if it does not already have that info in the payload
 */
function* handleProductAddedToCart(action: cartActions.AddProductToCartAction): SagaIterator {
	const account: UserAccount | undefined = yield select(selectedAccountSelector)
	if (account) {
		const canViewCost = account.permissions?.some(permission => permission === AccountPermission.VIEW_ACCOUNT_PRICING)
		const shouldLoadPrice = (canViewCost && !action.payload.product.accountPrice) || !action.payload.product.recommendedRetailPrice
		if (shouldLoadPrice) {
			const products: ProductSku[] = [{ sku: action.payload.product.sku }]
			yield call(fetchProductPricing, products, account, action)
		}
	}
}

/**
 * Handles fetching pricing for product being updated in the cart, if it does not already have that info in the payload
 */
function* handleProductUpdatedInCart(action: cartActions.UpdateProductQuantityInCartAction): SagaIterator {
	const account: UserAccount | undefined = yield select(selectedAccountSelector)
	if (account && action.payload.quantity > 0) {
		const canViewCost = account.permissions?.some(permission => permission === AccountPermission.VIEW_ACCOUNT_PRICING)
		const shouldLoadPrice = (canViewCost && !action.payload.product.accountPrice) || !action.payload.product.recommendedRetailPrice
		if (shouldLoadPrice) {
			const products: ProductSku[] = [{ sku: action.payload.product.sku }]
			yield call(fetchProductPricing, products, account, action)
		}
	}
}

// reusable method for fetching pricing for cart item(s)
function* fetchProductPricing(products: ProductSku[], account: UserAccount, action: Action<any>): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		cartActions.updateProductPricesInCart,
		() => getContentApi().getProductPrices({ productPriceRequestList: { products }, customerId: account.customerId })
	)
}

function* handleSubmitOrder(action: actions.SubmitOrderOnlineAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.submitOrderOnline,
		(payload: Order) => {
			const orderId = uuidv4()
			// create integration data
			const integrationData: IntegrationData[] | undefined =
				payload.simproCostCenterId ? [{ type: IntegrationDataTypeEnum.SIMPRO_COST_CENTER_ID, value: payload.simproCostCenterId }]
					: undefined
			// convert to api request format
			const request: CreateOrder = {
				id: orderId,
				customerId: payload.customerId!,
				clientName: payload.recipientName,
				branchId: Number.parseInt(payload.branchId, undefined),
				// customerEmail: realmOrder.email // not recorded yet
				clientAddress: payload.deliveryAddress,
				clientCity: payload.deliveryCity,
				clientSuburb: payload.deliverySuburb,
				orderReference: payload.orderNumber,
				builderContact: payload.onSiteContactNumber,
				// customerPostcode: realmOrder.postalCode // not recorded yet
				// customerState: realmOrder.orderStatus, // this isn't the correct status to use
				shippingInstructions: createInstructionsForOrder(payload.despatchMethod, payload.despatchSlot, payload.despatchClickAndCollectTimeslot),
				notes: payload.notes,
				despatchSlot: payload.despatchSlot?.toString(),
				despatchMethod: payload.despatchMethod,
				dateRequired: moment(payload.despatchDate).format('YYYY-MM-DD'),
				checkoutMethod: payload.checkoutMethod,
				integrationData,
				orderItems: payload.orderItems.map((item) => {
					return {
						productId: item.product.sku,
						quantity: item.quantity,
						comment: item.comment,
						productDescription: item.product.longDescription,
						productImage: item.product.primaryImageTitle,
					}
				}),
				externalReference: payload.externalReference,
			}
			return getOrderApi().createOrder({ createOrder: request })
		}
	)
}

export default function* (): SagaIterator {
	yield takeEvery(cartActions.accountSelected, updateProductPricesInCart)
	yield takeEvery(cartActions.addProductToCart, handleProductAddedToCart)
	yield takeEvery(cartActions.updateProductQuantityInCart, handleProductUpdatedInCart)
	yield takeLatest(actions.submitOrderOnline.started, handleSubmitOrder)
}
