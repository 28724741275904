import * as React from 'react'
import { Button, Divider } from '@mui/material'

import { KaingaOraLogoWithText, KaingaOraLogo, HnzListItem, HnzBackground } from '../../../utils/imagesProvider'

import '../../platform/styles/NewFeaturePage.scss'

interface Props {
	onNext: () => void
}

export default class HnzNewFeaturePageFive extends React.PureComponent<Props> {

	render() {
		return (
			<div className="page">
				<div className="page__heading">
					<img src={KaingaOraLogoWithText} alt="Kāinga Ora Logo" className="page__logo--heading" />
				</div>

				<div className="page__content">
					<h1 className="page__text--heading">TIPS</h1>

					<h2 className="page__text--subheading page__text--subheading-emphasized">
						KĀINGA ORA PRODUCTS GO<br />
						<span className="page__text--subheading-themed page__text--subheading-big">GREEN</span>
					</h2>

					<img src={HnzListItem} alt="Kāinga Ora Item" className="page__image--list-item" />

					<Divider className="page__divider" />

					<div className="page__text-container">
						<p className="page__text">Look out for the Kainga Ora Logo</p>

						<img src={KaingaOraLogo} alt="Kainga Ora" className="page__logo--small" />
					</div>
				</div>

				<div className="page__footer">
					<Button
						variant="outlined"
						className="button--fixed-width"
						onClick={this.props.onNext}
					>
						NEXT
					</Button>
				</div>

				<img src={HnzBackground} alt="Kāinga Ora" className="page__background page__background--hnz" />
			</div>
		)
	}
}