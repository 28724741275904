export enum Environment {
	LOCAL = 'LOCAL',
	DEV = 'DEV',
	TEST = 'TEST',
	DEMO = 'DEMO',
	PRODUCTION = 'PRODUCTION',
}

export interface EnvironmentUrls {
	appServerRootURL: string
	imageThumbServerUrl: string
	imageLargeServerUrl: string
	imageLargeServerUrlFallback: string
	specSheetsServerUrl: string
	brochuresServerUrl: string
	msdsServerUrl: string
	warrantyServerUrl: string
	sdocServerUrl: string
	installServerUrl: string
	metaDataUrl: string
}
