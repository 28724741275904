import { SagaIterator } from 'redux-saga'
import { takeEvery, call } from 'redux-saga/effects'
import { PriceBookExampleFileFormat } from 'typescript-fetch-api'

import { downloadExamplePriceBookFile, DownloadExamplePriceBookFileAction, navigateToPriceBook } from '../../common/priceBook/actions'
import { getPriceBookApi } from '../../common/api'
import { downloadFile } from '../../utils/functions'
import { callApi } from '../../common/api/functions'
import { PriceBookFileFormatFileName } from './content'
import * as NavigationManager from '../navigation/NavigationManager'

function* handleDownloadExamplePriceBookFile(action: DownloadExamplePriceBookFileAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, downloadExamplePriceBookFile, (payload: PriceBookExampleFileFormat) => {
		return getPriceBookApi().downloadExamplePriceBook({ fileFormat: payload })
			.then((response: Blob) => {
				downloadFile(response, PriceBookFileFormatFileName[payload])
			})
	})
}

function handleNavigateToPriceBook() {
	NavigationManager.navigateToPriceBook()
}

export default function* (): SagaIterator {
	yield takeEvery(downloadExamplePriceBookFile.started, handleDownloadExamplePriceBookFile)
	yield takeEvery(navigateToPriceBook, handleNavigateToPriceBook)
}