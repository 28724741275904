import * as React from 'react'

import CarouselPaginationDot from './CarouselPaginationDot'

import '../styles/CarouselPagination.css'

interface Props {
	dots: number
	index: number
	onChangeIndex?: (index: number) => void
}

export default class CarouselPagination extends React.PureComponent<Props> {

	_handleItemClick = (index: number) => {
		this.props.onChangeIndex!(index)
	}

	render() {
		const { index, dots, onChangeIndex } = this.props
		const children: JSX.Element[] = []

		for (let i = 0; i < dots; i += 1) {
			children.push(
				<CarouselPaginationDot
					key={i}
					index={i}
					isActive={i === index}
					onClick={onChangeIndex ? this._handleItemClick : undefined}
				/>,
			)
		}

		return (
			<div className="carousel-pagination">
				{children}
			</div>
		)
	}
}