import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { IntegrationAction, XeroSubscription, XeroConnections, XeroContact, XeroAccount, SimproSubscriptionType, AroFloSubscription, SimproCompany, SimproVendor, SimproOrderNumberFormat, SimproSubscription, IntegrationCompany, SimproCatalogue, ITradeSubscription, AroFloPunchoutUser, FergusSubscription, ScheduledSubscription } from 'typescript-fetch-api'

import * as actions from './actions'
import { readyAction } from '../../common/root/actions'
import { accountSelected } from '../../common/cart/actions'

export interface StoreState {
	readonly integrationError?: Error
	readonly integrationLoadingMessage?: string
	readonly integrationSuccess?: boolean
	readonly updateIntegrationStatusSuccess?: boolean

	// dialog flags
	readonly showFergusNewFeatureDialog: boolean
	readonly showXeroNewFeatureDialog: boolean

	// xero subscription
	readonly xeroSubscription: XeroSubscription | undefined
	readonly isLoadingXeroSubscription: boolean
	readonly xeroSubscriptionError: Error | undefined

	// update xero subscription
	readonly isUpdatingXeroSubscription: boolean
	readonly updateXeroSubscriptionSuccess: boolean
	readonly updateXeroSubscriptionError: Error | undefined

	// xero connections
	readonly xeroConnections: XeroConnections | undefined
	readonly isFetchingXeroConnections: boolean
	readonly xeroConnectionsError: Error | undefined

	// xero accounting data
	readonly xeroContacts: XeroContact[]
	readonly xeroAccounts: XeroAccount[]

	// simpro invoice subscription status
	readonly simproInvoiceStatus: boolean
	readonly isFetchingSimproInvoiceStatus: boolean
	readonly simproInvoiceStatusError: Error | undefined

	// simpro price book subscription status
	readonly simproPricebookStatus: boolean
	readonly isFetchingSimproPricebookStatus: boolean
	readonly simproPricebookStatusError: Error | undefined

	// simpro purchase order subscription status
	readonly simproPurchaseOrderSubscription: SimproSubscription | undefined
	readonly isFetchingSimproPurchaseOrderSubscription: boolean
	readonly simproPurchaseOrderSubscriptionError: Error | undefined

	// aroFlo subscription
	readonly aroFloSubscription: AroFloSubscription | undefined
	readonly isLoadingAroFloSubscription: boolean
	readonly aroFloSubscriptionError: Error | undefined
	readonly aroFloCatalogueUser: AroFloPunchoutUser | undefined
	readonly isLoadingAroFloCatalogueUser: boolean
	readonly aroFloCatalogueUserError: Error | undefined
	readonly isResettingAroFloCatalogueUser: boolean
	readonly resetAroFloCatalogueUserError: Error | undefined
	readonly isDeletingAroFloCatalogueUser: boolean
	readonly deleteAroFloCatalogueUserError: Error | undefined

	// iTrade subscription
	readonly iTradeSubscription: ITradeSubscription | undefined
	readonly isLoadingITradeSubscription: boolean
	readonly iTradeSubscriptionError: Error | undefined

	// Fergus subscription
	readonly fergusSubscription: FergusSubscription | undefined
	readonly isLoadingFergusSubscription: boolean
	readonly fergusSubscriptionError: Error | undefined

	// Scheduled Invoice subscription
	readonly scheduledInvoiceSubscription: ScheduledSubscription | undefined
	readonly isLoadingScheduledInvoiceSubscription: boolean
	readonly scheduledInvoiceSubscriptionError: Error | undefined

	// simpro purchase order
	readonly tempCompanyUrl: string | undefined
	readonly isFetchingSimproAccessToken: boolean
	readonly simproAccessTokenError: Error | undefined
	readonly simproAccessToken: string | undefined
	readonly simproRefreshToken: string | undefined
	readonly isFetchingSimproCompanies: boolean
	readonly simproCompaniesError: Error | undefined
	readonly simproCompanies: SimproCompany[]
	readonly isFetchingSimproVendors: boolean
	readonly simproVendorsError: Error | undefined
	readonly simproVendors: SimproVendor[]
	readonly isFetchingSimproOrderNumberFormats: boolean
	readonly simproOrderNumberFormatsError: Error | undefined
	readonly simproOrderNumberFormats: SimproOrderNumberFormat[]
	readonly isFetchingSimproCatalogues: boolean
	readonly simproCataloguesError: Error | undefined
	readonly simproCatalogues: SimproCatalogue[]

	// update simpro purchase order subscription
	readonly isUpdatingSimproSubscription: boolean
	readonly updateSimproSubscriptionError: Error | undefined
}

const INITIAL_STATE: StoreState = {
	integrationError: undefined,
	integrationLoadingMessage: undefined,
	integrationSuccess: undefined,
	updateIntegrationStatusSuccess: undefined,

	// dialog flags
	showFergusNewFeatureDialog: false,
	showXeroNewFeatureDialog: false,

	// xero subscription
	xeroSubscription: undefined,
	isLoadingXeroSubscription: false,
	xeroSubscriptionError: undefined,

	// update xero subscription
	isUpdatingXeroSubscription: false,
	updateXeroSubscriptionSuccess: false,
	updateXeroSubscriptionError: undefined,

	// xero connections
	xeroConnections: undefined,
	isFetchingXeroConnections: false,
	xeroConnectionsError: undefined,

	// xero accounting data
	xeroContacts: [],
	xeroAccounts: [],

	// simpro invoice subscription status
	simproInvoiceStatus: false,
	isFetchingSimproInvoiceStatus: false,
	simproInvoiceStatusError: undefined,

	// simpro  price book subscription status
	simproPricebookStatus: false,
	isFetchingSimproPricebookStatus: false,
	simproPricebookStatusError: undefined,

	// simpro purchase order subscription
	simproPurchaseOrderSubscription: undefined,
	isFetchingSimproPurchaseOrderSubscription: false,
	simproPurchaseOrderSubscriptionError: undefined,

	// aroFlo subscription
	aroFloSubscription: undefined,
	isLoadingAroFloSubscription: false,
	aroFloSubscriptionError: undefined,
	aroFloCatalogueUser: undefined,
	isLoadingAroFloCatalogueUser: false,
	aroFloCatalogueUserError: undefined,
	isResettingAroFloCatalogueUser: false,
	resetAroFloCatalogueUserError: undefined,
	isDeletingAroFloCatalogueUser: false,
	deleteAroFloCatalogueUserError: undefined,

	// iTrade subscription
	iTradeSubscription: undefined,
	isLoadingITradeSubscription: false,
	iTradeSubscriptionError: undefined,

	// Fergus subscription
	fergusSubscription: undefined,
	isLoadingFergusSubscription: false,
	fergusSubscriptionError: undefined,

	// Scheduled Invoice subscription
	scheduledInvoiceSubscription: undefined,
	isLoadingScheduledInvoiceSubscription: false,
	scheduledInvoiceSubscriptionError: undefined,

	// simpro purchase order
	tempCompanyUrl: undefined,
	isFetchingSimproAccessToken: false,
	simproAccessTokenError: undefined,
	simproAccessToken: undefined,
	simproRefreshToken: undefined,
	isFetchingSimproCompanies: false,
	simproCompaniesError: undefined,
	simproCompanies: [],
	isFetchingSimproVendors: false,
	simproVendorsError: undefined,
	simproVendors: [],
	isFetchingSimproOrderNumberFormats: false,
	simproOrderNumberFormatsError: undefined,
	simproOrderNumberFormats: [],
	isFetchingSimproCatalogues: false,
	simproCataloguesError: undefined,
	simproCatalogues: [],

	// update simpro purchase order subscription
	isUpdatingSimproSubscription: false,
	updateSimproSubscriptionError: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.connectIntegration.started, (state, payload): StoreState => {
		const actionMessage: string = payload.integrationAction === IntegrationAction.CONNECT
			? 'Connecting to' : 'Disconnecting from'
		return {
			...state,
			integrationError: undefined,
			integrationLoadingMessage: `${actionMessage} ${payload.integrationCompany}…`,
			integrationSuccess: undefined,
			tempCompanyUrl: undefined, // clear company url when starting new connection (will be saved on done action)
		}
	})
	.case(actions.connectIntegration.done, (state, payload): StoreState => {
		return {
			...state,
			integrationSuccess: true,
			integrationLoadingMessage: undefined,
			// for simpro, store the company url temporarily to send to server after completing integration authentication (when swapping accessCode for accessToken)
			tempCompanyUrl: payload.params.integrationCompany === IntegrationCompany.SIMPRO ? payload.params.companyUrl : state.tempCompanyUrl,
		}
	})
	.case(actions.connectIntegration.failed, (state, { error }): StoreState => {
		return {
			...state,
			integrationError: error,
			integrationLoadingMessage: undefined
		}
	})
	.case(actions.updateIntegrationStatus.started, (state): StoreState => {
		return {
			...state,
			updateIntegrationStatusSuccess: undefined,
		}
	})
	.case(actions.updateIntegrationStatus.done, (state): StoreState => {
		return {
			...state,
			updateIntegrationStatusSuccess: true,
			// prevents extra calls to update the integration status on mount of the integrations component
			integrationSuccess: undefined,
		}
	})

	// dialog flags
	.case(actions.showFergusNewFeatureDialog, (state, payload): StoreState => {
		return {
			...state,
			showFergusNewFeatureDialog: payload,
		}
	})
	.case(actions.showXeroNewFeatureDialog, (state, payload): StoreState => {
		return {
			...state,
			showXeroNewFeatureDialog: payload,
		}
	})

	// xero subscription
	.case(actions.getXeroSubscription.started, (state): StoreState => {
		return {
			...state,
			xeroSubscription: undefined,
			isLoadingXeroSubscription: true,
			updateXeroSubscriptionSuccess: false, // we clear this flag to prevent discrepancy in ui
		}
	})
	.case(actions.getXeroSubscription.done, (state, { result }): StoreState => {
		return {
			...state,
			xeroSubscription: result,
			isLoadingXeroSubscription: false,
		}
	})
	.case(actions.getXeroSubscription.failed, (state, { error }): StoreState => {
		return {
			...state,
			xeroSubscriptionError: error,
			isLoadingXeroSubscription: false,
		}
	})

	// update xero subscription
	.case(actions.updateXeroSubscription.started, (state): StoreState => {
		return {
			...state,
			isUpdatingXeroSubscription: true,
			updateXeroSubscriptionSuccess: false,
		}
	})
	.case(actions.updateXeroSubscription.done, (state, { result }): StoreState => {
		let updatedState: StoreState = {
			...state,
			xeroSubscription: result,
			isUpdatingXeroSubscription: false,
			updateXeroSubscriptionSuccess: true,
		}
		// user just disconnected, clear all connections-related data so we can show the connect screen
		if (!result.isSubscribed) {
			updatedState = {
				...updatedState,
				xeroConnections: undefined,
				xeroContacts: [],
				xeroAccounts: [],
			}
		}
		return updatedState
	})
	.case(actions.updateXeroSubscription.failed, (state, { error }): StoreState => {
		return {
			...state,
			updateXeroSubscriptionError: error,
			isUpdatingXeroSubscription: false,
		}
	})
	.case(actions.clearUpdateXeroSubscriptionSuccess, (state): StoreState => {
		return {
			...state,
			updateXeroSubscriptionSuccess: false,
		}
	})

	// xero connections
	.case(actions.getXeroConnections.started, (state): StoreState => {
		return {
			...state,
			isFetchingXeroConnections: true,
		}
	})
	.case(actions.getXeroConnections.done, (state, { result }): StoreState => {
		return {
			...state,
			xeroConnections: result,
			isFetchingXeroConnections: false,
		}
	})
	.case(actions.getXeroConnections.failed, (state, { error }): StoreState => {
		return {
			...state,
			xeroConnections: undefined,
			xeroConnectionsError: error,
			isFetchingXeroConnections: false,
		}
	})

	// xero accounting data
	.case(actions.getXeroContacts.started, (state): StoreState => {
		return {
			...state,
			xeroContacts: [],
		}
	})
	.case(actions.getXeroContacts.done, (state, { result }): StoreState => {
		return {
			...state,
			xeroContacts: result,
		}
	})
	.case(actions.getXeroAccounts.started, (state): StoreState => {
		return {
			...state,
			xeroAccounts: [],
		}
	})
	.case(actions.getXeroAccounts.done, (state, { result }): StoreState => {
		return {
			...state,
			xeroAccounts: result,
		}
	})

	// simpro subscription status
	.case(actions.getSimproSubscription.started, (state, { type }): StoreState => {
		return {
			...state,
			isFetchingSimproPricebookStatus: type === SimproSubscriptionType.PRICEBOOK ? true : state.isFetchingSimproPricebookStatus,
			isFetchingSimproInvoiceStatus: type === SimproSubscriptionType.INVOICE ? true : state.isFetchingSimproInvoiceStatus,
			isFetchingSimproPurchaseOrderSubscription: type === SimproSubscriptionType.PURCHASE_ORDER ? true : state.isFetchingSimproPurchaseOrderSubscription,
		}
	})
	.case(actions.getSimproSubscription.done, (state, { result, params: { type } }): StoreState => {
		return {
			...state,
			// pricebook
			simproPricebookStatus: type === SimproSubscriptionType.PRICEBOOK ? result.isSubscribed : state.simproPricebookStatus,
			isFetchingSimproPricebookStatus: type === SimproSubscriptionType.PRICEBOOK ? false : state.isFetchingSimproPricebookStatus,
			// invoice
			simproInvoiceStatus: type === SimproSubscriptionType.INVOICE ? result.isSubscribed : state.simproInvoiceStatus,
			isFetchingSimproInvoiceStatus: type === SimproSubscriptionType.INVOICE ? false : state.isFetchingSimproInvoiceStatus,
			// purchase order
			simproPurchaseOrderSubscription: type === SimproSubscriptionType.PURCHASE_ORDER ? result : state.simproPurchaseOrderSubscription,
			isFetchingSimproPurchaseOrderSubscription: type === SimproSubscriptionType.PURCHASE_ORDER ? false : state.isFetchingSimproPurchaseOrderSubscription,
			tempCompanyUrl: type === SimproSubscriptionType.PURCHASE_ORDER ? result.isSubscribed ? result.companyUrl : state.tempCompanyUrl : state.tempCompanyUrl,
		}
	})
	.case(actions.getSimproSubscription.failed, (state, { error, params }): StoreState => {
		if (params.type === SimproSubscriptionType.PRICEBOOK) {
			return {
				...state,
				simproPricebookStatus: INITIAL_STATE.simproPricebookStatus,
				simproPricebookStatusError: error,
				isFetchingSimproPricebookStatus: false,
			}
		}
		else if (params.type === SimproSubscriptionType.INVOICE) {
			return {
				...state,
				simproInvoiceStatus: INITIAL_STATE.simproInvoiceStatus,
				simproInvoiceStatusError: error,
				isFetchingSimproInvoiceStatus: false,
			}
		}
		else if (params.type === SimproSubscriptionType.PURCHASE_ORDER) {
			return {
				...state,
				simproPurchaseOrderSubscription: INITIAL_STATE.simproPurchaseOrderSubscription,
				simproPurchaseOrderSubscriptionError: error,
				isFetchingSimproPurchaseOrderSubscription: false,
			}
		}
		return state
	})

	// aroFlo subscription
	.case(actions.getAroFloSubscription.started, (state): StoreState => {
		return {
			...state,
			aroFloSubscription: undefined,
			isLoadingAroFloSubscription: true,
			aroFloSubscriptionError: undefined,
		}
	})
	.case(actions.updateAroFloSubscription.started, (state): StoreState => {
		return {
			...state,
			isLoadingAroFloSubscription: true,
			aroFloSubscriptionError: undefined,
		}
	})
	.cases([actions.getAroFloSubscription.done, actions.updateAroFloSubscription.done], (state, { result }): StoreState => {
		return {
			...state,
			aroFloSubscription: result,
			isLoadingAroFloSubscription: false,
		}
	})
	.cases([actions.getAroFloSubscription.failed, actions.updateAroFloSubscription.failed], (state, { error }): StoreState => {
		return {
			...state,
			aroFloSubscriptionError: error,
			isLoadingAroFloSubscription: false,
		}
	})
	.case(actions.getAroFloCatalogueUser.started, (state): StoreState => {
		return {
			...state,
			aroFloCatalogueUser: undefined,
			isLoadingAroFloCatalogueUser: true,
			aroFloCatalogueUserError: undefined,
		}
	})
	.case(actions.createAroFloCatalogueUser.started, (state): StoreState => {
		return {
			...state,
			isLoadingAroFloCatalogueUser: true,
			aroFloCatalogueUserError: undefined,
		}
	})
	.cases([actions.getAroFloCatalogueUser.done, actions.createAroFloCatalogueUser.done], (state, { result }): StoreState => {
		return {
			...state,
			aroFloCatalogueUser: result,
			isLoadingAroFloCatalogueUser: false,
		}
	})
	.cases([actions.getAroFloCatalogueUser.failed, actions.createAroFloCatalogueUser.failed], (state, { error }): StoreState => {
		return {
			...state,
			aroFloCatalogueUserError: error,
			isLoadingAroFloCatalogueUser: false,
		}
	})
	.case(actions.resetAroFloCatalogueUser.started, (state): StoreState => {
		return {
			...state,
			isResettingAroFloCatalogueUser: true,
			resetAroFloCatalogueUserError: undefined,
		}
	})
	.case(actions.resetAroFloCatalogueUser.done, (state, { result }): StoreState => {
		return {
			...state,
			aroFloCatalogueUser: result,
			isResettingAroFloCatalogueUser: false,
		}
	})
	.case(actions.resetAroFloCatalogueUser.failed, (state, { error }): StoreState => {
		return {
			...state,
			resetAroFloCatalogueUserError: error,
			isResettingAroFloCatalogueUser: false,
		}
	})
	.case(actions.deleteAroFloCatalogueUser.started, (state): StoreState => {
		return {
			...state,
			isDeletingAroFloCatalogueUser: true,
			deleteAroFloCatalogueUserError: undefined,
		}
	})
	.case(actions.deleteAroFloCatalogueUser.done, (state): StoreState => {
		return {
			...state,
			aroFloCatalogueUser: undefined,
			isDeletingAroFloCatalogueUser: false,
		}
	})
	.case(actions.deleteAroFloCatalogueUser.failed, (state, { error }): StoreState => {
		return {
			...state,
			deleteAroFloCatalogueUserError: error,
			isDeletingAroFloCatalogueUser: false,
		}
	})

	// iTrade subscription
	.case(actions.getITradeSubscription.started, (state): StoreState => {
		return {
			...state,
			iTradeSubscription: undefined,
			isLoadingITradeSubscription: true,
			iTradeSubscriptionError: undefined,
		}
	})
	.case(actions.updateITradeSubscription.started, (state): StoreState => {
		return {
			...state,
			isLoadingITradeSubscription: true,
			iTradeSubscriptionError: undefined,
		}
	})
	.cases([actions.getITradeSubscription.done, actions.updateITradeSubscription.done], (state, { result }): StoreState => {
		return {
			...state,
			iTradeSubscription: result,
			isLoadingITradeSubscription: false,
		}
	})
	.cases([actions.getITradeSubscription.failed, actions.updateITradeSubscription.failed], (state, { error }): StoreState => {
		return {
			...state,
			iTradeSubscriptionError: error,
			isLoadingITradeSubscription: false,
		}
	})

	// fergus subscription
	.case(actions.getFergusSubscription.started, (state): StoreState => {
		return {
			...state,
			fergusSubscription: undefined,
			isLoadingFergusSubscription: true,
			fergusSubscriptionError: undefined,
		}
	})
	.case(actions.getFergusSubscription.done, (state, { result }): StoreState => {
		return {
			...state,
			fergusSubscription: result,
			isLoadingFergusSubscription: false,
		}
	})
	.case(actions.getFergusSubscription.failed, (state, { error }): StoreState => {
		return {
			...state,
			fergusSubscriptionError: error,
			isLoadingFergusSubscription: false,
		}
	})

	// Scheduled Invoice subscription
	.case(actions.getScheduledInvoiceSubscription.started, (state): StoreState => {
		return {
			...state,
			scheduledInvoiceSubscription: undefined,
			isLoadingScheduledInvoiceSubscription: true,
			scheduledInvoiceSubscriptionError: undefined,
		}
	})
	.case(actions.updateScheduledInvoiceSubscription.started, (state): StoreState => {
		return {
			...state,
			isLoadingScheduledInvoiceSubscription: true,
			scheduledInvoiceSubscriptionError: undefined,
		}
	})
	.cases([actions.getScheduledInvoiceSubscription.done, actions.updateScheduledInvoiceSubscription.done], (state, { result }): StoreState => {
		return {
			...state,
			scheduledInvoiceSubscription: result,
			isLoadingScheduledInvoiceSubscription: false,
		}
	})
	.cases([actions.getScheduledInvoiceSubscription.failed, actions.updateScheduledInvoiceSubscription.failed], (state, { error }): StoreState => {
		return {
			...state,
			scheduledInvoiceSubscriptionError: error,
			isLoadingScheduledInvoiceSubscription: false,
		}
	})

	// simpro access token
	.case(actions.getSimproAccessToken.started, (state): StoreState => {
		return {
			...state,
			isFetchingSimproAccessToken: true,
			simproAccessToken: undefined,
			simproRefreshToken: undefined,
			simproCompanies: [],
			simproAccessTokenError: undefined,
		}
	})
	.case(actions.getSimproAccessToken.done, (state, { result }): StoreState => {
		return {
			...state,
			simproAccessToken: result.accessToken,
			simproRefreshToken: result.refreshToken,
			simproCompanies: result.companies,
			isFetchingSimproAccessToken: false,
		}
	})
	.case(actions.getSimproAccessToken.failed, (state, { error }): StoreState => {
		return {
			...state,
			simproAccessTokenError: error,
			isFetchingSimproAccessToken: false,
		}
	})
	// simpro companies
	.case(actions.getSimproCompanies.started, (state): StoreState => {
		return {
			...state,
			isFetchingSimproCompanies: true,
			simproCompanies: [],
		}
	})
	.case(actions.getSimproCompanies.done, (state, { result }): StoreState => {
		return {
			...state,
			simproCompanies: result,
			isFetchingSimproCompanies: false,
		}
	})
	.case(actions.getSimproCompanies.failed, (state, { error }): StoreState => {
		return {
			...state,
			simproCompaniesError: error,
			isFetchingSimproCompanies: false,
		}
	})
	// simpro vendors
	.case(actions.getSimproVendors.started, (state): StoreState => {
		return {
			...state,
			isFetchingSimproVendors: true,
			simproVendors: [],
		}
	})
	.case(actions.getSimproVendors.done, (state, { result }): StoreState => {
		return {
			...state,
			simproVendors: result,
			isFetchingSimproVendors: false,
		}
	})
	.case(actions.getSimproVendors.failed, (state, { error }): StoreState => {
		return {
			...state,
			simproVendorsError: error,
			isFetchingSimproVendors: false,
		}
	})
	.case(actions.onResetSimproVendors, (state): StoreState => {
		return {
			...state,
			isFetchingSimproVendors: false,
			simproVendors: [],
			simproVendorsError: undefined,
		}
	})
	// simpro order number formats
	.case(actions.getSimproOrderNumberFormats.started, (state): StoreState => {
		return {
			...state,
			isFetchingSimproOrderNumberFormats: true,
			simproOrderNumberFormats: [],
		}
	})
	.case(actions.getSimproOrderNumberFormats.done, (state, { result }): StoreState => {
		return {
			...state,
			simproOrderNumberFormats: result,
			isFetchingSimproOrderNumberFormats: false,
		}
	})
	.case(actions.getSimproOrderNumberFormats.failed, (state, { error }): StoreState => {
		return {
			...state,
			simproOrderNumberFormatsError: error,
			isFetchingSimproOrderNumberFormats: false,
		}
	})
	// simpro catalogues
	.case(actions.getSimproCatalogues.started, (state): StoreState => {
		return {
			...state,
			isFetchingSimproCatalogues: true,
			simproCatalogues: [],
			simproCataloguesError: undefined,
		}
	})
	.case(actions.getSimproCatalogues.done, (state, { result }): StoreState => {
		return {
			...state,
			simproCatalogues: result,
			isFetchingSimproCatalogues: false,
		}
	})
	.case(actions.getSimproCatalogues.failed, (state, { error }): StoreState => {
		return {
			...state,
			simproCataloguesError: error,
			isFetchingSimproCatalogues: false,
		}
	})
	.case(actions.onResetSimproCatalogues, (state): StoreState => {
		return {
			...state,
			isFetchingSimproCatalogues: false,
			simproCatalogues: [],
			simproCataloguesError: undefined,
		}
	})
	// update simpro subscription
	.case(actions.updateSimproSubscription.started, (state): StoreState => {
		return {
			...state,
			isUpdatingSimproSubscription: true,
			updateSimproSubscriptionError: undefined,
		}
	})
	.case(actions.updateSimproSubscription.done, (state, { result }): StoreState => {
		return {
			...state,
			isUpdatingSimproSubscription: false,
			simproPurchaseOrderSubscription: result,
			tempCompanyUrl: undefined, // clear temp url after creating subscription
		}
	})
	.case(actions.updateSimproSubscription.failed, (state, { error }): StoreState => {
		return {
			...state,
			isUpdatingSimproSubscription: false,
			updateSimproSubscriptionError: error,
		}
	})

	// a new account has been selected, clear all integrations state
	.case(accountSelected, (state): StoreState => {
		return INITIAL_STATE
	})

	// clearing of values
	.case(readyAction, (state): StoreState => {
		return {
			...INITIAL_STATE,
			// prevent resetting this flag since we use this to know when to update the integration status on mount of the integrations component
			integrationSuccess: state.integrationSuccess,
			// keep temp url as its used for simpro authentication (after performing auth and being redirected back to our app)
			tempCompanyUrl: state.tempCompanyUrl,
		}
	})