import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'

import { forgotPassword, ForgotPasswordSuccessAction } from '../../common/forgotpassword/actions'
import { isText } from '../../common/auth/functions'
import { ChangePasswordNavigationState } from './types'
import * as NavigationManager from '../navigation/NavigationManager'

function handleNavigateToVerifyCode(action: ForgotPasswordSuccessAction) {
	const isMobileNumber = action.payload.params.user && !isText(action.payload.params.user)

	// we only navigate to verify code for PHONE as with email the user will be sent a url link to follow.
	// paths/routes to redirect to would either be the MobileVerification or Change Password screens.
	if (isMobileNumber) {
		const state: ChangePasswordNavigationState = { mobileNumber: action.payload.params.user }
		NavigationManager.navigateToConfirmResetPassword(state)
	}
}

export default function* (): SagaIterator {
	yield takeEvery(forgotPassword.done, handleNavigateToVerifyCode)
}