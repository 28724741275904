import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'
import { RootStoreState } from '../../root'
import { OrderItem } from '../../order/types'
import { removeProductFromCart, updateProductQuantityInCart, navigateToCheckout, clearCart, navigateToReviewCart, updateProductAvailabilityInCart, navigateToArofloCheckout, updateProductCommentInCart } from '../actions'
import { navigateToProduct } from '../../product/actions'
import { navigateToNewList, clearErrorEditingList, UpdateProductsInListPayload, updateProductsInList } from '../../mylists/actions'
import { ProductList, ProductListItem } from 'typescript-fetch-api'
import { ProductInfo } from '../../../modules/platform'
import { UserAccount } from '../../accounts/types'
import { getPlatformSupportImplementation } from '../../platform'
import { getIsAroFloProcurementUser } from '../../auth/functions'
import { logoutRequest } from '../../auth/actions'
import { isEnabledRemoteConfig } from '../../remoteConfig/functions'
import { RemoteConfig } from '../../remoteConfig/types'

export interface Props {
	orderItems: OrderItem[]
	lists: ProductList[]
	selectedAccount?: UserAccount
	editingList: boolean
	errorEditingList?: Error
	costEnabled: boolean
	retailEnabled: boolean
	isLoggedIn: boolean
	isAroFloProcurementUser: boolean
	aroFloHookUrl?: string
	isProductCommentsEnabled: boolean
}

export interface Actions {
	onRemoveProductFromCart: (item: OrderItem) => void
	onUpdateProductQuantityInCart: (product: ProductInfo, quantity: number) => void
	onUpdateProductCommentInCart: (product: ProductInfo, quantity: number, comment: string) => void
	navigateToCheckout: () => void
	navigateToReviewCart: () => void
	onProductSelected: (product: ProductInfo) => void
	onClearCart: (orderItems?: OrderItem[]) => void
	navigateToNewList: (productListItems: ProductListItem[]) => void
	addProductsToList: (payload: UpdateProductsInListPayload) => void
	clearErrorEditingList: () => void
	updateProductAvailabilityInCart: () => void
	navigateToArofloCheckout: () => void
	signout: () => void
}

const mapStateToProps = (state: RootStoreState): Props => {
	// can only edit user lists so filtering that sub group out
	const userLists = state.lists.lists.find(item => item.prostix_account_id === null)
	const { costEnabled, retailEnabled } = getPlatformSupportImplementation().getPricingPreferences(state)
	return {
		orderItems: state.order.orderItems,
		lists: userLists && userLists.lists ? userLists.lists : [],
		selectedAccount: state.order.selectedAccount,
		editingList: state.lists.editingFromScreen === 'Cart' && state.lists.editingList,
		errorEditingList: state.lists.editingFromScreen === 'Cart' ? state.lists.errorEditingList : undefined,
		costEnabled,
		retailEnabled,
		isLoggedIn: !!state.auth.authToken,
		isAroFloProcurementUser: getIsAroFloProcurementUser(state.auth.username),
		aroFloHookUrl: state.auth.aroFloHookUrl,
		isProductCommentsEnabled: isEnabledRemoteConfig(RemoteConfig.PRODUCT_COMMENTS, state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onRemoveProductFromCart: (item: OrderItem) => {
		dispatch(removeProductFromCart(item))
	},
	onUpdateProductQuantityInCart: (product: ProductInfo, quantity: number) => {
		dispatch(updateProductQuantityInCart({ product, quantity }))
	},
	onUpdateProductCommentInCart: (product: ProductInfo, quantity: number, comment: string) => {
		dispatch(updateProductCommentInCart({ product, quantity, comment }))
	},
	navigateToCheckout: () => {
		dispatch(navigateToCheckout())
	},
	navigateToReviewCart: () => {
		dispatch(navigateToReviewCart())
	},
	onProductSelected: (product: ProductInfo) => {
		// we will not have a RealmProduct here so just pass through basic info and the Product will be loaded from realm in the Product Details component
		dispatch(navigateToProduct({ productSku: product.sku, productDescription: product.longDescription }))
	},
	onClearCart: (orderItems?: OrderItem[]) => {
		dispatch(clearCart(orderItems))
	},
	navigateToNewList: (productListItems: ProductListItem[]) => {
		dispatch(navigateToNewList({ items: productListItems }))
	},
	addProductsToList: (payload: UpdateProductsInListPayload) => {
		dispatch(updateProductsInList.started(payload))
	},
	clearErrorEditingList: () => {
		dispatch(clearErrorEditingList())
	},
	updateProductAvailabilityInCart: () => {
		dispatch(updateProductAvailabilityInCart.started())
	},
	navigateToArofloCheckout: () => {
		dispatch(navigateToArofloCheckout())
	},
	signout: () => {
		dispatch(logoutRequest({}))
	},
})

// @ts-ignore - generic type not being recognised after wrapping inside connect
export default <T>(DefaultComponent: React.ComponentType<T & Props & Actions>) => connect(mapStateToProps, mapDispatchToProps)(DefaultComponent)
