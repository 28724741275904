import { ReactNode } from 'react'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

import Component from '../../../modules/offline/components/ReadyGate'
import { RootStoreState } from '../../root'
import { PlatformReadyState, getIsPlatformReady } from '../../../modules/offline/types'

export interface Props {
	ready: boolean
	waitComponent?: React.ComponentType
	children?: ReactNode
}

export interface OwnProps {
	/** The component to render while we wait for the app to be ready. */
	waitComponent?: React.ComponentType
	children?: ReactNode
}

export interface Actions {

}

/** Populate the Props from the store state. */
const mapStateToProps = (store: RootStoreState & PlatformReadyState, { waitComponent, children }: OwnProps): Props => {
	const ready = store.ready
	const platformReady = getIsPlatformReady(store)
	return {
		ready: ready && platformReady,
		waitComponent,
		children,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
