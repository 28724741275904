import '../styles/EditLoginIdDialog.scss'

import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider, Button, Box, CircularProgress } from '@mui/material'

import { EditLoginIdDialogType } from '../types'

interface Props {
	type?: EditLoginIdDialogType
	isLoading?: boolean
	onClose(): void
	onEdit(): void
}

const EditLoginIdDialog = ({ type, isLoading, onClose, onEdit }: Props): JSX.Element => {

	let title: string
	let description: string
	if (type === EditLoginIdDialogType.NOTIFY_ME) {
		title = 'Updating Notify Me Settings'
		description = 'The Notify Me feature uses your registered email Login ID. Changing your Notify Me email will update your Login ID which will result in a forced sign out. Simply sign back in with your updated Login ID.'
	} else {
		title = 'You will be signed out…'
		description = 'Changing your Login ID will result in a forced sign out. Simply sign back in with your updated Login ID.'
	}

	return (
		<Dialog
			open={true}
			onClose={!isLoading ? onClose : undefined}
			maxWidth="xs"
			fullWidth={true}
			scroll="paper"
			className="edit-login-id-dialog"
		>
			<DialogTitle>{title}</DialogTitle>

			<DialogContent>
				<Divider />

				<p className="edit-login-id-dialog__text">{description}</p>
				<Box m={1} />
				<p className="edit-login-id-dialog__text">Do you want to continue?</p>

				{isLoading && (
					<React.Fragment>
						<Box m={1} />
						<CircularProgress />
					</React.Fragment>
				)}
			</DialogContent>

			{!isLoading && (
				<DialogActions disableSpacing={true}>
					<Button
						variant="contained"
						color="primary"
						fullWidth={true}
						onClick={onEdit}
					>
						Yes
					</Button>

					<Box m={1} />

					<Button
						variant="outlined"
						fullWidth={true}
						onClick={onClose}
					>
						Cancel
					</Button>
				</DialogActions>
			)}
		</Dialog>
	)
}

export default EditLoginIdDialog
