import '../styles/CommentIcon.scss'

import { Paper, Tooltip } from '@mui/material'

type Props = {
	hasComment: boolean
	className?: string
	elevation?: number
	small?: boolean
	onClick?: () => void
}

const CommentIcon = (props: Props): JSX.Element | null => {

	const _handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation() // stop event propogation to parent
		if (props.onClick) props.onClick()
	}

	return (
		<Paper
			className={`comment-icon ${props.small && 'comment-icon__small'} ${props.className}`}
			elevation={props.elevation || 0}
		>
			<Tooltip title={props.hasComment ? 'Update comment' : 'Add a comment'} onClick={_handleOnClick}>
				<span className={`${props.hasComment ? 'icon-message' : 'icon-add_comment'} ${props.small && 'comment-icon__icon-small'}`} />
			</Tooltip>
		</Paper>
	)
}

export default CommentIcon
