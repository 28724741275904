import { CarouselItem, CarouselPlatform, CarouselResponse, HomePageItemsResponse, HomePagePlatform } from 'typescript-fetch-api'
import actionCreatorFactory, { Action } from 'typescript-fsa'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Home')

export type NavigateToCarouselViewerAction = Action<CarouselItem>
export const navigateToCarouselViewer = actionCreator<CarouselItem>('NAVIGATE_TO_CAROUSEL_VIEWER')

export interface SendTokenRequestPayload {
	token: string
}
export type SendTokenRequestAction = Action<SendTokenRequestPayload>
export const sendRefreshTokenAction = actionCreator<SendTokenRequestPayload>('SEND_TOKEN')

export type FetchCarouselAction = Action<CarouselPlatform>
export const fetchCarousel = actionCreator.async<CarouselPlatform, CarouselResponse, Error>('FETCH_CAROUSEL')

export type FetchHomePageItemsAction = Action<HomePagePlatform>
export const fetchHomePageItems = actionCreator.async<HomePagePlatform, HomePageItemsResponse, Error>('FETCH_HOME_PAGE_ITEMS')

export const navigateToMyAccount = actionCreator('NAVIGATE_TO_MY_ACCOUNT')