import * as React from 'react'
import { Button, Divider } from '@mui/material'

import { KaingaOraLogoWithText, HnzBackground } from '../../../utils/imagesProvider'

import '../../platform/styles/NewFeaturePage.scss'

interface Props {
	onNext: () => void
}

export default class HnzNewFeaturePageTwo extends React.PureComponent<Props> {

	render() {
		return (
			<div className="page">
				<div className="page__heading">
					<img src={KaingaOraLogoWithText} alt="Kāinga Ora Logo" className="page__logo--heading" />
				</div>

				<div className="page__content">
					<h1 className="page__text--heading">WHATS NEW?</h1>

					<p className="page__text">Discover what’s new for Kāinga Ora tradies</p>

					<div className="page__boxed-content">
						<p className="page__text--subheading">
							<span className="page__text--subheading-themed page__text--subheading-emphasized">KĀINGA ORA</span><br />
							ACCOUNTS
						</p>

						<Divider className="page__divider" />

						<p className="page__text--subheading">
							<span className="page__text--subheading-themed page__text--subheading-emphasized">KĀINGA ORA</span><br />
							PRODUCTS
						</p>
					</div>

					<Divider className="page__divider" />

					<p className="page__text">Take your time to learn about these new features...</p>
				</div>

				<div className="page__footer">
					<Button
						variant="outlined"
						className="button--fixed-width"
						onClick={this.props.onNext}
					>
						NEXT
					</Button>
				</div>

				<img src={HnzBackground} alt="Kāinga Ora" className="page__background page__background--hnz" />
			</div>
		)
	}
}