import { OwnPropInfo } from '../../common/product/containers/Product'
import { PlatformProduct, ProductInfo } from '../platform'
import { ViewPDFPayload } from '../../common/product/actions'
import { SpecSheet } from '../../common/product/types'
import { UserAccount } from '../../common/accounts/types'
import { OrderItem } from '../../common/order/types'

export type ProductNavigationParams = {
	id: string
}

export interface ProductNavigationProps extends OwnPropInfo { }

export function extractProductNavProps(props: ProductNavigationProps): OwnPropInfo {
	return props
}

export function convertProductToViewPDFPayload(product: PlatformProduct, pdfId: number, type: SpecSheet | undefined): ViewPDFPayload {
	return { productId: product.sku, productTitle: product.shortdescription, imageId: product.primaryimage!, pdfId, type }
}

export enum ProductDialogTypeEnum {
	'ADD_TO', 'LIST', 'LABELS', 'REMOVE_FROM_LIST', 'REMOVE_FROM_LABEL_GROUP', 'REMOVE_FROM_PRICEBOOK'
}

export interface ListDialogTabPanelProps {
	// provide either single product or list of items (from cart)
	product?: ProductInfo
	items?: OrderItem[]
	selectedAccount?: UserAccount
	isLoading: boolean
	error?: Error
	quantity: number
	setQuantity: (quantity: number) => void
	onClose: () => void
}

export enum ListDialogTabPanelEnum {
	ADD_TO_LIST = '0',
	CREATE_NEW_LIST = '1',
}

export enum LabelsDialogTabPanelEnum {
	ADD_TO_LABEL_GROUP = 'ADD_TO_LABEL_GROUP',
	CREATE_NEW_LABEL_GROUP = 'CREATE_NEW_LABEL_GROUP',
}