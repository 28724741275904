import * as React from 'react'
import { Popper, Fade, Paper, ClickAwayListener, Divider } from '@mui/material'

interface Props {
	anchorElement: HTMLElement | null
	titleText: string
	actionText: string
	onActionClick: () => void
	onClickAway: () => void
}

export default class HeaderButtonPopper extends React.PureComponent<Props> {

	render() {
		const { anchorElement, titleText, actionText, onActionClick, onClickAway } = this.props

		return (
			<Popper
				open={true}
				anchorEl={anchorElement}
				transition={true}
				modifiers={[{
					name: 'preventOverflow',
					enabled: true,
					options: {
						rootBoundary: 'scrollParent',
					}
				}]}
				style={{ zIndex: 10 }}
			>
				{({ TransitionProps }) => (
					<ClickAwayListener
						onClickAway={onClickAway}
						// these listeners help close the popper when user clicks somewhere else on the page
						mouseEvent={'onClick'}
						touchEvent={'onTouchEnd'}
					>
						<Fade {...TransitionProps}>
							<Paper
								className="header-button-popper"
								square={true}
							>
								<span className="header-button-popper__text header-button-popper__text--title">{titleText}</span>
								<Divider className="header-button-popper__divider" />
								<span className="header-button-popper__text" onClick={onActionClick}>{actionText} <span className="header-nav-top--arrow">▶</span></span>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		)
	}
}