export enum LabelOrderInputs {
	BRANCH_ID = 'branchId',
	SHIPPING_ADDRESS = 'shippingAddress',
	SHIPPING_CITY = 'shippingCity',
	SHIPPING_SUBURB = 'shippingSuburb',
	DESPATCH_METHOD = 'despatchMethod',
	DESPATCH_SLOT = 'despatchSlot',
	DESPATCH_CLICK_AND_COLLECT_TIMESLOT = 'despatchClickAndCollectTimeslot',
	INSTRUCTIONS = 'instructions',
	DATE_REQUIRED = 'dateRequired',
	RECIPIENT_NAME = 'recipientName',
	ON_SITE_CONTACT_NUMBER = 'onSiteContactNumber'
}
