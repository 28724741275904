import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { VersionUpdate } from 'typescript-fetch-api'

import { WebStoreState, NewFeatureLaunch, CustomAppVersions } from '../types'
import HnzNewFeatureDialog from '../../hnz/components/HnzNewFeatureDialog'
import FergusNewFeatureDialog from '../../integrations/components/FergusNewFeatureDialog'
import XeroNewFeatureDialog from '../../integrations/components/XeroNewFeatureDialog'
import { UserAccount } from '../../../common/accounts/types'
import { NEW_FEATURES_LAUNCHED } from '../content'
import { updateLastDisplayedFeatureDialog } from '../actions'
import VersionUpdatesDialog from '../../versionUpdates/components/VersionUpdatesDialog'
import { isAdminOrPrincipal } from '../../../common/mylists/functions'
import { DATE_DATA_FORMAT } from '../../../utils/functions'

// specific account that shouldn't be shown the new feature dialogs
const PW_TEST_ACCOUNT_CUSTOMER_ID = 8888

const NewFeatureDialogs = (): JSX.Element | null => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */
	const lastDisplayedDate = useSelector<WebStoreState, string | undefined>(state => state.platform.lastWebDisplayedFeatureDialogDate)
	const selectedAccount = useSelector<WebStoreState, UserAccount | undefined>(state => state.order.selectedAccount)
	const versionUpdates = useSelector<WebStoreState, VersionUpdate[]>(state => state.versionUpdates.versionUpdates)

	/**
	 * Local State
	 */

	const [unseenFeature, setUnseenFeature] = useState<string | undefined>(undefined)

	/**
	 * Effects/Subscriptions
	 */

	useEffect(() => {
		let unseenFeatureList: NewFeatureLaunch[] = []
		if (selectedAccount && selectedAccount.customerId !== PW_TEST_ACCOUNT_CUSTOMER_ID) {
			// combine all features (both local and from the api)
			const launchedFeatures = NEW_FEATURES_LAUNCHED.concat(versionUpdates)
			// show the dialog if the user hasn't seen it - based on the last saved date from the api
			// NOTE: we are also checking against the feature availability depending on the account selected, ie if admin or hnz specific dialogs
			unseenFeatureList = launchedFeatures
				.filter(item => {
					if (item.isAdminOnly && !isAdminOrPrincipal(selectedAccount)) {
						return false
					} else if (item.isHnzOnly && !selectedAccount.hnz) {
						return false
					} else {
						// check if we have a previously saved date in the api
						return lastDisplayedDate ? moment(item.date).isAfter(lastDisplayedDate) : true
					}
				})
		}

		if (unseenFeatureList.length > 0) {
			// map all unseen features as moment/date
			const unseenFeatureMoments = unseenFeatureList.map<moment.Moment>(item => moment(item.date, DATE_DATA_FORMAT))
			// find the latest date from the list of unseen features, so we only show the newest feature to the user
			const latestUnseenFeatureDate = moment.max(unseenFeatureMoments)
			// get latest unseen feature based on max date
			const latestUnseenFeature = unseenFeatureList.find(item => latestUnseenFeatureDate.isSame(item.date))
			if (latestUnseenFeature) {
				setUnseenFeature(latestUnseenFeature.version)
			}
		}
	}, [selectedAccount, lastDisplayedDate, versionUpdates])

	/**
	 * Local Functions
	 */

	// prevent showing the dialogs - cases where it matches `PW_TEST_ACCOUNT_CUSTOMER_ID`
	if (!selectedAccount || selectedAccount.customerId === PW_TEST_ACCOUNT_CUSTOMER_ID) {
		return null
	}

	const _updateUnseenFeature = () => {
		dispatch(updateLastDisplayedFeatureDialog.started())
		setUnseenFeature(undefined)
	}

	return (
		<React.Fragment>
			<HnzNewFeatureDialog
				isUnseen={CustomAppVersions.HNZ === unseenFeature}
				onRemoveFromUnseenList={_updateUnseenFeature}
			/>
			<FergusNewFeatureDialog
				isUnseen={CustomAppVersions.FERGUS === unseenFeature}
				onRemoveFromUnseenList={_updateUnseenFeature}
			/>
			<XeroNewFeatureDialog
				isUnseen={CustomAppVersions.XERO === unseenFeature}
				onRemoveFromUnseenList={_updateUnseenFeature}
			/>
			{versionUpdates.map(versionUpdate => (
				<VersionUpdatesDialog
					key={versionUpdate.version}
					isUnseen={versionUpdate.version === unseenFeature}
					onRemoveFromUnseenList={_updateUnseenFeature}
					versionUpdate={versionUpdate}
				/>
			))}
		</React.Fragment>
	)
}

export default NewFeatureDialogs
