import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import * as actions from './actions'
import { ApiErrorMessage, getOrderApi } from '../api'
import { authTokenSelector } from '../auth/selectors'
import { callApi } from '../api/functions'

/**
 * Fetches users orders from network
 */
function* handleFetchOrders(action: actions.FetchOrdersAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.fetchOrders.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.fetchOrders,
			(payload: actions.FetchOrdersPayload) => {
				return getOrderApi().getOrders({ page: payload.page, pageSize: payload.pageSize })
			})
	}
}

export default function* (): SagaIterator {
	yield takeEvery(actions.fetchOrders.started, handleFetchOrders)
}