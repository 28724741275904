import actionCreatorFactory, { Action } from 'typescript-fsa'
import { BranchProductsResponse, CreateOrderDespatchMethodEnum, Order, ProductPricesResponse, SimproJob, SimproJobCostCenter, SimproJobSection } from 'typescript-fetch-api'

import { OrderItem } from '../order/types'
import { CheckoutMethodDetails, DeliveryDetails, DespatchDetails } from './types'
import { UserAccount } from '../accounts/types'
import { ProductInfo } from '../../modules/platform'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Cart')

export interface AddProductToCartPayload {
	product: ProductInfo
	count: number
	comment?: string
}
export type AddProductToCartAction = Action<AddProductToCartPayload>
export const addProductToCart = actionCreator<AddProductToCartPayload>('ADD_PRODUCT_TO_CART')

export const removeProductFromCart = actionCreator<OrderItem>('REMOVE_PRODUCT_FROM_CART')

export type ClearCartAction = Action<void | OrderItem[]>
export const clearCart = actionCreator<void | OrderItem[]>('CLEAR_CART')

// Note the payload contains the new correct OrderItem count
export type UpdateProductQuantityInCartAction = Action<OrderItem>
export const updateProductQuantityInCart = actionCreator<OrderItem>('UPDATE_PRODUCT_QUANTITY_IN_CART')

// called when user changes from Dyanmics to Frameworks branch, we need to ensure decimals are allowed on each product
export const updateProductQuantitiesInCart = actionCreator<OrderItem[]>('UPDATE_PRODUCT_QUANTITIES_IN_CART')

// Note the payload contains the new correct OrderItem comment
export type UpdateProductCommentInCartAction = Action<OrderItem>
export const updateProductCommentInCart = actionCreator<OrderItem>('UPDATE_PRODUCT_COMMENT_IN_CART')

export const updateProductPricesInCart = actionCreator.async<void, ProductPricesResponse, Error>('UPDATE_PRODUCT_PRICES_IN_CART')

export const updateProductAvailabilityInCart = actionCreator.async<void, BranchProductsResponse, Error>('UPDATE_PRODUCT_AVAILABILITY_IN_CART')

export const navigateToOrders = actionCreator('VIEW_ORDERS')

export const navigateToCart = actionCreator('VIEW_CART')

export const navigateToReviewCart = actionCreator('REVIEW_CART')

export const navigateToCheckout = actionCreator('VIEW_CHECKOUT')
export const navigateToCheckoutWithSimpro = actionCreator('VIEW_CHECKOUT_SIMPRO')

export const navigateToPin = actionCreator('ENTER_PIN')

export type AccountSelectedAction = Action<UserAccount>
export const accountSelected = actionCreator<UserAccount>('ACCOUNT_SELECTED')

export const clearAccountSelected = actionCreator('CLEAR_ACCOUNT_SELECTED')

export const branchSelected = actionCreator<string | undefined>('BRANCH_SELECTED')

export interface NavigateToConfirmActionPayload {
	orderNumber: string | undefined
	customerId: number,
	branchName: string,
	branchId: string,
	branchContactNumber: string,
	despatchDetails: DespatchDetails,
	deliveryDetails?: DeliveryDetails | undefined,
	notes: string | undefined,
	checkoutMethodDetails?: CheckoutMethodDetails,
	externalReference?: string,
}
export const navigateToConfirmOrder = actionCreator<NavigateToConfirmActionPayload>('VIEW_CONFIRM_ORDER')

export const reOrder = actionCreator<Order>('RE_ORDER')

export const sendOrderFailed = actionCreator('SEND_ORDER_FAILED')

export interface GetSimproJobsPayload {
	customerId: number
}
export type GetSimproJobsAction = Action<GetSimproJobsPayload>
export const getSimproJobs = actionCreator.async<GetSimproJobsPayload, SimproJob[], Error>('GET_SIMPRO_JOBS')

export interface GetSimproJobSectionsPayload {
	customerId: number
	jobId: number
}
export type GetSimproJobSectionsAction = Action<GetSimproJobSectionsPayload>
export const getSimproJobSections = actionCreator.async<GetSimproJobSectionsPayload, SimproJobSection[], Error>('GET_SIMPRO_JOB_SECTIONS')

export interface GetSimproJobCostCentersPayload {
	customerId: number
	jobId: number
	jobSectionId: number
}
export type GetSimproJobCostCentersAction = Action<GetSimproJobCostCentersPayload>
export const getSimproJobCostCenters = actionCreator.async<GetSimproJobCostCentersPayload, SimproJobCostCenter[], Error>('GET_SIMPRO_JOB_COST_CENTERS')

export const updateDespatchMethodInCart = actionCreator<CreateOrderDespatchMethodEnum>('UPDATE_DESPATCH_METHOD_IN_CART')

export const navigateToArofloCheckout = actionCreator('NAVIGATE_TO_AROFLO_CHECKOUT')