import { Channel, SagaIterator } from 'redux-saga'
import { actionChannel, call, fork, put, take, takeEvery } from 'redux-saga/effects'
import moment from 'moment'
import { GetClickAndCollectTimeslotsRequest, GetDeliveryTimeslotsRequest, GetStandardHoursRequest, Region } from 'typescript-fetch-api'

import { ApiErrorMessage, getBranchApi, getContentApi } from '../api'
import { callApi } from '../api/functions'
import * as actions from './actions'
import { getPlatformSupportImplementation } from '../platform'
import { getSortedData } from '../util/functions'

function* doFetchRegions(action: actions.FetchRegionsAction): SagaIterator {

	let lastTimestampFormatted: string | undefined
	// only grab the last timestamp value when force update hasn't been set so we can avoid repeatedly updating the region data
	if (!action.payload.forceUpdateRegions) {
		// get the current timestamp
		const lastTimestamp: string | null = yield call(getPlatformSupportImplementation().getBranchesFetchDateSharedPrefs)
		console.log('doFetchRegions currentTimestamp:', lastTimestamp)

		// format 'Sun, 06 Oct 2013 01:16:45 GMT'
		lastTimestampFormatted = lastTimestamp === null ? undefined :
			moment(parseInt(lastTimestamp, 10)).format('ddd, DD MMM YYYY HH:mm:ss Z')
	}

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchRegions,
		() => {
			return getContentApi().getBranches({ ifModifiedSince: lastTimestampFormatted })
				.catch((error) => {
					console.log('get branches error', error)
					let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
					if (error instanceof Response) {
						console.log('Regions reponse: ', error.status)
						if (error.status === 304) {
							console.log('Regions up to date')
							return undefined
						}
						switch (error.status) {
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
		})
}

function* doStoreRegions(action: actions.FetchRegionsRequestSuccessAction): SagaIterator {
	if (action.payload !== undefined) {
		const regions: Region[] | undefined = action.payload.result ? action.payload.result.regions : undefined

		if (regions !== undefined) {
			console.log('Got regions from server: ', regions)
			const mappedRegions: Region[] = regions.map((region => ({
				id: region.id,
				name: region.name,
				branches: getSortedData(region.branches, 'name') // sorts the branches in each region
			})))
			const sortedRegions: Region[] = getSortedData(mappedRegions, 'id') // sorts the regions

			// save regions on redux
			yield put(actions.storedRegions(sortedRegions))

			// save the new branches timestamp
			yield call(getPlatformSupportImplementation().saveBranchesFetchDateSharedPrefs, new Date().getTime().toString())
		}
	}
}

function* handleFetchClickAndCollectTimeslots(action: actions.FetchClickAndCollectTimeslotsAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchClickAndCollectTimeslots,
		(payload: GetClickAndCollectTimeslotsRequest) => {
			return getBranchApi().getClickAndCollectTimeslots(payload).then(result => result.days)
		})
}

function* handleFetchDeliveryTimeslots(action: actions.FetchDeliveryTimeslotsAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchDeliveryTimeslots,
		(payload: GetDeliveryTimeslotsRequest) => {
			return getBranchApi().getDeliveryTimeslots(payload).then(result => result.days)
		})
}

function* handleFetchStandardHours(action: actions.FetchStandardHoursAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchStandardHours,
		(payload: GetStandardHoursRequest) => {
			return getContentApi().getStandardHours(payload)
		})
}

/**
 * This channel allows the queueing of requests for loading showrooms and timeslots.
 * The queue prevents timeslot values in a showroom from being overridden on app start when all the regions have been loaded.
 */
function* loadShowroomsAndTimeslotsChannel() {
	// create a channel
	const channel: Channel<actions.FetchRegionsAction | actions.FetchClickAndCollectTimeslotsAction | actions.FetchDeliveryTimeslotsAction | actions.FetchStandardHoursAction> = yield actionChannel([
		actions.fetchRegions.started,
		actions.fetchClickAndCollectTimeslots.started,
		actions.fetchDeliveryTimeslots.started,
		actions.fetchStandardHours.started,
	])
	while (true) {
		// take from the channel
		const action: actions.FetchRegionsAction | actions.FetchClickAndCollectTimeslotsAction | actions.FetchDeliveryTimeslotsAction = yield take(channel)
		// use a blocking call and perform the requests sequentially
		if (action.type === actions.fetchRegions.started.type) {
			yield call(doFetchRegions, action as actions.FetchRegionsAction)
		} else if (action.type === actions.fetchClickAndCollectTimeslots.started.type) {
			yield call(handleFetchClickAndCollectTimeslots, action as actions.FetchClickAndCollectTimeslotsAction)
		} else if (action.type === actions.fetchDeliveryTimeslots.started.type) {
			yield call(handleFetchDeliveryTimeslots, action as actions.FetchDeliveryTimeslotsAction)
		} else if (action.type === actions.fetchStandardHours.started.type) {
			yield call(handleFetchStandardHours, action as actions.FetchStandardHoursAction)
		}
	}
}

export default function* (): SagaIterator {
	yield fork(loadShowroomsAndTimeslotsChannel)
	yield takeEvery(actions.fetchRegions.done, doStoreRegions)
}