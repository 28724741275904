import '../styles/ClearCartDialog.scss'

import React from 'react'
import { Dialog, DialogContent, Divider, Button } from '@mui/material'

interface Props {
	origin: 'cart' | 'signout'
	itemCount: number
	onClose: () => void
	onClearAll?: () => void
}

const ClearCartDialog = ({ origin, itemCount, onClose, onClearAll }: Props): JSX.Element => {

	return (
		<Dialog
			open={true}
			maxWidth="xs"
			fullWidth={true}
			scroll="paper"
			className="clear-cart-dialog"
			onClose={onClose}
		>
			<DialogContent className="clear-cart-dialog__content">
				<h3 className="clear-cart-dialog__heading">{`${origin === 'signout' ? 'Sign Out' : 'Clear Cart'}`}</h3>

				<Divider />

				<p className="clear-cart-dialog__body">
					{origin === 'signout' && 'Signing out will clear all items in the cart.'}
					{origin === 'cart' && `You currently have ${itemCount} ${itemCount === 1 ? 'item' : 'items'} in the cart.`}
					<br />
					{`Are you sure you want to ${origin === 'signout' ? 'sign out?' : 'empty your cart?'}`}
				</p>

				<Button
					variant="contained"
					color="primary"
					fullWidth={true}
					onClick={onClearAll}
				>
					{origin === 'signout' ? 'Confirm' : 'Clear Cart'}
				</Button>

				<Button
					variant="outlined"
					fullWidth={true}
					onClick={onClose}
				>
					Cancel
				</Button>
			</DialogContent>
		</Dialog>

	)
}

export default ClearCartDialog