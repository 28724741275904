import '../../platform/styles/Menu.css'
import '../styles/LabelsMenu.scss'

import { useState } from 'react'
import { Button, IconButton, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { LabelOrderItem } from 'typescript-fetch-api'

import Menu from '../../platform/components/Menu'
import LabelCartCountSummary from './LabelCartCountSummary'
import LabelsSmallInfoDialog from './LabelsSmallInfoDialog'
import { RootStoreState } from '../../../common/root'
import { SubheaderMenuProps } from '../../platform/types'
import { navigateToCreateLabels, navigateToLabelCart, navigateToUploadLabels } from '../../../common/labels/actions'
import { navigateToTab } from '../../platform/actions'

const LabelsMenu = (props: SubheaderMenuProps): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */

	const items = useSelector<RootStoreState, LabelOrderItem[]>(state => state.labels.labelOrderItems)

	/**
	 * Local State
	 */

	const [isLabelsInfoDialogOpen, setIsLabelsInfoDialogOpen] = useState(false)

	/**
	 * Local Functions
	 */

	const _navigateToLabels = () => {
		dispatch(navigateToTab({ tab: 'labels' }))
		props.onClickAway()
	}

	const _onCreateNewLabelGroup = () => {
		dispatch(navigateToCreateLabels())
		props.onClickAway()
	}

	const _onImportLabelGroup = () => {
		dispatch(navigateToUploadLabels())
		props.onClickAway()
	}

	const _onLabelCartClick = () => {
		dispatch(navigateToLabelCart())
		props.onClickAway()
	}

	return (
		<Menu anchorElement={props.anchorElement} onClickAway={props.onClickAway} isHnzAccount={props.isHnzAccount}>
			<div className="labels-menu menu__content-container">
				<div className="labels-menu__header">
					<h2 className="menu__text--heading">LABELS</h2>
					<Tooltip title="Click for more information" className="labels-menu__tooltip--info">
						<IconButton
							onClick={() => setIsLabelsInfoDialogOpen(true)}
							className="labels-menu__button--icon"
						>
							<span className="icon-info labels-menu__tooltip" />
						</IconButton>
					</Tooltip>
				</div>

				<hr className="menu__separator" />

				<div className="menu__items labels-menu__items">
					<div className=" menu__items--vertical">
						<h4
							className="menu__text"
							onClick={_navigateToLabels}
						>
							View Label Groups
						</h4>
						<h4
							className="menu__text"
							onClick={_onCreateNewLabelGroup}
						>
							Create New Label Group
						</h4>
						<h4
							className="menu__text"
							onClick={_onImportLabelGroup}
						>
							Import Label Group
						</h4>
					</div>

					<div className="labels-menu__cart-summary">
						<span className="labels-menu__cart-summary--text">Cart Summary</span>
						<LabelCartCountSummary orderItems={items} />
						<Button
							variant="contained"
							color="primary"
							className="labels-menu__button button--fixed-width"
							onClick={_onLabelCartClick}
						>
							Label Cart
						</Button>
					</div>

				</div>

				{isLabelsInfoDialogOpen &&
					<LabelsSmallInfoDialog
						onClose={() => setIsLabelsInfoDialogOpen(false)}
					/>
				}
			</div>
		</Menu>
	)
}

export default LabelsMenu
