import React from 'react'
import { Button as MuiButton, ButtonProps, styled } from '@mui/material'

import { colors } from '../muiTheme'

const FIXED_WIDTH = 177

const styles = {
	teal: {
		backgroundColor: colors.tealMain,
		color: colors.white,
		'&:hover': {
			backgroundColor: colors.tealMain,
			'@media (hover: none)': {
				backgroundColor: colors.tealMain,
			}
		}
	},
	red: {
		backgroundColor: colors.brinkPink,
		color: colors.white,
		'&:hover': {
			backgroundColor: colors.blush,
			'@media (hover: none)': {
				backgroundColor: colors.blush,
			}
		}
	},
	warning: {
		color: colors.redMain,
		'&:hover': {
			backgroundColor: 'transparent',
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			}
		}
	},
	outlined: {
		backgroundColor: colors.white,
		border: `1px solid ${colors.black}`,
		color: colors.black,
		'&:hover': {
			backgroundColor: colors.black,
			color: colors.white,
			'@media (hover: none)': {
				backgroundColor: colors.black,
				color: colors.white,
			},
		},
	},
	white: {
		backgroundColor: 'transparent',
		border: `1px solid ${colors.white}`,
		color: colors.white,
		'&:hover': {
			backgroundColor: colors.white,
			color: colors.greyLight,
			'@media (hover: none)': {
				backgroundColor: colors.white,
				color: colors.greyLight,
			},
		},
	},
	hnz: {
		backgroundColor: colors.watercourse,
		color: colors.white,
		'&:hover': {
			backgroundColor: colors.eucalyptus,
			'@media (hover: none)': {
				backgroundColor: colors.eucalyptus,
			},
		},
	},
	black: {
		backgroundColor: colors.black,
		color: colors.white,
		'&:hover': {
			backgroundColor: colors.black,
			'@media (hover: none)': {
				backgroundColor: colors.black,
			},
		},
	},
}

interface Props extends ButtonProps {
	isFixedWidth?: boolean
	kind: 'teal' | 'red' | 'outlined' | 'warning' | 'hnz' | 'white' | 'black'
	target?: React.HTMLAttributeAnchorTarget
}

const Button = styled(MuiButton)<Props>(({ isFixedWidth, kind }) => ({
	width: isFixedWidth ? FIXED_WIDTH : undefined,
	...styles[kind],
}))

export default Button
