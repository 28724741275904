import * as React from 'react'
import { Snackbar } from '@mui/material'

import * as Container from '../containers/SnackbarNotification'
import { OrderItem } from '../../../common/order/types'

interface State {
	snackbarMessage: string | undefined
}

const INITIAL_STATE: State = {
	snackbarMessage: undefined,
}

export default class SnackbarNotification extends React.PureComponent<Container.Props, State> {

	state = INITIAL_STATE

	componentDidUpdate(prevProps: Container.Props) {
		if (prevProps.orderItems !== this.props.orderItems) {
			let isUpdatedCart: boolean

			if (prevProps.orderItems.length !== this.props.orderItems.length) {
				isUpdatedCart = true
			} else {
				isUpdatedCart = this.props.orderItems.some(newOrderItem => {
					// find the OrderItem by the product sku
					const order: OrderItem | undefined = prevProps.orderItems.find(item => item.product.sku === newOrderItem.product.sku)
					// checks for a new product or if a product quantity changed
					return !order || (order && order.quantity !== newOrderItem.quantity)
				})
			}

			if (isUpdatedCart) {
				// wrapped in a timeout so we prevent simultaneously triggering a clickaway action on the snackbar when the quantity input is blurred
				setTimeout(() => {
					this.setState({ snackbarMessage: 'Updated cart' })
				}, 100)
			}
		} else if (prevProps.successEditingList !== this.props.successEditingList && !!this.props.successEditingList) {
			this.setState({ snackbarMessage: 'Updated list' })
		} else if (prevProps.successEditingLabelGroup !== this.props.successEditingLabelGroup && !!this.props.successEditingLabelGroup) {
			this.setState({ snackbarMessage: 'Updated label group' })
		} else if (prevProps.errorEditingList !== this.props.errorEditingList && !!this.props.errorEditingList) {
			this.setState({ snackbarMessage: 'Error updating list' })
		} else if (prevProps.errorEditingLabelGroups !== this.props.errorEditingLabelGroups && !!this.props.errorEditingLabelGroups) {
			this.setState({ snackbarMessage: 'Error updating label group' })
		} else if (prevProps.uploadingOrder !== this.props.uploadingOrder && !this.props.uploadingOrder && !this.props.successUploadingOrder) {
			this.setState({ snackbarMessage: 'Error uploading order' })
		} else if (prevProps.uploadingLabelOrder !== this.props.uploadingLabelOrder && !this.props.uploadingLabelOrder && this.props.errorUploadingLabelOrder) {
			this.setState({ snackbarMessage: 'Error uploading order' })
		}
	}

	_handleSnackbarClose = () => {
		this.setState({ snackbarMessage: undefined })
	}

	render() {
		const { snackbarMessage } = this.state

		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={!!snackbarMessage}
				autoHideDuration={2000}
				onClose={this._handleSnackbarClose}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id" className="snackbar">{snackbarMessage}</span>}
				style={{ zIndex: 10 }}
			/>
		)
	}
}