import * as React from 'react'
import { Drawer, Button } from '@mui/material'
import { AccountPermission } from 'typescript-fetch-api'

import Container, { Props, Actions } from '../../../common/cart/containers/Cart'
import { ProductInfo } from '../../platform'
import PriceSummary from './PriceSummary'
import { PriceType } from '../../../common/accounts/types'
import { getPriceType, getOrderPriceSummary, getProductPriceDetails } from '../functions'
import { OrderPriceSummary } from '../types'
import Dialog from '../../platform/components/Dialog'
import CartListWithStocks from './CartListWithStocks'
import ListDialog from '../../product/components/ListDialog'
import { AroFloHeader } from '../../../utils/imagesProvider'

import '../styles/CartDrawer.scss'

interface CartProps {
	isDrawerVisible: boolean
	onHideDrawer: () => void
}

interface State {
	selectedListId?: string
	shouldShowClearCartDialog: boolean
	shouldShowAddToListDialog: boolean
}

const INITIAL_STATE: State = {
	selectedListId: undefined,
	shouldShowClearCartDialog: false,
	shouldShowAddToListDialog: false,
}

class CartDrawer extends React.PureComponent<Props & Actions & CartProps, State> {

	state = INITIAL_STATE

	onProductSelected = (product: ProductInfo) => {
		this.props.onHideDrawer()
		this.props.onProductSelected(product)
	}

	onClearCart = () => {
		this._onHideClearCartDialog()
		this.props.onClearCart(this.props.orderItems)
	}

	onCheckout = () => {
		this.props.onHideDrawer()
		this.props.navigateToReviewCart()
	}

	_onShowClearCartDialog = () => {
		this.setState({ shouldShowClearCartDialog: true })
	}

	_onHideClearCartDialog = () => {
		this.setState({ shouldShowClearCartDialog: false })
	}

	_onShowAddToListDialog = () => {
		this.setState({ shouldShowAddToListDialog: true })
	}

	_onHideAddToListDialog = () => {
		this.setState({ shouldShowAddToListDialog: false })
	}

	_onArofloSubmit = () => {
		this.props.onHideDrawer()
		// navigate user to aroflo checkout screen which is where we log them out so they don't get taken to the sign in page while the form is bring submitted
		this.props.navigateToArofloCheckout()
	}

	_renderAroFloHiddenCartItems = (priceType: PriceType, hasHnzAccountSelected: boolean) => {
		// for each item in the cart, render hidden inputs to store product information
		return this.props.orderItems.map((item, index) => {
			const { productPrice } = getProductPriceDetails(item.product, priceType, hasHnzAccountSelected)
			const itemIndex = index + 1 // AroFlo index is 1-based
			return (
				<React.Fragment key={itemIndex}>
					<input type="hidden" name={`NEW_ITEM-DESCRIPTION[${itemIndex}]`} value={item.product.longDescription?.substring(0, 40)} />
					<input type="hidden" name={`NEW_ITEM-QUANTITY[${itemIndex}]`} value={item.quantity} />
					<input type="hidden" name={`NEW_ITEM-UNIT[${itemIndex}]`} value={item.product.uom} />
					<input type="hidden" name={`NEW_ITEM-PRICE[${itemIndex}]`} value={productPrice} />
					<input type="hidden" name={`NEW_ITEM-CURRENCY[${itemIndex}]`} value="NZD" />
					<input type="hidden" name={`NEW_ITEM-VENDORMAT[${itemIndex}]`} value={item.product.sku} />
					<input type="hidden" name={`NEW_ITEM-MANUFACTMAT[${itemIndex}]`} value={item.product.sku} />
				</React.Fragment>
			)
		})
	}

	render() {
		const { shouldShowClearCartDialog, shouldShowAddToListDialog } = this.state
		const { isDrawerVisible, onHideDrawer, orderItems, selectedAccount, isAroFloProcurementUser, aroFloHookUrl, isProductCommentsEnabled, onRemoveProductFromCart, onUpdateProductQuantityInCart, onUpdateProductCommentInCart } = this.props
		const hasOrderItems: boolean = orderItems.length > 0
		const hasHnzAccountSelected: boolean = !!selectedAccount && !!selectedAccount.hnz

		// calculating price-related values
		const priceType: PriceType = getPriceType(selectedAccount)
		const values: OrderPriceSummary = getOrderPriceSummary(orderItems, priceType, hasHnzAccountSelected)

		// checks if the selected user account for the order has permissions to create an order
		const hasOrderPermissions: boolean = selectedAccount && selectedAccount.permissions &&
			selectedAccount.permissions.some(item => item === AccountPermission.CREATE_ORDER) ? true : false

		return (
			<React.Fragment>
				<Drawer
					open={isDrawerVisible}
					onClose={onHideDrawer}
					anchor="right"
					className="cart-drawer"
				>
					{!shouldShowClearCartDialog &&
						<div className="cart-drawer__close--container">
							<span className="icon-close-round cart-drawer__close--icon" onClick={onHideDrawer} />
						</div>
					}

					<div className="cart-drawer__content">
						{shouldShowClearCartDialog ?
							<div className='cart-drawer__header cart-drawer__header--subtext-container'>
								<span className="cart-drawer__header--text">Confirm Clear Cart</span>
								{shouldShowClearCartDialog && <span className="cart-drawer__header--subtext">Confirming will remove {orderItems.length} item(s) from your cart</span>}
							</div>
							:
							<div className='cart-drawer__header'>
								<span className="cart-drawer__header--text">Cart</span>
								{hasOrderItems && <div className="icon-add-to-list cart-drawer__header--icon" onClick={this._onShowAddToListDialog} />}
								{hasOrderItems && <div className="icon-delete cart-drawer__header--icon" onClick={this._onShowClearCartDialog} />}
							</div>
						}

						<div className="cart-drawer__container">
							<div className={`cart-drawer__list ${!hasOrderItems ? 'cart-drawer__list--empty' : ''}`}>
								{hasOrderItems ?
									<CartListWithStocks
										orderItems={orderItems}
										priceType={priceType}
										hasHnzAccountSelected={hasHnzAccountSelected}
										isProductCommentsEnabled={isProductCommentsEnabled}
										onProductSelected={this.onProductSelected}
										onRemoveProductFromCart={onRemoveProductFromCart}
										onUpdateProductQuantityInCart={onUpdateProductQuantityInCart}
										onUpdateProductCommentInCart={onUpdateProductCommentInCart}
									/>
									:
									<span className="message info">
										You have no items in your cart
									</span>
								}
							</div>

							{shouldShowClearCartDialog && <div className="cart-drawer__overlay" />}
						</div>

						{shouldShowClearCartDialog ?
							<div className="cart-drawer__footer">
								<Button variant="contained" color="primary" className="cart-drawer__footer--button" onClick={this.onClearCart}>Confirm</Button>

								<Button variant="outlined" className="cart-drawer__footer--button" onClick={this._onHideClearCartDialog}>Cancel</Button>
							</div>
							:
							hasOrderItems ?
								<div className="cart-drawer__footer">
									<PriceSummary values={values} priceType={priceType} />

									{hasOrderPermissions && (
										<Button variant="contained" color="primary" className="cart-drawer__footer--button" onClick={this.onCheckout}>Checkout</Button>
									)}

									{isAroFloProcurementUser && aroFloHookUrl && (
										<form action={aroFloHookUrl} method="post" onSubmitCapture={this._onArofloSubmit}>
											{this._renderAroFloHiddenCartItems(priceType, hasHnzAccountSelected)}
											<Button variant="contained" color="primary" className="cart-drawer__footer--button cart-drawer__footer--aroflo" type="submit">
												SEND TO
												<img
													src={AroFloHeader}
													alt="AroFlo"
													className="cart-drawer__aroflo"
												/>
											</Button>
										</form>
									)}
								</div>
								: undefined
						}
					</div>
				</Drawer>

				{/* Clear Cart Dialog */}
				{shouldShowClearCartDialog && false && ( // TODO testing new design for confirm clear cart, so remove this after it has been approved
					<Dialog
						title="CLEAR CART"
						isOpen={shouldShowClearCartDialog}
						onClose={this._onHideClearCartDialog}
						onConfirm={this.onClearCart}
						onCancel={this._onHideClearCartDialog}
					>
						<div className="dialog__text">You currently have {orderItems.length} item(s) in the cart.</div>
						<div className="dialog__text">Are you sure you want to empty your cart?</div>
					</Dialog>
				)}

				{shouldShowAddToListDialog && (
					<ListDialog
						items={orderItems}
						onClose={this._onHideAddToListDialog}
					/>
				)}
			</React.Fragment>
		)
	}
}

export default Container<CartProps>(CartDrawer)