import { CategoryType } from './types'

/**
 * Identifies the category type based on the underscores in the category id. 
 * 0 - parent/category; 1 - subcategory; 2 - subsubcategory
 */
export const getNumericCategoryType = (categoryId: string): 0 | 1 | 2 => {
	const matches: string[] | null = categoryId.match(/_/g)
	if (matches) {
		return matches.length === 2 ? 2 : 1
	}
	return 0
}

export const getCategoryType = (categoryId: string): CategoryType => {
	const categoryType: 0 | 1 | 2 = getNumericCategoryType(categoryId)
	switch (categoryType) {
		case 1:
			return 'subcategory'
		case 2:
			return 'subsubcategory'
		default:
			return 'category'
	}
}