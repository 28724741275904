import { connect } from 'react-redux'

import Component from '../components/SnackbarNotification'
import { RootStoreState } from '../../../common/root'
import { OrderItem } from '../../../common/order/types'

export interface Props {
	orderItems: OrderItem[]
	successEditingList: boolean
	errorEditingList: boolean
	successEditingLabelGroup: boolean
	errorEditingLabelGroups: boolean

	// order upload
	uploadingOrder: boolean
	successUploadingOrder: boolean

	// label order upload
	uploadingLabelOrder: boolean
	errorUploadingLabelOrder: boolean
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		orderItems: state.order.orderItems,
		successEditingList: state.lists.successEditingList || false,
		errorEditingList: !!state.lists.errorEditingList,
		successEditingLabelGroup: state.labels.successEditingLabelGroups,
		errorEditingLabelGroups: !!state.labels.errorEditingLabelGroups,

		// order upload
		uploadingOrder: !!state.order.loadingMessageSendOrder,
		successUploadingOrder: state.order.successSendOrder,

		// label order upload
		uploadingLabelOrder: !!state.labels.creatingLabelOrder,
		errorUploadingLabelOrder: !!state.labels.errorCreatingLabelOrder,
	}
}

export default connect(mapStateToProps, {})(Component)
