import { SagaIterator } from 'redux-saga'
import { takeEvery, put } from 'redux-saga/effects'

import { readyAction } from '../../common/root/actions'
import { fetchRegions, navigateToRegions } from '../../common/showrooms/actions'
import * as NavigationManager from '../navigation/NavigationManager'

function* doFetchRegions(): SagaIterator {
	yield put(fetchRegions.started({}))
}

function handleNavigateToRegions() {
	NavigationManager.navigateToRegions()
}

export default function* (): SagaIterator {
	yield takeEvery(readyAction, doFetchRegions)
	yield takeEvery(navigateToRegions, handleNavigateToRegions)
}
