import { AccountPermission } from 'typescript-fetch-api'

import { PriceType, UserAccount } from '../../common/accounts/types'
import { OrderPriceSummary } from './types'
import { OrderItem } from '../../common/order/types'
import { ProductInfo } from '../platform'
import { ProductAvailability } from '../../common/cart/types'

/**
 * Grabs the price designated for an account based on the permissions.
 * @param selectedAccount a UserAccount currently selected for the order
 */
export const getPriceType = (selectedAccount?: UserAccount): PriceType => {
	const permissions: AccountPermission[] = (selectedAccount && selectedAccount.permissions) || []
	if (permissions.some(permission => permission === AccountPermission.VIEW_ACCOUNT_PRICING)) {
		return PriceType.CST
	}
	return PriceType.RRP
}

/**
 * Returns the product price details - price and gst, with respect to the account selected being an hnz one or not
 * @param product product
 * @param priceType price type
 * @param hasHnzAccountSelected flag to know whether the current account is an hnz one
 */
export const getProductPriceDetails = (product: ProductInfo, priceType: PriceType, hasHnzAccountSelected: boolean): { productPrice: number, productPriceGst: number | undefined } => {
	let productPrice: number
	let productPriceGst: number | undefined

	if (hasHnzAccountSelected && product.hnz && product.hnzPrice) {
		// we use the prices related to hnz since both the selected account and the product is under hnz
		productPrice = product.hnzPrice
		productPriceGst = product.hnzPriceGst
	} else {
		productPrice = product[priceType] || 0
		productPriceGst = product[`${priceType}Gst`]
	}

	return { productPrice, productPriceGst }
}

/**
 * Calculates the values - subtotal, gst and total for an order.
 * @param orderItems order items
 * @param priceType price type 
 * @param hasHnzAccountSelected flag to know whether the current account is an hnz one
 */
export const getOrderPriceSummary = (orderItems: OrderItem[], priceType: PriceType, hasHnzAccountSelected: boolean): OrderPriceSummary => {
	const values: { subtotal: number, gst: number } = orderItems.reduce(
		(data: { subtotal: number, gst: number }, item: OrderItem) => {
			const quantity: number = item.quantity
			const { productPrice, productPriceGst } = getProductPriceDetails(item.product, priceType, hasHnzAccountSelected)
			return ({
				subtotal: data.subtotal + (productPrice * quantity),
				gst: productPriceGst
					? data.gst + (productPriceGst * quantity)
					: data.gst + (productPrice * quantity * 0.15)
			})
		},
		{ subtotal: 0, gst: 0 }
	)
	const total: number = values.subtotal + values.gst
	return { ...values, total }
}

/**
 * Handles the info details for a product availability to be displayed on the tooltip.
 * @param availability the product availability
 * @param branchName the branch name
 * @returns the info details
 */
export const getProductAvailabilityInfoDetails = (availability: ProductAvailability, branchName?: string): string[] => {
	switch (availability) {
		case ProductAvailability.LIMITED_STOCK:
			return [
				`The ${branchName ? `${branchName} ` : ''}branch has stock but not enough to fulfill your total item quantity.`,
				'When you place the order we will commit the available stock to you and liaise with you about the backorder.',
			]
		case ProductAvailability.AVAILABLE_TO_ORDER:
			return [
				`The ${branchName ? `${branchName} ` : ''}branch does not have stock to fulfill your order quantity.`,
				'When you place the order we will order stock in and liaise with you about any further information.',
			]
		default:
			return []
	}
}

/**
 * Checks if an account has permission to create order
 * @param account the account
 * @returns true if the account has permission to create order; false if otherwise
 */
export const checkAccountHasOrderPermission = (account: UserAccount): boolean => {
	return !!account.permissions?.some(item => item === AccountPermission.CREATE_ORDER)
}