import { SagaIterator } from 'redux-saga'
import { call, takeEvery } from 'redux-saga/effects'
import { Event } from 'typescript-fetch-api'

import * as actions from './actions'
import { getEventsApi } from '../api'
import { callApi } from '../api/functions'

function* handleCreateEvent(action: actions.CreateEventAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.createEvent,
		(payload: Event, options: RequestInit) => {
			return getEventsApi().createEvent({ event: payload }, options)
		}, false)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.createEvent.started, handleCreateEvent)
}