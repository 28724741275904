import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Branch } from 'typescript-fetch-api'

import Component from '../components/Header'
import { navigateToTab, toggleDrawer } from '../actions'
import { navigateToPartners } from '../../integrations/actions'
import { popAllStacks, navigateToSignIn } from '../../../common/profile/actions'
import { logoutRequest } from '../../../common/auth/actions'
import { navigateToRegions } from '../../../common/showrooms/actions'
import { navigateToContactUs } from '../../../common/contactus/actions'
import { OrderItem } from '../../../common/order/types'
import { UserAccount } from '../../../common/accounts/types'
import { Partners } from '../../integrations/types'
import { DrawerType, WebStoreState } from '../types'
import { getShareholdingToken } from '../../auth/actions'
import { getIsAroFloProcurementUser } from '../../../common/auth/functions'

export interface Props {
	orderItems: OrderItem[]
	selectedAccount?: UserAccount
	loggedIn: boolean
	cartQuantity: number
	currentUsername?: string
	impersonatorUsername?: string
	selectedBranch?: Branch
	hasShareholdingAccess: boolean
	isAroFloProcurementUser: boolean
}

export interface Actions {
	goHome: () => void
	goRegions: () => void
	goSignin: () => void
	goSignout: () => void
	goContactUs: () => void
	goPartners: (partner: Partners) => void
	onToggleDrawer: (drawerType: DrawerType) => void
	onGoToShareholdingSite: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: WebStoreState): Props => {
	const branches = state.showrooms.regions.reduce<Branch[]>((data, region) => data.concat(region.branches), [])
	const branchId = state.order.branchId

	return {
		orderItems: state.order.orderItems,
		selectedAccount: state.order.selectedAccount,
		loggedIn: !!state.auth.authToken,
		cartQuantity: state.order.orderItems.length,
		currentUsername: `${state.auth.firstName} ${state.auth.lastName}`,
		impersonatorUsername: state.auth.authToken ? state.auth.authToken.impersonator_username : undefined,
		selectedBranch: branches.find(branch => branch.id === branchId),
		hasShareholdingAccess: state.platform.accounts.length > 0 && !state.auth.authToken?.impersonator_username,
		isAroFloProcurementUser: getIsAroFloProcurementUser(state.auth.username),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	goHome: () => {
		dispatch(navigateToTab({ tab: 'home' }))
	},
	goRegions: () => {
		dispatch(navigateToRegions())
	},
	goSignin: () => {
		dispatch(navigateToSignIn())
	},
	goSignout: () => {
		dispatch(popAllStacks())
		dispatch(logoutRequest({}))
	},
	goContactUs: () => {
		dispatch(navigateToContactUs())
	},
	goPartners: (partner: Partners) => {
		dispatch(navigateToPartners(partner))
	},
	onToggleDrawer: (drawerType: DrawerType) => {
		dispatch(toggleDrawer(drawerType))
	},
	onGoToShareholdingSite: () => {
		dispatch(getShareholdingToken.started())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
