import '../styles/RoleInfo.scss'

import React from 'react'
import { Popper, ClickAwayListener, Fade, Paper, Box } from '@mui/material'

import { Role } from '../../../common/accounts/types'
import { RoleDetails } from '../content'

interface Props {
	role: Role
	anchorElement: HTMLElement | null
	onClickAway: () => void
}

const RoleInfo = ({ role, anchorElement, onClickAway }: Props): JSX.Element => {

	return (
		<Popper
			open={!!anchorElement}
			anchorEl={anchorElement}
			transition={true}
			className="role-info"
			placement="bottom-start"
		>
			{({ TransitionProps }) => (
				<ClickAwayListener onClickAway={onClickAway}>
					<Fade {...TransitionProps}>
						<Paper>
							<div className="role-info__heading">
								<span className="icon-info" />

								<div>
									<p className="role-info__text">Role</p>
									<p className="role-info__text--heading">{role}</p>
								</div>
							</div>

							<p className="role-info__text--heading">{role} Role Description</p>

							<Box m={2} />

							{RoleDetails[role].map((detail, index) => (
								<p key={index} className="role-info__text">
									- {detail}
								</p>
							))}
						</Paper>
					</Fade>
				</ClickAwayListener>
			)}
		</Popper>
	)
}

export default RoleInfo
