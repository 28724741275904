import { BranchClosure, ClickAndCollectTimeslotDay, DeliveryTimeslotDay, ERP, StandardHoursDay } from 'typescript-fetch-api'

/**
 * Describes a region which is stored in redux / persist
 */
export interface Region {
	id: string
	name: string
	branches: Array<Branch>
}

/**
 * Describes a branch which is stored in redux / persist
 */
export interface Branch {
	id: string
	name: string
	address: string
	contactNumber: string
	latitude: number
	longitude: number
	closures?: BranchClosure[]
	clickAndCollectTimeslots?: ClickAndCollectTimeslotDay[]
	deliveryTimeslots?: DeliveryTimeslotDay[]
	standardHours?: StandardHoursDay[]
	isDts: boolean
	erp: ERP
}

export enum DaysOfWeekEnum {
	MON = 0,
	TUE = 1,
	WED = 2,
	THU = 3,
	FRI = 4,
	SAT = 5,
	SUN = 6,
}