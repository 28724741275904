import '../styles/StockAvailabilityLogo.scss'

import { Paper, Tooltip, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'

import { WebStoreState } from '../../platform/types'
import { ProductAvailability } from '../../../common/cart/types'
import { getStockAvailabilityTypeForItem } from '../../../common/cart/functions'

interface Props {
	stockCount?: number
	quantity?: number
	sku?: string // used as fallback reference to check for the actual product quantity in the cart
	branchName?: string
	hideTooltip?: boolean
	className?: string
}

const StockAvailabilityLogo = (props: Props): JSX.Element => {

	/**
	 * Store State
	 */

	const branchName = useSelector<WebStoreState, string | undefined>(state => {
		if (props.branchName) return props.branchName
		for (let i = 0; i < state.showrooms.regions.length; i++) {
			const matchingBranch = state.showrooms.regions[i].branches.find(branch => branch.id === state.order.branchId)
			if (matchingBranch) {
				return matchingBranch.name
			}
		}
		return undefined
	})
	const quantity = useSelector<WebStoreState, number>(state => {
		// no quantity reference, grab it directly off the cart
		if (props.quantity === undefined && props.sku) {
			return state.order.orderItems.find(item => item.product.sku === props.sku)?.quantity || 0
		}
		return props.quantity || 0
	})
	const showLoading = useSelector<WebStoreState, boolean>(state => {
		return props.stockCount === undefined && (
			(state.products.productStockCountBatches !== undefined && state.products.productStockCountBatches > 0) ||
			state.products.fetchingProductStockCounts ||
			state.search.fetchingSearchResultStockCount ||
			state.productfinder.fetchingStockCounts ||
			state.product.loadingBranchesAvailability.length > 0
		)
	})

	/**
	 * Render
	 */

	const availability = getStockAvailabilityTypeForItem(quantity, props.stockCount)

	let stockCountTooltip = ''
	if (!props.hideTooltip && !showLoading) {
		stockCountTooltip = availability === ProductAvailability.LIMITED_STOCK ? `Limited Stock - ${branchName}`
			: availability === ProductAvailability.IN_STOCK ? `In Stock - ${branchName}`
				: `Not In Stock - ${branchName}`
	}

	const stockLogoStyle = availability === ProductAvailability.LIMITED_STOCK ? 'stock-logo__circle--limited'
		: availability === ProductAvailability.IN_STOCK ? 'stock-logo__circle--stock'
			: ''

	return (
		<Paper
			className={`stock-logo ${props.className}`}
			elevation={2}
		>
			<Tooltip title={stockCountTooltip}>
				{showLoading ?
					<CircularProgress size={24} />
					:
					<div className={`stock-logo__circle ${stockLogoStyle}`}>
						<span className="icon-shop stock-logo__icon" />
					</div>
				}
			</Tooltip>
		</Paper>
	)
}

export default StockAvailabilityLogo