import * as React from 'react'
import { FormControl, FormHelperText, CircularProgress, TextField, Button, Slide, Paper } from '@mui/material'

import Container, { Props, Actions } from '../../../common/accounts/containers/AddAccount'
import Tooltip from '../../platform/components/Tooltip'
import { isInvalidString } from '../../../common/util/functions'
import BranchSelect from '../../platform/components/BranchSelect'

import '../styles/JoinAccount.scss'

interface OwnProps {
	visible: boolean
	onCancelJoinAccount: () => void
}

interface State {
	accountId: string
	businessName: string
	selectedBranch?: string
	businessNameError?: string
	selectedBranchError?: string
	errorMessage?: string
	successMessage?: string
}

const INITIAL_STATE: State = {
	accountId: '',
	businessName: '',
	selectedBranch: undefined,
	businessNameError: undefined,
	selectedBranchError: undefined,
	errorMessage: undefined,
	successMessage: undefined,
}

class JoinAccount extends React.PureComponent<Props & Actions & OwnProps, State> {

	state = INITIAL_STATE

	componentDidUpdate(prevProps: Props & Actions) {
		if (this.props.loading && prevProps.loading !== this.props.loading) {
			this.setState({ errorMessage: undefined, successMessage: undefined })
		}

		// reset the local state values once the user has successfully joined an account
		if (this.props.accountAddedSuccessfully && prevProps.accountAddedSuccessfully !== this.props.accountAddedSuccessfully) {
			this.setState({ ...INITIAL_STATE, successMessage: 'Successfully added account' })
		}

		if (this.props.error && prevProps.error !== this.props.error) {
			this.setState({ errorMessage: this.props.error.message || 'Error adding account' })
		}
	}

	_onValidate = () => {
		const { accountId, businessName, selectedBranch } = this.state

		if (!businessName) {
			this.setState({ businessNameError: 'A business name is required' })
			return
		} else {
			if (isInvalidString(businessName)) {
				this.setState({ businessNameError: 'Please enter a valid business name' })
				return
			}
		}
		if (!selectedBranch) {
			this.setState({ selectedBranchError: 'A branch is required' })
			return
		}
		this.props.onAddAccount(
			businessName,
			accountId ? parseInt(accountId, 10) : undefined,
			parseInt(selectedBranch, 10)
		)
	}

	_handleAccountIdOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ accountId: e.target.value, errorMessage: undefined, successMessage: undefined })
	}

	_handleBusinessNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ businessName: e.target.value, businessNameError: undefined, errorMessage: undefined, successMessage: undefined })
	}

	_handleSelectOnChange = (branchId: string | undefined) => {
		this.setState({ selectedBranch: branchId, selectedBranchError: undefined, errorMessage: undefined, successMessage: undefined })
	}

	_getMessage = (): JSX.Element | null => {
		if (this.props.loading) {
			return <CircularProgress className="join-account__progress" />
		} else if (this.state.errorMessage) {
			return <span className="message error">{this.state.errorMessage}</span>
		} else if (this.state.successMessage) {
			return <span className="message success">Successfully added account</span>
		}
		return null
	}

	render() {
		const { accountId, businessName, businessNameError, selectedBranchError } = this.state
		const { branches, visible } = this.props

		return (
			<Slide direction="up" in={visible} mountOnEnter={true} unmountOnExit={true}>
				<Paper className="join-account">
					<div className="join-account__form">

						<span className="join-account__text join-account__text--section">Add Account</span>

						<FormControl>
							<Tooltip title="Please enter your account number">
								<TextField
									type="number"
									id="accountId"
									value={accountId}
									label="Account ID"
									onChange={this._handleAccountIdOnChange}
									variant="outlined"
									autoComplete="off"
								/>
							</Tooltip>
							<Tooltip
								title="Put the name of the business in the Business Name and we’ll find it."
								arrow={false}
								placement="bottom-start"
							>
								<FormHelperText className="join-account__text--helper">Forgotten your Account ID?</FormHelperText>
							</Tooltip>
						</FormControl>

						<FormControl>
							<Tooltip title="Please enter your business name">
								<TextField
									type="text"
									id="businessName"
									value={businessName}
									label="Business Name *"
									onChange={this._handleBusinessNameOnChange}
									error={!businessName && !!businessNameError}
									variant="outlined"
									autoComplete="off"
								/>
							</Tooltip>
							<FormHelperText error={!!businessNameError}>{businessNameError}</FormHelperText>
						</FormControl>

						<FormControl>
							<div>
								{/* NOTE: we wrap this in a `div` to bypass the failed prop error thrown by the `Tooltip` */}
								<BranchSelect
									options={branches}
									label="Preferred Branch *"
									inputParams={{
										inputProps: { autoComplete: 'off' },
									}}
									clearSelection={!this.props.loading && this.props.accountAddedSuccessfully}
									onBranchSelected={this._handleSelectOnChange}
								/>
							</div>
							<FormHelperText error={!!selectedBranchError}>{selectedBranchError}</FormHelperText>
						</FormControl>

						{this._getMessage()}
					</div>

					<div className="user-profile__update-footer">
						<Button variant="contained" color="primary" className="user-profile__update-footer--button" onClick={this._onValidate}>
							ADD ACCOUNT
						</Button>
						<Button variant="outlined" className="user-profile__update-footer--button" onClick={this.props.onCancelJoinAccount}>
							CANCEL
						</Button>
					</div>
				</Paper>
			</Slide>
		)
	}
}

export default Container(JoinAccount)