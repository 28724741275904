import * as React from 'react'
import { Button, Divider, IconButton } from '@mui/material'

import { LogoYTIB } from '../../../utils/imagesProvider'

import '../styles/NewFeaturePage.scss'

interface Props {
	onClose: () => void
}

export default class NewFeatureLastPage extends React.PureComponent<Props> {

	render() {
		const { onClose } = this.props

		return (
			<div className="page">
				<div className="page__heading page__heading--end">
					<IconButton className="page__button--close" onClick={onClose}>
						<div className="icon-close" />
					</IconButton>
				</div>

				<div className="page__content">
					<img src={LogoYTIB} alt="Plumbing World Logo" className="page__logo--pw-ytib" />

					<Divider className="page__divider" />

					<p className="page__text page__text--extra">100% OWNED BY KIWI TRADIES</p>
				</div>

				<div className="page__footer">
					<Button
						variant="contained"
						color="primary"
						className="button--fixed-width"
						onClick={onClose}
					>
						GO
					</Button>
				</div>
			</div>
		)
	}
}