import { Channel, SagaIterator } from 'redux-saga'
import { actionChannel, call, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { DownloadOrderFileType, DownloadOrderRequest, GetCustomerOrdersRequest, OrderStatus, OrderType } from 'typescript-fetch-api'

import * as actions from './actions'
import { downloadFile } from '../../utils/functions'
import { ApiErrorMessage, getOrderApi } from '../api'
import { authTokenSelector } from '../auth/selectors'
import { callApi } from '../api/functions'

/**
 * Fetches orders for the customer / account from network
 */
function* handleFetchCustomerOrders(action: actions.GetCustomerOrdersAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.fetchCustomerOrders.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.fetchCustomerOrders,
			(payload: actions.GetCustomerOrdersPayload) => {
				let status: OrderStatus | undefined
				if (payload.status) {
					// we forcefully send an uppercase status since comparison in the server references the enum name (in uppercase) instead of the enum value (in lowercase)
					// e.g. RECEIVED("received")
					status = payload.status.toUpperCase() as OrderStatus
				}
				const requestParams: GetCustomerOrdersRequest = {
					customerId: payload.customerId,
					type: payload.type as OrderType,
					branchId: payload.branchId ? payload.branchId : undefined,
					salesRep: payload.orderedBy ? payload.orderedBy : undefined,
					reference: payload.reference ? payload.reference : undefined,
					status,
					pwgoOnly: payload.pwgoOnly ? payload.pwgoOnly : undefined,
					interimOrders: payload.interimOrders ? payload.interimOrders : undefined,
				}
				return getOrderApi().getCustomerOrders(requestParams)
					.catch((error: Error) => {
						let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
						if (error instanceof Response) {
							// @ts-ignore
							if (error.error_description) {
								// @ts-ignore
								errorText = error.error_description
							}
						}
						throw new Error(errorText)
					})
			})
	}
}

/**
 * Fetches order for the customer / account from network
 */
function* handleFetchCustomerOrder(action: actions.GetCustomerOrderAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.fetchCustomerOrder.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.fetchCustomerOrder,
			(payload: actions.GetCustomerOrderPayload) => {
				return getOrderApi()
					.getCustomerOrder({
						customerId: payload.customerId,
						orderId: payload.orderId,
						orderSuffixId: payload.orderSuffixId,
						type: payload.orderType,
						erp: payload.erp,
					})
					.catch((error: Error) => {
						let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
						if (error instanceof Response) {
							switch (error.status) {
								case 404:
									if (payload.orderType === 'QUOTE') {
										errorText = ApiErrorMessage.QUOTE_ERROR_MESSAGE
										break
									}
									errorText = ApiErrorMessage.ORDER_ERROR_MESSAGE
									break
								case 406:
									errorText = ApiErrorMessage.INVOICE_ERROR_MESSAGE
									break
								default:
									// @ts-ignore
									if (error.error_description) {
										// @ts-ignore
										errorText = error.error_description
									}
							}
						}
						throw new Error(errorText)
					})
			})
	}
}

/**
 * This channel allows the queueing of requests for downloading multiple orders.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 */
function* downloadOrdersChannel() {
	// create a channel for request actions to download an order
	const requestChannel: Channel<actions.DownloadOrderRequestAction> = yield actionChannel(actions.downloadCustomerOrder.started)
	while (true) {
		// take from the channel
		const action: actions.DownloadOrderRequestAction = yield take(requestChannel)
		// use a blocking call and perform the requests sequentially
		yield call(handleDownloadOrder, action)
	}
}

/**
 * Downloads the order for given order Id and customer.
 * @param action The action containing the download order payload
 */
export function* handleDownloadOrder(action: actions.DownloadOrderRequestAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.downloadCustomerOrder.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.downloadCustomerOrder,
			(payload: actions.DownloadOrderPayload) => {
				const requestParams: DownloadOrderRequest = {
					customerId: payload.customerId,
					orderId: payload.orderId,
					orderSuffixId: payload.orderSuffixId,
					orderType: payload.orderType,
					type: payload.type,
					erp: payload.erp,
				}
				return getOrderApi().downloadOrder(requestParams)
					.then((response: Blob) => {
						let mimeType
						switch (payload.type) {
							case DownloadOrderFileType.CSV:
							case DownloadOrderFileType.CSV_2:
								mimeType = 'text/csv'
								break
							case DownloadOrderFileType.PDF:
							case DownloadOrderFileType.PDF_2:
							case DownloadOrderFileType.PDF_2_WITH_IMAGE:
							case DownloadOrderFileType.PDF_2_WITH_PRICE:
							case DownloadOrderFileType.PDF_2_WITH_PRICE_AND_IMAGE:
							case DownloadOrderFileType.PDF_2_KOHC:
								mimeType = 'application/pdf'
								break
							case DownloadOrderFileType.XLS:
								mimeType = 'application/vnd.ms-excel'
								break
						}

						// Create a Blob from the PDF Stream
						const file = new Blob(
							[response],
							{ type: mimeType })

						// chrome sometimes blocks large files being opened in new window, couldnt find exact size limit so using 2mb
						if (payload.view && payload.type === DownloadOrderFileType.PDF && file.size < 2000000) {
							// Build a URL from the file
							const fileURL = URL.createObjectURL(file)
							// Open the URL on new Window
							window.open(fileURL, '_blank')
						} else {
							let fileExtension
							switch (payload.type) {
								case DownloadOrderFileType.PDF:
								case DownloadOrderFileType.PDF_2:
								case DownloadOrderFileType.PDF_2_WITH_IMAGE:
								case DownloadOrderFileType.PDF_2_WITH_PRICE:
								case DownloadOrderFileType.PDF_2_WITH_PRICE_AND_IMAGE:
								case DownloadOrderFileType.PDF_2_KOHC:
									fileExtension = '.pdf'
									break
								case DownloadOrderFileType.CSV:
								case DownloadOrderFileType.CSV_2:
									fileExtension = '.csv'
									break
								case DownloadOrderFileType.XLS:
									fileExtension = '.xlsx'
									break
							}
							// create a download
							const filename = action.payload.orderId + '-' + action.payload.customerId + fileExtension
							downloadFile(response, filename)
						}
					})
			})
	}
}

/**
 * Handles downloading a set of customer orders as a zip file.
 * @param action The action containing the customer orders to download as zip.
 */
export function* handleDownloadCustomerOrdersAsZip(action: actions.DownloadCustomerOrderAsZipAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.downloadCustomerOrderAsZip,
		(payload: actions.DownloadCustomerOrderAsZipPayload) => {
			const { customerId, orderType, type, orders } = payload
			return getOrderApi()
				.downloadOrders({
					customerId,
					orderType,
					type,
					downloadOrdersRequest: { orders },
				})
				.then((response: Blob) => {
					const filename: string = `${payload.customerId}-${orderType === 'ORDER' ? 'Orders' : 'Quotes'}.zip`
					downloadFile(response, filename)
				})
		})
}

/**
 * Handles emailing a set of customer orders as a zip file.
 * @param action The action containing the customer orders to email.
 */
export function* handleEmailCustomerOrders(action: actions.EmailCustomerOrdersAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.emailCustomerOrders,
		(payload: actions.EmailCustomerOrdersPayload) => {
			const { customerId, orderType, type, orders, email } = payload
			return getOrderApi()
				.emailOrders({
					customerId,
					orderType,
					type,
					emailOrdersRequest: { orders, email },
				})
		})
}

export default function* (): SagaIterator {
	yield fork(downloadOrdersChannel)
	yield takeEvery(actions.fetchCustomerOrders.started, handleFetchCustomerOrders)
	yield takeEvery(actions.fetchCustomerOrder.started, handleFetchCustomerOrder)
	yield takeEvery(actions.downloadCustomerOrderAsZip.started, handleDownloadCustomerOrdersAsZip)
	yield takeEvery(actions.emailCustomerOrders.started, handleEmailCustomerOrders)
}