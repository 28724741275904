import { SagaIterator } from 'redux-saga'
import { call, takeEvery } from 'redux-saga/effects'

import { getAccounts, GetAccountsRequestSuccessAction, navigateToAddAccount } from '../../common/accounts/actions'
import { AccountStatus } from '../../common/accounts/types'
import * as NavigationManager from '../navigation/NavigationManager'

function* handleGetAccountsDone(action: GetAccountsRequestSuccessAction): SagaIterator {
	// if this action is coming from a login.done action we want to display the add account screen if the user has no accounts
	if (action.payload.params.fromLogin) {
		const { accounts: userAccounts } = action.payload.result
		// check if the user has an approved account
		const hasApprovedAccount: boolean = userAccounts ? userAccounts.some(account => account.registrationState === AccountStatus.Approved) : false
		if (hasApprovedAccount === false) {
			// navigate to add account screen
			yield call(handleNavigateToAddAccount)
		}
	}
}

function handleNavigateToAddAccount() {
	NavigationManager.navigateToAddAccount()
}

export default function* (): SagaIterator {
	yield takeEvery(getAccounts.done, handleGetAccountsDone)
	yield takeEvery(navigateToAddAccount, handleNavigateToAddAccount)
}