import { SagaIterator } from 'redux-saga'
import { takeEvery, select, call, put, takeLatest } from 'redux-saga/effects'
import { Action } from 'typescript-fsa'
import { Product, ProductPricesResponse, ProductList, ProductSku } from 'typescript-fetch-api'

import * as actions from './actions'
import * as mylistsActions from '../mylists/actions'
import * as labelsActions from '../labels/actions'
import { UserAccount } from '../accounts/types'
import { getPriceParams } from '../auth/functions'
import { getContentApi, getListApi, getPriceBookApi } from '../api'
import { callApi } from '../api/functions'
import { selectedAccountSelector } from '../order/selectors'
import { productSelector } from './selectors'
import { PlatformProduct } from '../../modules/platform'

function* checkProductInPriceBook(action: actions.CheckProductInPriceBookAction): SagaIterator {
	const { customerId, sku } = action.payload
	// @ts-ignore API
	yield call(callApi, action, actions.checkProductInPriceBook, () => {
		return getPriceBookApi().isProductInPriceBook({ customerId, sku })
			.then(response => response.inPriceBook || false)
	})
}

function* searchProductForBarcodeAPI(action: Action<string>): SagaIterator {
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)

	// @ts-ignore API
	yield call(callApi, action, actions.searchProductForBarcodeAPI, (barcode: string) => {
		return getContentApi().searchProductsByBarcode({ customerId, barcode, includePrices })
			.then((result: Product | undefined) => {
				if (!result) {
					// no product found, thrown error instead so the user can re-scan
					throw new Error('Product not found')
				}
				return result
			})
	})
}

function* loadProductPrices(action: Action<string>): SagaIterator {
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const customerId = selectedAccount && selectedAccount.customerId
	// @ts-ignore API
	yield call(callApi, action, actions.loadProductPrices, (sku: string) => {
		const products: ProductSku[] = [{ sku }]
		return getContentApi().getProductPrices({ productPriceRequestList: { products }, customerId })
			.then((result: ProductPricesResponse) => {
				if (result && result.prices) {
					return result.prices.find(item => item.sku === sku)
				}
				return undefined
			})
	})
}

function* loadProductPricesForBarcode(action: Action<string>): SagaIterator {
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const customerId = selectedAccount && selectedAccount.customerId
	// @ts-ignore API
	yield call(callApi, action, actions.loadProductPricesForBarcode, (sku: string) => {
		const products: ProductSku[] = [{ sku }]
		return getContentApi().getProductPrices({ productPriceRequestList: { products }, customerId })
			.then((result: ProductPricesResponse) => {
				if (result && result.prices) {
					return result.prices.find(item => item.sku === sku)
				}
				return undefined
			})
	})
}

/**
 * Handles adding or removing a product from the price book based on the sku.
 */
function* includeProductInPriceBook(action: actions.IncludeProductInPriceBookAction): SagaIterator {
	const account: UserAccount | undefined = yield select(selectedAccountSelector)

	// we only check for the account since we do the role checks in the component
	if (account) {
		const { sku, isIncluded } = action.payload

		if (isIncluded) {
			// @ts-ignore API
			yield call(callApi, action, actions.includeProductInPriceBook, () => {
				return getPriceBookApi().addProductToPriceBook({ customerId: account!.customerId, sku })
			})
		} else {
			// @ts-ignore API
			yield call(callApi, action, actions.includeProductInPriceBook, () => {
				return getPriceBookApi().removeProductFromPriceBook({ customerId: account!.customerId, sku })
			})
		}
	}
}

function* loadProductLists(action: actions.LoadProductListsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.loadProductLists, (payload: actions.LoadProductListsPayload) => {
		return getListApi().getListsForProduct({ sku: payload.productSku, customerId: payload.customerId })
			.then(result => {
				if (result.lists && result.lists.length > 0) {
					// merge all lists from the response
					return result.lists.reduce<ProductList[]>((data, parentList) => (
						parentList.lists ? [...data, ...parentList.lists] : data
					), [])
				}
				return []
			})
	})
}

/**
 * Handles reloading the current product's lists after successfully adding/removing it from a list
 * @param action the success action
 */
function* reloadProductListsOnUpdate(action: mylistsActions.AddProductToListSuccessAction | mylistsActions.RemoveProductFromListSuccessAction): SagaIterator {
	if (action.payload.params.screen === 'Product') {
		yield put(actions.loadProductLists.started({ productSku: action.payload.params.sku, customerId: action.payload.params.customerId }))
	}
}

/**
 * Handles reloading a product's lists on successful create/edit (only if the product is also included in the list items)
 * @param action the success action
 */
function* reloadProductListsOnCreateOrEdit(action: mylistsActions.CreateOrEditListActionSuccess): SagaIterator {
	const { params } = action.payload
	if (params.screen === 'Product') {
		// check if the user is viewing a product
		const selectedProduct: PlatformProduct | undefined = yield select(productSelector)
		if (selectedProduct) {
			// check if the product being viewed is included in the list items
			const isProductInList = params.list.productListItems ? params.list.productListItems.some(item => item.sku === selectedProduct.sku) : false
			if (isProductInList) {
				// reload the product lists so we display updated items on the UI
				yield put(actions.loadProductLists.started({ productSku: selectedProduct.sku, customerId: params.customerId }))
			}
		}
	}
}

/**
 * Handles reloading the current product's labels after successfully adding/removing it from a label group
 * @param action the success action
 */
function* reloadProductLabelsOnUpdate(action: labelsActions.AddProductToLabelGroupActionSuccessAction | labelsActions.RemoveProductFromLabelGroupSuccessAction): SagaIterator {
	if (action.payload.params.reloadProductLabels) {
		yield put(actions.loadProductLists.started({ productSku: action.payload.params.sku }))
	}
}

/**
 * Handles reloading the current product's labels after successfully adding it to a new label group
 * @param action the success action
 */
function* reloadProductLabelsOnCreate(action: labelsActions.CreateOrEditLabelsGroupActionSuccess): SagaIterator {
	if (action.payload.params.navigateToDetailsOnSuccess) {
		const list = action.payload.params.list
		if (list.productListItems && list.productListItems.length > 0) {
			const product = list.productListItems[0]
			yield put(actions.loadProductLists.started({ productSku: product.sku }))
		}
	}
}

function* handleGetBranchProductDetail(action: actions.GetProductAvailabilityAction) {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.getProductAvailability,
		(payload: actions.GetProductAvailabilityPayload) => {
			return getContentApi().getProductStock({ sku: payload.productSku, branchId: payload.branchId })
		},
		false
	)
}

function* handleGetBranchProductDetailForBarcode(action: actions.GetProductAvailabilityAction) {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.getProductAvailabilityForBarcode,
		(payload: actions.GetProductAvailabilityPayload) => {
			return getContentApi().getProductStock({ sku: payload.productSku, branchId: payload.branchId })
		},
		false
	)
}

function* handleGetBranchProductDetails(action: actions.GetBranchesAvailabilityAction) {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.getBranchesAvailability,
		(payload: actions.GetBranchesAvailabilityPayload) => {
			return getContentApi().getProductStocks({ sku: payload.productSku, productStocksRequest: { branches: payload.branchIds.map(id => { return { id } }) } })
		},
		false
	)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.checkProductInPriceBook.started, checkProductInPriceBook)
	yield takeEvery(actions.includeProductInPriceBook.started, includeProductInPriceBook)
	yield takeEvery(actions.searchProductForBarcodeAPI.started, searchProductForBarcodeAPI)
	yield takeEvery(actions.loadProductPrices.started, loadProductPrices)
	yield takeEvery(actions.loadProductPricesForBarcode.started, loadProductPricesForBarcode)
	yield takeEvery(actions.loadProductLists.started, loadProductLists)
	yield takeEvery(actions.getProductAvailability.started, handleGetBranchProductDetail)
	yield takeEvery(actions.getProductAvailabilityForBarcode.started, handleGetBranchProductDetailForBarcode)
	yield takeEvery(actions.getBranchesAvailability.started, handleGetBranchProductDetails)
	yield takeLatest([mylistsActions.addProductToList.done, mylistsActions.removeProductFromList.done], reloadProductListsOnUpdate)
	yield takeLatest(mylistsActions.createOrEditList.done, reloadProductListsOnCreateOrEdit)
	yield takeLatest([labelsActions.addProductToLabelGroup.done, labelsActions.removeProductFromLabelGroup.done], reloadProductLabelsOnUpdate)
	yield takeLatest(labelsActions.createOrEditLabelsGroup.done, reloadProductLabelsOnCreate)
}
