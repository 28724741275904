import { NavigateFunction, Location } from 'react-router-dom'
import { BackflowProductBrand, BackflowProductType, IntegrationCompany } from 'typescript-fetch-api'
import { OwnPropInfo as AccountListOwnProps } from '../../common/mylists/containers/AccountList'

import { CheckoutNavigationState, CheckoutPath, ConfirmNavigationState, OrderCompleteNavigationState } from '../cart/types'
import { CustomerOrderDetailsNavigationState } from '../customerorders/types'
import { FinancialTabOptions } from '../../common/financials/actions'
import { ChangePasswordNavigationState } from '../forgotpassword/types'
import { Partners } from '../integrations/types'
import { LabelOrderCompleteNavigationProps, NavigateToCreateLabelsState } from '../labels/types'
import { MobileVerificationLocationState } from '../mobileVerification/types'
import { NavigateToNewListState } from '../mylists/types'
import { TabOptions } from '../platform/actions'
import { Paths } from './types'
import { ProductFinderType } from '../../common/productfinder/types'

/**
 * NavigateFunction must be aquired from the useNavigation hook, so is set via NavigateSetter dummy component
 */
let navigate: NavigateFunction

export function setNavigateFunction(func: NavigateFunction) {
	navigate = func
}

let location: Location

export function setLocation(loc: Location) {
	location = loc
}

export function getLocation(): Location | undefined {
	return location
}

/* -------------------------------------------------------------------------- */
/*                              Screen Navigation                             */
/* -------------------------------------------------------------------------- */

export const navigateToHome = (): void => {
	navigate(Paths.HOME)
}

export const navigateToSignIn = (): void => {
	navigate(Paths.SIGN_IN)
}

/**
 * Products
 */

export const navigateToHnzCategory = (categoryId: string, categoryTitle: string): void => {
	const encodedCategoryTitle = encodeURIComponent(categoryTitle)
	navigate(`${Paths.PRODUCTS_HNZ}/${categoryId}/${encodedCategoryTitle}`)
}

export const navigateToHnzProducts = (): void => {
	navigate(Paths.PRODUCTS_HNZ)
}

export const navigateToProductDetails = (productSku: string, replace?: boolean): void => {
	const encodedProductSku = encodeURIComponent(productSku)
	navigate(Paths.PRODUCT_DETAILS.replace(':id', encodedProductSku), { replace })
}

export const navigateToProductViaParams = (productSku: string): void => {
	const url = location.pathname
	const encodedProductSku = encodeURIComponent(productSku)
	navigate(url + '?product=' + encodedProductSku)
}

export const navigateToProductSearch = (searchText?: string): void => {
	if (searchText) {
		const encodedSearchText = searchText && encodeURIComponent(searchText)
		navigate(Paths.SEARCH + '?q=' + encodedSearchText)
	} else {
		navigate(Paths.SEARCH)
	}
}

export const navigateToCategory = (url: string): void => {
	// most of the logic was in the saga so I left it there for now and just pass through the calculated url
	navigate(url)
}

export const navigateToProductFinder = (type: ProductFinderType): void => {
	switch (type) {
		case ProductFinderType.HWC:
			navigate(Paths.HWC_PRODUCT_FINDER)
			break
	}
}

export const navigateToProductFinderResults = (type: ProductFinderType): void => {
	navigate(Paths.PRODUCT_FINDER_RESULTS + '?type=' + type)
}

export const navigateToBackflowLanding = (): void => {
	navigate(Paths.BACKFLOW_LANDING)
}

export const navigateToBackflowBrand = (brand: BackflowProductBrand): void => {
	navigate(Paths.BACKFLOW_BRAND.replace(':id', brand))
}

export const navigateToBackflowType = (type: BackflowProductType): void => {
	navigate(Paths.BACKFLOW_PRODUCT_TYPE.replace(':id', type))
}

/**
 * Orders/Quotes
 */

export const navigateToCustomerOrders = (): void => {
	navigate(Paths.CUSTOMER_ORDERS)
}

export const navigateToInterimOrders = (): void => {
	navigate(Paths.INTERIM_ORDERS)
}

export const navigateToCustomerOrderDetails = (orderId: string, orderSuffix: string, state: CustomerOrderDetailsNavigationState): void => {
	navigate(Paths.CUSTOMER_ORDER_DETAILS.replace(':id', orderId).replace(':suffix', orderSuffix), { state })
}

export const navigateToInterimOrderDetails = (orderId: string, orderSuffix: string, state: CustomerOrderDetailsNavigationState): void => {
	navigate(Paths.INTERIM_ORDER_DETAILS.replace(':id', orderId).replace(':suffix', orderSuffix), { state })
}

export const navigateToCustomerQuotes = (): void => {
	navigate(Paths.CUSTOMER_QUOTES)
}

export const navigateToCustomerQuoteDetails = (orderId: string, orderSuffix: string, state: CustomerOrderDetailsNavigationState): void => {
	navigate(Paths.CUSTOMER_QUOTE_DETAILS.replace(':id', orderId).replace(':suffix', orderSuffix), { state })
}

export const navigateToCheckout = (): void => {
	navigate(Paths.CHECKOUT)
}

export const navigateToCheckoutWithAction = (action: CheckoutPath, state?: CheckoutNavigationState | ConfirmNavigationState | OrderCompleteNavigationState): void => {
	navigate(`${Paths.CHECKOUT}?action=${action}`, { state })
}

/**
 * Labels
 */

export const navigateToLabelOrders = (): void => {
	navigate(Paths.LABEL_ORDERS)
}

export const navigateToLabelOrderDetails = (orderId: string): void => {
	navigate(Paths.LABEL_ORDER_DETAILS.replace(':id', orderId))
}

export const navigateToLabelCheckout = (): void => {
	navigate(Paths.LABELS_CHECKOUT)
}

export const navigateToLabelCheckoutWithAction = (action: CheckoutPath, state?: LabelOrderCompleteNavigationProps): void => {
	navigate(`${Paths.LABELS_CHECKOUT}?action=${action}`, { state })
}

export const navigateToLabels = (state?: NavigateToCreateLabelsState): void => {
	navigate(Paths.LABELS, { state })
}

export const navigateToLabelGroup = (listId: string): void => {
	navigate(Paths.LABEL_DETAILS.replace(':id', listId))
}

/**
 * Lists
 */

export const navigateToLists = (state?: NavigateToNewListState): void => {
	navigate(Paths.LISTS, { state })
}

export const navigateToListGroup = (listGroupId: string, state: AccountListOwnProps): void => {
	navigate(Paths.LIST_GROUP.replace(':id', listGroupId), { state })
}

export const navigateToListDetails = (listGroupId: string, listId: string): void => {
	const encodedListId = encodeURIComponent(listId)
	navigate(Paths.LIST_DETAILS.replace(':id', listGroupId).replace(':listId', encodedListId))
}

/**
 * User
 */

export const navigateToAddAccount = (): void => {
	navigate(Paths.ADD_ACCOUNT)
}

export const navigateToRegister = (): void => {
	navigate(Paths.REGISTER)
}

export const navigateToResetPassword = (): void => {
	navigate(Paths.RESET_PASSWORD)
}

export const navigateToVerifyAccount = (): void => {
	navigate(Paths.VERIFY_ACCOUNT)
}

export const navigateToConfirmResetPassword = (state: ChangePasswordNavigationState): void => {
	navigate(Paths.CONFIRM_RESET_PASSWORD, { state })
}

export const navigateToConfirmPhone = (state: MobileVerificationLocationState): void => {
	navigate(Paths.REGISTER_CONFIRM_PHONE, { state })
}

/**
 * General
 */

export const navigateToTab = (tab: TabOptions, userId?: number): void => {
	if (tab === 'home') {
		navigate(Paths.HOME)
	} else if (tab === 'profile') {
		// distinguishes whether we display the basic or editable user profile screen
		const profilePath: string = userId ? `/${tab}/${userId}` : `/${tab}`
		// saves the current location when the profile tab was clicked.
		// this is to preserve the previous location while the profile modal/dialog is showing.
		const state = { background: location }
		navigate(profilePath, { state })
	} else {
		navigate('/' + tab)
	}
}

export const navigateToVersionUpdates = (): void => {
	navigate(Paths.VERSION_UPDATES)
}

export const navigateToTermsAndConditions = (): void => {
	navigate(Paths.TERMS_AND_CONDITIONS)
}

export const navigateToPrivacyPolicy = (): void => {
	navigate(Paths.PRIVACY_POLICY)
}

export const navigateToTermsOfTrade = (): void => {
	navigate(Paths.TERMS_OF_TRADE)
}

export const navigateToContact = (): void => {
	navigate(Paths.CONTACT)
}

export const navigateToRegions = (): void => {
	navigate(Paths.REGIONS)
}

export const navigateToArofloCheckoutLoadingScreen = (): void => {
	navigate(Paths.AROFLO_PUNCHOUT + '?action=checkout')
}

/**
 * Admin
 */

export const navigateToFinancialTab = (tab: FinancialTabOptions): void => {
	navigate('/' + Paths.FINANCIALS_RELATIVE + '/' + tab)
}

export const navigateToPriceBook = (): void => {
	navigate(Paths.ADMIN_PRICE_BOOK)
}

export const navigateToIntegration = (type?: IntegrationCompany): void => {
	let url
	if (type === IntegrationCompany.AROFLO) {
		url = Paths.ADMIN_INTEGRATIONS_AROFLO
	} else if (type === IntegrationCompany.FERGUS) {
		url = Paths.ADMIN_INTEGRATIONS_FERGUS
	} else if (type === IntegrationCompany.SIMPRO) {
		url = Paths.ADMIN_INTEGRATIONS_SIMPRO
	} else if (type === IntegrationCompany.XERO) {
		url = Paths.ADMIN_INTEGRATIONS_XERO
	} else if (type === IntegrationCompany.ITRADE) {
		url = Paths.ADMIN_INTEGRATIONS_ITRADE
	} else {
		url = Paths.ADMIN_INTEGRATIONS
	}
	navigate(url)
}

export const navigateToPartner = (partner: Partners): void => {
	if (partner === Partners.SIMPRO) {
		navigate(Paths.SIMPRO)
	} else if (partner === Partners.XERO) {
		navigate(Paths.XERO)
	}
}