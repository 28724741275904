import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { RootStoreState } from '../../root'
import * as actions from '../actions'
import { Branch } from '../../showrooms/types'
import { getSortedData } from '../../util/functions'

export interface Props {
	loading: boolean
	loadingMessage?: string
	error?: Error
	accountAddedSuccessfully: boolean
	branches: Branch[]
}

export interface Actions {
	onAddAccount: (nickname: string, customerId?: number, branchId?: number) => void
	onSuccess: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	// combines all the branches in each region to a single array
	const combinedBranches: Branch[] = state.showrooms.regions.reduce<Branch[]>((data, region) => data.concat(region.branches), [])
	// sorts the branches alphabetically according to name
	const branches: Branch[] = getSortedData(combinedBranches, 'name')

	return {
		loading: state.accounts.loadingMessageAddAccount !== undefined,
		loadingMessage: state.accounts.loadingMessageAddAccount,
		error: state.accounts.errorAddAccount,
		accountAddedSuccessfully: state.accounts.successAddAccount,
		branches,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onAddAccount: (nickname: string, customerId?: number, branchId?: number) => {
		dispatch(actions.addAccount.started({ nickname, customerId, branchId }))
	},
	onSuccess: () => {
		dispatch(actions.addAccountSuccess())
	},
})

// @ts-ignore - generic type not being recognised after wrapping inside connect
export default <T>(DefaultComponent: React.ComponentType<T & Props & Actions>) => connect(mapStateToProps, mapDispatchToProps)(DefaultComponent)
