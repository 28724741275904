import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider, createTheme, Theme } from '@mui/material'
import 'firebase/analytics'
import 'firebase/remote-config'

import { store } from './common/root'
import ReadyGate from './common/offline/containers/ReadyGate'
import { theme } from './modules/platform/muiTheme'
import { setupFirebase } from './modules/platform/functions'

const muiTheme: Theme = createTheme(theme)
export const firebase = setupFirebase()

class App extends React.PureComponent {
	render() {
		return (
			<Provider store={store}>
				<ThemeProvider theme={muiTheme}>
					<ReadyGate />
				</ThemeProvider>
			</Provider>
		)
	}
}

export default App