import '../styles/GoldProductLogo.css'

import { Paper, Tooltip } from '@mui/material'

import { GoldProductLogo as GoldProductLogoImage } from '../../../utils/imagesProvider'

type Props = {
	elevation?: number
	className?: string
}

const GoldProductLogo = (props: Props): JSX.Element => {
	return (
		<Paper
			className={`gold-product ${props.elevation && 'gold-product-paper'} ${props.className}`}
			elevation={props.elevation || 0}
		>
			<Tooltip title="Gold Product">
				<img src={GoldProductLogoImage} alt="Gold Product Logo" className="gold-product-logo" />
			</Tooltip>
		</Paper>
	)
}

export default GoldProductLogo
