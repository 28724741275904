import { useSelector } from 'react-redux'

import { RootStoreState } from '../../root'
import { DEFAULT_LIST_CATEGORY } from '../content'
import { ListCategory } from '../types'
import { AccountStatus, UserAccount } from '../../accounts/types'
import { isAdminOrPrincipal } from '../functions'

interface Props {
	hnzMode?: boolean
	hnzOnly?: boolean
	showExistingLists?: boolean  // flag to show list categories that have lists (for adding to an existing list)
}

/**
 * A hook that returns the list categories based on the accounts in the store.
 * 
 * @param props the props for the hooks
 * @returns the list categories
 */
const useGetListCategories = (props: Props): ListCategory[] => {
	const listCategories = useSelector<RootStoreState, ListCategory[]>(state => {
		let items: ListCategory[] = [DEFAULT_LIST_CATEGORY]

		let accounts: UserAccount[] = []
		if (props.hnzMode) {
			// in hnz mode, only display the current account selected
			// NOTE: we bang this since `hnzMode` only gets enabled if an account has been previously selected
			const selectedAccount = state.order.selectedAccount!
			accounts.push(selectedAccount)
		} else if (state.accounts.accounts) {
			// only allow users to add lists to accounts they have Admin/Principal roles for
			accounts = state.accounts.accounts.filter(account => (
				account.registrationState === AccountStatus.Approved && isAdminOrPrincipal(account)
			))

			if (props.hnzOnly) {
				// filter hnz accounts - used for adding hnz only products
				accounts = accounts.filter(account => !!account.hnz)
			}
		}

		if (props.showExistingLists) {
			// filter the accounts again based on existing lists
			accounts = accounts.filter(account => state.lists.lists.some(list => list.prostix_account_id && list.prostix_account_id === account.customerId))
		}

		const mappedAccounts = accounts.map<ListCategory>(account => ({ customerId: account.customerId, name: account.name, hnz: account.hnz }))
		items = [...items, ...mappedAccounts]

		return items
	})

	return listCategories
}

export default useGetListCategories
