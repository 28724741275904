import { IntegrationDetail } from './types'
import * as images from '../../utils/imagesProvider'

export const XERO_ACCOUNTING_SOFTWARE = 'https://www.xero.com/nz/'
export const XERO_FEATURES = 'https://www.xero.com/nz/accounting-software/'
export const XERO_PRICING = 'https://www.xero.com/nz/pricing-plans/'
export const XERO_SIGNUP = 'https://www.xero.com/signup/'
export const XERO_XTID_PWGO = 'x30plumbingworld'
export const XERO_SIGNUP_WITH_XTID = `${XERO_SIGNUP}?xtid=${XERO_XTID_PWGO}`

export const XERO_INTEGRATION_DETAILS: IntegrationDetail[] = [
	{
		id: 0,
		title: 'Integrate invoices',
		description: 'pwGO is integrated with Xero to send invoices to a nominated supplier. Further improve your workflow by connecting with Xero.',
		icon: 'icon-compare_arrows',
	},
	{
		id: 1,
		title: 'Invoices sync every hour',
		description: 'Once connected, pwGO invoices are automatically imported into Xero every hour.',
		icon: 'icon-sync',
	},
	{
		id: 2,
		title: 'Copy of invoice appears in Xero as an attachment',
		description: 'Easily view Plumbing World invoices in Xero as a PDF attachment.',
		icon: 'icon-attach_file',
	},
]

export const XERO_DETAILS: IntegrationDetail[] = [
	{
		id: 0,
		title: 'Get A Real-Time View Of Your Cashflow',
		description: 'Log in anytime, anywhere on your Mac, PC, tablet of phone to get a real-time view of your cash flow. It’s small business accounting software that’s simple, smart and occasionally magical.',
		icon: 'icon-bullet',
	},
	{
		id: 1,
		title: 'Run Your Business On The Go',
		description: 'Use our mobile app to reconcile, send invoices, or create expense claims - from anywhere.',
		icon: 'icon-bullet',
	},
	{
		id: 2,
		title: 'Get Paid Faster With Online Invoicing',
		description: 'Send online invoices to your customers - and get updated when they’re opened.',
		icon: 'icon-bullet',
	},
	{
		id: 3,
		title: 'Reconcile In Seconds',
		description: 'Xero imports and categorises your latest bank transactions. Just click ok to reconcile.',
		icon: 'icon-bullet',
	},
]

export const SIMPRO = 'https://www.simprogroup.com/nz'
export const SIMPRO_CONTACT_US = 'https://www.simprogroup.com/nz/contact-us'
export const SIMPRO_LOGIN = 'https://login.simprosuite.com/'

export const SIMPRO_DETAILS: IntegrationDetail[] = [
	{
		id: 0,
		title: 'Inventory Management',
		description: 'Streamline inventory tracking and connect materials to your jobs without manual data entry.',
		icon: images.IconSimproInventory,
	},
	{
		id: 1,
		title: 'Invoicing and Payments',
		description: 'Send invoices on time, give customers payment flexibility and increase cash flow.',
		icon: images.IconSimproInvoice,
	},
	{
		id: 2,
		title: 'Accounting Integration',
		description: 'Integrate directly with accounting software.',
		icon: images.IconSimproAccounting,
	},
	{
		id: 3,
		title: 'Mobile',
		description: 'View all the information you need for the job in one mobile app.',
		icon: images.IconSimproMobile,
	},
	{
		id: 4,
		title: 'Estimating and Quoting',
		description: 'Use digital take off templates to build estimates and convert quotes to jobs in seconds.',
		icon: images.IconSimproEstimate,
	},
	{
		id: 5,
		title: 'Scheduling and Dispatching',
		description: 'Schedule individuals or teams based on availability and job status from a digital dashboard.',
		icon: images.IconSimproSchedule,
	},
]

export const AROFLO_ONBOARDING = 'https://images2.plumbingworld.co.nz/Forms/AroFlo%20Invoice%20Integration%20Onboarding%20Guide.pdf'
export const AROFLO_LOGIN = 'https://office.aroflo.com/'
export const AROFLO_CATALOGUES = 'https://help.aroflo.com/display/SiteAdmin/Supplier+Online+Catalogues'

export const ITRADE_ONBOARDING = 'https://images2.plumbingworld.co.nz/Forms/iTrade%20Invoice%20Integration%20Onboarding%20Guide.pdf'
export const ITRADE_LOGIN = 'https://login.itrade.net/auth/login'

export const SIMPRO_ONBOARDING = 'https://images2.plumbingworld.co.nz/Forms/simPRO%20Integration%20Guide.pdf'