import { CreateLabelOrderDespatchMethodEnum, CreateOrderDespatchMethodEnum, LabelOrderDespatchMethodEnum, OrderDespatchMethodEnum, SimproJob } from 'typescript-fetch-api'

import { OrderItem } from '../order/types'
import { ProductAvailability } from './types'
import { QUANTITY_DECIMALS_NOT_ALLOWED } from './content'

export const filterOrderItemsByStockAvailability = (items: OrderItem[], type: ProductAvailability): OrderItem[] => {
	const filteredItems: OrderItem[] = []
	for (const item of items) {
		const availability = getStockAvailabilityTypeForItem(item.quantity, item.product.quantityAvailable)
		if (availability === type) {
			filteredItems.push(item)
		}
	}
	return filteredItems
}

export const getStockAvailabilityTypeForItem = (quantity: number, quantityAvailable?: number): ProductAvailability => {
	if (quantityAvailable === undefined || quantityAvailable === 0) return ProductAvailability.AVAILABLE_TO_ORDER
	if (quantityAvailable - quantity < 0 || quantityAvailable < 0) return ProductAvailability.LIMITED_STOCK
	return ProductAvailability.IN_STOCK
}

/**
 * Handles getting dummy data for the stock count and quantity to populate on the @see StockAvailabilityLogo.tsx
 * @param productAvailability the product availability
 * @returns the stock count and quantity
 */
export const getDummyDataForProductAvailability = (productAvailability: ProductAvailability): { stockCount: number, quantity: number } => {
	let stockCount = 0
	let quantity = 0
	if (productAvailability === ProductAvailability.IN_STOCK) {
		stockCount = 2
		quantity = 1
	} else if (productAvailability === ProductAvailability.LIMITED_STOCK) {
		stockCount = 1
		quantity = 2
	}
	return { stockCount, quantity }
}

export const getDespatchMethodLabel = (despatchMethod: OrderDespatchMethodEnum | LabelOrderDespatchMethodEnum | CreateOrderDespatchMethodEnum | CreateLabelOrderDespatchMethodEnum): string => {
	switch (despatchMethod) {
		case OrderDespatchMethodEnum.CPU:
		case LabelOrderDespatchMethodEnum.CPU:
		case CreateOrderDespatchMethodEnum.CPU:
		case CreateLabelOrderDespatchMethodEnum.CPU:
			return 'Click & Collect'
		case OrderDespatchMethodEnum.DIR_D:
		case LabelOrderDespatchMethodEnum.DIR_D:
		case CreateOrderDespatchMethodEnum.DIR_D:
		case CreateLabelOrderDespatchMethodEnum.DIR_D:
			return 'Delivery'
		case OrderDespatchMethodEnum.IN_STORE:
		case CreateOrderDespatchMethodEnum.IN_STORE:
			return 'In-Store'
	}
}

/**
 * Handles validation and checks for an updated quantity with respect to the list of non-allowed decimals.
 * 
 * @param quantity the quantity to check
 * @param uom the uom
 * @param isBranchDynamics whether the currently selected branch is a dynamics branch
 * @returns the updated quantity
 */
export const getUpdatedQuantityWithDecimalCheck = (quantity: string, uom: string, isBranchDynamics: boolean): string => {
	let hasSpecialChars
	// check if decimal places are allowed (if uom is not in the list of non-allowed decimals or if it's a dynamics branch and uom is 'ea')
	let isDecimalAllowed = uom && (!QUANTITY_DECIMALS_NOT_ALLOWED.some(item => item === uom) || (isBranchDynamics && uom === 'ea'))
	if (isDecimalAllowed) {
		hasSpecialChars = !RegExp('^[0-9]+.?[0-9]*|.[0-9]+$').test(quantity)
	} else {
		hasSpecialChars = !RegExp('^[0-9]*$').test(quantity) || quantity === '0' // don't allow 0 for nondecimal numbers
	}
	return hasSpecialChars ? '' : quantity
}

/**
 * Handles checking and formatting a quantity with respect to the list of non-allowed decimals.
 * 
 * @param quantity the quantity to format and check
 * @param uom the uom
 * @returns the formatted quantity
 */
export const formatQuantityWithDecimalCheck = (quantity: string, uom: string): number => {
	if (quantity.includes('.')) {
		const parsedQuantity = parseFloat(quantity).toFixed(2)
		return parseFloat(parsedQuantity)
	}
	return parseInt(quantity, 10)
}

/**
 * Handles filtering the simpro jobs based on the search query.
 * Used on the simpro checkout.
 * 
 * @param query the search query
 * @param simproJobs the initial simpro jobs set
 * @returns the filtered simpro jobs
 */
export const filterSimproJobs = (query: string, simproJobs: SimproJob[]): SimproJob[] => {
	const searchQuery = query.toUpperCase()
	return simproJobs.filter(job => {
		if (job.id.includes(searchQuery) || (job.name && job.name.toUpperCase().includes(searchQuery))) return true
		if (job.site && job.site.name && job.site.name.toUpperCase().includes(searchQuery)) return true
		if (job.technicians && job.technicians.some(technician => technician.name && technician.name.toUpperCase().includes(searchQuery))) return true
		return false
	})
}