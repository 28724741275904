import '../styles/MenuDrawer.scss'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Drawer } from '@mui/material'
import { AccountPermission } from 'typescript-fetch-api'

import { RootStoreState } from '../../../common/root'
import { UserAccount } from '../../../common/accounts/types'
import { logoutRequest } from '../../../common/auth/actions'
import { OrderItem } from '../../../common/order/types'
import { isEnabledRemoteConfig } from '../../../common/remoteConfig/functions'
import { RemoteConfig } from '../../../common/remoteConfig/types'
import CategoryMenu from '../../products/containers/CategoryMenu'
import ListMenu from '../../mylists/containers/ListMenu'
import LabelsMenuPopper from '../../labels/components/LabelsMenuPopper'
import CustomerOrdersMenu from '../../customerorders/containers/CustomerOrdersMenu'
import QuotesMenuPopper from '../../customerorders/components/QuotesMenuPopper'
import FinancialsMenu from '../../financials/containers/FinancialsMenu'
import AdminMenuPopper from '../../admin/components/AdminMenuPopper'
import SettingsMenuPopper from './SettingsMenuPopper'
import ClearCartDialog from '../../cart/components/ClearCartDialog'
import useWindowDimensions from '../../../common-ui/utils/hooks/useWindowDimensions'
import { getShareholdingToken } from '../../auth/actions'
import { WebStoreState } from '../types'
import { getIsAroFloProcurementUser } from '../../../common/auth/functions'
import useGetBranchForId from '../../../common/showrooms/hooks/useGetBranchForId'

enum MenuItemId {
	PRODUCTS, LISTS, LABELS, ORDERS, QUOTES, FINANCIALS, CART, ADMIN, SETTINGS, SIGNOUT
}

interface Props {
	isDrawerVisible: boolean
	onHideDrawer: () => void
	onShowAccountsDrawer: () => void
	onShowBranchDrawer: () => void
	onShowProfileDrawer: () => void
	onShowCartDrawer: () => void
}

const MenuDrawer = ({ isDrawerVisible, onHideDrawer, onShowAccountsDrawer, onShowBranchDrawer, onShowProfileDrawer, onShowCartDrawer }: Props): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()
	const { isSmallScreen, height } = useWindowDimensions()

	/**
	* Store State
	*/

	const selectedAccount = useSelector<RootStoreState, UserAccount | undefined>(state => state.order.selectedAccount)
	const currentUsername = useSelector<RootStoreState, string>(state => `${state.auth.firstName} ${state.auth.lastName}`)
	const isAroFloProcurementUser = useSelector<RootStoreState, boolean>(state => getIsAroFloProcurementUser(state.auth.username))
	const loggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.authToken)
	const orderItems = useSelector<RootStoreState, OrderItem[]>(state => state.order.orderItems)
	const isLabelsEnabled = useSelector<RootStoreState, boolean>(state => isEnabledRemoteConfig(RemoteConfig.LABELS, state))
	const branchId = useSelector<RootStoreState, number>(state => state.order.branchId !== undefined ? parseInt(state.order.branchId) : -1)
	const selectedBranch = useGetBranchForId(branchId)
	const hasShareholdingAccess = useSelector<WebStoreState, boolean>(state => state.platform.accounts.length > 0 && !state.auth.authToken?.impersonator_username)

	/**
	* Local State
	*/

	const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null)
	const [lockedAnchorElement, setLockedAnchorElement] = useState<HTMLDivElement | null>(null)
	const [showClearCartDialog, setShowClearCartDialog] = useState<boolean>(false)

	let canViewAdmin = false
	let canViewFinancials = false
	if (selectedAccount && selectedAccount.permissions && selectedAccount.permissions.length > 0) {
		canViewAdmin = selectedAccount.permissions.some(item => item === AccountPermission.VIEW_TRADE_ACCOUNT_USER) ? true : false
		canViewFinancials = selectedAccount.permissions.some(item => item === AccountPermission.VIEW_STATEMENTS || item === AccountPermission.VIEW_INVOICES) ? true : false
	}
	const isHnzAccountSelected = !!selectedAccount && !!selectedAccount.hnz

	// calculate if menu content not visible without scrolling, and hide the footer items (we try to make all items visible if possible, tiny screens will still need content to be scrolled)
	const rowHeight = 56 + 1 // divider height
	const numItems = 7 + (isLabelsEnabled ? 1 : 0) + (canViewFinancials ? 1 : 0) + (canViewAdmin ? 1 : 0)
	const showFooter: boolean = height - (110) - (numItems * rowHeight) - ((hasShareholdingAccess ? 4 : 3) * rowHeight - 1) >= 0 // header - content - footer

	/**
	 * Effects / Subscriptions
	 */

	useEffect(() => {
		if (!isDrawerVisible) {
			// if hiding the drawer, clear out anchor element
			setAnchorElement(null)
			setLockedAnchorElement(null)
		}
	}, [isDrawerVisible])

	/**
	* Local Functions
	*/

	const _onLogout = () => {
		if (orderItems.length > 0) {
			setShowClearCartDialog(true)
		} else {
			_onConfirmLogout()
		}
	}

	const _onConfirmLogout = () => {
		dispatch(logoutRequest({}))
		_onHideClearCartDialog()
	}

	const _onHideClearCartDialog = () => {
		setShowClearCartDialog(false)
	}

	const _onRowClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const target: HTMLDivElement = e.currentTarget
		if (lockedAnchorElement?.id === target.id) {
			setLockedAnchorElement(null)
		} else {
			setLockedAnchorElement(target)
		}
	}

	const _onMouseOver = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const target: HTMLDivElement = e.currentTarget
		if (anchorElement?.id !== target.id) {
			setAnchorElement(target)
		}
	}

	const _onCloseSubmenu = () => {
		setLockedAnchorElement(null)
		setAnchorElement(null)
	}

	const _goToShareholdingSite = () => {
		dispatch(getShareholdingToken.started())
	}

	/**
	 * Render
	 */

	const renderItem = (id: MenuItemId, title: string, icon: string, onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) => {
		// if there is a locked item, compare id with that
		const isChecked: boolean = lockedAnchorElement ? lockedAnchorElement.id === id.toString() : anchorElement?.id === id.toString()
		return (
			<React.Fragment>
				<div key={id} id={id.toString()} className={`menu-drawer__item ${isChecked && 'menu-drawer__item--checked'}`} onClick={onClick} onMouseEnter={!isSmallScreen ? _onMouseOver : undefined}>
					<span className={`menu-drawer__item--icon ${icon} ${isChecked && 'menu-drawer__item--icon__checked'}`} />
					<span className={`menu-drawer__item--title ${isChecked && 'menu-drawer__item--title__checked'}`}>{title}</span>
					<span className={`menu-drawer__item--arrow icon-keyboard_arrow_right ${isChecked && 'menu-drawer__item--arrow__checked'}`} />
				</div>
				<Divider className="menu-drawer__footer--divider" />
			</React.Fragment>
		)
	}

	const renderCartItem = (id: MenuItemId, title: string, icon: string, action: () => void) => {
		const itemCount = orderItems.reduce<number>((data, item) => data + item.quantity, 0)
		return (
			<React.Fragment>
				<div key={id} className="menu-drawer__item menu-drawer__item--cart" onClick={action} onMouseEnter={!isSmallScreen ? _onMouseOver : undefined}>
					<span className={`menu-drawer__item--icon ${icon}`} />
					<span className="menu-drawer__item--title">{title}</span>
					<span className="menu-drawer__item--subtitle menu-drawer__item--subtitle__bold">{orderItems.length} Product{orderItems.length === 1 ? '' : 's'} | {itemCount} Item{itemCount === 1 ? '' : 's'}</span>
					<span className="menu-drawer__item--arrow icon-keyboard_arrow_right" />
				</div>
				<Divider className="menu-drawer__footer--divider" />
			</React.Fragment>
		)
	}


	const renderPopperMenu = (): JSX.Element | null => {
		// if there is a locked item, use that, otherwise try using temp anchor (from mouse over)
		const id = lockedAnchorElement?.id || anchorElement?.id
		let Menu
		switch (id) {
			case MenuItemId.PRODUCTS.toString():
				Menu = CategoryMenu
				break
			case MenuItemId.LISTS.toString():
				Menu = ListMenu
				break
			case MenuItemId.LABELS.toString():
				Menu = LabelsMenuPopper
				break
			case MenuItemId.ORDERS.toString():
				Menu = CustomerOrdersMenu
				break
			case MenuItemId.QUOTES.toString():
				Menu = QuotesMenuPopper
				break
			case MenuItemId.FINANCIALS.toString():
				Menu = FinancialsMenu
				break
			case MenuItemId.ADMIN.toString():
				Menu = AdminMenuPopper
				break
			case MenuItemId.SETTINGS.toString():
				Menu = SettingsMenuPopper
				break
			default:
				return null
		}

		return <Menu anchorElement={lockedAnchorElement || anchorElement} onClickAway={onHideDrawer} isHnzAccount={isHnzAccountSelected} isSmallScreen={isSmallScreen} onCloseSubmenu={_onCloseSubmenu} />
	}

	return (
		<Drawer
			open={isDrawerVisible}
			onClose={onHideDrawer}
			anchor="left"
			className="menu-drawer"
		>
			<div className="menu-drawer__close--container">
				<span className="icon-close-round menu-drawer__close--icon" onClick={onHideDrawer} />
			</div>

			<div className="menu-drawer__content">
				<div className="menu-drawer__header" id='HEADER'>
					<span className="menu-drawer__header--text">Menu</span>
				</div>

				{/* on small screens the popper menu replaces drawer content */}
				{isSmallScreen && (lockedAnchorElement || anchorElement) ? null :
					<div className="menu-drawer__list">
						{renderItem(MenuItemId.PRODUCTS, 'Products', 'icon-grid', _onRowClicked)}
						{renderItem(MenuItemId.LISTS, 'Lists', 'icon-format_list_bulleted', _onRowClicked)}
						{isLabelsEnabled && !isAroFloProcurementUser && renderItem(MenuItemId.LABELS, 'Labels', 'icon-label', _onRowClicked)}
						{!isAroFloProcurementUser && renderItem(MenuItemId.ORDERS, 'Orders', 'icon-custom-lists', _onRowClicked)}
						{!isAroFloProcurementUser && renderItem(MenuItemId.QUOTES, 'Quotes', 'icon-quote', _onRowClicked)}
						{canViewFinancials && !isAroFloProcurementUser && renderItem(MenuItemId.FINANCIALS, 'Financials', 'icon-invoice', _onRowClicked)}
						{renderCartItem(MenuItemId.CART, 'Cart', 'icon-shopping-cart', onShowCartDrawer)}
						{canViewAdmin && !isAroFloProcurementUser && renderItem(MenuItemId.ADMIN, 'Admin', 'icon-admin', _onRowClicked)}
						{!isAroFloProcurementUser && renderItem(MenuItemId.SETTINGS, 'Settings', 'icon-admin-settings', _onRowClicked)}
						{renderItem(MenuItemId.SIGNOUT, 'Sign Out', 'icon-lists', _onLogout)}
					</div>
				}

				{renderPopperMenu()}

				{loggedIn && showFooter && !isAroFloProcurementUser &&
					<div className="menu-drawer__footer">
						{hasShareholdingAccess &&
							<React.Fragment>
								<div className="menu-drawer__item menu-drawer__footer--row-nzpm" onClick={_goToShareholdingSite} onMouseEnter={_onCloseSubmenu}>
									<span />
									<div className="menu-drawer__footer">
										<span className="menu-drawer__footer--title">myNZPM</span>
										<span className="menu-drawer__footer--subtitle">Graded Cash &amp; Annual Statements</span>
									</div>
									<span className="menu-drawer__footer--arrow icon-keyboard_arrow_right" />
								</div>
								<Divider className="menu-drawer__footer--divider" />
							</React.Fragment>
						}
						<div className="menu-drawer__item menu-drawer__footer--row" onClick={onShowAccountsDrawer} onMouseEnter={_onCloseSubmenu}>
							<span className={`${isHnzAccountSelected ? 'icon-kainga-ora-simple' : 'icon-user-circle-outline'} menu-drawer__footer--icon`} />
							<div className="menu-drawer__footer">
								<span className="menu-drawer__footer--title">{selectedAccount?.name}</span>
								<span className="menu-drawer__footer--subtitle">{selectedAccount?.customerId}   {selectedAccount?.accountRoleName}</span>
							</div>
							<span className="menu-drawer__footer--arrow icon-keyboard_arrow_right" />
						</div>
						<Divider className="menu-drawer__footer--divider" />

						<div className="menu-drawer__item menu-drawer__footer--row" onClick={onShowBranchDrawer} onMouseEnter={_onCloseSubmenu}>
							<span className="icon-shop menu-drawer__footer--icon" />
							<div className="menu-drawer__footer">
								<span className="menu-drawer__footer--title">{selectedBranch?.name}</span>
								<span className="menu-drawer__footer--subtitle">Change Branch</span>
							</div>
							<span className="menu-drawer__footer--arrow icon-keyboard_arrow_right" />
						</div>
						<Divider className="menu-drawer__footer--divider" />

						<div className="menu-drawer__item menu-drawer__footer--row" onClick={onShowProfileDrawer} onMouseEnter={_onCloseSubmenu}>
							<span className="icon-user menu-drawer__footer--icon" />
							<div className="menu-drawer__footer">
								<span className="menu-drawer__footer--title">{currentUsername}</span>
								<span className="menu-drawer__footer--subtitle">Edit your Profile and Account</span>
							</div>
							<span className="menu-drawer__footer--arrow icon-keyboard_arrow_right" />
						</div>
					</div>
				}

				{loggedIn && isAroFloProcurementUser && (
					<div className="menu-drawer__item menu-drawer__footer--row" onClick={onShowBranchDrawer} onMouseEnter={_onCloseSubmenu}>
						<span className="icon-shop menu-drawer__footer--icon" />
						<div className="menu-drawer__footer">
							<span className="menu-drawer__footer--title">{selectedBranch?.name}</span>
							<span className="menu-drawer__footer--subtitle">Change Branch</span>
						</div>
						<span className="icon-keyboard_arrow_right" />
					</div>
				)}
			</div>

			{showClearCartDialog &&
				<ClearCartDialog
					origin="signout"
					itemCount={orderItems.length}
					onClose={_onHideClearCartDialog}
					onClearAll={_onConfirmLogout}
				/>
			}
		</Drawer>
	)
}

export default MenuDrawer