import { useLocation } from 'react-router-dom'

import Header from './Header'
import { Paths } from '../../navigation/types'

const HeaderNavigator = (): JSX.Element => {
	const location = useLocation()

	// needed a way to update the header when the user navigates away from the AroFlo sign in page
	const isAroFloSignInPage = location.pathname.indexOf(Paths.AROFLO_PUNCHOUT) > -1

	return <Header isAroFloSignInPage={isAroFloSignInPage} />
}

export default HeaderNavigator