import '../../cart/styles/PriceSummary.scss'

import { LabelOrderItem } from 'typescript-fetch-api'

type Props = {
	orderItems: LabelOrderItem[]
}

const LabelCartCountSummary = (props: Props): JSX.Element => {

	const totalLabels = props.orderItems.reduce((data, order) => data + order.quantity, 0)

	return (
		<div className="price-summary">
			<div className="price-summary__content">
				<span className="price-summary__heading">Item Count</span>
				<span className="price-summary__description">{props.orderItems.length}</span>
			</div>

			<div className="price-summary__content">
				<span className="price-summary__heading">TOTAL LABELS</span>
				<span className="price-summary__description price-summary__description--total">{totalLabels}</span>
			</div>
		</div>
	)
}

export default LabelCartCountSummary
