import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'

import { register, RegisterRequestSuccessAction } from '../../common/register/actions'
import { MobileVerificationLocationState } from '../mobileVerification/types'
import * as NavigationManager from '../navigation/NavigationManager'

function handleNavigateToVerifyCode(action: RegisterRequestSuccessAction) {
	const mobileNumber = action.payload.params.registerRequest.mobileNumber

	// we only navigate to verify code for PHONE as with email the user will be sent a url link to follow.
	// paths/routes to redirect to would either be the MobileVerification or Change Password screens.
	if (mobileNumber) {
		const state: MobileVerificationLocationState = {
			mobileNumber,
			password: action.payload.params.registerRequest.password
		}
		NavigationManager.navigateToConfirmPhone(state)
	}
}

export default function* (): SagaIterator {
	yield takeEvery(register.done, handleNavigateToVerifyCode)
}