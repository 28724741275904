import { connect } from 'react-redux'

import Component from '../components/Root'
import { WebStoreState } from '../types'
import { isEnabledRemoteConfig } from '../../../common/remoteConfig/functions'
import { RemoteConfig } from '../../../common/remoteConfig/types'

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	isLoggedIn: boolean
	isLabelsEnabled: boolean
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: WebStoreState): Props => {
	return {
		isLoggedIn: !!state.auth.authToken,
		isLabelsEnabled: isEnabledRemoteConfig(RemoteConfig.LABELS, state),
	}
}

export default connect(mapStateToProps, {})(Component)