import { lazy } from 'react'

/**
 * Provides the routes used across the app.
 * The components are wrapped with lazy so we only load what's necessary based on the current route.
 *
 * Note: The lazy route/component should then be rendered inside a `Suspense`, so we can show fallback content while waiting for the lazy component to load.
 *
 * @see [Code-Splitting](https://reactjs.org/docs/code-splitting.html)
 * @see [Route-based code splitting](https://reactjs.org/docs/code-splitting.html#route-based-code-splitting)
 */
export default {
	Home: lazy(() => import('../common/home/containers/Home')),
	SearchResultList: lazy(() => import('../modules/search/containers/SearchResultListNavigator')),
	AddAccount: lazy(async () => import('../modules/accounts/components/AddAccount')),
	CustomerOrdersNavigator: lazy(() => import('../modules/customerorders/components/CustomerOrdersNavigator')),
	BranchQuotes: lazy(() => import('../modules/customerorders/components/BranchQuotes')),
	CustomerOrders: lazy(() => import('../modules/customerorders/containers/CustomerOrders')),
	CustomerOrderDetailsNavigator: lazy(() => import('../modules/customerorders/containers/CustomerOrderDetailsNavigator')),
	ProductsNavigator: lazy(() => import('../modules/products/containers/ProductsNavigator')),
	ProductEntry: lazy(() => import('../modules/product/components/ProductEntry')),
	CheckoutNavigator: lazy(() => import('../modules/cart/components/CheckoutNavigator')),
	Lists: lazy(() => import('../modules/mylists/containers/MyListsNavigator')),
	AccountList: lazy(() => import('../modules/mylists/containers/AccountListNavigator')),
	ListDetails: lazy(() => import('../modules/mylists/containers/ListDetailsNavigator')),
	Labels: lazy(() => import('../modules/labels/containers/LabelsNavigator')),
	LabelGroup: lazy(() => import('../modules/labels/containers/LabelGroupNavigator')),
	LabelOrders: lazy(() => import('../common/myorders/containers/LabelOrders')),
	LabelOrderDetails: lazy(() => import('../modules/labels/containers/LabelOrderDetails')),
	HnzProducts: lazy(() => import('../modules/hnz/containers/HnzProducts')),
	SignIn: lazy(() => import('../common/auth/containers/SignIn')),
	Register: lazy(() => import('../common/register/containers/Register')),
	EmailVerification: lazy(() => import('../modules/mobileVerification/containers/EmailVerification')),
	MobileVerification: lazy(() => import('../modules/mobileVerification/containers/MobileVerificationNavigator')),
	ForgotPassword: lazy(() => import('../modules/forgotpassword/containers/ForgotPasswordNavigator')),
	Admin: lazy(() => import('../modules/admin/components/Admin')),
	Financials: lazy(() => import('../modules/financials/containers/Financials')),
	ChangePassword: lazy(() => import('../modules/forgotpassword/containers/ChangePasswordNavigator')),
	Impersonate: lazy(() => import('../modules/auth/containers/Impersonate')),
	GeneralNavigator: lazy(() => import('../modules/general/components/GeneralNavigator')),
	Regions: lazy(() => import('../common/showrooms/containers/Regions')),
	ContactUs: lazy(() => import('../modules/contactus/components/ContactUs')),
	PageUnknown: lazy(() => import('../modules/platform/containers/PageUnknown')),
	Xero: lazy(() => import('../modules/integrations/components/Xero')),
	XeroLandingPage: lazy(() => import('../modules/integrations/components/XeroLandingPage')),
	SimproLandingPage: lazy(() => import('../modules/integrations/components/SimproLandingPage')),
	Fergus: lazy(() => import('../modules/integrations/components/Fergus')),
	Simpro: lazy(() => import('../modules/integrations/components/Simpro')),
	AroFlo: lazy(() => import('../modules/integrations/components/AroFlo')),
	ITrade: lazy(() => import('../modules/integrations/components/ITrade')),
	Integrations: lazy(() => import('../modules/integrations/components/Integrations')),
	AdminAccounts: lazy(() => import('../modules/admin/containers/AdminAccounts')),
	PriceBook: lazy(() => import('../modules/priceBook/containers/PriceBook')),
	VersionUpdates: lazy(() => import('../modules/versionUpdates/components/VersionUpdates')),
	AroFloSignIn: lazy(() => import('../modules/integrations/components/AroFloSignIn')),
	ProductFinderResults: lazy(() => import('../modules/productfinder/containers/ProductFinderResultsNavigator')),
	HWCProductFinder: lazy(() => import('../common/productfinder/containers/HWCProductFinder')),
	BackflowLanding: lazy(() => import('../modules/backflow/components/BackflowLanding')),
	BackflowBrandProducts: lazy(() => import('../modules/backflow/components/BackflowBrandProducts')),
	BackflowTypeProducts: lazy(() => import('../modules/backflow/components/BackflowTypeProducts')),
}
