import { ClickAndCollectTimeslot, ClosureType, CreateOrderCheckoutMethodEnum, CreateOrderDespatchMethodEnum, SimproJob, SimproJobCostCenter, SimproJobSection } from 'typescript-fetch-api'

export interface DeliverySlot {
	value: string
}

export const DELIVERY_TIME_RANGES: Array<DeliverySlot> = [
	{ value: 'AM' },
	{ value: 'PM' },
]

export enum PIN_TYPE {
	SET, RESET, CHECKOUT, LABEL_CHECKOUT, DELETE_SELF
}

export interface DeliveryDetails {
	recipientName?: string
	streetAddress?: string
	suburb?: string
	city?: string
	onSiteContactNumber?: string
}

export interface DespatchDetails {
	despatchDate: string
	despatchSlot?: ClosureType
	despatchClickAndCollectTimeslot?: ClickAndCollectTimeslot
	despatchMethod: CreateOrderDespatchMethodEnum
}

export interface CheckoutMethodDetails {
	checkoutMethod: CreateOrderCheckoutMethodEnum
	simproJob?: SimproJob
	simproJobSection?: SimproJobSection
	simproJobCostCenter?: SimproJobCostCenter
}

export enum ProductAvailability {
	IN_STOCK = 'In-Stock',
	LIMITED_STOCK = 'Limited Stock',
	AVAILABLE_TO_ORDER = 'Not In-Stock',
}

export enum MaxOrderInputLengthFrameworks {
	ORDER_NUMBER = 16,
	NOTES = 90,
	RECIPIENT_NAME = 17,
	ON_SITE_CONTACT_NUMBER = 12,
	ADDRESS = 30,
}

export enum MaxOrderInputLengthDynamics {
	ORDER_NUMBER = 60,
	NOTES = 5000,
	RECIPIENT_NAME = 17,
	ON_SITE_CONTACT_NUMBER = 12,
	ADDRESS = 30,
}
