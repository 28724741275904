import '../styles/ProfileDrawer.scss'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer } from '@mui/material'

import { RootStoreState } from '../../../common/root'
import { AccountStatus, UserAccount } from '../../../common/accounts/types'
import UserProfile from '../../admin/containers/UserProfile'
import { BasicUserProfile as BasicUserProfileType } from '../../admin/types'
import BasicUserProfile from '../../admin/containers/BasicUserProfile'
import { clearUpdateUserPasswordFlags } from '../../../common/profile/actions'
import { isAdminOrPrincipal } from '../../../common/mylists/functions'

interface Props {
	accountId?: number // if viewing specific user account (as admin)
	isForAdd?: boolean // if is for adding new user account (as admin)
	isDrawerVisible: boolean
	onHideDrawer: () => void
}

const ProfileDrawer = ({ accountId, isForAdd, isDrawerVisible, onHideDrawer }: Props): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	* Store State
	*/

	const userId = useSelector<RootStoreState, number | undefined>(state => state.auth.userId)
	const selectedAccount = useSelector<RootStoreState, UserAccount | undefined>(state => state.order.selectedAccount)
	const accounts = useSelector<RootStoreState, UserAccount[]>(state => state.accounts.accounts?.filter(account => account.registrationState === AccountStatus.Approved) || [])
	const adminAccounts = useSelector<RootStoreState, UserAccount[]>(state => state.accounts.adminAccounts || [])
	const basicUser = useSelector<RootStoreState, BasicUserProfileType>(state => {
		const { firstName, lastName, email, mobileNumber } = state.auth
		return { firstName, lastName, email, mobileNumber }
	})

	const hasApprovedAccounts: boolean = accounts.length > 0
	const isTradeUser = !isAdminOrPrincipal(selectedAccount)
	// find account user is trying to view
	let account: UserAccount | undefined
	if (isForAdd) {
		// adding new account
		account = undefined
	}
	// if no account id given, user is viewing their own profile from subheader button
	// OR if selected account has Trade role, they cannot be viewing admin accounts, so must be viewing their own account (not sure if still need this check?)
	else if (isTradeUser || !accountId) {
		const customerId: number | undefined = selectedAccount?.customerId
		// load the selected account details from the current user's account list
		account = (customerId && accounts.find(item => item.customerId === customerId)) || undefined
	} else {
		// load the selected account details from the admin accounts
		account = (accountId && adminAccounts.find(account => account.id === accountId)) || undefined
	}
	const isViewingOwnAccount: boolean = !!account && !!userId && account.userId === userId

	/**
	 * Local State
	 */

	const [showConfirmRemoveUser, setShowConfirmRemoveUser] = useState<boolean>(false)

	/**
	 * Effects / Subscriptions
	 */

	useEffect(() => {
		dispatch(clearUpdateUserPasswordFlags())
	}, [dispatch])

	/**
	 * Local Functions
	 */

	const onShowConfirmRemoval = (show: boolean) => {
		setShowConfirmRemoveUser(show)
	}

	const onCloseDrawer = () => {
		if (showConfirmRemoveUser) onShowConfirmRemoval(false)
		onHideDrawer()
	}

	/**
	 * Render
	 */

	return (
		<Drawer
			open={isDrawerVisible}
			onClose={onCloseDrawer}
			anchor="right"
			className="profile-drawer"
		>
			<div className="profile-drawer__close--container">
				<span className="icon-close-round profile-drawer__close--icon" onClick={onCloseDrawer} />
			</div>

			<div className="profile-drawer__content">
				<div className="profile-drawer__header">
					<span className="profile-drawer__header--text">{showConfirmRemoveUser ? 'Confirm Removal' : isForAdd ? 'Add User' : isViewingOwnAccount ? 'Profile and Account' : 'User Profile'}</span>
					{showConfirmRemoveUser && <span className="profile-drawer__header--subtext">Confirming will remove the user and cannot be undone</span>}
				</div>

				{isForAdd || hasApprovedAccounts ?
					<UserProfile account={account} showAccountsSection={isViewingOwnAccount} onCancel={onHideDrawer} onShowConfirmRemoval={onShowConfirmRemoval} /> :
					<BasicUserProfile account={basicUser} onCancel={onHideDrawer} />
				}
			</div>
		</Drawer>

	)
}

export default ProfileDrawer