import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { PriceBookSubscriptionResponse, PriceBookSubscription, PriceBookRequest, PriceBookExampleFileFormat } from 'typescript-fetch-api'

const actionCreator = actionCreatorFactory('PriceBook')

export const navigateToPriceBook = actionCreator('NAVIGATE_TO_PRICE_BOOK')

export type FetchPriceBookSubscriptionAction = Action<number>
export const fetchPriceBookSubscription = actionCreator.async<number, PriceBookSubscriptionResponse, Error>('FETCH_PRICE_BOOK_SUBSCRIPTION')

export interface UpdatePriceBookSubscriptionPayload {
	customerId: number
	subscription?: PriceBookSubscription
}
export type UpdatePriceBookSubscriptionAction = Action<UpdatePriceBookSubscriptionPayload>
export const updatePriceBookSubscription = actionCreator.async<UpdatePriceBookSubscriptionPayload, PriceBookSubscriptionResponse, Error>('UPDATE_PRICE_BOOK_SUBSCRIPTION')

export interface CreatePriceBookRequestPayload {
	customerId: number
	request?: PriceBookRequest
}
export type CreatePriceBookRequestAction = Action<CreatePriceBookRequestPayload>
export type CreatePriceBookRequestSuccessAction = Action<Success<CreatePriceBookRequestPayload, Response>>
export const createPriceBookRequest = actionCreator.async<CreatePriceBookRequestPayload, Response, Error>('CREATE_PRICE_BOOK_REQUEST')

export type DownloadExamplePriceBookFileAction = Action<PriceBookExampleFileFormat>
export const downloadExamplePriceBookFile = actionCreator.async<PriceBookExampleFileFormat, Blob, Error>('DOWNLOAD_EXAMPLE_PRICE_BOOK')