// import { CSSProperties } from 'react'
import { Theme } from '@mui/material'
// import { AutocompleteClassKey } from '@mui/material'

// Type definitions for MUI Lab components
// https://github.com/mui-org/material-ui/issues/12164#issuecomment-564041219
// interface MuiOverrides extends Overrides {
// 	MuiAutocomplete?: Partial<Record<AutocompleteClassKey, CSSProperties | object>>
// }

// interface MuiTheme extends Theme {
// 	overrides?: MuiOverrides
// }

export const colors = {
	black: '#000000',
	white: '#ffffff',
	offWhite: '#fcfcfb',
	tealMain: '#66BD9B',
	tealLight: '#e0f1eb',
	tealDark: '#74c2a5',
	greyMain: '#9c9d97',
	greyLight: '#BEBEBE',
	greyDark: '#8c8d87',
	redMain: '#B70000',
	redLight: '#f4babc',
	redDark: '#d5181f',
	orange: '#FFBB00',

	// color names taken from https://www.htmlcsscolor.com/
	watercourse: '#007749', // hnz mode color
	greenWhite: '#D4D4D2',
	seashell: '#F6F5F4',
	eucalyptus: '#33926D',
	whiteSmoke: '#F5F5F5',
	delta: '#90928C',
	persianBlue: '#0036D0',
	deepSkyBlue: '#13B5EA',
	navyBlue: '#1976D2',
	brinkPink: '#F2607B',
	blush: '#C24D62',
	aroflo: '#383749',
	kellyGreen: '#53AB2E',
	gainsboro: '#DFDFDF',
	suvaGrey: '#8E8E8E',
}

export const theme: Theme = {
	// @ts-ignore
	palette: {
		primary: {
			main: colors.tealMain,
			light: colors.tealLight,
			dark: colors.tealDark,
			contrastText: colors.white,
		},
		secondary: {
			main: colors.greyMain,
			dark: colors.greyDark,
			light: colors.greyLight,
			contrastText: colors.black,
		},
		error: {
			main: colors.redMain,
			dark: colors.redDark,
			light: colors.redLight,
			contrastText: colors.white,
		},
	},
	// @ts-ignore
	typography: {
		fontFamily: '"Montserrat", "Arial", sans-serif',
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
					borderRadius: 4,
					color: colors.black,
					backgroundColor: colors.white,
					'&:hover, &$focused': {
						backgroundColor: colors.white,
					},
				},
			}
		},
		MuiInput: {
			styleOverrides: {
				input: {
					padding: 20,
					borderRadius: 4,
					fontSize: '14px',
					lineHeight: '18px',
				},
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontSize: '14px',
					lineHeight: '18px',
					'&$disabled': {
						backgroundColor: colors.whiteSmoke,
						color: colors.black,
					},
				},
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 4,
					'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
						borderColor: colors.suvaGrey,
					},
				},
				input: {
					color: colors.black,
					'&::placeholder': {
						color: colors.suvaGrey,
						opacity: 1,
					},
				},
				multiline: {
					padding: 20,
				},
				notchedOutline: {
					borderColor: colors.black,
				},
			}
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					backgroundColor: 'transparent',
					'&:hover, &$focused': {
						backgroundColor: 'transparent',
					},
				},
				input: {
					backgroundColor: colors.white,
					'&:hover, &$focused': {
						backgroundColor: colors.white,
					},
					borderRadius: 4,
					fontSize: 16,
					paddingLeft: 20,
				},
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: colors.black,
					'&.Mui-disabled': {
						color: colors.suvaGrey,
					},
				},
				filled: {
					fontSize: 14,
					paddingLeft: 10,
				},
			}
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontSize: '14px',
					borderRadius: 4,
					'&$focused': {
						color: colors.greyMain,
					},
					'&[data-shrink=true]': {
						fontFamily: '"Montserrat-SemiBold", "Arial", sans-serif',
					},
				},
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
					textTransform: 'none',
					fontSize: '16px',
					lineHeight: '20px',
					padding: '6px 12px',
					height: '50px',
					'&:hover, &:active': {
						boxShadow: 'none',
					},
					fontFamily: '"Montserrat-Bold", "Arial", sans-serif',
				},
				outlined: {
					color: colors.black,
					borderColor: colors.black,
					backgroundColor: colors.white,
					'&:hover': {
						borderColor: colors.black,
						backgroundColor: colors.black,
						color: colors.white,
						'@media (hover: none)': {
							borderColor: colors.black,
							backgroundColor: colors.black,
							color: colors.white,
						},
					},
				},
				contained: {
					boxShadow: 'none',
					'&:hover, &:active': {
						boxShadow: 'none',
					},
				},
			}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					'&$checked': {
						color: colors.tealMain,
					},
				},
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					// backgroundColor: colors.black, // now uses border color
				},
			}
		},
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					marginTop: 30,
					fontSize: 11,
				},
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					marginLeft: 20,
					marginTop: 5,
					fontFamily: '"Montserrat", "Arial", sans-serif',
					fontSize: 12,
					lineHeight: '15px',
					color: colors.tealMain,
					'&$error': {
						color: colors.redMain,
					},
				},
			}
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					textAlign: 'center',
					fontSize: 20,
					fontFamily: '"Montserrat-SemiBold", "Arial", sans-serif',
					padding: '25px 30px',
				},
			}
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					width: '-webkit-fill-available',
					padding: '25px 35px',
				}
			}
		},
		MuiDialogContentText: {
			styleOverrides: {
				root: {
					fontSize: 12,
					lineHeight: '15px',
					fontFamily: '"Montserrat-SemiBold", "Arial", sans-serif',
					color: colors.delta,
				},
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: colors.black,
					'&:hover': {
						backgroundColor: 'transparent',
					},
				},
				colorPrimary: {
					'&$checked': {
						'&:hover': {
							backgroundColor: 'transparent',
						},
					},
				},
				colorSecondary: {
					'&$checked': {
						'&:hover': {
							backgroundColor: 'transparent',
						},
					},
				},
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontFamily: 'Montserrat',
				},
			}
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					borderBottom: `2px solid ${colors.black}`,
					color: colors.black,
					fontFamily: '"Montserrat-Medium", "Arial", sans-serif',
					padding: '10px 16px',
				},
				body: {
					fontFamily: '"Montserrat-SemiBold", "Arial", sans-serif',
					fontSize: 16,
				},
			}
		},
		MuiCircularProgress: {
			styleOverrides: {
				root: {
					width: 24,
					height: 24,
				},
			}
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					marginRight: 0,
				},
				label: {
					fontFamily: '"Montserrat", "Arial", sans-serif',
					fontSize: 14,
				},
			}
		},
		MuiSnackbar: {
			styleOverrides: {
				root: {
					fontFamily: '"Montserrat", "Arial", sans-serif',
					fontSize: 12,
				},
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontFamily: '"Montserrat", "Arial", sans-serif',
					fontSize: '14px',
					lineHeight: '18px',
					color: colors.greyMain,
					'&:hover': {
						backgroundColor: colors.tealLight,
					},
				},
			}
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					'&:focus': {
						backgroundColor: 'transparent',
					},
				},
				outlined: {
					fontFamily: '"Montserrat-SemiBold", "Arial", sans-serif',
					fontSize: '14px',
					lineHeight: '18px',
				},
			}
		},
		MuiListSubheader: {
			styleOverrides: {
				root: {
					fontFamily: '"Montserrat-SemiBold", "Arial", sans-serif',
					fontSize: 12,
					textTransform: 'uppercase',
				},
			}
		},
		MuiPaper: {
			styleOverrides: {
				outlined: {
					borderColor: colors.black,
				},
			}
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					'&$selected': {
						backgroundColor: colors.tealMain,
						color: colors.white,
						'&:hover': {
							backgroundColor: colors.tealLight,
							color: colors.greyLight,
						},
					},
				},
			}
		},
		MuiAutocomplete: {
			styleOverrides: {
				option: {
					fontFamily: '"Montserrat", "Arial", sans-serif',
					fontSize: 16,
					color: colors.greyMain,
					'&[aria-selected=true]': {
						backgroundColor: colors.white,
					},
					'&:hover, &[data-focus=true]': {
						backgroundColor: colors.tealLight,
					},
				},
			}
		},
		MuiStepper: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			}
		},
		MuiStepLabel: {
			styleOverrides: {
				label: {
					fontFamily: '"Montserrat-Bold", "Arial", sans-serif',
					'&.MuiStepLabel-label': {
						fontSize: 20,
						lineHeight: '24px',
						marginTop: 30,
						color: colors.black,
					}
				},
			}
		},
		MuiStepConnector: {
			styleOverrides: {
				alternativeLabel: {
					top: '25%',
				},
				root: {
					'&.Mui-active': {
						'.MuiStepConnector-line': {
							borderColor: colors.tealMain,
						},
					},
					'&.Mui-completed': {
						'.MuiStepConnector-line': {
							borderColor: colors.tealMain,
						},
					}
				},
				line: {
					borderColor: colors.gainsboro,
				},
				lineHorizontal: {
					borderTopWidth: 8,
				},
			}
		},
		MuiTypography: {
			styleOverrides: {
				subtitle1: {
					fontFamily: '"Montserrat-SemiBold", "Arial", sans-serif',
					fontSize: 24,
					lineHeight: '29px',
				},
				subtitle2: {
					fontFamily: '"Montserrat-SemiBold", "Arial", sans-serif',
					fontSize: 20,
					lineHeight: '24px',
				},
				body2: {
					fontFamily: '"Montserrat", "Arial", sans-serif',
					fontSize: 14,
					lineHeight: '18px',
				},
			}
		},
	}
}