import * as React from 'react'
import { ListItem } from '@mui/material'

import { PlatformProduct } from '../../platform'
import ProductImage from '../../products/components/ProductImage'

import '../styles/SearchSuggestionItem.css'

interface Props {
	item: PlatformProduct
	onSearchSuggestionItemClick(item: PlatformProduct): void
}

export default class SearchSuggestionItem extends React.PureComponent<Props> {

	onSearchSuggestionItemClick = () => {
		this.props.onSearchSuggestionItemClick(this.props.item)
	}

	render() {
		const { longdescription, sku, primaryimage } = this.props.item

		return (
			<ListItem
				className="search-suggestion"
				onClick={this.onSearchSuggestionItemClick}
			>
				<ProductImage
					image={primaryimage}
					alt={sku}
					className="search-suggestion__image"
				/>
				<div className="search-suggestion__details">
					<span className="search-suggestion__text--label">{longdescription}</span>
					<span>{sku}</span>
				</div>
			</ListItem>
		)
	}
}