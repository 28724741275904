import './styles.scss'

import React, { ErrorInfo, ReactNode } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

interface Props {
	children: ReactNode
}

interface State {
	error: Error | undefined
}

const INITIAL_STATE: State = {
	error: undefined,
}

/**
 * Handle errors triggered when a module fails to load (ie due to network failure or chunk failed to load).
 * @see [Error Boundaries](https://reactjs.org/docs/code-splitting.html#error-boundaries)
 */
class LoaderErrorBoundary extends React.PureComponent<Props, State> {

	state = INITIAL_STATE

	static getDerivedStateFromError(error: Error): State {
		// Update state so the next render will show the fallback UI.
		return { error }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		console.error('Uncaught error:', error, errorInfo)
	}

	_onReloadPage = (): void => {
		window.location.reload()
	}

	render(): React.ReactNode {
		if (this.state.error) {
			// You can render any custom fallback UI
			return (
				<div className="loader">
					<h2 className="loader__text">Something went wrong!</h2>

					<Box m={1} />

					<p className="loader__text loader__text--detail">There was an error loading the page. Please try again.</p>

					<Box m={2} />

					<Button
						variant="outlined"
						onClick={this._onReloadPage}
						className="button--fixed-width"
					>
						Retry
					</Button>
				</div>
			)
		}

		return this.props.children
	}
}

export default LoaderErrorBoundary