export enum ProductFinderType {
    HWC = 'HWC',
    GOLD = 'GOLD',
	BACKFLOW = 'BACKFLOW',
}

export enum HWCMainFilters {
    PRESSURE_RATING = 'Pressure Rating',
    MATERIAL = 'Material',
    ENERGY_SOURCE = 'Energy Source',
    DIMENSIONS = 'Dimensions',
}

export enum PressureRatingValue {
    LOW = 'Low Pressure',
    MEDIUM = 'Medium Pressure',
    HIGH = 'Mains Pressure',
}

export enum MaterialValue {
    STAINLESS_STEEL = 'Stainless Steel',
    COPPER = 'Copper',
    VITREOUS_ENAMEL = 'Vitreous Enamel',
}

export enum EnergySourceValue {
    ELECTRIC = 'Electric',
    GAS = 'Gas',
    WETBACK_ELECTIC = 'Wetback Electric',
}

export const WIDTH_ATTRIBUTE_NAME = 'Width (mm)'
export const HEIGHT_ATTRIBUTE_NAME = 'Height (mm)'
export const LITRES_ATTRIBUTE_NAME = 'Litres'

export const PRODUCT_FINDER_FILTERS = {
    [ProductFinderType.HWC]: [
        HWCMainFilters.PRESSURE_RATING,
        HWCMainFilters.MATERIAL,
        HWCMainFilters.ENERGY_SOURCE,
        HWCMainFilters.DIMENSIONS,
    ],
}

export const HWC_BASE_CATEGORY_ID = 'WTH_STO' // most water heaters have this category id

export function getBaseCategoryId(type: ProductFinderType): string | undefined {
    switch (type) {
        case ProductFinderType.HWC:
            return HWC_BASE_CATEGORY_ID
        default:
            return undefined
    }
}