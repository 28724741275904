import React, { useState, useEffect } from 'react'
import { TextField, TextFieldProps, Popper, Autocomplete, AutocompleteValue, createFilterOptions } from '@mui/material'

import { Branch } from '../../../common/showrooms/types'

interface Props {
	options: Branch[]
	defaultBranchId?: string
	label?: string
	placeholder?: string
	error?: boolean
	disableClearable?: boolean
	showBranchIdInOption?: boolean
	clearSelection?: boolean
	inputParams?: TextFieldProps
	onBranchSelected: (branchId: string | undefined) => void
}

const BranchSelect = ({ options, defaultBranchId, label, placeholder, error, disableClearable, showBranchIdInOption, clearSelection, inputParams, onBranchSelected }: Props): JSX.Element => {

	/**
	 * Local State
	 */

	const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null)

	/**
	 * Effects / Subscriptions
	 */

	// effect that initialises the selected branch
	useEffect(() => {
		if (defaultBranchId) {
			const branch = options.find(option => option.id === defaultBranchId)
			if (branch) {
				setSelectedBranch(branch)
				onBranchSelected(branch.id)
			}
		} else if (options.length === 1) {
			// user only has access to one branch, set that as default
			setSelectedBranch(options[0])
			onBranchSelected(options[0].id)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (clearSelection) {
			setSelectedBranch(null)
		}
	}, [clearSelection])

	/**
	 * Local Functions
	 */

	const filterOptions = createFilterOptions<Branch | null>({
		// include the branch id when filtering
		stringify: option => option ? option.name + option.id : '',
	})

	const _getOptionLabel = (option: Branch | null): string => {
		if (option) {
			if (showBranchIdInOption) {
				return `${option.id} - ${option.name}`
			}
			return option.name
		}
		return ''
	}

	return (
		<Autocomplete<Branch | null, false, true, false>
			options={options}
			getOptionLabel={_getOptionLabel}
			autoComplete={true}
			filterOptions={filterOptions}
			renderInput={(params) => (
				<TextField
					{...params}
					{...inputParams}
					variant={(inputParams && inputParams.variant) || 'outlined'}
					label={label !== undefined ? label : 'Branch'}
					placeholder={placeholder}
					error={error}
					inputProps={inputParams ? { ...params.inputProps, ...inputParams.inputProps } : { ...params.inputProps }}
					InputProps={inputParams ? { ...params.InputProps, ...inputParams.InputProps } : { ...params.InputProps }}
				/>
			)}
			onChange={(e: React.ChangeEvent<{}>, value: AutocompleteValue<Branch | null, false, true, false>) => {
				setSelectedBranch(value)
				onBranchSelected(value ? value.id : undefined)
			}}
			// @ts-ignore: MUI type error not recognising null as optional default type
			value={selectedBranch}
			isOptionEqualToValue={(option: Branch | null, value: Branch | null) => (
				!!option && !!value && option.id === value.id
			)}
			disabled={options.length === 1} // user only has access to one branch, disable dropdown
			disableClearable={disableClearable || undefined}
			PopperComponent={props => (
				<Popper
					{...props}
					placement="bottom-start"
					popperOptions={{
						// forces a given position (bottom of input) regardless of screen size, layout, etc.
						// https://github.com/popperjs/popper-core/issues/354#issuecomment-315789038
						modifiers: [
							{
								name: 'preventOverflow',
								enabled: true,
								options: {
									rootBoundary: 'viewport',
								}
							},
							{
								name: 'flip',
								enabled: false,
							}
						]
					}}
				/>
			)}
		/>
	)
}

export default BranchSelect
