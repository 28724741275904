import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import { VersionUpdate } from 'typescript-fetch-api'

import * as actions from './actions'
import * as authActions from '../../common/auth/actions'

export interface StoreState {
	readonly versionUpdates: VersionUpdate[]
	readonly fetchingVersionUpdates: boolean
	readonly errorFetchingVersionUpdates?: Error

	readonly versionUpdate?: string
}

const INITIAL_STATE: StoreState = {
	versionUpdates: [],
	fetchingVersionUpdates: false,
	errorFetchingVersionUpdates: undefined,

	versionUpdate: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.fetchingVersionUpdates.started, (state): StoreState => {
		return {
			...state,
			fetchingVersionUpdates: true,
			errorFetchingVersionUpdates: undefined,
		}
	})
	.case(actions.fetchingVersionUpdates.done, (state, payload): StoreState => {
		const versionUpdates = payload.result.versions || []
		return {
			...state, versionUpdates, fetchingVersionUpdates: false,
		}
	})
	.case(actions.fetchingVersionUpdates.failed, (state, payload): StoreState => {
		return {
			...state,
			versionUpdates: [],
			fetchingVersionUpdates: false,
			errorFetchingVersionUpdates: payload.error,
		}
	})

	.case(actions.setVersionUpdate, (state, payload): StoreState => {
		return {
			...state,
			versionUpdate: payload.version,
		}
	})

	.cases([authActions.loggedOut, authActions.clearAuthToken, authActions.refreshAuthTokenFailed], (): StoreState => INITIAL_STATE)
