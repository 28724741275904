import { SagaIterator } from 'redux-saga'
import { call, takeEvery } from 'redux-saga/effects'
import { CarouselPlatform, HomePagePlatform } from 'typescript-fetch-api'

import * as actions from './actions'
import { callApi } from '../api/functions'
import { getContentApi } from '../api'
import { getSortedData } from '../util/functions'

function* handleFetchCarousel(action: actions.FetchCarouselAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchCarousel,
		(platform: CarouselPlatform) => getContentApi().getCarousel({ platform, active: true })
			.then(result => {
				const carouselItems = result.items || []
				return {
					...result,
					items: getSortedData(carouselItems, 'ordinal'),
				}
			})
	)
}

function* handleFetchHomePageItems(action: actions.FetchHomePageItemsAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.fetchHomePageItems,
		(platform: HomePagePlatform) =>
			getContentApi().getHomePageContent({ platform, active: true })
				.then(result => {
					const items = result.items || []
					return {
						...result,
						items: getSortedData(items, 'ordinal'),
					}
				})
	)
}

export default function* (): SagaIterator {
	yield takeEvery(actions.fetchCarousel.started, handleFetchCarousel)
	yield takeEvery(actions.fetchHomePageItems.started, handleFetchHomePageItems)
}