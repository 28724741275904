import { Statement } from 'typescript-fetch-api'

import { PAYMENT_CALLBACK_URL_CANCEL, PAYMENT_CALLBACK_URL_FAIL, PAYMENT_CALLBACK_URL_SUCCESS } from './content'
import { PaymentResultEnum } from './types'
import { formatAmount } from '../../common/util/functions'

/**
 * Gets the PaymentResultEnum based on the payment callback URL
 * @param callback the callback URL
 * @returns the PaymentResultEnum
 */
export const getPaymentResultFromCallback = (callback: string): PaymentResultEnum | undefined => {
	if (callback.includes(PAYMENT_CALLBACK_URL_SUCCESS)) return PaymentResultEnum.SUCCESS
	else if (callback.includes(PAYMENT_CALLBACK_URL_FAIL)) return PaymentResultEnum.FAIL
	else if (callback.includes(PAYMENT_CALLBACK_URL_CANCEL)) return PaymentResultEnum.CANCEL
	return undefined
}

/**
 * Gets the default amount for the account summary
 * @param accountSummary the account summary
 * @returns the default amount
 */
export const getDefaultAmountForAccountSummary = (accountSummary: Statement): string => {
	const { day30Balance, day60Balance, day90Balance } = accountSummary
	let totalDue = day30Balance || 0
	if (day60Balance) totalDue += day60Balance
	if (day90Balance) totalDue += day90Balance
	if (totalDue < 0) totalDue = 0
	return formatAmount(totalDue, true, true)
}

/**
 * Gets the title for the invoice type to use in the filename when downloading
 * @param type the invoice type
 * @returns the title for the invoice type
 */
export const getInvoiceTypeDownloadTitle = (type: string): string => {
	switch (type) {
		case 'Invoice':
			return 'INV'
		case 'Credit Note':
			return 'CRD'
		default:
			return ''
	}
}