import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { IntegrationCompany, IntegrationAction as IntegrationActionEnum, IntegrationResponse, XeroSubscription, XeroConnections, XeroContact, XeroAccount, SimproSubscription, SimproSubscriptionType, UpdateXeroSubscriptionRequest, GetXeroConnectionsGrantTypeEnum, GetAroFloSubscriptionRequest, AroFloSubscription, UpdateAroFloSubscriptionOperationRequest, SimproCompaniesTokenResponse, SimproCompany, SimproVendor, SimproOrderNumberFormat, SimproJobStage, SimproCatalogue, GetITradeSubscriptionRequest, ITradeSubscription, UpdateITradeSubscriptionOperationRequest, AroFloPunchoutUser, GetAroFloPunchoutUserRequest, CreateAroFloProcurementUserRequest, DeleteAroFloPunchoutUserRequest, ResetAroFloPunchoutUserRequest, GetFergusSubscriptionRequest, FergusSubscription, GetScheduledSubscriptionRequest, ScheduledSubscription, UpdateScheduledSubscriptionOperationRequest } from 'typescript-fetch-api'

import { UserAccount } from '../../common/accounts/types'
import { Partners } from './types'

const actionCreator = actionCreatorFactory('Integrations')

export type NavigateToIntegrationAction = Action<IntegrationCompany | void>
export const navigateToIntegration = actionCreator<IntegrationCompany | void>('NAVIGATE_TO_INTEGRATION')

export interface IntegrationPayload {
	customerId: number
	integrationCompany: IntegrationCompany
	integrationAction: IntegrationActionEnum
	companyUrl?: string
}
export type IntegrationAction = Action<IntegrationPayload>

export type ConnectIntegrationSuccessAction = Action<Success<IntegrationPayload, IntegrationResponse>>
export const connectIntegration = actionCreator.async<IntegrationPayload, IntegrationResponse, Error>('CONNECT_INTEGRATION')

export type UpdateIntegrationStatusSuccessAction = Action<Success<IntegrationPayload, UserAccount>>
export const updateIntegrationStatus = actionCreator.async<IntegrationPayload, UserAccount, Error>('UPDATE_INTEGRATION_STATUS')

export interface GetXeroSubscriptionPayload {
	customerId: number
}
export type GetXeroSubscriptionAction = Action<GetXeroSubscriptionPayload>
export const getXeroSubscription = actionCreator.async<GetXeroSubscriptionPayload, XeroSubscription, Error>('GET_XERO_SUBSCRIPTION')

export type UpdateXeroSubscriptionAction = Action<UpdateXeroSubscriptionRequest>
export const updateXeroSubscription = actionCreator.async<UpdateXeroSubscriptionRequest, XeroSubscription, Error>('UPDATE_XERO_SUBSCRIPTION')

export interface GetXeroConnectionsPayload {
	customerId: number
	grantType: GetXeroConnectionsGrantTypeEnum
	value: string
}
export type GetXeroConnectionsAction = Action<GetXeroConnectionsPayload>
export type GetXeroConnectionsSuccessAction = Action<Success<GetXeroConnectionsPayload, XeroConnections>>
export const getXeroConnections = actionCreator.async<GetXeroConnectionsPayload, XeroConnections, Error>('GET_XERO_CONNECTIONS')

export interface GetXeroContactsOrAccountsPayload {
	accessToken: string
	customerId: number
	tenantId: string
}
export type GetXeroContactsOrAccountsAction = Action<GetXeroContactsOrAccountsPayload>
export const getXeroContacts = actionCreator.async<GetXeroContactsOrAccountsPayload, XeroContact[], Error>('GET_XERO_CONTACTS')
export const getXeroAccounts = actionCreator.async<GetXeroContactsOrAccountsPayload, XeroAccount[], Error>('GET_XERO_ACCOUNTS')

export const clearUpdateXeroSubscriptionSuccess = actionCreator('CLEAR_UPDATE_XERO_SUBSCRIPTION_SUCCESS')

// showing dialogs
export const showFergusNewFeatureDialog = actionCreator<boolean>('SHOW_FERGUS_NEW_FEATURE_DIALOG')
export const showXeroNewFeatureDialog = actionCreator<boolean>('SHOW_XERO_NEW_FEATURE_DIALOG')

export interface GetSimproSubscriptionPayload {
	customerId: number
	type: SimproSubscriptionType
}
export type GetSimproSubscriptionAction = Action<GetSimproSubscriptionPayload>
export const getSimproSubscription = actionCreator.async<GetSimproSubscriptionPayload, SimproSubscription, Error>('GET_SIMPRO_SUBSCRIPTION')

export interface UpdateSimproSubscriptionPayload {
	customerId: number
	accessToken: string
	refreshToken?: string // not needed for disconnect
	companyUrl: string
	companyId: string
	vendorId: string
	orderNumberFormat: string
	isSubscribed: boolean
	jobStages: SimproJobStage[]
	catalogueId?: number
	itemise: boolean
}
export type UpdateSimproSubscriptionAction = Action<UpdateSimproSubscriptionPayload>
export const updateSimproSubscription = actionCreator.async<UpdateSimproSubscriptionPayload, SimproSubscription, Error>('UPDATE_SIMPRO_SUBSCRIPTION')

export interface GetSimproAccessTokenPayload {
	customerId: number
	code: string
	companyUrl: string
}
export type GetSimproAccessTokenAction = Action<GetSimproAccessTokenPayload>
export const getSimproAccessToken = actionCreator.async<GetSimproAccessTokenPayload, SimproCompaniesTokenResponse, Error>('GET_SIMPRO_ACCESS_TOKEN')

export interface GetSimproCompaniesPayload {
	customerId: number
	accessToken: string
	companyUrl: string
}
export type GetSimproCompaniesAction = Action<GetSimproCompaniesPayload>
export const getSimproCompanies = actionCreator.async<GetSimproCompaniesPayload, SimproCompany[], Error>('GET_SIMPRO_COMPANIES')

export interface GetSimproVendorsPayload {
	customerId: number
	accessToken: string
	companyUrl: string
	companyId: string
	searchTerm?: string
}
export type GetSimproVendorsAction = Action<GetSimproVendorsPayload>
export const getSimproVendors = actionCreator.async<GetSimproVendorsPayload, SimproVendor[], Error>('GET_SIMPRO_VENDORS')

export interface GetSimproOrderNumberFormatsPayload {
	customerId: number
}
export type GetSimproOrderNumberFormatsAction = Action<GetSimproOrderNumberFormatsPayload>
export const getSimproOrderNumberFormats = actionCreator.async<GetSimproOrderNumberFormatsPayload, SimproOrderNumberFormat[], Error>('GET_SIMPRO_ORDER_NUMBER_FORMATS')

export interface GetSimproCataloguesPayload extends GetSimproVendorsPayload {
	searchTerm: string
}
export type GetSimproCataloguesAction = Action<GetSimproCataloguesPayload>
export const getSimproCatalogues = actionCreator.async<GetSimproCataloguesPayload, SimproCatalogue[], Error>('GET_SIMPRO_CATALOGUES')

export const onResetSimproCatalogues = actionCreator('RESET_SIMPRO_CATALOGUES')
export const onResetSimproVendors = actionCreator('RESET_SIMPRO_VENDORS')

export type NavigateToPartnersAction = Action<Partners>
export const navigateToPartners = actionCreator<Partners>('NAVIGATE_TO_PARTNERS')

export type GetAroFloSubscriptionAction = Action<GetAroFloSubscriptionRequest>
export const getAroFloSubscription = actionCreator.async<GetAroFloSubscriptionRequest, AroFloSubscription, Error>('GET_AROFLO_SUBSCRIPTION')

export type UpdateAroFloSubscriptionAction = Action<UpdateAroFloSubscriptionOperationRequest>
export const updateAroFloSubscription = actionCreator.async<UpdateAroFloSubscriptionOperationRequest, AroFloSubscription, Error>('UPDATE_AROFLO_SUBSCRIPTION')

export type GetAroFloCatalogueUserAction = Action<GetAroFloPunchoutUserRequest>
export const getAroFloCatalogueUser = actionCreator.async<GetAroFloPunchoutUserRequest, AroFloPunchoutUser | undefined, Error>('GET_AROFLO_CATALOGUE_USER')

export type CreateAroFloCatalogueUserAction = Action<CreateAroFloProcurementUserRequest>
export const createAroFloCatalogueUser = actionCreator.async<CreateAroFloProcurementUserRequest, AroFloPunchoutUser, Error>('CREATE_AROFLO_CATALOGUE_USER')

export type DeleteAroFloCatalogueUserAction = Action<DeleteAroFloPunchoutUserRequest>
export const deleteAroFloCatalogueUser = actionCreator.async<DeleteAroFloPunchoutUserRequest, void, Error>('DELETE_AROFLO_CATALOGUE_USER')

export type ResetAroFloCatalogueUserAction = Action<ResetAroFloPunchoutUserRequest>
export const resetAroFloCatalogueUser = actionCreator.async<ResetAroFloPunchoutUserRequest, AroFloPunchoutUser, Error>('RESET_AROFLO_CATALOGUE_USER')

export type GetITradeSubscriptionAction = Action<GetITradeSubscriptionRequest>
export const getITradeSubscription = actionCreator.async<GetITradeSubscriptionRequest, ITradeSubscription, Error>('GET_ITRADE_SUBSCRIPTION')

export type UpdateITradeSubscriptionAction = Action<UpdateITradeSubscriptionOperationRequest>
export const updateITradeSubscription = actionCreator.async<UpdateITradeSubscriptionOperationRequest, ITradeSubscription, Error>('UPDATE_ITRADE_SUBSCRIPTION')

export type GetFergusSubscriptionAction = Action<GetFergusSubscriptionRequest>
export const getFergusSubscription = actionCreator.async<GetFergusSubscriptionRequest, FergusSubscription, Error>('GET_FERGUS_SUBSCRIPTION')

export type GetScheduledInvoiceSubscriptionAction = Action<GetScheduledSubscriptionRequest>
export const getScheduledInvoiceSubscription = actionCreator.async<GetITradeSubscriptionRequest, ScheduledSubscription, Error>('GET_SCHEDULED_SUBSCRIPTION')

export type UpdateScheduledInvoiceSubscriptionAction = Action<UpdateScheduledSubscriptionOperationRequest>
export const updateScheduledInvoiceSubscription = actionCreator.async<UpdateScheduledSubscriptionOperationRequest, ScheduledSubscription, Error>('UPDATE_SCHEDULED_SUBSCRIPTION')