import React from 'react'

import { PriceType, getPriceTypeDisplayName } from '../../../common/accounts/types'

import '../styles/PriceSummary.scss'

interface Props {
	values: {
		subtotal: number
		gst: number
		total: number
	}
	priceType?: PriceType
}

export default class PriceSummary extends React.PureComponent<Props> {

	render() {
		const { values: { subtotal, gst, total }, priceType } = this.props

		return (
			<div className="price-summary">
				<div className="price-summary__content">
					<span className="price-summary__heading">Subtotal</span>
					<span className="price-summary__description">${subtotal.toFixed(2)}</span>
				</div>
				<div className="price-summary__content">
					<span className="price-summary__heading">GST</span>
					<span className="price-summary__description">${gst.toFixed(2)}</span>
				</div>
				<div className="price-summary__content">
					<span className="price-summary__heading">Total</span>
					<span className="price-summary__description price-summary__description--total">${total.toFixed(2)}</span>
				</div>
				{priceType && (
					<span className="price-summary__price-type">
						Pricing based off {getPriceTypeDisplayName(priceType)}
					</span>
				)}
			</div>
		)
	}
}