import '../styles/CartActions.scss'

import * as React from 'react'
import { ButtonGroup, Button as MuiButton } from '@mui/material'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

import Button from '../../platform/components/Button'
import OrderItemQuantity from './OrderItemQuantity'

const DEFAULT_QUANTITY = 1

interface Props {
	isHnz?: boolean
	uom?: string
	parentQuantity?: number // supports cases where a parent component updates the quantity (ie setting all quantities from the list screen)
	hideCartButton?: boolean
	minValue?: number
	maxValue?: number
	isLabelCart?: boolean
	isGridItem?: boolean // we display a different cart button for grid items
	onAddToCart: (quantity: number) => void
	onUpdateQuantity?: (quantity: number) => void
}

interface State {
	quantity: number
}

const INITIAL_STATE: State = {
	quantity: DEFAULT_QUANTITY,
}

export default class CartActions extends React.PureComponent<Props, State> {

	state = INITIAL_STATE

	componentDidMount() {
		// initialise the quantity field
		this.setState({ quantity: this._getDefaultQuantity() })
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.parentQuantity !== this.props.parentQuantity && this.props.parentQuantity && this.state.quantity !== this.props.parentQuantity) {
			// there's been a change to the parent quantity and it doesn't match the local quantity, update accordingly
			this.setState({ quantity: this.props.parentQuantity })
		}
	}

	/**
	 * Returns the default value for the quantity field.
	 * Note: we set the default value to the parent quantity prop if it exists
	 * @returns the default quantity
	 */
	_getDefaultQuantity = (): number => this.props.parentQuantity || DEFAULT_QUANTITY

	_onUpdateQuantity = () => {
		if (this.props.onUpdateQuantity) {
			this.props.onUpdateQuantity(this.state.quantity)
		}
	}

	_addCartQuantity = () => {
		const newQuantity: number = this.state.quantity + 1
		this.setState({
			// honour the maxValue prop if it exists
			quantity: this.props.maxValue
				? newQuantity > this.props.maxValue ? this.props.maxValue : newQuantity
				: newQuantity === 0 ? DEFAULT_QUANTITY : newQuantity
		}, this._onUpdateQuantity)
	}

	_subtractCartQuantity = () => {
		const newQuantity: number = this.state.quantity - 1
		this.setState({
			// honour the minValue prop if it exists
			quantity: this.props.minValue
				? newQuantity < this.props.minValue || newQuantity === 0 ? this.props.minValue : newQuantity
				: newQuantity === 0 ? -1 : newQuantity
		}, this._onUpdateQuantity)
	}

	_onAddToCart = () => {
		this.props.onAddToCart(this.state.quantity)
		this.setState({ quantity: this._getDefaultQuantity() })
	}

	_renderCartButton = (): JSX.Element => {
		const { quantity } = this.state
		const { isHnz, isLabelCart, isGridItem } = this.props
		const isNegativeCartQuantity: boolean = quantity < 0

		if (isNegativeCartQuantity) {
			return (
				<Button
					kind="red"
					fullWidth={!isGridItem}
					onClick={this._onAddToCart}
					sx={{ boxShadow: 2 }}
					classes={{
						root: 'cart-actions__button--cart',
					}}
				>
					{isGridItem ? <span className="icon-shopping-cart" /> : `Remove from ${isLabelCart ? 'Label ' : ''}Cart`}
				</Button>
			)
		} else if (isHnz) {
			return (
				<Button
					kind="hnz"
					fullWidth={!isGridItem}
					onClick={this._onAddToCart}
					sx={{ boxShadow: 2 }}
					classes={{
						root: 'cart-actions__button--cart',
					}}
				>
					{isGridItem ? <span className="icon-shopping-cart" /> : `Add to ${isLabelCart ? 'Label ' : ''}Cart`}
				</Button>
			)
		}
		return (
			<Button
				kind="black"
				fullWidth={!isGridItem}
				onClick={this._onAddToCart}
				sx={{ boxShadow: 2 }}
				classes={{
					root: 'cart-actions__button--cart',
				}}
			>
				{isGridItem ? <span className="icon-shopping-cart" /> : `Add to ${isLabelCart ? 'Label ' : ''}Cart`}
			</Button>
		)
	}

	_handleOnUpdateQuantity = (newQuantity: number) => {
		let quantity
		if (this.props.minValue !== undefined && newQuantity < this.props.minValue) {
			quantity = this.props.minValue
		} else if (this.props.maxValue !== undefined && newQuantity > this.props.maxValue) {
			quantity = this.props.maxValue
		} else {
			quantity = newQuantity
		}
		this.setState({ quantity }, this._onUpdateQuantity)
	}

	render() {
		const { quantity } = this.state
		const { hideCartButton, minValue, uom } = this.props

		return (
			<div className="cart-actions">
				<ButtonGroup
					classes={{
						root: 'cart-actions__button-group',
					}}
					orientation="horizontal"
					fullWidth={true}
					sx={{ boxShadow: 2 }}
				>
					<MuiButton
						disabled={minValue ? quantity <= minValue : false}
						onClick={this._subtractCartQuantity}
						classes={{
							root: 'cart-actions__button',
						}}
					>
						<RemoveRoundedIcon sx={{ fontSize: 30 }} />
					</MuiButton>

					<OrderItemQuantity
						itemQuantity={quantity.toString()}
						hideUom={true}
						uom={uom}
						onUpdateQuantity={this._handleOnUpdateQuantity}
					/>

					<MuiButton
						onClick={this._addCartQuantity}
						classes={{
							root: 'cart-actions__button',
						}}
					>
						<AddRoundedIcon sx={{ fontSize: 30 }} />
					</MuiButton>
				</ButtonGroup>

				{!hideCartButton && this._renderCartButton()}
			</div>
		)
	}
}