import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { GetSearchFiltersRequest, SearchProductsRequest, SearchRequest } from 'typescript-fetch-api'

import * as actions from './actions'
import * as productsActions from '../../common/products/actions'
import * as accountsActions from '../../common/accounts/actions'
import { AccountStatus, UserAccount } from '../../common/accounts/types'
import { selectedAccountSelector } from '../../common/order/selectors'
import { callApi } from '../../common/api/functions'
import { getContentApi } from '../../common/api'
import { formatCategoryResponse } from '../categories/functions'
import { getPriceParams } from '../../common/auth/functions'
import { paginationSizeSelector } from '../platform/selectors'
import { productCategorySelector } from '../../common/products/selectors'
import { HnzSearchFilter } from './types'
import { INITIAL_PAGE } from '../platform/content'
import * as NavigationManager from '../navigation/NavigationManager'

function handleNavigateToHnzCategory(action: actions.SelectHnzCategoryAction) {
	if (action.payload) {
		const { categoryId, categoryTitle } = action.payload
		NavigationManager.navigateToHnzCategory(categoryId, categoryTitle)
	} else {
		NavigationManager.navigateToHnzProducts()
	}
}

/**
 * Listens to any updates to the users accounts and shows the hnz new feature bottom sheet when a newly approved account is an hnz one.
 * @param action Action containing the approved account id
 */
function* handleCheckIsHnzApprovedAccount(action: accountsActions.GetAccountsRequestSuccessAction): SagaIterator {
	const { result: { accounts }, params: { accountId } } = action.payload
	// we check if the account exists and if it's an approved hnz one
	const isApprovedHnzAccount: boolean = !!accounts && accounts.some(account => (
		account.customerId === accountId && account.registrationState === AccountStatus.Approved && !!account.hnz
	))
	if (isApprovedHnzAccount) {
		yield put(actions.showHnzNewFeatureDialog(true))
	}
}

function* loadHnzCategories(action: actions.HnzAction): SagaIterator {
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const customerId: number | undefined = selectedAccount ? selectedAccount.customerId : undefined

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadHnzCategories,
		() => (
			getContentApi().getTopLevelCategory({ customerId }).then(response => (
				response.categories ? formatCategoryResponse(response.categories) : []
			))
		))
}

function* loadHnzProducts(action: actions.LoadHnzProductsAction): SagaIterator {
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId } = getPriceParams(selectedAccount)
	const pageSize: number = yield select(paginationSizeSelector)
	const searchRequest: SearchRequest = { filters: [HnzSearchFilter] }

	const productCategory: string | undefined = yield select(productCategorySelector)
	// clear any productCategory saved in the store, if any, since it wouldn't match with the products being loaded
	if (productCategory) {
		yield put(productsActions.clearSelectedCategory())
	}

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadHnzProducts,
		(payload: actions.LoadHnzProductsPayload | undefined) => {
			const requestParameters: SearchProductsRequest = {
				categoryId: payload ? payload.categoryId : undefined,
				page: INITIAL_PAGE,
				pageSize,
				customerId,
				searchRequest,
			}
			return getContentApi().searchProducts(requestParameters)
				.then(response => ({ products: response.products || [], totalProductPagesCount: response.totalPagesCount }))
		})
}

function* loadHnzProductFilters(action: actions.LoadHnzProductsAction): SagaIterator {
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadFiltersForHnzProducts,
		(payload: actions.LoadHnzProductsPayload) => {
			const categoryId: string | undefined = payload.categoryId
			const query: string | undefined = categoryId ? undefined : '' // we default to an empty string since either the query or categoryId should be populated for the request to go through

			const requestParameters: GetSearchFiltersRequest = {
				query,
				categoryId,
				customerId,
				includePrices,
				hnzProductsOnly: true,
			}
			return getContentApi().getSearchFilters(requestParameters)
				.then(response => response.filters || [])
		})
}

export default function* (): SagaIterator {
	yield takeEvery(actions.selectHnzCategory, handleNavigateToHnzCategory)
	yield takeEvery(accountsActions.getAccounts.done, handleCheckIsHnzApprovedAccount)
	yield takeEvery(actions.loadHnzCategories.started, loadHnzCategories)
	yield takeEvery(actions.loadHnzProducts.started, loadHnzProducts)
	yield takeEvery(actions.loadHnzProducts.started, loadHnzProductFilters)
}