import React from 'react'
import MuiButton from '@mui/material/Button'

import { LogoBlack } from '../../../utils/imagesProvider'
import Button from '../../platform/components/Button'

import '../../platform/styles/NewFeaturePage.scss'

type HeadingImage = 'pwgo' | 'fergus' | 'fergus-dark'

interface Props {
	background: string
	headingImage?: HeadingImage
	backgroundClass?: string
	buttonText?: string
	onNext?: () => void
	onClose?: () => void
}

const IntegrationNewFeaturePage: React.FunctionComponent<Props> = ({ background, headingImage, backgroundClass = '', buttonText = 'NEXT', onNext, onClose }: Props) => {

	const _getHeadingImage = (headingImage: HeadingImage) => {
		switch (headingImage) {
			case 'pwgo':
				return (
					<React.Fragment>
						<img src={LogoBlack} alt="PlumbingWorld Logo" className="page__logo--heading page__logo--pw-heading" />

						<MuiButton
							className="page__button--skip"
							onClick={onClose}
						>
							SKIP
						</MuiButton>
					</React.Fragment>
				)
			case 'fergus':
				return <span className="icon-fergus" />
			case 'fergus-dark':
				return <span className="icon-fergus icon-fergus--dark" />
		}
	}

	return (
		<div className="page">
			<div className="page__heading">
				{headingImage && _getHeadingImage(headingImage)}
			</div>

			<div className="page__content">
				<img src={background} alt="Background" className={`page__background ${backgroundClass}`} />
			</div>

			<div className="page__footer">
				<Button
					kind="white"
					className="button--fixed-width"
					onClick={onNext}
				>
					{buttonText}
				</Button>
			</div>
		</div>
	)
}

export default IntegrationNewFeaturePage
