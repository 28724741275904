import { SagaIterator } from 'redux-saga'
import { takeEvery, call, put, select } from 'redux-saga/effects'
import { CarouselPlatform } from 'typescript-fetch-api'

import * as actions from '../../common/versionUpdates/actions'
import { login } from '../../common/auth/actions'
import { readyAction } from '../../common/root/actions'
import { authTokenSelector } from '../../common/auth/selectors'
import * as NavigationManager from '../navigation/NavigationManager'

function* handleLoadWebVersionUpdatesOnLogin(): SagaIterator {
	const payload: actions.FetchVersionUpdatesPayload = {
		page: 0,
		pageSize: 100,
		platform: CarouselPlatform.WEB,
	}
	yield put(actions.fetchingVersionUpdates.started(payload))
}

function* handleLoadWebVersionUpdatesOnReady(): SagaIterator {
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (hasAuthToken) {
		yield call(handleLoadWebVersionUpdatesOnLogin)
	}
}

function handleNavigateToVersionUpdates() {
	NavigationManager.navigateToVersionUpdates()
}

export default function* (): SagaIterator {
	yield takeEvery(readyAction, handleLoadWebVersionUpdatesOnLogin)
	yield takeEvery(login.done, handleLoadWebVersionUpdatesOnReady)
	yield takeEvery(actions.navigateToVersionUpdates, handleNavigateToVersionUpdates)
}
