export default {
	white: '#FFFFFF',
	black: '#000000',
	ivory: '#FCFCFB',
	mediumAquamarine: '#74C2A5',
	keppel: '#66BD9B',
	clearDay: '#E0F1EB',
	jumbo: '#8C8D87',
	starDust: '#9C9D97',
	silver: '#BEBEBE',
	fireEngineRed: '#D5181F',
	alizarin: '#ED1B23',
	azalea: '#F4BABC',
	selectiveYellow: '#FFBB00',
	pineTree: '#161715',
	lightGrey: '#D3D3D3',
	delta: '#90928C',
	snowDrift: '#E2E2E1',
	watercourse: '#007749', // hnz mode color
	eucalyptus: '#33926D',
	eucalyptusLight: '#3e9e79',
	darkOrange: '#FF8800',
	grey: '#828282',
	brinkPink: '#F2607B',
	blush: '#C24D62',
	darkGrey: '#AFAFAF',
	nobel: '#949494',
	nightRider: '#333333',
	hummingBird: '#CFEAE0',
	freeSpeechRed: '#B70000',
	shingleFawn: '#7B5F39',
	suvaGrey: '#8E8E8E',
}