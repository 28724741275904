import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { MobileVerificationRequest, EmailVerificationRequest } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('MobileValidation')

// payload determines whether to take the user to checkout screen afterwards
export interface MobileVerificationRequestPayload {
	mobileVerificationRequest: MobileVerificationRequest
	goCheckoutAfter: boolean
	fromProfile?: boolean
}
export type MobileVerificationRequestAction = Action<MobileVerificationRequestPayload>
export type MobileVerificationRequestSuccessAction = Action<Success<MobileVerificationRequestPayload, Response>>
export const verifyMobile = actionCreator.async<MobileVerificationRequestPayload, Response, Error>('MOBILE_VERIFICATION')

export interface EmailVerificationRequestPayload {
	emailVerificationRequest: EmailVerificationRequest
}
export type EmailVerificationRequestAction = Action<EmailVerificationRequestPayload>
export type EmailVerificationRequestSuccessAction = Action<Success<EmailVerificationRequestPayload, Response>>
export const verifyEmail = actionCreator.async<EmailVerificationRequestPayload, Response, Error>('EMAIL_VERIFICATION')

export interface ResendVerificationCodePayload {
	mobileNumber: string
	password?: string
	goCheckoutAfter: boolean
}
export type ResendVerificationCodeAction = Action<ResendVerificationCodePayload>
export const resendVerificationCode = actionCreator.async<ResendVerificationCodePayload, Response, Error>('RESEND_VERIFICATION_CODE')