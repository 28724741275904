import { Channel, SagaIterator } from 'redux-saga'
import { actionChannel, call, fork, put, take } from 'redux-saga/effects'

import * as actions from '../../common/remoteConfig/actions'
import { getPlatformSupportImplementation } from '../platform'

function* handleFetchRemoteConfig(action: actions.FetchRemoteConfigAction): SagaIterator {
	const remoteConfig = action.payload
	try {
		const remoteConfigValue = yield call(getPlatformSupportImplementation().getRemoteConfigByKey, remoteConfig)
		yield put(actions.onUpdateRemoteConfig({ remoteConfig, value: remoteConfigValue }))
	} catch (error) {
		getPlatformSupportImplementation().reportError('Error fetching remote config for ' + remoteConfig, error)
	}
}

function* fetchRemoteConfigChannel() {
	// create a channel for request actions to fetch remote config
	const requestChannel: Channel<actions.FetchRemoteConfigAction> = yield actionChannel(actions.fetchRemoteConfig.type)
	while (true) {
		// take from the channel
		const action: actions.FetchRemoteConfigAction = yield take(requestChannel)
		// use a blocking call and perform the requests sequentially
		yield call(handleFetchRemoteConfig, action)
	}
}

export default function* (): SagaIterator {
	yield fork(fetchRemoteConfigChannel)
}