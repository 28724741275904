import * as React from 'react'
import { Popper, Fade, Paper, ClickAwayListener } from '@mui/material'
import { SubheaderMenuProps } from '../types'
import useWindowDimensions from '../../../common-ui/utils/hooks/useWindowDimensions'

interface Props extends SubheaderMenuProps {
	titleText: string
	children: React.ReactNode
	anchorBottom?: boolean // if true popper will be anchored to bottom (for menu items near bottom of the screen)
	anchorOffset?: number // anchor position offset - used to calculate max height of popper
}

const MenuPopper = ({ anchorElement, titleText, children, anchorBottom, anchorOffset, onCloseSubmenu, onClickAway }: Props): JSX.Element => {

	const { isSmallScreen, height } = useWindowDimensions()

	// small screens don't use popper component, they replace the drawer content instead
	if (isSmallScreen) {
		return (
			<div className="menu-drawer__list">
				<div className="menu-drawer__popper--title-container menu-drawer__popper--title-container__small">
					<span className="icon-keyboard_arrow_left" onClick={onCloseSubmenu} />
					<span className="menu-drawer__popper--title">{titleText}</span>
				</div>

				{children}
			</div>
		)
	}

	return (
		<Popper
			open={true}
			anchorEl={anchorElement}
			transition={true}
			modifiers={[
				{
					name: 'preventOverflow',
					enabled: false, // position the popper outside the bounds of the menu drawer
				},
				{
					name: 'flip',
					enabled: true, // flips to other side if no room
				},
				{
					name: 'offset',
					enabled: true,
					options: {
						offset: anchorBottom ? [0, 0] : anchorOffset !== undefined ? [-56, 0] : [0, 0], // move up 56, move right 4 (to create gap)
					}
				},
			]}
			style={{ zIndex: 1500 }} // needs to be higher than Drawer default zIndex
			placement={anchorBottom ? 'right-end' : 'right-start'}
			className="menu-drawer__popper"
		>
			{({ TransitionProps }) => (
				<ClickAwayListener
					onClickAway={onClickAway}
					// disable the listeners since we are listening to events via the anchor element
					mouseEvent={false}
					touchEvent={false}
				>
					<Fade {...TransitionProps}>
						<Paper square={true}>
							<div className="menu-drawer__popper--title-container">
								<span className="menu-drawer__popper--title">{titleText}</span>
							</div>

							{/* give children container a max height so content will be scrollable on small screens */}
							{/* max height = screen height - drawer height - popper bottom margin */}
							<div className="menu-drawer__popper--children" style={{ maxHeight: height - 110 - (anchorOffset || 0) }}>
								{children}
							</div>
						</Paper>
					</Fade >
				</ClickAwayListener >
			)}
		</Popper >
	)
}

export default MenuPopper