import '../styles/ProductUom.scss'

import { ButtonGroup, Button } from '@mui/material'

type Props = {
	uoms: string[]
	selectedUom: string
}

const ProductUom = (props: Props) => {

	return (
		<ButtonGroup
			classes={{
				root: 'product-uom',
				grouped: 'product-uom__button--grouped',
			}}
			orientation="horizontal"
		>
			{props.uoms.map(uom => {
				const isSelected = props.selectedUom === uom
				return (
					<Button
						key={uom}
						variant={isSelected ? 'contained' : 'outlined'}
						classes={{
							root: isSelected ? 'product-uom__button product-uom__button--selected' : 'product-uom__button',
						}}
					>
						{uom}
					</Button>
				)
			})}
		</ButtonGroup>
	)
}

export default ProductUom