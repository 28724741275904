import '../styles/VersionUpdatesDialog.scss'

import React from 'react'
import { Button, DialogActions, DialogContent, Divider, IconButton, Dialog } from '@mui/material'
import { VersionUpdate, VersionUpdateItem } from 'typescript-fetch-api'

import * as images from '../../../utils/imagesProvider'
import { NewFeatureDialogProps } from '../../platform/types'
import useNewFeatureDialog from '../../../utils/hooks/useNewFeatureDialog'

const LINE_BREAK = '\\n'

interface Props {
	versionUpdate: VersionUpdate
}

const VersionUpdatesDialog = ({ isUnseen, onRemoveFromUnseenList, versionUpdate }: NewFeatureDialogProps & Props): JSX.Element => {

	/**
	 * Local State
	 */

	const { isOpen } = useNewFeatureDialog({
		showDialog: false,
		forceShowDialog: isUnseen,
	})

	/**
	 * Local Functions 
	 */

	const _onClose = () => {
		if (isUnseen) {
			// user has seen the dialog at least one time, update parent component to remove from unseen list
			onRemoveFromUnseenList()
		}
	}

	return (
		<Dialog
			open={isOpen}
			maxWidth="md"
			fullWidth={true}
			onClose={_onClose}
			className="version-updates-dialog"
		>
			<div className="version-updates-dialog__header">
				<img
					src={images.Logo}
					alt="pwGO Logo"
					className="version-updates-dialog__logo"
				/>

				<IconButton
					onClick={_onClose}
					className="version-updates-dialog__header--button"
				>
					<span className="icon-close-round version-updates-dialog--icon-close" />
				</IconButton>

				<h5>{versionUpdate.description}</h5>
				<div className="version-updates-dialog__divider--container">
					<Divider className="version-updates-dialog__divider" />
					<div className="version-updates-dialog__divider--info">
						<span className="icon-new_releases version-updates-dialog__new-releases" />
						<span className="version-updates-dialog__divider--text">{versionUpdate.title}</span>
					</div>
					<Divider className="version-updates-dialog__divider" />
				</div>
			</div>

			<DialogContent>
				<h5 className="version-updates-dialog__content--title">Update includes:</h5>
				<div className="version-updates-dialog__content">
					{versionUpdate.items && versionUpdate.items.map((item: VersionUpdateItem) => {
						// split the description so we can add line breaks
						const descriptions = item.description.split(LINE_BREAK)
						return (
							<div
								key={item.title}
								className="version-updates-dialog__item"
							>
								<div className="version-updates-dialog__item--image">
									{(item.iconUrl || item.ironLabel) &&
										<div className="version-updates-dialog__item--circle">
											{item.iconUrl && (
												<img
													src={item.iconUrl}
													alt={item.ironLabel || 'Update'}
													className="version-updates-dialog__image"
												/>
											)}
											{item.ironLabel && <span className="version-updates-dialog__label">{item.ironLabel}</span>}
										</div>
									}
								</div>
								<div className="version-updates-dialog__item--content">
									<span className="version-updates-dialog__item--title">{item.title}</span>
									{descriptions.map((description, index) => (
										<React.Fragment key={index}>
											<span className="version-updates-dialog__item--description">
												{description}
											</span>
											{index !== descriptions.length - 1 && <br />}
										</React.Fragment>
									))}
								</div>
							</div>
						)
					})}
				</div>
			</DialogContent>

			{versionUpdate.url && (
				<DialogActions disableSpacing={true}>
					<Button
						variant="contained"
						color="primary"
						className="button--fixed-width"
						onClick={() => {
							_onClose()
							window.open(versionUpdate.url!)
						}}
					>
						{versionUpdate.buttonText ? versionUpdate.buttonText.toUpperCase() : 'LEARN MORE'}
					</Button>
				</DialogActions>
			)}
		</Dialog>
	)
}

export default VersionUpdatesDialog
