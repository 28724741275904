import '../styles/Footer.scss'

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AccountPermission } from 'typescript-fetch-api'
import moment from 'moment'

import { PW_SOCIALS } from '../../contactus/content'
import { APP_STORE_URL, PLAY_STORE_URL } from '../../auth/content'
import { BadgeApple, BadgeGoogle } from '../../../utils/imagesProvider'
import { GeneralScreens } from '../../general/content'
import { WebStoreState } from '../types'
import { navigateToContactUs } from '../../../common/contactus/actions'
import { navigateToTermsAndConditions, navigateToPrivacyPolicy } from '../../../common/profile/actions'
import { UserAccount } from '../../../common/accounts/types'
import { navigateToTab, navigateToOrdersTab, navigateToBranchQuotes } from '../actions'
import { logoutRequest } from '../../../common/auth/actions'
import { navigateToRegions } from '../../../common/showrooms/actions'
import { showHnzNewFeatureDialog } from '../../hnz/actions'
import { showFergusNewFeatureDialog, showXeroNewFeatureDialog } from '../../integrations/actions'
import { AROFLO_ONBOARDING, SIMPRO_ONBOARDING } from '../../integrations/content'
import { navigateToVersionUpdates } from '../../../common/versionUpdates/actions'
import { getIsAroFloProcurementUser } from '../../../common/auth/functions'
import { Paths } from '../../navigation/types'

const Footer = (): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()
	const location = useLocation()

	// needed a way to update the footer when the user navigates away from the AroFlo sign in page
	const isAroFloSignInPage = location.pathname.indexOf(Paths.AROFLO_PUNCHOUT) > -1

	/**
	 * Store State
	 */

	const isLoggedIn = useSelector<WebStoreState, boolean>(state => !!state.auth.authToken)
	const selectedAccount = useSelector<WebStoreState, UserAccount | undefined>(state => state.order.selectedAccount)
	const isAroFloProcurementUser = useSelector<WebStoreState, boolean>(state => getIsAroFloProcurementUser(state.auth.username))

	/**
	 * Local Functions
	 */

	const _onNavigateToHome = () => {
		dispatch(navigateToTab({ tab: 'home' }))
	}

	const _onNavigateToLists = () => {
		dispatch(navigateToTab({ tab: 'lists' }))
	}

	const _onNavigateToContactUs = () => {
		dispatch(navigateToContactUs())
	}

	const _onNavigateToRegions = () => {
		dispatch(navigateToRegions())
	}

	const _onNavigateToOrders = () => {
		dispatch(navigateToOrdersTab({ tab: 'current-orders' }))
	}

	const _onNavigateToQuotes = () => {
		dispatch(navigateToBranchQuotes())
	}

	const _onSignOut = () => {
		dispatch(logoutRequest({}))
	}

	const _onShowFergusNewFeatureDialog = () => {
		dispatch(showFergusNewFeatureDialog(true))
	}

	const _onShowXeroNewFeatureDialog = () => {
		dispatch(showXeroNewFeatureDialog(true))
	}

	// check if the user has a selected account and it is an hnz one
	const hasHnzAccountSelected = !!selectedAccount && !!selectedAccount.hnz
	// check if the user has a selected account and it has the edit integrations permission
	const canEditIntegrations = selectedAccount?.permissions?.some(permission => permission === AccountPermission.EDIT_INTEGRATIONS)

	return (
		<div className={`max-container footer${isLoggedIn && !isAroFloSignInPage ? ' footer-auth' : ''}`}>
			<div className="footer__content">

				{isLoggedIn && !isAroFloSignInPage && (
					<React.Fragment>
						<div className="footer__top">
							<div className="footer__top-item">
								<span className="footer__text--heading">PWGO</span>

								<span className="footer__text" onClick={_onNavigateToHome}>Home</span>
								<span className="footer__text" onClick={_onNavigateToLists}>View Lists</span>
								{!isAroFloProcurementUser && <span className="footer__text" onClick={_onNavigateToOrders}>Current Orders</span>}
								{!isAroFloProcurementUser && <span className="footer__text" onClick={_onNavigateToQuotes}>Branch Quotes</span>}
								<span className="footer__text" onClick={_onSignOut}>Sign out</span>
							</div>

							<div className="footer__top-item">
								<span className="footer__text--heading">HELP</span>

								{/* TODO: Update FAQ link on update of design and content in XD. */}
								{/* <span className="footer__text">
										<a href="#" target="_blank" rel="noopener noreferrer">
											FAQ
										</a>
									</span> */}
								<span className="footer__text" onClick={_onNavigateToContactUs}>Contact us</span>
								<span className="footer__text" onClick={_onNavigateToRegions}>Find a branch</span>
								{hasHnzAccountSelected && (
									<span className="footer__text" onClick={() => { dispatch(showHnzNewFeatureDialog(true)) }}>About Kāinga Ora</span>
								)}
								<span className="footer__text" onClick={() => { dispatch(navigateToVersionUpdates()) }}>Version Updates</span>
							</div>

							{canEditIntegrations && (
								<div className="footer__top-item">
									<span className="footer__text--heading">INTEGRATIONS</span>
									<span className="footer__text" onClick={_onShowFergusNewFeatureDialog}>About Fergus Integration</span>
									<span className="footer__text" onClick={_onShowXeroNewFeatureDialog}>About Xero Integration</span>
									<a target="_blank" rel="noopener noreferrer" href={SIMPRO_ONBOARDING} className="footer__text">
										About simPRO Integration
									</a>
									<a target="_blank" rel="noopener noreferrer" href={AROFLO_ONBOARDING} className="footer__text">
										About AroFlo Integration
									</a>
								</div>
							)}

							<div className="footer__top-item">
								<span className="footer__text--app-heading">DOWNLOAD PWGO APP</span>

								<div className="footer__badges">
									<a href={APP_STORE_URL} target="_blank" rel="noopener noreferrer">
										<img src={BadgeApple} className="footer__image--apple" alt="Apple App Store" />
									</a>
									<a href={PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
										<img src={BadgeGoogle} className="footer__image--google" alt="Google Play Store" />
									</a>
								</div>

								<span className="footer__text--heading">FOLLOW US</span>

								<span>
									{PW_SOCIALS.map(item => (
										<a key={item.icon} className="footer__social" href={item.url} target="_blank" rel="noopener noreferrer">
											<span className={`${item.icon} footer__icons--social`} />
										</a>
									))}
								</span>
							</div>
						</div>

						<div className="footer__separator" />
					</React.Fragment>
				)}

				<div className="footer__bottom">
					<div>
						<span className="footer__text">
							Copyright © {moment().year()} Plumbing World Ltd.
							&nbsp;&nbsp;|&nbsp;&nbsp;
							V{process.env.REACT_APP_VERSION}
						</span>

						{isLoggedIn && !isAroFloSignInPage && (
							<div className="footer__links footer__text">
								<span className="footer__links--clickable" onClick={() => { dispatch(navigateToTermsAndConditions()) }}>{GeneralScreens.toc.title}</span>
								<span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
								<span className="footer__links--clickable" onClick={() => { dispatch(navigateToPrivacyPolicy()) }}>{GeneralScreens.privacyPolicy.title}</span>
							</div>
						)}
					</div>

					<span className="footer__icons">
						<span className="icon-plumbing-world" />
						<span className="icon-metrix" />
						<span className="icon-nzpm" />
					</span>
				</div>
			</div>
		</div>
	)
}

export default Footer
