import { SagaIterator } from 'redux-saga'
import { takeEvery, put } from 'redux-saga/effects'
import { Location } from 'react-router-dom'
import { Action } from 'typescript-fsa'
import { AccountPermission } from 'typescript-fetch-api'

import { accountSelected, AccountSelectedAction } from '../../common/cart/actions'
import { UserAccount } from '../../common/accounts/types'
import { getAccountDetails, getAccountSummary, getPaymentLink, GetPaymentLinkSuccessAction, getStatements, navigateToFinancialsTab, NavigateToFinancialTabAction } from '../../common/financials/actions'
import * as NavigationManager from '../navigation/NavigationManager'
import { Paths } from '../navigation/types'
import { FinancialsTabEnum } from '../../common/financials/types'

/**
 * Listens when a new account is selected and updates the financial statements.
 */
function* updateStatements(action: Action<UserAccount>): SagaIterator {
	const location: Location | undefined = NavigationManager.getLocation()
	// check if we are in a screen related to statements / account balance
	const isStatementsOrAccountsUrl: boolean = location
		? location.pathname.startsWith(Paths.FINANCIALS_STATEMENTS) || location.pathname.startsWith(Paths.FINANCIALS_ACCOUNT_BALANCE)
		: false

	if (isStatementsOrAccountsUrl) {
		const selectedAccount: UserAccount = action.payload
		const hasStatementsPermission: boolean = selectedAccount.permissions
			? selectedAccount.permissions.some(item => item === AccountPermission.VIEW_STATEMENTS)
			: false

		// only make call to update statements data if the newly selected account has permissions to view statements
		if (hasStatementsPermission) {
			yield put(getStatements.started({ accountId: selectedAccount.customerId }))
		}

		// only make call to fetch credit limit if the new account has the required permission
		const hasCreditLimitPermission: boolean = selectedAccount.permissions
			? selectedAccount.permissions.some(item => item === AccountPermission.VIEW_CREDIT_LIMIT)
			: false
		if (hasCreditLimitPermission) {
			yield put(getAccountDetails.started({ accountId: selectedAccount.customerId }))
		}
	}
}

function handleNavigateToFinancialTab(action: NavigateToFinancialTabAction) {
	const { tab } = action.payload
	// statements is now under the account balance tab
	if (tab === 'statements') {
		NavigationManager.navigateToFinancialTab(FinancialsTabEnum.ACCOUNT_BALANCE)
	} else {
		NavigationManager.navigateToFinancialTab(tab)
	}
}

function handleNavigateToPaymentLink(action: GetPaymentLinkSuccessAction) {
	window.open(action.payload.result.link, '_blank')
}

function* handleGetAccountSummary(action: AccountSelectedAction) {
	yield put(getAccountSummary.started({ accountId: action.payload.customerId }))
}

export default function* (): SagaIterator {
	yield takeEvery(accountSelected, updateStatements)
	yield takeEvery(accountSelected, handleGetAccountSummary)
	yield takeEvery(navigateToFinancialsTab, handleNavigateToFinancialTab)
	yield takeEvery(getPaymentLink.done, handleNavigateToPaymentLink)
}