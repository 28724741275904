import { IntegrationCompany } from 'typescript-fetch-api'

import { Role, AccountStatus, getAccountStatusDisplayName } from '../../common/accounts/types'

export type AdminTabOptions = 'integrations' | 'price-book'

export type AdminNavItem = { title: string, path: AdminTabOptions | undefined }
export const ADMIN_USERS_NAV_ITEM: AdminNavItem = { title: 'Users', path: undefined }
export const ADMIN_PRICE_BOOK_NAV_ITEM: AdminNavItem = { title: 'Price Book', path: 'price-book' }
export const ADMIN_INTEGRATIONS_NAV_ITEM: AdminNavItem = { title: 'Integrations', path: 'integrations' }

/**
 * Contains the basic details needed for a user profile.
 */
export interface BasicUserProfile {
	firstName?: string
	lastName?: string
	email?: string
	mobileNumber?: string
}

export type AdminNavigatorNavigationParams = { screen?: AdminTabOptions, subScreen?: IntegrationCompany }

/**
 * ROLE
 */

export interface RoleSelectType {
	value: Role
	label: Role
}

export const ROLE_SELECT: Record<Role, RoleSelectType> = {
	'Admin': { value: Role.Admin, label: Role.Admin },
	'Principal': { value: Role.Principal, label: Role.Principal },
	'Trade+': { value: Role.TradePlus, label: Role.TradePlus },
	'Trade': { value: Role.Trade, label: Role.Trade },
	'Procurement': { value: Role.Procurement, label: Role.Procurement }, // add this here even though it won't be a visible option to user
}

/**
 * STATUS
 */

export interface StatusSelectType {
	value: AccountStatus
	label: string
}

export const STATUS_SELECT: Record<AccountStatus, StatusSelectType> = {
	'approved': { value: AccountStatus.Approved, label: getAccountStatusDisplayName(AccountStatus.Approved) },
	'deleted': { value: AccountStatus.Deleted, label: getAccountStatusDisplayName(AccountStatus.Deleted) },
	'disabled': { value: AccountStatus.Disabled, label: getAccountStatusDisplayName(AccountStatus.Disabled) },
	'pending': { value: AccountStatus.Pending, label: getAccountStatusDisplayName(AccountStatus.Pending) },
	'unassigned': { value: AccountStatus.Unassigned, label: getAccountStatusDisplayName(AccountStatus.Unassigned) },
}

export enum EditLoginIdDialogType {
	PROFILE, NOTIFY_ME
}