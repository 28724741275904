import { IntegrationCompany } from 'typescript-fetch-api'
import { Role } from '../../common/accounts/types'

export const RoleDetails: Record<Role, string[]> = {
	Principal: [
		'View Cost & Recommended Retail Pricing',
		'Create Lists',
		'Create Orders',
		'View & Download - Orders, Quotes, Invoices & Statements',
		'View Account Balance',
		'View Account Credit Limit',
		'Manage own User Profile',
		'Assign Trade or Admin Roles to Users',
		'Enable / disable Users',
		'Remove Users',
		'Manage Integrations',
	],
	Admin: [
		'View Cost & Recommended Retail Pricing',
		'Create Lists',
		'Create Orders',
		'View & Download - Orders, Quotes, Invoices & Statements',
		'View Account Balance',
		'Manage own User Profile',
		'Assign Trade or Admin Roles to Users',
		'Enable / disable Users',
		'Manage Integrations',
	],
	'Trade+': [
		'View Cost & Recommended Retail Pricing',
		'Create lists',
		'Create orders',
		'View Current Orders and Branch Quotes',
		'Update User Profile',
	],
	Trade: [
		'View Recommended Retail Pricing only',
		'Create lists',
		'Create orders',
		'View Current Orders and Branch Quotes',
		'Update User Profile',
	],
	Procurement: [
		'View Cost & Recommended Retail Pricing',
		'Create lists',
		'Create AroFlo orders',
	],
}

export const INTEGRATION_NAV_ITEMS: { title: string, path: IntegrationCompany }[] = [
	{ title: 'Xero', path: IntegrationCompany.XERO },
	{ title: 'simPRO', path: IntegrationCompany.SIMPRO },
	{ title: 'Fergus', path: IntegrationCompany.FERGUS },
	{ title: 'AroFlo', path: IntegrationCompany.AROFLO },
	{ title: 'iTrade', path: IntegrationCompany.ITRADE },
]

export const SHARES_EMAIL = 'shares@nzpm.co.nz'