import { SagaIterator } from 'redux-saga'
import { call, takeEvery } from 'redux-saga/effects'

import * as actions from './actions'
import { ApiErrorMessage, getRegisterApi } from '../api'
import { callApi } from '../api/functions'
import { getPlatformSupportImplementation } from '../platform'

function* handleForgotPasswordStarted(action: actions.ForgotPasswordAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.forgotPassword,
		(payload: actions.ForgotPasswordPayload) => {
			return getRegisterApi().resetPassword({
				resetPasswordRequest: { user: payload.user },
			})
		})
}

function* handleChangePasswordStarted(action: actions.ChangePasswordRequestAction): SagaIterator {
	// get push token
	const token = yield call(getPlatformSupportImplementation().getFCMToken)
	// perform the set new password request
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.changePassword,
		(payload: actions.ChangePasswordRequestPayload) => {
			return getRegisterApi()
				.resetNewPassword({
					confirmResetPassword: { ...payload.changePasswordRequest, pushToken: token }
				})
				.catch((error) => {
					let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
					if (error instanceof Response) {
						switch (error.status) {
							case 401:
								errorText = 'Invalid username/password supplied'
								getPlatformSupportImplementation().handleIncorrectSMSCode(action, errorText)
								break
							case 403:
								errorText = 'Invalid verification code'
								getPlatformSupportImplementation().handleIncorrectSMSCode(action, errorText)
								break
							case 406:
								errorText = 'Invalid password'
								getPlatformSupportImplementation().handleIncorrectSMSCode(action, errorText)
								break
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
		})
}

export default function* (): SagaIterator {
	yield takeEvery(actions.forgotPassword.started, handleForgotPasswordStarted)
	yield takeEvery(actions.changePassword.started, handleChangePasswordStarted)
}