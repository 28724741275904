import { TabOptions } from './actions'
import { Breadcrumb, NewFeatureLaunch, CustomAppVersions } from './types'
import { Partners } from '../integrations/types'

/**
 * Contents for subheader, both for authenticated and not authenticated users
 * - title: Title of route to appear in tab
 * - route: Parent route to navigate to on tab click
 * - pathnames: Possible paths to navigate to (so the bottom indicator will be shown)
 */
export type SubheaderItem = {
	title: 'Products' | 'Orders' | 'Financials' | 'Admin' | 'Lists' | 'Sign In' | 'Register' | 'Profile' | 'Labels' | 'Xero' | 'simPRO'
	route?: TabOptions
	pathnames?: string[]
}

type SubheaderItemsAuthKey = 'products' | 'orders' | 'financials' | 'admin' | 'lists' | 'financials' | 'profile' | 'labels'
type SubheaderItemsGeneralKey = 'signIn' | 'register'

export const SUBHEADER_ITEMS_AUTH: Record<SubheaderItemsAuthKey, SubheaderItem> = {
	products: { title: 'Products', route: undefined, pathnames: ['products', 'product'] },
	orders: { title: 'Orders', route: undefined, pathnames: ['orders'] },
	financials: { title: 'Financials', route: undefined, pathnames: ['financials'] },
	admin: { title: 'Admin', route: undefined, pathnames: ['admin'] },
	lists: { title: 'Lists', route: undefined, pathnames: ['lists'] },
	profile: { title: 'Profile', route: 'profile', pathnames: ['profile'] },
	labels: { title: 'Labels', route: undefined, pathnames: ['labels'] },
}

export const SUBHEADER_ITEMS_GENERAL: Record<SubheaderItemsGeneralKey, SubheaderItem> = {
	signIn: { title: 'Sign In', route: 'signin', pathnames: ['signin', 'resetPassword'] },
	register: { title: 'Register', route: 'register', pathnames: ['register'] },
}

export const SUBHEADER_ITEMS_PARTNERS: Record<Partners, SubheaderItem> = {
	[Partners.XERO]: { title: 'Xero', route: Partners.XERO, pathnames: [Partners.XERO] },
	[Partners.SIMPRO]: { title: 'simPRO', route: Partners.SIMPRO, pathnames: [Partners.SIMPRO] },
}

export const HOME_BREADCRUMB: Breadcrumb = { title: 'Home', type: 'home', isClickable: true }

export const MIN_FILE_COUNT_TO_ZIP = 6
export const INITIAL_PAGE = 0

// outlines the launch dates for each custom new feature so we know what new feature dialogs to display
export const NEW_FEATURES_LAUNCHED: NewFeatureLaunch[] = [
	{
		title: '',
		subtitle: '',
		description: '',
		version: CustomAppVersions.HNZ,
		date: '2020-07-01',
		isHnzOnly: true,
	},
	{
		title: '',
		subtitle: '',
		description: '',
		version: CustomAppVersions.FERGUS,
		date: '2020-08-14',
		isAdminOnly: true,
	},
	{
		title: '',
		subtitle: '',
		description: '',
		version: CustomAppVersions.XERO,
		date: '2021-04-26',
		isAdminOnly: true,
	},
]