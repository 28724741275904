import { RemoteConfig, RemoteConfigFeature, RemoteConfigProperty } from '../../common/remoteConfig/types'
import { firebase } from '../../App'

export const getRemoteConfigByKey = (remoteConfigKey: RemoteConfig): Promise<RemoteConfigFeature> => {
	return firebase.remoteConfig
		.fetchAndActivate()
		.then((_: boolean) => {
			return firebase.remoteConfig.getValue(remoteConfigKey)
		})
		.then((snapshot: firebase.remoteConfig.Value) => {
			const remoteConfigValue: RemoteConfigFeature = JSON.parse(snapshot.asString())
			// check if the appropriate properties has been setup
			if (remoteConfigValue[RemoteConfigProperty.USERS] !== undefined
				|| remoteConfigValue[RemoteConfigProperty.CUSTOMERS] !== undefined
				|| remoteConfigValue[RemoteConfigProperty.AVAILABLE_TO_ALL] !== undefined) {
				return remoteConfigValue
			}
			throw new Error('Remote config data for ' + remoteConfigKey + ' not setup properly')
		})
}