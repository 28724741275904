import * as React from 'react'

import { UserAccount, getAccountStatusForId } from '../../../common/accounts/types'
import { AccountStatus as AccountStatusType } from '../../../common/accounts/types'
import { colors } from '../../platform/muiTheme'
import Tooltip from '../../platform/components/Tooltip'

import '../styles/AccountStatus.css'

interface Props {
	account: UserAccount
}

export default class AccountStatus extends React.PureComponent<Props> {

	_getStatusColor(status: AccountStatusType): string {
		switch (status) {
			case AccountStatusType.Pending:
			case AccountStatusType.Unassigned:
				return colors.orange
			case AccountStatusType.Approved:
				return colors.tealMain
			case AccountStatusType.Disabled:
			case AccountStatusType.Deleted:
				return colors.redMain
			default:
				return colors.greyMain
		}
	}

	_getStatusText(status: AccountStatusType): string {
		switch (status) {
			case AccountStatusType.Pending:
			case AccountStatusType.Unassigned:
				return 'Pending'
			case AccountStatusType.Approved:
				return 'Approved'
			default:
				return 'Disabled'
		}
	}

	render() {
		const { account } = this.props
		const status: AccountStatusType = getAccountStatusForId(account.registrationState!)
		const color: string = this._getStatusColor(status)
		const text: string = this._getStatusText(status)

		return (
			<Tooltip title={text}>
				<span className="account-status" style={{ background: color }} />
			</Tooltip>
		)
	}
}