import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { BranchProductsResponse, ProductPricesResponse, ProductSku, SearchFilter, SearchRequest } from 'typescript-fetch-api'

import { LoadProductsResult } from '../products/actions'
import { ProductFinderType } from './types'

const actionCreator = actionCreatorFactory('ProductFinder')

/**
 * navigation
 */

export type NavigateToProductFinderAction = Action<ProductFinderType>
export const navigateToProductFinder = actionCreator<ProductFinderType>('NAVIGATE_TO_PRODUCT_FINDER')

export type NavigateToProductFinderResultsAction = Action<ProductFinderType>
export const navigateToProductFinderResults = actionCreator<ProductFinderType>('NAVIGATE_TO_PRODUCT_FINDER_RESULTS')

/**
 * load products
 */

export interface LoadProductFinderProductsPayload {
	categoryId?: string
	page?: number
	pageSize?: number
	searchRequest?: SearchRequest // used on web to set stockedInBranch filter for initial products load
	includeAttributes: boolean // include attributes in search filters response (only for HWC finder)
}
export type LoadSubcategoriesAction = Action<LoadProductFinderProductsPayload>
export type LoadProductsSuccessAction = Action<Success<LoadProductFinderProductsPayload, LoadProductsResult>>
export const loadProductFinderProducts = actionCreator.async<LoadProductFinderProductsPayload, LoadProductsResult, Error>('LOAD_PRODUCT_FINDER_PRODUCTS')

// action dispatched when applied filters are changed, need to show loading spinner while reloading products
export const setLoadingProductFinderProductsState = actionCreator('SET_LOADING_PRODUCT_FINDER_PRODUCTS_STATE')

/**
 * load filters
 */

export type LoadProductFinderFiltersAction = Action<LoadProductFinderProductsPayload>
export const loadProductFinderFilters = actionCreator.async<LoadProductFinderProductsPayload, SearchFilter[], Error>('LOAD_PRODUCT_FINDER_FILTERS')

export const setLoadingProductFinderFiltersState = actionCreator('SET_LOADING_PRODUCT_FINDER_FILTERS_STATE')

export interface ProductFilter {
	categoryId?: string
	filters?: SearchFilter[]
	page?: number
	pageSize?: number
}
export type SaveProductFinderFilterAction = Action<ProductFilter>
export const saveProductFinderFilter = actionCreator<ProductFilter>('SAVE_PRODUCT_FINDER_FILTER')

/**
 * load prices
 */

export interface LoadProductFinderPricesPayload {
	skus: ProductSku[]
	customerId?: number
	appendToList?: boolean
}
export type LoadProductFinderPricesAction = Action<LoadProductFinderPricesPayload>
export const loadProductFinderPrices = actionCreator.async<LoadProductFinderPricesPayload, ProductPricesResponse, Error>('LOAD_PRODUCT_FINDER_PRICES')

export const cancelLoadProductFinderPrices = actionCreator('CANCEL_LOAD_PRODUCT_FINDER_PRICES')

/**
 * load stock counts
 */

export interface LoadProductFinderAvailabilityPayload {
	skus: ProductSku[]
	branchId: number
	appendToList?: boolean
}
export type LoadProductFinderAvailabilityAction = Action<LoadProductFinderAvailabilityPayload>
export const loadProductFinderAvailability = actionCreator.async<LoadProductFinderAvailabilityPayload, BranchProductsResponse, Error>('LOAD_PRODUCT_FINDER_AVAILABILITY')

export const cancelLoadProductFinderAvailability = actionCreator('CANCEL_LOAD_PRODUCT_FINDER_AVAILABILITY')