import '../styles/UserProfile.scss'

import * as React from 'react'
import { FormControl, FormHelperText, CircularProgress, TextField, MenuItem, Select, InputAdornment, IconButton, Button as MuiButton, Snackbar, FormControlLabel, Switch, Divider, SelectChangeEvent } from '@mui/material'
import { AccountPermission } from 'typescript-fetch-api'

import * as Container from '../containers/UserProfile'
import { Role, AccountStatus, getRoleForRoleId, getAccountStatusForId, UserStatus } from '../../../common/accounts/types'
import { RoleSelectType, ROLE_SELECT, StatusSelectType, STATUS_SELECT } from '../types'
import { isValidEmail, isText, validatePassword } from '../../../common/auth/functions'
import { EditAccountRequestPayload, CreateAccountRequestPayload, EditLoginIdPayload } from '../../../common/accounts/actions'
import { isOwnAccount } from '../../../common/util/functions'
import { MobilePrefixOptions } from '../../register/types'
import RoleInfo from './RoleInfo'
import BranchSelect from '../../platform/components/BranchSelect'
import EditLoginIdDialog from './EditLoginIdDialog'
import DrawerAccordion from '../../platform/components/DrawerAccordion'
import AccountsTable from '../../accounts/components/AccountsTable'
import JoinAccount from '../../accounts/components/JoinAccount'
import ShareholdingAccessDrawerAccordion from './ShareholdingAccessDrawerAccordion'

interface State {
	// values
	firstName?: string
	lastName?: string
	role?: Role
	status?: AccountStatus
	email?: string
	mobileNumber?: string
	mobilePrefix?: string
	accountId?: string
	preferredBranch?: string
	defaultBranchId?: string
	businessName?: string
	password: string
	confirmPassword: string
	oldPassword: string
	passwordShown: boolean
	confirmPasswordShown: boolean
	oldPasswordShown: boolean
	shareholderNo: string

	// errors
	firstNameError?: string
	lastNameError?: string
	roleError?: string
	statusError?: string
	emailError?: string
	mobileNumberError?: string
	accountIdError?: string
	preferredBranchError?: string
	businessNameError?: string
	passwordError?: string
	confirmPasswordError?: string
	oldPasswordError?: string
	validationError?: string

	isRemoveUserDialogOpen: boolean
	showAddAccountDialog: boolean
	showSnackbar: boolean
	roleAnchorElement: HTMLElement | null

	accountWithUpdatedLoginId?: EditLoginIdPayload

	hasMadeChanges: boolean
}

const INITIAL_STATE: State = {
	firstName: '',
	lastName: '',
	role: Role.Trade,
	status: AccountStatus.Approved,
	email: '',
	mobileNumber: '',
	mobilePrefix: MobilePrefixOptions[0].value,
	accountId: '',
	preferredBranch: '',
	businessName: '',
	password: '',
	confirmPassword: '',
	oldPassword: '',
	passwordShown: false,
	confirmPasswordShown: false,
	oldPasswordShown: false,
	roleAnchorElement: null,
	hasMadeChanges: false,
	isRemoveUserDialogOpen: false,
	showAddAccountDialog: false,
	showSnackbar: false,
	shareholderNo: '',
}

export default class UserProfile extends React.PureComponent<Container.Props & Container.Actions & Container.OwnProps, State> {

	constructor(props: Container.Props & Container.Actions & Container.OwnProps) {
		super(props)

		// set initial state with info from selected account (if we have one)
		const { account, branches, customerId, defaultOrderAccountBranchId } = props

		if (account) {
			let mobileNumber
			let mobilePrefix
			if (account.mobileNumber) {
				// grab the first 3 chars from the mobile number
				const prefix = account.mobileNumber.substr(0, 3)
				// found a matching prefix
				if (MobilePrefixOptions.some(option => option.value === prefix)) {
					mobilePrefix = prefix
					// grab all the chars after the prefix
					mobileNumber = account.mobileNumber.substr(3)
				} else {
					// no valid prefix - add in all chars
					mobileNumber = account.mobileNumber
				}
			} else {
				mobileNumber = INITIAL_STATE.mobileNumber
				mobilePrefix = INITIAL_STATE.mobilePrefix
			}

			// convert types
			this.state = {
				...INITIAL_STATE,
				firstName: account.firstName || INITIAL_STATE.firstName,
				lastName: account.lastName || INITIAL_STATE.lastName,
				role: account.accountRoleName ? getRoleForRoleId(account.accountRoleName) : INITIAL_STATE.role,
				status: account.registrationState ? getAccountStatusForId(account.registrationState) : INITIAL_STATE.status,
				email: account.email || INITIAL_STATE.email,
				mobileNumber,
				mobilePrefix,
				accountId: account.customerId ? account.customerId.toString() : INITIAL_STATE.accountId,
				defaultBranchId: account.defaultBranchId ? account.defaultBranchId.toString() : INITIAL_STATE.defaultBranchId,
				businessName: account.name || INITIAL_STATE.businessName,
				password: INITIAL_STATE.password,
				confirmPassword: INITIAL_STATE.confirmPassword,
				oldPassword: INITIAL_STATE.oldPassword,
			}
		} else {
			let defaultBranchId
			if (defaultOrderAccountBranchId) {
				defaultBranchId = defaultOrderAccountBranchId
			} else {
				// default to first branch if there is no selected account for the order
				defaultBranchId = branches.length > 0 ? branches[0].id : INITIAL_STATE.preferredBranch
			}

			this.state = {
				...INITIAL_STATE,
				accountId: customerId.toString(),
				defaultBranchId,
			}
		}
	}

	componentDidMount() {
		// we want to clear any previous success/error message on redux regarding editing an account
		this.props.clearEditAccountFlags()
	}

	componentDidUpdate(prevProps: Container.Props, prevState: State) {
		if (prevProps.updatePasswordSuccess !== this.props.updatePasswordSuccess && this.props.updatePasswordSuccess) {
			// user has successfully updated their password, clear the fields
			this.setState({ password: '', confirmPassword: '', oldPassword: '' })
			return
		}

		const successUpdatingProfile = prevProps.updateProfileSuccess !== this.props.updateProfileSuccess && this.props.updateProfileSuccess
		const errorUpdatingProfile = prevProps.updateProfileError !== this.props.updateProfileError && this.props.updateProfileError
		if (this.state.accountWithUpdatedLoginId && (successUpdatingProfile || errorUpdatingProfile)) {
			// clear the saved account (closes the edit login ID dialog) on success/error 
			this.setState({ accountWithUpdatedLoginId: undefined })
			return
		}
		// if success removing a user, we should close the drawer as that user no longer exists
		if (successUpdatingProfile && this.state.isRemoveUserDialogOpen) {
			this.props.onCancel()
			return
		}

		if (successUpdatingProfile && this.state.hasMadeChanges) {
			// if success, hide the save changes button
			this.setState({ hasMadeChanges: false })
			return
		}

		// show snackbar success/error message
		if ((successUpdatingProfile || errorUpdatingProfile) && !this.state.showSnackbar) {
			this.setState({ showSnackbar: true })
			return
		}

		const successRequestingShareholdingAccess = prevProps.successRequestingShareholdingAccess !== this.props.successRequestingShareholdingAccess && this.props.successRequestingShareholdingAccess
		const errorRequestingShareholdingAccess = prevProps.errorRequestingShareholdingAccess !== this.props.errorRequestingShareholdingAccess && this.props.errorRequestingShareholdingAccess
		if ((successRequestingShareholdingAccess || errorRequestingShareholdingAccess) && !this.state.showSnackbar) {
			this.setState({ showSnackbar: true, shareholderNo: '' })
			return
		}
	}

	/**
	 * Can edit user details for the selected trading account if it matches the logged in user.
	 */
	_canEditUserDetails = (): boolean => {
		const { account, loggedInUserMobileNumber, loggedInUserEmail } = this.props
		// if no selected account we are creating a new user - so can set name/mobile/email
		if (!account) {
			return true
		}
		// if viewing own account - can edit your own name/mobile/email
		const isViewingOwnAccount: boolean = isOwnAccount(account, loggedInUserMobileNumber, loggedInUserEmail)
		if (isViewingOwnAccount) {
			return true
		}
		// if the selected user is not yet verified - we should be able to edit their details (as an Admin)
		if (account.userRegistrationState && account.userRegistrationState !== UserStatus.Verified) {
			return true
		}
		return false
	}

	_canEditRole = (): boolean => {
		const { account, permissions, role, loggedInUserMobileNumber, loggedInUserEmail } = this.props
		// if no selected account we are creating a new user - so can set role/status
		if (!account) {
			return true
		}

		// if viewing own account - cannot change role or status (prevents issues with demoting to TradeRoll which kicks you off Admin page, or disabling account which removes account from account selector dropdown)
		const isViewingOwnAccount: boolean = isOwnAccount(account, loggedInUserMobileNumber, loggedInUserEmail)

		// check if the user account has permissions to update roles
		const hasUpdateRolePermissions = !!permissions.some(item => (
			item === AccountPermission.UPDATE_TRADE_ACCOUNT_PRINCIPAL_ROLE || item === AccountPermission.UPDATE_TRADE_ACCOUNT_ADMIN_ROLE
		))

		// get the selected account's associated role
		const selectedAccountRole: Role | undefined = account && account.accountRoleName ? account.accountRoleName as Role : undefined
		// checks whether the user account role has more priority compared to the selected account role (e.g. admin role shouldn't be able to edit a principal role)
		const hasRolePriority: boolean = this._getRoleValue(role) >= this._getRoleValue(selectedAccountRole)

		if (isViewingOwnAccount || !hasUpdateRolePermissions || !hasRolePriority) {
			return false
		}
		return true
	}

	/**
	 * Assigns temporary values to corresponding roles to check for their priority
	 */
	_getRoleValue = (role?: Role): number => {
		switch (role) {
			case Role.Principal:
				return 3
			case Role.Admin:
				return 2
			case Role.TradePlus: // tradeplus should not be able to edit trade accounts (they have same priority)
			case Role.Trade:
			case Role.Procurement:
				return 1
			default:
				return 0
		}
	}

	_canEditStatus = (): boolean => {
		const { account, permissions, loggedInUserMobileNumber, loggedInUserEmail } = this.props
		// if no selected account we are creating a new user - so can set role/status
		if (!account) {
			return true
		}

		// if viewing own account - cannot change role or status (prevents issues with demoting to TradeRoll which kicks you off Admin page, or disabling account which removes account from account selector dropdown)
		const isViewingOwnAccount: boolean = isOwnAccount(account, loggedInUserMobileNumber, loggedInUserEmail)

		// Checks if the user account has permissions to edit the trade account status
		const hasUpdateStatusPermission = !!permissions.some(item => item === AccountPermission.UPDATE_TRADE_ACCOUNT_STATUS)

		if (isViewingOwnAccount || !hasUpdateStatusPermission) {
			return false
		}
		return true
	}

	_canDeleteAccount = (): boolean => {
		const { account, loggedInUserMobileNumber, loggedInUserEmail } = this.props
		// if no selected account we are creating a new user - cannot delete account
		if (!account) {
			return false
		}
		// if viewing own account - cannot delete own account
		const isViewingOwnAccount: boolean = isOwnAccount(account, loggedInUserMobileNumber, loggedInUserEmail)
		if (isViewingOwnAccount) {
			return false
		}
		return true
	}

	_isEditingExistingAccount = (): boolean => {
		const { account } = this.props
		// is we have an existing account we are editing one. (could change to flag in container instead incase selectedAccount not found for given id)
		return !!account
	}

	/**
	 * SELECT RENDERS
	 */

	_renderRoles = (): RoleSelectType[] => {
		const { role } = this.props
		// include default roles
		const roles: RoleSelectType[] = [ROLE_SELECT.Trade, ROLE_SELECT['Trade+'], ROLE_SELECT.Admin]
		// if Principal role, allow them to assign Principal
		if (role && role === Role.Principal) {
			roles.push(ROLE_SELECT.Principal)
		}
		return roles
	}

	_renderStatuses = (): StatusSelectType[] => {
		const statuses: StatusSelectType[] = [STATUS_SELECT.approved]
		// if editing existing account can set to Disabled state
		if (this._isEditingExistingAccount()) {
			statuses.push(STATUS_SELECT.disabled)
		}
		const { account } = this.props
		// Note: only add PENDING as an option if selected account is currently in that state (should not be able to go from any other state to Pending)
		if (account && account.registrationState === AccountStatus.Pending) {
			statuses.push(STATUS_SELECT.pending)
		}
		return statuses
	}

	_getLoader = (): React.ReactNode | null => {
		const { validationError } = this.state
		const { account, updateProfileSuccess, updateProfileError, updatingProfile, updatePasswordSuccess, updatePasswordError, updatingPassword } = this.props

		if (validationError) {
			return <p className="message error">{validationError}</p>
		} else if (updatingProfile || updatingPassword) {
			// this prevents showing 2 loaders on both the user profile and edit login ID dialog
			if (!this.state.accountWithUpdatedLoginId) return <CircularProgress />
			else return null
		} else if (updateProfileError || updatePasswordError) {
			const action: string = account ? 'updating' : 'adding'

			if (updateProfileError) {
				return <p className="message error">{updateProfileError.message || `Error ${action} profile`}</p>
			} else {
				return <p className="message error">{updatePasswordError!.message || `Error ${action} password`}</p>
			}
		} else if (updateProfileSuccess || updatePasswordSuccess) {
			return <p className="message success">{`Successfully ${account ? 'updated' : 'added'} user`}</p>
		}
		return null
	}

	_getSnackbarMessage = (): string | undefined => {
		const { validationError } = this.state
		const { account, updateProfileSuccess, updateProfileError, updatePasswordSuccess, updatePasswordError, successRequestingShareholdingAccess, errorRequestingShareholdingAccess } = this.props

		if (validationError) {
			return validationError
		} else if (updateProfileError || updatePasswordError) {
			const action: string = account ? 'updating' : 'adding'

			if (updateProfileError) {
				return updateProfileError.message || `Error ${action} profile`
			} else {
				return updatePasswordError!.message || `Error ${action} password`
			}
		} else if (updateProfileSuccess || updatePasswordSuccess) {
			return `Successfully ${account ? 'updated' : 'added'} user`
		} else if (successRequestingShareholdingAccess) {
			return 'Successfully requested shareholder access'
		} else if (errorRequestingShareholdingAccess) {
			return errorRequestingShareholdingAccess.message || 'Error requesting shareholder access'
		}
		return undefined
	}

	/**
	 * REMOVE USER DIALOG ACTIONS
	 */

	_onShowRemoveUserDialog = () => {
		this.setState({ isRemoveUserDialogOpen: true })
		this.props.onShowConfirmRemoval(true)
	}

	_onHideRemoveUserDialog = () => {
		this.setState({ isRemoveUserDialogOpen: false })
		this.props.onShowConfirmRemoval(false)
	}

	_onShowAddAccountDialog = () => {
		this.setState({ showAddAccountDialog: true })
	}

	_onHideAddAccountDialog = () => {
		this.setState({ showAddAccountDialog: false })
	}

	_handleSnackbarClose = () => {
		this.setState({ showSnackbar: false })
	}

	/**
	 * STATE CHANGE HANDLERS
	 */

	_onSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const { name, value } = event.target
		this.setState({ [name!]: value, [`${name}Error`]: undefined, validationError: undefined, hasMadeChanges: true } as Pick<State, 'role' | 'status'>)
	}

	_onBranchSelectChange = (branchId: string | undefined) => {
		// initial render sets default preferredBranch, do not count this as the user making changes
		const hasMadeChanges: boolean = this.state.preferredBranch !== '' && this.state.preferredBranch !== branchId
		this.setState({ preferredBranch: branchId, preferredBranchError: undefined, hasMadeChanges })
	}

	_onMobilePrefixChange = (event: SelectChangeEvent<string>) => {
		this.setState({ mobilePrefix: event.target.value, hasMadeChanges: true })
	}

	_handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, id } = e.target
		this.setState({ [id]: value, [`${id}Error`]: undefined, validationError: undefined, hasMadeChanges: true } as Pick<State, 'firstName' | 'lastName' | 'email' | 'mobileNumber' | 'businessName'>)
	}

	_handleProductRangePreferenceToggle = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		this.props.setBranchFilterPreference(checked)
	}

	_handleToggleOldPasswordVisible = () => {
		this.setState({ oldPasswordShown: !this.state.oldPasswordShown })
	}

	_handleTogglePasswordVisible = () => {
		this.setState({ passwordShown: !this.state.passwordShown })
	}

	_handleToggleConfirmPasswordVisible = () => {
		this.setState({ confirmPasswordShown: !this.state.confirmPasswordShown })
	}

	/**
	 * API-CONNECTED ACTIONS
	 */

	_onUpdateUserClicked = () => {
		const { account, loggedInUserEmail, loggedInUserMobileNumber } = this.props
		// validate
		const { businessName, status, role, preferredBranch, firstName, lastName, email, mobileNumber, mobilePrefix, validationError, password, confirmPassword, oldPassword } = this.state
		// type error
		if (status === AccountStatus.Unassigned) {
			throw Error('Not possible to set to unassigned status')
		}
		if (role === undefined) {
			throw Error('Not possible to have role undefined')
		}
		// first and last name is required
		if (!firstName) {
			this.setState({ firstNameError: 'A first name is required' })
			return
		}
		if (!lastName) {
			this.setState({ lastNameError: 'A last name is required' })
			return
		}
		// if email provided check it is valid format
		if (email && !isValidEmail(email)) {
			this.setState({ emailError: 'Please enter a valid email' })
			return
		}
		// if mobile provided check it is valid format
		// NOTE - the prefix is required, so we're setting the complete mobile to undefined if any of the values are missing
		const completeMobile = mobilePrefix && mobileNumber ? mobilePrefix + mobileNumber : undefined
		if (completeMobile && isText(completeMobile)) {
			this.setState({ mobileNumberError: 'Please enter a valid mobile number' })
			return
		}
		// must provide either mobile or email
		if (!completeMobile && !email) {
			this.setState({ validationError: 'Please enter mobile number or email' })
			return
		}
		if (!businessName) {
			this.setState({ businessNameError: 'A business name is required' })
			return
		}
		// NOTE: we only validate password format if one is provided, it is optional as user may be creating NEW trading account for an EXISTING user
		if (password) {
			const isViewingOwnAccount: boolean = account ? isOwnAccount(account, this.props.loggedInUserMobileNumber, this.props.loggedInUserEmail) : false
			if (isViewingOwnAccount && !oldPassword) {
				this.setState({ oldPasswordError: 'Please enter your old password' })
				return
			}

			const passwordError = validatePassword(password)
			if (passwordError) {
				this.setState({ passwordError: 'Password must be at least 8 characters long and contain a capital and numeric value' })
				return
			}
			if (!confirmPassword) {
				this.setState({ confirmPasswordError: 'Confirm password is required' })
				return
			} else if (password !== confirmPassword) {
				this.setState({ confirmPasswordError: 'Passwords do not match' })
				return
			}
		}
		// clear any existing error state
		if (validationError) {
			this.setState({ validationError: undefined })
		}
		// don't send empty string for mobile or email to server (if its blank then just don't send any value for that property)
		const emailPayload = email === '' ? undefined : email
		const mobileNumberPayload = completeMobile === '' ? undefined : completeMobile

		// perform action
		if (account) {
			const request: EditAccountRequestPayload = {
				id: account.id,
				customerId: account.customerId,
				userId: account.userId,
				name: businessName,
				registrationState: status,
				accountRoleName: role,
				defaultBranchId: Number(preferredBranch),
				firstName,
				lastName,
				mobileNumber: mobileNumberPayload,
				email: emailPayload,
			}

			// check if the user is attempting to change their login ID
			const isViewingOwnAccount: boolean = isOwnAccount(account, loggedInUserMobileNumber, loggedInUserEmail)
			const hasUpdatedEmail = account.email !== emailPayload
			const hasUpdatedMobile = account.mobileNumber !== mobileNumberPayload

			if (isViewingOwnAccount && (hasUpdatedEmail || hasUpdatedMobile)) {
				let accountWithUpdatedLoginId: EditLoginIdPayload = request
				// the user is attempting to update their password since they have the password fields filled
				if (oldPassword && password) {
					accountWithUpdatedLoginId = {
						...accountWithUpdatedLoginId,
						password: {
							oldPassword,
							newPassword: password,
						},
					}
				}
				this.setState({ accountWithUpdatedLoginId })
			} else {
				this.props.editAccount(request)
				// the user is attempting to update their password since they have the password fields filled
				if (oldPassword && password) {
					this.props.updateUserPassword(oldPassword, password)
				}
			}
		} else {
			const request: CreateAccountRequestPayload = {
				customerId: this.props.customerId,
				name: businessName,
				accountRoleName: role,
				defaultBranchId: Number(preferredBranch),
				firstName,
				lastName,
				mobileNumber: mobileNumberPayload,
				email: emailPayload,
				password,
			}
			this.props.createAccount(request)
		}
	}

	_onRemoveUserClicked = () => {
		this.props.deleteAccount(this.props.account!)
	}

	_onSetRoleAnchorElement = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation()
		if (!this.state.roleAnchorElement) {
			this.setState({ roleAnchorElement: e.currentTarget })
		}
	}

	_onRemoveRoleAnchorElement = () => {
		if (this.state.roleAnchorElement) {
			this.setState({ roleAnchorElement: null })
		}
	}

	_onCancelEditLoginId = () => {
		this.setState({ accountWithUpdatedLoginId: undefined })
	}

	_onEditLoginId = () => {
		this.props.editLoginId(this.state.accountWithUpdatedLoginId!)
	}

	_onUpdateShareholderNo = (shareholderNo: string) => {
		this.setState({ shareholderNo })
	}

	_onClearShareholderNo = () => {
		this.setState({ shareholderNo: '' })
	}

	_onRequestShareholdingAccess = () => {
		if (!this.state.shareholderNo) return

		// highly unlikely scenario since the user would've logged in either with their mobile/email
		const loginId = this.props.loggedInUserMobileNumber || this.props.loggedInUserEmail
		if (!loginId) return

		const shareholderNumber = parseInt(this.state.shareholderNo, 10)
		this.props.requestShareholdingAccess({ shareholderNumber, loginId })
	}

	_renderFooter = () => {
		if (!this.props.account) {
			return (
				<div className="user-profile__update-footer">
					<MuiButton variant="contained" color="primary" className="user-profile__update-footer--button" onClick={this._onUpdateUserClicked} disabled={this.props.updatingProfile}>
						{(this.props.updatingProfile) ? <CircularProgress color="secondary" size="small" /> : 'ADD USER'}
					</MuiButton>
				</div>
			)
		}

		if (this.state.isRemoveUserDialogOpen) {
			return (
				<div className="user-profile__update-footer">
					<MuiButton variant="contained" color="primary" className="user-profile__update-footer--button" onClick={this._onRemoveUserClicked} disabled={this.props.updatingProfile}>
						{(this.props.updatingProfile) ? <CircularProgress color="secondary" size="small" /> : 'CONFIRM REMOVAL'}
					</MuiButton>
					<MuiButton variant="outlined" className="user-profile__update-footer--button" onClick={this._onHideRemoveUserDialog}>
						CANCEL
					</MuiButton>
				</div>
			)
		}

		if (this.state.shareholderNo) {
			return (
				<div className="user-profile__update-footer">
					<MuiButton variant="contained" color="primary" className="user-profile__update-footer--button" onClick={this._onRequestShareholdingAccess} disabled={this.props.requestingShareholdingAccess}>
						{(this.props.requestingShareholdingAccess) ? <CircularProgress color="secondary" size="small" /> : 'SEND REQUEST'}
					</MuiButton>
					<MuiButton variant="outlined" className="user-profile__update-footer--button" onClick={this._onClearShareholderNo}>
						CANCEL
					</MuiButton>
				</div>
			)
		}

		if (this.state.hasMadeChanges) {
			return (
				<div className="user-profile__update-footer">
					<MuiButton variant="contained" color="primary" className="user-profile__update-footer--button" onClick={this._onUpdateUserClicked} disabled={this.props.updatingProfile || this.props.updatingPassword}>
						{(this.props.updatingProfile || this.props.updatingPassword) ? <CircularProgress color="secondary" size="small" /> : 'UPDATE'}
					</MuiButton>
					<MuiButton variant="outlined" className="user-profile__update-footer--button" onClick={this.props.onCancel}>
						CANCEL
					</MuiButton>
				</div>
			)
		}

		if (this.props.showAccountsSection) {
			return (
				<div className="user-profile__update-footer">
					<MuiButton variant="contained" color="primary" className="user-profile__update-footer--button" onClick={this._onShowAddAccountDialog}>
						ADD ACCOUNT
					</MuiButton>
				</div>
			)
		}

		if (this._canDeleteAccount()) {
			return (
				<div className="user-profile__update-footer">
					<MuiButton variant="outlined" className="user-profile__update-footer--button" onClick={this._onShowRemoveUserDialog}>
						REMOVE USER
					</MuiButton>
				</div>
			)
		}

		return undefined
	}

	render() {
		const {
			firstName, lastName, status, role, email, mobileNumber, accountId, businessName, isRemoveUserDialogOpen, password, confirmPassword, oldPassword,
			firstNameError, lastNameError, statusError, roleError, emailError, mobileNumberError, accountIdError, preferredBranchError, businessNameError, showSnackbar,
			passwordError, confirmPasswordError, oldPasswordError, defaultBranchId, mobilePrefix, roleAnchorElement, accountWithUpdatedLoginId, showAddAccountDialog,
			oldPasswordShown, passwordShown, confirmPasswordShown, shareholderNo,
		} = this.state
		const { account, loggedInUserMobileNumber, loggedInUserEmail, branches, accounts, showAccountsSection, branchFilterPreferenceEnabled } = this.props

		// identifiers
		const isViewingOwnAccount: boolean = account ? isOwnAccount(account, loggedInUserMobileNumber, loggedInUserEmail) : false
		const canEditUserDetails: boolean = this._canEditUserDetails()
		const canEditRole: boolean = this._canEditRole()
		const canEditStatus: boolean = this._canEditStatus()
		// user can set password if creating a new account or if viewing own account
		const canSetPassword: boolean = !account || isViewingOwnAccount
		const userUnVerified: boolean = account && account.userRegistrationState ? account.userRegistrationState !== UserStatus.Verified : false

		return (
			<React.Fragment>
				<div className="profile-drawer__container">
					<div className="profile-drawer__accounts">
						<DrawerAccordion title="Profile" iconName="icon-user" divider={true}>

							{userUnVerified && <span className="user-profile__text--subheading">Editing account for unverified user</span>}

							<div className="user-profile__row">
								<FormControl className="user-profile__form">
									<TextField
										type="text"
										id="firstName"
										label="First Name *"
										value={firstName}
										disabled={!canEditUserDetails}
										onChange={this._handleInputChange}
										variant="outlined"
										autoComplete="off"
									/>
									<FormHelperText error={!!firstNameError}>{firstNameError}</FormHelperText>
								</FormControl>

								<FormControl className="user-profile__form">
									<TextField
										type="text"
										id="lastName"
										label="Last Name *"
										value={lastName}
										disabled={!canEditUserDetails}
										onChange={this._handleInputChange}
										variant="outlined"
										autoComplete="off"
									/>
									<FormHelperText error={!!lastNameError}>{lastNameError}</FormHelperText>
								</FormControl>
							</div>

							<p className="user-profile__text user-profile__text--section">Login ID - Email and/or Mobile</p>

							<div className="user-profile__row">
								<FormControl className="user-profile__form">
									<TextField
										type="email"
										id="email"
										label="Email"
										value={email}
										disabled={!canEditUserDetails}
										onChange={this._handleInputChange}
										variant="outlined"
										autoComplete="off"
										autoCapitalize="none"
									/>
									<FormHelperText error={!!emailError}>{emailError}</FormHelperText>
								</FormControl>

								<FormControl className="user-profile__form">
									{/* Console warns of multiple inputs being used inside a form control (https://github.com/mui-org/material-ui/issues/16907) */}
									{/* We need a select inside the textfield to preserve the label on the outlined input, so we're nesting 2 inputs inside the form control */}
									<TextField
										type="number"
										id="mobileNumber"
										label="Mobile"
										value={mobileNumber}
										disabled={!canEditUserDetails}
										onChange={this._handleInputChange}
										variant="outlined"
										autoComplete="off"
										autoCapitalize="none"
										className="user-profile__form--mobile"
										InputProps={{
											startAdornment: (
												<Select
													onChange={this._onMobilePrefixChange}
													value={mobilePrefix}
													variant="standard"
													disableUnderline={true}
													className="user-profile__form--mobile-select"
												>
													{MobilePrefixOptions.map(option => (
														<MenuItem
															key={option.value}
															value={option.value}
														>
															{option.label}
														</MenuItem>
													))}
												</Select>
											),
										}}
									/>
									<FormHelperText error={!!mobileNumberError}>{mobileNumberError}</FormHelperText>
								</FormControl>
							</div>

							{canSetPassword && (
								<React.Fragment>
									<p className="user-profile__text user-profile__text--section">Change Password</p>

									{isViewingOwnAccount && (
										<div className="user-profile__row">
											<FormControl className="user-profile__form">
												<TextField
													type={oldPasswordShown ? 'text' : 'password'}
													id="oldPassword"
													label="Old Password"
													onChange={this._handleInputChange}
													variant="outlined"
													value={oldPassword}
													autoComplete="new-password"
													autoCapitalize="none"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	onClick={this._handleToggleOldPasswordVisible}
																	disableRipple={true}
																	edge="end"
																>
																	<span className={`icon-${oldPasswordShown ? 'visibility_off' : 'visibility'} user-profile__icon-visibility`} />
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
												<FormHelperText error={!!oldPasswordError}>{oldPasswordError}</FormHelperText>
											</FormControl>
										</div>
									)}

									<div className="user-profile__row">
										<FormControl className="user-profile__form">
											<TextField
												type={passwordShown ? 'text' : 'password'}
												id="password"
												label={isViewingOwnAccount ? 'New Password' : 'Password'}
												onChange={this._handleInputChange}
												variant="outlined"
												value={password}
												autoComplete="new-password"
												autoCapitalize="none"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																onClick={this._handleTogglePasswordVisible}
																disableRipple={true}
																edge="end"
															>
																<span className={`icon-${passwordShown ? 'visibility_off' : 'visibility'} user-profile__icon-visibility`} />
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
											<FormHelperText error={!!passwordError}>{passwordError}</FormHelperText>
										</FormControl>

										<FormControl className="user-profile__form">
											<TextField
												type={confirmPasswordShown ? 'text' : 'password'}
												id="confirmPassword"
												label={isViewingOwnAccount ? 'Confirm New Password' : 'Confirm Password'}
												onChange={this._handleInputChange}
												variant="outlined"
												value={confirmPassword}
												autoComplete="new-password"
												autoCapitalize="none"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																onClick={this._handleToggleConfirmPasswordVisible}
																disableRipple={true}
																edge="end"
															>
																<span className={`icon-${confirmPasswordShown ? 'visibility_off' : 'visibility'} user-profile__icon-visibility`} />
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
											<FormHelperText error={!!confirmPasswordError}>{confirmPasswordError}</FormHelperText>
										</FormControl>
									</div>
								</React.Fragment>
							)}
						</DrawerAccordion>

						{isViewingOwnAccount &&
							<DrawerAccordion title="Profile Preferences" iconName="icon-star" divider={true}>
								<div className="user-profile__preferences">

									<p className="user-profile__text user-profile__text--section">Filter By</p>

									<FormControlLabel
										control={
											<Switch
												color="primary"
												checked={branchFilterPreferenceEnabled}
												onChange={this._handleProductRangePreferenceToggle}
											/>
										}
										label={<span className="user-profile__switch--label">Product Range by Branch</span>}
										labelPlacement="start"
										className="user-profile__switch"
									/>

									<Divider />

									<p className="user-profile__text user-profile__text--section user-profile__preferences">Apply filter when viewing a new product category or using product search rather than defaulting to &apos;View All&apos;.</p>

								</div>
							</DrawerAccordion>
						}

						<DrawerAccordion title="Account" iconName="icon-user-circle-outline" divider={true}>

							<p className="user-profile__text user-profile__text--section user-profile__text--padding">Edit Selected Account</p>

							<div className="user-profile__row">
								<FormControl className="user-profile__form">
									<TextField
										label="Account ID *"
										value={accountId}
										disabled={true}
										variant="outlined"
										autoComplete="off"
										autoCapitalize="none"
									/>
									<FormHelperText error={!!accountIdError}>{accountIdError}</FormHelperText>
								</FormControl>

								<FormControl className="user-profile__form">
									<TextField
										type="text"
										id="businessName"
										label="Business Name"
										value={businessName}
										onChange={this._handleInputChange}
										variant="outlined"
										autoComplete="off"
									/>
									<FormHelperText error={!!businessNameError}>{businessNameError}</FormHelperText>
								</FormControl>
							</div>

							<div className="user-profile__row">
								<FormControl className="user-profile__form">
									<TextField
										name="role"
										value={role}
										onChange={this._onSelectChange}
										label="Role*"
										SelectProps={{
											MenuProps: {
												anchorOrigin: {
													vertical: 'bottom',
													horizontal: 'left'
												},
												transformOrigin: {
													vertical: 'top',
													horizontal: 'left'
												},
											},
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment
													position="end"
													className={`user-profile__role-info ${canEditRole ? 'user-profile__role-info--editable' : ''}`}
												>
													<IconButton
														onClick={this._onSetRoleAnchorElement}
														disableRipple={true}
														edge="end"
													>
														<span className="icon-info" />
													</IconButton>
												</InputAdornment>
											),
										}}
										variant="outlined"
										select={canEditRole}
										disabled={!canEditRole}
										className={`user-profile__role ${canEditRole ? 'user-profile__role--editable' : ''}`}
									>
										{canEditRole && this._renderRoles().map(option => (
											<MenuItem
												key={option.label}
												value={option.value}
											>
												{option.label}
											</MenuItem>
										))}
									</TextField>
									<FormHelperText error={!!roleError}>{roleError}</FormHelperText>
								</FormControl>

								<FormControl className="user-profile__form">
									<BranchSelect
										options={branches}
										label="Preferred Branch"
										defaultBranchId={defaultBranchId}
										inputParams={{
											inputProps: { autoComplete: 'off' },
										}}
										onBranchSelected={this._onBranchSelectChange}
									/>
									<FormHelperText error={!!preferredBranchError}>{preferredBranchError}</FormHelperText>
								</FormControl>
							</div>

							<div className="user-profile__row">
								<FormControl className="user-profile__form">
									<TextField
										name="status"
										value={status}
										onChange={this._onSelectChange}
										label="Status*"
										SelectProps={{
											MenuProps: {
												anchorOrigin: {
													vertical: 'bottom',
													horizontal: 'left'
												},
												transformOrigin: {
													vertical: 'top',
													horizontal: 'left'
												},
											}
										}}
										variant="outlined"
										select={canEditStatus}
										disabled={!canEditStatus}
										className="user-profile__status"
									>
										{canEditStatus && this._renderStatuses().map(option => (
											<MenuItem
												key={option.label}
												value={option.value}
											>
												{option.label}
											</MenuItem>
										))}
									</TextField>
									<FormHelperText error={!!statusError}>{statusError}</FormHelperText>
								</FormControl>
							</div>

							{showAccountsSection &&
								<React.Fragment>
									<p className="user-profile__text user-profile__text--section">Accounts</p>

									{accounts ?
										<AccountsTable accounts={accounts} onAddAccount={this._onShowAddAccountDialog} />
										:
										<p className="message info">You have no accounts</p>
									}
								</React.Fragment>
							}
						</DrawerAccordion>

						{isViewingOwnAccount && (
							<ShareholdingAccessDrawerAccordion
								shareholderNo={shareholderNo}
								onUpdateShareholderNo={this._onUpdateShareholderNo}
							/>
						)}

						{/* LOADING */}

						{/* shown in snackbar instead now */}
						{/* <div className="user-profile__footer">
							{this._getLoader()}
						</div> */}

						{/* DIALOGS */}

						{role && (
							<RoleInfo
								role={role}
								anchorElement={roleAnchorElement}
								onClickAway={this._onRemoveRoleAnchorElement}
							/>
						)}

						{accountWithUpdatedLoginId && (
							<EditLoginIdDialog
								isLoading={this.props.updatingProfile}
								onClose={this._onCancelEditLoginId}
								onEdit={this._onEditLoginId}
							/>
						)}

						<Snackbar
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							open={showSnackbar}
							autoHideDuration={2000}
							onClose={this._handleSnackbarClose}
							message={this._getSnackbarMessage()}
						/>
					</div>

					{(isRemoveUserDialogOpen || showAddAccountDialog) && <div className="user-profile__overlay" />}

					<JoinAccount visible={showAddAccountDialog} onCancelJoinAccount={this._onHideAddAccountDialog} />
				</div>

				{this._renderFooter()}
			</React.Fragment>
		)
	}
}