import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ChangeBranch, DrawerType, WebStoreState } from '../types'
import ChangeAccountDrawer from './ChangeAccountDrawer'
import ChangeBranchDrawer from './ChangeBranchDrawer'
import ProfileDrawer from './ProfileDrawer'
import { UserAccount } from '../../../common/accounts/types'
import { toggleDrawer } from '../actions'
import { accountSelected } from '../../../common/cart/actions'
import HnzCartDialog from '../containers/HnzCartDialog'
import { OrderItem } from '../../../common/order/types'
import CartDrawer from '../../cart/components/CartDrawer'
import MenuDrawer from './MenuDrawer'

/**
 * Displays the different drawers used across the app.
 * This also handles showing the hnz cart dialog when changing accounts.
 * @returns the drawers
 */
const Drawers = (): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */

	const toggledDrawer = useSelector<WebStoreState, DrawerType | undefined>(state => state.platform.toggledDrawer)
	const selectedAccount = useSelector<WebStoreState, UserAccount | undefined>(state => state.order.selectedAccount)
	const hnzOnlyOrderItems = useSelector<WebStoreState, OrderItem[]>(state => state.order.orderItems.filter(item => !!item.product.hnzOnly))

	/**
	 * Local State
	 */

	const [temporarySelectedAccount, setTemporarySelectedAccount] = useState<UserAccount | undefined>(undefined)

	/**
	 * Local Functions
	 */

	const _hideDrawer = () => {
		dispatch(toggleDrawer(undefined))
	}

	const _showAccountDrawer = () => {
		dispatch(toggleDrawer(DrawerType.ACCOUNT))
	}

	const _showBranchDrawer = () => {
		dispatch(toggleDrawer(DrawerType.BRANCH))
	}

	const _showProfileDrawer = () => {
		dispatch(toggleDrawer(DrawerType.PROFILE))
	}

	const _showCartDrawer = () => {
		dispatch(toggleDrawer(DrawerType.CART))
	}

	return (
		<React.Fragment>
			<ChangeBranchDrawer
				isDrawerVisible={toggledDrawer === DrawerType.BRANCH}
				type={ChangeBranch.SELECT_BRANCH}
				onHideDrawer={_hideDrawer}
			/>

			<ChangeAccountDrawer
				isDrawerVisible={toggledDrawer === DrawerType.ACCOUNT}
				onHideDrawer={_hideDrawer}
				onChangeAccount={(newAccount: UserAccount) => {
					// the user selected a different account, save it to the store
					if (selectedAccount && selectedAccount.id !== newAccount.id) {
						// the user is changing their account to a non-hnz one
						if (selectedAccount.hnz && !newAccount.hnz) {
							// we check if the user has hnz only items in their order
							if (hnzOnlyOrderItems.length > 0) {
								// displays the dialog
								setTemporarySelectedAccount(newAccount)
							} else {
								dispatch(accountSelected(newAccount))
							}
						} else {
							dispatch(accountSelected(newAccount))
						}
					}
				}}
			/>

			<ProfileDrawer
				isDrawerVisible={toggledDrawer === DrawerType.PROFILE}
				onHideDrawer={_hideDrawer}
			/>

			<CartDrawer
				isDrawerVisible={toggledDrawer === DrawerType.CART}
				onHideDrawer={_hideDrawer}
			/>

			<MenuDrawer
				isDrawerVisible={toggledDrawer === DrawerType.MENU}
				onHideDrawer={_hideDrawer}
				onShowAccountsDrawer={_showAccountDrawer}
				onShowBranchDrawer={_showBranchDrawer}
				onShowProfileDrawer={_showProfileDrawer}
				onShowCartDrawer={_showCartDrawer}
			/>

			{temporarySelectedAccount && (
				<HnzCartDialog
					hnzOnlyOrderItems={hnzOnlyOrderItems}
					onConfirm={() => {
						dispatch(accountSelected(temporarySelectedAccount))
					}}
					onCancel={() => {
						setTemporarySelectedAccount(undefined)
					}}
				/>
			)}
		</React.Fragment>
	)
}

export default Drawers