import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from '../products/actions'
import { setUpdatingProductsState } from '../products/actions'
import { ProductsViewState, UpdatingProductsState } from './types'

export interface StoreState {
	readonly productsViewState: ProductsViewState,
	readonly downloadProductsState?: UpdatingProductsState,
}

const INITIAL_STATE: StoreState = {
	productsViewState: ProductsViewState.LIST,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	.case(actions.toggleProductsViewState, (state, payload): StoreState => ({
		...state, productsViewState: payload
	}))

	.case(setUpdatingProductsState, (state, payload): StoreState => ({
		...state, downloadProductsState: payload
	}))

	.case(actions.clearUpdatingProductsState, (state): StoreState => ({
		...state, downloadProductsState: undefined
	}))

	// if the network call to download products failed, we set product state this way
	.case(actions.fetchProducts.failed, (state): StoreState => ({
		...state, downloadProductsState: UpdatingProductsState.ERROR
	}))