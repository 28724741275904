import React from 'react'
import { useDispatch } from 'react-redux'

import MenuPopper from '../../platform/components/MenuPopper'
import { SubheaderMenuProps } from '../../platform/types'
import { navigateToBranchQuotes } from '../../platform/actions'

const QuotesMenuPopper = ({ anchorElement, isHnzAccount, onClickAway, onCloseSubmenu }: SubheaderMenuProps): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Local Functions
	 */

	/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
	const _navigateToCustomerQuotes = () => {
		dispatch(navigateToBranchQuotes())
		onClickAway()
	}

	const _navigateToBranchQuotes = () => {
		dispatch(navigateToBranchQuotes())
		onClickAway()
	}

	return (
		<MenuPopper anchorElement={anchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount} titleText="Quotes" onCloseSubmenu={onCloseSubmenu}>
			{/* Hidden until feature added */}
			{/* <div className="menu-drawer__item" onClick={_navigateToCustomerQuotes}>
				<span />
				<span className="menu-drawer__item--title">Customer Quotes</span>
				<span />
			</div>
			<Divider className="menu-drawer__footer--divider" /> */}
			<div className="menu-drawer__item" onClick={_navigateToBranchQuotes}>
				<span />
				<span className="menu-drawer__item--title">Branch Quotes</span>
				<span />
			</div>
			{/* <Divider className="menu-drawer__footer--divider" /> */}
		</MenuPopper>
	)
}

export default QuotesMenuPopper
