import actionCreatorFactory, { Action } from 'typescript-fsa'
import { SearchFilter } from 'typescript-fetch-api'

import { Category } from '../../common/categories/types'
import { ProductFilter, LoadProductsResult, LoadFiltersForProductsPayload } from '../../common/products/actions'

const actionCreator = actionCreatorFactory('Hnz')

// generic hnz action for empty payload
export type HnzAction = Action<undefined>

// loads the top-level categories containing HNZ/Kainga Ora products
export const loadHnzCategories = actionCreator.async<void, ReadonlyArray<Category>, Error>('LOAD_HNZ_CATEGORIES')

export interface LoadHnzProductsPayload {
	categoryId?: string
}
export type LoadHnzProductsAction = Action<LoadHnzProductsPayload>
export const loadHnzProducts = actionCreator.async<LoadHnzProductsPayload, LoadProductsResult, Error>('LOAD_HNZ_PRODUCTS')
export const setLoadingHnzProductsState = actionCreator('SET_LOADING_HNZ_PRODUCTS_STATE')

export interface SelectHnzCategoryPayload {
	categoryId: string
	categoryTitle: string
}
export type SelectHnzCategoryAction = Action<SelectHnzCategoryPayload | undefined>
export const selectHnzCategory = actionCreator<SelectHnzCategoryPayload | undefined>('SELECT_HNZ_CATEGORY')

export const loadFiltersForHnzProducts = actionCreator.async<LoadFiltersForProductsPayload, SearchFilter[], Error>('LOAD_FILTERS_FOR_HNZ_PRODUCTS')

export const saveHnzProductsFilter = actionCreator<ProductFilter>('SAVE_HNZ_PRODUCTS_FILTER')

export const showHnzNewFeatureDialog = actionCreator<boolean>('SHOW_HNZ_NEW_FEATURE_DIALOG')

export type ToggleHnzModeAction = Action<boolean>
export const toggleHnzMode = actionCreator<boolean>('TOGGLE_HNZ_MODE')