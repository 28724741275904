import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'

import * as actions from './actions'
import * as contactUsActions from '../contactus/actions'

export interface StoreState {
	readonly editingProfile: boolean
	readonly editingProfileError?: Error
	readonly sendingContactUs: boolean
	readonly sendingContactUsError?: Error
	readonly sentContactUs: boolean
	readonly updatingPassword: boolean
	readonly updatePasswordSuccess: boolean
	readonly updatePasswordError?: Error
}

const INITIAL_STATE: StoreState = {
	editingProfile: false,
	sendingContactUs: false,
	sendingContactUsError: undefined,
	sentContactUs: false,
	updatingPassword: false,
	updatePasswordSuccess: false,
	updatePasswordError: undefined
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actions.editProfile.started, (state, payload): StoreState => ({
	...state, editingProfile: true, editingProfileError: undefined,
}))

reducer.case(actions.editProfile.done, (state, payload): StoreState => ({
	...state, editingProfile: false,
}))

reducer.case(actions.editProfile.failed, (state, { error }): StoreState => ({
	...state, editingProfile: false, editingProfileError: error,
}))

reducer.case(contactUsActions.sendContactUs.started, (state): StoreState => ({
	...state, sendingContactUs: true, sendingContactUsError: undefined, sentContactUs: false,
}))

reducer.case(contactUsActions.sendContactUs.done, (state): StoreState => ({
	...state, sendingContactUs: false, sendingContactUsError: undefined, sentContactUs: true,
}))

reducer.case(contactUsActions.sendContactUs.failed, (state, { error }): StoreState => ({
	...state, sendingContactUs: false, sendingContactUsError: error, sentContactUs: false,
}))

reducer.case(contactUsActions.clearForm, (state): StoreState => ({
	...state, sendingContactUs: false, sendingContactUsError: undefined, sentContactUs: false,
}))

reducer.case(actions.updateUserPassword.started, (state): StoreState => ({
	...state, updatingPassword: true, updatePasswordSuccess: false, updatePasswordError: undefined,
}))

reducer.case(actions.updateUserPassword.done, (state): StoreState => ({
	...state, updatingPassword: false, updatePasswordSuccess: true
}))

reducer.case(actions.updateUserPassword.failed, (state, { error }): StoreState => ({
	...state, updatingPassword: false, updatePasswordError: error,
}))

reducer.case(actions.clearUpdateUserPasswordFlags, (state): StoreState => ({
	...state, updatingPassword: false, updatePasswordSuccess: false, updatePasswordError: undefined,
}))