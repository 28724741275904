import actionCreatorFactory, { Action } from 'typescript-fsa'
import { CarouselPlatform, VersionsResponse } from 'typescript-fetch-api'

const actionCreator = actionCreatorFactory('VersionUpdates')

export const navigateToVersionUpdates = actionCreator('GO_VERSION_UPDATES')

export interface FetchVersionUpdatesPayload {
	page: number
	pageSize: number
	platform: CarouselPlatform
}
export type FetchVersionUpdatesAction = Action<FetchVersionUpdatesPayload>
export const fetchingVersionUpdates = actionCreator.async<FetchVersionUpdatesPayload, VersionsResponse, Error>('FETCH_VERSION_UPDATES')

export interface SetVersionUpdatePayload {
	version?: string
}
export const setVersionUpdate = actionCreator<SetVersionUpdatePayload>('SET_VERSION_UPDATE')
