import actionCreatorFactory, { Action, Success, Failure } from 'typescript-fsa'
import { RegisterRequest } from './types'

const actionCreator = actionCreatorFactory('Auth')

// payload determines whether to take the user to checkout screen afterwards
export interface RegisterRequestPayload {
	registerRequest: RegisterRequest
	goCheckoutAfter: boolean
}
export type RegisterRequestAction = Action<RegisterRequestPayload>
export type RegisterRequestSuccessAction = Action<Success<RegisterRequestPayload, Response>>
export type RegisterRequestFailureAction = Action<Failure<RegisterRequestPayload, Error>>
export const register = actionCreator.async<RegisterRequestPayload, Response, Error>('REGISTER')