import React, { useState } from 'react'
import { TabPanel } from '@mui/lab'
import { Box, Button, CircularProgress, FormControl, FormHelperText, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { ProductList, ProductListItem, UpdateListProductsActionType } from 'typescript-fetch-api'

import { ListDialogTabPanelEnum, ListDialogTabPanelProps } from '../types'
import ListCategoryInput from '../../mylists/components/ListCategoryInput'
import { ListCategory } from '../../../common/mylists/types'
import ListSelectInput from './ListSelectInput'
import { addProductToList, updateProductsInList } from '../../../common/mylists/actions'

const AddToListTabPanel = (props: ListDialogTabPanelProps): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Local State
	 */

	const [quantityError, setQuantityError] = useState<string | undefined>(undefined)

	const [selectedListCategory, setSelectedListCategory] = useState<ListCategory | undefined>(undefined)
	const [selectedListCategoryError, setSelectedListCategoryError] = useState<string | undefined>(undefined)

	const [selectedList, setSelectedList] = useState<ProductList | undefined>(undefined)
	const [selectedListError, setSelectedListError] = useState<string | undefined>(undefined)

	const hnzOnly = props.product ? props.product.hnzOnly : props.items!.some(item => item.product.hnzOnly)

	return (
		<TabPanel value={ListDialogTabPanelEnum.ADD_TO_LIST}>
			<form className="list-dialog__form">
				{/* only show quantity if adding single product */}
				{props.product && (
					<React.Fragment>
						<FormControl fullWidth={true}>
							<TextField
								type="number"
								label="Quantity"
								value={props.quantity}
								error={!!quantityError}
								variant="outlined"
								autoComplete="off"
								autoFocus={true}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									const parsedQuantity = parseInt(e.target.value, 10)
									props.setQuantity(parsedQuantity)

									if (!isNaN(parsedQuantity) && parsedQuantity > 0) {
										setQuantityError(undefined)
									} else {
										setQuantityError(!parsedQuantity ? 'A quantity is required' : 'Invalid quantity')
									}
								}}
								inputProps={{
									min: 1,
								}}
							/>
							{quantityError && <FormHelperText error={true}>{quantityError}</FormHelperText>}
						</FormControl>

						<Box m={1} />
					</React.Fragment>
				)}

				<FormControl fullWidth={true}>
					<ListCategoryInput
						selectedAccount={props.selectedAccount}
						listCategory={selectedListCategory}
						showExistingLists={true}
						hnzOnly={hnzOnly}
						error={!!selectedListCategoryError}
						onOptionSelected={(listCategory?: ListCategory) => {
							setSelectedListCategory(listCategory)
							setSelectedListCategoryError(!listCategory ? 'A list category is required' : undefined)
						}}
					/>
					{selectedListCategoryError && <FormHelperText error={true}>{selectedListCategoryError}</FormHelperText>}
				</FormControl>

				<Box m={1} />

				<FormControl fullWidth={true}>
					<ListSelectInput
						listCategory={selectedListCategory}
						list={selectedList}
						error={!!selectedListError}
						onOptionSelected={(list?: ProductList) => {
							setSelectedList(list)
							setSelectedListError(selectedListCategory && selectedList && !list ? 'A list is required' : undefined)
						}}
					/>
					{selectedListError && <FormHelperText error={true}>{selectedListError}</FormHelperText>}
				</FormControl>

				<Box m={1.5} />

				{props.isLoading && <CircularProgress color="primary" />}

				{!props.isLoading && (
					<React.Fragment>
						<Button
							variant="contained"
							color="primary"
							fullWidth={true}
							onClick={() => {
								if (!props.quantity) {
									setQuantityError('A quantity is required')
								} else if (!selectedListCategory) {
									setSelectedListCategoryError('A list category is required')
								} else if (!selectedList) {
									setSelectedListError('A list is required')
								} else {
									if (props.product) {
										const sku = props.product.sku
										// check if product already exists in the list and merge the quantities
										const product = selectedList.productListItems ? selectedList.productListItems.find(item => item.sku === sku) : undefined
										const updatedQuantity = product ? product.quantity + props.quantity : props.quantity

										dispatch(addProductToList.started({
											id: selectedList.id,
											sku: props.product.sku,
											quantity: updatedQuantity,
											customerId: props.selectedAccount && props.selectedAccount.customerId,
											screen: 'Product',
										}))
									} else {
										// multiple products
										const products: ProductListItem[] = props.items!.map(item => ({
											sku: item.product.sku,
											quantity: item.quantity,
										}))
										dispatch(updateProductsInList.started({
											id: selectedList.id,
											products,
											customerId: props.selectedAccount && props.selectedAccount.customerId,
											updateActionType: UpdateListProductsActionType.MERGE,
											screen: 'Product',
										}))
									}
								}
							}}
						>
							Add to List
						</Button>

						<Box m={1} />

						<Button
							variant="outlined"
							fullWidth={true}
							onClick={props.onClose}
						>
							Cancel
						</Button>

						{props.error && (
							<React.Fragment>
								<Box m={1} />
								<p className="message error">{props.error.message || 'There was an error adding the list'}</p>
							</React.Fragment>
						)}
					</React.Fragment>
				)}
			</form>
		</TabPanel>
	)
}

export default AddToListTabPanel
