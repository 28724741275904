import { SagaIterator } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'

import * as actions from './actions'
import { getPlatformSupportImplementation } from '../platform'
import { ApiErrorMessage, getRegisterApi } from '../api'
import { callApi } from '../api/functions'

function* handleVerifyMobile(action: actions.MobileVerificationRequestAction): SagaIterator {
	if (action.payload.mobileVerificationRequest.password) {
		// get push token
		const token = yield call(getPlatformSupportImplementation().getFCMToken)
		// coming from register, use confirmPhoneNumber api call
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.verifyMobile,
			(payload: actions.MobileVerificationRequestPayload) => {
				const request = payload.mobileVerificationRequest
				return getRegisterApi().confirmPhoneNumber({
					confirmPhone: {
						mobileNumber: request.mobileNumber,
						password: request.password,
						code: request.code,
						push_token: token
					},
				}).catch((error) => {
					let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
					if (error instanceof Response) {
						switch (error.status) {
							case 404:
								errorText = 'Code expired; press resend code.'
								break
							case 403:
								errorText = 'Invalid verification code'
								break
							case 400:
								// currently handling this case by showing a more descriptive error message. we could change this to how /register handles getting 400 by signing the user in with the provided credentials
								// TODO: the server also returns a 400 if the user does not exist though - should be different status I think
								errorText = 'Already verified. Please sign in.'
								break
							// TODO another possible response is 401 which means the password is wrong
							// - not really an issue on the MobileApp because you can only navigate to verifyPhone by going through the Register screen (which handles this case)
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
			})
	} else {
		// coming from set new password, pass done, which is handled in the handleVerifyMobileDone function
		yield put(actions.verifyMobile.done({ params: action.payload, result: new Response() }))
	}
}

function* handleResendVerificationCode(action: actions.ResendVerificationCodeAction): SagaIterator {
	// if there is a password then we know we've come from the register screen
	if (action.payload.password) {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.resendVerificationCode,
			(payload: actions.ResendVerificationCodePayload) => {
				return getRegisterApi().resendCode({
					credentials: {
						mobileNumber: payload.mobileNumber,
						password: payload.password
					},
				})
			})
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.resendVerificationCode,
			(payload: actions.ResendVerificationCodePayload) => {
				return getRegisterApi().forgotPassword({ mobileNumber: { mobileNumber: payload.mobileNumber } })
			})
	}
}

function* handleVerifyEmail(action: actions.EmailVerificationRequestAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.verifyEmail,
		(payload: actions.EmailVerificationRequestPayload) => {
			return getRegisterApi().confirmEmail({ token: payload.emailVerificationRequest.token }).catch((error) => {
				let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
				if (error instanceof Response) {
					switch (error.status) {
						case 403:
							errorText = 'Invalid verification token'
							break
						default:
							// @ts-ignore
							if (error.error_description) {
								// @ts-ignore
								errorText = error.error_description
							}
					}
				}
				throw new Error(errorText)
			})
		})
}

export default function* (): SagaIterator {
	yield takeEvery(actions.verifyMobile.started, handleVerifyMobile)
	yield takeEvery(actions.resendVerificationCode.started, handleResendVerificationCode)
	yield takeEvery(actions.verifyEmail.started, handleVerifyEmail)
}