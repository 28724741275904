import actionCreatorFactory, { Success, Action, Failure } from 'typescript-fsa'
import { OrderId, Order } from './types'
import { wrapOfflineAction } from '../api/offline'
import { Order as ServerOrder } from 'typescript-fetch-api'
import { getPlatformSupportImplementation } from '../platform'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Order')

export type SubmitOrderAction = Action<OrderId>
export type SubmitOrderErrorAction = Action<Failure<OrderId, Error>>
export const submitOrder = wrapOfflineAction(actionCreator.async<OrderId, ServerOrder, Error>('SUBMIT_ORDER_OFFLINE'), (payload) => {
	return getPlatformSupportImplementation().submitOrderToApi(payload)
})

export type SubmitOrderOnlineAction = Action<Order>
export type SubmitOrderOnlineSuccessAction = Action<Success<Order, ServerOrder>>
export const submitOrderOnline = actionCreator.async<Order, ServerOrder, Error>('SUBMIT_ORDER_ONLINE')

export type SaveOrderToDatabaseSuccessAction = Action<Success<Order, OrderId>>
export type SaveOrderToDatabaseAction = Action<Order>
export const saveOrderToDatabase = actionCreator.async<Order, OrderId, Error>('SAVE_ORDER_TO_DB')

export type RequeueFailedOrderAction = Action<OrderId>
export const requeueFailedOrder = actionCreator<OrderId>('REQUEUE_FAILED_ORDER')