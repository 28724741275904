import { SagaIterator, Channel } from 'redux-saga'
import { actionChannel, call, fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { AddListRequest, CreateLabelOrder, DeleteListRequest, GetListRequest, GetListsRequest, PagedProductListResponse, ProductList, ProductListItem, ProductListType, UpdateListProductsOperationRequest } from 'typescript-fetch-api'

import { callApi } from '../api/functions'
import { ApiErrorMessage, getContentApi, getLabelOrderApi, getListApi } from '../api'
import * as actions from './actions'
import { mapPagedProductListToProductList } from '../mylists/functions'
import { convertProductListItemToLabelOrderItem } from './functions'
import { authTokenSelector } from '../auth/selectors'
import { labelGroupSelector } from './selectors'
import { downloadFile } from '../../utils/functions'

function* handleGetLabelGroup(action: actions.FetchLabelGroupAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.fetchLabelGroup, (payload: actions.FetchLabelGroupPayload) => {
		const requestParameters: GetListRequest = {
			id: payload.id,
			page: payload.page,
			pageSize: payload.pageSize,
		}
		return getListApi().getList(requestParameters)
			.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response))
	})
}

function* hadleAddLabelGroupItemsToCart(action: actions.FetchLabelGroupSuccessAction): SagaIterator {
	if (action.payload.params.addToCart && action.payload.result.productListItems) {
		// loop through all the label group items and add them to the label cart one by one
		for (const item of action.payload.result.productListItems) {
			// convert to label order item
			const labelOrderItem = convertProductListItemToLabelOrderItem(item)
			yield put(actions.addItemQuantityToLabelCart({ item: labelOrderItem }))
		}
	}
}

function* handleGetLabelGroups(action: actions.FetchLabelGroupsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.fetchLabelGroups, (payload: actions.FetchLabelGroupsPayload) => {
		const includeItems = !!payload.includeItems
		const requestParams: GetListsRequest = {
			productListType: ProductListType.LABEL,
			includeItems,
		}
		return getListApi().getLists(requestParams)
			.then(result => {
				if (result.lists && result.lists.length > 0) {
					// grabbing user lists from the response
					const userLists = result.lists.find(item => !item.prostix_account_id)
					if (userLists && userLists.lists && userLists.lists.length > 0) {
						return userLists.lists
					}
				}
				return []
			})
	})
}

function* handleCreateOrEditLabelsGroup(action: actions.CreateOrEditLabelsGroupAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.createOrEditLabelsGroup, (payload: actions.CreateOrEditLabelsGroupPayload) => {
		const requestParams: AddListRequest = {
			productList: payload.list,
			productListType: ProductListType.LABEL,
			includeItems: false,
		}
		return getListApi().addList(requestParams)
			.then(result => {
				if (result.lists && result.lists.length > 0) {
					// grabbing user lists from the response
					const userLists = result.lists.find(item => !item.prostix_account_id)
					if (userLists && userLists.lists && userLists.lists.length > 0) {
						return userLists.lists
					}
				}
				return []
			})
	})
}

/**
 * This channel allows the queueing of requests for creating/editing label groups.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 */
function* createOrEditLabelGroupChannel() {
	// create a channel for request actions to create/edit a label group
	const requestChannel: Channel<actions.CreateOrEditLabelsGroupAction> = yield actionChannel(actions.createOrEditLabelsGroup.started)
	while (true) {
		// take from the channel
		const action: actions.CreateOrEditLabelsGroupAction = yield take(requestChannel)
		// use a blocking call and perform the requests sequentially
		yield call(handleCreateOrEditLabelsGroup, action)
	}
}

function* handleDeleteLabelGroup(action: actions.DeleteLabelGroupAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.deleteLabelGroup, (payload: ProductList) => {
		const requestParams: DeleteListRequest = {
			id: payload.id,
			productListType: ProductListType.LABEL,
			includeItems: false,
		}
		return getListApi().deleteList(requestParams)
			.then(result => {
				if (result.lists && result.lists.length > 0) {
					// grabbing user lists from the response
					const userLists = result.lists.find(item => !item.prostix_account_id)
					if (userLists && userLists.lists && userLists.lists.length > 0) {
						return userLists.lists
					}
				}
				return []
			})
	})
}

/**
 * This channel allows the queueing of requests for deleting label groups.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 */
function* deleteLabelGroupChannel() {
	// create a channel for request actions to delete a label group
	const requestChannel: Channel<actions.DeleteLabelGroupAction> = yield actionChannel(actions.deleteLabelGroup.started)
	while (true) {
		// take from the channel
		const action: actions.DeleteLabelGroupAction = yield take(requestChannel)
		// use a blocking call and perform the requests sequentially
		yield call(handleDeleteLabelGroup, action)
	}
}

function* handleCreateLabelOrder(action: actions.CreateLabelOrderAction) {
	// @ts-ignore API
	yield call(callApi, action, actions.createLabelOrder, (payload: CreateLabelOrder) => {
		return getLabelOrderApi().createLabelOrder({ createLabelOrder: payload })
	})
}

function* handleCreatedLabelOrder() {
	// refresh label orders (from intial page)
	const action = actions.fetchLabelOrders.started({ page: 0, pageSize: 24 })
	yield call(handleFetchLabelOrders, action)
}

function* handleFetchLabelOrders(action: actions.FetchLabelOrdersAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.fetchLabelOrders, (payload: actions.FetchLabelOrdersPayload) => {
		return getLabelOrderApi().searchLabelOrders({
			page: payload.page,
			pageSize: payload.pageSize,
			searchLabelOrdersRequest: { filters: payload.filters },
		})
	})
}

function* handleFetchLabelOrder(action: actions.FetchLabelOrderAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.fetchLabelOrder, (payload: string) => {
		return getLabelOrderApi().getLabelOrder({ id: payload })
	})
}

/**
 * Handles adding a product to a label group
 * @param action the action containing the label group id and basic product list item details
 */
function* handleAddProductToLabelGroup(action: actions.AddProductToLabelGroupAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.addProductToLabelGroup, (payload: actions.AddProductToLabelGroupPayload) => {
		const { id, sku, quantity, page, pageSize } = payload
		const product: ProductListItem = { sku, quantity }
		const requestParams: UpdateListProductsOperationRequest = {
			id,
			page,
			pageSize,
			updateListProductsRequest: { products: [product] },
		}
		return getListApi().updateListProducts(requestParams)
			.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response))
	})
}

/**
 * Handles removing a product from a label group
 * @param action the action containing the label group id and the product sku to delete
 */
function* handleRemoveProductFromLabelGroup(action: actions.RemoveProductFromLabelGroupAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.removeProductFromLabelGroup, (payload: actions.RemoveProductFromLabelGroupPayload) => {
		const { id, sku, page, pageSize } = payload
		const quantity = 0
		const product: ProductListItem = { sku, quantity }
		const requestParams: UpdateListProductsOperationRequest = {
			id,
			page,
			pageSize,
			updateListProductsRequest: { products: [product] },
		}
		return getListApi().updateListProducts(requestParams)
			.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response))
	})
}

/**
 * Handles removing products from a label group
 * @param action the action containing the label group id and products to remove
 */
function* handleClearLabelGroup(action: actions.ClearLabelGroupAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.clearLabelGroup, (payload: actions.ClearLabelGroupPayload) => {
		const { id, products } = payload
		// set quantity to 0 for all passed productsh we want to remove in this label group
		const updatedQuantityProducts: ProductListItem[] = products.map(product => ({ ...product, quantity: 0 }))
		const requestParams: UpdateListProductsOperationRequest = {
			id,
			updateListProductsRequest: { products: updatedQuantityProducts },
		}
		return getListApi().updateListProducts(requestParams)
			.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response))
	})
}

function* handlePinLabelGroup(action: actions.PinLabelGroupAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.pinLabelGroup, (listId: string) => {
		return getListApi().pinProductList({ id: listId })
	})
}

function* handleUnpinLabelGroup(action: actions.PinLabelGroupAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.unpinLabelGroup, (listId: string) => {
		return getListApi().unpinProductList({ id: listId })
	})
}

/**
 * Handles updating multiple products in a label group
 * @param action the action containing the label group id and basic product list item details
 */
function* handleUpdateProductsInLabelGroup(action: actions.UpdateProductsInLabelGroupAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.updateProductsInLabelGroup, (payload: actions.UpdateProductsInLabelGroupPayload) => {
		const { id, products, page, pageSize, updateActionType } = payload
		const requestParams: UpdateListProductsOperationRequest = {
			id,
			page,
			pageSize,
			updateActionType,
			updateListProductsRequest: { products },
		}
		return getListApi().updateListProducts(requestParams)
			.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response))
	})
}

function* handleUpdateLabelGroupTitle(action: actions.UpdateLabelGroupTitleAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.updateLabelGroupTitle, (payload: actions.UpdateLabelGroupTitlePayload) => {
		const { id, title } = payload
		return getListApi().updateListTitle({ id: id, updateListTitleRequest: { title } })
	})
}

/**
 * Sequentially updates a label group's title and products
 * @param action the action containing the updated label group details
 */
function* handleUpdateLabelGroupTitleAndProducts(action: actions.UpdateLabelGroupTitleAndProductsAction): SagaIterator {
	try {
		const { id, title, products, ...rest } = action.payload

		// 1 - update the label group title
		yield call(() => getListApi().updateListTitle({ id, updateListTitleRequest: { title } }))

		// 2 - update the products (if any)
		let updatedLabelGroup: ProductList | undefined
		// if products array is empty, don't attempt to send to server, as it rejects requests made with empty products array
		if (products.length > 0) {
			updatedLabelGroup = yield call(() => getListApi().updateListProducts({ id, updateListProductsRequest: { products }, ...rest })
				.then((response: PagedProductListResponse) => mapPagedProductListToProductList(response)))
		} else {
			// grab the label group from the store so we can pass that as the result with the updated title
			const storedList = yield select(labelGroupSelector(id))
			if (storedList) {
				updatedLabelGroup = { ...storedList, title }
			}
		}

		// 3 - update the label group in the store
		// NOTE: we pass the bare minimum list in the unlikely case that it couldn't be found in the store
		yield put(actions.updateLabelGroupTitleAndProducts.done({ params: action.payload, result: updatedLabelGroup || { id, title } }))
	} catch (error) {
		yield put(actions.updateLabelGroupTitleAndProducts.failed({ params: action.payload, error: error as Error }))
	}
}

/**
 * Generates the labels of a label group
 * @param action The action containing the label group ID
 */
export function* handleGenerateLabels(action: actions.DownloadLabelsAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.downloadLabels.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.downloadLabels,
			(payload: actions.DownloadLabelsPayload) => {
				return getContentApi()
					.downloadLabels({ labelGroupId: payload.id })
					.then((response: Blob) => {
						const mimeType = 'application/pdf'
						// Create a Blob from the PDF Stream
						const file = new Blob([response], { type: mimeType })
						// chrome sometimes blocks large files being opened in new window, couldnt find exact size limit so using 2mb
						if (payload.view && file.size < 2000000) {
							// Build a URL from the file
							const fileURL = URL.createObjectURL(file)
							// Open the URL on new Window
							window.open(fileURL, '_blank')
						} else {
							// create a download
							const filename = `${payload.id}.pdf`
							downloadFile(response, filename)
						}
					})
			})
	}
}

function* handleUploadLabelGroup(action: actions.UploadLabelGroupAction): SagaIterator {
	const isCreatingNewLabelGroup = action.payload.title !== undefined
	if (isCreatingNewLabelGroup) {
		// performs a chain of calls to handle creating a label group and uploading the items afterwards
		try {
			// 1 - make call to create label group
			const createLabelGroupRequest: AddListRequest = {
				productList: { id: action.payload.id, title: action.payload.title! },
				productListType: ProductListType.LABEL,
			}
			yield call(() => getListApi().addList(createLabelGroupRequest))

			// 2 - add the products to the newly created label group
			const uploadedLabelGroup = yield call(() => getListApi().uploadList({ id: action.payload.id, body: action.payload.file }))

			// 3 - successfully uploaded items, save updated label group to store
			yield put(actions.uploadLabelGroup.done({ params: action.payload, result: uploadedLabelGroup }))
		} catch (error) {
			yield put(actions.uploadLabelGroup.failed({ params: action.payload, error: error as Error }))
		}
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.uploadLabelGroup,
			(payload: actions.UploadLabelGroupPayload) => {
				const { id, file } = payload
				return getListApi().uploadList({ id, body: file })
			})
	}
}

export default function* (): SagaIterator {
	yield fork(createOrEditLabelGroupChannel)
	yield fork(deleteLabelGroupChannel)
	yield takeEvery(actions.fetchLabelGroup.started, handleGetLabelGroup)
	yield takeEvery(actions.fetchLabelGroup.done, hadleAddLabelGroupItemsToCart)
	yield takeEvery(actions.fetchLabelGroups.started, handleGetLabelGroups)
	yield takeEvery(actions.createLabelOrder.started, handleCreateLabelOrder)
	yield takeEvery(actions.createLabelOrder.done, handleCreatedLabelOrder)
	yield takeEvery(actions.fetchLabelOrders.started, handleFetchLabelOrders)
	yield takeEvery(actions.fetchLabelOrder.started, handleFetchLabelOrder)
	yield takeEvery(actions.addProductToLabelGroup.started, handleAddProductToLabelGroup)
	yield takeEvery(actions.removeProductFromLabelGroup.started, handleRemoveProductFromLabelGroup)
	yield takeEvery(actions.clearLabelGroup.started, handleClearLabelGroup)
	yield takeEvery(actions.pinLabelGroup.started, handlePinLabelGroup)
	yield takeEvery(actions.unpinLabelGroup.started, handleUnpinLabelGroup)
	yield takeEvery(actions.updateProductsInLabelGroup.started, handleUpdateProductsInLabelGroup)
	yield takeEvery(actions.updateLabelGroupTitle.started, handleUpdateLabelGroupTitle)
	yield takeEvery(actions.updateLabelGroupTitleAndProducts.started, handleUpdateLabelGroupTitleAndProducts)
	yield takeEvery(actions.downloadLabels.started, handleGenerateLabels)
	yield takeEvery(actions.uploadLabelGroup.started, handleUploadLabelGroup)
}