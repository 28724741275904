import { SagaIterator } from 'redux-saga'
import { put, takeEvery } from 'redux-saga/effects'

import { EmailVerificationRequestSuccessAction, MobileVerificationRequestSuccessAction, verifyEmail, verifyMobile } from '../../common/mobileVerification/actions'
import { login } from '../../common/auth/actions'
import { LoginRequest } from '../../common/auth/types'

function* handleVerifyMobileDone(action: MobileVerificationRequestSuccessAction): SagaIterator {
	const { mobileVerificationRequest: { mobileNumber, password }, goCheckoutAfter } = action.payload.params
	// if there is a password then we know we've come from the register screen, therefore we will attempt to auto login
	if (password) {
		const loginRequest: LoginRequest = { username: mobileNumber, password, goCheckoutAfter }
		yield put(login.started(loginRequest))
	}
}

function* handleVerifyEmailDone(action: EmailVerificationRequestSuccessAction): SagaIterator {
	const { email, password } = action.payload.params.emailVerificationRequest
	if (email && password) {
		const loginRequest: LoginRequest = { username: email, password }
		yield put(login.started(loginRequest))
	}
}

export default function* (): SagaIterator {
	yield takeEvery(verifyMobile.done, handleVerifyMobileDone) // after mobile verified (either from registration or forgot password flow)
	yield takeEvery(verifyEmail.done, handleVerifyEmailDone)
}