import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

// import Component from '../components/CustomerOrdersMenu'
import Component from '../components/CustomerOrdersMenuPopper'
import { navigateToOrdersTab, navigateToBranchQuotes } from '../../platform/actions'
import { SubheaderMenuProps } from '../../platform/types'
import { RootStoreState } from '../../../common/root'
import { isEnabledRemoteConfig } from '../../../common/remoteConfig/functions'
import { RemoteConfig } from '../../../common/remoteConfig/types'

export type OwnProps = SubheaderMenuProps

export interface Props {
	isLabelsEnabled: boolean
	isInterimOrdersEnabled: boolean
}

export interface Actions {
	navigateToOrdersTab: () => void
	navigateToInterimOrdersTab: () => void
	navigateToLabelOrdersTab: () => void
	navigateToQuotesTab: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		isLabelsEnabled: isEnabledRemoteConfig(RemoteConfig.LABELS, state),
		isInterimOrdersEnabled: isEnabledRemoteConfig(RemoteConfig.INTERIM_ORDERS, state),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	navigateToOrdersTab: () => {
		dispatch(navigateToOrdersTab({ tab: 'current-orders' }))
	},
	navigateToInterimOrdersTab: () => {
		dispatch(navigateToOrdersTab({ tab: 'interim-orders' }))
	},
	navigateToLabelOrdersTab: () => {
		dispatch(navigateToOrdersTab({ tab: 'labels' }))
	},
	navigateToQuotesTab: () => {
		dispatch(navigateToBranchQuotes())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
