import { SagaIterator } from 'redux-saga'
import { takeEvery, select, call, put } from 'redux-saga/effects'
import { GetSearchFiltersRequest, ProductSku, SearchFilter, SearchFilterFilterTypeEnum, SearchProductsRequest } from 'typescript-fetch-api'

import * as actions from '../../common/products/actions'
import { UserAccount } from '../../common/accounts/types'
import { orderBranchSelector, selectedAccountSelector } from '../../common/order/selectors'
import { getPriceParams } from '../../common/auth/functions'
import { paginationSizeSelector } from '../platform/selectors'
import { INITIAL_PAGE } from '../platform/content'
import { getContentApi } from '../../common/api'
import { callApi } from '../../common/api/functions'
import { filterChangeAction, FilterQueryAction, getSearchProductsAvailability, loadSearchPrices, performQuickSearch, ProductsLoadedActionSuccess } from '../../common/search/actions'
import { loadHnzProducts } from '../hnz/actions'
import { HnzSearchFilter } from '../hnz/types'
import { allProductsParamsSelector, branchProductsParamsSelector, savedFilterSelector } from '../../common/products/selectors'
import { loadProductFinderAvailability, loadProductFinderPrices, loadProductFinderProducts } from '../../common/productfinder/actions'
import * as NavigationManager from '../navigation/NavigationManager'
import { Paths } from '../navigation/types'
import { HEIGHT_ATTRIBUTE_NAME, LITRES_ATTRIBUTE_NAME, ProductFinderType, WIDTH_ATTRIBUTE_NAME } from '../../common/productfinder/types'

function* loadProducts(action: actions.LoadSubcategoriesAction): SagaIterator {
	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId } = getPriceParams(selectedAccount)
	const pageSize: number = yield select(paginationSizeSelector)

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadProducts,
		(payload: actions.LoadProductsActionPayload) => {
			const requestParameters: SearchProductsRequest = {
				categoryId: payload.categoryId,
				page: payload.page || INITIAL_PAGE,
				pageSize,
				customerId,
				// don't include pricing or stock count to improve loading times (we load them asynchronously after the page of results gets back)
				// includePrices,
				// branchId: selectedBranchId,
				searchRequest: payload.searchRequest,
			}
			return getContentApi().searchProducts(requestParameters)
				.then(response => ({ products: response.products || [], totalProductPagesCount: response.totalPagesCount }))
		})
}

/**
 * Loads the filters associated to the products in the product list.
 * @param action action containing the categoryId of the product list where we load the filters from
 */
function* loadFiltersForProducts(action: actions.LoadSubcategoriesAction): SagaIterator {
	// update store flag to know when fetching product filters
	yield put(actions.setLoadingFiltersState())

	// grabs the user's current selected account which is tagged to the order
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)

	let branchId: number | undefined
	const branchFilter = action.payload.searchRequest?.filters?.find(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH)
	if (branchFilter && branchFilter.id) {
		branchId = parseInt(branchFilter.id, 10)
	}
	const goldProductsOnly: boolean | undefined = action.payload.searchRequest?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.GOLD)

	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadFiltersForProducts,
		(payload: actions.LoadProductsActionPayload) => {
			const requestParameters: GetSearchFiltersRequest = {
				categoryId: payload.categoryId,
				customerId,
				includePrices,
				branchId,
				goldProductsOnly,
			}
			return getContentApi().getSearchFilters(requestParameters)
				.then(response => response.filters || [])
		})
}

function* handleProductsLoaded(action: ProductsLoadedActionSuccess): SagaIterator {
	const { categoryId, queryText, searchRequest } = action.payload.params
	// fix for when loading first page with hnz filter on, the searchRequest property is empty so we need to manually add the hnz filter to request
	const loadedHnzProducts: boolean = action.type === loadHnzProducts.done.type
	const isProductFinder: boolean = action.type === loadProductFinderProducts.done.type
	let filters: SearchFilter[] = searchRequest?.filters ? [...searchRequest.filters] : (loadedHnzProducts && [HnzSearchFilter]) || []

	// remove branch filter (because we don't want to include it for 'VIEW_ALL' count - we add this again later for loading 'BRANCH' count)
	filters = filters.filter(filter => filter.filterType !== SearchFilterFilterTypeEnum.BRANCH)

	const location = NavigationManager.getLocation()
	const pathname = location?.pathname || ''
	const search = location?.search || ''
	const isHWCFinder = pathname.includes(Paths.PRODUCT_FINDER_RESULTS) && search.includes(ProductFinderType.HWC.toString())
	if (isHWCFinder) {
		// to ensure we only get back HWC products we need to add dimension filters, even if the user has not saved any
		let minWidth, maxWidth, minHeight, maxHeight, minLitres, maxLitres
		filters.forEach(filter => {
			if (filter.filterType === SearchFilterFilterTypeEnum.ATTRIBUTE) {
				if (filter.name === LITRES_ATTRIBUTE_NAME && filter.id === 'MIN') minLitres = true
				else if (filter.name === LITRES_ATTRIBUTE_NAME && filter.id === 'MAX') maxLitres = true
				else if (filter.name === WIDTH_ATTRIBUTE_NAME && filter.id === 'MIN') minWidth = true
				else if (filter.name === WIDTH_ATTRIBUTE_NAME && filter.id === 'MAX') maxWidth = true
				else if (filter.name === HEIGHT_ATTRIBUTE_NAME && filter.id === 'MIN') minHeight = true
				else if (filter.name === HEIGHT_ATTRIBUTE_NAME && filter.id === 'MAX') maxHeight = true
			}
		})
		if (minWidth !== true) filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: WIDTH_ATTRIBUTE_NAME, id: 'MIN', value: '0', })
		if (maxWidth !== true) filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: WIDTH_ATTRIBUTE_NAME, id: 'MAX', value: '10000', })
		if (minHeight !== true) filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: HEIGHT_ATTRIBUTE_NAME, id: 'MIN', value: '0', })
		if (maxHeight !== true) filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: HEIGHT_ATTRIBUTE_NAME, id: 'MAX', value: '10000', })
		if (minLitres !== true) filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: LITRES_ATTRIBUTE_NAME, id: 'MIN', value: '0', })
		if (maxLitres !== true) filters?.push({ filterType: SearchFilterFilterTypeEnum.ATTRIBUTE, name: LITRES_ATTRIBUTE_NAME, id: 'MAX', value: '10000', })
	}

	// to load hnzOnly products, must provide a hnz account id
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)

	// console.log('loadProductCounts', 'category:', categoryId, 'query:', queryText, 'filters:', filters)

	// compare old and new branch params to see if we should update the counts
	const oldViewAllParams: actions.LoadProductsCountPayload | undefined = yield select(allProductsParamsSelector)
	const newViewAllParams: actions.LoadProductsCountPayload = { type: 'VIEW_ALL', categoryId, queryText, customerId, searchRequest: { filters }, isProductFinder }
	if (shouldRefreshCounts(oldViewAllParams, newViewAllParams)) {
		// refresh 'VIEW_ALL' count for new settings
		yield put(actions.loadProductsCount.started(newViewAllParams))
	}

	// add branch filter when loading 'BRANCH' counts
	const selectedBranchId: number | undefined = yield select(orderBranchSelector)
	filters = filters.concat({ filterType: SearchFilterFilterTypeEnum.BRANCH, id: selectedBranchId?.toString() }) // server only checks against 'id' field
	// compare old and new branch params to see if we should update the counts
	const oldBranchParams: actions.LoadProductsCountPayload | undefined = yield select(branchProductsParamsSelector)
	const newBranchParams: actions.LoadProductsCountPayload = { type: 'BRANCH', categoryId, queryText, customerId, searchRequest: { filters }, isProductFinder }
	if (shouldRefreshCounts(oldBranchParams, newBranchParams)) {
		// refresh 'BRANCH' count for new settings
		yield put(actions.loadProductsCount.started(newBranchParams))
	}

	// get products from result
	const { result: { products } } = action.payload
	if (products.length === 0) return
	const skus: ProductSku[] = products.map(product => ({ sku: product.sku }))

	// load stock counts for product results
	const branchId: number | undefined = yield select(orderBranchSelector)
	if (branchId) {
		// dispatch the right action based on if doing query text search
		if (queryText) {
			yield put(getSearchProductsAvailability.started({ branchId, skus }))
		} else if (isProductFinder) {
			yield put(loadProductFinderAvailability.started({ branchId, skus }))
		} else {
			yield put(actions.getProductsAvailability.started({ branchId, skus }))
		}
	}

	// load pricing
	if (includePrices) {
		if (queryText) {
			yield put(loadSearchPrices.started({ customerId, skus }))
		} else if (isProductFinder) {
			yield put(loadProductFinderPrices.started({ customerId, skus }))
		} else {
			yield put(actions.loadProductPrices.started({ customerId, skus }))
		}
	}
}

/**
 * Compare the old and new search params to see if anything has changed which means we should update the product counts from the server
 * @param oldParams old product count search params
 * @param newParams new product count search params
 * @returns {@code true} if should reload the product counts from server
 */
function shouldRefreshCounts(oldParams: actions.LoadProductsCountPayload | undefined, newParams: actions.LoadProductsCountPayload): boolean {
	if (oldParams === undefined) return true
	if (oldParams.categoryId !== newParams.categoryId) return true
	if (oldParams.queryText !== newParams.queryText) return true
	if (oldParams.customerId !== newParams.customerId) return true
	if (oldParams.searchRequest && !newParams.searchRequest) return true
	if (!oldParams.searchRequest && newParams.searchRequest) return true
	if (oldParams.searchRequest && newParams.searchRequest && oldParams.searchRequest.filters && newParams.searchRequest.filters) {
		// if suppliers added/removed, length will change
		if (oldParams.searchRequest.filters.length !== newParams.searchRequest.filters.length) return true
		for (let i = 0; i < oldParams.searchRequest.filters.length; i++) {
			// if selected branch swapped, length will stay the same but content in array will change
			if (oldParams.searchRequest.filters[i].filterType !== newParams.searchRequest.filters[i].filterType) return true
			if (oldParams.searchRequest.filters[i].id !== newParams.searchRequest.filters[i].id) return true
			if (oldParams.searchRequest.filters[i].name !== newParams.searchRequest.filters[i].name) return true
		}
	}
	return false
}

function* loadProductsCount(action: actions.loadProductsCountAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.loadProductsCount, ({ categoryId, queryText, customerId, searchRequest }: actions.LoadProductsCountPayload) => {
			const requestParameters: SearchProductsRequest = {
				query: queryText,
				categoryId,
				page: 0,
				pageSize: 1,
				customerId,  // required - when loading hnz only products must supply hnz account
				includePrices: false, // don't load pricing
				branchId: undefined, // don't need to load individual availability
				searchRequest,
			}
			return getContentApi().searchProducts(requestParameters).then(response => response.count)
		})
}

function* reloadProductFiltersOnBranchUpdate(action: FilterQueryAction): SagaIterator {
	const pathname = NavigationManager.getLocation()?.pathname || ''
	const isProductFinderPath = pathname.includes(Paths.PRODUCT_FINDER_RESULTS)
	if (isProductFinderPath) return

	const savedFilters: actions.ProductFilter | undefined = yield select(savedFilterSelector)
	const searchFilters = action.payload.searchRequest?.filters

	const categoryId = action.payload.categoryId
	if (categoryId) {
		// check if the user enabled/disabled the branch or gold products filter
		const newBranchFilter = searchFilters?.find(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH)
		const hasEnabledBranchFilter = !savedFilters?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH) && !!newBranchFilter
		const hasDisabledBranchFilter = !!savedFilters?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.BRANCH) && !newBranchFilter
		const newGoldProductsFilter = searchFilters?.find(item => item.filterType === SearchFilterFilterTypeEnum.GOLD)
		const hasEnabledGoldProductsFilter = !savedFilters?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.GOLD) && !!newGoldProductsFilter
		const hasDisabledGoldProductsFilter = !!savedFilters?.filters?.some(item => item.filterType === SearchFilterFilterTypeEnum.GOLD) && !newGoldProductsFilter
		if (hasEnabledBranchFilter || hasDisabledBranchFilter || hasEnabledGoldProductsFilter || hasDisabledGoldProductsFilter) {
			yield call(loadFiltersForProducts, {
				type: actions.loadProducts.started.type,
				payload: {
					categoryId,
					searchRequest: action.payload.searchRequest,
				},
			})
		}
	}
}

export default function* (): SagaIterator {
	yield takeEvery(actions.loadProducts.started, loadProducts)
	yield takeEvery(actions.loadProducts.started, loadFiltersForProducts)

	yield takeEvery(actions.loadProducts.done, handleProductsLoaded)
	yield takeEvery(performQuickSearch.done, handleProductsLoaded)
	yield takeEvery(loadHnzProducts.done, handleProductsLoaded)
	yield takeEvery(loadProductFinderProducts.done, handleProductsLoaded)

	yield takeEvery(actions.loadProductsCount.started, loadProductsCount)

	yield takeEvery(filterChangeAction, reloadProductFiltersOnBranchUpdate)
}