import { ProductList } from 'typescript-fetch-api'

import { RootStoreState } from '../root'

/**
 * Retrieves a label group from the store given an ID
 * @param id the label group ID
 * @returns the label group, if found
 */
export const labelGroupSelector = (id: string) => (state: RootStoreState): ProductList | undefined => {
	for (const group of state.labels.labelGroups) {
		if (group.id === id) return group
	}
	return undefined
}