import moment from 'moment'
import { OrderStatus, InvoiceType, ClosureType, CreateOrderDespatchMethodEnum, ClickAndCollectTimeslot, ClickAndCollectTimeslotDay, CreateLabelOrderDespatchMethodEnum, OrderDespatchMethodEnum, LabelOrderDespatchMethodEnum, Product, ProductPrices, BranchProductDetail } from 'typescript-fetch-api'

import { reportError } from '../../modules/logging/functions'
import { UserAccount } from '../accounts/types'
import { getDespatchMethodLabel } from '../cart/functions'
import { InvoiceFilter, InvoiceFilterType } from '../financials/types'
import { SortDirection } from './types'

/**
 * For Server orders we display status text, since there are a lot and don't have icons to clearly represent each status
 */
export function getServerOrderStatusText(status: string): string {
	switch (status) {
		case OrderStatus.RECEIVED:
		case OrderStatus.SENT_TO_PROSTIX:
		case OrderStatus.RETRY_ERP:
		case OrderStatus.FAILED:
		case OrderStatus.SENT_TO_INTEGRATION:
		case OrderStatus.FAILED_INTEGRATION:
		case OrderStatus.RETRY_INTEGRATION:
			return 'Received'
		case OrderStatus.SENT_TO_STORE:
		case OrderStatus.RETRY_EMAIL_STORE:
		case OrderStatus.SENT_TO_SALES_COORDINATOR:
		case OrderStatus.RETRY_SALES_COORDINATOR:
			return 'Sent to store'
		case OrderStatus.PICKUP:
			return 'Ready for collection'
		case OrderStatus.DESPATCHED:
		case OrderStatus.DELIVERY:
			return 'Ready for delivery'
		case OrderStatus.CONTACTUS:
			return 'Please contact us'
		case OrderStatus.BACKORDERED:
			return 'Some items backordered'
		case OrderStatus.RELEASED:
			return 'Completed'
		default:
			return ''
	}
}

/**
 * When selecting order statuses in a list we provide text based on the enums
 */
export function getSelectOrderStatusText(status: string): string {
	switch (status) {
		case OrderStatus.RECEIVED:
			return 'Received'
		case OrderStatus.SENT_TO_STORE:
			return 'Sent to store'
		case OrderStatus.PICKUP:
			return 'Ready for collection'
		case OrderStatus.DESPATCHED:
		case OrderStatus.DELIVERY:
			return 'Ready for delivery'
		case OrderStatus.CONTACTUS:
			return 'Please contact us'
		case OrderStatus.BACKORDERED:
			return 'Some items backordered'
		case OrderStatus.RELEASED:
			return 'Released'
		default:
			return ''
	}
}

/**
 * This list is used for selecting items
 */
export function getSelectOrderStatusList(): OrderStatus[] {
	const statuses: OrderStatus[] = []
	statuses.push(OrderStatus.BACKORDERED)
	statuses.push(OrderStatus.CONTACTUS)
	statuses.push(OrderStatus.DELIVERY)
	statuses.push(OrderStatus.PICKUP)
	statuses.push(OrderStatus.RECEIVED)
	statuses.push(OrderStatus.SENT_TO_STORE)
	return statuses
}

/**
 * 
 * Gets the InvoiceType for the type in the filter
 */
export function getInvoiceType(value: string): InvoiceType | undefined {
	if (value === 'Invoice') {
		return InvoiceType.INVOICE
	}
	if (value === 'Credit Note') {
		return InvoiceType.CREDIT_NOTE
	}
	if (value === 'Cash Sale') {
		return InvoiceType.CASH_SALE
	}
	reportError('Unknown invoice type: ' + value)
	return undefined
}

export function getInvoiceTypeTitle(value: InvoiceType): string {
	if (value === InvoiceType.INVOICE) {
		return 'Invoice'
	}
	if (value === InvoiceType.CREDIT_NOTE) {
		return 'Credit Note'
	}
	if (value === InvoiceType.CASH_SALE) {
		return 'Cash Sale'
	}
	reportError('Unknown invoice type: ' + value)
	return ''
}

/**
 * Gets the Filter title for the filter list
 */

export function getFilterTitle(invoiceFilerType: InvoiceFilterType): string {
	switch (invoiceFilerType) {
		case InvoiceFilterType.DATE:
			return 'Date'
		case InvoiceFilterType.INVOICE_NO:
			return 'Invoice No.'
		case InvoiceFilterType.ORDER_NO:
			return 'Order No.'
		case InvoiceFilterType.BRANCH:
			return 'Branch'
		case InvoiceFilterType.TYPE:
			return 'Type'
		case InvoiceFilterType.DISPLAY_$0_INVOICES:
			return 'Display $0 Invoices'
	}
}

export function getFilterLabel(type: InvoiceFilterType): string {
	switch (type) {
		case InvoiceFilterType.DATE:
			return 'between'
		case InvoiceFilterType.INVOICE_NO:
		case InvoiceFilterType.ORDER_NO:
			return 'contains'
		case InvoiceFilterType.BRANCH:
		case InvoiceFilterType.TYPE:
			return 'equals'
		case InvoiceFilterType.DISPLAY_$0_INVOICES:
			return ''
	}
}

export function getFilterValue(item: InvoiceFilter, value: any): string {
	switch (item.type) {
		case InvoiceFilterType.DATE:
			return `${formattedDate(value.fromDate)} - ${formattedDate(value.toDate)}`
		case InvoiceFilterType.BRANCH:
			return value.name
		case InvoiceFilterType.TYPE:
			return getInvoiceTypeTitle(value)
		case InvoiceFilterType.INVOICE_NO:
		case InvoiceFilterType.ORDER_NO:
		default:
			return value
	}
}

/**
 * Dates
 */

export const DATE_FORMAT = 'YYYY-MM-DD'
export const API_DATE_TIME_FORMAT = 'ddd, DD MMM YYYY hh:mm:ss Z'
export const DESPATCH_TIME_FORMAT = 'hh:mmA'
export const DESPATCH_DATE_FORMAT = 'D MMMM YYYY'

export function formatAmount(value: number, excludeSymbol?: boolean, excludeSeparator?: boolean): string {
	const format = excludeSeparator ? '$1' : '$1,'
	const formattedAmount = value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, format)
	if (value < 0)`${formattedAmount.slice(0, 1)}${excludeSymbol ? '' : '$'}${formattedAmount.slice(1)}`
	return `${excludeSymbol ? '' : '$'}${formattedAmount}`
}

export function formattedDate(rawDate: string | undefined): string {
	if (rawDate === undefined) {
		return ''
	}
	let date = moment(rawDate, DATE_FORMAT)
	return date.format('DD/MM/YYYY')
}

export function getTodaysDate(): string {
	return moment().format(DATE_FORMAT)
}

/**
 * Gets the default from date to use when fetching invoices and no date range is set by the user
 * @returns today minus 90 days. formatted as 'YYYY-MM-DD'.
 */
export function getDefaultInvoiceFromDate(): string {
	return moment().subtract(90, 'days').format(DATE_FORMAT)
}

export function getMinDespatchDateMoment(): moment.Moment {
	return moment().startOf('day') // TODAY
}

export function getMaxDespatchDateMoment(): moment.Moment {
	return moment().startOf('day').add(2, 'M')
}

export function getMinDespatchDate(): string {
	return getTodaysDate()
}

export function getMaxDespatchDate(): string {
	return moment().add(2, 'M').format(DATE_FORMAT)
}

export function getFormattedClickAndCollectTimeslot(timeslot: ClickAndCollectTimeslot): string {
	return moment(timeslot.startTime, API_DATE_TIME_FORMAT).format(DESPATCH_TIME_FORMAT)
}

/**
 * Gets the despatch details for an in-store order
 * NOTE:
 * - date: set to today by default
 * - timeslot: set to AM if before midday, PM if otherwise
 * @returns the in-store despatch details
 */
export function getInStoreDespatchDetails(): { date: string, slot: ClosureType.AM | ClosureType.PM } {
	const today = moment()
	const maxTimeAM = moment('11:59:59', 'hh:mm:ss')
	return {
		date: today.format(DATE_FORMAT),
		slot: today.isSameOrBefore(maxTimeAM, 'seconds') ? ClosureType.AM : ClosureType.PM,
	}
}

/**
 * Handles getting a valid timeslot for the given time.
 * 
 * @param timeslots the list of timeslots
 * @param time the time to compare
 * @returns the matching c&c timeslot
 */
function getMatchingClickAndCollectTimeslot(timeslots: ClickAndCollectTimeslot[], time: moment.Moment): ClickAndCollectTimeslot | undefined {
	if (timeslots.length > 0) {
		// need to change current time to be the same date as the timeslots (server only returns time (h:m))
		const startTime = moment(timeslots[0].startTime, API_DATE_TIME_FORMAT)
		time.set({
			year: startTime.year(),
			month: startTime.month(),
			date: startTime.date()
		})

		// check if the time passed is earlier than ALL of the timeslots, and grab first available timeslot
		const timeEarlierThanSlots = time.isBefore(moment(timeslots[0].startTime, API_DATE_TIME_FORMAT), 'minutes')
		if (timeEarlierThanSlots) return timeslots[0]
	}

	for (let i = 0; i < timeslots.length; i++) {
		const timeslot = timeslots[i]
		if (time.isBetween(moment(timeslot.startTime, API_DATE_TIME_FORMAT), moment(timeslot.endTime, API_DATE_TIME_FORMAT), 'minutes')) {
			return timeslot
		}
	}

	return undefined
}

/**
 * Handles getting the timeslots, given a specific day.
 * 
 * @param timeslots the list of timeslots
 * @param day the day to get the timeslots from
 * @returns the day's timeslots
 */
export function getClickAndCollectTimeslotsForDay(timeslots: ClickAndCollectTimeslotDay[], day: string): ClickAndCollectTimeslot[] {
	// get timeslots for day
	const timeslotsForDay = timeslots.find(item => moment(item.day).isSame(day, 'day'))?.timeslots
	return timeslotsForDay || []
}

/**
 * Handles getting the c&c despatch details.
 * This recursively goes through the next list of days if there is no valid timeslot for the given day.
 * 
 * @param timeslots the list of timeslots
 * @param day the day to use for comparison when getting the despatch details
 * @returns the c&c despatch details
 */
export function getCollectInOneHourDespatchDetails(timeslots: ClickAndCollectTimeslotDay[], day: string): { despatchDate: string, despatchClickAndCollectTimeslot: ClickAndCollectTimeslot } | undefined {
	// get timeslots for day
	const timeslotsForDay = getClickAndCollectTimeslotsForDay(timeslots, day)

	let hasInvalidTimeslots = false
	// check for available timeslots
	if (!timeslotsForDay || timeslots.length === 0) {
		hasInvalidTimeslots = true
	} else {
		// check if day is today
		const isDaySameAsToday = moment(day).isSame(getTodaysDate(), 'day')
		if (isDaySameAsToday) {
			// if today, get next available timeslot in an hour
			const timeInOneHour = moment().add(1, 'hour')
			const validTimeslot = getMatchingClickAndCollectTimeslot(timeslotsForDay, timeInOneHour)
			if (!validTimeslot) {
				hasInvalidTimeslots = true
			} else {
				return {
					despatchDate: day,
					despatchClickAndCollectTimeslot: validTimeslot,
				}
			}
		} else {
			// if another day, get first available timeslot
			return {
				despatchDate: day,
				despatchClickAndCollectTimeslot: timeslotsForDay[0],
			}
		}
	}

	// no valid timeslots, get next available day with timeslots
	if (hasInvalidTimeslots) {
		const nextDay = moment(day, DATE_FORMAT).add(1, 'day').format(DATE_FORMAT)
		return getCollectInOneHourDespatchDetails(timeslots, nextDay)
	}

	return undefined
}

/**
 * Calculates whether the selected account belongs to the logged in user.
 */
export function isOwnAccount(account: UserAccount, mobile?: string, email?: string): boolean {
	const accountMobile = account.mobileNumber
	if (accountMobile && accountMobile === mobile) {
		return true
	}
	const accountEmail = account.email
	if (accountEmail && accountEmail === email) {
		return true
	}
	return false
}

/**
 * Retrieves the comparator given a sort order. Handles empty values.
 * Note: Empty values are sorted last
 * https://stackoverflow.com/a/29829361
 * 
 * @param a the first value to compare
 * @param b the second value to compare
 * @param order the order to sort against (ASCENDING / DESCENDING)
 * @param key the key to sort against
 * @returns the comparator
 */
export const comparator = <T>(a: T, b: T, order: SortDirection, key: keyof T): number => {
	// equal items are sorted equally
	if (a[key] === b[key]) {
		return 0
	}
	// nulls/undefined sort after anything else (dont use !a[key] to handle numbers being 0, eg sort by ordinal)
	else if (a[key] === undefined || a[key] === null) {
		return 1
	}
	else if (b[key] === undefined || b[key] === null) {
		return -1
	}
	// if ascending, lowest sorts first
	else if (order === SortDirection.ASCENDING) {
		return a[key] < b[key] ? -1 : 1
	}
	// if descending, highest sorts first
	return a[key] < b[key] ? 1 : -1
}

/**
 * Handles sorting lists given an order and key
 * 
 * @param data the data
 * @param order the order to sort against (ASCENDING / DESCENDING)
 * @param key the key to sort against
 * @returns the sorted data
 */
export const getSortedData = <T>(data: T[], key: keyof T, order?: SortDirection): T[] => {
	return data.sort((a: T, b: T) => comparator(a, b, order || SortDirection.ASCENDING, key))
}

/**
 * Remove leading 0s from id
 * @param id the string with leading 0s
 */
export function removeLeadingZeros(id: string): string {
	let value = ''
	let foundNonZero = false
	for (let letter of id) {
		if (foundNonZero) {
			value += letter
		} else if (letter !== '0') {
			value += letter
			foundNonZero = true
		}
	}
	return value
}

/**
 * Checks if a string only contains whitespaces (ie. spaces, tabs or line breaks).
 * @param value the string to check
 */
export const isInvalidString = (value: string): boolean => value.replace(/\s/g, '').length === 0

const ORDER_INSTRUCTIONS_SEPARATOR = ' - '

/**
 * Creates instructions such as site contact, phone number and time slot
 */
export function createInstructionsForOrder(despatchMethod: OrderDespatchMethodEnum | LabelOrderDespatchMethodEnum | CreateOrderDespatchMethodEnum | CreateLabelOrderDespatchMethodEnum, despatchSlot?: ClosureType, despatchClickAndCollectTimeslot?: ClickAndCollectTimeslot): string {
	const despatchMethodLabel = getDespatchMethodLabel(despatchMethod)
	let instructions = ''

	// order type; pickup / delivery / in-store
	instructions += despatchMethodLabel

	// despatch timeslot
	instructions += ORDER_INSTRUCTIONS_SEPARATOR
	if (despatchSlot) {
		instructions += despatchSlot
	} else if (despatchClickAndCollectTimeslot) {
		instructions += getFormattedClickAndCollectTimeslot(despatchClickAndCollectTimeslot)
	}

	return instructions
}

/**
 * Gets the despatch slot from the shipping instructions.
 * We split the instructions only on the first occurrence of the separater, since c&c timeslots are formatted in the same way.
 * ie Click & Collect - 13:00 - 13:50
 * 
 * @param instructions the shipping instructions
 * @returns the despatch slot
 */
export function getDespatchSlotFromInstructions(instructions: string): string {
	const [, ...rest] = instructions.split(ORDER_INSTRUCTIONS_SEPARATOR)
	return rest.join(ORDER_INSTRUCTIONS_SEPARATOR)
}

/**
 * Handles reordering items given a new index
 * @param items the items
 * @param from the previous index
 * @param to the new index
 * @returns the reordered items
 */
export const reorderItems = <T>(items: T[], from: number, to: number): T[] => (
	items.reduce((prev: T[], current: T, idx: number, self: T[]) => {
		if (from === to) {
			prev.push(current)
		}
		if (idx === from) {
			return prev
		}
		if (from < to) {
			prev.push(current)
		}
		if (idx === to) {
			prev.push(self[from])
		}
		if (from > to) {
			prev.push(current)
		}
		return prev
	}, [])
)

/**
 * Handles adding pricing to the products
 * @param products the products to add pricing to
 * @param prices the prices to add
 * @returns the products with pricing
 */
export function appendPricingToApiProducts(products: Product[], prices: Array<ProductPrices>): Product[] {
	return products.map(product => ({
		...product,
		...prices.find(priced => priced.sku === product.sku)
	}))
}

/**
 * Handles adding availability to the products
 * @param products the products to add availability to
 * @param availability the availability to add
 * @returns the products with availability
 */
export function appendAvailabilityToApiProducts(products: Product[], availability: Array<BranchProductDetail>): Product[] {
	return products.map(product => ({
		...product,
		quantityAvailable: availability.find(count => count.productId === product.sku)?.stockAvailable
	}))
}

/**
 * Checks for a valid number input.
 * This covers limitations for when an input is type="number" and the user enters a value that is not a number.
 * 
 * https://github.com/mui/material-ui/issues/10582#issuecomment-1509048033
 * 
 * @param value the value to check
 * @returns true if the value is a valid number input
 */
export const isValidNumberInput = (value: string): boolean => {
	if (value === 'e' || value === 'E' || value === '-' || value === '+') {
		return false
	}
	return true
}

/**
 * Handles removing trailing zeroes from a given value.
 * @param value the value to remove trailing zeroes from
 * @returns the value with trailing zeroes removed
 */
export const removeTrailingZeroes = (value: string): string => {
	const floatValue = parseFloat(value)
	return floatValue.toString()
}