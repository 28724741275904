import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { BranchesResponse, ClickAndCollectTimeslotDay, DeliveryTimeslotDay, GetClickAndCollectTimeslotsRequest, GetDeliveryTimeslotsRequest, GetStandardHoursRequest, RegionStandardHours } from 'typescript-fetch-api'

import { Branch, Region } from './types'
import { ProductInfo } from '../../modules/platform'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Showrooms')

export const navigateToRegions = actionCreator('NAVIGATE_TO_REGIONS')

export type NavigateToBranchSelectActionPayload = Action<ProductInfo>
export const navigateToBranchSelect = actionCreator<ProductInfo>('NAVIGATE_TO_BRANCH_SELECT')

export interface FetchRegionsPayload {
	forceUpdateRegions?: boolean
}
export type FetchRegionsResponse = BranchesResponse | undefined
export type FetchRegionsRequestSuccessAction = Action<Success<FetchRegionsPayload, FetchRegionsResponse>>
export type FetchRegionsAction = Action<FetchRegionsPayload>
export const fetchRegions = actionCreator.async<FetchRegionsPayload, FetchRegionsResponse, Error>('LOAD_REGIONS')
export const storedRegions = actionCreator<Array<Region>>('STORED_REGIONS')

export type NavigateToShowroomsActionPayload = Action<Region>
export const navigateToShowrooms = actionCreator<Region>('NAVIGATE_TO_SHOWROOMS')

export type NavigateToShowroomDetailsActionPayload = Action<Branch>
export const navigateToShowroomDetails = actionCreator<Branch>('NAVIGATE_TO_SHOWROOM_DETAIL')

export type FetchClickAndCollectTimeslotsAction = Action<GetClickAndCollectTimeslotsRequest>
export const fetchClickAndCollectTimeslots = actionCreator.async<GetClickAndCollectTimeslotsRequest, ClickAndCollectTimeslotDay[], Error>('FETCH_CLICK_AND_COLLECT_TIMESLOTS')

export type FetchDeliveryTimeslotsAction = Action<GetDeliveryTimeslotsRequest>
export const fetchDeliveryTimeslots = actionCreator.async<GetDeliveryTimeslotsRequest, DeliveryTimeslotDay[], Error>('FETCH_DELIVERY_TIMESLOTS')

export type FetchStandardHoursAction = Action<GetStandardHoursRequest>
export const fetchStandardHours = actionCreator.async<GetStandardHoursRequest, RegionStandardHours[], Error>('FETCH_STANDARD_HOURS')
