import '../styles/ChangeBranchDrawer.scss'

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Divider, Drawer, IconButton, Radio } from '@mui/material'
import { Branch, BranchProductDetail, Region } from 'typescript-fetch-api'
import SwipeableViews from 'react-swipeable-views'

import { RootStoreState } from '../../../common/root'
import { branchSelected, navigateToReviewCart } from '../../../common/cart/actions'
import { ChangeBranch } from '../types'
import { getBranchesAvailability } from '../../../common/product/actions'
import { BranchesAvailability } from '../../../common/product/types'
import usePrevious from '../../../utils/hooks/usePrevious'
import StockAvailabilityLogo from '../../products/components/StockAvailablityLogo'
import ChangeBranchConfirmDialog from './ChangeBranchConfirmDialog'
import { isCurrentDay } from '../../../common/showrooms/functions'
import BranchDetails from './BranchDetails'
import BranchOpeningHours from './BranchOpeningHours'

interface Props {
	isDrawerVisible: boolean
	type: ChangeBranch
	sku?: string
	onHideDrawer: () => void
}

const ChangeBranchDrawer = ({ isDrawerVisible, type, sku, onHideDrawer }: Props): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	* Store State
	*/

	const regions = useSelector<RootStoreState, Region[]>(state => state.showrooms.regions)
	const defaultBranchId = useSelector<RootStoreState, string | undefined>(state => state.order.branchId)
	const branchesAvailability = useSelector<RootStoreState, BranchesAvailability>(state => state.product.branchesAvailability)

	/**
	 * Local State
	 */

	// currently viewed region (index)
	const [activeRegionIndex, setActiveRegionIndex] = useState<number>(regions.findIndex(region => region.branches.find(branch => branch.id === defaultBranchId)))
	const [scrollToSelectedBranch, setScrollToSelectedBranch] = useState<boolean>(false)
	const [temporarySelectedBranch, setTemporarySelectedBranch] = useState<Branch | undefined>(undefined)

	const region: Region | undefined = regions[activeRegionIndex]

	const availability: BranchProductDetail[] | undefined = (region && branchesAvailability) ?
		region.id === '2' || region.id === 'NTHN' ? branchesAvailability.northern :
			region.id === '3' || region.id === 'CENT' ? branchesAvailability.central :
				region.id === '4' || region.id === 'LCNT' ? branchesAvailability.lowerCentral :
					region.id === '5' || region.id === 'STHN' ? branchesAvailability.southern
						: undefined
		: undefined

	// detect when drawer is opened
	const prevIsDrawerVisible = usePrevious<boolean>(isDrawerVisible)

	/**
	 * Effects / Subscriptions
	 */

	useEffect(() => {
		// if user changed the selected branch from Another part in the app, we should update the current page (current region index)
		setActiveRegionIndex(regions.findIndex(region => region.branches.find(branch => branch.id === defaultBranchId)))
	}, [defaultBranchId, regions])

	useEffect(() => {
		// loading branches availability when drawer is visible and it's availability drawer
		if (sku && region && isDrawerVisible && type === ChangeBranch.AVAILABILITY) {
			// check we didn't load availability for active region yet
			let shouldLoadAvailability = false
			switch (region.id) {
				case '2':
				case 'NTHN':
					if (branchesAvailability.northern.length === 0) {
						shouldLoadAvailability = true
					}
					break
				case '3':
				case 'CENT':
					if (branchesAvailability.central.length === 0) {
						shouldLoadAvailability = true
					}
					break
				case '4':
				case 'LCNT':
					if (branchesAvailability.lowerCentral.length === 0) {
						shouldLoadAvailability = true
					}
					break
				case '5':
				case 'STHN':
					if (branchesAvailability.southern.length === 0) {
						shouldLoadAvailability = true
					}
					break
			}
			if (shouldLoadAvailability) dispatch(getBranchesAvailability.started({ productSku: sku, regionId: region.id, branchIds: region.branches.map(branch => branch.id) }))
		}
	}, [sku, type, isDrawerVisible, region, branchesAvailability, dispatch])

	useEffect(() => {
		if (isDrawerVisible && !prevIsDrawerVisible) {
			// change to region of selected branch
			setActiveRegionIndex(regions.findIndex(region => region.branches.find(branch => branch.id === defaultBranchId)))
			// set flag to scroll to selected branch once the views have finished rendering (using the ref callback for this)
			setScrollToSelectedBranch(true)
		}
	}, [isDrawerVisible, prevIsDrawerVisible, regions, defaultBranchId])

	/**
	 * Local Functions
	 */

	const onPreviousRegionClick = () => {
		if (activeRegionIndex > 0) {
			setActiveRegionIndex(activeRegionIndex - 1)
		} else {
			// go to end
			setActiveRegionIndex(regions.length - 1)
		}
	}

	const onNextRegionClick = () => {
		if (activeRegionIndex < regions.length - 1) {
			setActiveRegionIndex(activeRegionIndex + 1)
		} else {
			// go back to start
			setActiveRegionIndex(0)
		}
	}

	const onChangeBranch = (branch: Branch) => {
		// shows the change branch confirm dialog
		setTemporarySelectedBranch(branch)
	}

	const onSelectedBranchRef = (ref: HTMLDivElement | null) => {
		if (ref) {
			// these were the nicest settings when scrolling to a view off screen
			ref.scrollIntoView({ block: 'nearest', inline: 'nearest' })
			setScrollToSelectedBranch(false)
		}
	}

	/**
	 * Render
	 */

	const renderSelectItem = (branch: Branch) => {
		const isDefaultBranch = branch.id === defaultBranchId
		return (
			<React.Fragment key={branch.id}>
				<div
					className="change-branch-drawer__branch-container"
					onClick={() => onChangeBranch(branch)}
					ref={scrollToSelectedBranch && isDefaultBranch ? onSelectedBranchRef : undefined}
				>
					<Radio checked={isDefaultBranch} disabled={true} />
					<div className="change-branch-drawer__branch-content">
						<div>
							<p className={`change-branch-drawer__branch-content--${isDefaultBranch ? 'default-title' : 'title'}`}>{branch.name}</p>
							<span className="change-branch-drawer__branch-content--text">{branch.address}</span>
						</div>
						<div>
							<BranchOpeningHours currentDay={branch.standardHours?.find(day => isCurrentDay(day))} />
							<span className="change-branch-drawer__branch-content--text change-branch-drawer__branch-content--underline">{branch.contactNumber}</span>
						</div>
					</div>
				</div>
				{branch.standardHours &&
					<div className="change-branch-drawer__branch-details">
						<BranchDetails
							standardHours={branch.standardHours}
							latitude={branch.latitude}
							longitude={branch.longitude}
						/>
					</div>
				}
				<Divider className="change-branch-drawer__divider" />
			</React.Fragment>
		)
	}

	const renderAvailabilityItem = (branch: Branch) => {
		const isDefaultBranch = branch.id === defaultBranchId
		const currentBranchStock = availability && availability.find(currentBranch => currentBranch.branchId === branch.id)?.stockAvailable

		return (
			<React.Fragment key={branch.id}>
				<div
					className="change-branch-drawer__branch-container"
					onClick={() => onChangeBranch(branch)}
					ref={scrollToSelectedBranch && isDefaultBranch ? onSelectedBranchRef : undefined}
				>
					<StockAvailabilityLogo stockCount={currentBranchStock} sku={sku} branchName={branch.name} />
					<div className="change-branch-drawer__branch-content">
						<div>
							<p className={`change-branch-drawer__branch-content--title-availability change-branch-drawer__branch-content--${isDefaultBranch ? 'default-title' : 'title'}`}>
								{branch.name}
							</p>
							<span className="change-branch-drawer__branch-content--text">{branch.address}</span>
						</div>
						<div>
							<BranchOpeningHours currentDay={branch.standardHours?.find(day => isCurrentDay(day))} />
							<p className="change-branch-drawer__branch-content--text change-branch-drawer__branch-content--underline">{branch.contactNumber}</p>
							{isDefaultBranch &&
								<span className="change-branch-drawer__branch-content--footer-text">Selected</span>
							}
						</div>
					</div>
				</div>
				{branch.standardHours &&
					<div className="change-branch-drawer__availability">
						<BranchDetails
							standardHours={branch.standardHours}
							latitude={branch.latitude}
							longitude={branch.longitude}
						/>
					</div>
				}
				<Divider className="change-branch-drawer__divider" />
			</React.Fragment>
		)
	}

	return (
		<Drawer
			open={isDrawerVisible}
			onClose={onHideDrawer}
			anchor="right"
			className="change-branch-drawer"
		>
			<div className="change-branch-drawer__close--container">
				<span className="icon-close-round change-branch-drawer__close--icon" onClick={onHideDrawer} />
			</div>

			<div className="change-branch-drawer__content">
				<div className="change-branch-drawer__header">
					<span className="change-branch-drawer__header--text">Change Branch</span>
				</div>

				<div className="change-branch-drawer__container">
					<div className="change-branch-drawer__container--header">
						<IconButton
							className="change-branch-drawer__chevron--right"
							onClick={onPreviousRegionClick}
						>
							<span className="icon-keyboard_arrow_left change-branch-drawer__chevron--icon" />
						</IconButton>
						<IconButton
							className="change-branch-drawer__chevron--left"
							onClick={onNextRegionClick}
						>
							<span className="icon-keyboard_arrow_right change-branch-drawer__chevron--icon" />
						</IconButton>
					</div>
					<SwipeableViews
						enableMouseEvents={true}
						index={activeRegionIndex}
						disabled={true}
						className="change-branch-drawer__container--swipeable"
					>
						{regions.map(region => (
							<React.Fragment key={region.id}>
								<div className=" change-branch-drawer__container--title">
									<span className="change-branch-drawer__header--text">{region.name}</span>
								</div>

								<div className="change-branch-drawer__swipeable-content">
									{type === ChangeBranch.SELECT_BRANCH &&
										region.branches.map(branch => (
											renderSelectItem(branch)
										))
									}
									{type === ChangeBranch.AVAILABILITY &&
										region.branches.map(branch => (
											renderAvailabilityItem(branch)
										))
									}
								</div>
							</React.Fragment>
						))}
					</SwipeableViews>
				</div>

				<div className="change-branch-drawer__footer">
					<span className="change-branch-drawer__footer--title">Changing branch will affect:</span>
					<ol className="change-branch-drawer__footer--text">
						<li>The ordering branch</li>
						<li>Filter by: Product Range</li>
						<li>Stock availability for products</li>
					</ol>
				</div>
			</div>

			{temporarySelectedBranch && (
				<ChangeBranchConfirmDialog
					branch={temporarySelectedBranch}
					onConfirm={(goToReviewCart?: boolean) => {
						dispatch(branchSelected(temporarySelectedBranch.id))

						if (goToReviewCart) dispatch(navigateToReviewCart())

						// closes the dialog and drawer
						setTemporarySelectedBranch(undefined)
						onHideDrawer()
					}}
					onClose={() => setTemporarySelectedBranch(undefined)}
				/>
			)}
		</Drawer>
	)
}

export default ChangeBranchDrawer