import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import * as authActions from '../auth/actions'

export interface StoreState {
	readonly successChangePassword: boolean
	readonly successForgotPassword: boolean
	readonly loadingMessageForgotPassword?: string
	readonly loadingMessageChangePassword?: string
	readonly errorForgotPassword?: Error
	readonly errorChangePassword?: Error
}

const INITIAL_STATE: StoreState = {
	successChangePassword: false,
	successForgotPassword: false,
	loadingMessageForgotPassword: undefined,
	loadingMessageChangePassword: undefined,
	errorForgotPassword: undefined,
	errorChangePassword: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	// forgot password
	.case(actions.forgotPassword.started, (state): StoreState => {
		return { ...state, successForgotPassword: false, loadingMessageForgotPassword: 'Sending verification details…', errorForgotPassword: undefined }
	})
	.case(actions.forgotPassword.done, (state): StoreState => {
		return { ...state, successForgotPassword: true, loadingMessageForgotPassword: undefined, errorForgotPassword: undefined }
	})
	.case(actions.forgotPassword.failed, (state, { error }): StoreState => {
		return { ...state, successForgotPassword: false, loadingMessageForgotPassword: undefined, errorForgotPassword: error }
	})
	// change password
	.case(actions.changePassword.started, (state): StoreState => {
		return { ...state, successChangePassword: false, loadingMessageChangePassword: 'Changing password…', errorChangePassword: undefined }
	})
	.case(actions.changePassword.done, (state): StoreState => {
		return { ...state, successChangePassword: true, loadingMessageChangePassword: undefined, errorChangePassword: undefined }
	})
	.case(actions.changePassword.failed, (state, { error }): StoreState => {
		return { ...state, successChangePassword: false, loadingMessageChangePassword: undefined, errorChangePassword: error }
	})
	.case(actions.clearChangePasswordSuccess, (state): StoreState => {
		return { ...state, successChangePassword: false }
	})
	/* The user has been logged out remove accounts from the state. */
	.cases([authActions.loggedOut, authActions.clearAuthToken], (): StoreState => {
		return INITIAL_STATE
	})