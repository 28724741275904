import * as React from 'react'
import { Button, Divider } from '@mui/material'

import { KaingaOraLogoWithText, LogoBlack, HnzBackground } from '../../../utils/imagesProvider'

import '../../platform/styles/NewFeaturePage.scss'

interface Props {
	onNext: () => void
	onClose: () => void
}

export default class HnzNewFeaturePageOne extends React.PureComponent<Props> {

	render() {
		const { onNext, onClose } = this.props

		return (
			<div className="page">
				<div className="page__heading">
					<img src={KaingaOraLogoWithText} alt="Kāinga Ora Logo" className="page__logo--heading" />

					<Button
						className="page__button--skip"
						onClick={onClose}
					>
						SKIP
					</Button>
				</div>

				<div className="page__content">
					<img src={LogoBlack} alt="Plumbing World Logo" className="page__logo--pw" />

					<Divider className="page__divider" />

					<p className="page__text">Welcome to a new way to shop with Plumbing World.</p>

					<div className="page__boxed-content">
						<p className="page__text">
							Discover what’s new for<br />
							<span className="page__text--emphasized">KĀINGA ORA</span> tradies
						</p>
					</div>
				</div>

				<div className="page__footer">
					<Button
						variant="contained"
						color="primary"
						className="button--fixed-width"
						onClick={onNext}
					>
						LEARN
					</Button>
				</div>

				<img src={HnzBackground} alt="Kāinga Ora" className="page__background page__background--hnz" />
			</div>
		)
	}
}