export type GeneralPaths = 'terms-and-conditions' | 'privacy-policy' | 'terms-of-trade'

export const GeneralScreens: Record<'toc' | 'privacyPolicy' | 'tradeTerms', { title: string, path: GeneralPaths }> = {
	toc: {
		title: 'Terms and Conditions',
		path: 'terms-and-conditions'
	},
	privacyPolicy: {
		title: 'Privacy Policy',
		path: 'privacy-policy'
	},
	tradeTerms: {
		title: 'Terms of Trade',
		path: 'terms-of-trade'
	},
}

export const PW_SERVICE_DESK_URL = 'servicedesk@plumbingworld.co.nz'
export const TRADE_TERMS_URL = 'http://images2.plumbingworld.co.nz/Forms/Terms%20of%20Trade.pdf'
export const PW_LTD = 'privacy@plumbingworld.co.nz'