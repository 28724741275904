import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import { Supplier, ExclusiveBrand } from './types'

export interface StoreState {
	readonly suppliers: Supplier[]
	readonly appliedSupplierIdFilterIds: string[]

	readonly exclusiveBrands: ExclusiveBrand[]
	readonly appliedExclusiveBrands: ExclusiveBrand[]
}

const INITIAL_STATE: StoreState = {
	suppliers: [],
	appliedSupplierIdFilterIds: [],
	exclusiveBrands: [],
	appliedExclusiveBrands: [],
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(actions.saveFiltersAction, (state, payload): StoreState => ({
	...state, appliedSupplierIdFilterIds: payload.filters
}))

reducer.case(actions.loadSuppliersAction.done, (state, payload): StoreState => ({
	...state, suppliers: payload.result
}))

reducer.case(actions.saveExclusiveBrandFiltersAction, (state, payload): StoreState => ({
	...state, appliedExclusiveBrands: payload.filters
}))

reducer.case(actions.loadExclusiveBrandsAction.done, (state, payload): StoreState => ({
	...state, exclusiveBrands: payload.result
}))

reducer.case(actions.clearFilters, (state): StoreState => ({
	...state, appliedSupplierIdFilterIds: [], appliedExclusiveBrands: [],
}))