import actionCreatorFactory, { Action } from 'typescript-fsa'
import { ContactUsRequest } from 'typescript-fetch-api'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('ContactUs')

export const navigateToContactUs = actionCreator('NAVIGATE_TO_CONTACT_US')

export const clearForm = actionCreator('CLEAR_CONTACT_US')

export interface SendContactUsPayload {
	reCaptchaToken: string
	contactUsRequest: ContactUsRequest
}
export type SendContactUsAction = Action<SendContactUsPayload>
export const sendContactUs = actionCreator.async<SendContactUsPayload, Response, Error>('SEND_CONTACT_US')