import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

import Component from '../components/SearchSuggestions'
import { RootStoreState } from '../../../common/root'
import { PlatformProduct, convertPlatformProductToViewProductPayload } from '../../../modules/platform'
import { ViewProductPayload, navigateToProduct } from '../../../common/product/actions'
import { ProductFinderType } from '../../../common/productfinder/types'
import { navigateToProductFinder } from '../../../common/productfinder/actions'

export interface Props {
	products: ReadonlyArray<PlatformProduct>
	searchExcludesHnzProducts?: boolean
	loading: boolean
}

export interface OwnProps {
	searchText?: string
	open: boolean
	anchorElement: HTMLElement | null
	onToggle: (value: boolean) => void
	onViewMore: () => void
	logSearchEvent: (sku?: string) => void
}

export interface Actions {
	onSelectProduct: (product: PlatformProduct) => void
	navigateToProductFinder: (mode: ProductFinderType) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		products: state.search.quickSearchResults || [],
		loading: state.search.fetchingQuickSearchResults,
		searchExcludesHnzProducts: state.search.quickSearchExcludesHnzProducts,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onSelectProduct: (product: PlatformProduct) => {
		const payload: ViewProductPayload = convertPlatformProductToViewProductPayload(product)
		dispatch(navigateToProduct(payload))
		// log search event
		ownProps.logSearchEvent(product.sku)
	},
	navigateToProductFinder: (mode: ProductFinderType) => {
		dispatch(navigateToProductFinder(mode))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
