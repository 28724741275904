export enum RemoteConfig {
	LABELS = 'labels',
	PRICEBOOKS = 'pricebooks',
	CLICK_AND_COLLECT = 'click_and_collect',
	PRODUCT_COMMENTS = 'product_comments',
	MANAGE_SCHEDULED_INVOICES = 'manage_scheduled_invoices',
	ACCOUNT_PAYMENT = 'account_payment',
	STATEMENT_MESSAGE = 'statement_message',
	INTERIM_ORDERS = 'interim_orders',
}

export enum RemoteConfigProperty {
	USERS = 'users',
	CUSTOMERS = 'customers',
	AVAILABLE_TO_ALL = 'availabletoall',
}

export interface RemoteConfigFeature {
	[RemoteConfigProperty.USERS]: string[]
	[RemoteConfigProperty.CUSTOMERS]: string[]
	[RemoteConfigProperty.AVAILABLE_TO_ALL]: boolean
}

export enum UserProperties {
	USER_ID = 'api_user_id',
}
