import * as React from 'react'

import { OwnProps, Actions } from '../containers/HnzCartDialog'
import { Dialog, DialogContent, Divider, Button } from '@mui/material'
import '../styles/HnzCartDialog.scss'

export default class HnzCartDialog extends React.PureComponent<OwnProps & Actions> {

	_onRemoveHnzOnlyProductsFromCart = () => {
		const { hnzOnlyOrderItems, onRemoveProductFromCart, onConfirm, onCancel } = this.props
		hnzOnlyOrderItems.forEach(item => {
			onRemoveProductFromCart(item)
		})

		onConfirm() // updates the account
		onCancel() // closes the dialog
	}

	render() {
		const { onCancel } = this.props

		return (
			<Dialog
				open={true}
				maxWidth="xs"
				fullWidth={true}
				scroll="paper"
				className="hnz-cart-dialog"
				onClose={onCancel}
			>
				<DialogContent className="hnz-cart-dialog__content">
					<h3 className="hnz-cart-dialog__heading">Kāinga Ora Products in Cart</h3>

					<Divider />

					<p className="hnz-cart-dialog__body">
						Changing the account will remove all product that are exclusive to Kāinga Ora from the cart.
					</p>

					<Button
						variant="contained"
						color="primary"
						fullWidth={true}
						onClick={this._onRemoveHnzOnlyProductsFromCart}
					>
						Confirm
					</Button>

					<Button
						variant="outlined"
						fullWidth={true}
						onClick={onCancel}
					>
						Cancel
					</Button>
				</DialogContent>
			</Dialog>
		)
	}
}