import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

// import Component from '../components/CategoryMenu'
import Component from '../components/CategoryMenuPopper'
import { WebStoreState, SubheaderMenuProps } from '../../platform/types'
import { selectCategoryAction, loadCategories } from '../../../common/categories/actions'
import { loadSubcategoriesForMenu, navigateToTab } from '../../platform/actions'
import { loadHnzCategories, selectHnzCategory } from '../../hnz/actions'
import { Category, CategoryType } from '../../../common/categories/types'
import { navigateToProductFinder } from '../../../common/productfinder/actions'
import { ProductFinderType } from '../../../common/productfinder/types'
import { navigateToBackflow } from '../../../common/backflow/actions'

export type OwnProps = SubheaderMenuProps

export interface Props {
	categories: ReadonlyArray<Category>
	hnzCategories: ReadonlyArray<Category>
	subcategories: ReadonlyArray<Category>
	subSubcategories: ReadonlyArray<Category>
	isLoadingCategories: boolean
	isLoadingHnzCategories: boolean
	isLoadingSubcategories: boolean
	isLoadingSubSubcategories: boolean
	categoryId: string | undefined
	subcategoryId: string | undefined
	hnzMode: boolean
}

export interface Actions {
	loadTopLevelCategories: (hnz: boolean) => void
	loadSubcategories: (categoryId: string, type: CategoryType) => void
	navigateToCategoryTab: () => void
	navigateToCategory: (categoryId: string, categoryTitle: string, hnz: boolean) => void
	navigateToHnzProducts?: () => void
	navigateToProductFinder: (mode: ProductFinderType) => void
	navigateToBackflowProducts: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: WebStoreState): Props => {
	return {
		categories: state.categories.categories || [],
		isLoadingCategories: !!state.categories.loadingMessage,
		subcategories: state.platform.menuSubcategories || [],
		isLoadingSubcategories: state.platform.isLoadingSubcategories,
		categoryId: state.platform.categoryId,
		subSubcategories: state.platform.menuSubSubcategories || [],
		isLoadingSubSubcategories: state.platform.isLoadingSubSubcategories,
		subcategoryId: state.platform.subcategoryId,
		hnzCategories: state.hnz.hnzCategories || [],
		isLoadingHnzCategories: state.hnz.isLoadingHnzCategories,
		hnzMode: state.hnz.hnzMode,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	loadTopLevelCategories: (hnz: boolean) => {
		if (hnz) {
			dispatch(loadHnzCategories.started())
		} else {
			dispatch(loadCategories.started({ categoryId: undefined, type: 'category' }))
		}
	},
	loadSubcategories: (categoryId: string, type: CategoryType) => {
		dispatch(loadSubcategoriesForMenu.started({ categoryId, type }))
	},
	navigateToCategoryTab: () => {
		dispatch(navigateToTab({ tab: 'categories' }))
	},
	navigateToCategory: (categoryId: string, categoryTitle: string, hnz: boolean) => {
		if (hnz) {
			dispatch(selectHnzCategory({ categoryId, categoryTitle }))
		} else {
			// @ts-ignore we prevent passing a type so we know we're loading a category
			dispatch(selectCategoryAction({ categoryId, categoryTitle }))
		}
	},
	navigateToHnzProducts: () => {
		dispatch(selectHnzCategory(undefined))
	},
	navigateToProductFinder: (mode: ProductFinderType) => {
		dispatch(navigateToProductFinder(mode))
	},
	navigateToBackflowProducts: () => {
		dispatch(navigateToBackflow())
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
