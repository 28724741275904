import * as React from 'react'

import * as Container from '../../../common/offline/containers/ReadyGate'
import Root from '../../platform/containers/Root'

export default class ReadyGate extends React.PureComponent<Container.Props & Container.Actions> {

	render() {
		const { ready } = this.props
		if (ready) {
			return (
				<Root />
			)
		}
		return (
			<div />
		)
	}
}