import '../styles/ListDialog.scss'

import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, Tab } from '@mui/material'
import { TabContext, TabList } from '@mui/lab'
import { useSelector } from 'react-redux'

import { ProductInfo } from '../../platform'
import AddToListTabPanel from './AddToListTabPanel'
import CreateNewListTabPanel from './CreateNewListTabPanel'
import { UserAccount } from '../../../common/accounts/types'
import { RootStoreState } from '../../../common/root'
import usePrevious from '../../../utils/hooks/usePrevious'
import { ListDialogTabPanelEnum } from '../types'
import { OrderItem } from '../../../common/order/types'

interface Props {
	// provide either single product or list of items (from cart)
	product?: ProductInfo
	items?: OrderItem[]
	onClose: () => void
}

const ListDialog = (props: Props): JSX.Element => {

	/**
	 * Store State
	 */

	const selectedAccount = useSelector<RootStoreState, UserAccount | undefined>(state => state.order.selectedAccount)
	const updatingList = useSelector<RootStoreState, boolean>(state => state.lists.editingFromScreen === 'Product' && (state.lists.editingList || state.lists.deletingList))
	const errorUpdatingList = useSelector<RootStoreState, Error | undefined>(state => state.lists.errorEditingList || state.lists.errorDeletingList)

	/**
	 * Local State
	 */

	const [tabIndex, setTabIndex] = useState<ListDialogTabPanelEnum>(ListDialogTabPanelEnum.ADD_TO_LIST)
	// persist the quantity set on both tabs
	const [quantity, setQuantity] = useState<number>(1)

	// Get the previous value (was passed into hook on last render)
	const prevUpdatingList = usePrevious<boolean>(updatingList)

	/**
	 * Effects/Subscriptions
	 */

	/**
	 * Effect that closes the list dialog on successful update
	 */
	useEffect(() => {
		// NOTE: the `prev` values are undefined on the first render, so we make an extra check for that
		if (prevUpdatingList !== undefined) {
			const successUpdatingList = prevUpdatingList !== updatingList && !updatingList && !errorUpdatingList
			if (successUpdatingList) {
				props.onClose()
			}
		}
		// missing dependency: 'props'
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prevUpdatingList, updatingList, errorUpdatingList])

	return (
		<Dialog
			open={true}
			maxWidth="xs"
			fullWidth={true}
			onClose={!updatingList ? props.onClose : undefined}
			className="list-dialog"
		>
			<DialogContent>
				<TabContext value={tabIndex}>
					<TabList
						onChange={(_: React.ChangeEvent<{}>, value: ListDialogTabPanelEnum) => {
							setTabIndex(value)
						}}
						indicatorColor="primary"
						centered={true}
						TabIndicatorProps={{
							className: 'list-dialog__indicator',
						}}
					>
						<Tab label="Add to List" value={ListDialogTabPanelEnum.ADD_TO_LIST} className="list-dialog__tab" />
						<Tab label="Create New List" value={ListDialogTabPanelEnum.CREATE_NEW_LIST} className="list-dialog__tab" />
					</TabList>

					<Box m={2} />

					{props.product ?
						<React.Fragment>
							<p className="list-dialog__text">{props.product.longDescription}</p>
							<p className="list-dialog__text">{props.product.sku}</p>
						</React.Fragment>
						:
						<p className="list-dialog__text">Adding {props.items!.length} item{props.items!.length > 1 ? 's' : ''} to list.</p>
					}

					{tabIndex === ListDialogTabPanelEnum.ADD_TO_LIST && (
						<AddToListTabPanel
							product={props.product}
							items={props.items}
							selectedAccount={selectedAccount}
							isLoading={updatingList}
							error={errorUpdatingList}
							quantity={quantity}
							setQuantity={setQuantity}
							onClose={props.onClose}
						/>
					)}

					{tabIndex === ListDialogTabPanelEnum.CREATE_NEW_LIST && (
						<CreateNewListTabPanel
							product={props.product}
							items={props.items}
							selectedAccount={selectedAccount}
							isLoading={updatingList}
							error={errorUpdatingList}
							quantity={quantity}
							setQuantity={setQuantity}
							onClose={props.onClose}
						/>
					)}
				</TabContext>
			</DialogContent>
		</Dialog>
	)
}

export default ListDialog
