import * as React from 'react'

import '../styles/CarouselPagination.css'

interface Props {
	index: number
	isActive: boolean
	onClick?: (index: number) => void
}

export default class CarouselPaginationDot extends React.PureComponent<Props> {

	_handleClick = () => {
		this.props.onClick!(this.props.index)
	}

	render() {
		const { isActive, onClick } = this.props
		let className = 'carousel-pagination__dot'
		if (isActive) {
			className += ' carousel-pagination__dot--active'
		}

		if (onClick) {
			return (
				<div
					className={`${className} carousel-pagination__dot--clickable`}
					onClick={this._handleClick}
				/>
			)
		}

		return <div className={className} />
	}
}