export type IntegrationStatus = 'CONNECT' | 'DISCONNECT' | undefined

export type XeroInfoType = 'NAME' | 'CODE' | 'UPDATE' | undefined

export const SUBSCRIPTION_NOT_SET = 'NotSet'

export enum Partners {
    XERO = 'xero',
    SIMPRO = 'simpro',
}

export interface IntegrationDetail {
    id: number
    title: string
    description: string
    icon: string
}