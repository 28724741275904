import '../styles/CartList.scss'

import * as React from 'react'
import { Divider, List } from '@mui/material'

import { OrderItem } from '../../../common/order/types'
import { PriceType } from '../../../common/accounts/types'
import { ProductInfo } from '../../platform'
import { getOrderPriceSummary } from '../functions'
import CartItemDetailed from './CartItemDetailed'
import DrawerAccordion from '../../platform/components/DrawerAccordion'
import { ProductAvailability } from '../../../common/cart/types'
import { filterOrderItemsByStockAvailability, getDummyDataForProductAvailability } from '../../../common/cart/functions'
import StockAvailabilityLogo from '../../products/components/StockAvailablityLogo'
import { NOT_INSTOCK_DESCRIPTION } from '../content'

interface Props {
	orderItems: OrderItem[]
	priceType: PriceType
	hasHnzAccountSelected: boolean
	isProductCommentsEnabled: boolean
	onRemoveProductFromCart: (product: OrderItem) => void
	onProductSelected: (product: ProductInfo) => void
	onUpdateProductQuantityInCart: (product: ProductInfo, quantity: number) => void
	onUpdateProductCommentInCart: (product: ProductInfo, quantity: number, comment: string) => void
}

export default class CartListWithStocks extends React.Component<Props> {

	render() {
		const { orderItems, priceType, hasHnzAccountSelected, isProductCommentsEnabled, onRemoveProductFromCart, onProductSelected, onUpdateProductQuantityInCart, onUpdateProductCommentInCart } = this.props

		// separate items into groups based on stock availability
		const itemsInStock = filterOrderItemsByStockAvailability(orderItems, ProductAvailability.IN_STOCK)
		const itemsLimitedStock = filterOrderItemsByStockAvailability(orderItems, ProductAvailability.LIMITED_STOCK)
		const itemsAvailableToOrder = filterOrderItemsByStockAvailability(orderItems, ProductAvailability.AVAILABLE_TO_ORDER)

		// HNZ expanded mode
		if (hasHnzAccountSelected) {
			const hnzProducts = orderItems.filter(order => !!order.product.hnz)
			const otherProducts = orderItems.filter(order => !order.product.hnz)

			// calculate sub totals for each section
			const hnzProductsSubtotal = getOrderPriceSummary(hnzProducts, priceType, !!hasHnzAccountSelected).subtotal
			const otherProductsSubtotal = getOrderPriceSummary(otherProducts, priceType, !!hasHnzAccountSelected).subtotal

			return (
				<List className="cart-list" disablePadding={true}>
					{hnzProducts.length > 0 &&
						<React.Fragment>
							<DrawerAccordion
								title="KĀINGA ORA PRODUCTS"
								headerClassName="cart-list__accordion-header--hnz"
								divider={false}
								subtitle={`${hnzProducts.length === 1 ? '1 Item' : `${hnzProducts.length} Items`}`}
							>
								{hnzProducts.map((item, index) => (
									<React.Fragment key={item.product.sku}>
										<CartItemDetailed
											product={item.product}
											quantity={item.quantity}
											comment={item.comment}
											priceType={priceType}
											stockCount={item.product.quantityAvailable}
											isProductCommentsEnabled={isProductCommentsEnabled}
											onRemoveProductFromCart={onRemoveProductFromCart}
											onProductSelected={onProductSelected}
											onUpdateProductQuantityInCart={onUpdateProductQuantityInCart}
											onUpdateProductCommentInCart={onUpdateProductCommentInCart}
										/>
										{index < hnzProducts.length - 1 && <Divider className="cart-list__divider" />}
									</React.Fragment>
								))}
							</DrawerAccordion>
							<div className="cart-list__subtotal--container">
								<span className="cart-list__subtotal">Subtotal</span>
								<span className="cart-list__subtotal--price">${hnzProductsSubtotal.toFixed(2)}</span>
							</div>
						</React.Fragment>
					}
					{otherProducts.length > 0 &&
						<React.Fragment>
							<DrawerAccordion
								title="OTHER PRODUCTS"
								headerClassName="cart-list__accordion-header--available-to-order"
								divider={false}
								subtitle={`${otherProducts.length} Item${otherProducts.length === 1 ? '' : 's'}`}
							>
								{otherProducts.map((item, index) => (
									<React.Fragment key={item.product.sku}>
										<CartItemDetailed
											product={item.product}
											quantity={item.quantity}
											comment={item.comment}
											priceType={priceType}
											stockCount={item.product.quantityAvailable}
											isProductCommentsEnabled={isProductCommentsEnabled}
											onRemoveProductFromCart={onRemoveProductFromCart}
											onProductSelected={onProductSelected}
											onUpdateProductQuantityInCart={onUpdateProductQuantityInCart}
											onUpdateProductCommentInCart={onUpdateProductCommentInCart}
										/>
										{index < otherProducts.length - 1 && <Divider className="cart-list__divider" />}
									</React.Fragment>
								))}
							</DrawerAccordion>
							<div className="cart-list__subtotal--container">
								<span className="cart-list__subtotal">Subtotal</span>
								<span className="cart-list__subtotal--price">${otherProductsSubtotal.toFixed(2)}</span>
							</div>
						</React.Fragment>
					}
				</List>
			)
		}

		// calculate sub totals for each section
		const itemsInStockSubtotal = getOrderPriceSummary(itemsInStock, priceType, !!hasHnzAccountSelected).subtotal
		const itemsLimitedStockSubtotal = getOrderPriceSummary(itemsLimitedStock, priceType, !!hasHnzAccountSelected).subtotal
		const itemsAvailableToOrderSubtotal = getOrderPriceSummary(itemsAvailableToOrder, priceType, !!hasHnzAccountSelected).subtotal

		return (
			<List className="cart-list" disablePadding={true}>
				{itemsInStock.length > 0 &&
					<React.Fragment>
						<DrawerAccordion
							title="IN-STOCK"
							icon={<StockAvailabilityLogo {...getDummyDataForProductAvailability(ProductAvailability.IN_STOCK)} />}
							headerClassName="cart-list__accordion-header--in-stock"
							divider={false}
							subtitle={`${itemsInStock.length === 1 ? '1 Item' : `${itemsInStock.length} Items`}`}
						>
							{itemsInStock.map((item, index) => (
								<React.Fragment key={item.product.sku}>
									<CartItemDetailed
										product={item.product}
										quantity={item.quantity}
										comment={item.comment}
										priceType={priceType}
										isProductCommentsEnabled={isProductCommentsEnabled}
										onRemoveProductFromCart={onRemoveProductFromCart}
										onProductSelected={onProductSelected}
										onUpdateProductQuantityInCart={onUpdateProductQuantityInCart}
										onUpdateProductCommentInCart={onUpdateProductCommentInCart}
									/>
									{index < itemsInStock.length - 1 && <Divider className="cart-list__divider" />}
								</React.Fragment>
							))}
						</DrawerAccordion>
						<div className="cart-list__subtotal--container">
							<span className="cart-list__subtotal">In-Stock Subtotal</span>
							<span className="cart-list__subtotal--price">${itemsInStockSubtotal.toFixed(2)}</span>
						</div>
					</React.Fragment>
				}
				{itemsLimitedStock.length > 0 &&
					<React.Fragment>
						<DrawerAccordion
							title="LIMITED STOCK"
							icon={<StockAvailabilityLogo {...getDummyDataForProductAvailability(ProductAvailability.LIMITED_STOCK)} />}
							headerClassName="cart-list__accordion-header--limited-stock"
							divider={false}
							subtitle={`${itemsLimitedStock.length === 1 ? '1 Item' : `${itemsLimitedStock.length} Items`}`}
						>
							{itemsLimitedStock.map((item, index) => (
								<React.Fragment key={item.product.sku}>
									<CartItemDetailed
										product={item.product}
										quantity={item.quantity}
										comment={item.comment}
										priceType={priceType}
										isProductCommentsEnabled={isProductCommentsEnabled}
										onRemoveProductFromCart={onRemoveProductFromCart}
										onProductSelected={onProductSelected}
										onUpdateProductQuantityInCart={onUpdateProductQuantityInCart}
										onUpdateProductCommentInCart={onUpdateProductCommentInCart}
									/>
									{index < itemsLimitedStock.length - 1 && <Divider className="cart-list__divider" />}
								</React.Fragment>
							))}
						</DrawerAccordion>
						<div className="cart-list__subtotal--container">
							<span className="cart-list__subtotal">Limited Stock Subtotal</span>
							<span className="cart-list__subtotal--price">${itemsLimitedStockSubtotal.toFixed(2)}</span>
						</div>
					</React.Fragment>
				}
				{itemsAvailableToOrder.length > 0 &&
					<React.Fragment>
						<DrawerAccordion
							title="NOT IN-STOCK"
							icon={<StockAvailabilityLogo {...getDummyDataForProductAvailability(ProductAvailability.AVAILABLE_TO_ORDER)} />}
							headerClassName="cart-list__accordion-header--available-to-order"
							divider={false}
							subtitle={`${itemsAvailableToOrder.length} Item${itemsAvailableToOrder.length === 1 ? '' : 's'}`}
							description={NOT_INSTOCK_DESCRIPTION}
						>
							{itemsAvailableToOrder.map((item, index) => (
								<React.Fragment key={item.product.sku}>
									<CartItemDetailed
										product={item.product}
										quantity={item.quantity}
										comment={item.comment}
										priceType={priceType}
										isProductCommentsEnabled={isProductCommentsEnabled}
										onRemoveProductFromCart={onRemoveProductFromCart}
										onProductSelected={onProductSelected}
										onUpdateProductQuantityInCart={onUpdateProductQuantityInCart}
										onUpdateProductCommentInCart={onUpdateProductCommentInCart}
									/>
									{index < itemsAvailableToOrder.length - 1 && <Divider className="cart-list__divider" />}
								</React.Fragment>
							))}
						</DrawerAccordion>
						<div className="cart-list__subtotal--container">
							<span className="cart-list__subtotal">Not In-Stock Subtotal</span>
							<span className="cart-list__subtotal--price">${itemsAvailableToOrderSubtotal.toFixed(2)}</span>
						</div>
					</React.Fragment>
				}
			</List>
		)
	}
}