import * as React from 'react'
import { Divider } from '@mui/material'

import * as Container from '../containers/FinancialsMenu'
import MenuPopper from '../../platform/components/MenuPopper'

export default class FinancialsMenuPopper extends React.PureComponent<Container.OwnProps & Container.Actions> {

	_navigateToInvoices = () => {
		this.props.navigateToInvoices()
		this.props.onClickAway()
	}

	_navigateToAccountBalance = () => {
		this.props.navigateToAccountBalance()
		this.props.onClickAway()
	}

	render() {
		const { anchorElement, onClickAway, isHnzAccount, onCloseSubmenu } = this.props

		return (
			<MenuPopper anchorElement={anchorElement} onClickAway={onClickAway} isHnzAccount={isHnzAccount} titleText="Financials" onCloseSubmenu={onCloseSubmenu}>
				<div className="menu-drawer__item" onClick={this._navigateToInvoices}>
					<span />
					<span className="menu-drawer__item--title">Invoices</span>
					<span />
				</div>
				<Divider className="menu-drawer__footer--divider" />
				<div className="menu-drawer__item" onClick={this._navigateToAccountBalance}>
					<span />
					<span className="menu-drawer__item--title">Account Summary</span>
					<span />
				</div>
			</MenuPopper>
		)
	}
}