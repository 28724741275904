import actionCreatorFactory from 'typescript-fsa'
import { Supplier, ExclusiveBrand } from './types'

const actionCreator = actionCreatorFactory('Filter')

export interface SaveFiltersPayload {
	filters: Array<string>
}
export const saveFiltersAction = actionCreator<SaveFiltersPayload>('SAVE_SUPPLIER_FILTERS')

export const loadSuppliersAction = actionCreator.async<void, Supplier[], Error>('LOAD_SUPPLIERS')

export interface SaveExclusiveBrandFiltersPayload {
	filters: Array<ExclusiveBrand>
}
export const saveExclusiveBrandFiltersAction = actionCreator<SaveExclusiveBrandFiltersPayload>('SAVE_EXCLUSIVE_BRAND_FILTERS')

export const loadExclusiveBrandsAction = actionCreator.async<void, ExclusiveBrand[], Error>('LOAD_EXCLUSIVE_BRANDS')

export const clearFilters = actionCreator('CLEAR_SELECTED_FILTERS')