import '../styles/Root.scss'

import React from 'react'
import { Route } from 'react-router-dom'
import objectFillImages from 'object-fit-images'
import { BrowserRouter, Navigate, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import * as Container from '../containers/Root'
import HeaderNavigator from '../containers/HeaderNavigator'
import SnackbarNotification from '../containers/SnackbarNotification'
import Footer from './Footer'
import UpdateSnackbarNotification from '../../../common-ui/components/UpdateSnackbarNotification'
import NewFeatureDialogs from './NewFeatureDialogs'
import LazySwitch from '../../navigation/components/LazySwitch'
import Routes from '../../../utils/routeProvider'
import Drawers from './Drawers'
import ScrollToTop from '../../navigation/components/ScrollToTop'
import NavigateSetter from '../../navigation/components/NavigateSetter'
import { RootStoreState } from '../../../common/root'
import { Paths } from '../../navigation/types'
import { isAdminOrPrincipal } from '../../../common/mylists/functions'
import { ProductsListParams } from '../../products/types'

function PublicRoute({ children }: { children: React.ReactElement }) {
	const isLoggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.authToken)
	return !isLoggedIn ? children : <Navigate to={Paths.HOME} />
}

function PrivateRoute({ children }: { children: React.ReactElement }) {
	const isLoggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.authToken)
	return isLoggedIn ? children : <Navigate to={Paths.SIGN_IN} />
}

function PrivateRouteAdmin({ children }: { children: React.ReactElement }) {
	const isLoggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.authToken)
	const hasAdminAccountSelected: boolean = useSelector<RootStoreState, boolean>(state => isAdminOrPrincipal(state.order.selectedAccount))
	return isLoggedIn && hasAdminAccountSelected ? children : <Navigate to={isLoggedIn ? Paths.HOME : Paths.SIGN_IN} />
}

function PrivateRouteHnz({ children }: { children: React.ReactElement }) {
	const isLoggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.authToken)
	const isHnzAccount: boolean = useSelector<RootStoreState, boolean>(state => !!state.order.selectedAccount?.hnz)
	const location = useLocation()
	const { id, name } = useParams<ProductsListParams>()

	if (isLoggedIn && isHnzAccount)
		return children

	// if user was viewing hnz product categories, navigate them to non-hnz page for the same category
	if (isLoggedIn && id && name) {
		// remove hnz from path and redirect to top level category
		const path = location.pathname.replace('/kāinga-ora', '').replace('/k%C4%81inga-ora', '') // issue with accent character
		return <Navigate to={path} />
	}

	return <Navigate to={isLoggedIn ? Paths.HOME : Paths.SIGN_IN} />
}

function App({ children }: { children: React.ReactElement[] }) {
	const isLoggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.authToken)
	const location = useLocation() // extracted out into function to rerender on location change
	// we don't want the extra bottom padding on the AroFlo sign in page
	const isAroFloSignInPage = location.pathname.indexOf(Paths.AROFLO_PUNCHOUT) > -1
	return (
		<div className={`app${isLoggedIn && !isAroFloSignInPage ? ' app-auth' : ''}`}>
			{children}
		</div>
	)
}

/**
 * Defines the routes for the app and listens to any router location changes.
 */
export default class Root extends React.PureComponent<Container.Props> {

	componentDidMount() {
		// Adds the object-fit polyfill for images and fixes the problem for IE.
		// We must update the css to add object-fit normally for browsers that support it and font-family for those that don't.
		// https://github.com/fregante/object-fit-images#usage
		objectFillImages(null)
	}

	render() {
		const { isLoggedIn, isLabelsEnabled } = this.props
		return (
			<BrowserRouter>
				<ScrollToTop />
				<NavigateSetter />
				<App>
					<HeaderNavigator />

					<div className="app-main">
						<LazySwitch>
							{/* Private Routes */}
							<Route
								path={Paths.HOME}
								element={
									<PrivateRoute>
										<Routes.Home />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.SEARCH}
								element={
									<PrivateRoute>
										<Routes.SearchResultList />
									</PrivateRoute>
								}
							/>

							{/* Products */}
							<Route
								path={Paths.PRODUCTS}
								element={
									<PrivateRoute>
										<Routes.ProductsNavigator />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.PRODUCTS_SUB}
								element={
									<PrivateRoute>
										<Routes.ProductsNavigator />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.PRODUCTS_SUB_SUB}
								element={
									<PrivateRoute>
										<Routes.ProductsNavigator />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.PRODUCT_DETAILS}
								element={
									<PrivateRoute>
										<Routes.ProductEntry />
									</PrivateRoute>
								}
							/>

							{/* Kainga Ora/HNZ Routes */}
							<Route
								path={Paths.PRODUCTS_HNZ}
								element={
									<PrivateRouteHnz>
										<Routes.HnzProducts />
									</PrivateRouteHnz>
								}
							/>
							<Route
								path={Paths.PRODUCTS_HNZ_SUB}
								element={
									<PrivateRouteHnz>
										<Routes.HnzProducts />
									</PrivateRouteHnz>
								}
							/>

							{/* Product Finder */}
							<Route
								path={Paths.HWC_PRODUCT_FINDER}
								element={
									<PrivateRoute>
										<Routes.HWCProductFinder />
									</PrivateRoute>
								}
							/>

							<Route
								path={Paths.PRODUCT_FINDER_RESULTS}
								element={
									<PrivateRoute>
										<Routes.ProductFinderResults />
									</PrivateRoute>
								}
							/>

							{/* Backflow Water Protection */}
							<Route
								path={Paths.BACKFLOW_LANDING}
								element={
									<PrivateRoute>
										<Routes.BackflowLanding />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.BACKFLOW_BRAND}
								element={
									<PrivateRoute>
										<Routes.BackflowBrandProducts />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.BACKFLOW_PRODUCT_TYPE}
								element={
									<PrivateRoute>
										<Routes.BackflowTypeProducts />
									</PrivateRoute>
								}
							/>

							{/* Orders  */}
							<Route
								path={Paths.CUSTOMER_QUOTES}
								element={
									<PrivateRoute>
										<Routes.BranchQuotes />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.CUSTOMER_QUOTE_DETAILS}
								element={
									<PrivateRoute>
										<Routes.CustomerOrderDetailsNavigator />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.CUSTOMER_ORDER_DETAILS}
								element={
									<PrivateRoute>
										<Routes.CustomerOrderDetailsNavigator />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.INTERIM_ORDER_DETAILS}
								element={
									<PrivateRoute>
										<Routes.CustomerOrderDetailsNavigator />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.LABEL_ORDER_DETAILS}
								element={
									<PrivateRoute>
										{isLabelsEnabled ? <Routes.LabelOrderDetails /> : <Routes.PageUnknown />}
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.ORDERS}
								element={
									<PrivateRoute>
										<Routes.CustomerOrdersNavigator />
									</PrivateRoute>
								}
							/>

							{/* Checkout */}
							<Route
								path={Paths.CHECKOUT}
								element={
									<PrivateRoute>
										<Routes.CheckoutNavigator />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.LABELS_CHECKOUT}
								element={
									<PrivateRoute>
										{isLabelsEnabled ? <Routes.CheckoutNavigator /> : <Routes.PageUnknown />}
									</PrivateRoute>
								}
							/>

							{/* Lists & Labels */}
							<Route
								path={Paths.LISTS}
								element={
									<PrivateRoute>
										<Routes.Lists />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.LIST_GROUP}
								element={
									<PrivateRoute>
										<Routes.AccountList />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.LIST_DETAILS}
								element={
									<PrivateRoute>
										<Routes.ListDetails />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.LABELS}
								element={
									<PrivateRoute>
										{isLabelsEnabled ? <Routes.Labels /> : <Routes.PageUnknown />}
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.LABEL_DETAILS}
								element={
									<PrivateRoute>
										{isLabelsEnabled ? <Routes.LabelGroup /> : <Routes.PageUnknown />}
									</PrivateRoute>
								}
							/>

							{/* Other */}
							<Route
								path={Paths.VERSION_UPDATES}
								element={
									<PrivateRoute>
										<Routes.VersionUpdates />
									</PrivateRoute>
								}
							/>
							<Route
								path={Paths.ADD_ACCOUNT}
								element={
									<PrivateRoute>
										<Routes.AddAccount />
									</PrivateRoute>
								}
							/>

							{/* Public Routes */}
							<Route
								path={Paths.SIGN_IN}
								element={
									<PublicRoute>
										<Routes.SignIn />
									</PublicRoute>
								}
							/>
							<Route
								path={Paths.REGISTER}
								element={
									<PublicRoute>
										<Routes.Register />
									</PublicRoute>
								}
							/>
							<Route
								path={Paths.REGISTER_CONFIRM_EMAIL}
								element={
									<PublicRoute>
										<Routes.EmailVerification />
									</PublicRoute>
								}
							/>
							<Route
								path={Paths.REGISTER_CONFIRM_PHONE}
								element={
									<PublicRoute>
										<Routes.MobileVerification />
									</PublicRoute>
								}
							/>
							<Route
								path={Paths.RESET_PASSWORD}
								element={
									<PublicRoute>
										<Routes.ForgotPassword />
									</PublicRoute>
								}
							/>
							{/* needed different url for third case of resetting password so we can display unique title message */}
							<Route
								path={Paths.VERIFY_ACCOUNT}
								element={
									<PublicRoute>
										<Routes.ForgotPassword />
									</PublicRoute>
								}
							/>
							<Route
								path={Paths.XERO}
								element={
									<PublicRoute>
										<Routes.XeroLandingPage />
									</PublicRoute>
								}
							/>
							<Route
								path={Paths.SIMPRO}
								element={
									<PublicRoute>
										<Routes.SimproLandingPage />
									</PublicRoute>
								}
							/>

							{/* Admin Routes */}
							<Route
								path={Paths.ADMIN}
								element={
									<PrivateRouteAdmin>
										<Routes.Admin />
									</PrivateRouteAdmin>
								}
							/>
							<Route
								path={Paths.FINANCIALS}
								element={
									<PrivateRouteAdmin>
										<Routes.Financials />
									</PrivateRouteAdmin>
								}
							/>

							{/* Other Routes */}
							{/* This is a generic Route since it's being used by both Change password (private) and Reset password (public) */}
							<Route
								path={Paths.CONFIRM_RESET_PASSWORD}
								element={
									<Routes.ChangePassword />
								}
							/>
							<Route
								path={Paths.IMPERSONATE}
								element={
									<Routes.Impersonate />
								}
							/>
							<Route
								path={Paths.TERMS_AND_CONDITIONS}
								element={
									<Routes.GeneralNavigator />
								}
							/>
							<Route
								path={Paths.PRIVACY_POLICY}
								element={
									<Routes.GeneralNavigator />
								}
							/>
							<Route
								path={Paths.TERMS_OF_TRADE}
								element={
									<Routes.GeneralNavigator />
								}
							/>
							<Route
								path={Paths.REGIONS}
								element={
									<Routes.Regions />
								}
							/>
							<Route
								path={Paths.CONTACT}
								element={
									<Routes.ContactUs />
								}
							/>
							<Route
								path={Paths.AROFLO_PUNCHOUT}
								element={
									<Routes.AroFloSignIn />
								}
							/>

							<Route path="*" element={<Routes.PageUnknown />} />
						</LazySwitch>
					</div>

					<Footer />
					<UpdateSnackbarNotification />

					{isLoggedIn ?
						<React.Fragment>
							<SnackbarNotification />
							<NewFeatureDialogs />
							<Drawers />
						</React.Fragment>
						:
						<React.Fragment />
					}
				</App>
			</BrowserRouter>
		)
	}
}
