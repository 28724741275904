import '../styles/ChangeBranchConfirmDialog.scss'

import { Dialog, DialogContent, Divider, Button } from '@mui/material'

import { Branch } from '../../../common/showrooms/types'

type Props = {
	branch: Branch
	onConfirm: (goToReviewCart?: boolean) => void
	onClose: () => void
}

const ChangeBranchConfirmDialog = (props: Props) => {
	return (
		<Dialog
			open={true}
			onClose={props.onClose}
			maxWidth="xs"
			fullWidth={true}
			scroll="paper"
			className="change-branch-confirm-dialog"
		>
			<DialogContent className="change-branch-confirm-dialog__content">
				<h3 className="change-branch-confirm-dialog__text change-branch-confirm-dialog__text--heading">Confirm Branch Change</h3>

				<Divider />

				<p className="change-branch-confirm-dialog__text change-branch-confirm-dialog__text--details">
					You have selected &quot;{props.branch.name}&quot;.<br />
					<b className="change-branch-confirm-dialog__text--note">Note: Products may have changed in availability.</b><br />
					Select &quot;Confirm&quot; to proceed.
				</p>

				<Button
					variant="contained"
					color="primary"
					fullWidth={true}
					onClick={() => props.onConfirm()}
				>
					Confirm
				</Button>

				<Button
					variant="outlined"
					color="primary"
					fullWidth={true}
					onClick={() => props.onConfirm(true)}
				>
					Confirm & Go to Review Cart
				</Button>

				<Button
					variant="outlined"
					fullWidth={true}
					onClick={props.onClose}
				>
					Cancel
				</Button>
			</DialogContent>
		</Dialog>
	)
}

export default ChangeBranchConfirmDialog