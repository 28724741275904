import { Action } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { ERP, ProductSku } from 'typescript-fetch-api'

import * as accountActions from '../../common/accounts/actions'
import * as cartActions from '../../common/cart/actions'
import { AccountStatus, UserAccount } from '../accounts/types'
import { getBranchApi, getUserApi } from '../api'
import { orderBranchSelector, orderItemsSelector, selectedAccountSelector } from './selectors'
import { OrderItem } from '../../common/order/types'
import { callApi } from '../api/functions'
import { branchSelector } from '../auth/selectors'
import { Branch } from '../showrooms/types'

function* handleUpdateSelectedAccountForOrder(action: accountActions.GetAccountsRequestSuccessAction): SagaIterator {
	const accounts = action.payload.result.accounts
	// we will only allow approved accounts to be selected, therefore, we need to re-validate the selected account when accounts are updated
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	let selectedAccountValidated: UserAccount | undefined

	if (accounts && accounts.length > 0) {
		// find the current account selected so we can check if it's still approved
		const newSelectedAccount: UserAccount | undefined = selectedAccount && accounts.find(item => item.id === selectedAccount.id)
		if (newSelectedAccount && newSelectedAccount.registrationState === AccountStatus.Approved) {
			selectedAccountValidated = newSelectedAccount
		} else {
			// assign an approved account as the selected account
			selectedAccountValidated = accounts.find(item => item.registrationState === AccountStatus.Approved)
		}
	}

	if (selectedAccountValidated) {
		// only update the selected account in the store if different from the current one
		if (!selectedAccount || selectedAccount.id !== selectedAccountValidated.id) {
			yield put(cartActions.accountSelected(selectedAccountValidated))
		}
	} else {
		yield put(cartActions.clearAccountSelected())
	}
}

/**
 * Handles populating the order branch, if empty, when a new account gets selected.
 * @param action the action containing the selected account
 */
function* handlePopulateBranch(action: cartActions.AccountSelectedAction): SagaIterator {
	const orderBranch = yield select(orderBranchSelector)
	if (!orderBranch) {
		const accountDefaultBranch = action.payload.defaultBranchId
		if (accountDefaultBranch) {
			yield put(cartActions.branchSelected(accountDefaultBranch.toString()))
		}
	}
}

/**
 * Handles updating the product stock counts in the cart whenever a new branch for the order is selected.
 */
function* updateProductAvailabilityInCart(action: Action<any>): SagaIterator {
	const orderItems: OrderItem[] = yield select(orderItemsSelector)
	if (orderItems.length > 0) {
		const products: ProductSku[] = orderItems.map(item => ({ sku: item.product.sku }))
		yield call(fetchProductAvailability, products, action)
	}
}

/**
 * If changing from Dynamics to Frameworks branch, check decimal quantities for products with 'ea' uom
 */
function* updateOrderItemQuantitiesForBranchChange(action: Action<any>): SagaIterator {
	const orderBranch: Branch | undefined = yield select(branchSelector)
	const orderItems: OrderItem[] = yield select(orderItemsSelector)
	if (orderItems.length > 0 && orderBranch?.erp === ERP.FRAMEWORKS) {
		const items: OrderItem[] = orderItems.map(item => ({ ...item, quantity: item.product.uom === 'ea' ? Number(item.quantity.toFixed(0)) : item.quantity }))
		yield put(cartActions.updateProductQuantitiesInCart(items))
	}
}

/**
 * Handles fetching stock count for product being added to the cart, if it does not already have that info in the payload
 */
function* handleProductAddedToCart(action: cartActions.AddProductToCartAction): SagaIterator {
	// check if item being added has stock availability already
	if (action.payload.product.quantityAvailable === undefined) {
		const products: ProductSku[] = [{ sku: action.payload.product.sku }]
		yield call(fetchProductAvailability, products, action)
	}
}

/**
 * Handles fetching stock count for product being updated in the cart, if it does not already have that info in the payload
 */
function* handleProductUpdatedInCart(action: cartActions.UpdateProductQuantityInCartAction): SagaIterator {
	// check if item being added has stock availability already & item being added not removed
	if (action.payload.product.quantityAvailable === undefined && action.payload.quantity > 0) {
		const products: ProductSku[] = [{ sku: action.payload.product.sku }]
		yield call(fetchProductAvailability, products, action)
	}
}

// reusable method for fetching stock availability for cart item(s)
function* fetchProductAvailability(products: ProductSku[], action: Action<any>): SagaIterator {
	const branchId: string | undefined = yield select(orderBranchSelector)
	if (branchId) {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			cartActions.updateProductAvailabilityInCart,
			() => getBranchApi().getBranchProducts({ branchId: Number(branchId), branchProductsRequest: { products } })
		)
	}
}

function* getSimproJobs(action: cartActions.GetSimproJobsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, cartActions.getSimproJobs, (payload: cartActions.GetSimproJobsPayload) => {
		const { customerId } = payload
		return getUserApi().getSimproJobs({ customerId })
	})
}

function* getSimproJobSections(action: cartActions.GetSimproJobSectionsAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, cartActions.getSimproJobSections, (payload: cartActions.GetSimproJobSectionsPayload) => {
		const { customerId, jobId } = payload
		return getUserApi().getSimproJobSections({ customerId, jobId: jobId.toString() })
	})
}

function* getSimproJobCostCenters(action: cartActions.GetSimproJobCostCentersAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, cartActions.getSimproJobCostCenters, (payload: cartActions.GetSimproJobCostCentersPayload) => {
		const { customerId, jobId, jobSectionId } = payload
		return getUserApi().getSimproJobCostCenters({ customerId, jobId: jobId.toString(), jobSectionId: jobSectionId.toString() })
	})
}

export default function* (): SagaIterator {
	yield takeEvery(accountActions.getAccounts.done, handleUpdateSelectedAccountForOrder)
	yield takeEvery(cartActions.accountSelected, handlePopulateBranch)
	yield takeLatest([cartActions.branchSelected, cartActions.updateProductAvailabilityInCart.started], updateProductAvailabilityInCart)
	yield takeLatest(cartActions.branchSelected, updateOrderItemQuantitiesForBranchChange)
	yield takeEvery(cartActions.addProductToCart, handleProductAddedToCart)
	yield takeEvery(cartActions.updateProductQuantityInCart, handleProductUpdatedInCart)
	yield takeEvery(cartActions.getSimproJobs.started, getSimproJobs)
	yield takeEvery(cartActions.getSimproJobSections.started, getSimproJobSections)
	yield takeEvery(cartActions.getSimproJobCostCenters.started, getSimproJobCostCenters)
}