import { ProductList, ProductListItem, ProductListType } from 'typescript-fetch-api'

import * as ListDetails from '../../common/mylists/containers/ListDetails'
import * as NewList from '../../common/mylists/containers/NewList'
import * as AccountList from '../../common/mylists/containers/AccountList'
import { UserAccount } from '../../common/accounts/types'

/**
 * New List
 */

export interface NewListLocationState {
	productListItems?: ProductListItem[]
}

export interface NewListNavigationProps extends NewList.OwnPropInfo { }

export function extractNewListNavProps(props: NewListNavigationProps): NewList.OwnPropInfo {
	return props
}

/**
 * List Details
 */

export type ListDetailsNavigationParams = {
	id: string
	listId: string
}

export interface ListDetailsNavigationProps extends ListDetailsNavigationParams { }

export function extractListDetailsNavProps(props: ListDetailsNavigationProps): ListDetails.OwnPropInfo {
	return {
		listId: decodeURIComponent(props.listId),
		accountListId: props.id,
	}
}

/**
 * Account list
 */

export interface AccountListNavigationParams {
	id: string
}

export interface AccountListNavigationProps extends AccountList.OwnPropInfo { }

export function extractAccountListNavProps(props: AccountListNavigationProps): AccountList.OwnPropInfo {
	return props
}

/**
 * Other
 */

export type MyListsBreadcrumbsMatchParams = {
	id?: string
	listId?: string
}

export interface CartItemQuantity {
	sku: string
	quantity: number
}

export interface ListCopyDialogTabPanelProps {
	list: ProductList
	onClose: () => void
}

export interface ListImportDialogTabPanelProps {
	selectedAccount?: UserAccount
	isLoading: boolean
	error?: Error
	productListType?: ProductListType
	onClose: () => void
}

export enum ListDialogTabPanelEnum {
	ADD_TO_LIST = '0',
	CREATE_NEW_LIST = '1',
}

// Note: for ease of sorting, the enum values match the properties on ProductListItem
export enum ListHeading {
	ORDINAL = 'ordinal',
	PRODUCT_DESCRIPTION = 'shortdescription',
}

export const LIST_NAME_MAX_LENGTH = 60

export interface NavigateToNewListState {
	dialogType?: ListsDialogType
}

export enum ListsDialogType {
	CREATE, UPLOAD
}