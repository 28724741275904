import * as Api from 'typescript-fetch-api'

export interface UserAccount extends Api.Account {
}

/**
 * The key represents server value
 */
export enum Role {
	Principal = 'Principal',
	Admin = 'Admin',
	TradePlus = 'Trade+',
	Trade = 'Trade',
	Procurement = 'Procurement',
}

export function getRoleForRoleId(roleId: string): Role {
	switch (roleId) {
		case Role.Principal:
			return Role.Principal
		case Role.Admin:
			return Role.Admin
		case Role.TradePlus:
			return Role.TradePlus
		case Role.Trade:
			return Role.Trade
		case Role.Procurement:
			return Role.Procurement
		default:
			throw Error('Unknown role: ' + roleId)
	}
}

export function getRoleDisplayName(role: Role): string {
	switch (role) {
		case Role.Principal:
			return 'Principal'
		case Role.Admin:
			return 'Admin'
		case Role.TradePlus:
			return 'Trade+'
		case Role.Trade:
			return 'Trade'
		case Role.Procurement:
			return 'Procurement'
		default:
			throw Error('Unknown role: ' + role)
	}
}

/**
 * The key represents server value
 */
export enum AccountStatus {
	Pending = 'pending',
	Approved = 'approved',
	Disabled = 'disabled',
	Deleted = 'deleted',
	Unassigned = 'unassigned',
}

export function getAccountStatusForId(statusId: string): AccountStatus {
	switch (statusId) {
		case AccountStatus.Pending:
			return AccountStatus.Pending
		case AccountStatus.Approved:
			return AccountStatus.Approved
		case AccountStatus.Disabled:
			return AccountStatus.Disabled
		case AccountStatus.Deleted:
			return AccountStatus.Deleted
		case AccountStatus.Unassigned:
			return AccountStatus.Unassigned
		default:
			throw Error('Unknown status: ' + statusId)
	}
}

export function getAccountStatusDisplayName(status: AccountStatus): string {
	switch (status) {
		case AccountStatus.Pending:
			return 'Pending'
		case AccountStatus.Approved:
			return 'Approved'
		case AccountStatus.Disabled:
			return 'Disabled'
		case AccountStatus.Deleted:
			return 'Deleted'
		case AccountStatus.Unassigned:
			return 'Unassigned'
		default:
			throw Error('Unknown status: ' + status)
	}
}

/**
 * The key represents server value
 */
export enum UserStatus {
	CodeSent = 'code_sent',
	InvalidCode = 'invalid_code',
	Verified = 'verified',
	CodeSendFailure = 'code_send_failure',
	Disabled = 'disabled',
}

export enum PriceType {
	RRP = 'recommendedRetailPrice',
	TRD = 'tradePrice',
	CST = 'accountPrice',
}

export function getPriceTypeDisplayName(priceType: PriceType): string {
	switch (priceType) {
		case PriceType.RRP:
			return 'Retail Price'
		case PriceType.TRD:
			return 'Trade Price'
		case PriceType.CST:
			return 'Cost Price'
		default:
			throw Error('Unknown price type: ' + priceType)
	}
}

export function getPriceTypeDisplayNameShort(priceType: PriceType): string {
	switch (priceType) {
		case PriceType.RRP:
			return 'Retail'
		case PriceType.TRD:
			return 'Trade'
		case PriceType.CST:
			return 'Cost'
		default:
			throw Error('Unknown price type: ' + priceType)
	}
}