import '../../mylists/styles/ListCategoryInput.scss'

import React, { useEffect, useState } from 'react'
import { TextField, Autocomplete, AutocompleteValue, createFilterOptions, AutocompleteChangeReason } from '@mui/material'
import { useSelector } from 'react-redux'
import { ProductList, ProductListType } from 'typescript-fetch-api'

import { ListCategory } from '../../../common/mylists/types'
import { RootStoreState } from '../../../common/root'
import { API_USER_LISTS_TITLE } from '../../../common/mylists/content'

interface Props {
	listCategory: ListCategory | undefined
	list?: ProductList
	error?: boolean
	// flag to reuse select for Labels
	// NOTE: we use this to tap into the labels reducer instead of the lists reducer
	productListType?: ProductListType
	onOptionSelected: (list?: ProductList) => void
}

/**
 * Input that allows the user to select a list from a list category/parent.
 * NOTE: 
 * - this component uses the same styles defined on the ListCategoryInput component
 * - this component is being reused for label groups using the `productListType` prop; only difference is the reducer being accessed for the options
 * @param props the component props
 * @returns the List select
 */
const ListSelectInput = (props: Props): JSX.Element => {

	/**
	 * Store State
	 */

	const lists = useSelector<RootStoreState, ProductList[]>(state => {
		if (props.productListType === ProductListType.LABEL) {
			return state.labels.labelGroups
		}

		const category = props.listCategory
			? state.lists.lists.find(item => (
				props.listCategory!.customerId
					? item.prostix_account_id === props.listCategory!.customerId
					: item.title === API_USER_LISTS_TITLE
			))
			: undefined
		return category && category.lists ? category.lists : []
	})

	/**
	 * Local State
	 */

	const [inputValue, setInputValue] = useState<string>((props.list && props.list.title) || '')

	/**
	 * Effects / Subscriptions
	 */

	/**
	 * Effect that resets the selected list option every time a new list category (effectively the parent) is selected.
	 */
	useEffect(() => {
		props.onOptionSelected(undefined)
		// missing dependency: 'props'
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.listCategory])

	/**
	 * Local Functions
	 */

	const _filterOptions = createFilterOptions<ProductList | null>({
		stringify: option => {
			if (option) {
				if (option.customerId) return option.customerId + option.title
				else return option.title
			}
			return ''
		},
	})

	return (
		<Autocomplete<ProductList | null, false, false, false>
			inputValue={inputValue}
			value={props.list}
			options={lists}
			getOptionLabel={option => option ? option.title : ''}
			autoComplete={true}
			filterOptions={_filterOptions}
			renderInput={(params) => (
				<TextField
					{...params}
					label={props.productListType === ProductListType.LABEL ? 'Label Group' : 'List'}
					variant="outlined"
					inputProps={{
						...params.inputProps,
						autoComplete: 'off',
					}}
					error={props.error}
				/>
			)}
			onChange={(_: React.ChangeEvent<{}>, value: AutocompleteValue<ProductList | null, false, false, true>, reason: AutocompleteChangeReason) => {
				if (reason === 'selectOption') {
					props.onOptionSelected(value as ProductList)
				} else if (reason === 'clear') {
					props.onOptionSelected(undefined)
				}
			}}
			onInputChange={(_: React.ChangeEvent<{}>, value: string) => {
				setInputValue(value)
			}}
			isOptionEqualToValue={(option: ProductList | null, value: ProductList | null) => option === value}
			renderOption={(optionProps: React.HTMLAttributes<HTMLLIElement>, option: ProductList | null) => {
				if (!option) return undefined
				const { className, ...rest } = optionProps
				return (
					<li className={`${className} list-category-input__option`} {...rest}>
						<p className="list-category-input__text--title">{option.title}</p>
						{option.customerId && <p className="list-category-input__text">{option.customerId}</p>}
					</li>
				)
			}}
			disabled={props.productListType !== ProductListType.LABEL && (!props.listCategory || lists.length === 0)}
			popupIcon={<span className="icon-keyboard_arrow_down list-category-input__icon" />}
			className="list-category-input"
		/>
	)
}

export default ListSelectInput
