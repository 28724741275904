import { SagaIterator } from 'redux-saga'
import { takeEvery, select, all, call } from 'redux-saga/effects'
import { Success, Action } from 'typescript-fsa'
import { Order as ServerOrder } from 'typescript-fetch-api'

import * as auth from '../../common/auth/actions'
import * as platformAuth from '../auth/actions'
import * as product from '../../common/product/actions'
import * as order from '../../common/order/actions'
import * as register from '../../common/register/actions'
import * as account from '../../common/accounts/actions'
import * as cart from '../../common/cart/actions'
import * as backflow from '../../common/backflow/actions'

import { firebase } from '../../App'
import { UserAccount } from '../../common/accounts/types'
import { selectedAccountSelector } from '../../common/order/selectors'
import { isText, isValidEmail } from '../../common/auth/functions'
import { PlatformProduct } from '../platform'
import { Order, OrderItem } from '../../common/order/types'
import { usernameSelector } from '../../common/auth/selectors'
import { RouteList, RouteNames } from '../platform/types'
import { CheckoutPath } from '../cart/types'
import { UserProperties } from '../../common/remoteConfig/types'
import { getStockAvailabilityTypeForItem } from '../../common/cart/functions'
import { ProductAvailability } from '../../common/cart/types'
import { Paths } from '../navigation/types'
import { locationChange, LocationChangeAction } from '../platform/actions'
import { getPlatformSupportImplementation } from '../../common/platform'
import { getBackflowAsAnalyticsMethod } from '../../common/backflow/functions'

function setupAnalyticsUser(action: account.GetAccountsRequestSuccessAction) {
	// check if the accounts fetch was from login
	if (action.payload.params.fromLogin) {
		const userId = action.payload.result.userId.toString()
		firebase.analytics.setUserProperties([{ [UserProperties.USER_ID]: userId }])
	}
}

function resetAnalyticsUser() {
	firebase.analytics.setUserProperties([{ [UserProperties.USER_ID]: null }])
}

function handleLoginDone(action: auth.LoginRequestSuccessAction) {
	const username = action.payload.params.username
	let method: 'email' | 'mobile' | 'username' | 'impersonate'

	// checks for the method used by the user to login
	if (!username) {
		// if username is empty we logged in via impersonate, as we are handling the login via 'api/sagas/handleCheckImpersonator'
		method = 'impersonate'
	} else if (isText(username) === false) {
		method = 'mobile'
	} else if (isValidEmail(username)) {
		method = 'email'
	} else {
		method = 'username'
	}

	firebase.analytics.logEvent('login', { method })
}

function* handleNavigateToProductViaClick(action: product.ProductSelectedAction): SagaIterator {
	// To avoid duplicate logging when viewing a product, we check if the product details were passed in the params so we know whether the product has been loaded or not.
	// If it's not loaded, then the `handleNavigateToProductViaUrl` function should be in charge of managing that log.
	if (action.payload.productDetails) {
		const account: UserAccount | undefined = yield select(selectedAccountSelector)
		const customerId: number | undefined = account ? account.customerId : undefined

		firebase.analytics.logEvent('view_item', {
			productId: action.payload.productSku,
			customerId,
		})
	}
}

function* handleNavigateToProductViaUrl(action: Action<Success<product.LoadProductDetailsPayload, PlatformProduct>>): SagaIterator {
	const account: UserAccount | undefined = yield select(selectedAccountSelector)
	const customerId: number | undefined = account ? account.customerId : undefined

	firebase.analytics.logEvent('view_item', {
		productId: action.payload.params.productSku,
		customerId,
	})
}

function* handleOrderDone(action: Action<Success<Order, ServerOrder>>): SagaIterator {
	const username: string | undefined = yield select(usernameSelector)
	const { customerId, orderReference, branchId } = action.payload.result

	firebase.analytics.logEvent('CHECKOUT_COMPLETE', {
		username,
		customerId,
		orderId: orderReference,
		branchId,
	})
}

function handleRegisterDone() {
	firebase.analytics.logEvent('sign_up', {})
}

function handleRemoveProductFromCart(action: Action<OrderItem>) {
	handleSendStockAvailabilityEvent(action.payload)
}

function handleUpdateProductInCart(action: Action<OrderItem>) {
	// check if quanity 0 which means user is removing product from cart
	if (action.payload.quantity === 0) {
		handleSendStockAvailabilityEvent(action.payload)
	}
}

function* handleClearCart(action: cart.ClearCartAction) {
	if (action.payload) {
		const orderItems = action.payload as OrderItem[]
		if (orderItems.length > 0) {
			yield all(orderItems.map(item => call(handleSendStockAvailabilityEvent, item, 'cartClear')))
		}
	}
}

function handleSendStockAvailabilityEvent(item: OrderItem, type?: string) {
	const availability: ProductAvailability = getStockAvailabilityTypeForItem(item.quantity, item.product.quantityAvailable)
	const availabilityLabel: string = availability === ProductAvailability.IN_STOCK ? 'inStock' :
		availability === ProductAvailability.LIMITED_STOCK ? 'limitedStock' :
			'availableToOrder'
	const price = item.product.accountPrice || 0.00

	// build params
	const params = {
		sku: item.product.sku,
		availability: availabilityLabel,
		quantity: item.quantity,
		price,
		type,
	}

	// send event
	firebase.analytics.logEvent('remove_item_from_cart', params)
	//console.log('ProductRemoved - params: ', params)
}

function handleLocationChange(action: LocationChangeAction) {
	const { pathname, search } = action.payload
	let screen: RouteNames = RouteNames.HOME

	if (pathname !== Paths.HOME) {
		// we make an exception for viewing a product since a product can be viewed via:
		// - direct url: `/product/123` 
		// - search params: `/products/BRM/Bathroom?product=123` or `/customerOrders/1234/0?product=123`
		if (search && search.startsWith('?product')) {
			screen = RouteNames.PRODUCT
		} else if (pathname === RouteList.Checkout) {
			// we grab the checkout action from the search params so we know what specific checkout screen we're in
			const urlParams = new URLSearchParams(search)
			const path = urlParams.get('action') || undefined

			switch (path as CheckoutPath) {
				case CheckoutPath.OrderDetails:
					screen = RouteNames.CHECKOUT_ORDER_DETAILS
					break
				case CheckoutPath.Confirm:
					screen = RouteNames.CHECKOUT_CONFIRM
					break
				case CheckoutPath.Complete:
					screen = RouteNames.CHECKOUT_COMPLETE
					break
				default:
					screen = RouteNames.CHECKOUT
					break
			}
		} else if (pathname === RouteList['Label Checkout']) {
			// we grab the checkout action from the search params so we know what specific checkout screen we're in
			const urlParams = new URLSearchParams(search)
			const path = urlParams.get('action') || undefined

			switch (path as CheckoutPath) {
				case CheckoutPath.OrderDetails:
					screen = RouteNames.LABEL_CHECKOUT_ORDER_DETAILS
					break
				case CheckoutPath.Confirm:
					screen = RouteNames.LABEL_CHECKOUT_CONFIRM
					break
				case CheckoutPath.Complete:
					screen = RouteNames.LABEL_CHECKOUT_COMPLETE
					break
				default:
					screen = RouteNames.LABEL_CHECKOUT
					break
			}
		} else {
			let hasExactMatch = false

			// checks for an exact path match from the route list
			for (const key of Object.keys(RouteList)) {
				if (pathname === RouteList[key]) {
					screen = key as RouteNames
					hasExactMatch = true
					break
				}
			}

			// if we haven't found an exact path match, then try to find the closest path match
			if (hasExactMatch === false) {
				for (const key of Object.keys(RouteList)) {
					if (pathname.startsWith(RouteList[key])) {
						screen = key as RouteNames
						break
					}
				}
			}
		}
	}

	firebase.analytics.logEvent('screen_view', {
		app_name: 'pwGO – Plumbing World',
		screen_name: screen,
	})
}

function handleGoToShareholdingSite(action: platformAuth.GetShareholdingTokenSuccessAction) {
	const { access_token } = action.payload.result
	if (access_token) {
		firebase.analytics.logEvent('navigate_to_mynzpm', {
			app_name: 'myNZPM',
		})
	}
}

function handleNavigateToBackflow() {
	firebase.analytics.logEvent('NAVIGATE_TO_BACKFLOW', {
		app_name: getPlatformSupportImplementation().getPlatformClientId(),
	})
}

function handleNavigateToBackflowProduct(action: backflow.NavigateToBackflowBrandAction | backflow.NavigateToBackflowTypeAction) {
	firebase.analytics.logEvent('NAVIGATE_TO_BACKFLOW', {
		app_name: getPlatformSupportImplementation().getPlatformClientId(),
		method: getBackflowAsAnalyticsMethod(action.payload),
	})
}

function handleUpdateCartWithBackflowProduct() {
	firebase.analytics.logEvent('UPDATE_CART_WITH_BACKFLOW_PRODUCT', {
		app_name: getPlatformSupportImplementation().getPlatformClientId(),
	})
}

function handleSendContactUsWithBackflow() {
	firebase.analytics.logEvent('SEND_CONTACT_US_BACKFLOW', {
		app_name: getPlatformSupportImplementation().getPlatformClientId(),
	})
}

export default function* (): SagaIterator {
	// auth
	yield takeEvery(auth.login.done, handleLoginDone)
	yield takeEvery([auth.loggedOut, auth.refreshAuthTokenFailed], resetAnalyticsUser)
	yield takeEvery(platformAuth.getShareholdingToken.done, handleGoToShareholdingSite)

	// account
	yield takeEvery(account.getAccounts.done, setupAnalyticsUser)

	// product
	yield takeEvery(product.navigateToProduct, handleNavigateToProductViaClick)
	yield takeEvery(product.loadProductDetails.done, handleNavigateToProductViaUrl)

	// order
	yield takeEvery(order.submitOrderOnline.done, handleOrderDone)
	yield takeEvery(cart.removeProductFromCart, handleRemoveProductFromCart)
	yield takeEvery(cart.updateProductQuantityInCart, handleUpdateProductInCart)
	yield takeEvery(cart.clearCart, handleClearCart)

	// register
	yield takeEvery(register.register.done, handleRegisterDone)

	// routing
	yield takeEvery(locationChange, handleLocationChange)

	// backflow
	yield takeEvery(backflow.navigateToBackflow, handleNavigateToBackflow)
	yield takeEvery([backflow.navigateToBackflowBrand, backflow.navigateToBackflowType], handleNavigateToBackflowProduct)
	yield takeEvery(backflow.updateCartWithBackflowProduct, handleUpdateCartWithBackflowProduct)
	yield takeEvery(backflow.sendContactUsBackflow.started, handleSendContactUsWithBackflow)
}