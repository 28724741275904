import * as React from 'react'

import * as Container from '../containers/SubheaderTab'
import CategoryMenu from '../../products/containers/CategoryMenu'
import OrderQuoteMenu from '../../customerorders/containers/CustomerOrdersMenu'
import ListMenu from '../../mylists/containers/ListMenu'
import FinancialsMenu from '../../financials/containers/FinancialsMenu'
import AdminMenu from '../../admin/components/AdminMenu'
import LabelsMenu from '../../labels/components/LabelsMenu'

import '../styles/Subheader.scss'

interface State {
	isHovered: boolean
}

const INITIAL_STATE: State = {
	isHovered: false,
}

export default class SubheaderTab extends React.PureComponent<Container.Props & Container.Actions & Container.OwnProps, State> {

	state = INITIAL_STATE
	anchorElement: HTMLElement | null = null

	componentDidUpdate(_prevProps: Container.Props & Container.Actions & Container.OwnProps, prevState: State) {
		if (prevState.isHovered !== this.state.isHovered) {
			this._isMenuDisplayed()
		}
	}

	_handleTabOnMouseOver = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// set the reference to the anchor element needed for the popper
		if (!this.anchorElement) {
			this.anchorElement = e.currentTarget
		}
		this.setState({ isHovered: true })
	}

	_handleTabOnMouseLeave = () => {
		if (this.anchorElement) {
			this.anchorElement = null
		}
		this.setState({ isHovered: false })
	}

	_handleHideDropdown = () => {
		if (this.anchorElement) {
			this.anchorElement = null
		}
		if (this.state.isHovered) {
			this.setState({ isHovered: false })
		}
	}

	/**
	 * Updates the parent component if the menu is displayed or not.
	 * Used for hiding the search suggestions whenever a tab menu is open.
	 */
	_isMenuDisplayed = () => {
		// we know if the tab item has a menu dropdown if there is no route defined
		const hasMenu: boolean = this.props.tab.route === undefined
		if (hasMenu && this.props.isMenuDisplayed) {
			this.props.isMenuDisplayed(this.state.isHovered)
		}
	}

	_onNavigateToTab = () => {
		const { tab: { route }, userId } = this.props
		if (route) {
			this.props.navigateToTab(route, userId)
		}
	}

	/**
	 * Checks if the current router location pathname matches with any pathnames associated to the current tab
	 */
	_isActivePath = (pathnames?: string[]): boolean => {
		const { pathname } = this.props
		return pathname && pathnames ? pathnames.some(path => pathname.startsWith(path)) : false
	}

	_renderTabMenu = (): JSX.Element | null => {
		const { tab: { title }, isHnzAccount } = this.props
		let Menu
		switch (title) {
			case 'Products':
				Menu = CategoryMenu
				break
			case 'Orders':
				Menu = OrderQuoteMenu
				break
			case 'Lists':
				Menu = ListMenu
				break
			case 'Labels':
				Menu = LabelsMenu
				break
			case 'Financials':
				Menu = FinancialsMenu
				break
			case 'Admin':
				Menu = AdminMenu
				break
			default:
				return null
		}
		return <Menu anchorElement={this.anchorElement} onClickAway={this._handleHideDropdown} isHnzAccount={isHnzAccount} />
	}

	render() {
		const { isHovered } = this.state
		const { title, route, pathnames } = this.props.tab
		// we know if the tab item has a menu dropdown if there is no route defined
		const hasMenu: boolean = route === undefined
		const isActivePath = this._isActivePath(pathnames)

		return (
			<div
				className={`subheader__tab${hasMenu === false ? ' subheader__tab--clickable' : ''}`}
				onMouseOver={this._handleTabOnMouseOver}
				onMouseLeave={this._handleTabOnMouseLeave}
				onClick={this._onNavigateToTab}
			>
				<div className="subheader__tab-content">
					<span className={`subheader__text ${isActivePath ? 'subheader__text--active' : ''}`}>{title}</span>
					{/* Shows the tab indicator if the current route matches the list of pathnames or if the user is currently hovering on the tab */}
					<span className={isActivePath || isHovered ? 'subheader__indicator' : ''} />
				</div>

				{isHovered && hasMenu && this._renderTabMenu()}
			</div>
		)
	}
}