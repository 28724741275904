import { SagaIterator } from 'redux-saga'
import { takeEvery, call, put, select } from 'redux-saga/effects'
import { CarouselPlatform, HomePagePlatform } from 'typescript-fetch-api'

import { readyAction } from '../../common/root/actions'
import { fetchCarousel, fetchHomePageItems } from '../../common/home/actions'
import { authTokenSelector } from '../../common/auth/selectors'
import { login } from '../../common/auth/actions'

function* handleLoadingCarousel(): SagaIterator {
	yield put(fetchCarousel.started(CarouselPlatform.WEB))
}

function* handleLoadingHomePageItems(): SagaIterator {
	yield put(fetchHomePageItems.started(HomePagePlatform.WEB))
}

function* handleCheckAuthStatus(): SagaIterator {
	const hasAuthToken = (yield select(authTokenSelector)) !== undefined
	if (hasAuthToken) {
		yield call(handleLoadingCarousel)
		yield call(handleLoadingHomePageItems)
	}
}

export default function* (): SagaIterator {
	yield takeEvery([readyAction, login.done], handleCheckAuthStatus)
}
