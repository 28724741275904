import { SagaIterator } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'

import * as actions from './actions'
import * as mobileVerificationActions from '../mobileVerification/actions'
import { ApiErrorMessage, getRegisterApi } from '../api'
import { callApi } from '../api/functions'
import { store } from '../root'

function* handleRegisterFailed(action: actions.RegisterRequestFailureAction): SagaIterator {
	const {
		params: { registerRequest: { mobileNumber, email, password }, goCheckoutAfter },
		error,
	} = action.payload

	if (error.message === ApiErrorMessage.ERROR_MSG_ALREADY_VERIFIED) {
		if (mobileNumber) {
			// the user is already registered and has typed a valid username and password and is not logged in
			// so we'll send a verify mobile done action with their credentials
			const mobileVerificationRequestPayload: mobileVerificationActions.MobileVerificationRequestPayload = {
				mobileVerificationRequest: { code: '', mobileNumber, password }, // code can be ignored as its only used to process the verifyMobile.started event
				goCheckoutAfter,
			}
			yield put(mobileVerificationActions.verifyMobile.done({ params: mobileVerificationRequestPayload, result: new Response() }))
		} else if (email) {
			const emailVerificationRequestPayload: mobileVerificationActions.EmailVerificationRequestPayload = {
				emailVerificationRequest: { token: '', email, password }, // token can be ignored as its only used to process the verifyEmail.started event
			}
			yield put(mobileVerificationActions.verifyEmail.done({ params: emailVerificationRequestPayload, result: new Response() }))
		}
	}
}

function* handleRegister(action: actions.RegisterRequestAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.register,
		(payload: actions.RegisterRequestPayload) => {
			const request = payload.registerRequest
			return getRegisterApi()
				.registerUser({ userInfo: request })
				.catch((error) => {
					console.log('registerUser error', error)
					let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
					if (error instanceof Response) {
						switch (error.status) {
							case 301:
								errorText = ApiErrorMessage.CODE_SEND_FAILURE
								break
							case 401:
								errorText = 'Already registered, invalid username or password'
								break
							case 406:
								if (!store.getState().auth.authToken) {
									// the user is already registered and has typed a valid username and password and is not logged in
									// so we'll send a verify mobile done action with their credentials
									errorText = ApiErrorMessage.ERROR_MSG_ALREADY_VERIFIED
								} else {
									errorText = 'You are already logged in as this user'
								}
								break
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
		})
}

export default function* (): SagaIterator {
	yield takeEvery(actions.register.started, handleRegister)
	yield takeEvery(actions.register.failed, handleRegisterFailed)
}